import {
	Currencies,
	PortActionTypeLabels,
	PortActionTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { NewPortRotationEntryFormValues } from '../context/hooks/usePortRotationHandlers';

export const getNewPortRotationEntryFields = ({
	portOptions,
	currency,
}: {
	portOptions: {
		label: string;
		value: number;
	}[];
	currency: Values<typeof Currencies>;
	tempFormValues: Partial<NewPortRotationEntryFormValues>;
}) => [
	{
		name: 'type',
		label: 'Type',
		type: 'select',
		required: true,
		options: Object.values(PortActionTypes)
			.filter((pat) => {
				const blacklist: Values<typeof PortActionTypes>[] = [
					PortActionTypes.CANAL_TRANSIT,
					PortActionTypes.BALLAST_LEG,
					PortActionTypes.LOADING,
					PortActionTypes.DISCHARGING,
				];

				return !blacklist.includes(pat);
			})
			.map((pat) => ({
				label: PortActionTypeLabels[pat],
				value: pat,
			})),
	},
	{
		name: 'portId',
		label: 'Port',
		type: 'select',
		required: true,
		options: portOptions,
		inputProps: {
			showSearch: true,
		},
	},
	{
		name: 'daysInPort',
		label: 'Days in port',
		type: 'number',
		required: false,
		inputProps: {
			allowNegative: false,
		},
	},
	{
		name: 'portCost',
		label: 'Port Cost',
		type: 'currency',
		required: false,
		inputProps: {
			currency,
			allowNegative: false,
		},
	},
];
