import {
	useState,
	useEffect,
	useRef,
} from 'react';
import isVesselInBounds from './isVesselInBounds';

const useAnchorPosition = (position, viewport, mapRef) => {
	const [isInBounds, setIsInbounds] = useState(null);
	const latestCalculatedPositionRef = useRef({ latitude: 0, longitude: 0, zoom: 0 });

	useEffect(() => {
		const inBounds = isVesselInBounds(
			position,
			viewport,
			mapRef,
			latestCalculatedPositionRef,
		);

		if (inBounds != null) {
			setIsInbounds(inBounds);
		}
	}, [viewport, mapRef, position]);

	return isInBounds;
};

export default useAnchorPosition;
