import { SelectOption } from '@client/components/Select';

export const getSelectOptions = <LabelKey extends string, ValueKey extends string>(
	data: Record<LabelKey | ValueKey, string>[],
	labelKey: LabelKey,
	valueKey: ValueKey,
	sortDesc?: boolean,
): SelectOption[] => {
	const values = data.map((row) => ({
		label: row[labelKey],
		value: row[valueKey],
	}));

	values.sort();

	if (sortDesc) {
		values.reverse();
	}

	return values;
};

export const parseCurrencyAmount = (value: string): number => Number((`${value}`).replace(/[^0-9.-]+/g, ''));
export const parsePercentage = (
	value: string | null,
): number | null => (
	value != null ? Number(value.toString().replace('%', '')) : null
);

const requiredObjectValueValidator = (rule: any, fieldObject: any) => {
	try {
		if (fieldObject[rule.valueKey] != null) {
			return Promise.resolve();
		}

		throw new Error(rule.message);
	} catch (e) {
		return Promise.reject(e);
	}
};

export const rules = {
	required: { required: true, message: 'Field is required' },
	requiredShort: { required: true, message: 'Required' },
	requiredObjectValue: (valueKey = 'value') => (
		{ required: true, message: 'Field is required', valueKey, validator: requiredObjectValueValidator }
	),
	numberPositive: { type: 'number', min: 0, message: 'Value must be positive' },
} as const;
