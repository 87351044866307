import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFile,
	faFileAlt,
	faFileAudio,
	faFileImage,
	faFilePdf,
	faFileSpreadsheet,
	faFileVideo,
	faFileWord,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const fileIcons = [
	{
		icon: faFilePdf,
		color: '#b30c01',
		predicate: (t: string) => t === 'application/pdf',
	},
	{
		icon: faFileImage,
		predicate: (t: string) => t.startsWith('image'),
	},
	{
		icon: faFileVideo,
		predicate: (t: string) => t.startsWith('video'),
	},
	{
		icon: faFileAudio,
		predicate: (t: string) => t.startsWith('audio'),
	},
	{
		icon: faFileWord,
		color: '#1a73e8',
		predicate: (t: string) => t === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	},
	{
		icon: faFileSpreadsheet,
		color: '#0f7e45',
		predicate: (t: string) => t === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	},
	{
		icon: faFileAlt,
		predicate: (t: string) => t.startsWith('text'),
	},
];

const FileIcon = ({ fileType = '', ...props }) => {
	const match = fileIcons.find(({ predicate }) => predicate(fileType));

	return (
		<FontAwesomeIcon
			color={match?.color || '#666'}
			{...props}
			icon={(match?.icon || faFile) as IconProp}
		/>
	);
};

export default FileIcon;
