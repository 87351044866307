import React, {
	useState,
	useEffect,
} from 'react';
import {
	Flex,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/pro-thin-svg-icons';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { useExchangeRates } from '@client/utils/hooks/useExchangeRates';
import Select from '../Select';
import NumericInput from '../NumericInput';
import Button from '../Button';
import styles from './CurrencySelect.module.css';

export type CurrencySelectProps = {
	baseCurrency: Values<typeof Currencies>;
	value: {
		currency?: Values<typeof Currencies>;
		exchangeRate?: number;
	};
	onChange: (currency: Values<typeof Currencies>, exchangeRate: number) => void;
}

const CurrencySelect = ({
	baseCurrency,
	value,
	onChange,
}: CurrencySelectProps) => {
	const [selectedCurrency, setSelectedCurrency] = useState<
		Values<typeof Currencies> | null | undefined
	>(value?.currency);
	const [selectedExchangeRate, setSelectedExchangeRate] = useState<
		number | undefined | null
	>(value?.exchangeRate);

	const exchangeRates = useExchangeRates(baseCurrency);
	const fetchedRate = exchangeRates != null ? exchangeRates[selectedCurrency ?? baseCurrency] : 1;

	useEffect(() => {
		const {
			currency,
			exchangeRate,
		} = value;

		if (currency != null && exchangeRate != null) {
			setSelectedCurrency(currency);
			setSelectedExchangeRate(exchangeRate);
		}
	}, [value]);

	const handleCurrencyChange = (c: Values<typeof Currencies> | null) => {
		if (c == null) {
			return;
		}

		setSelectedCurrency(c);
		setSelectedExchangeRate(exchangeRates[c]);
		if (selectedExchangeRate != null) {
			onChange(c, exchangeRates[c]);
		}
	};

	const handleRateChange = (r: number | null) => {
		if (r == null) {
			return;
		}

		setSelectedExchangeRate(r);
		if (selectedCurrency != null) {
			onChange(selectedCurrency, r);
		}
	};

	return (
		<Flex gap={5}>
			<Flex vertical>
				<Typography.Text
					className={styles.label}
				>
					Currency
				</Typography.Text>
				<Select
					showSearch
					className={styles.select}
					value={selectedCurrency ?? baseCurrency}
					defaultValue={selectedCurrency ?? baseCurrency}
					options={Object.values(Currencies).map((c) => ({
						label: c,
						value: c,
					}))}
					onSelect={handleCurrencyChange}
				/>
			</Flex>
			{baseCurrency !== selectedCurrency && (
				<Flex vertical>
					<Typography.Text
						className={styles.label}
					>
						Exchange rate
					</Typography.Text>
					<NumericInput
						className={styles.input}
						width={100}
						value={selectedExchangeRate}
						onChange={handleRateChange}
						suffix={(
							<Button
								className={styles.refreshButton}
								onClick={() => {
									handleRateChange(fetchedRate);
								}}
								icon={(<FontAwesomeIcon color="#1677ff" icon={faRefresh} />)}
							/>
						)}
					/>
				</Flex>
			)}
		</Flex>
	);
};

export default CurrencySelect;
