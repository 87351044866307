import HireInvoiceItem from './HireInvoiceItem';

class HIITotal extends HireInvoiceItem {
	static get itemType() {
		return 'total';
	}

	constructor(id, isOriginal) {
		super(id, isOriginal);

		this._showTotal = false;
		this.itemTypeLabel = 'Total';
		this._fields = {};
	}

	_calculateTotal(invoiceItems) {
		return invoiceItems.reduce((sum, item) => (
			sum + item.getTotal(invoiceItems, true)
		), 0);
	}

	getDescription() {
		return '';
	}

	static _getClassParams(_model, _parentModel) {
		throw new Error('Cannot run _getClassParams on HIITotal');
	}

	async saveModel(_model) {
		throw new Error('Cannot run saveModel on HIITotal');
	}
}

HireInvoiceItem.addItemClass(HIITotal);

export default HIITotal;
