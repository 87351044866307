import { useEffect } from 'react';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { useGlobalState } from '@client/lib/globalState';
import { getExchangeRates } from '@client/lib/untyped-api';

type CurrencyValue = typeof Currencies[keyof typeof Currencies];

export type ExchangeRates = {
	[key in CurrencyValue]: number;
}

export const useExchangeRates = (
	fromCurrency: Values<typeof Currencies>,
): ExchangeRates => {
	const [exchangeRates, setExchangeRates] = useGlobalState(`exchange-rate-${fromCurrency}`);

	useEffect(() => {
		const refreshExchangeRates = async () => {
			const rates = await getExchangeRates(fromCurrency);
			setExchangeRates(rates);
		};

		if (exchangeRates == null) {
			refreshExchangeRates();
		}
	}, [fromCurrency, exchangeRates, setExchangeRates]);

	return exchangeRates as unknown as ExchangeRates;
};
