// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a8pog7Zfto9fxqQx27fz {\n\theight: 100%;\n\tbackground-color: #001529;\n\ttext-align: center;\n}\n\n.a8pog7Zfto9fxqQx27fz,\n._Z_kmgqbPODN9VaeheX1 {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tbox-shadow: #002a52 0 0 20px 10px;\n}\n\n._Z_kmgqbPODN9VaeheX1 {\n\tbackground-color: white;\n\tpadding: 32px;\n\tborder-radius: 2px;\n}\n\n.bc5qht6zfUFr3qg8KKXL {\n\tcolor: #666;\n}\n\n.zhZv6f0XInfZoX2IjD6J {\n\twidth: 240px;\n\tmargin-bottom: 24px;\n}\n\n.kAtnfjrNCJGdYmDGi7w6 {\n\tmargin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/UnauthorizedScreen/UnauthorizedScreen.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,yBAAyB;CACzB,kBAAkB;AACnB;;AAEA;;CAEC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,iCAAiC;AAClC;;AAEA;CACC,uBAAuB;CACvB,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,YAAY;CACZ,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".unauthorizedWrapper {\n\theight: 100%;\n\tbackground-color: #001529;\n\ttext-align: center;\n}\n\n.unauthorizedWrapper,\n.errorInnerWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tbox-shadow: #002a52 0 0 20px 10px;\n}\n\n.errorInnerWrapper {\n\tbackground-color: white;\n\tpadding: 32px;\n\tborder-radius: 2px;\n}\n\n.additionalInfo {\n\tcolor: #666;\n}\n\n.unauthorizedLogo {\n\twidth: 240px;\n\tmargin-bottom: 24px;\n}\n\n.unauthorizedButton {\n\tmargin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unauthorizedWrapper": "a8pog7Zfto9fxqQx27fz",
	"errorInnerWrapper": "_Z_kmgqbPODN9VaeheX1",
	"additionalInfo": "bc5qht6zfUFr3qg8KKXL",
	"unauthorizedLogo": "zhZv6f0XInfZoX2IjD6J",
	"unauthorizedButton": "kAtnfjrNCJGdYmDGi7w6"
};
export default ___CSS_LOADER_EXPORT___;
