import { Moment } from 'moment';
import {
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import {
	formatDate,
	toMoment,
} from '@shared/utils/date';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

export type HIIRevenueSubjectToDaysType = HIIRevenueSubjectToDays;

class HIIRevenueSubjectToDays extends HireInvoiceItem {
	static get itemType() {
		return 'revenueSubjectToDays';
	}

	name: string;
	amount: number;
	startDate: Moment | undefined;
	endDate: Moment | undefined;
	useUTC: boolean;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			name: string;
			amount: number;
			startDate?: Moment;
			endDate?: Moment;
			useUTC?: boolean;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.name = params.name;
		this.amount = params.amount;
		this.startDate = params.startDate;
		this.endDate = params.endDate;
		this._showTotal = true;
		this.itemTypeLabel = params.name;
		this.addManually = true;
		this.templateSection = TemplateItem.Sections.REVENUES;
		this.useUTC = params.useUTC ?? true;

		this.pnlGroup = PnlGroups.REVENUE_SUBJECT_TO_DAYS;

		this._fields = {
			amount: {
				label: 'Amount',
				type: HIIFieldTypes.CURRENCY,
			},
			startDate: {
				label: 'Start date',
				type: HIIFieldTypes.DATE,
			},
			endDate: {
				label: 'End date',
				type: HIIFieldTypes.DATE,
			},
		};
	}

	calculateAmount() {
		if (this.startDate != null && this.endDate != null) {
			// Dividing by 1440 to get days
			const diff = (toMoment(this.endDate).diff(toMoment(this.startDate), 'minutes') / 1440);

			return (diff ?? 0) * this.amount;
		}

		return 0;
	}

	_calculateTotal() {
		return this.calculateAmount();
	}

	getDescription() {
		let dateStr = '';

		if (this.startDate != null && this.endDate != null) {
			dateStr =
				`
				${formatDate(this.startDate, 'MMM Do YYYY HH:mm', false, this.useUTC ? 'utc' : 'localTime')}
				${this.useUTC ? 'UTC' : 'LT'} - 
				${formatDate(this.endDate, 'MMM Do YYYY HH:mm', false, this.useUTC ? 'utc' : 'localTime')}
				${this.useUTC ? 'UTC' : 'LT'}
				`;
		}

		return `
		${formatCurrency(this.amount, this.fixtureCurrency)} per day\n
		${dateStr}
		`;
	}

	static _getClassParams(model: any, _parentModel: any) {
		return {
			name: model.name,
			amount: model.amount,
			startDate: model.startDate,
			endDate: model.endDate,
			useUTC: model.useUTC,
			fixtureCurrency: model.fixtureCurrency,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);

		model.name = this.name;
		model.amount = this.amount;
		model.startDate = this.startDate;
		model.endDate = this.endDate;
		model.useUTC = this.useUTC;

		await model.save();
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.name,
					this.getDescription(),
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					this.name,
					this.getDescription(),
				],
			},
		};
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return (
			(this.name === other.name) &&
			(this.amount === other.amount)
		);
	}
}

HireInvoiceItem.addItemClass(HIIRevenueSubjectToDays);

export default HIIRevenueSubjectToDays;
