// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DeaP49fEob63iSGr1HWg {\n\tposition: absolute;\n\ttop: 15px;\n\tright: 15px;\n\tbox-shadow: 0 0 20px black;\n\tz-index: 1;\n\twidth: 170px;\n\tbackground-color: white;\n\tborder-radius: 6px;\n\topacity: 0.9;\n}\n\n.tyEwzWGVn6RxfR9pDQme {\n\tfont-size: 12px;\n\tpadding: 10px 10px;\n}\n\n.tyEwzWGVn6RxfR9pDQme .ant-card-body {\n\tpadding: 0 !important;\n}\n\n.SiIsJOW1hOWyd7yDVfLY {\n\ttext-align: right;\n}\n\n.XyMuk_UrHdrU3Ifp32h2 {\n\tmargin: 0;\n}\n\n.u862t5VNKbi8r8aoD49b {\n\ttext-align: center;\n}\n\n.Rm87IOOFhwXk7sDGn9X7 {\n\tcolor: cyan;\n}\n\n.FzKZpofMYjSZoHRTDkJO {\n\tcolor: black;\n}\n\n.aURIpsVnSgFmF1mClh_w {\n\tcolor: #77d2df;\n\topacity: 0.8;\n}\n\n.YYDT_z4P4kfoPbA7Cbvq {\n\tmargin-bottom: 0 !important;\n}\n\n.RKgpGkAsg2dn3nu_Rkb9 {\n\tfont-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/Map/MapLegend.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,SAAS;CACT,WAAW;CACX,0BAA0B;CAC1B,UAAU;CACV,YAAY;CACZ,uBAAuB;CACvB,kBAAkB;CAClB,YAAY;AACb;;AAEA;CACC,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,cAAc;CACd,YAAY;AACb;;AAEA;CACC,2BAA2B;AAC5B;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".legend {\n\tposition: absolute;\n\ttop: 15px;\n\tright: 15px;\n\tbox-shadow: 0 0 20px black;\n\tz-index: 1;\n\twidth: 170px;\n\tbackground-color: white;\n\tborder-radius: 6px;\n\topacity: 0.9;\n}\n\n.legendCard {\n\tfont-size: 12px;\n\tpadding: 10px 10px;\n}\n\n.legendCard :global(.ant-card-body) {\n\tpadding: 0 !important;\n}\n\n.switchCol {\n\ttext-align: right;\n}\n\n.divider {\n\tmargin: 0;\n}\n\n.icon {\n\ttext-align: center;\n}\n\n.shipIcon {\n\tcolor: cyan;\n}\n\n.majorPortsIcon {\n\tcolor: black;\n}\n\n.ecaZonesIcon {\n\tcolor: #77d2df;\n\topacity: 0.8;\n}\n\n.bottomRow {\n\tmargin-bottom: 0 !important;\n}\n\n.label {\n\tfont-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legend": "DeaP49fEob63iSGr1HWg",
	"legendCard": "tyEwzWGVn6RxfR9pDQme",
	"switchCol": "SiIsJOW1hOWyd7yDVfLY",
	"divider": "XyMuk_UrHdrU3Ifp32h2",
	"icon": "u862t5VNKbi8r8aoD49b",
	"shipIcon": "Rm87IOOFhwXk7sDGn9X7",
	"majorPortsIcon": "FzKZpofMYjSZoHRTDkJO",
	"ecaZonesIcon": "aURIpsVnSgFmF1mClh_w",
	"bottomRow": "YYDT_z4P4kfoPbA7Cbvq",
	"label": "RKgpGkAsg2dn3nu_Rkb9"
};
export default ___CSS_LOADER_EXPORT___;
