// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zw0i9qNtbEe_hu4kD0Sn {\n\tmargin-bottom: 8px;\n\tflex-direction: column;\n\talign-items: flex-start;\n}\n\n.Zw0i9qNtbEe_hu4kD0Sn .ant-form-item-control {\n\twidth: 100%;\n}\n\n.fj5VRTMJIvHP1KuNFogg .ant-tabs-tab {\n\tcolor: red !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/PdfGenerator/private/styles/Settings.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,sBAAsB;CACtB,uBAAuB;AACxB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":[".formItem {\n\tmargin-bottom: 8px;\n\tflex-direction: column;\n\talign-items: flex-start;\n}\n\n.formItem :global(.ant-form-item-control) {\n\twidth: 100%;\n}\n\n.error :global(.ant-tabs-tab) {\n\tcolor: red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "Zw0i9qNtbEe_hu4kD0Sn",
	"error": "fj5VRTMJIvHP1KuNFogg"
};
export default ___CSS_LOADER_EXPORT___;
