import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
} from 'react';
import history from '@client/utils/history';

const NavigationBlockContext = createContext();

export const useNavigationBlock = () => useContext(NavigationBlockContext);

const defaultMessage = 'You have unsaved changes. Are you sure you want to leave the page?';

/*
 * This context allows you to block navigation
 * (both using internal links and trying to navigate to another site)
 * Simply register a blocker using `useBlocker` and
 * indicating with the first parameter whether to enable it
 * useBlocker also takes an optional message to show when blocking
 * However, most browsers will show their own built-in message when navigating to another site.
 * To block other things than navigation, use `makeBlockable`
 */
export const NavigationBlockProvider = ({ children }) => {
	const [blockers, setBlockers] = useState([]);
	const [isBlocking, setIsBlocking] = useState(false);

	const shouldBlock = blockers.length > 0;
	const currentMessage = blockers.length > 0 ? blockers[0].message : defaultMessage;

	const makeBlockable = useCallback((toDo) => (...args) => {
		// eslint-disable-next-line no-alert
		if (shouldBlock && !window.confirm(currentMessage)) {
			return;
		}

		toDo(...args);
	}, [currentMessage, shouldBlock]);

	useEffect(() => {
		let unblock;

		const handler = (e) => {
			e.returnValue = currentMessage;
		};

		if (shouldBlock) {
			unblock = history.block(currentMessage);
			window.addEventListener('beforeunload', handler);
		}

		setIsBlocking(shouldBlock);

		return () => {
			if (shouldBlock) {
				unblock();
				window.removeEventListener('beforeunload', handler);
			}
		};
	}, [currentMessage, shouldBlock]);

	const useBlocker = (enabled, message = defaultMessage) => {
		useEffect(() => {
			const id = Math.floor(Math.random() * 100000000000000000);

			if (enabled) {
				const newBlocker = {
					id,
					message,
				};

				setBlockers((oldBlockers) => [
					...oldBlockers,
					newBlocker,
				]);
			}

			return () => {
				if (enabled) {
					setBlockers((oldBlockers) => (
						oldBlockers.filter((b) => b.id !== id)
					));
				}
			};
		}, [enabled, message]);
	};

	const value = {
		useBlocker,
		blockers,
		isBlocking,
		makeBlockable,
	};

	return (
		<NavigationBlockContext.Provider
			value={value}
		>
			{children}
		</NavigationBlockContext.Provider>
	);
};
