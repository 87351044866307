import React, {
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Drawer,
	Form,
	Grid,
	Input,
	Modal,
} from 'antd';
import { Moment } from 'moment';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { asyncMap } from '@shared/utils/array';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { AttachmentProps } from '@api/models/attachment';
import InvoiceAttachment from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceAttachment';
import useFileUpload, { Upload } from '@client/utils/hooks/useFileUpload';
import DatePicker from '@client/components/DatePicker';
import MultiCurrencyInput from '@client/components/MultiCurrencyInput';
import Button from '@client/components/Button';
import {
	createBrokerInvoice,
	updateBrokerInvoice,
} from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import getFileFromAttachment from '@client/utils/getFileFromAttachment';
import LoadingIndicator from '@client/components/LoadingIndicator';
import styles from './BrokerInvoiceDrawer.module.css';

const required = [{ required: true, message: 'Field is required' }];

type BrokerInvoiceForm = {
	id?: number;
	brokerId: number;
	brokerName: string;
	contract: string;
	voyageId: number;
	itemDescription: string;
	dateReceived: Moment;
	dueDate: Moment;
	amount: {
		value: number;
		currency: Values<typeof Currencies>;
		exchangeRate: number;
	};
}

type Props = {
	brokerId: number | undefined;
	brokerName: string;
	voyageId: number;
	contractIdentifier: string;
	initialFiles?: Upload[];
	currency: Values<typeof Currencies>;
	editValues: GetVoyageDetailsResponse['brokerInvoices'][number] | null;
	setEditValues: React.Dispatch<SetStateAction<GetVoyageDetailsResponse['brokerInvoices'][number] | null>>;
	voyageDetails: GetVoyageDetailsResponse;
	open: boolean;
	setOpen: React.Dispatch<SetStateAction<boolean>>;
	refreshDetails: () => void;
	confirmTitle: (row: GetVoyageDetailsResponse['brokerInvoices'][number], form?: boolean) => string | null;
}

const BrokerInvoiceDrawer = ({
	brokerId,
	brokerName,
	voyageId,
	contractIdentifier,
	currency,
	initialFiles,
	editValues,
	setEditValues,
	open,
	setOpen,
	voyageDetails,
	refreshDetails,
	confirmTitle,
}: Props) => {
	const screens = Grid.useBreakpoint();
	const [loadingAttachments, setLoadingAttachments] = useState(false);

	const {
		uploaded,
		uploadProps,
		onRemoveUpload,
		onSetUploaded,
		onResetUploaded,
	} = useFileUpload({ multiple: true });

	useEffect(() => {
		if (initialFiles == null || initialFiles.length === 0) {
			return;
		}

		onSetUploaded(initialFiles);
	}, [onSetUploaded, initialFiles]);

	useEffect(() => {
		if (!open) {
			onResetUploaded();
		}
	}, [onResetUploaded, open]);

	const [form] = Form.useForm<BrokerInvoiceForm>();

	useEffect(() => {
		form.setFieldsValue({
			brokerName,
			contract: contractIdentifier,
		});
	}, [brokerName, contractIdentifier, form]);

	useEffect(() => {
		if (editValues != null) {
			form.setFieldsValue({
				id: editValues.id,
				amount: {
					value: editValues.amount,
					currency,
					exchangeRate: editValues.exchangeRate,
				},
				dueDate: editValues.dueDate,
				dateReceived: editValues.dateReceived,
				itemDescription: editValues.itemDescription,
			});
		}
	}, [currency, editValues, form]);

	const handleModalBackgroundClick = useCallback((e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			setOpen(false);
			setEditValues(null);
			form.resetFields();
		}
	}, [form, setEditValues, setOpen]);

	useEffect(() => {
		const updateDefaultFiles = async () => {
			setLoadingAttachments(true);

			const brokerInvoice = voyageDetails.brokerInvoices.find((inv) => inv.id === editValues?.id);

			if (brokerInvoice == null) {
				setLoadingAttachments(false);

				return;
			}

			try {
				const getUploadObject = async (attachment: AttachmentProps) => {
					const file: File & {
						uid?: string;
						attachmentId?: number;
						deleteable?: boolean;
					} = await getFileFromAttachment(attachment);

					file.uid = `${file.name}-${file.lastModified}`;
					file.attachmentId = attachment.id;

					return {
						file: file as File & {
							uid: string;
							attachmentId: number;
							deleteable?: boolean;
						},
						url: window.URL.createObjectURL(file),
					};
				};

				const brokerInvoiceAttachments = brokerInvoice.attachments;

				const brokerInvoiceFiles = await asyncMap(
					brokerInvoiceAttachments,
					async (attachment) => {
						const uploadObj: {
							url: any;
							deleteable?: boolean;
							file: File & {
								uid: string;
								attachmentId: number;
							};
						} = await getUploadObject(attachment);

						uploadObj.deleteable = true;

						return uploadObj;
					},
				);

				onSetUploaded(brokerInvoiceFiles);
			} catch (e) {
				showErrorNotification('Could not load attachments', e as Error);
			}

			setLoadingAttachments(false);
		};

		updateDefaultFiles();
	}, [onSetUploaded, editValues, voyageDetails.brokerInvoices]);

	const attachmentUpload = useMemo(() => (
		<div className={styles.attachmentsInner}>
			<InvoiceAttachment
				files={uploaded}
				uploadProps={uploadProps}
				onRemoveFile={onRemoveUpload}
			/>
		</div>
	), [onRemoveUpload, uploadProps, uploaded]);

	const handleSubmitInvoice = useCallback(async (vals: BrokerInvoiceForm) => {
		try {
			const values = {
				...vals,
				brokerId,
				voyageId,
				amount: vals.amount.value,
				currency: vals.amount.currency,
				exchangeRate: vals.amount.exchangeRate,
			};

			const attachments = Array.isArray(uploaded) ? uploaded.map((u) => u.file) : uploaded;

			if (editValues != null) {
				await updateBrokerInvoice({
					...values,
					brokerInvoiceId: editValues.id,
					attachments,
				});
			} else {
				await createBrokerInvoice({
					...values,
					attachments,
				});
			}

			setOpen(false);
			setEditValues(null);
			showSuccessNotification(`Broker invoice ${editValues == null ? 'created' : 'updated'}!`);
			refreshDetails();
		} catch (e) {
			showErrorNotification(`Could not ${editValues == null ? 'create' : 'update'} broker invoice`, e as Error);
		}
	}, [brokerId, editValues, refreshDetails, setEditValues, setOpen, uploaded, voyageId]);

	return (
		<>
			{open && (
				<Modal
					open={open}
					wrapClassName={styles.rightSideModalWrapper}
					footer={null}
					width="100%"
					mask={false}
					onCancel={() => {
						setEditValues(null);
						form.resetFields();
					}}
				>
					<div className={styles.drawerSpacerContainer}>
						<div className={styles.drawerSpacer} />
						<div
							className={styles.attachmentsDraggerContainer}
							onClick={handleModalBackgroundClick}
							role="presentation"
						>
							{loadingAttachments ? (
								<LoadingIndicator />
							) : (
								!screens.xs && attachmentUpload
							)}
						</div>
					</div>
				</Modal>
			)}
			<Drawer
				title={editValues != null ? 'Edit Broker Invoice' : 'Create New Broker Invoice'}
				open={open}
				placement="left"
				width={310}
				onClose={() => {
					setEditValues(null);
					form.resetFields();
					setOpen(false);
				}}
				mask={!open}
			>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleSubmitInvoice}
					preserve
				>
					<Form.Item
						name="brokerName"
						label="Broker Name"
					>
						<Input disabled />
					</Form.Item>
					<Form.Item
						name="contract"
						label="Contract Identifier"
					>
						<Input disabled />
					</Form.Item>
					<Form.Item
						name="itemDescription"
						label="Description"
						rules={required}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="dateReceived"
						label="Date Received"
						rules={required}
					>
						<DatePicker />
					</Form.Item>
					<Form.Item
						name="dueDate"
						label="Date Due"
					>
						<DatePicker />
					</Form.Item>
					<Form.Item
						name="amount"
						label="Amount"
						rules={required}
					>
						<MultiCurrencyInput
							baseCurrency={currency}
							placeholder="Amount"
						/>
					</Form.Item>
					{editValues != null && confirmTitle(editValues) != null && (
						<>
							<Alert
								message={confirmTitle(editValues, true)}
								type="warning"
							/>
							<br />
						</>
					)}
					<Button
						htmlType="submit"
						type="primary"
					>
						Save Invoice
					</Button>
				</Form>
			</Drawer>
		</>
	);
};

export default BrokerInvoiceDrawer;
