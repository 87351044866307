import React from 'react';
import NumericInput, { NumericInputProps } from '@client/components/NumericInput';

export type PercentageInputProps = NumericInputProps;

const PercentageInput: React.FC<PercentageInputProps> = ({
	allowNegative = false,
	...props
}) => (
	<NumericInput
		addonAfter="%"
		allowNegative={allowNegative}
		{...props}
	/>
);

export default PercentageInput;
