// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hrTi5GBMnOJoxweI85wG {\n\tfont-size: 22px;\n}\n\n.y3YQmEsQZIeWaJauuQ1k {\n\twidth: 100%;\n}\n\n.F8Kz9LMh_J_n1TxbnVhE {\n\twidth: 100%;\n}\n\n.G3oqEXveMXxRbubnkN5g {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/EditableDetails/EditableDetails.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".detailsIcon {\n\tfont-size: 22px;\n}\n\n.datePicker {\n\twidth: 100%;\n}\n\n.input {\n\twidth: 100%;\n}\n\n.select {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsIcon": "hrTi5GBMnOJoxweI85wG",
	"datePicker": "y3YQmEsQZIeWaJauuQ1k",
	"input": "F8Kz9LMh_J_n1TxbnVhE",
	"select": "G3oqEXveMXxRbubnkN5g"
};
export default ___CSS_LOADER_EXPORT___;
