// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eDxosQWCb01MnY6Rn7uK .ant-collapse-header:focus {\n\ttransition: none;\n\toutline: 1px solid var(--primary-light) !important;\n}\n\n.XEuZc4dgTbViVUE1ijWi:first-child {\n\twidth: 40%;\n}\n\n.CJI6I948b96RzvorYBnY {\n\ttext-align: right;\n}\n\n.o9PXcBceBTXUiHSy1W41 .ant-badge-status-dot {\n\tmargin-right: 5px;\n}\n\n.d4Xb123hZuLoNzCwVeIA {\n\tfont-size: smaller;\n\tcolor: red;\n}\n\n.grhdw47k6gK_6hEFA1Ny {\n\tfont-size: smaller;\n\tcolor: #1890ff;\n}\n\n.ZNMnR2nIXBq_W2wDDvJo {\n\tfont-size: 20px;\n\tpadding-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fixtures/shared/styles/FixtureSections.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,kDAAkD;AACnD;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;CAClB,UAAU;AACX;;AAEA;CACC,kBAAkB;CAClB,cAAc;AACf;;AAEA;CACC,eAAe;CACf,iBAAiB;AAClB","sourcesContent":[".container :global(.ant-collapse-header):focus {\n\ttransition: none;\n\toutline: 1px solid var(--primary-light) !important;\n}\n\n.row:first-child {\n\twidth: 40%;\n}\n\n.legendRow {\n\ttext-align: right;\n}\n\n.legendBadge :global(.ant-badge-status-dot) {\n\tmargin-right: 5px;\n}\n\n.missingField {\n\tfont-size: smaller;\n\tcolor: red;\n}\n\n.optionalField {\n\tfont-size: smaller;\n\tcolor: #1890ff;\n}\n\n.emptyDescription {\n\tfont-size: 20px;\n\tpadding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "eDxosQWCb01MnY6Rn7uK",
	"row": "XEuZc4dgTbViVUE1ijWi",
	"legendRow": "CJI6I948b96RzvorYBnY",
	"legendBadge": "o9PXcBceBTXUiHSy1W41",
	"missingField": "d4Xb123hZuLoNzCwVeIA",
	"optionalField": "grhdw47k6gK_6hEFA1Ny",
	"emptyDescription": "ZNMnR2nIXBq_W2wDDvJo"
};
export default ___CSS_LOADER_EXPORT___;
