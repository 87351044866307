import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Space,
	Modal,
	Switch,
	List,
	Checkbox,
	Divider,
	TimePicker,
} from 'antd';
import {
	faBell,
	faQuestionCircle,
	faShip,
	faAlarmClock,
	faSliders,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Moment } from 'moment';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import throttle from 'lodash.throttle';
import isEqual from 'lodash.isequal';
import {
	AlertTypes,
	DateFormats,
	FeatureFlags,
	GlobalLaytimeCalculationMethod,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import { capitalize } from '@shared/utils/string';
import type { UserAttributes } from '@api/features/user/updateUser';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getUserData,
	getVersionInfo,
	getVessels,
	updateUser,
} from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import CountryFlag from '@client/components/CountryFlag';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import Select from '@client/components/Select';
import styles from './UserSettingsModal.module.css';
import SettingEntry from './components/SettingEntry';
import CardWithMenu from './components/CardWithMenu';

const defaultPerformanceEmailTime = '08:00';

const UserSettingsModal = ({ ...props }) => {
	const [vessels] = useFetchedState(getVessels);
	const [appInfo] = useFetchedState(getVersionInfo, [], { autoRefresh: false });
	const { refreshAuthInfo, userInfo } = useAuth();
	const showPerformanceEmailSetting = useFeatureFlag(FeatureFlags.PERFORMANCE);

	const [userData, refreshUserData] = useFetchedState(getUserData);

	const [relevantVesselsState, setRelevantVesselsState] = useState<number[]>([]);
	const [enableVesselFilter, setEnableVesselFilter] = useState(false);
	const [performanceEmailTime, setPerformanceEmailTime] = useState<Moment | null>(null);
	const [
		preferredDateFormat,
		setPreferredDateFormat,
	] = useState<Values<typeof DateFormats> | null>(null);

	useEffect(() => {
		setPreferredDateFormat(userData?.dateFormat || null);
	}, [userData]);

	useEffect(() => {
		setEnableVesselFilter(userInfo.filterVessels);
	}, [userInfo.filterVessels]);

	useEffect(() => {
		setRelevantVesselsState((prevState) => {
			const newVessels = (userData?.relevantVessels || []).map((v: { id: number }) => v.id).sort();

			return isEqual(newVessels, prevState) ? prevState : newVessels;
		});
	}, [userData?.relevantVessels]);

	useEffect(() => {
		if (userData?.performanceEmailTime != null && userData?.performanceEmailTimeOffset != null) {
			setPerformanceEmailTime(toMoment(`2022-02-01 ${userData.performanceEmailTime}`));
		}
	}, [userData?.performanceEmailTime, userData?.performanceEmailTimeOffset]);

	const userId = useMemo(() => userData?.id, [userData]);

	const alertFilter = useMemo(() => {
		if (userData?.alertFilter == null) {
			return [];
		}

		return userData.alertFilter;
	}, [userData?.alertFilter]);

	const setSetting = useCallback(async (attributes: UserAttributes) => {
		if (userId != null) {
			await updateUser({ userId, attributes });
		}

		await refreshUserData();
		await refreshAuthInfo();
	}, [refreshAuthInfo, refreshUserData, userId]);

	const throttledUpdateVesselFilter = useMemo(() => throttle((vesselFilters) => {
		setSetting({ 'relevantVessels': vesselFilters });
	}, 2000, { leading: false }), [setSetting]);

	useEffect(() => {
		throttledUpdateVesselFilter(relevantVesselsState);
	}, [throttledUpdateVesselFilter, relevantVesselsState]);

	const checkVessel = async (vesselId: number, value: boolean) => {
		let newRelevantVessels;

		if (value) {
			newRelevantVessels = [...relevantVesselsState, vesselId];
		} else {
			newRelevantVessels = relevantVesselsState.filter((v) => v !== vesselId);
		}

		setRelevantVesselsState(newRelevantVessels.sort());
	};

	const setAlert = async (alert: Values<typeof AlertTypes>, value: boolean) => {
		if (!value) {
			await setSetting({ 'alertFilter': [...alertFilter, alert] });
		} else {
			const index = alertFilter.findIndex((e) => e === alert);
			alertFilter.splice(index, 1);
			await setSetting({ 'alertFilter': alertFilter });
		}
	};

	const setAlertTime = async (value: string | string[]) => {
		const time = Array.isArray(value) ? value[0] : value;
		const offset = new Date().getTimezoneOffset();

		setPerformanceEmailTime(toMoment(`2022-02-01 ${time}`));
		await setSetting({
			'performanceEmailTime': time,
			'performanceEmailTimeOffset': -offset,
		});
	};

	const receiveEmailAlerts = userData?.receiveEmailAlerts ?? false;

	const filteredVessels = vessels?.filter((v) => v.ownershipType !== VesselOwnershipTypes.MARKET);

	const settingsPages = [
		{
			key: 'general',
			title: 'General',
			icon: faSliders,
			content: (
				<>
					<SettingEntry label="Preferred date format">
						<Select
							defaultValue={preferredDateFormat}
							options={[
								{
									label: 'YYYY/MM/DD',
									value: DateFormats.YMD,
								},
								{
									label: 'DD/MM/YYYY',
									value: DateFormats.DMY,
								},
								{
									label: 'MM/DD/YYYY',
									value: DateFormats.MDY,
								},
							]}
							onChange={(v) => {
								if (v != null) {
									setSetting({ 'dateFormat': v });
									localStorage.setItem('preferredDateFormat', v);
								}
							}}
						/>
					</SettingEntry>
					<SettingEntry label="Laytime calculation method">
						<Select
							defaultValue={userData?.globalLaytimeCalculationMethod}
							options={Object.values(GlobalLaytimeCalculationMethod).map((method) => ({
								value: method,
								label: capitalize(method),
							}))}
							onChange={async (v) => {
								if (v != null) {
									await updateUser({
										attributes: {
											globalLaytimeCalculationMethod: v,
										},
									});

									await refreshAuthInfo();
								}
							}}
						/>
					</SettingEntry>
				</>
			),
		},
		{
			key: 'vessels',
			title: 'Vessels',
			icon: faShip,
			content: (
				<>
					<SettingEntry label="Enable default vessel filter">
						<Switch
							checked={enableVesselFilter}
							onChange={(v) => {
								setEnableVesselFilter(v);
								setSetting({ 'filterVessels': v });
							}}
						/>
					</SettingEntry>
					<SettingEntry label="Default vessel filter">
						{!userInfo.filterVessels && (<div className={styles.overlay} />)}
						<List
							size="small"
							className={styles.vesselList}
							dataSource={filteredVessels ?? []}
							renderItem={(item) => (
								<List.Item key={item.id}>
									<Space>
										<Checkbox
											disabled={!userInfo.filterVessels}
											checked={relevantVesselsState.includes(item.id)}
											onChange={(e) => checkVessel(item.id, e.target.checked)}
										>
											{item.name}
										</Checkbox>
									</Space>
								</List.Item>
							)}
						/>
					</SettingEntry>
				</>
			),
		},
		{
			key: 'alerts',
			title: 'Alerts',
			icon: faBell,
			content: (
				<div className={styles.alertsContent}>
					<SettingEntry label="Receive email alerts">
						<Switch
							checked={receiveEmailAlerts}
							onChange={(v) => {
								setSetting({ 'receiveEmailAlerts': v });
								if (userData?.performanceEmailTime == null) {
									setAlertTime(defaultPerformanceEmailTime);
								}
							}}
						/>
					</SettingEntry>
					<Divider className={styles.slimDivider} />
					{userData?.isManagement && (
						<SettingEntry label="Email when an expense is submitted for approval">
							<Switch
								checked={!alertFilter.some((f) => f === AlertTypes.APPROVE_EXPENSE)}
								onChange={(v) => setAlert(AlertTypes.APPROVE_EXPENSE, v)}
								disabled={!receiveEmailAlerts}
							/>
						</SettingEntry>
					)}
					<SettingEntry label="Email when a new expense has been entered">
						<Switch
							checked={!alertFilter.some((f) => f === AlertTypes.NEW_EXPENSE)}
							onChange={(v) => setAlert(AlertTypes.NEW_EXPENSE, v)}
							disabled={!receiveEmailAlerts}
						/>
					</SettingEntry>
					{showPerformanceEmailSetting && (
						<SettingEntry label="Daily fleet performance overview">
							<div className={styles.fleetPerformanceAlert}>
								<Switch
									checked={!alertFilter.some((f) => f === AlertTypes.FLEET_PERFORMANCE)}
									onChange={(v) => {
										setAlert(AlertTypes.FLEET_PERFORMANCE, v);
										if (userData?.performanceEmailTime == null) {
											setAlertTime(defaultPerformanceEmailTime);
										}
									}}
									disabled={!receiveEmailAlerts}
								/>
								<TimePicker
									allowClear={false}
									className={styles.timePicker}
									defaultValue={toMoment(`2022-02-01 ${defaultPerformanceEmailTime}`)}
									disabled={!receiveEmailAlerts || alertFilter.some(
										(f) => f === AlertTypes.FLEET_PERFORMANCE,
									)}
									minuteStep={15}
									format="HH:mm"
									suffixIcon={(
										<FontAwesomeIcon
											icon={faAlarmClock as IconProp}
										/>
									)}
									onChange={(_field, value) => setAlertTime(value)}
									value={performanceEmailTime || toMoment(`2022-02-01 ${defaultPerformanceEmailTime}`)}
								/>
							</div>
						</SettingEntry>
					)}
					<SettingEntry label="Email when a new arrival/departure report is received">
						<Switch
							checked={!alertFilter.some((f) => f === AlertTypes.ARRIVAL_DEPARTURE)}
							onChange={(v) => setAlert(AlertTypes.ARRIVAL_DEPARTURE, v)}
							disabled={!receiveEmailAlerts}
						/>
					</SettingEntry>
					<SettingEntry label="Email when a new hire invoice must be raised">
						<Switch
							checked={!alertFilter.some((f) => f === AlertTypes.LATE_HIRE_INVOICING)}
							onChange={(v) => setAlert(AlertTypes.LATE_HIRE_INVOICING, v)}
							disabled={!receiveEmailAlerts}
						/>
					</SettingEntry>
					<SettingEntry label="Email when ETA is slipping beyond the laycan">
						<Switch
							checked={!alertFilter.some((f) => f === AlertTypes.ETA_SLIPPAGE)}
							onChange={(v) => setAlert(AlertTypes.ETA_SLIPPAGE, v)}
							disabled={!receiveEmailAlerts}
						/>
					</SettingEntry>
					<SettingEntry label="Email when a new payment has been entered">
						<Switch
							checked={!alertFilter.some((f) => f === AlertTypes.NEW_PAYMENT)}
							onChange={(v) => setAlert(AlertTypes.NEW_PAYMENT, v)}
							disabled={!receiveEmailAlerts}
						/>
					</SettingEntry>
					<SettingEntry label="Weekly summary email">
						<Switch
							checked={!alertFilter.some((f) => f === AlertTypes.WEEKLY_SUMMARY)}
							onChange={(v) => setAlert(AlertTypes.WEEKLY_SUMMARY, v)}
							disabled={!receiveEmailAlerts}
						/>
					</SettingEntry>

				</div>
			),
		},
		{
			key: 'about',
			title: 'About ClearVoyage',
			icon: faQuestionCircle,
			content: (
				<>
					<SettingEntry label="App Version">
						{appInfo != null ? `${appInfo.LATEST_TAG} (${appInfo.LATEST_COMMIT})` : ''}
					</SettingEntry>
					<SettingEntry label="App Documents">
						<a target="_blank" rel="noreferrer" href="https://clearvoyage.com/privacy-policy/">
							Privacy Policy
						</a>
						<br />
						<a target="_blank" rel="noreferrer" href="https://clearvoyage.com/saas-agreement/">
							SaaS Agreement
						</a>
						<br />
						<a target="_blank" rel="noreferrer" href="https://clearvoyage.com/confidentiality-agreement/">
							Confidentiality Agreement
						</a>
						<br />
						<a target="_blank" rel="noreferrer" href="https://clearvoyage.com/acceptable-use/">
							Acceptable Use
						</a>
					</SettingEntry>
					<div className={styles.madeIn}>
						Made in Copenhagen
						<CountryFlag
							className={styles.flag}
							countryCode="DK"
						/>
					</div>
				</>
			),
		},
	];

	return (
		<Modal
			centered
			footer={null}
			className={styles.settingsModal}
			width={675}
			{...props}
		>
			<CardWithMenu pages={settingsPages} />
		</Modal>
	);
};

export default UserSettingsModal;
