import React, {
	ReactElement,
	useState,
} from 'react';
import {
	ComposedChart,
	Legend,
	LegendType,
	Line,
	ReferenceArea,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { DefaultLegendContent } from 'recharts/lib/component/DefaultLegendContent';
import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent';
import range from 'lodash.range';
import { round } from '@shared/utils/math';
import { CrewReportTypes } from '@shared/utils/constants';
import checkThresholds from '@shared/utils/checkThresholds';
import { ChartColors } from '@client/utils/constants';
import styles from './PerformanceLineChart.module.css';

type ReferenceLine = {
	label?: string;
	color?: string;
	strokeSpacing?: number;
	lineWidth?: number;
	visible?: boolean;
} & (
	{
		dataKey: string;
		y?: undefined;
	} |
	{
		dataKey?: undefined;
		y: number;
	}
);

export type CustomDotProps = {
	cx: number;
	cy: number;
	payload: any;
	value: number;
};

type DataLine<T> = {
	dataKey: (Extract<keyof T, string>) | ((item: T) => number | 'hidden');
	label: string;
	smooth?: boolean;
	color?: string;
	dotRadius?: number;
	lineWidth?: number;
	CustomDotComponent?: React.FC<CustomDotProps>;
};

export type TooltipProps = {
	name: string;
	value: string | null;
	color: string;
	noData?: boolean;
};

type PerformanceLineChartProps<T> = {
	height?: number;
	referenceLine?: ReferenceLine;
	extraLegendItems?: Array<{
		color: string;
		label: string;
		type: LegendType;
		iconSvgElement?: ReactElement<SVGElement>;
	}>;
	extraTooltipItems?: Array<(
		| (
			{
				label: string;
				color?: string;
				getColor?: (item: T) => string;
			} & (
				{ noData: true; dataKey?: undefined } |
				{ noData?: false; dataKey: keyof T }
			)
		)
		| (
			(row: T) => TooltipProps | null
		)
	)>;
	xAxis: {
		dataKey: (Extract<keyof T, string>) | ((item: T) => number | string);
	};
	yAxis: {
		dataKey: Extract<keyof T, string>;
	};
	dataLines: Array<DataLine<T>>;
	data: Array<T>;
	shadedAreas?: Array<{
		x1: string | number;
		x2: string | number;
	}>;
	yAxisTicks?: number;
	openReportDrawer: (report: T) => void;
	thresholds?: { min: number; max: number };
};

const PerformanceLineChart = <T extends { id: string }, >(props: PerformanceLineChartProps<T>) => {
	const {
		referenceLine,
		extraLegendItems,
		extraTooltipItems,
		dataLines,
		height,
		data,
		xAxis,
		yAxis,
		shadedAreas,
		yAxisTicks,
		openReportDrawer,
		thresholds,
	} = props;

	const [hoverReport, setHoverReport] = useState<T>();

	let minReferenceLine: number | null = null;
	let maxReferenceLine: number | null = null;

	if (referenceLine?.dataKey != null) {
		const referenceLinePoints = data
			.map((d) => d[referenceLine.dataKey])
			.filter((d) => d != null);

		if (referenceLinePoints.length === 0) {
			minReferenceLine = null;
			maxReferenceLine = null;
		} else {
			minReferenceLine = Math.min(...referenceLinePoints);
			maxReferenceLine = Math.max(...referenceLinePoints);
		}
	} else if (referenceLine?.y != null) {
		minReferenceLine = referenceLine.y;
		maxReferenceLine = referenceLine.y;
	}

	const filteredData = data.filter((report: any) => report.type === CrewReportTypes.NOON_AT_SEA);

	const allPoints = filteredData.map((d) => d[yAxis.dataKey] as unknown as number);

	const minPoints = [
		...allPoints,
		...(minReferenceLine == null ? [] : [minReferenceLine]),
	];

	const maxPoints = [
		...allPoints,
		...(maxReferenceLine == null ? [] : [maxReferenceLine]),
	];

	let minValue = minPoints.length > 0 ? Math.min(...minPoints) : 0;
	let maxValue = maxPoints.length > 0 ? Math.max(...maxPoints) : 0;

	if (minValue !== Math.floor(minValue)) {
		minValue = Math.floor(minValue);
	} else {
		minValue = Math.max(0, minValue - 1);
	}

	if (maxValue !== Math.ceil(maxValue)) {
		maxValue = Math.ceil(maxValue);
	} else {
		maxValue += 1;
	}

	const getYaxisInterval = () => {
		let minYPadding = round(((maxValue - minValue) * 0.1), 0);
		const maxYPadding = round(((maxValue - minValue) * 0.1), 0);

		if (minYPadding < 1) {
			minYPadding = 1;
		}

		if (minValue === 0) {
			minYPadding = 0;
		}

		const difference = (maxValue + maxYPadding) - (minValue - minYPadding);
		let step = round(Math.ceil(difference / (yAxisTicks ?? 1)), 0);

		if (difference / (yAxisTicks ?? 1) < 0.5) {
			step /= 2;
		}

		const start = (minValue - minYPadding) < 0 ? 0 : (minValue - minYPadding);
		const end = maxValue + 1;

		return range(start, end, step);
	};

	return (
		<div className={styles.wrapper}>
			<ResponsiveContainer
				width="100%"
				height={height ?? 200}
			>
				<ComposedChart
					data={data}
					className={styles.lineChart}
					margin={{ bottom: 25 }}
					// eslint-disable-next-line react/forbid-component-props
					style={{
						cursor: hoverReport != null ? 'pointer' : undefined,
					}}
					onClick={() => {
						if (hoverReport == null) {
							return;
						}

						openReportDrawer(hoverReport);
					}}
				>
					<XAxis
						interval={data.length > 30 ? 'preserveStartEnd' : 0}
						angle={-45}
						tick={{ dy: 15 }}
						dataKey={xAxis.dataKey}
					/>
					<YAxis
						allowDecimals
						dataKey={yAxis.dataKey}
						interval={0}
						ticks={getYaxisInterval()}
						domain={[minValue, maxValue]}
					/>
					{referenceLine != null && referenceLine.visible !== false && (
						<Line
							name={referenceLine.label ?? ''}
							dataKey={(d) => (referenceLine.y ?? d[referenceLine.dataKey])}
							stroke="black"
							dot={false}
							activeDot={false}
							isAnimationActive={false}
							type="step"
							strokeWidth={referenceLine.lineWidth ?? 1}
							strokeDasharray={(
								referenceLine!.strokeSpacing != null ?
									`${referenceLine!.strokeSpacing} ${referenceLine!.strokeSpacing}` :
									'5 5'
							)}
							connectNulls
							tooltipType="none"
						/>
					)}
					{shadedAreas?.map((area) => (
						<ReferenceArea
							x1={area.x1}
							x2={area.x2}
							isFront
							stroke="#8c8b8b"
							strokeOpacity={0.3}
							shape={(rectProps) => {
								const offset = data.length > 0 ? 300 / data.length : 0;
								const baseline = 60;
								const offsetX = rectProps.x - offset;
								const x = Math.max(offsetX, baseline);
								const amountNotMovedDueToBaseline = Math.max(baseline - offsetX, 0);
								let width = rectProps.width - amountNotMovedDueToBaseline;

								const lastPoint = data[data.length - 1];
								const lastX = typeof xAxis.dataKey === 'string' ?
									lastPoint[xAxis.dataKey] :
									xAxis.dataKey(lastPoint);

								if (rectProps.x2 === lastX) {
									width += offset;
								}

								return (
									<g>
										<rect
											{...rectProps}
											x={x}
											width={width}
										/>
									</g>
								);
							}}
						/>
					))}
					{(dataLines ?? []).map((line) => {
						const { CustomDotComponent } = line;

						return (
							<Line
								isAnimationActive={false}
								type={line.smooth ? 'monotone' : 'linear'}
								dataKey={line.dataKey}
								name={line.label}
								stroke={line.color ?? ChartColors.DARK_BLUE}
								// @ts-ignore Recharts doesn't know its own types, this works fine (:
								dot={(dotProps) => {
									if (dotProps.value == null) {
										return null;
									}

									if (dotProps.value === 'hidden') {
										return null;
									}

									if (CustomDotComponent != null) {
										return (
											<CustomDotComponent {...dotProps} />
										);
									}

									return (
										<circle
											{...dotProps}
											radius={line.dotRadius ?? 3}
											stroke={thresholds != null && checkThresholds({ value: dotProps.value, thresholds }) ? '#de0f00' : dotProps.stroke}
											fill={thresholds != null && checkThresholds({ value: dotProps.value, thresholds }) ? '#de0f00' : dotProps.fill}
										/>
									);
								}}
								activeDot={{ r: line.dotRadius ?? 3 }}
								strokeWidth={line.lineWidth ?? 1}
							/>
						);
					})}
					<Tooltip<string, string>
						content={(tooltipProps) => {
							const report = tooltipProps.payload?.[0]?.payload as (
								| (T & { isInPort: boolean })
								| undefined
							);

							if (report?.[yAxis.dataKey] == null && !report?.isInPort) {
								setHoverReport(undefined);

								return null;
							}

							setHoverReport(report);

							const newPayload = [
								...(report.isInPort ? [] : tooltipProps.payload ?? []),
								...(
									thresholds && checkThresholds({
										value: Number(report[yAxis.dataKey]),
										thresholds,
									}) ? [{
											name: 'Potential error detected',
											color: '#de0f00',
											noData: true,
										}] : []
								),
								...(extraTooltipItems?.map((tooltip) => {
									if (typeof tooltip === 'function') {
										return tooltip(report);
									}

									return {
										name: `${tooltip.label}`,
										value: tooltip.noData ?
											'' :
											report[tooltip.dataKey] != null ?
												`${report[tooltip.dataKey]}` :
												'-',
										color: tooltip.getColor != null ? tooltip.getColor(report) : tooltip.color,
										noData: tooltip.noData,
									};
								}).filter((t) => t != null) ?? []),
							];

							return (
								<DefaultTooltipContent
									{...tooltipProps}
									// @ts-ignore
									payload={newPayload}
									// @ts-ignore
									formatter={
										(
											value: string | number,
											name: string,
											payload: {
												name: string;
												value: string | number;
												color: string;
												noData: boolean;
											},
										) => [value, name + (payload.noData ? '' : ': ')]
									}
									separator=""
								/>
							);
						}}
					/>
					<Legend
						wrapperStyle={{ position: 'relative' }}
						iconType="plainline"
						content={(legendProps) => {
							const newPayload = [
								...(legendProps.payload ?? []),
								...(extraLegendItems?.map((i) => ({
									color: i.color,
									value: i.label,
									type: i.type,
									payload: {
										color: i.color,
										stroke: i.color,
										strokeWidth: 1,
										legendType: i.type,
										strokeDasharray: 0,
									},
									legendIcon: i.iconSvgElement,
								})) ?? []),
								...(
									thresholds != null &&
									allPoints.some((value) => checkThresholds({ value, thresholds })) ?
										[{
											color: '#de0f00',
											value: 'Potential error',
											type: 'circle' as LegendType,
											payload: {
												color: '#de0f00',
												stroke: '#de0f00',
												strokeWidth: 1,
												legendType: 'circle' as LegendType,
												strokeDasharray: 0,
											},
											legendIcon: (<circle cx={15} cy={14} r={10} fill="#de0f00" />),
										}] : []
								),
							];

							return (
								// @ts-ignore
								<DefaultLegendContent
									{...legendProps}
									payload={newPayload}
								/>
							);
						}}
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
};

export default PerformanceLineChart;
