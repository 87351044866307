import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import {
	Spin,
	Row,
	Col,
	Alert,
} from 'antd';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { SaveOutlined } from '@ant-design/icons';
import { asyncMap } from '@shared/utils/array';
import {
	FixtureCounterpartyTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import { formatCurrency } from '@shared/utils/currency';
import HIITotal from '@shared/hireInvoice/HIITotal';
import type { AttachmentProps } from '@api/models/attachment';
import type { HireInvoiceProps } from '@api/models/hire-invoice';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import {
	getAttachmentUrl,
	getCounterparties,
	getHireInvoiceDetails,
	updateHireInvoiceDetails,
	updateHireInvoice,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import DocumentDetails from '@client/components/DocumentDetails';
import AttachmentViewer from '@client/components/AttachmentViewer';
import { Links } from '@client/utils/links';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import asyncWrapper from '@client/utils/asyncWrapper';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import EditReceivedPopover from '@client/screens/fleet/VoyageDetailsScreen/components/EditReceivedPopover';
import Button from '@client/components/Button';
import showErrorNotification from '@client/utils/showErrorNotification';
import CreditNoteCheckbox from '@client/components/CreditNoteCheckbox';
import styles from './HireInvoiceDetailsScreen.module.css';
import { getItems } from './helpers/getDescriptionItems';

export type RowType = {
	id: number;
	type: string;
	isTotal: boolean;
	ours: number;
	item: HireInvoiceItem;
	changedItem: HireInvoiceItem;
	description: string;
	invoiceIdentifier: string;
	isGrouped: boolean | null;
	editing?: boolean;
	parentId?: number | null;
	groupedItems?: HireInvoiceItem[];
	isFixedExpandable?: boolean;
	isExpandablePayments?: boolean;
}

const HireInvoiceDetailsScreen = () => {
	const { id } = useParams<{ id: string }>();
	const [changedItems, setChangedItems] = useState<HireInvoiceItem[]>([]);
	const [generateCreditNote, setGenerateCreditNote] = useState(false);
	const [saving, setSaving] = useState(false);

	const [invoice, refreshInvoice, error, loading] = useFetchedState(
		() => getHireInvoiceDetails(Number(id)),
		[id],
		{ showNotification: false },
	);

	const invoiceItems = useMemo(() => {
		if (invoice == null || invoice.items == null) {
			return [];
		}

		return invoice.items.map((i) => HireInvoiceItem.fromJSON(i));
	}, [invoice]);

	const changedItemsById = useMemo(() => changedItems.reduce((obj, item) => ({
		...obj,
		[item.id]: item,
	}), {}), [changedItems]);

	const [charterers, _reloadCharterers, _error, _loading] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTEREROWNER),
	);

	const editValue = (
		isOwner: boolean,
		invoiceItem: HireInvoiceItem,
		field: string,
		newValue: number | string | boolean | null,
	) => {
		// If any field is edited (other than totalOverride), reset totalOverride
		if (field !== 'totalOverride') {
			editValue(isOwner, invoiceItem, 'totalOverride', null);
		}

		setChangedItems((differences) => {
			let changedItem;

			// If a difference already exists, use that one
			const existingDifference = differences.find((d) => d.id === invoiceItem.id);

			// Make a copy of the old invoice item
			if (existingDifference != null) {
				changedItem = existingDifference.copy();
			} else {
				changedItem = invoiceItem.copy();
			}

			// Changed items should not have children
			changedItem.children = [];

			// Update isOriginal
			changedItem.isOriginal = isOwner;

			// Update the field value
			changedItem[field] = newValue;

			return [
				...differences.filter((d) => d.id !== invoiceItem.id),
				changedItem,
			];
		});
	};

	const [
		openSupportingDocument,
		setOpenSupportingDocument,
	] = useState<AttachmentProps>();

	const getItemTotal = useCallback((item: HireInvoiceItem): number => {
		const changedItem = changedItemsById[item.id];

		if (changedItem == null) {
			return item.getTotal(invoiceItems);
		}

		return changedItem.getTotal(invoiceItems);
	}, [invoiceItems, changedItemsById]);

	const getDataObject = useCallback((item: HireInvoiceItem): RowType | null => {
		const isTotal = item instanceof HIITotal;

		const invoiceIdentifier = (item.isGrouped ?
			'Grouped' :
			invoice?.invoiceIdentifier ?? 'Cumulative'
		);

		const ours = getItemTotal(item);

		let changedItem = changedItemsById[item.id];

		if (changedItem == null) {
			changedItem = invoiceItems.find((i) => i.id === item.id);
		}

		return {
			item,
			changedItem,
			isTotal,
			id: item.id,
			parentId: item.parentId,
			type: item.itemTypeLabel,
			description: item.getDescription(invoiceItems),
			ours,
			invoiceIdentifier,
			isGrouped: item.isGrouped,
		};
	}, [
		invoice,
		getItemTotal,
		invoiceItems,
		changedItemsById,
	]);

	const data: RowType[] = useMemo(() => {
		const rows = invoiceItems.map((i) => getDataObject(i));

		return rows
			.filter((row): row is RowType => row != null)
			.sort((a, b) => a.id - b.id);
	}, [invoiceItems, getDataObject]);

	if (loading || invoice == null) {
		return (
			<div className={styles.loadingWrapper}>
				<Spin />
			</div>
		);
	}

	const saveChanges = async () => {
		setSaving(true);

		try {
			await updateHireInvoice(
				invoice.voyageId,
				invoice.id,
				changedItems,
				generateCreditNote,
			);
			await refreshInvoice();
		} catch (e) {
			showErrorNotification('Could not save cumulative', e as Error);
		}

		setGenerateCreditNote(false);
		setSaving(false);
	};

	const onSaveDetails = asyncWrapper(async (values: Partial<HireInvoiceProps>) => {
		await updateHireInvoiceDetails(Number(id), values);
		await refreshInvoice();
	}, 'Could not save invoice');

	const onOpenSupportingDocument = (attachment: AttachmentProps) => {
		setOpenSupportingDocument(attachment);
	};

	const onDownloadSupportingDocuments = async () => {
		const fileBlobs = await asyncMap(invoice.supportingDocuments, async (attachment) => {
			const attachmentUrl = await getAttachmentUrl(attachment.id);
			const response = await fetch(attachmentUrl);

			if (!response.ok) {
				throw new Error('Response not OK');
			}

			const blob = await response.blob();

			return { name: attachment.name, blob };
		});

		const zip = new JSZip();

		fileBlobs.forEach(({ name, blob }) => zip.file(name, blob));

		const zipContent = await zip.generateAsync({ type: 'blob' });

		saveAs(zipContent, `${invoice.invoiceIdentifier} documents.zip`);
	};

	const items = (error || charterers == null) ? [] : getItems(
		invoice,
		charterers,
		onOpenSupportingDocument,
		onDownloadSupportingDocuments,
	);

	return (
		<SimpleScreen
			error={error}
			canGoBack
			title={invoice.invoiceIdentifier}
			rootPageTitle="Voyages"
			breadcrumbs={!error ? [
				['Voyages', Links.Voyages.get()],
				[invoice.voyageIdentifier, Links.Voyage.get(invoice.voyageId)],
				['Invoices', `${Links.Voyage.get(invoice.voyageId)}/#/invoices`],
			] : undefined}
		>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card
								title={`Invoice #${id}`}
								bordered
							>
								<EditableDetails
									items={items}
									onSave={onSaveDetails}
								/>
							</Card>
						</Col>
						{invoice.hasAccountingSystem && (
							<Col span={24}>
								<Card title="Account System">
									{invoice.codatInvoice == null ? (
										<Alert
											message="Error"
											description="This invoice could not be found in the accounting system. Please contact support if you believe this is mistake"
											type="error"
											showIcon
										/>
									) : (
										<Alert
											message="Healthy"
											description="This invoice is synchronized to your accounting system"
											type="success"
											showIcon
										/>
									)}
								</Card>
							</Col>
						)}
						<Col span={24}>
							<Card
								slim
								title="Items on invoice"
								extra={(
									<Button
										icon={(<SaveOutlined />)}
										type="primary"
										disabled={saving || invoice?.fixtureType === FixtureTypes.SPOT}
										onClick={saveChanges}
										confirmTitle={(
											<CreditNoteCheckbox
												createCreditNote={generateCreditNote}
												setCreateCreditNote={setGenerateCreditNote}
											/>
										)}
									>
										Save
									</Button>
								)}
							>
								<Table
									size="small"
									dataSource={data}
									pagination={false}
									columns={[
										{
											title: 'Type',
											dataIndex: 'type',
										},
										{
											title: 'Item',
											dataIndex: 'description',
										},
										{
											align: 'right',
											title: 'Amount',
											dataIndex: 'ours',
											render: (v) => (v == null ? 0 : formatCurrency(v, invoice.fixtureCurrency)),
										},
										{
											title: '',
											dataIndex: '',
											render: (_r, row) => (
												<EditReceivedPopover
													item={row.item}
													changedItem={row.changedItem}
													onEditOurValue={(
														fieldName: string,
														newValue: number | boolean | string | null,
													) => {
														editValue(true, row.item, fieldName, newValue);
													}}
													ourTotal={row.ours}
													disabled={invoice?.fixtureType === FixtureTypes.SPOT}
													ourName="ClearVoyage"
													fixtureCurrency={invoice.currency}
												/>
											),
										},
									]}
								/>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Card
						title="Attachments"
						bordered
					>
						<DocumentDetails
							attachments={invoice.attachments}
							initialShownAttachment={invoice.attachments[0]?.id}
							autoFocusFirst
						/>
					</Card>
				</Col>
			</Row>
			<AttachmentViewer
				attachment={openSupportingDocument}
				modal
				onCloseModal={() => setOpenSupportingDocument(undefined)}
			/>
		</SimpleScreen>
	);
};

export default HireInvoiceDetailsScreen;
