export const undo = <T>(
	history: T[],
	setHistory: (newHistory: T[]) => void,
	pos: number,
	setPos: (newPos: number) => void,
	setValue: (newValue: T) => void,
): void => {
	if (pos <= 0) {
		return;
	}

	const newValueHistoryPos = pos - 1;
	setPos(newValueHistoryPos);

	const newValue = history[newValueHistoryPos];

	if (newValue != null) {
		setValue(newValue);
	}
};

export const redo = <T>(
	history: T[],
	setHistory: (newHistory: T[]) => void,
	pos: number,
	setPos: (newPos: number) => void,
	setValue: (newValue: T) => void,
): void => {
	if (pos >= history.length - 1) {
		return;
	}

	const newValueHistoryPos = pos + 1;
	setPos(newValueHistoryPos);

	const newValue = history[newValueHistoryPos];

	if (newValue != null) {
		setValue(newValue);
	}
};

export const addChange = <T>(
	history: T[],
	setHistory: (newHistory: T[]) => void,
	pos: number,
	setPos: (newPos: number) => void,
	newValue: T,
): void => {
	if (history[pos] === newValue) {
		return;
	}

	const newPos = pos + 1;
	setHistory([...history.slice(0, newPos), newValue]);
	setPos(newPos);
};
