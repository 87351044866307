import React, { useState } from 'react';
import {
	Popover,
	Tooltip,
} from 'antd';
import { Marker } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames'; import Details from '../Details';
import styles from './VesselMarker.module.css';
import getVesselMarkerDetails from './private/getVesselMarkerDetails';

const VesselMarker = ({ vessel, disablePopover }) => {
	const [popoverVisible, setPopoverVisible] = useState(false);

	const { vesselDetails, outdated } = getVesselMarkerDetails(vessel);

	return (
		(
			<Marker
				key={`marker-${vessel.name}`}
				longitude={vessel.position.longitude}
				latitude={vessel.position.latitude}
				className={outdated ? styles.outdatedShip : styles.ship}
				offsetTop={-14}
				offsetLeft={-14}
			>
				<Tooltip
					title={outdated && 'Position data is more than one day old'}
					overlayStyle={{ maxWidth: 'max-content' }}
				>
					<Popover
						open={popoverVisible}
						onOpenChange={(visible) => {
							if (!disablePopover) {
								setPopoverVisible(visible);
							}
						}}
						trigger="click"
						overlayClassName={styles}
						content={(<Details title="Vessel Details" items={vesselDetails} />)}
					>
						{/* Subtracting 45deg because the icon itself is rotated 45deg clockwise */}
						<span className="fa-stack">
							<FontAwesomeIcon
								className={classNames({ [styles.clickable]: !disablePopover })}
								size="lg"
								transform={{ rotate: vessel.position.heading - 45 }}
								icon={faLocationArrow}
							/>
						</span>
					</Popover>
				</Tooltip>
			</Marker>
		)
	);
};

export default VesselMarker;
