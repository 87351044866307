import {
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import getHireChildPnlAmountCalculator from '@shared/utils/pnlAmountCalculators/getHireChildPnlAmountCalculator';
import { formatPercentage } from '@shared/utils/formatPercentage';
import TemplateItem from '../TemplateItem';
import HIIHirePeriod from './HIIHirePeriod';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

export type HIIHireAddressCommissionType = HIIHireAddressCommission;

class HIIHireAddressCommission extends HireInvoiceItem {
	static get itemType() {
		return 'addressCommission';
	}

	percentage: number | undefined;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			percentage: number;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this.percentage = params.percentage;

		this._showTotal = true;
		this.addManually = false;
		this.itemTypeLabel = 'Address Commission';
		this.pnlGroup = PnlGroups.ADDRESS_COMMISSIONS;
		this.pnlAmountCalculator = getHireChildPnlAmountCalculator(this);

		this.templateSection = TemplateItem.Sections.COMMISSIONS;

		this._fields = {
			percentage: {
				type: HIIFieldTypes.PERCENTAGE,
				label: 'Commission percentage',
			},
		};
	}

	_calculateTotal(invoiceItems: HireInvoiceItem[]) {
		const hirePeriod = this.getParent(invoiceItems);

		if (hirePeriod == null) {
			return 0;
		}

		return -1 * hirePeriod.getTotal(invoiceItems) * (
			this.percentage != null ? (this.percentage / 100) : 1
		);
	}

	getDescription() {
		return `${formatPercentage(this.percentage ?? 0, 2)}`;
	}

	static _getClassParams(model: any, _parentModel: any) {
		return {
			percentage: model.percentage,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);

		model.percentage = this.percentage;

		await model.save();
	}

	_getTemplateItemParams(invoiceItems: HireInvoiceItem[]) {
		const hirePeriod = this.getParent(invoiceItems) as HIIHirePeriod;

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: ['Address commission', this.getDescription()],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					' — Address commission',
					`${formatPercentage(this.percentage ?? 0, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(hirePeriod.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return this.percentage === other.percentage;
	}
}

HireInvoiceItem.addItemClass(HIIHireAddressCommission);

export default HIIHireAddressCommission;
