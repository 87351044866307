import React, {
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Divider,
	Row,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMinus,
	faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { getVcPnl } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import TcInCostAllocation from '../TcInCostAllocation';
import getPnlTableColumns from './helpers/pnlTableColumns';
import styles from './helpers/styles/styles.module.css';
import getVcPnlItems from './helpers/getVcPnlItems';

export type TablePnlEntry = {
	item: string;
	actual: number;
	estimated: number;
	invoiced: number;
	bold?: boolean;
	number?: boolean;
}

const VcPnlTab = ({
	id,
	currency,
}: {
	id:number;
	currency: Values<typeof Currencies>;
}) => {
	const [pnl] = useFetchedState(async () => getVcPnl(id));
	const [activeTab, setActiveTab] = useState('pnl');
	const columns = getPnlTableColumns(currency);

	const { revenueItems, expenseItems, resultsItems } = useMemo(() => (
		pnl == null ? {
			revenueItems: [],
			expenseItems: [],
			resultsItems: [],
		} : getVcPnlItems(pnl)
	), [pnl]);

	return (
		<Row align="middle" justify="center">
			<Col xs={24} sm={24} xl={20} xxl={activeTab === 'tcIn' ? 18 : 12}>
				<Card
					bodyStyle={{ paddingTop: 0 }}
					title="P&L Sheet"
					tabList={[
						{ label: 'P&L', key: 'pnl' },
						{ label: 'TC-In Allocation', key: 'tcIn' },
					]}
					onTabChange={(key) => setActiveTab(key)}
				>
					{activeTab === 'tcIn' ? (
						<TcInCostAllocation voyageId={id} />
					) : (
						<>
							<Divider>
								<Space>
									<FontAwesomeIcon icon={faPlus as IconProp} />
									Revenue
								</Space>
							</Divider>
							<Table<TablePnlEntry>
								size="small"
								pagination={false}
								columns={columns}
								dataSource={revenueItems}
								className={styles.table}
								bordered
							/>
							<Divider>
								<Space>
									<FontAwesomeIcon icon={faMinus as IconProp} />
									Expenses
								</Space>
							</Divider>
							<Table<TablePnlEntry>
								size="small"
								pagination={false}
								columns={columns}
								dataSource={expenseItems}
								className={styles.table}
								bordered
							/>
							<Divider>Results</Divider>
							<Table<TablePnlEntry>
								size="small"
								pagination={false}
								columns={columns}
								dataSource={resultsItems}
								className={styles.table}
								bordered
							/>
						</>
					)}
				</Card>
			</Col>
		</Row>

	);
};

export default VcPnlTab;
