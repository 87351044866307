import React, { SetStateAction } from 'react';
import {
	Alert,
	Space,
} from 'antd';
import { VesselConditionTypes } from '@shared/utils/constants';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import Card from '@client/components/Card/Card';
import ConsumptionSets from '../components/ConsumptionSets/ConsumptionSets';
import styles from './styles/VesselConsumptionTab.module.css';

type Props = {
	vessel: GetVesselDetailsResponse | undefined;
	refreshVessel: () => void;
	setBlockNavigation: React.Dispatch<SetStateAction<boolean>>;
}

const VesselConsumptionTab = ({
	vessel,
	refreshVessel,
	setBlockNavigation,
}: Props) => {
	const fixtureCount = vessel?.fixtureCount ?? 0;

	return (
		<Card
			className={styles.card}
			title="Vessel Consumption"
		>
			{fixtureCount > 0 && (
				<div style={{ width: 'fit-content', paddingBottom: '5px' }}>
					<Alert
						type="warning"
						message={(
							<>
								Changes in consumption data will affect existing estimate.
							</>
						)}
					/>
				</div>
			)}
			<Space direction="vertical">
				<ConsumptionSets
					consumptionSets={vessel?.consumptionSets ?? []}
					inPortEntries={vessel?.performanceEntries
						.filter(
							(entry) => entry.condition === VesselConditionTypes.LOADING ||
							entry.condition === VesselConditionTypes.DISCHARGING ||
							entry.condition === VesselConditionTypes.IDLE,
						)}
					secondaryInPortEnabled={vessel?.enableSecondaryInPortConsumption ?? false}
					vesselId={vessel?.id}
					refreshVessel={refreshVessel}
					setBlockClose={setBlockNavigation}
				/>
			</Space>
		</Card>

	);
};

export default VesselConsumptionTab;
