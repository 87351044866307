import React, { useEffect } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import classNames from 'classnames';
import Button from '@client/components/Button';
import undo from '@client/assets/images/undo.png';
import styles from './styles/DraggableCard.module.css';
import ItemCard from './ItemCard';

export const ItemType = 'PDFItem';

const DraggableCard = ({
	id,
	added,
	children,
	enabled = true,
	onRemoveClick,
	onAddItem,
}) => {
	const [{ dragging }, dragRef, preview] = useDrag({
		item: {
			type: ItemType,
			itemId: id,
			content: children,
		},
		canDrag: () => !added && enabled,
		collect: (monitor) => ({
			dragging: monitor.isDragging(),
		}),
	});

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, [preview]);

	return (
		<div
			ref={dragRef}
			role="button"
			tabIndex={0}
			onClick={onAddItem}
			onKeyDown={(e) => e.key === 'Enter' && onAddItem()}
			className={classNames(styles.container, {
				[styles.dragging]: dragging,
				[styles.added]: added,
			})}
		>
			<div className={styles.dragBackground} />
			{added && (
				<Button
					type="primary"
					className={styles.removeButton}
					onClick={(e) => {
						e.stopPropagation();
						onRemoveClick();
					}}
				>
					<img
						alt="Undo"
						className={styles.undo}
						src={undo}
					/>
				</Button>
			)}
			<ItemCard>
				{children}
			</ItemCard>
		</div>
	);
};

export default DraggableCard;
