import { LaytimeTerms } from '@shared/utils/constants';
import type {
	CargoStats,
	CargoWithPortsAndCalculationsJSON,
} from '@api/features/laytime/getCargosAndLaytimes';
import {
	calculateAveragedLaytime,
	calculateNonReversibleLaytime,
	calculateReversibleLaytime,
} from './laytimeCalculations';

const getLaytimeStats = (cargo: CargoWithPortsAndCalculationsJSON[number]) => {
	let stats: CargoStats = {
		totalDemurrage: null,
		totalDespatch: null,
		totalReceivable: null,
		totalTimeDiff: 0,
		totalTimeLoading: null,
		totalTimeDischarging: null,
		totalDemurrageLoading: null,
		totalDemurrageDischarging: null,
		totalDespatchLoading: null,
		totalDespatchDischarging: null,
		details: [],
	};

	const { laytimeTerms } = cargo;

	switch (laytimeTerms) {
		case LaytimeTerms.REVERSIBLE:
			// @ts-ignore - these are used on the frontend and backing and typing the cargo stuff
			// is a nightmare
			stats = calculateReversibleLaytime(cargo, stats);
			break;
		case LaytimeTerms.NON_REVERSIBLE:
			// @ts-ignore
			stats = calculateNonReversibleLaytime(cargo, stats);
			break;
		case LaytimeTerms.AVERAGED:
			stats = calculateAveragedLaytime(cargo, stats);
			break;
		default:
			break;
	}

	return stats;
};

export default getLaytimeStats;
