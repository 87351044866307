// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TkYcBAIFtdLxXNHZmu1B {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 32px;\n}\n\n.XPG1Wnlhr1l6_p5SpW9z {\n\theight: 100%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/LoadingIndicator.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,aAAa;AACd;;AAEA;CACC,YAAY;AACb","sourcesContent":[".container {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 32px;\n}\n\n.fullHeight {\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TkYcBAIFtdLxXNHZmu1B",
	"fullHeight": "XPG1Wnlhr1l6_p5SpW9z"
};
export default ___CSS_LOADER_EXPORT___;
