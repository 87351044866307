import React from 'react';
import {
	faGrid,
	faMap,
	faTableLayout,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Card,
	Flex,
} from 'antd';
import classNames from 'classnames';
import { EstimatorModes } from '../helpers/types';
import styles from './styles/ModePicker.module.css';

const ModePicker = ({
	mode,
	setMode,
}: {
	mode: EstimatorModes;
	setMode: (newMode: EstimatorModes) => void;
}) => {
	const ModeButton = ({ buttonMode }: {buttonMode: EstimatorModes}) => {
		let icon = faTableLayout;

		if (buttonMode === 'grid') {
			icon = faGrid;
		} else if (buttonMode === 'map') {
			icon = faMap;
		}

		return (
			<FontAwesomeIcon
				className={classNames(styles.modeButton, {
					[styles.active]: mode === buttonMode,
				})}
				onClick={() => setMode(buttonMode)}
				fontSize={18}
				icon={icon}
			/>
		);
	};

	return (
		<Card className={styles.card}>
			<Flex className={styles.fullHeight} align="center" justify="space-between">
				<ModeButton buttonMode="normal" />
				<ModeButton buttonMode="grid" />
				<ModeButton buttonMode="map" />
			</Flex>
		</Card>
	);
};

export default ModePicker;
