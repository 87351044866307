// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I5knvWjGVipabzLMSG0j {\n\tposition: relative;\n}\n\n.UGqwEllQlJcRPkuTkxVD {\n\tmax-width: 800px;\n\tmargin: 0 auto;\n}\n\n.ByUaYVebx6SVlJl5uBN8 {\n\tposition: absolute;\n\tleft: 0;\n\tright: 0;\n\tbackground-color: #a5292a;\n\theight: 1px;\n\ttop: 50%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/DocumentDetails.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,cAAc;AACf;;AAEA;CACC,kBAAkB;CAClB,OAAO;CACP,QAAQ;CACR,yBAAyB;CACzB,WAAW;CACX,QAAQ;AACT","sourcesContent":[".tab {\n\tposition: relative;\n}\n\n.attachmentWrapper {\n\tmax-width: 800px;\n\tmargin: 0 auto;\n}\n\n.cancelledLine {\n\tposition: absolute;\n\tleft: 0;\n\tright: 0;\n\tbackground-color: #a5292a;\n\theight: 1px;\n\ttop: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": "I5knvWjGVipabzLMSG0j",
	"attachmentWrapper": "UGqwEllQlJcRPkuTkxVD",
	"cancelledLine": "ByUaYVebx6SVlJl5uBN8"
};
export default ___CSS_LOADER_EXPORT___;
