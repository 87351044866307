// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KI8WwDdCw7Qdw3H7SvFl {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/settings/UserSettingsModal/components/styles/SettingEntry.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;AACpB","sourcesContent":[".setting {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setting": "KI8WwDdCw7Qdw3H7SvFl"
};
export default ___CSS_LOADER_EXPORT___;
