import type { GetRobsResponse } from '@api/features/vessels/getRobs';
import type { ReturnStem } from '@api/features/voyages/bunker-stems/getBunkerStems';

export const transformRobToBunkerStem = (rob: GetRobsResponse['robs'][number]) => {
	if (rob.bunkerStemId != null) {
		return {
			id: rob.bunkerStemId!,
			voyageId: rob.voyageId!,
			supplierId: rob.BunkerStem.VoyageExpense.supplierId!,
			portCallId: rob.BunkerStem.VoyageExpense.portCallId!,
			invoiceDate: rob.BunkerStem.VoyageExpense.invoiceDate,
			stemDate: rob.BunkerStem.stemDate,
			robs: rob.RobBunkers.map((rb) => ({
				fuelGrade: rb.fuelGrade,
				quantity: rb.totalQuantity,
				pricePerTon: 0,
				id: rb.id,
			})),
			parcels: rob.BunkerStem.VoyageBunkers.map((vb) => ({
				...vb,
				...vb.Bunker,
				voyageBunkerId: vb.id,
				account: vb.tcInId != null ? vb.tcInId : 'owner',
				currency: vb.Bunker.currency,
				isInvoiced: vb.HIIBunker != null,
			})),
			currency: rob.BunkerStem.VoyageExpense.currency,
			exchangeRate: rob.BunkerStem.VoyageExpense.exchangeRate,
			attachments: rob.BunkerStem.VoyageExpense.Attachments,
		} as ReturnStem;
	}

	return null;
};
