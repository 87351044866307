import React, { useMemo } from 'react';
import {
	Col,
	Row,
	Flex,
	Grid,
} from 'antd';
import classNames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import formatTcAndBbFixtureDuration from '@shared/utils/formatTcAndBbFixtureDuration';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getPorts,
	getVoyages,
	updateVoyage,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import LinkedVoyages from '@client/screens/fleet/VoyageDetailsScreen/components/LinkedVoyages/LinkedVoyages';
import Card from '@client/components/Card/Card';
import Details from '@client/components/Details';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';
import DatePicker from '@client/components/DatePicker';
import Select from '@client/components/Select';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import TooltipIcon from '@client/components/TooltipIcon';
import styles from './styles/SummaryTab.module.css';
import EstimatedDates from './EstimatedDates/EstimatedDates';

const TcSummaryTab = ({
	voyageDetails,
	fixtureDetails,
	refreshVoyageDetails,
	refreshFixtureDetails,
}: {
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps | SpotFixtureProps>;
	voyageDetails: GetVoyageDetailsResponse;
	refreshVoyageDetails: () => Promise<void> | void;
	refreshFixtureDetails: () => void;
}) => {
	const {
		xxl,
	} = Grid.useBreakpoint();

	const [ports] = useFetchedState(getPorts);
	const portOptions = getPortOptions(ports ?? []);

	const [voyages] = useFetchedState(getVoyages);

	const filteredVoyages = useMemo(() => {
		if (voyages == null) {
			return [];
		}

		return voyages.filter((v) => (
			v.vesselId === voyageDetails.vesselId &&
			v.id !== voyageDetails.id
		));
	}, [voyageDetails, voyages]);

	const updateVoyageField = async (field: string, value: any, contractId?: number | null) => {
		try {
			await updateVoyage(contractId ?? voyageDetails.id, { [field]: value });
			await refreshVoyageDetails();
			refreshFixtureDetails();
			showSuccessNotification('Contract updated');
		} catch (e) {
			if (value !== undefined) {
				showErrorNotification('Could not update contract', e as Error);
			}
		}
	};

	const setAlert = () => {
		if (voyageDetails.id != null && fixtureDetails.type !== FixtureTypes.SPOT) {
			if (!voyageDetails.deliveryDate && !voyageDetails.deliveryPort) {
				return 'Missing delivery port and date';
			}

			if (!voyageDetails.deliveryDate) {
				return 'Missing delivery date';
			}

			if (!voyageDetails.deliveryPort) {
				return 'Missing delivery port';
			}
		}

		return false;
	};

	const isAlert = setAlert();
	const alert = isAlert && (
		<span>{isAlert}</span>
	);

	if (voyageDetails == null) {
		return null;
	}

	const getDeliveryRedeliveryItems = () => ([
		{
			key: '1',
			label: (
				<Flex>
					<span>Delivery date</span>
					{alert && (
						<TooltipIcon
							Icon={ExclamationCircleOutlined}
						>
							{alert}
						</TooltipIcon>
					)}
				</Flex>
			),
			value: (
				<DatePicker
					value={voyageDetails?.deliveryDate}
					onChange={(date) => updateVoyageField('deliveryDate', date)}
					time
				/>
			),
			style: {
				width: 210,
			},
		},
		{
			key: '2',
			label: 'Redelivery date',
			value: (
				<DatePicker
					value={voyageDetails?.completionDate}
					onChange={(date) => updateVoyageField('completionDate', date)}
					time
				/>
			),
			style: {
				width: 210,
			},
		},
		{
			key: '3',
			label: 'Delivery port',
			value: (
				<Select
					value={voyageDetails?.deliveryPort?.id}
					onChange={(value) => updateVoyageField('deliveryPort', value)}
					options={portOptions}
					showSearch
					allowClear
					onClear={() => updateVoyageField('deliveryPort', null)}
				/>
			),
			style: {
				width: 210,
			},
		},
		{
			key: '4',
			label: 'Redelivery port',
			value: (
				<Select
					value={voyageDetails?.completionPort?.id}
					onChange={(value) => updateVoyageField('completionPort', value)}
					options={portOptions}
					showSearch
					allowClear
					onClear={() => updateVoyageField('completionPort', null)}
				/>
			),
			style: {
				width: 210,
			},
		},
	]);

	const getOtherItems = () => ([
		{
			type: 'text',
			key: '1',
			label: 'Duration',
			value: formatTcAndBbFixtureDuration(fixtureDetails as TcFixtureProps),
			style: {
				width: 210,
			},
		},
		{
			key: '2',
			label: 'Charterer',
			value: (
				<>
					{FixtureTypeLabels[fixtureDetails.type]}
					{fixtureDetails.type === FixtureTypes.TC_IN ? ' from ' : ' to '}
					{fixtureDetails.counterpartyName}
				</>
			),
			style: {
				width: 210,
			},
		},
	]);

	return (
		<Row gutter={[16, 16]}>
			<Col xxl={8} lg={24}>
				<Card
					className={classNames([styles.smallerCard, !xxl && styles.linkedVoyageCard])}
				>
					<LinkedVoyages
						voyageDetails={voyageDetails}
						refreshVoyageDetails={refreshVoyageDetails}
						voyages={filteredVoyages}
						ownershipPeriods={voyageDetails.ownershipPeriods}
						isTcIn={voyageDetails.fixture.type === FixtureTypes.TC_IN}
					/>
				</Card>
			</Col>
			<Col xxl={8} lg={24}>
				<Card className={classNames([styles.smallerCard, styles.estimatedDatesCard])}>
					<Flex vertical gap={10}>
						<EstimatedDates
							voyageDetails={voyageDetails}
							updateVoyageField={updateVoyageField}
							portOptions={portOptions}
						/>
						<Details
							className={styles.details}
							column={4}
							labelWidth={220}
							title={null}
							items={getDeliveryRedeliveryItems()}
						/>
						<Details
							className={styles.details}
							column={4}
							title={null}
							labelWidth={220}
							items={getOtherItems()}
						/>
					</Flex>
				</Card>
			</Col>
		</Row>
	);
};

export default TcSummaryTab;
