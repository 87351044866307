import React from 'react';
import VesselMarker from '@client/components/Map/VesselMarker';
import { getVesselsWithPositions } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';

export type VesselsLayerProps = {
	vesselIdsToShow?: number[];
}

const VesselsLayer: React.FC<VesselsLayerProps> = ({
	vesselIdsToShow,
}) => {
	const [vessels] = useFetchedState(getVesselsWithPositions);

	return (
		<>
			{
				(vessels ?? [])
					.filter((v) => vesselIdsToShow === undefined || vesselIdsToShow.includes(v.id))
					.map((vessel) => (
						<VesselMarker
							key={vessel.name}
							vessel={vessel}
							disablePopover={false}
						/>
					))
			}
		</>
	);
};

export default VesselsLayer;
