import React, { useMemo } from 'react';
import type { GetConnectionAndMappingResponse } from '@api/features/accounting-system/getConnectionAndMapping';
import type { GetSuppliersResponse } from '@api/features/suppliers/getSuppliers';
import EditableTable from '@client/components/EditableTable/EditableTable';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	createMappingEntry,
	getAccountingSuppliers,
	getSuppliers,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';

const CounterpartyMappingTab = ({
	connection,
	refreshConnection,
}: {
	connection: GetConnectionAndMappingResponse | undefined;
	refreshConnection: () => void;
}) => {
	const [suppliers, refreshSuppliers] = useFetchedState(getSuppliers);
	const [vendors] = useFetchedState(async () => (
		connection == null ? [] : getAccountingSuppliers(connection.id)
	), [connection]);

	const onSave = async (
		supplierId: number | undefined,
		update: Partial<GetSuppliersResponse[number] & {accountingCode: string}>,
	) => {
		const { accountingCode } = update;

		if (accountingCode != null && supplierId != null && connection != null) {
			try {
				await createMappingEntry(connection.id, 'supplier', supplierId, accountingCode);
				await refreshConnection();
				showSuccessNotification('Successfully mapped entry');
			} catch (e) {
				showErrorNotification('Could not create mapping', e as Error);
			}
		}

		refreshSuppliers();
	};

	const suppliersList = useMemo(() => {
		if (
			suppliers == null ||
			connection == null
		) {
			return [];
		}

		const formatted = suppliers.map((s) => {
			const mappingEntry = connection.AccountingMappingEntries.find((mapping) => (
				mapping.type === 'supplier' &&
				mapping.cvIdentifier === String(s.id)
			));

			return {
				...s,
				accountingCode: mappingEntry?.code ?? undefined,
			};
		});

		return formatted;
	}, [suppliers, connection]);

	return (
		<Card slim>
			<EditableTable
				actionsTitle=""
				iconButtons
				pagination={false}
				keyDataIndex="id"
				onSave={onSave}
				dataSource={suppliersList}
				columns={[
					{
						dataIndex: 'id',
						title: 'ID',
					},
					{
						dataIndex: 'name',
						title: 'Name',
					},
					{
						dataIndex: 'accountingCode',
						title: 'Accounting System Entry',
						editingProps: {
							type: 'select',
							options: (vendors ?? []).map((c) => ({
								label: c.name,
								value: c.id,
							})),

							inputProps: {
								placeholder: 'Select supplier',
								showSearch: true,
							},
						},
						editable: true,
						render: (v: string | null) => (
							v == null ? (<i>Empty</i>) : vendors?.find((c) => c.id === v)?.name
						),
					},
				]}
			/>
		</Card>
	);
};

export default CounterpartyMappingTab;
