import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import TemplateItem from '@shared/TemplateItem';
import getFirstNotNull from '@shared/utils/getFirstNotNull';
import type { HIIRevenueItemType } from '@api/models/hii-revenue-item';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

class HIIRevenueItem extends HireInvoiceItem {
	static get itemType() {
		return 'revenueItem';
	}

	revenueItemId: number;
	cargoId: number;
	voyageId: number;
	itemDescription: string;
	amount: number;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	note?: string | null;
	chartererId: number;
	subjectToCommissions: boolean;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			revenueItemId: number;
			cargoId: number;
			voyageId: number;
			itemDescription: string;
			amount: number;
			currency: Values<typeof Currencies>;
			exchangeRate: number;
			note?: string | null;
			chartererId: number;
			subjectToCommissions: boolean;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });
		this.cargoId = params.cargoId;
		this.revenueItemId = params.revenueItemId;
		this.voyageId = params.voyageId;
		this.itemDescription = params.itemDescription;
		this.amount = params.amount;
		this.currency = params.currency;
		this.exchangeRate = params.exchangeRate;
		this.chartererId = params.chartererId;
		this.subjectToCommissions = params.subjectToCommissions;
		this.note = params.note;

		this._showTotal = true;
		this.itemTypeLabel = 'Revenue';
		this.templateSection = TemplateItem.Sections.REVENUES;
		this.addManually = true;
		this.pnlGroup = PnlGroups.OTHER_REVENUE;
	}

	async saveModel(model: HIIRevenueItemType) {
		await super.saveModel(model);

		model.cargoId = this.cargoId;
		model.revenueItemId = this.revenueItemId;
		model.amountOverride = this.amount;
		model.currencyOverride = this.currency;
		model.exchangeRateOverride = this.exchangeRate;

		await model.save();
	}

	static async _getClassParams(model: HIIRevenueItemType, _parentModel: typeof HireInvoiceItem) {
		const revenueItem = await model.getRevenueItem();
		const cargo = await model.getCargo();

		return {
			cargoId: cargo.id,
			revenueItemId: revenueItem.id,
			amount: getFirstNotNull(model.amountOverride, revenueItem.amount),
			itemDescription: revenueItem.itemDescription,
			currency: revenueItem.currency,
			exchangeRate: getFirstNotNull(
				model.exchangeRateOverride, revenueItem.exchangeRate,
			),
			subjectToCommissions: revenueItem.subjectToCommissions,
		};
	}

	_calculateTotal(_invoiceItems?: HireInvoiceItem[], convertCurrency?: boolean) {
		let total = this.amount;

		if (convertCurrency && this.fixtureCurrency !== this.currency) {
			total = this.amount / this.exchangeRate;
		}

		return total;
	}

	getDescription() {
		return `${this.itemDescription}`;
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.itemDescription,
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					this.itemDescription,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIRevenueItem);

export default HIIRevenueItem;

