import distance from '@turf/distance';
import uuid from 'short-uuid';
import { Moment } from 'moment';
import { Values } from '@shared/utils/objectEnums';
import { CrewReportTypes } from '@shared/utils/constants';

export type Report = {
	id?: number | string;
	type?: Values<typeof CrewReportTypes>;
	latitude: number;
	longitude: number;
	date: Moment | string;
	groupId?: undefined;
};

type Grouped<T extends Report> = T & { isGroup: false; groupId?: string } | {
	latitude: number;
	longitude: number;
	isGroup: true;
	reports: Report[];
	groupId?: string;
};

const GROUP_RADIUS = 10;

export const groupReports = <T extends Report>(reportsToGroup: T[]): Grouped<T>[] => {
	let ungroupedReports = [...reportsToGroup];
	const groupedReports: Grouped<T>[] = [];

	while (ungroupedReports.length > 0) {
		let highestNeighborCountReport: T | null = null;
		let highestNeighborCountCloseReports: T[] = [];

		for (let i = 0; i < ungroupedReports.length; i++) {
			let neighborCount = 0;
			const closeReports = [];

			for (let j = 0; j < ungroupedReports.length; j++) {
				const dist = distance(
					[ungroupedReports[i].longitude, ungroupedReports[i].latitude],
					[ungroupedReports[j].longitude, ungroupedReports[j].latitude],
				);

				if (i !== j && dist <= GROUP_RADIUS) {
					neighborCount += 1;
					closeReports.push(ungroupedReports[j]);
				}
			}

			if (neighborCount > highestNeighborCountCloseReports.length || highestNeighborCountReport == null) {
				highestNeighborCountReport = ungroupedReports[i];
				highestNeighborCountCloseReports = closeReports;
			}
		}

		if (highestNeighborCountReport == null) {
			throw new Error('highestNeighborCountReport is null');
		}

		if (highestNeighborCountCloseReports.length === 0) {
			groupedReports.push({ ...highestNeighborCountReport, isGroup: false });
		} else {
			const groupId = uuid.generate();
			const childReports = [highestNeighborCountReport, ...highestNeighborCountCloseReports]
				.map((r) => ({ ...r, groupId }))
				// @ts-ignore
				.sort((a, b) => new Date(a.date) - new Date(b.date));
			groupedReports.push({
				latitude: highestNeighborCountReport.latitude,
				longitude: highestNeighborCountReport.longitude,
				reports: childReports,
				isGroup: true,
			});
		}

		// Remove the newly grouped reports from the list of ungrouped reports
		ungroupedReports = ungroupedReports.filter((r) => (
			r !== highestNeighborCountReport &&
			!highestNeighborCountCloseReports.includes(r)
		));
	}

	return groupedReports;
};
