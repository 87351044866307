import React from 'react';
import {
	Space,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { capitalize } from '@shared/utils/string';
import {
	ConsumptionRelationTypes,
	FuelTypes,
	FuelZoneTypes,

	VesselConditionTypes,
} from '@shared/utils/constants';
import type { PerformanceEntryProps } from '@api/models/performance-entry';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import Button from '@client/components/Button';
import { SimplePerformance } from './ConsumptionSets';

const CONS_FIELD_WIDTH = 125;

export const SIMPLE_COLUMNS_AT_SEA = [
	{
		dataIndex: 'fuelZoneType',
		render: (c: string) => capitalize(c),
	},
	{
		title: 'Fuel type',
		dataIndex: 'fuelType',
	},
	{
		title: 'Ballast',
		dataIndex: 'ballast',
	},
	{
		title: 'Laden',
		dataIndex: 'laden',
	},
];

export const SIMPLE_COLUMNS_IN_PORT = [
	{
		dataIndex: 'fuelZoneType',
		render: (c: string) => capitalize(c),
	},
	{
		title: 'Fuel type',
		dataIndex: 'fuelType',
	},
	{
		title: 'loading',
		dataIndex: 'loading',
	},
	{
		title: 'discharging',
		dataIndex: 'discharging',
	},
	{
		title: 'idle',
		dataIndex: 'idle',
	},
];

export const getColumns = (
	copyValues: (record: SimplePerformance, inPort: boolean) => void,
	disabled: boolean = false,
	inPort: boolean = false,
): EditableColumns<SimplePerformance> => {
	const baseColumns = [
		{
			editable: false,
			type: 'text',
			dataIndex: 'fuelZoneType',
			width: CONS_FIELD_WIDTH + 20,
			render: (c: SimplePerformance) => {
				const label = capitalize(c.fuelZoneType);

				if (c.fuelZoneType === FuelZoneTypes.ECA) {
					return (
						<Space>
							<Typography.Text>{label}</Typography.Text>
							<Button
								disabled={disabled}
								onClick={() => copyValues(c, inPort)}
								size="small"
								type="primary"
								icon={(<FontAwesomeIcon icon={faCopy} />)}
							/>
						</Space>
					);
				}

				return label;
			},
		},
		{
			editable: true,
			title: 'Fuel type',
			type: 'select',
			width: CONS_FIELD_WIDTH,
			inputProps: {
				disabled,
				options: Object.values(FuelTypes).map((f) => ({ label: f, value: f })),
			},
			dataIndex: 'fuelType',
		},
	] as const;

	const inPortColumns = [
		{
			editable: true,
			title: 'Loading',
			width: CONS_FIELD_WIDTH,
			dataIndex: 'loading',
			type: 'number',
			inputProps: {
				disabled,
				addonAfter: 'MT',
				asAsync: false,
			},
		},
		{
			editable: true,
			title: 'Discharging',
			width: CONS_FIELD_WIDTH,
			dataIndex: 'discharging',
			type: 'number',
			inputProps: {
				disabled,
				addonAfter: 'MT',
				asAsync: false,
			},
		},
		{
			editable: true,
			title: 'Idle',
			width: CONS_FIELD_WIDTH,
			dataIndex: 'idle',
			type: 'number',
			inputProps: {
				disabled,
				addonAfter: 'MT',
				asAsync: false,
			},
		},
	] as const;

	const atSeaColumns = [
		{
			editable: true,
			title: 'Ballast',
			width: CONS_FIELD_WIDTH,
			dataIndex: 'ballast',
			type: 'number',
			inputProps: {
				disabled,
				addonAfter: 'MT',
				asAsync: false,
			},
		},
		{
			editable: true,
			title: 'Laden',
			width: CONS_FIELD_WIDTH,
			dataIndex: 'laden',
			type: 'number',
			inputProps: {
				disabled,
				addonAfter: 'MT',
				asAsync: false,
			},
		},
	] as const;

	return [...baseColumns, ...(inPort ? inPortColumns : atSeaColumns)];
};

export const getAtSeaEntries = (
	relevantEntry: ConsumptionSetProps & {
		PerformanceEntries: PerformanceEntryProps[];
	} | undefined | null,
) => {
	if (relevantEntry == null) {
		return {
			main: [],
			secondary: [],
		};
	}

	return {
		main: getTransformedRows(relevantEntry.PerformanceEntries
			.filter((c) => c.relation === ConsumptionRelationTypes.MAIN)
			.sort((a, b) => b.fuelZoneType.localeCompare(a.fuelZoneType))),
		secondary: getTransformedRows(relevantEntry.PerformanceEntries
			.filter((c) => c.relation === ConsumptionRelationTypes.SECONDARY)
			.sort((a, b) => b.fuelZoneType.localeCompare(a.fuelZoneType))),
	};
};

export const getInPortEntries = (entries: Array<PerformanceEntryProps>) => {
	if (entries == null) {
		return {
			main: [],
			secondary: [],
		};
	}

	return {
		main: getTransformedInPortRows(entries
			.filter((c) => c.relation === ConsumptionRelationTypes.MAIN)
			.sort((a, b) => b.fuelZoneType.localeCompare(a.fuelZoneType))),
		secondary: getTransformedInPortRows(entries
			.filter((c) => c.relation === ConsumptionRelationTypes.SECONDARY)
			.sort((a, b) => b.fuelZoneType.localeCompare(a.fuelZoneType))),
	};
};

export const getTransformedRows = (data: Array<PerformanceEntryProps>) => {
	const ballastNormal = data.find((f) => f.condition === VesselConditionTypes.BALLAST &&
		f.fuelZoneType === FuelZoneTypes.NORMAL);

	const ladenNormal = data.find((f) => f.condition === VesselConditionTypes.LADEN &&
		f.fuelZoneType === FuelZoneTypes.NORMAL);

	const ballastEca = data.find((f) => f.condition === VesselConditionTypes.BALLAST &&
		f.fuelZoneType === FuelZoneTypes.ECA);

	const ladenEca = data.find((f) => f.condition === VesselConditionTypes.LADEN &&
		f.fuelZoneType === FuelZoneTypes.ECA);

	if (ballastNormal == null || ladenNormal == null || ballastEca == null || ladenEca == null) {
		return [];
	}

	return ([
		{
			ballast: ballastNormal.consumption,
			laden: ladenNormal.consumption,
			fuelType: ballastNormal.fuelType,
			fuelZoneType: ballastNormal.fuelZoneType,
			ballastId: ballastNormal.id,
			ladenId: ladenNormal.id,
			consumptionSetId: ballastNormal.consumptionSetId,
			relation: ballastNormal.relation,
		},
		{
			ballast: ballastEca.consumption,
			laden: ladenEca.consumption,
			fuelType: ballastEca.fuelType,
			fuelZoneType: ballastEca.fuelZoneType,
			ballastId: ballastEca.id,
			ladenId: ladenEca.id,
			consumptionSetId: ballastEca.consumptionSetId,
			relation: ballastEca.relation,
		},
	]);
};

export const getTransformedInPortRows = (data: Array<PerformanceEntryProps>) => {
	const loadingNormal = data.find((f) => f.condition === VesselConditionTypes.LOADING &&
		f.fuelZoneType === FuelZoneTypes.NORMAL);

	const dischargingNormal = data.find((f) => f.condition === VesselConditionTypes.DISCHARGING &&
		f.fuelZoneType === FuelZoneTypes.NORMAL);

	const idleNormal = data.find((f) => f.condition === VesselConditionTypes.IDLE &&
		f.fuelZoneType === FuelZoneTypes.NORMAL);

	const loadingEca = data.find((f) => f.condition === VesselConditionTypes.LOADING &&
		f.fuelZoneType === FuelZoneTypes.ECA);

	const dischargingEca = data.find((f) => f.condition === VesselConditionTypes.DISCHARGING &&
		f.fuelZoneType === FuelZoneTypes.ECA);

	const idleEca = data.find((f) => f.condition === VesselConditionTypes.IDLE &&
		f.fuelZoneType === FuelZoneTypes.ECA);

	if (
		loadingNormal == null ||
		dischargingNormal == null ||
		idleNormal == null ||
		loadingEca == null ||
		dischargingEca == null ||
		idleEca == null
	) {
		return [];
	}

	return ([
		{
			loading: loadingNormal.consumption,
			discharging: dischargingNormal.consumption,
			idle: idleNormal.consumption,
			fuelType: loadingNormal.fuelType,
			fuelZoneType: loadingNormal.fuelZoneType,
			loadingId: loadingNormal.id,
			dischargingId: dischargingNormal.id,
			idleId: idleNormal.id,
			relation: loadingNormal.relation,
		},
		{
			loading: loadingEca.consumption,
			discharging: dischargingEca.consumption,
			idle: idleEca.consumption,
			fuelType: loadingEca.fuelType,
			fuelZoneType: loadingEca.fuelZoneType,
			loadingId: loadingEca.id,
			dischargingId: dischargingEca.id,
			idleId: idleEca.id,
			relation: loadingEca.relation,
		},
	]);
};

