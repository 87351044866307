import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import {
	DATE_AND_TIME,
	FixtureTypes,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import { fixtureTypeToName } from '@shared/utils/fixtureUtils';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import type { VesselOwnershipPeriodProps } from '@api/models/vessel-ownership-period';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import { formatDate } from '@client/utils/formatDate';
import { Links } from '@client/utils/links';
import VoyageSelector from '@client/screens/fleet/VoyageDetailsScreen/components/VoyageSelector/VoyageSelector';
import EmptyText from '@client/components/EmptyText';
import { updateVoyage } from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import Button from '@client/components/Button';
import styles from './LinkedVoyages.module.css';

type Props = {
	voyageDetails: GetVoyageDetailsResponse;
	refreshVoyageDetails: () => Promise<void> | void;
	voyages: GetVoyagesResponse;
	ownershipPeriods: Array<VesselOwnershipPeriodProps>;
	isTcIn?: boolean;
}

const LinkedVoyages = ({
	voyageDetails,
	refreshVoyageDetails,
	voyages,
	ownershipPeriods,
	isTcIn,
}: Props) => {
	const [
		pendingLinkingChanges,
		setPendingLinkingChanges,
	] = useState<{[s: string]: number | null} | null>();

	const handleSaveLinking = useCallback(async (override?: {[field: string]: number | null}) => {
		if (pendingLinkingChanges == null && override == null) {
			return;
		}

		let values = pendingLinkingChanges;

		if (override != null) {
			values = override;
		}

		if (values == null) {
			return;
		}

		try {
			await updateVoyage(voyageDetails.id, values);
			await refreshVoyageDetails();
		} catch (e) {
			showErrorNotification('Could not update linked contracts', e as Error);
		}
	}, [pendingLinkingChanges, refreshVoyageDetails, voyageDetails.id]);

	const items = useMemo(() => {
		return (
			[
				...(!isTcIn ?
					[{
						key: '0',
						label: 'Previous contract',
						value: voyageDetails.linkedTcInVoyageId === voyageDetails.previousVoyageId ?
							voyageDetails.linkedTcInVoyageId :
							voyageDetails.previousVoyageId,
						type: 'select',
						options: [],
						editable: true,
						render: () => {
							const id = voyageDetails.linkedTcInVoyageId === voyageDetails.previousVoyageId ?
								voyageDetails.linkedTcInVoyageId :
								voyageDetails.previousVoyageId;

							if (id === -1) {
								return (
									<div className={styles.contractLink}>
										<div className={styles.ownershipPeriod}>
											<p>Start of vessel ownership period:</p>
											<b>{formatDate(voyageDetails.commencementDate, DATE_AND_TIME)}</b>
										</div>
										<Button
											type="link"
											className={styles.unlinkBtn}
											confirmTitle="Are you sure you want to unlink this contract?"
											onClick={async () => {
												await handleSaveLinking({
													'previousVoyageId': null,
												});
											}}
										>
											Unlink
										</Button>
									</div>
								);
							}

							const selectedVoyage = voyages.find((v) => v.id === id);

							if (id == null || selectedVoyage == null) {
								return (<EmptyText />);
							}

							return (
								<div className={styles.contractLink}>
									<div className={styles.contractIdentifierAndType}>
										<Link to={Links.Voyage.get(id)}>
											{selectedVoyage.identifier}
										</Link>
										{selectedVoyage.fixtureType != null && (
											<Typography.Text
												italic
											>
												{fixtureTypeToName(selectedVoyage.fixtureType ?? null)}
											</Typography.Text>
										)}
									</div>
									<Button
										type="link"
										className={styles.unlinkBtn}
										confirmTitle="Are you sure you want to unlink this contract?"
										onClick={async () => {
											await handleSaveLinking({
												'previousVoyageId': null,
											});
										}}
									>
										Unlink
									</Button>
								</div>

							);
						},
						renderInput: () => (
							<VoyageSelector
								type="previous"
								allowClear
								voyages={voyages.filter((v) => v.id !== voyageDetails.nextVoyageId)}
								startOfVesselOwnershipPeriod={
									ownershipPeriods[0]?.fromDate != null ?
										toMoment(ownershipPeriods[0].fromDate) : null
								}
								defaultValue={voyageDetails.linkedTcInVoyageId === voyageDetails.previousVoyageId ?
									voyageDetails.linkedTcInVoyageId :
									voyageDetails.previousVoyageId}
								onSelect={(v) => {
									if (v === -1) {
										setPendingLinkingChanges((prev) => ({
											...prev,
											previousVoyageId: -1,
										}));

										return;
									}

									const selectedVoyage = voyages.find((vo) => vo.id === v);

									if (v != null && selectedVoyage == null) {
										return;
									}

									let attributes = {};

									if (
										selectedVoyage?.fixtureType !== FixtureTypes.TC_IN &&
										!voyageDetails.isFirstContract
									) {
										attributes = {
											previousVoyageId: v ?? null,
										};
									} else {
										attributes = {
											linkedTcInVoyageId: v ?? null,
											previousVoyageId: v ?? null,
										};
									}

									setPendingLinkingChanges((prev) => ({
										...prev,
										...attributes,
									}));
								}}
							/>
						),
					}] :
					[]),
				{
					key: '1',
					label: 'Next contract',
					value: voyageDetails?.nextVoyage?.id ?? null,
					type: 'select',
					options: [],
					editable: true,
					render: () => {
						if (voyageDetails.nextVoyageId == null) {
							return (<EmptyText />);
						}

						return (
							<div className={styles.contractLink}>
								<div className={styles.contractIdentifierAndType}>
									<Link to={Links.Voyage.get(voyageDetails.nextVoyageId)}>
										{voyageDetails.nextVoyage?.identifier}
									</Link>
									{voyageDetails?.nextVoyage?.fixture?.type != null && (
										<Typography.Text
											italic
										>
											{fixtureTypeToName(voyageDetails?.nextVoyage?.fixture?.type ?? null)}
										</Typography.Text>
									)}
								</div>
								<Button
									type="link"
									className={styles.unlinkBtn}
									confirmTitle="Are you sure you want to unlink this contract?"
									onClick={async () => {
										await handleSaveLinking({
											'nextVoyageId': null,
										});
									}}
								>
									Unlink
								</Button>
							</div>
						);
					},
					renderInput: () => (
						<VoyageSelector
							allowClear
							voyages={voyages
								.filter((voyage) => voyage.fixtureType !== FixtureTypes.TC_IN &&
									voyage.id !== voyageDetails.previousVoyageId)}
							defaultValue={voyageDetails.nextVoyageId}
							onSelect={(v) => {
								setPendingLinkingChanges((prev) => ({
									...prev,
									nextVoyageId: v ?? null,
								}));
							}}
							type="next"
						/>
					),
				},
			]
		);
	}, [isTcIn, ownershipPeriods, voyages, voyageDetails, handleSaveLinking]);

	if (ownershipPeriods == null) {
		return null;
	}

	return (
		<EditableDetails
			className={styles.details}
			labelWidth={175}
			title="Linked contracts"
			onSave={() => handleSaveLinking()}
			onCancel={() => {
				setPendingLinkingChanges(null);
			}}
			// @ts-ignore we're doing nothing wrong
			items={items}
		/>
	);
};

export default LinkedVoyages;
