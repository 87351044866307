import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
	Badge,
	Space,
	Switch,
	Typography,
} from 'antd';
import { Moment } from 'moment';
import {
	Currencies,
	DATE_AND_TIME,
	VesselOwnershipTypeLabels,
	VesselOwnershipTypes,
	VesselTypeLabels,
	VesselTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatNumber } from '@shared/utils/formatNumber';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { GetUserDataResponse } from '@api/features/user/getUserData';
import type { BankAccountProps } from '@api/models/bank-account';
import { formatDate } from '@client/utils/formatDate';
import { renderDate } from '@client/utils/table';
import TooltipIcon from '@client/components/TooltipIcon';
import NumericInput from '@client/components/NumericInput';
import CountryFlag from '@client/components/CountryFlag';
import FlagSelector from '@client/components/FlagSelector';
import EmptyText from '@client/components/EmptyText';
import Select from '@client/components/Select';
import { countries } from '@client/utils/countries';
import { EditableColumn } from '@client/components/EditableTable';
import { OwnerPeriod } from '../tabs/VesselDetailsTab';
import styles from '../VesselDetailsScreen.module.css';
import getSizeOptions from './getVesselSizeOptions';

const getCustomNumberInput = (
	{ value, onChange }: {value?: number; onChange: (values: number | null) => void},
) => (
	<NumericInput
		separateThousands
		allowNegative={false}
		value={value != null ? Number(value) : null}
		onChange={onChange}
	/>
);

const getCustomNumberRender = ({
	value,
}: {
	value: number;
}) => (
	value != null ?
		formatNumber(value, { separateThousands: true }) :
		(<EmptyText />)
);

export const getCommsItems = (
	vessel: GetVesselDetailsResponse | undefined,
) => {
	if (vessel == null) {
		return [];
	}

	const {
		commsCallSign,
		commsInmarsatNumber,
		commsInmarsatFax,
		commsInmarsatEmail,
	} = vessel;

	return [
		{
			key: 'commsCallSign',
			label: 'Call Sign',
			type: 'text' as const,
			value: commsCallSign,
			editable: true,
		},
		{
			key: 'commsInmarsatNumber',
			label: 'Inmarsat Telephone Number',
			value: commsInmarsatNumber,
			type: 'text' as const,
			editable: true,
		},
		{
			key: 'commsInmarsatFax',
			label: 'Inmarsat Fax Number',
			value: commsInmarsatFax,
			type: 'text' as const,
			editable: true,
		},
		{
			key: 'commsInmarsatEmail',
			label: 'Inmarsat Email',
			value: commsInmarsatEmail,
			type: 'text' as const,
			editable: true,
		},
	];
};

export const getBankingItems = (
	account: BankAccountProps,
	allowCurrencyChange: boolean,
	vesselOwner: boolean,
) => {
	const {
		accountNo,
		iban,
		bankName,
		swift,
		currency,
		name,
		businessUnit,
	} = account;

	return [
		{
			key: 'name',
			label: (
				<span>
					Account Name
				</span>
			),
			value: name,
			editable: true,
		},
		...(vesselOwner ? [] : [{
			key: 'businessUnit',
			label: (
				<span>
					Business Unit
					<Badge
						dot
						color="red"
						className={styles.bankAccountBadge}
					/>
				</span>
			),
			value: businessUnit,
			editable: true,
		}]),
		{
			key: 'accountNo',
			label: 'Account No.',
			value: accountNo,
			editable: true,
		},
		{
			key: 'iban',
			label: 'IBAN',
			value: iban,
			editable: true,
		},
		{
			key: 'bankName',
			label: 'Beneficiary Bank',
			value: bankName,
			editable: true,
		},
		{
			key: 'swift',
			label: 'SWIFT / BIC',
			value: swift,
			editable: true,
		},
		{
			key: 'currency',
			label: (
				<span>
					Currency
					<TooltipIcon>
						Currency can only be changed if the vessel is not fixed to a voyage
					</TooltipIcon>
				</span>
			),
			value: currency,
			type: 'select',
			options: Object.values(Currencies).map((t) => ({
				label: t,
				value: t,
			})),
			inputProps: {
				showSearch: true,
			},
			editable: allowCurrencyChange,
		},
	];
};

export const getGeneralItems = (
	vessel: GetVesselDetailsResponse,
	userData: GetUserDataResponse,
	selectedVesselType?: Values<typeof VesselTypes> | null,
) => {
	const {
		organizationName,
		useVesselCompanyOnInvoice: orgUseVesselCompanyOnInvoice,
	} = userData;
	const {
		vatId,
		name,
		codename,
		ownershipType,
		companyName,
		useVesselCompanyOnInvoice,
		yard,
		yearBuilt,
		flag,
		portOfRegistry,
		imo,
		classificationId,
		vesselClass,
		lastSpecialSurvey,
		lastDryDocking,
		nextSpecialSurvey,
		nextDryDocking,
		ownersPIClub,
		mastersName,
		LOA,
		breadth,
		summerDWT,
		ladenDraft,
		ballastDraft,
		lightship,
		grossTonnage,
		netTonnage,
		suezGRT,
		suezNRT,
		panamaNRT,
		grainCapacity,
		TPC,
		summerDraft,
		constants,
		baleCapacity,
		cranes,
		grabs,
		conditionBoundary,
		createdAt,
		updatedAt,
		type,
		size,
		technicalOperator,
		commercialOperator,
		disponentOwner,
		lastDryDockingLocation,
		lastAnnualSurvey,
		lengthBetweenPerpendiculars,
		fittedForPanamaCanal,
		fittedForSuezCanal,
		fittedForStLawrenceSeaway,
		permittedCargos,
		totalCargoTankCapacities98,
		totalCargoTankCapacities100,
		maxNumberOfLoadedGrades,
		mouldedDepth,
	} = vessel;

	let calculatedSize = size;

	if (selectedVesselType != null && selectedVesselType !== type) {
		calculatedSize = null;
	}

	const getCompanyName = (value: boolean | undefined) => {
		if (value === true) {
			return companyName || 'Vessel company name';
		}

		if (value === false) {
			return organizationName ?? '';
		}

		return orgUseVesselCompanyOnInvoice ?
			`Default (${companyName || 'Vessel company name'})` :
			`Default (${organizationName})`;
	};

	const keyDetails = [
		{
			key: 'imo',
			label: 'IMO',
			value: Number(imo),
			editable: true,
			type: 'number' as const,
			renderInput: (
				{ value, onChange }: {value?: number; onChange: (values: number | null) => void},
			) => (
				<NumericInput
					addonBefore="IMO"
					separateThousands={false}
					allowNegative={false}
					integersOnly
					value={value != null ? Number(value) : null}
					onChange={onChange}
				/>
			),
			render: ({ value }: {value?: any}) => (value != null ? (
				<>
					IMO
					{' '}
					{value}
				</>
			) : (
				<span>
					<EmptyText />
					<TooltipIcon danger Icon={ExclamationCircleOutlined} placement="bottom">
						An IMO number is required to enable AIS functionality
					</TooltipIcon>
				</span>
			)),
		},
		{
			key: 'name',
			label: 'Vessel name',
			value: name,
			editable: true,
			type: 'text' as const,
		},
		{
			key: 'type',
			label: 'Vessel type',
			value: type,
			editable: true,
			type: 'select' as const,
			options: Object.keys(VesselTypes).map((t) => ({
				label: VesselTypeLabels[t],
				value: t,
			})),
		},
		{
			key: 'size',
			label: 'Vessel size',
			value: calculatedSize,
			editable: true,
			type: 'select' as const,
			options: getSizeOptions(selectedVesselType ?? type),
		},
		...(vessel.ownershipType !== VesselOwnershipTypes.MARKET ?
			[
				{
					key: 'ownershipType',
					label: 'Ownership Type',
					value: VesselOwnershipTypeLabels[ownershipType],
					editable: false,
					type: 'text' as const,
				},
				{
					key: 'codename',
					label: 'Vessel codename',
					value: codename,
					editable: true,
					type: 'text' as const,
				},
				{
					key: 'companyName',
					label: 'Company name',
					value: companyName,
					editable: true,
					type: 'text' as const,
				},
			] :
			[]
		),
	];

	const vesselParticulars = [
		{
			key: 'vatId',
			label: 'VAT number',
			value: vatId,
			editable: true,
			type: 'text' as const,
		},
		{
			key: 'useVesselCompanyOnInvoice',
			label: 'Invoice company name',
			type: 'select' as const,
			render: ({ value }: {value?: boolean | null}) => {
				return value != null ? (
					<span>
						<Typography.Text>
							{getCompanyName(value)}
						</Typography.Text>
					</span>
				) : <></>;
			},
			renderInput: ({ value, onChange }: {value?: boolean; onChange: (values: any) => void}) => (
				<Select<boolean>
					fullWidth
					onChange={onChange}
					value={value}
					options={[
						{ label: getCompanyName(true), value: true },
						{ label: getCompanyName(false), value: false },
						{ label: getCompanyName(undefined), value: undefined },
					]}
				/>
			),
			value: useVesselCompanyOnInvoice as boolean,
			editable: true,
		},
		...(vessel.type === VesselTypes.LPG ?
			[
				{
					key: 'technicalOperator',
					label: 'Tehcnical operator',
					value: technicalOperator,
					editable: true,
				},
				{
					key: 'commercialOperator',
					label: 'Commercial operator ',
					value: commercialOperator,
					editable: true,
				},
				{
					key: 'disponentOwner',
					label: 'Disponent owner',
					value: disponentOwner,
					editable: true,
				},
			] :
			[]
		),
		{
			key: 'conditionBoundary',
			label: (
				<Space>
					<div>Draft threshold (Ballast/Laden)</div>
					<TooltipIcon>
						Enter the draft threshold that determines if the vessel is considered
						ballast or laden based on whether the actual draft is below or above this value
					</TooltipIcon>
				</Space>
			),
			value: conditionBoundary,
			type: 'number',
			editable: true,
		},
		{
			key: 'yard',
			label: 'Shipyard',
			value: yard,
			editable: true,
		},
		{
			key: 'yearBuilt',
			label: 'Year built',
			value: yearBuilt,
			editable: true,
		},
		{
			key: 'flag',
			label: 'Vessel flag',
			render: ({ value }: {value?: any}) => (
				value != null && (
					<CountryFlag
						countryCode={vessel.flag == null ? undefined : vessel.flag}
						showCountryName
					/>
				)
			),
			renderInput: ({ value, onChange }: {
				value: Values<typeof countries>;
				onChange: (values: any) => void;
			}) => (
				<FlagSelector
					countryCode={value}
					onChange={onChange}
				/>
			),
			value: flag,
			editable: true,
		},
		{
			key: 'portOfRegistry',
			label: 'Port of registry',
			value: portOfRegistry,
			editable: true,
		},
		{
			key: 'vesselClass',
			label: 'Vessel class',
			value: vesselClass,
			editable: true,
		},
		{
			key: 'classificationId',
			label: 'Classification id',
			value: classificationId,
			editable: true,
		},
		{
			key: 'lastSpecialSurvey',
			label: 'Last special survey',
			value: lastSpecialSurvey ? formatDate(lastSpecialSurvey) : null,
			editable: true,
			type: 'date',
		},
		{
			key: 'lastDryDocking',
			label: 'Last dry docking',
			value: lastDryDocking ? formatDate(lastDryDocking) : null,
			editable: true,
			type: 'date',
		},
		...(vessel.type === VesselTypes.LPG ?
			[
				{
					key: 'lastDryDockingLocation',
					label: 'Last dry docking location',
					value: lastDryDockingLocation,
					editable: true,
				},
			] :
			[]
		),
		{
			key: 'nextSpecialSurvey',
			label: 'Next special survey',
			value: nextSpecialSurvey ? formatDate(nextSpecialSurvey) : null,
			editable: true,
			type: 'date',
		},
		{
			key: 'nextDryDocking',
			label: 'Next dry docking',
			value: nextDryDocking ? formatDate(nextDryDocking) : null,
			editable: true,
			type: 'date',
		},
		...(vessel.type === VesselTypes.LPG ?
			[
				{
					key: 'lastAnnualSurvey',
					label: 'Last annual survey',
					value: lastAnnualSurvey,
					editable: true,
					type: 'date',
				},
			] :
			[]
		),
		{
			key: 'ownersPIClub',
			label: 'Owners PI club',
			value: ownersPIClub,
			editable: true,
		},
		{
			key: 'mastersName',
			label: 'Masters name',
			value: mastersName,
			editable: true,
		},
		{
			key: 'LOA',
			label: 'LOA',
			value: LOA,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		...(vessel.type === VesselTypes.LPG ?
			[
				{
					key: 'lengthBetweenPerpendiculars',
					label: 'Length between perpendiculars',
					value: lengthBetweenPerpendiculars,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
			] :
			[]
		),
		{
			key: 'breadth',
			label: 'Breadth',
			value: breadth,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		...(vessel.type === VesselTypes.LPG ?
			[
				{
					key: 'mouldedDepth',
					label: 'Moulded depth',
					value: mouldedDepth,
					editable: true,
				},
			] :
			[]
		),
		{
			key: 'summerDWT',
			label: 'Summer DWT',
			value: summerDWT,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'ladenDraft',
			label: 'Default Laden Draft',
			value: ladenDraft,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'ballastDraft',
			label: 'Default Ballast Draft',
			value: ballastDraft,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'summerDraft',
			label: 'Summer Draft',
			value: summerDraft,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'constants',
			label: 'Constants',
			value: constants,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'lightship',
			label: 'Lightship',
			value: lightship,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'TPC',
			label: 'TPC',
			value: TPC,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'grossTonnage',
			label: 'Gross Tonnage',
			value: grossTonnage,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'netTonnage',
			label: 'Net Tonnage',
			value: netTonnage,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'suezGRT',
			label: 'Suez GRT',
			value: suezGRT,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'suezNRT',
			label: 'Suez NRT',
			value: suezNRT,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		{
			key: 'panamaNRT',
			label: 'Panama NRT',
			value: panamaNRT,
			editable: true,
			renderInput: getCustomNumberInput,
			render: getCustomNumberRender,
		},
		...(vessel.type !== VesselTypes.LPG ?
			[
				{
					key: 'grainCapacity',
					label: 'Grain Capacity',
					value: grainCapacity,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
				{
					key: 'baleCapacity',
					label: 'Bale Capacity',
					value: baleCapacity,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
				{
					key: 'cranes',
					label: 'Cranes',
					value: cranes,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
				{
					key: 'grabs',
					label: 'Grabs',
					value: grabs,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
			] :
			[]
		),

		...(vessel.type === VesselTypes.LPG ?
			[
				{
					key: 'permittedCargos',
					label: 'Permitted cargos',
					value: permittedCargos,
					editable: true,
				},
				{
					key: 'totalCargoTankCapacities98',
					label: 'Total cargo tank capacities 98%',
					value: totalCargoTankCapacities98,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
				{
					key: 'totalCargoTankCapacities100',
					label: 'Total cargo tank capacities 100%',
					value: totalCargoTankCapacities100,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
				{
					key: 'maxNumberOfLoadedGrades',
					label: 'Maximum number of loaded grades',
					value: maxNumberOfLoadedGrades,
					editable: true,
					renderInput: getCustomNumberInput,
					render: getCustomNumberRender,
				},
			] :
			[]
		),

		{
			key: 'createdAt',
			label: 'Creation date',
			value: formatDate(createdAt, DATE_AND_TIME),
		},
		{
			key: 'updatedAt',
			label: 'Last updated',
			value: formatDate(updatedAt, DATE_AND_TIME),
		},
		...(vessel.type === VesselTypes.LPG ?
			[
				{
					key: 'fittedForPanamaCanal',
					label: 'Fitted for Panama Canal',
					value: fittedForPanamaCanal === true ? 'true' : 'false',
					editable: true,
					type: 'switch',
					renderInput: ({ onChange }: { onChange: () => void }) => {
						return (
							<Switch
								defaultValue={fittedForPanamaCanal || false}
								onChange={onChange}
							/>
						);
					},

				},
				{
					key: 'fittedForSuezCanal',
					label: 'Fitted for Suez Canal',
					value: fittedForSuezCanal === true ? 'true' : 'false',
					editable: true,
					type: 'switch',
					renderInput: ({ onChange }: { onChange: () => void }) => {
						return (
							<Switch
								defaultValue={fittedForSuezCanal || false}
								onChange={onChange}
							/>
						);
					},
				},
				{
					key: 'fittedForStLawrenceSeaway',
					label: 'Fitted for St Lawrence Seaway',
					value: fittedForStLawrenceSeaway === true ? 'true' : 'false',
					editable: true,
					type: 'switch',
					renderInput: ({ onChange }: { onChange: () => void }) => {
						return (
							<Switch
								defaultValue={fittedForStLawrenceSeaway || false}
								onChange={onChange}
							/>
						);
					},
				},
			] :
			[]
		),
	];

	return {
		keyDetails,
		vesselParticulars,
	};
};

export const ownedPeriodsColumns: Array<EditableColumn<
	OwnerPeriod,
	'id'
>> = [
	{
		dataIndex: 'fromDate',
		title: 'Owned From',
		render: renderDate(DATE_AND_TIME),
		editable: true,
		editingProps: {
			type: 'date',
			inputProps: {
				time: true,
				range: false,
			},
		},
	},
	{
		dataIndex: 'toDate',
		title: 'Owned Until',
		render: (v: Moment) => (v == null ? (
			<em>Ongoing</em>
		) : formatDate(v, DATE_AND_TIME)),
		editable: true,
		editingProps: {
			type: 'date',
			required: false,
			inputProps: {
				time: true,
				range: false,
				placeholder: 'Until (optional)',
			},
		},
	},
];
