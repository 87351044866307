import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
	Alert,
	Flex,
	Popover,
	TooltipProps,
} from 'antd';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { faWrench } from '@fortawesome/pro-solid-svg-icons';
import {
	PortActionTypes,
	PortActionTypeLabels,
	Currencies,
	SalinityTypes,
	currencySymbols,
	ShortPortActionTypes,
	LaytimeIntervals,
} from '@shared/utils/constants';
import { PortRotationEntryGeneratorTypes } from '@shared/utils/port-rotation-entry-generator-types';
import { standardSort } from '@shared/utils/standardSort';
import { Values } from '@shared/utils/objectEnums';
import { round } from '@shared/utils/math';
import { capitalize } from '@shared/utils/string';
import { formatCurrency } from '@shared/utils/currency';
import type { Port } from '@api/utils/ports';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import type { SeaPassageSettings } from '@api/utils/sequelize/calculateVoyageItineraryDetails/helpers/itineraryConstants';
import Button from '@client/components/Button';
import { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import Select from '@client/components/Select';
import ConfigContent from '../ConfigContent';
import styles from '../styles/shared.module.css';
import { TimeFormat } from '../PortRotationCard';
import {
	LocalPortRotation,
	PartialPortRotationEntry,
} from '../context/hooks/usePortRotationHandlers';

const shortenSalinity = (salinity: SalinityTypes) => {
	switch (salinity) {
		case SalinityTypes.FRESH:
			return 'Fre.';
		case SalinityTypes.BRACKISH:
			return 'Brack.';
		case SalinityTypes.SALT:
			return 'Salt.';
		default:
			return '';
	}
};

type Props = {
	onDeletePortRotationEntry: (entry: PartialPortRotationEntry) => void;
	onPortRotationRowChange: (
		id: number,
		attributes: any
	) => void;
	onConfigUpdate: (id: number, routeSettings: SeaPassageSettings) => void;
	renderWrappingTime: (value: Moment, format: TimeFormat) => JSX.Element | null;
	speedPopoverOpen: number | null;
	setSpeedPopoverOpen: React.Dispatch<React.SetStateAction<number | null>>;
	selectedTimeFormat: TimeFormat;
	currency: Values<typeof Currencies>;
	ports: Port[];
	consumptionSets: Array<ConsumptionSetProps>;
	screens: Partial<Record<string, boolean>>;
}

export const getPortRotationColumns = ({
	onDeletePortRotationEntry,
	onConfigUpdate,
	onPortRotationRowChange,
	speedPopoverOpen,
	setSpeedPopoverOpen,
	selectedTimeFormat,
	currency,
	ports,
	consumptionSets,
	renderWrappingTime,
}: Props): EditableColumns<LocalPortRotation> => {
	return [
		{
			title: '',
			editable: false,
			render: (portRotationEntry: PartialPortRotationEntry) => (
				<Button
					type="link"
					danger
					size="small"
					confirmTitle="Are you sure you want to delete this entry?"
					onClick={() => onDeletePortRotationEntry(portRotationEntry)}
					disabledTooltip="Auto-generated entries cannot be deleted directly"
					disabled={portRotationEntry.generatedBy != null}
					tooltipProps={{
						placement: 'topRight',
					} as TooltipProps}
					icon={(
						<DeleteOutlined
							// eslint-disable-next-line react/forbid-component-props
							style={{
								fontSize: '16px',
								color: portRotationEntry.generatedBy != null ? 'hsl(0, 100%, 90%)' : 'red',
							}}
						/>
					)}
				/>
			),
			width: '30px',
		},
		{
			title: '',
			editable: false,
			className: styles.cellNoPadding,
			render: (portRotationEntry: PartialPortRotationEntry) => (
				<ConfigContent
					portRotationId={portRotationEntry.id}
					handleConfigUpdate={(id, settings) => id != null && onConfigUpdate(id, settings)}
					routeOptions={portRotationEntry.routeOptions}
					disabled={
						portRotationEntry.type === PortActionTypes.CANAL_TRANSIT ||
						portRotationEntry.type === PortActionTypes.BALLAST_LEG
					}
				/>
			),
			width: '40px',
		},
		{
			title: 'Type',
			dataIndex: 'type',
			type: 'select',
			renderValue: (p) => ShortPortActionTypes[p.value as Values<typeof PortActionTypes>] ??
				p.value,
			editable: (r: PartialPortRotationEntry) => r.generatedBy == null,
			className: (r: PartialPortRotationEntry) => (
				r.generatedBy != null ?
					styles.calculated :
					undefined
			),
			inputProps: {
				showSearch: true,
				className: styles.typeSelector,
				options: Object.values(PortActionTypes)
					.map((type) => ({
						label: PortActionTypeLabels[type],
						value: type,
					}))
					.sort(standardSort('label')),
				popupMatchSelectWidth: false,
				popupClassName: styles.typeSelector,
				labelRender: (p) => ShortPortActionTypes[p.value as Values<typeof PortActionTypes>],
			},
			width: 30,
		},
		{
			title: 'Port',
			dataIndex: 'port',
			// @ts-ignore when showAction is set, this field fails. Makes no sense
			type: 'select',
			editable: (r: PartialPortRotationEntry) => (
				r.generatedBy == null ||
				r.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG
			),
			className: (r: PartialPortRotationEntry) => {
				if (
					r.generatedBy != null &&
					r.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG
				) {
					return styles.calculated;
				}

				return undefined;
			},
			inputProps: {
				// @ts-ignore when showAction is set, this field fails. Makes no sense
				placeholder: 'Select port',
				className: styles.slimInput,
				showAction: 'focus',
				options: ports.map((port) => ({
					label: port.name?.toUpperCase(),
					value: port.id,
				})).sort(standardSort('label')),
				showSearch: true,
				dropdownStyle: {
					minWidth: '250px',
				},
			},
			transformData: {
				in: (port: Port | null) => port?.id ?? null,
				out: (portId: number | null) => (
					ports.find((p) => p.id === portId) ?? null
				),
			},
		},
		{
			title: 'Arrival',
			dataIndex: 'arrivalDate',
			type: 'date',
			editable: false,
			className: (row) => {
				if (row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG) {
					return styles.disabled;
				}

				return styles.calculated;
			},
			renderValue: (p) => {
				if (p.value == null) {
					return null;
				}

				return renderWrappingTime(p.value, selectedTimeFormat);
			},
			inputProps: {
				allowClear: false,
			},
			width: 135,
		},
		{
			title: 'Departure',
			dataIndex: 'departureDate',
			type: 'date',
			editable: (row) => row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG,
			className: (row) => {
				if (row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG) {
					return styles.calculated;
				}

				return undefined;
			},
			renderValue: (p) => {
				if (p.value == null) {
					return null;
				}

				return renderWrappingTime(p.value, selectedTimeFormat);
			},
			inputProps: {
				allowClear: false,
				time: true,
				className: styles.slimInput,
				showTimezone: false,
			},
			width: 130,
		},
		{
			title: 'Distance',
			children: [
				{
					title: 'Normal',
					dataIndex: 'normalDistance',
					type: 'number',
					editable: false,
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled :
							styles.calculatedNumber
					),
					renderValue: (p) => (p.value != null ? `${p.value}` : null),
					width: 40,
				},
				{
					title: 'ECA',
					dataIndex: 'ecaDistance',
					type: 'number',
					editable: false,
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled :
							styles.calculatedNumber
					),
					renderValue: (p) => (p.value != null ? `${p.value}` : null),
					width: 40,
				},
			],
		},
		{
			title: 'Marg.',
			dataIndex: 'seaMargin',
			inputProps: {
				placeholder: 'Margin',
				className: styles.slimInput,
				addonAfter: '%',
				size: 'small',
			},
			type: 'number',
			editable: (row) => (row.seaDays != null && row.seaDays !== 0),
			className: (row) => (
				row.seaDays != null && row.seaDays !== 0 ?
					undefined :
					styles.disabled
			),
			width: 50,
		},
		{
			title: 'Speed',
			// @ts-ignore
			type: 'number',
			inputProps: {
				// @ts-ignore
				placeholder: 'Speed',
			},
			editable: false,
			width: 75,
			className: (row) => (row.nextEntryId == null ? styles.disabled : styles.calculated),
			render: (row) => row != null && (
				(
					<div
						className={styles.speedProfileContainer}
					>
						<span style={{ whiteSpace: 'nowrap' }}>{`${row.speed} kts`}</span>
						<Popover
							title="Choose consumption set"
							trigger="click"
							open={speedPopoverOpen === row?.id}
							onOpenChange={(open) => (
								open ?
									setSpeedPopoverOpen(row.id) :
									setSpeedPopoverOpen(null)
							)}
							content={(
								<Flex
									gap={10}
									vertical
								>
									<Alert
										// eslint-disable-next-line react/forbid-component-props
										style={{ width: 300 }}
										message="The chosen consumption profile will be used to calculate the speed for the sea passage between this entry and the next."
										type="info"
										showIcon
									/>
									<Select
										fullWidth
										value={row.consumptionSetId}
										options={consumptionSets.map((c) => ({
											value: c.id,
											label: c.name,
										}))}
										onSelect={(v) => {
											onPortRotationRowChange(row.id, { consumptionSetId: Number(v) });
											setSpeedPopoverOpen(null);
										}}
									/>
								</Flex>
							)}
						>
							<Button
								type="link"
								className={styles.speedProfileBtn}
								icon={(<FontAwesomeIcon size="sm" icon={faWrench as Icon} />)}
							/>
						</Popover>
					</div>
				)),
		},
		{
			title: 'Draft',
			dataIndex: 'draft',
			type: 'number',
			inputProps: {
				className: styles.slimInput,
				addonAfter: 'm',
			},
			width: 55,
			editable: (row) => (
				row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG &&
				row.generatedBy !== PortRotationEntryGeneratorTypes.CARGO
			),
			className: (row) => (
				(
					row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ||
					row.generatedBy === PortRotationEntryGeneratorTypes.CARGO
				) ? styles.calculatedNumber :
					undefined
			),
			renderValue: (p) => (p.value != null ? round(p.value) : ''),
		},
		{
			title: 'Salinity',
			dataIndex: 'salinity',
			type: 'select',
			editable: true,
			width: 60,
			inputProps: {
				placeholder: 'Salinity',
				className: styles.slimInput,
				popupMatchSelectWidth: false,
				labelRender: (label) => shortenSalinity(label.value as SalinityTypes),
				options: Object.values(SalinityTypes)
					.map((salinity) => ({
						label: capitalize(salinity),
						value: salinity,
					})),
			},
			renderValue: (p) => (p.value != null ? shortenSalinity(p.value as SalinityTypes) : 'N/A'),
		},
		{
			title: 'Days',
			children: [
				{
					title: 'Sea',
					dataIndex: 'seaDays',
					type: 'number',
					width: 50,
					align: 'right',
					editable: false,
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled :
							styles.calculatedNumber
					),
					renderValue: (p) => (p.value != null ? round(p.value) : null),
				},
				{
					title: 'Working',
					dataIndex: 'workingDays',
					type: 'number',
					inputProps: {
						className: styles.slimInput,
					},
					width: 45,
					align: 'right',
					editable: (row) => (
						row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG &&
						row.generatedBy !== PortRotationEntryGeneratorTypes.CARGO
					),
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ? styles.disabled : (
							row.generatedBy === PortRotationEntryGeneratorTypes.CARGO
						) ? styles.calculatedNumber :
							undefined
					),
					renderValue: (p) => (p.value != null ? round(p.value) : '0'),
				},
				{
					title: 'Idle',
					dataIndex: 'idleDays',
					type: 'number',
					width: 45,
					inputProps: {
						className: styles.slimInput,
					},
					align: 'right',
					editable: (row) => (
						row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG
					),
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled : undefined
					),
					renderValue: (p) => (p.value != null ? round(p.value) : 'N/A'),
				},
				{
					title: 'Turn',
					dataIndex: 'turnDays',
					type: 'number',
					inputProps: {
						className: styles.slimInput,
					},
					width: 45,
					align: 'right',
					editable: (row) => (
						row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG
					),
					className: (row) => (
						row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
							styles.disabled : undefined
					),
					renderValue: (p) => (p.value != null ? round(p.value) : '0'),
				},
			],
		},

		{
			title: 'L/D Rate',
			dataIndex: 'loadDischargeRate',
			type: 'number',
			editable: (row) => (row.generatedBy === PortRotationEntryGeneratorTypes.CARGO),
			className: (row) => (
				row.generatedBy !== PortRotationEntryGeneratorTypes.CARGO ?
					styles.disabled :
					undefined
			),
			width: 60,
			inputProps: {
				allowNegative: false,
				className: styles.slimInput,
			},
		},
		{
			title: 'Unit',
			dataIndex: 'laytimeInterval',
			type: 'select',
			editable: true,
			width: 50,
			inputProps: {
				placeholder: 'Unit',
				className: styles.slimInput,
				popupMatchSelectWidth: false,
				options: [{
					label: '/ day',
					value: LaytimeIntervals.DAY,
				},
				{
					label: '/ hour',
					value: LaytimeIntervals.HOUR,
				}],
			},
			renderValue: (v) => `/ ${v.value}`,
		},
		{
			title: 'Port Cost',
			dataIndex: 'portCost',
			type: 'currency',
			inputProps: {
				className: styles.slimInput,
				currency,
				addCurrencyBefore: false,
				addonBefore: currencySymbols[currency] ?? currency,
			},
			width: 120,
			editable: (row) => row.generatedBy !== PortRotationEntryGeneratorTypes.BALLAST_LEG,
			className: (row) => {
				if (row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG) {
					return styles.disabled;
				}

				return undefined;
			},
		},
		{
			title: 'Dem/(Des)',
			dataIndex: 'demurrageDespatch',
			type: 'currency',
			editable: false,
			renderValue: (laytime) => formatCurrency((laytime?.value ?? 0) * -1, currency),
			className: (row) => (
				row.generatedBy === PortRotationEntryGeneratorTypes.BALLAST_LEG ?
					styles.disabled :
					styles.calculatedNumber
			),
			inputProps: {
				addCurrencyBefore: false,
				currency,
				allowNegative: true,
			},
			width: '90px',
		},
	];
};
