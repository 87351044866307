import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Alert,
	Checkbox,
} from 'antd';
import { FixtureTypeLabels } from '@shared/utils/constants';
import type { AccrualMapping } from '@api/models/organization';
import EditableCellTableRedux, { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getOrganizationDetails,
	getUserData,
	updateOrganization,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import styles from '../approvals/ApprovalSettings.module.css';

const AccrualSettings = () => {
	const [userData] = useFetchedState(getUserData);
	const [orgDetails, refreshOrgDetails, _error, loading] = useFetchedState(async () => {
		if (userData != null) {
			const response = await getOrganizationDetails(userData?.organizationId);

			return response;
		}

		return null;
	}, [userData]);

	const columns: EditableColumns<AccrualMapping> = [
		{
			title: 'Type',
			type: 'text',
			editable: false,
			render: (row: AccrualMapping) => {
				return `(${FixtureTypeLabels[row.type]}) ${row.field}`;
			},
		},
		{
			title: (
				<>
					Contract commencement
				</>
			),
			dataIndex: 'accrueFromContractCommencement',
			editable: true,
			align: 'center',
			type: 'checkbox',
			renderInput: ({ value, onChange }) => (
				<div style={{ textAlign: 'center' }}>
					<Checkbox
						onChange={(e) => onChange(e.target.checked)}
						checked={value}
					/>
				</div>
			),
		},
		{
			title: (
				<>
					Loading commencement
				</>
			),
			dataIndex: 'accrueFromContractCommencement',
			editable: true,
			align: 'center',
			type: 'checkbox',
			renderInput: ({ value, onChange }) => (
				<div style={{ textAlign: 'center' }}>
					<Checkbox
						onChange={(e) => onChange(!e.target.checked)}
						checked={!value}
					/>
				</div>
			),
		},
	];

	const dataSource = useMemo(() => (
		orgDetails?.accrualsMappingList.filter((e) => e.accrueFromContractCommencement != null) ?? []
	), [orgDetails]);

	const handleChange = useCallback(async (values: Array<AccrualMapping>) => {
		try {
			if (orgDetails != null) {
				await updateOrganization(orgDetails.id, {
					accrualsMappingList: values,
				});
				await refreshOrgDetails();
			}
		} catch (e: any) {
			showErrorNotification(e.toString());
		}
	}, [orgDetails, refreshOrgDetails]);

	return (
		<div className={styles.tableContainer}>
			<Alert
				className={styles.alert}
				type="info"
				message={(
					<p>
						For period closes, revenues & expenses can be recognised by one of two methods:
						<br />
						<br />
						<b>Method 1: </b>
						Recognise revenues/expenses from contract commencement
						<br />
						<b>Method 2: </b>
						Recognise revenues/expenses from loading commencement (for IFRS)
					</p>
				)}
			/>
			<EditableCellTableRedux
				columns={columns}
				dataSource={dataSource}
				onChange={handleChange}
				loading={loading}
				pagination={false}
			/>
		</div>
	);
};

export default AccrualSettings;
