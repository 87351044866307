import { useRef } from 'react';
import isEqual from 'lodash.isequal';

/*
* Hook for when useMemo is not deep enough.
* useMemo compares dependencies by referential equality ("is this object in the same place in memory as before?"),
* which is great for most stuff. Sometimes it's not, and stuff returned from an API might be identical to last render,
* but have changed places in memory and is "not equal" in the eyes of old-man useMemo, even though the data is the same.
*
* useDeepMemo is slower than useMemo (we run a deep compare on every dependency), but can result in fewer
* unnecessary renders when used correctly. Use sparingly!
* */

const useDeepMemo = <T>(
	fn: () => T,
	deps: any[] | [] | null,
) => {
	const first = useRef(true);
	const prevDeps = useRef(deps);
	const result = useRef<T | undefined>(undefined);
	const isSame = prevDeps.current?.every((p, index) => {
		let val1 = p;
		let val2 = deps?.[index];

		if (typeof val1 === 'function') {
			val1 = val1.toString();
		}

		if (typeof val2 === 'function') {
			val2 = val2.toString();
		}

		return isEqual(val1, val2);
	});

	if (
		first.current ||
		!isSame
	) {
		result.current = fn();
		first.current = false;
		prevDeps.current = deps;

		return result.current;
	}

	return result.current;
};

export default useDeepMemo;
