import React from 'react';
import { Route } from 'react-router-dom';
import {
	FixtureTypes,
	FeatureFlags,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import FixtureTcInDetailsScreen from '@client/screens/fixtures/tc/tc-in/FixtureTcInDetailsScreen';
import FixtureTcOutDetailsScreen from '@client/screens/fixtures/tc/tc-out/FixtureTcOutDetailsScreen';
import HomeScreen from '@client/screens/general/HomeScreen/HomeScreen';
import OrganizationDetailsScreen from '@client/screens/admin/OrganizationDetailsScreen/OrganizationDetailsScreen';
import OrganizationListScreen from '@client/screens/admin/OrganizationListScreen/OrganizationListScreen';
import VesselDetailsScreen from '@client/screens/fleet/VesselDetailsScreen/VesselDetailsScreen';
import VesselsListScreen from '@client/screens/fleet/VesselsListScreen/VesselsListScreen';
import VoyagesListScreen from '@client/screens/fleet/VoyagesListScreen/VoyagesListScreen';
import InvoiceDetailsScreen from '@client/screens/financials/HireInvoiceDetailsScreen/HireInvoiceDetailsScreen';
import MapScreen from '@client/screens/map/MapScreen/MapScreen';
import { Links } from '@client/utils/links';
import ErrorPage from '@client/components/ErrorPage';
import APIError from '@client/utils/APIError';
import PerformanceListScreen from '@client/screens/performance/PerformanceListScreen/PerformanceListScreen';
import PerformanceDetailsScreen from '@client/screens/performance/PerformanceDetailsScreen/PerformanceDetailsScreen';
import EstimatesListPage from '@client/screens/estimates/list/EstimatesListPage';
import CompareEstimatesScreen from '@client/screens/estimates/compare/CompareEstimatesScreen';
import CargosListScreen from '@client/screens/cargos/list/CargosListScreen';
import ManagementSettingsScreen from '@client/screens/management/ManagementSettingsScreen/ManagementSettingsScreen';
import AccountingItemsScreen from '@client/screens/financials/ArApScreen/AccountingItemsScreen';
import CargosDetailsScreen from '@client/screens/cargos/details/CargoDetailsScreen';
import AccrualsScreen from '@client/screens/financials/Accounting/AccrualsScreen/AccrualsScreen';
import PeriodCloseScreen from '@client/screens/financials/Accounting/PeriodCloseScreen/PeriodCloseScreen';
import PreviousClosesScreen from '@client/screens/financials/Accounting/PreviousClosesScreen/PreviousClosesScreen';
import VideoGuideScreen from '@client/screens/admin/VideoGuideScreen/VideoGuideScreen';
import FeatureHighlightScreen from '@client/screens/admin/FeatureHighlightScreen/FeatureHighlightScreen';
import ApprovalsScreen from '@client/screens/financials/ExpenseApprovalsScreen/ApprovalsScreen';
import AccountingSystemConfigurationScreen from '@client/screens/financials/Accounting/AccountingSystemConfigurationScreen';
import ContractDetailsScreen from '@client/screens/fleet/VoyageDetailsScreen/ContactDetailsScreen';
import EstimateDetailsScreen from '@client/screens/estimates/details/EstimateDetailsScreen';
import FixtureBbOutListScreen from '@client/screens/fixtures/tc/bb-out/FixtureBbOutListScreen';
import FixtureBbOutDetailsScreen from '@client/screens/fixtures/tc/bb-out/FixtureBbOutDetailsScreen';
import CounterpartiesListScreen from '@client/screens/commercial/CounterpartiesListScreen/CounterpartiesListScreen';
import CounterpartyDetailsScreen from '@client/screens/commercial/CounterpartyDetailsScreen/CounterpartyDetailsScreen';
import UsersListScreen from '../screens/settings/UsersListScreen/UsersListScreen';
import UserDetailsScreen from '../screens/settings/UserDetailsScreen/UserDetailsScreen';
import UserGroupDetailsScreen from '../screens/settings/UserGroupDetailsScreen/UserGroupDetailsScreen';
import UserGroupsListScreen from '../screens/settings/UserGroupsListScreen/UserGroupsListScreen';
import ExpensesScreen from '../screens/financials/ExpensesScreen/ExpensesScreen';
import FixtureTcOutListScreen from '../screens/fixtures/tc/tc-out/FixtureTcOutListScreen';
import FixtureTcInListScreen from '../screens/fixtures/tc/tc-in/FixtureTcInListScreen';

export const getRoutes = (
	{
		isAdmin,
		isManagement,
		userFeatureFlags,
	}: {
		isAdmin: boolean;
		isManagement: boolean;
		userFeatureFlags: Record<Values<typeof FeatureFlags>, boolean>;
	},
) => {
	const routes = [
		{ path: Links.Home.path, component: HomeScreen, exact: true },
		{ path: Links.Organization.path, component: OrganizationDetailsScreen, disabled: !isAdmin },
		{ path: Links.Organizations.path, component: OrganizationListScreen, disabled: !isAdmin },
		{ path: Links.VideoGuides.path, component: VideoGuideScreen, disabled: !isAdmin },
		{ path: Links.FeatureHighlights.path, component: FeatureHighlightScreen, disabled: !isAdmin },
		{
			path: Links.Management.Settings.path,
			component: ManagementSettingsScreen,
			disabled: !isManagement,
		},
		{ path: Links.Vessel.path, component: VesselDetailsScreen },
		{ path: Links.Vessels.path, component: VesselsListScreen },
		/* eslint-disable max-len */
		{ path: Links.Fixtures[FixtureTypes.BB_OUT].List.path, component: FixtureBbOutListScreen, exact: true },
		{ path: Links.Fixtures[FixtureTypes.BB_OUT].Details.path, component: FixtureBbOutDetailsScreen },
		{ path: Links.Fixtures[FixtureTypes.TC_OUT].List.path, component: FixtureTcOutListScreen, exact: true },
		{ path: Links.Fixtures[FixtureTypes.TC_OUT].Details.path, component: FixtureTcOutDetailsScreen },
		{ path: Links.Fixtures[FixtureTypes.TC_IN].List.path, component: FixtureTcInListScreen, exact: true },
		{ path: Links.Fixtures[FixtureTypes.TC_IN].Details.path, component: FixtureTcInDetailsScreen },
		{ path: Links.Fixtures[FixtureTypes.SPOT].List.path, component: EstimatesListPage, exact: true },
		{ path: Links.Fixtures[FixtureTypes.SPOT].Details.path, component: EstimateDetailsScreen },
		{ path: Links.Fixtures[FixtureTypes.SPOT].CompareEstimates.path, component: CompareEstimatesScreen },
		/* eslint-enable max-len */
		{ path: Links.Expenses.path, component: ExpensesScreen },
		{
			path: Links.Management.Approvals.path,
			component: ApprovalsScreen,
			disabled: !isManagement,
		},
		{ path: Links.VoyageInvoice.path, component: InvoiceDetailsScreen },
		{ path: Links.Voyage.path, component: ContractDetailsScreen },
		{ path: Links.Voyages.path, component: VoyagesListScreen },
		...(userFeatureFlags[FeatureFlags.PERFORMANCE] ? [
			{ path: Links.PerformanceOverview.path, component: PerformanceListScreen, exact: true },
			{ path: Links.PerformanceDetails.path, component: PerformanceDetailsScreen },
		] : []),
		{ path: Links.AccountingItems.path, component: AccountingItemsScreen },
		{ path: Links.Accounting.Mapping.path, component: AccountingSystemConfigurationScreen },
		{ path: Links.Accounting.Accruals.path, component: AccrualsScreen },
		{ path: Links.Accounting.PeriodClose.path, component: PeriodCloseScreen },
		{ path: Links.Accounting.PreviousCloses.path, component: PreviousClosesScreen },
		{ path: Links.Counterparty.path, component: CounterpartyDetailsScreen },
		{ path: Links.Counterparties.path, component: CounterpartiesListScreen },
		{ path: Links.Map.path, component: MapScreen },
		{ path: Links.Cargo.path, component: CargosDetailsScreen },
		{ path: Links.Cargos.path, component: CargosListScreen },
		{ path: Links.User.path, component: UserDetailsScreen },
		{ path: Links.Users.path, component: UsersListScreen },
		{ path: Links.Group.path, component: UserGroupDetailsScreen },
		{ path: Links.Groups.path, component: UserGroupsListScreen },
		// Don't add routes below this line, it's a catch-all for non-existing pages
		{ path: '/', component: () => (<ErrorPage error={new APIError('Requested page not found', true, 404)} />) },
	];

	return routes.filter((r) => !r.disabled).map((r) => (
		<Route
			path={r.path}
			component={r.component}
			exact={r.exact}
			key={r.path}
		/>
	));
};
