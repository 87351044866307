import React from 'react';
import { Typography } from 'antd';
import {
	CrewReportTypes,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import { TimeFormat } from '@shared/utils/date';
import { round } from '@shared/utils/math';
import { capitalize } from '@shared/utils/string';
import type { BerthingReportProps } from '@api/models/berthing-report';
import { formatDate } from '@client/utils/formatDate';
import Details from '@client/components/Details';
import Table from '@client/components/Table/Table';
import { coordinatesToDMSString } from '@client/utils/coordinates';
import styles
	from '@client/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/styles/NoonReportDrawerContent.module.css';
import Map from '@client/components/Map/Map';
import ReportsLayer from '@client/components/Map/layers/ReportsLayer';

const BerthingReportDrawerContent = ({
	report,
	selectedTimeFormat,
}: {
	report: BerthingReportProps;
	selectedTimeFormat?: TimeFormat;
}) => {
	const robColumns = [
		{
			title: 'Fuel Grade',
			dataIndex: 'fuelType',
			key: 'fuelType',
		},
		{
			title: 'Quantity',
			dataIndex: 'tons',
			key: 'tons',
			render: (tons: number) => (tons != null ? `${round(tons, 3)} tons` : ''),
		},
	];

	const detailItems = [
		{
			key: 'type',
			label: 'Type',
			value: CrewReportTypes.DELIVERY,
		},
		{
			key: 'date',
			label: 'Date',
			value: formatDate(report.date, DATE_AND_TIME, true, selectedTimeFormat),
		},
		{
			key: 'locationName',
			label: 'Delivery Location',
			value: report.locationName,
		},
		{
			key: 'captainName',
			label: 'Master\'s name',
			value: report.captainName,
		},
		{
			key: 'loadCondition',
			label: 'Load Condition',
			value: capitalize(report.loadCondition),
		},
		{
			key: 'numberOfTugs',
			label: 'Number of tugs',
			value: report.numberOfTugs,
		},
		{
			key: 'berthName',
			label: 'Berth Name',
			value: report.berthName,
		},
		{
			key: 'position',
			label: 'Position',
			value: coordinatesToDMSString({
				lat: report.latitude,
				long: report.longitude,
			}),
		},
		{
			key: 'remarks',
			label: 'Remarks',
			value: report.remarks,
			maxHeight: 200,
			style: {
				whiteSpace: 'pre-wrap',
				overflow: 'auto',
			} as React.CSSProperties,
		},
	];

	return (
		<>
			<Typography.Title level={5}>Details</Typography.Title>
			<Details
				items={detailItems}
				hideHeader
			/>
			<br />
			<Typography.Title level={5}>ROBs</Typography.Title>
			<Table
				columns={robColumns}
				dataSource={report.robItems || []}
				pagination={false}
			/>
			<br />
			<>
				<Typography.Title level={5}>Position</Typography.Title>
				<div className={styles.map}>
					<Map
						defaultPosition={{
							latitude: report.latitude,
							longitude: report.longitude,
						}}
					>
						{({ viewport }) => (
							<ReportsLayer
								reports={[{ ...report, type: CrewReportTypes.DELIVERY }]}
								zoom={viewport.zoom}
								openReportDrawer={undefined}
							/>
						)}
					</Map>
				</div>
			</>
		</>
	);
};

export default BerthingReportDrawerContent;
