// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EUM2BrMAmZfpBFnnz1Tg {\n\tmargin-right: 7.5px;\n}\n\n.Z34JpbMZivzLwnVRLyG2 tr .ant-descriptions-item-label {\n\twidth: 170px;\n}\n\n.I2NOTqK1wZyFRnnCmJuB .ant-table-thead .ant-table-cell {\n\tpadding: 5px;\n}\n\n.I2NOTqK1wZyFRnnCmJuB .ant-table-thead .ant-table-cell:last-child {\n\ttext-align: right;\n}\n\n.I2NOTqK1wZyFRnnCmJuB .ant-table-cell {\n\tpadding: 5px;\n}\n\n.uyUCC8kW3NjMplMcG5o4 {\n\ttext-align: right;\n}\n\n.uzBPEBQkLgDgq6F7PmQf {\n\tcolor: red;\n}\n\n.rFuq39RR_5mQ3p6rul1u {\n\tcolor: green;\n}\n\n.TTJVoXQNnOmKosF9fTj0 {\n\theight: 100%;\n\tmargin: 0;\n\tpadding: 0;\n}\n\n.LQq8_cjRTaAcl3p0MYep {\n\tfont-size: 11px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fixtures/shared/styles/SummaryCard.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,SAAS;CACT,UAAU;AACX;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".icon {\n\tmargin-right: 7.5px;\n}\n\n.details tr :global(.ant-descriptions-item-label) {\n\twidth: 170px;\n}\n\n.table :global(.ant-table-thead) :global(.ant-table-cell) {\n\tpadding: 5px;\n}\n\n.table :global(.ant-table-thead) :global(.ant-table-cell):last-child {\n\ttext-align: right;\n}\n\n.table :global(.ant-table-cell) {\n\tpadding: 5px;\n}\n\n.amountCells {\n\ttext-align: right;\n}\n\n.negative {\n\tcolor: red;\n}\n\n.positive {\n\tcolor: green;\n}\n\n.loadingEstimate {\n\theight: 100%;\n\tmargin: 0;\n\tpadding: 0;\n}\n\n.smallerText {\n\tfont-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "EUM2BrMAmZfpBFnnz1Tg",
	"details": "Z34JpbMZivzLwnVRLyG2",
	"table": "I2NOTqK1wZyFRnnCmJuB",
	"amountCells": "uyUCC8kW3NjMplMcG5o4",
	"negative": "uzBPEBQkLgDgq6F7PmQf",
	"positive": "rFuq39RR_5mQ3p6rul1u",
	"loadingEstimate": "TTJVoXQNnOmKosF9fTj0",
	"smallerText": "LQq8_cjRTaAcl3p0MYep"
};
export default ___CSS_LOADER_EXPORT___;
