import React from 'react';
import {
	Alert,
	Flex,
	Form,
	Input,
	Space,
	Tag,
} from 'antd';
import { useHistory } from 'react-router';
import { DeleteOutlined } from '@ant-design/icons';
import uuid from 'short-uuid';
import {
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { SupplierProps } from '@api/models/supplier';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import type { VoyageWithFixtureAndCounterparty } from '@api/features/voyages/getVoyageDetails';
import type { GetBunkerStemsResponse } from '@api/features/voyages/bunker-stems/getBunkerStems';
import type {
	Parcel,
	StemRob,
} from '@api/features/voyages/bunker-stems/createBunkerStem';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import { useAuth } from '@client/lib/auth';
import Select from '../Select';
import CountryFlag from '../CountryFlag';
import DatePicker from '../DatePicker';
import EditableCellTableRedux from '../EditableTableRedux/EditableCellTableRedux';
import AddButton from '../AddButton';
import CurrencySelect from '../CurrencySelect/CurrencySelect';
import CreditNoteCheckbox from '../CreditNoteCheckbox';
import styles from './CreateBunkerStemForm.module.css';

const required = [{ required: true, message: 'Field is required' }];

const getStemDateRange = (portCall: ItineraryPortCallDto | undefined) => {
	if (portCall == null) {
		return [];
	}

	const start = portCall.arrivalDate;
	const end = portCall.departureDate;

	return [start, end];
};

type Props = {
	onSubmit: () => Promise<void>;
	voyageId: number;
	suppliers: SupplierProps[] | undefined;
	parcels: Parcel[];
	setParcels: React.Dispatch<React.SetStateAction<Parcel[]>>;
	robs: StemRob[];
	setRobs: React.Dispatch<React.SetStateAction<StemRob[]>>;
	defaultCurrency: Values<typeof Currencies>;
	setCreatingNewSupplier: React.Dispatch<React.SetStateAction<boolean>>;
	selectedCurrencyAndRate: {
		currency: Values<typeof Currencies>; exchangeRate: number;
	};
	setSelectedCurrencyAndRate: React.Dispatch<React.SetStateAction<{
		currency: Values<typeof Currencies>; exchangeRate: number;
	}>>;
	creatingNewSupplier: boolean;
	onCloseForm: () => void;
	portCalls: ItineraryPortCallDto[];
	disableItinerarySelect: boolean;
	itineraryEntry: ItineraryPortCallDto | undefined;
	setItineraryEntry: React.Dispatch<React.SetStateAction<ItineraryPortCallDto | undefined>>;
	createCreditNote: boolean;
	setCreateCreditNote: React.Dispatch<React.SetStateAction<boolean>>;
	tcInContract?: VoyageWithFixtureAndCounterparty | null;
	editingStem?: GetBunkerStemsResponse[number] | undefined | null;
	dirty?: boolean;
}

const BunkerStemFormItems = ({
	onSubmit,
	parcels,
	setParcels,
	robs,
	setRobs,
	voyageId,
	suppliers,
	setCreatingNewSupplier,
	creatingNewSupplier,
	onCloseForm,
	portCalls,
	disableItinerarySelect,
	itineraryEntry,
	setItineraryEntry,
	tcInContract,
	editingStem,
	defaultCurrency,
	selectedCurrencyAndRate,
	setSelectedCurrencyAndRate,
	createCreditNote,
	setCreateCreditNote,
	dirty,
}: Props) => {
	const auth = useAuth();
	const history = useHistory();

	const goToItin = () => {
		onCloseForm();
		history.push(`${Links.Voyage.get(voyageId)}/#/itinerary`);
	};

	const [stemDateMin, stemDateMax] = getStemDateRange(itineraryEntry);

	return (
		<>
			<Form.Item
				name="supplierId"
				label="Supplier"
				rules={required}
				initialValue={editingStem?.supplierId}
			>
				<Select
					placeholder="Select Supplier"
					showSearch
					allowClear
					options={[
						{
							label: (<i>Create new...</i>),
							value: 'new',
						},
						...(suppliers ?? []).map((s) => ({
							label: s.name,
							value: s.id,
						}))]}
					onChange={(counterpartyId) => {
						setCreatingNewSupplier(counterpartyId === 'new');
					}}
				/>
			</Form.Item>
			<Form.Item
				fieldId="supplierName"
				name="supplierName"
				label="Supplier Name"
				hidden={!creatingNewSupplier}
				rules={[{ required: creatingNewSupplier, message: 'Field is missing' }]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				name="portCallId"
				rules={required}
				label="Select port call"
				initialValue={itineraryEntry?.id}
				extra={(
					<>
						{'Don\'t see the port call you\'re looking for? Check the '}
						<Button className={styles.noPaddingButton} onClick={goToItin} type="link">
							Itinerary
						</Button>
					</>
				)}
			>
				<Select
					showSearch
					disabled={disableItinerarySelect}
					options={portCalls.map((pc) => ({
						value: pc.id,
						label: (
							<Space>
								<Tag className={styles.tag} color={pc.estimated ? 'cyan' : 'geekblue'}>
									{pc.estimated ? 'Estimated' : 'Actual'}
								</Tag>
								<CountryFlag countryCode={pc.port.countryCode} />
								{pc.port.name}
							</Space>),
					}))}
					placeholder="Select Port"
					onSelect={(pcId) => {
						const portCall = portCalls.find((p) => p.id === pcId);
						setItineraryEntry(portCall);
					}}
				/>
			</Form.Item>
			<Flex
				gap={20}
				align="center"
			>
				<Form.Item
					name="stemDate"
					label="Stem Date"
					initialValue={itineraryEntry?.arrivalDate ?? itineraryEntry?.estimatedArrivalDate}
					rules={required}
					tooltip="Bunker stems are required to happen between the arrival and departure date of a given port call"
				>
					<DatePicker
						className={styles.datepicker}
						range={false}
						minDate={stemDateMin ?? undefined}
						maxDate={stemDateMax ?? undefined}
						time
					/>
				</Form.Item>
				<Form.Item
					name="invoiceDate"
					label="Invoice Date"
					initialValue={editingStem?.invoiceDate}
					className={styles.invoiceDate}
				>
					<DatePicker
						className={styles.datepicker}
						range={false}
					/>
				</Form.Item>
			</Flex>
			<Form.Item
				label="Parcel currency"
				name="currency"
				valuePropName="v"
			>
				<CurrencySelect
					baseCurrency={defaultCurrency}
					value={{
						currency: selectedCurrencyAndRate.currency,
						exchangeRate: selectedCurrencyAndRate.exchangeRate,
					}}
					onChange={(c, e) => {
						setSelectedCurrencyAndRate({
							currency: c,
							exchangeRate: e,
						});
					}}
				/>
			</Form.Item>
			{tcInContract != null && (
				<Alert
					showIcon
					message={`Any parcels created to account ${tcInContract?.fixture.fixtureCounterparty.name} \
					will result in a bunker expense also being created on contract ${tcInContract?.identifier} (the associated TC-in contract)`}
					className={styles.alert}
				/>
			)}
			<Form.Item label="Parcel(s)">
				<EditableCellTableRedux
					dataSource={parcels}
					size="small"
					pagination={false}
					onChange={(v) => setParcels(v)}
					emptyText="Empty"
					rowKey="id"
					columns={[
						{
							title: 'Account',
							dataIndex: 'account',
							editable: true,
							width: 100,
							type: 'select',
							inputProps: {
								options: [{
									label: auth?.userInfo?.organizationName ?? 'Owner',
									value: 'owner',
								},
								...(tcInContract != null ? [{
									label: `${tcInContract?.fixture.fixtureCounterparty.name}`,
									value: tcInContract?.id,
								}] : [])],
							},
						},
						{
							title: 'Fuel grade',
							dataIndex: 'fuelGrade',
							editable: true,
							width: 100,
							type: 'select',
							inputProps: {
								options: Object.keys(FuelTypes).map((key) => ({
									label: FuelTypes[key],
									value: key,
								})),
							},
						},
						{
							title: 'Quantity',
							dataIndex: 'quantity',
							editable: true,
							type: 'number',
							inputProps: {
								addonAfter: 'MT',
							},
							transformData: {
								in: Math.abs,
								out: Math.abs,
							},
						},
						{
							title: 'Price',
							dataIndex: 'pricePerTon',
							editable: true,
							type: 'currency',
							inputProps: {
								currency: selectedCurrencyAndRate.currency,
							},
						},
						{
							title: (
								<AddButton
									onClick={() => {
										setParcels((prev) => [
											...prev,
											{
												id: uuid.generate(),
												account: 'owner',
												fuelGrade: FuelTypes.VLSFO,
												quantity: 0,
												pricePerTon: 0,
												currency: selectedCurrencyAndRate.currency,
											},
										]);
									}}

								/>),
							// @ts-ignore
							dataIndex: 'addEntry',
							render: (row: Parcel) => (
								<Button
									onClick={() => setParcels((prev) => prev.filter((p) => p.id !== row.id))}
									type="text"
									confirmTitle="Are you sure you want to delete this row?"
									danger
									icon={(<DeleteOutlined />)}
								/>
							),
						},
					]}
				/>
			</Form.Item>
			<Form.Item label="ROB (including parcels)">
				<EditableCellTableRedux
					dataSource={robs}
					size="small"
					pagination={false}
					onChange={(v) => setRobs(v)}
					emptyText="Empty"
					columns={[
						{
							title: 'Fuel grade',
							dataIndex: 'fuelGrade',
							editable: true,
							width: 100,
							type: 'select',
							inputProps: {
								options: Object.keys(FuelTypes).map((key) => ({
									label: FuelTypes[key],
									value: key,
								})),
							},
						},
						{
							title: 'Quantity',
							dataIndex: 'quantity',
							editable: true,
							type: 'number',
							inputProps: {
								addonAfter: 'MT',
							},
							transformData: {
								in: Math.abs,
								out: Math.abs,
							},
						},
						{
							title: (
								<AddButton
									onClick={() => {
										setRobs((prev) => [
											...prev,
											{
												id: uuid.generate(),
												fuelGrade: FuelTypes.VLSFO,
												quantity: 0,
											},
										]);
									}}

								/>),
							// @ts-ignore
							dataIndex: 'addEntry',
							render: (row: StemRob) => (
								<Button
									onClick={() => setRobs((prev) => prev.filter((p) => p.id !== row.id))}
									type="text"
									confirmTitle="Are you sure you want to delete this row?"
									danger
									icon={(<DeleteOutlined />)}
								/>
							),
						},
					]}
				/>
			</Form.Item>
			<Button
				onClick={onSubmit}
				type="primary"
				confirmTitle={dirty ? (
					<CreditNoteCheckbox
						description={`One or more parcels have already been invoiced on ${tcInContract?.identifier} -
						do you want to generate a credit note?`}
						createCreditNote={createCreditNote}
						setCreateCreditNote={setCreateCreditNote}
						showCumulativeAlert={false}
					/>
				) : undefined}
			>
				Save Bunker Stem
			</Button>
		</>
	);
};

export default BunkerStemFormItems;
