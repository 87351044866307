import {
	useState,
	useMemo,
	useCallback,
} from 'react';

export type Upload = {
	file: File & { uid: string };
	url: string;
}

const useFileUpload = (options: { multiple?: boolean } = {}) => {
	const {
		multiple = false,
	} = options;

	const [uploads, setUploads] = useState<Upload[]>([]);

	const uploadProps = useMemo(() => ({
		beforeUpload: async (newFile: File & { uid: string }) => {
			const newUrl = window.URL.createObjectURL(newFile);

			setUploads((oldUploads) => {
				const newUpload = { file: newFile, url: newUrl };

				if (!multiple) {
					return [newUpload];
				}

				return [
					...oldUploads,
					newUpload,
				];
			});

			return false;
		},
	}), [multiple]);

	const setUploaded = useCallback((newUploads: Upload[]) => {
		setUploads(newUploads);
	}, []);

	const resetUploaded = useCallback(() => {
		setUploads([]);
	}, []);

	const removeUpload = useCallback((uid: string) => {
		setUploads((oldUploads) => oldUploads.filter((u) => u.file.uid !== uid));
	}, []);

	return {
		uploaded: multiple ? uploads : uploads[0],
		uploadProps,
		onRemoveUpload: removeUpload,
		onSetUploaded: setUploaded,
		onResetUploaded: resetUploaded,
	};
};

export default useFileUpload;
