// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EsYI2LZ4iiouLWCyyGZZ {\n\tcolor: inherit;\n\tfont-size: inherit;\n}\n\n.hhDlHf9Ms8Zl6GdnroJI .ant-badge-count {\n\tcolor: #999;\n\tbackground-color: #fff;\n\tbox-shadow: 0 0 0 1px #d9d9d9 inset;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/ExpenseCountBadge.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,sBAAsB;CACtB,mCAAmC;AACpC","sourcesContent":[".badge {\n\tcolor: inherit;\n\tfont-size: inherit;\n}\n\n.gray :global(.ant-badge-count) {\n\tcolor: #999;\n\tbackground-color: #fff;\n\tbox-shadow: 0 0 0 1px #d9d9d9 inset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "EsYI2LZ4iiouLWCyyGZZ",
	"gray": "hhDlHf9Ms8Zl6GdnroJI"
};
export default ___CSS_LOADER_EXPORT___;
