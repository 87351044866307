import React, {
	useEffect,
	useState,
} from 'react';
import { InputProps } from 'antd/lib/input';
import AsyncInput from '../Async/AsyncInput';

type Props = Omit<InputProps, 'onChange'> & {
	value: string;
	onChange: (v: string) => Promise<void> | void;
}

const EditableInput = ({
	value,
	onChange,
	...props
}: Props) => {
	const [inputState, setInputState] = useState(value ?? '');
	const [cache, setCache] = useState<string | null>(null);

	useEffect(() => {
		setInputState(value);
	}, [value]);

	return (
		<AsyncInput
			{...props}
			value={inputState}
			onChange={async (event) => {
				setInputState(event.target.value);
				if (cache == null) {
					setCache(inputState);
				}

				try {
					if (typeof onChange === 'function') {
						await onChange(event.target.value);
						setCache(null);
					}
				} catch (e) {
					setInputState(cache ?? '');
					setCache(null);
				}
			}}
		/>
	);
};

export default EditableInput;
