import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Image,
	Row,
	Space,
	Upload,
} from 'antd';
import { Moment } from 'moment';
import {
	InvoiceLogoModes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { VesselProps } from '@api/models/vessel';
import Select from '@client/components/Select';
import {
	createVesselOwnershipPeriod,
	deleteVesselOwnershipPeriod,
	getUserData,
	updateVesselLogo,
	updateVesselOwnershipPeriod,
} from '@client/lib/api';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import EditableTable from '@client/components/EditableTable';
import {
	getCommsItems,
	getGeneralItems,
	ownedPeriodsColumns,
} from '@client/screens/fleet/VesselDetailsScreen/helpers/descriptionItemsHelper';
import styles from '@client/screens/fleet/VesselDetailsScreen/VesselDetailsScreen.module.css';
import TooltipIcon from '@client/components/TooltipIcon';
import Button from '@client/components/Button';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import Card from '@client/components/Card/Card';

export type OwnerPeriod = Omit<GetVesselDetailsResponse['ownershipPeriods'][number],
	'createdAt' |
	'updatedAt' |
	'deletedAt' |
	'fromDate' |
	'toDate'
> & {
	fromDate: Moment;
	toDate: Moment | null;
};

type Props = {
	vessel: GetVesselDetailsResponse | undefined;
	vesselId: number;
	saveEdit: (details: any) => void;
	refreshVessel: () => void;
}

export const VesselDetailsTab: React.FC<Props> = ({
	vessel,
	vesselId,
	saveEdit,
	refreshVessel,
}) => {
	const [tempInvoiceLogoMode, setTempInvoiceLogoMode] = useState(vessel?.invoiceLogoMode);

	const [editValues, setEditValues] = useState<Partial<VesselProps> | undefined>({});

	useEffect(
		() => setTempInvoiceLogoMode(vessel?.invoiceLogoMode),
		[vessel?.invoiceLogoMode],
	);

	const commsItems = getCommsItems(vessel);
	const [userData] = useFetchedState(getUserData);
	const { keyDetails, vesselParticulars } = userData != null && vessel != null ?
		getGeneralItems(vessel, userData, editValues?.type) :
		{ keyDetails: [], vesselParticulars: [] };

	const addNewOwnershipPeriod = async (values: {fromDate: Moment; toDate: Moment | null}) => {
		await createVesselOwnershipPeriod(vesselId, values.fromDate, values.toDate);
		await refreshVessel();
	};

	const saveOwnershipPeriod = async (
		periodId: number,
		values: {fromDate: Moment | null; toDate: Moment | null},
	) => {
		await updateVesselOwnershipPeriod(vesselId, periodId, {
			fromDate: values.fromDate,
			toDate: values.toDate || null,
		});
		await refreshVessel();
	};

	const deleteOwnershipPeriod = async (periodId: number) => {
		await deleteVesselOwnershipPeriod(vesselId, periodId);
		await refreshVessel();
	};

	const onUploadImage = async (file: File) => {
		try {
			if (vessel == null) {
				return;
			}

			await updateVesselLogo(vessel.id, file);
			await refreshVessel();
		} catch (e) {
			showErrorNotification('Could not upload image', e as Error);
		}
	};

	const changeInvoiceLogoMode = async (newValue: 'NONE' | 'ORGANIZATION_LOGO' | 'CUSTOM_LOGO' | null) => {
		if (newValue == null) {
			return;
		}

		setTempInvoiceLogoMode(newValue);
		await saveEdit({ invoiceLogoMode: newValue });
	};

	const vesselOwnerShipPeriods = useMemo(() => vessel?.ownershipPeriods.map((p) => ({
		...p,
		fromDate: toMoment(p.fromDate),
		toDate: p.toDate != null ? toMoment(p.toDate) : null,
	})), [vessel]);

	return (
		<Row gutter={[16, 16]}>
			<Col span={12}>
				<Card>
					{keyDetails != null && (
						<EditableDetails
							data-cy="details"
							items={keyDetails}
							onEditValuesChange={(values: Partial<VesselProps> | undefined) => {
								setEditValues({ ...editValues, ...values });
							}}
							editValues={editValues}
							onCancel={() => setEditValues({})}
							onSave={() => {
								saveEdit(editValues);
								setEditValues({});
							}}
							title="Key Details"
							className={styles.keyDetailsTable}
						/>
					)}
					{vesselParticulars != null && vessel?.ownershipType !== VesselOwnershipTypes.MARKET && (
						<EditableDetails
							data-cy="details"
							// @ts-ignore
							items={vesselParticulars}
							onSave={saveEdit}
							title="Vessel Particulars"
							className={styles.particularsTable}
						/>
					)}
				</Card>
			</Col>
			{vesselParticulars != null && vessel?.ownershipType === VesselOwnershipTypes.MARKET && (
				<Col span={12}>
					<Card>
						<EditableDetails
							data-cy="details"
							// @ts-ignore
							items={vesselParticulars}
							onSave={saveEdit}
							title="Vessel Particulars"
							className={styles.particularsTable}
						/>
					</Card>
				</Col>
			)}
			<Col span={12}>
				{vessel?.ownershipType !== VesselOwnershipTypes.MARKET && (
					<Row gutter={[16, 16]}>
						<Col span={24}>
							{vessel?.ownershipType === VesselOwnershipTypes.OWNED && (
								<Card bordered>
									<EditableTable<OwnerPeriod, 'id'>
										keyDataIndex="id"
										key={`${vesselOwnerShipPeriods ?? [].length}-periods`}
										columns={ownedPeriodsColumns}
										pagination={false}
										dataSource={vesselOwnerShipPeriods ?? []}
										allowAddNew
										addNewText="Ownership Periods"
										iconButtons
										enableDelete
										onSave={saveOwnershipPeriod}
										onDelete={deleteOwnershipPeriod}
										onAddNew={addNewOwnershipPeriod}
									/>
								</Card>
							)}
						</Col>
						<Col span={24}>
							<Card
								bordered
								title="Vessel Communications"
							>
								<EditableDetails
									data-cy="comms"
									items={commsItems}
									onSave={saveEdit}
								/>
							</Card>
						</Col>
						<Col span={24}>
							<Card
								bordered
								title={(
									<>
										Invoice logo
										<TooltipIcon>
											{'This is the image that will be shown on this vessel\'s hire invoices'}
										</TooltipIcon>
									</>
								)}
								extra={(
									<Space>
										<span><b>Logo to show:</b></span>
										<Select
											className={styles.invoiceLogoMode}
											value={tempInvoiceLogoMode}
											onChange={changeInvoiceLogoMode}
											options={[
												{ label: 'None', value: InvoiceLogoModes.NONE },
												{ label: 'Organization Logo', value: InvoiceLogoModes.ORGANIZATION_LOGO },
												{ label: 'Upload Logo', value: InvoiceLogoModes.CUSTOM_LOGO },
											]}
											defaultValue={InvoiceLogoModes.ORGANIZATION_LOGO}
										/>
									</Space>
								)}
							>
								<Space
									direction="vertical"
									size="middle"
									className={styles.invoiceHeaderLogoWrapper}
								>
									{tempInvoiceLogoMode !== InvoiceLogoModes.NONE && (
										<Image
											width={200}
											src={vessel?.logo}
										/>
									)}
									{tempInvoiceLogoMode === InvoiceLogoModes.CUSTOM_LOGO && (
										<Upload
											beforeUpload={(file) => onUploadImage(file)}
											showUploadList={false}
										>
											<Button type="primary">Upload new</Button>
										</Upload>
									)}
									{tempInvoiceLogoMode === InvoiceLogoModes.NONE && (
										<span>No logo will be shown</span>
									)}
								</Space>
							</Card>
						</Col>

					</Row>
				)}
			</Col>
		</Row>
	);
};
