import React from 'react';
import {
	CargoUnitLabels,
	CargoUnitTypes,
	DefaultCargoUnit,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import NumericInput, { NumericInputProps } from './NumericInput';
import Select from './Select';

type ValueObject = {
	unit?: Values<typeof CargoUnitTypes>;
	quantity?: number;
};

export type CargoQuantityInputProps = ExtendUnique<{
	defaultUnit?: Values<typeof CargoUnitTypes>;
	value?: ValueObject | number;
	onChange?: (newValue: ValueObject) => void;
}, NumericInputProps>;

const CargoQuantityInput = ({
	value,
	onChange,
	placeholder,
	defaultUnit = DefaultCargoUnit,
}: CargoQuantityInputProps) => {
	const valueProp = {
		unit: defaultUnit,
		...(typeof value === 'object' ? value : {}),
		...(typeof value === 'number' ? ({ quantity: value }) : {}),
	};

	const {
		unit,
		quantity,
	} = valueProp;

	const changeValue = (newValue: Partial<ValueObject>) => {
		onChange?.({
			quantity: newValue.quantity ?? quantity,
			unit: newValue.unit ?? unit,
		});
	};

	const unitSelector = (
		<Select<Values<typeof CargoUnitTypes>>
			onSelect={(v) => changeValue({
				unit: (v as Values<typeof CargoUnitTypes>) ?? DefaultCargoUnit,
			})}
			defaultValue={defaultUnit}
			value={unit}
			options={Object.values(CargoUnitTypes).map((c) => ({
				label: CargoUnitLabels[c],
				value: c,
			}))}
		/>
	);

	return (
		<NumericInput
			value={quantity}
			addonAfter={unitSelector}
			defaultValue={quantity}
			allowNegative={false}
			placeholder={placeholder}
			onChange={(v) => changeValue({ quantity: v ?? undefined })}
		/>
	);
};

export default CargoQuantityInput;
