// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qEIpIcUX2d5iYsEjlGl4 {\n\tfont-size: 16px;\n\talign-items: center;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tflex: 1;\n}\n\n.qEIpIcUX2d5iYsEjlGl4.hJQoH28Z6fDoJ9iwq5Iw {\n\topacity: 0;\n}\n\n.RslPKN3hPRrEF9_Fj7cW {\n\tcursor: grab;\n\twidth: 30px !important;\n\ttext-align: left;\n\tdisplay: inline-block;\n\tcolor: rgba(0, 0, 0, 0.5) !important;\n\tmargin-right: 10px;\n}\n\n.klRfUPhb0W6bAecgfM4T {\n\tcursor: no-drop;\n\topacity: 0.25;\n}\n\n.gARRz7HNgUoQOKW1ETRu {\n\tdisplay: inline-flex;\n\talign-items: baseline;\n\tflex: 1;\n}\n\n.dS6k6D3BGM5owtX66GoS {\n\tmargin: 6px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/DraggableList/private/DraggableListItem.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,mBAAmB;CACnB,aAAa;CACb,8BAA8B;CAC9B,OAAO;AACR;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,YAAY;CACZ,sBAAsB;CACtB,gBAAgB;CAChB,qBAAqB;CACrB,oCAAoC;CACpC,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,aAAa;AACd;;AAEA;CACC,oBAAoB;CACpB,qBAAqB;CACrB,OAAO;AACR;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".item {\n\tfont-size: 16px;\n\talign-items: center;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tflex: 1;\n}\n\n.item.dragging {\n\topacity: 0;\n}\n\n.grip {\n\tcursor: grab;\n\twidth: 30px !important;\n\ttext-align: left;\n\tdisplay: inline-block;\n\tcolor: rgba(0, 0, 0, 0.5) !important;\n\tmargin-right: 10px;\n}\n\n.dragDisabled {\n\tcursor: no-drop;\n\topacity: 0.25;\n}\n\n.content {\n\tdisplay: inline-flex;\n\talign-items: baseline;\n\tflex: 1;\n}\n\n.slimDivider {\n\tmargin: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "qEIpIcUX2d5iYsEjlGl4",
	"dragging": "hJQoH28Z6fDoJ9iwq5Iw",
	"grip": "RslPKN3hPRrEF9_Fj7cW",
	"dragDisabled": "klRfUPhb0W6bAecgfM4T",
	"content": "gARRz7HNgUoQOKW1ETRu",
	"slimDivider": "dS6k6D3BGM5owtX66GoS"
};
export default ___CSS_LOADER_EXPORT___;
