// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X4mOGgJCJA6K4i9sYKrk {\n\tpadding-top: 8px;\n\tpadding-right: 24px;\n}\n\n.sF4WIzHZuN7cwEtoTQBS > svg {\n\toverflow: visible;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/charts/LineChart/PerformanceLineChart.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".wrapper {\n\tpadding-top: 8px;\n\tpadding-right: 24px;\n}\n\n.lineChart > svg {\n\toverflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "X4mOGgJCJA6K4i9sYKrk",
	"lineChart": "sF4WIzHZuN7cwEtoTQBS"
};
export default ___CSS_LOADER_EXPORT___;
