import React, {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
	createContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { IMPERSONATION_ID_LOCAL_STORAGE_KEY } from '@shared/utils/constants';
import { Links } from '@client/utils/links';
import { setImpersonationId } from './api';

const ImpersonationContext = createContext();

export const useImpersonation = () => useContext(ImpersonationContext);

export const ImpersonationProvider = ({ children }) => {
	const history = useHistory();

	const [isImpersonating, setIsImpersonating] = useState(false);
	const [impersonatingOrgId, setImpersonatingOrgId] = useState(null);

	const goHome = useCallback(() => {
		// Create an url for the home page and go to it
		// We do this instead of history.push so the browser will reload the page
		const url = history.createHref({ pathname: Links.Home.get() });
		window.location.replace(url);
	}, [history]);

	useEffect(() => {
		const organizationIdStr = localStorage.getItem(IMPERSONATION_ID_LOCAL_STORAGE_KEY);

		if (organizationIdStr != null) {
			const organizationId = Number(organizationIdStr);

			// Set local state
			setIsImpersonating(true);
			setImpersonatingOrgId(organizationId);

			// Set for API
			setImpersonationId(organizationId);
		}
	}, []);

	const beginImpersonating = useCallback((organizationId) => {
		localStorage.setItem(IMPERSONATION_ID_LOCAL_STORAGE_KEY, organizationId);

		goHome();
	}, [goHome]);

	const stopImpersonating = useCallback(() => {
		localStorage.removeItem(IMPERSONATION_ID_LOCAL_STORAGE_KEY);

		goHome();
	}, [goHome]);

	const value = useMemo(() => ({
		isImpersonating,
		orgId: impersonatingOrgId,
		beginImpersonating,
		stopImpersonating,
	}), [isImpersonating, impersonatingOrgId, beginImpersonating, stopImpersonating]);

	return (
		<ImpersonationContext.Provider
			value={value}
		>
			{children}
		</ImpersonationContext.Provider>
	);
};
