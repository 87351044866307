// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fZqSLBIAufmXl4M_Ldf9 > div {\n\tbox-sizing: border-box;\n\toutline: 1px solid #f00 !important;\n\tborder-radius: 3px;\n\tbox-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.28);\n\t-webkit-box-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.28);\n\t-moz-box-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.28);\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/EditableTableRedux/styles/EditableCellRedux.module.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,kCAAkC;CAClC,kBAAkB;CAClB,6CAA6C;CAC7C,qDAAqD;CACrD,kDAAkD;AACnD","sourcesContent":[".missing > div {\n\tbox-sizing: border-box;\n\toutline: 1px solid #f00 !important;\n\tborder-radius: 3px;\n\tbox-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.28);\n\t-webkit-box-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.28);\n\t-moz-box-shadow: 0 0 5px 1px rgba(255, 0, 0, 0.28);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"missing": "fZqSLBIAufmXl4M_Ldf9"
};
export default ___CSS_LOADER_EXPORT___;
