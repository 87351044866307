import React, { useMemo } from 'react';
import {
	Col,
	Row,
} from 'antd';
import sortBy from 'lodash.sortby';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import { updateOffHireTypes } from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import EditableTable from '@client/components/EditableTable';
import { useAuth } from '@client/lib/auth';

const OffHireTypes = () => {
	const { userInfo, refreshAuthInfo } = useAuth();

	const offHireTypes = useMemo(() => (userInfo as UserData)?.orgOffHireTypes, [userInfo]);

	const formattedOffHireTypes = useMemo(() => {
		return offHireTypes?.map((ot) => {
			return { offHireType: ot };
		});
	}, [offHireTypes]);

	const onSave = async (editingEntry: string | null, values: { offHireType: string }) => {
		const newOffHireTypesArr: string[] = offHireTypes ?? [];

		if (editingEntry != null) {
			const indexOfEditingEntry = newOffHireTypesArr?.findIndex((t) => t === editingEntry);
			newOffHireTypesArr?.splice(indexOfEditingEntry, 1);
		}

		if (newOffHireTypesArr?.find((t) => t === values.offHireType) != null) {
			showErrorNotification('Off-hire type with that name already exists', undefined, { duration: 5 });

			return;
		}

		if (values.offHireType === '' || values.offHireType == null) {
			showErrorNotification('Off-hire type cannot be empty', undefined, { duration: 5 });

			return;
		}

		newOffHireTypesArr.push(values.offHireType);

		try {
			await updateOffHireTypes({
				offHireTypes: newOffHireTypesArr || offHireTypes,
			});
			showSuccessNotification('Off-hire types updated');
			refreshAuthInfo();
		} catch (e) {
			showErrorNotification('Cannot update off-hire types');
		}
	};

	const onDelete = async (values: string) => {
		const newOffHireTypesArr = offHireTypes;

		const indexOfEntryToDelete = offHireTypes?.findIndex((ot) => ot === values);

		if (indexOfEntryToDelete != null) {
			newOffHireTypesArr?.splice(indexOfEntryToDelete, 1);

			try {
				await updateOffHireTypes({
					offHireTypes: newOffHireTypesArr || offHireTypes,
				});
			} catch (e) {
				showErrorNotification('Cannot update off-hire types');
			}

			refreshAuthInfo();
		}
	};

	return (
		<Row>
			<Col xxl={8} xl={10} lg={16} sm={18}>
				<EditableTable
					size="small"
					// @ts-ignore
					columns={[{
						dataIndex: 'offHireType',
						title: 'Type',
						editable: true,
					}]}
					dataSource={sortBy(formattedOffHireTypes) ?? []}
					pagination={false}
					allowAddNew
					enableDelete
					enableEdit={() => true}
					onAddNew={(newEntry) => onSave(null, newEntry)}
					onDelete={(item) => onDelete(item)}
					onSave={(editingEntry, newEntry) => onSave(editingEntry, newEntry)}
					iconButtons
					keyDataIndex="offHireType"
					addNewText="Add new off-hire type"
					extraOnCancelEdit={refreshAuthInfo}
				/>
			</Col>
		</Row>
	);
};

export default OffHireTypes;
