import React from 'react';
import {
	Card,
	Col,
	Row,
	notification,
} from 'antd';
import {
	useParams,
	Link,
} from 'react-router-dom';
import { DATE_AND_TIME } from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import { formatDate } from '@client/utils/formatDate';
import {
	getUserGroupDetails,
	deleteUserGroup,
	updateUserGroup,
} from '@client/lib/api';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import Table from '@client/components/Table/Table';
import history from '@client/utils/history';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';

const getItems = ({
	id,
	name,
	createdAt,
	updatedAt,
}) => [
	{
		key: 'id',
		label: 'Id',
		value: id,
		width: 80,
		editable: false,
	},
	{
		key: 'name',
		label: 'Name',
		value: name,
		editable: true,
	},
	{
		key: 'createdAt',
		label: 'Creation date',
		value: formatDate(createdAt, DATE_AND_TIME),
		editable: false,
	},
	{
		key: 'updatedAt',
		label: 'Last updated',
		value: formatDate(updatedAt, DATE_AND_TIME),
		editable: false,
	},
];

const userColumns = [
	{
		title: 'Id',
		dataIndex: 'id',
		key: 'id',
		sorter: standardSort('id'),
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		render: (name, { id }) => (
			<Link to={Links.User.get(id)}>
				{name}
			</Link>
		),
		sorter: standardSort('name'),
	},
];

const UserDetailsScreen = () => {
	const { id } = useParams();

	const [
		group,
		refreshGroup,
		error,
		loading,
	] = useFetchedState(
		() => getUserGroupDetails(id),
		[],
		{ showNotification: false },
	);

	const save = async (values) => {
		if (Object.keys(values).length > 0) {
			await updateUserGroup(id, values);
		}

		await refreshGroup();
	};

	const deleteGroup = async () => {
		try {
			await deleteUserGroup(id);

			notification.success({
				message: 'Group deleted',
				description: `The group "${group?.name}" has successfully been deleted.`,
			});

			history.goBack();
		} catch (e) {
			showErrorNotification('Could not delete group', e);
		}
	};

	return (
		<SimpleScreen
			canGoBack
			title={group?.name}
			rootPageTitle="Team Members Groups"
			loading={loading}
			headerActions={[(
				<Button
					key="delete-group"
					confirmTitle={`Are you sure you want to delete the group ${group?.name}?`}
					onClick={deleteGroup}
					type="primary"
					danger
				>
					Delete group
				</Button>
			)]}
			error={error}
		>
			<Row gutter={16}>
				<Col span={12}>
					<Card>
						<EditableDetails
							items={group != null ? getItems(group) : []}
							onSave={save}
						/>
					</Card>
				</Col>
				<Col span={12}>
					<Card title="Team Members">
						<Table
							rowKey="id"
							dataSource={group?.users ?? []}
							columns={userColumns}
							emptyText="No Team Members"
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default UserDetailsScreen;
