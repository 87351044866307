import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Divider,
	Typography,
} from 'antd';
import { standardSort } from '@shared/utils/standardSort';
import type { GetVideoGuidesResponse } from '@api/features/admin/getVideoGuides';
import type { VideoGuideProps } from '@api/models/video-guide';
import EditableCellTableRedux, { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import { updateVideoGuideOrder } from '@client/lib/api';

type Props = {
	videos: GetVideoGuidesResponse;
	refresh: () => void;
	loading: boolean;
}

const PagesWithVideosTab = ({
	videos,
	refresh,
	loading,
}: Props) => {
	const uniquePagesWithVideo = useMemo(() => {
		const pages = new Set<string>();
		videos.forEach((v) => {
			if (v.page != null) {
				pages.add(v.page);
			}
		});

		return Array.from(pages);
	}, [videos]);

	const videoColumns: EditableColumns<VideoGuideProps> = useMemo(() => ([
		{
			title: 'Title',
			dataIndex: 'title',
			type: 'text',
			editable: false,
		},
		{
			title: 'Description',
			dataIndex: 'description',
			type: 'text',
			editable: false,
		},
	]), []);

	const handleChange = useCallback(async (newEntries: VideoGuideProps[]) => {
		await updateVideoGuideOrder(newEntries);
		refresh();
	}, [refresh]);

	const tables = useMemo(() => {
		return uniquePagesWithVideo.map((page) => {
			const relevantVideos = videos
				.filter((v) => v.page === page)
				.sort(standardSort('order'));

			return (
				<div>
					<Typography.Title level={3}>{page}</Typography.Title>
					<EditableCellTableRedux
						columns={videoColumns}
						dataSource={relevantVideos}
						onChange={handleChange}
						pagination={false}
						loading={loading}
						bordered
						size="small"
						tableLayout="fixed"
						reorderable
						orderKey="order"
						rowKey={(row) => `${row.id}`}
						isDroppable={() => true}
						isDraggable={() => true}
					/>
					<Divider />
				</div>
			);
		});
	}, [handleChange, loading, uniquePagesWithVideo, videoColumns, videos]);

	return (
		<>
			{tables}
		</>
	);
};

export default PagesWithVideosTab;
