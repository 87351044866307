import type { AttachmentProps } from '@api/models/attachment';
import { getAttachmentUrl } from '@client/lib/api';

const getFileFromAttachment = async (attachment: AttachmentProps) => {
	const attachmentUrl = await getAttachmentUrl(attachment.id);

	const response = await fetch(attachmentUrl);

	if (!response.ok) {
		throw new Error('Response not OK');
	}

	const blob = await response.blob();

	const file = new File([blob], attachment.name, { type: attachment.type });

	return file;
};

export default getFileFromAttachment;
