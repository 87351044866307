import type getPortsAndRanges from '@api/features/geo/getPortsAndRanges';
import type {
	Port,
	Range,
} from '@api/utils/ports';

export const getPortOptions = (
	ports: Port[],
) => {
	ports.sort((a, b) => a.name.localeCompare(b.name));

	return ports.map((p) => ({
		label: p.name,
		value: p.id,
	}));
};

const getPortAndRangeOptions = (
	portsAndRanges: ReturnType<typeof getPortsAndRanges>,
	customPort: string | null = null,
) => {
	const ports = portsAndRanges.filter((p): p is Port => (p as Port).isPort);
	const ranges = portsAndRanges.filter((p): p is Range => (p as Range).isRange);
	ports.sort((a, b) => a.name.localeCompare(b.name));
	ranges.sort((a, b) => a.name.localeCompare(b.name));

	let showCustomPort = false;

	if (customPort != null) {
		const portNames = ports.map((e) => e.name);

		if (!portNames.includes(customPort.toUpperCase())) {
			showCustomPort = true;
		}
	}

	if (ports.length > 0 && ranges.length > 0) {
		return [
			{
				label: 'Ranges',
				options: ranges.map((v) => ({
					label: `${v.name} [RANGE]`,
					value: v.id,
				})),
			},
			{
				label: 'Ports',
				options: ports.map((p) => ({
					label: p.name,
					value: p.id,
				})),
			},
			...((showCustomPort && customPort != null) ? [{
				label: 'Add custom',
				options: [{
					label: `${customPort.toUpperCase()} [CUSTOM]`,
					value: `${customPort.toUpperCase()}`,
				}],
			}] : []),
		];
	}

	return (ports.length > 0 ? ports : ranges).map((p) => ({
		label: p.name,
		value: p.id,
	}));
};

export default getPortAndRangeOptions;
