import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Col,
	Empty,
	Row,
	Space,
} from 'antd';
import { Moment } from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import isEqual from 'lodash.isequal';
import { toMoment } from '@shared/utils/date';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@api/features/ops/getVesselItinerary';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetVoyageItineraryResponse } from '@api/features/voyages/getVoyageItinerary';
import {
	createItineraryEntry,
	getPorts,
	getTimezoneOffset,
	getVesselDetails,
	reorderPortCall,
	updateVoyage,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getTabs } from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/getTabs';
import showErrorNotification from '@client/utils/showErrorNotification';
import LoadingIndicator from '@client/components/LoadingIndicator';
import getPortAndRangeOptions from '@client/utils/getPortAndRangeOptions';
import manOnBoat from '@client/assets/images/man_on_boat.svg';
import FormPopover from '@client/components/FormPopover';
import Itinerary from './Itinerary';
import styles from './ItineraryTab.module.css';

const ItineraryTab = ({
	voyageDetails,
	refreshDetails,
	itinerary,
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	itineraryError,
	itineraryLoading,
}: {
	itineraryLoading: boolean;
	itineraryError: Error | null;
	voyageDetails: GetVoyageDetailsResponse;
	refreshDetails: () => Promise<void>;
	itinerary: GetVoyageItineraryResponse | undefined;
	startDate: string | null;
	setStartDate: (date: string | null) => void;
	endDate: string | null;
	setEndDate: (date: string | null) => void;
}) => {
	const { vesselId } = voyageDetails;
	const [activeTab, setActiveTab] = useState('summary');
	const [initialPort, setInitialPort] = useState<number | undefined>();
	const [selectedTimeFormat, setSelectedTimeFormat] = useState<'utc' | 'localTime'>(voyageDetails.itineraryUseUTC ? 'utc' : 'localTime');
	const [selectedEntry, setSelectedEntry] = useState<
		ItinerarySeaPassageDto | ItineraryPortCallDto>();
	const [selectedIndex, setSelectedIndex] = useState<number | null>();
	const [itineraryLength, setItineraryLength] = useState(0);
	const [entryAdded, setEntryAdded] = useState(false);
	const [updateItineraryLoading, setUpdateItineraryLoading] = useState(false);

	const updateTimeFormat = async (format: 'utc' | 'localTime') => {
		await updateVoyage(voyageDetails.id, { itineraryUseUTC: format === 'utc' });
		setSelectedTimeFormat(format);
	};

	const [defaultPortTzOffset, _refresh, _error, _tzLoading] = useFetchedState(
		async () => {
			if (initialPort == null) {
				return 0;
			}

			const port = ports?.find((p) => p.id === initialPort);

			if (port == null) {
				return 0;
			}

			return await getTimezoneOffset(port.latitude, port.longitude);
		},
		[initialPort],
	);

	const [vessel, refreshVessel] = useFetchedState(() => getVesselDetails(Number(vesselId)));
	const [ports] = useFetchedState(getPorts);
	const portOptions = useMemo(() => getPortAndRangeOptions(ports ?? []), [ports]);

	const isConsMissingForSpeed = useMemo(() => {
		if (selectedEntry == null || isItemPortCall(selectedEntry)) {
			return false;
		}

		const selectedSpeed = selectedEntry.routeOptions.speed;
		const performanceEntries = vessel?.performanceEntries;

		if (performanceEntries == null) {
			return false;
		}

		return performanceEntries.find((s) => s.speed === selectedSpeed) == null;
	}, [selectedEntry, vessel?.performanceEntries]);

	/*
	* Canal transit sea passages and port calls are destroyed between recalculations,
	* so we need some custom logic for re-selecting whichever item the user has changed.
	* */
	useEffect(() => {
		if (itinerary == null) {
			return;
		}

		let updatedEntry;

		if (selectedEntry == null) {
			setSelectedEntry(itinerary[0]);
			setSelectedIndex(0);

			return;
		}

		if (itineraryLength !== itinerary.length && entryAdded) {
			updatedEntry = itinerary[itinerary.length - 1];
			setSelectedIndex(itinerary.length - 1);
			setItineraryLength(itinerary.length);
			setEntryAdded(false);
		} else if (selectedIndex != null) {
			updatedEntry = itinerary[selectedIndex];
		}

		if (!isEqual(updatedEntry, selectedEntry)) {
			setSelectedEntry(updatedEntry);
		}
	}, [
		itineraryLength,
		itinerary,
		itinerary?.length,
		selectedEntry,
		selectedIndex,
		setSelectedEntry,
	]);

	const onReorderPortCall = useCallback(async (draggedIndex: number, droppedOverIndex: number) => {
		setUpdateItineraryLoading(true);

		try {
			const draggedId = itinerary?.[draggedIndex]?.id;
			const droppedOverId = itinerary?.[droppedOverIndex]?.id;

			if (draggedId == null) {
				throw new Error(`Couldn't find id of dragged item with index ${draggedIndex}`);
			}

			if (droppedOverId == null) {
				throw new Error(`Couldn't find id of dropped-over item with index ${droppedOverId}`);
			}

			try {
				await reorderPortCall({
					vesselId: Number(vesselId),
					draggedId,
					droppedOverId,
					voyageId: voyageDetails.id,
				});
			} catch (error) {
				showErrorNotification('Could not re-order itinerary', error as Error);
			}

			try {
				await refreshDetails();
			} catch (error) {
				showErrorNotification(
					'Could not refresh itinerary, please reload the page',
					error as Error,
					{ showReload: true },
				);
			}
		} finally {
			setUpdateItineraryLoading(false);
		}
	}, [itinerary, refreshDetails, vesselId, voyageDetails.id]);

	const latestActualDate = useMemo(() => {
		if (itinerary == null || itinerary.length === 0) {
			return null;
		}

		let candidate: Moment | null = null;
		itinerary.forEach((item) => {
			if (!isItemPortCall(item)) {
				return;
			}

			if (item.departureDate != null) {
				if (
					candidate == null ||
					item.departureDate.isAfter(candidate)
				) {
					candidate = item.departureDate;

					return;
				}
			}

			if (item.arrivalDate != null) {
				if (
					candidate == null ||
					item.arrivalDate.isAfter(candidate)
				) {
					candidate = item.arrivalDate;
				}
			}
		});

		return candidate;
	}, [itinerary]);

	const latestEstimatedDate = useMemo(() => {
		if (selectedEntry == null) {
			return null;
		}

		const prevSeaPassage = itinerary?.find((item): item is ItinerarySeaPassageDto => {
			return !isItemPortCall(item) && item.nextPortCallId === selectedEntry.id;
		});

		if (prevSeaPassage == null) {
			return null;
		}

		const prevPortCall = itinerary?.find(
			(pc): pc is ItineraryPortCallDto => pc.id === prevSeaPassage.prevPortCallId,
		);

		if (prevPortCall == null) {
			return null;
		}

		return prevPortCall.estimatedDepartureDate;
	}, [itinerary, selectedEntry]);

	const isPreviousActual = useMemo(() => {
		if (selectedEntry == null) {
			return false;
		}

		if (!isItemPortCall(selectedEntry)) {
			const prevPortCall = itinerary?.find((item): item is ItineraryPortCallDto => {
				return isItemPortCall(item) && item?.nextSeaPassageId === selectedEntry.id;
			});

			return prevPortCall != null && prevPortCall.arrivalDate != null;
		}

		const prevSeaPassage = itinerary?.find((item): item is ItinerarySeaPassageDto => {
			return !isItemPortCall(item) && item.nextPortCallId === selectedEntry.id;
		});

		if (prevSeaPassage == null) {
			return true;
		}

		const prevPortCall = itinerary?.find((item): item is ItineraryPortCallDto => {
			return isItemPortCall(item) && prevSeaPassage?.prevPortCallId === item.id;
		});

		return prevPortCall?.departureDate != null;
	}, [itinerary, selectedEntry]);

	const { tabContent, tabList } = getTabs({
		itinerary,
		voyageDetails,
		selectedEntry,
		setSelectedEntry,
		refreshDetails,
		setActiveTab,
		selectedTimeFormat,
		ports,
		latestActualDate,
		latestEstimatedDate,
		isPreviousActual,
		vessel,
		setSelectedIndex,
		refreshVessel: async () => {
			refreshVessel();
			await refreshDetails();
		},
		isConsMissingForSpeed,
	});

	const onAddEntry = async ({
		portId,
		estimatedDepartureDate,
	} : {
		portId: number;
		estimatedDepartureDate?: Moment;
	}) => {
		await createItineraryEntry({
			vesselId: Number(vesselId),
			portId,
			savingIndicator: true,
			voyageId: voyageDetails.id,
			estimatedDepartureDate,
		});

		await refreshDetails();

		if (itinerary == null) {
			return;
		}

		setItineraryLength(itinerary.length + 1);
		setEntryAdded(true);
	};

	if (itinerary?.length === 0) {
		return (
			<div className={styles.emptyWrapper}>
				<Card>
					<Empty
						className={styles.emptyMessage}
						description={(
							<Row gutter={[16, 16]}>
								<Col span={24}>
									The itinerary is currently empty.
									<br />
									To get started, add a the first port call.
								</Col>
								<Col span={24}>
									<FormPopover
										title="Add planned port call"
										popoverWidth={400}
										fields={[
											{
												label: 'Port',
												required: true,
												name: 'portId',
												type: 'select',
												options: portOptions,
												inputProps: {
													showSearch: true,
													onSelect: setInitialPort,
												},
											},
											{
												label: 'Est. Departure Date',
												required: true,
												name: 'estimatedDepartureDate',
												type: 'date',
												inputProps: {
													time: true,
													forceUtcUpdate: true,
													defaultUtcOffset: defaultPortTzOffset,
													showTimezone: true,
												},
											},
										]}
										buttonText={(
											<Space>
												<FontAwesomeIcon icon={faPlus as IconProp} />
												Add Planned Port Call
											</Space>
										)}
										disabled={false}
										onSubmit={onAddEntry}
									/>
								</Col>
							</Row>
						)}
						image={manOnBoat}
					/>
				</Card>
			</div>
		);
	}

	return (
		<Row gutter={[4, 4]}>
			<Col span={8}>
				{updateItineraryLoading && (
					<div className={styles.itinLoadingOverlay}>
						<LoadingIndicator />
					</div>
				)}
				<Itinerary
					loading={itineraryLoading}
					error={itineraryError}
					entries={itinerary ?? []}
					addEntry={onAddEntry}
					selectedEntry={selectedEntry}
					setActiveTab={setActiveTab}
					setSelectedEntry={(entry) => {
						setSelectedEntry(entry);

						const isPortCall = isItemPortCall(entry);

						const index = itinerary?.findIndex((e) => {
							if (isPortCall) {
								return e.id === entry.id && isItemPortCall(e);
							}

							return e.id === entry.id && !isItemPortCall(e);
						});
						setSelectedIndex(index);
					}}
					lastActualPortCallDate={
						vessel?.lastActualPortCallArrivalDate != null ?
							toMoment(vessel?.lastActualPortCallArrivalDate) :
							null
					}
					reorderPortCall={onReorderPortCall}
					portOptions={portOptions}
					selectedTimeFormat={selectedTimeFormat}
					setSelectedTimeFormat={updateTimeFormat}
					startDate={startDate}
					endDate={endDate}
					setStartDate={setStartDate}
					setEndDate={setEndDate}
				/>
			</Col>
			<Col span={16}>
				<Card
					onTabChange={setActiveTab}
					activeTabKey={activeTab}
					tabList={tabList}
					className={styles.tabsCard}
				>
					<div className={styles.tabContent}>
						{tabContent != null ? tabContent[activeTab] : null}
					</div>
				</Card>
			</Col>
		</Row>
	);
};

export default ItineraryTab;
