import React, { ReactNode } from 'react';
import {
	faChartPieAlt,
	faShield,
	faFileSignature,
	faShip,
	faAddressBook,
	faRoute,
	faGlobeEurope,
	faFileInvoiceDollar,
	faCubes,
	faCheckCircle,
	IconDefinition,
	faMailbox,
	faListCheck,
	faCalculator,
} from '@fortawesome/pro-duotone-svg-icons';
import {
	faCorner,
	faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { Space } from 'antd';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	AccountingItemApprovalStates,
	FixtureTypes,
} from '@shared/utils/constants';
import AccountingItemCountBadge from '@client/components/AccountingItemCountBadge';
import { Links } from '@client/utils/links';

export type MenuItemSection = (
	'HOME' |
	'ADMIN' |
	'FIXTURES' |
	'EXPENSES' |
	'APPROVALS' |
	'COMPANY_SETTINGS' |
	'VESSELS' |
	'COUNTERPARTIES' |
	'VOYAGES' |
	'MAP' |
	'CARGOS' |
	'ACCOUNTING_ITEMS' |
	'ACCOUNTING'
);

type MenuItem = {
	text: string | ReactNode;
	icon: IconDefinition;
	admin?: boolean;
	hidden?: boolean;
} & (
	{
		link: string;
	} |
	{
		children: Omit<MenuItem, 'icon'>[];
	}
)

type GetMenuItems = (options: {
	showSpotFixtures: boolean;
	showPerformance: boolean;
}) => Record<MenuItemSection, MenuItem>

const getMenuItems: GetMenuItems = (_options) => ({
	HOME: {
		text: 'Home',
		link: Links.Home.get(),
		icon: faChartPieAlt,
	},
	ADMIN: {
		text: 'Admin',
		icon: faShield,
		admin: true,
		children: [
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							Organizations
						</Space>
					</>
				),
				link: Links.Organizations.get(),
			},
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							Video Guides
						</Space>
					</>
				),
				link: Links.VideoGuides.get(),
			},
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							Feature Highlights
						</Space>
					</>
				),
				link: Links.FeatureHighlights.get(),
			},
		],
	},
	MANAGEMENT: {
		text: 'Management',
		icon: faListCheck,
		management: true,
		children: [
			{
				text: (
					<AccountingItemCountBadge
						state={AccountingItemApprovalStates.SUBMITTED}
						types="both"
					>
						Approvals
					</AccountingItemCountBadge>
				),
				'data-tour': 'menuItemExpenseApproval',
				link: Links.Management.Approvals.get(),
				icon: faCheckCircle,
			},
			{
				text: 'Company Settings',
				link: Links.Management.Settings.get(),
			},
		],
	},
	APPROVALS: {
		management: true,
		text: (
			<AccountingItemCountBadge
				state={AccountingItemApprovalStates.SUBMITTED}
				types="both"
			>
				Approvals
			</AccountingItemCountBadge>
		),
		link: Links.Management.Approvals.get(),
		icon: faCheckCircle,
	},
	COMPANY_SETTINGS: {
		management: true,
		text: 'Company Settings',
		link: Links.Management.Settings.get(),
		icon: faWrench,
	},
	FIXTURES: {
		text: 'Estimates',
		'data-tour': 'menuItemFixtures',
		icon: faFileSignature,
		children: [
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							TC-Out
						</Space>
					</>
				),
				link: Links.Fixtures[FixtureTypes.TC_OUT].List.get(),
			},
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							TC-In
						</Space>
					</>
				),
				link: Links.Fixtures[FixtureTypes.TC_IN].List.get(),
			},
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							VC-Out
						</Space>
					</>
				),
				link: Links.Fixtures[FixtureTypes.SPOT].List.get(),
			},
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							BB-Out
						</Space>
					</>
				),
				link: Links.Fixtures[FixtureTypes.BB_OUT].List.get(),
			},
		],
	},
	EXPENSES: {
		text: 'Vendor Expenses',
		'data-tour': 'menuItemVendorExpenses',
		link: Links.Expenses.get(),
		icon: faFileInvoiceDollar,
	},
	CARGOS: {
		text: 'Cargos',
		link: Links.Cargos.get(),
		icon: faCubes,
	},
	ACCOUNTING_ITEMS: {
		text: 'Postings',
		link: Links.AccountingItems.get(),
		icon: faFileInvoiceDollar,
	},
	ACCOUNTING: {
		text: 'Accounting',
		icon: faCalculator,
		children: [
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							Accruals
						</Space>
					</>
				),
				link: Links.Accounting.Accruals.get(),
			},
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							Previous Closes
						</Space>
					</>
				),
				link: Links.Accounting.PreviousCloses.get(),
			},
			{
				text: (
					<>
						<Space>
							<FontAwesomeIcon
								rotation={180}
								icon={faCorner as Icon}
							/>
							Mappings
						</Space>
					</>
				),
				link: Links.Accounting.Mapping.get(),
			},
		],
	},
	INBOX: {
		text: 'Inbox',
		link: Links.Inbox.get(),
		icon: faMailbox,
	},
	VESSELS: {
		text: 'Vessels',
		'data-tour': 'menuItemVessels',
		link: Links.Vessels.get(),
		icon: faShip,
	},
	COUNTERPARTIES: {
		text: 'Counterparties',
		'data-tour': 'menuItemCounterparties',
		icon: faAddressBook,
		link: Links.Counterparties.get(),
	},
	VOYAGES: {
		text: 'Contracts',
		'data-tour': 'menuItemVoyages',
		link: Links.Voyages.get(),
		icon: faRoute,
	},
	MAP: {
		text: 'Map',
		'data-tour': 'menuItemMap',
		icon: faGlobeEurope,
		link: Links.Map.get(),
	},
});

export default getMenuItems;
