import React, {
	useEffect,
	useState,
} from 'react';
import { CheckboxProps } from 'antd/lib/checkbox';
// eslint-disable-next-line no-restricted-imports
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';

type Props<T> = Omit<CheckboxProps, 'onChange'> & {
	onChange: (e: T) => Promise<void> | void;
};

const AsyncSelect = <T extends SelectValue, >({ value, onChange, ...rest }: Props<T>) => {
	const [localValue, setLocalValue] = useState(value);
	const [cache, setCache] = useState(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (error) {
			setLocalValue(cache);
			setCache(null);
			setError(false);
		}
	}, [cache, error]);

	return (
		<Select
			value={localValue}
			onChange={async (v: T) => {
				if (cache == null) {
					setCache(localValue);
				}

				setLocalValue(v);

				try {
					if (typeof onChange === 'function') {
						await onChange(v);
						setCache(null);
					}
				} catch (e) {
					setError(true);
				}
			}}
			{...rest}
		/>
	);
};

export default AsyncSelect;

