const formatMinutes = (minutes: number) => {
	if (minutes === 0 || minutes == null) {
		return '';
	}

	const days = minutes / 1440;

	return `${days.toFixed(3)} days`;
};

export default formatMinutes;

