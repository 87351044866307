import React from 'react';
import { Typography } from 'antd';
import {
	AccountingSystemProviders,
	AccountingSystemStrategies,
	Currencies,
	DATE_AND_TIME,
	FeatureFlags,
	InvoiceTemplates,
} from '@shared/utils/constants';
import { generateActivationUrl } from '@shared/utils/generateActivationUrl';
import { formatHumanReadable } from '@shared/utils/string';
import type { OrganizationProps } from '@api/models/organization';
import { formatDate } from '@client/utils/formatDate';
import { EditableDetailsItem } from '@client/components/EditableField';

const getItems = (
	org: OrganizationProps,
): EditableDetailsItem<any>[] | null => {
	const activationLink = generateActivationUrl(org?.activationToken ?? '', window.location.origin);

	return [
		{
			key: 'name',
			label: 'Name',
			value: org.name,
			type: 'text',
			editable: true,
		},
		{
			key: 'providerIds',
			label: 'Provider ID(s)',
			value: org.providerIds,
			editable: true,
			type: 'list',
		},
		{
			key: 'featureFlags',
			label: 'Feature flags',
			value: org.featureFlags,
			editable: true,
			type: 'select',
			multiple: true,
			options: Object.values(FeatureFlags).map((value) => ({
				label: formatHumanReadable(value),
				value,
			})),
		},
		{
			key: 'activationLink',
			label: 'Activation link',
			value: activationLink == null ? '' : (
				<Typography.Text copyable={{ text: activationLink }}>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={activationLink}
					>
						Link url
					</a>
				</Typography.Text>
			),
		},
		{
			label: 'Company name',
			key: 'companyName',
			value: org.companyName,
			type: 'text',
			editable: true,
		},
		{
			label: 'Invoice Template',
			key: 'invoiceTemplate',
			value: org.invoiceTemplate,
			type: 'select',
			options: Object.values(InvoiceTemplates).map((t) => ({
				label: t,
				value: t,
			})),
			editable: true,
		},
		{
			label: 'Base Currency',
			key: 'baseCurrency',
			value: org.baseCurrency,
			type: 'select',
			options: Object.values(Currencies).map((t) => ({
				label: t,
				value: t,
			})),
			inputProps: {
				showSearch: true,
			},
			editable: true,
		},
		{
			label: 'Bunker Accounting Method',
			key: 'bunkerAccountingMethod',
			value: org.bunkerAccountingMethod,
			type: 'select',
			editable: true,
			options: [
				{
					label: 'FIFO',
					value: 'FIFO',
				},
				{
					label: 'LIFO',
					value: 'LIFO',
				},
			],
		},
		{
			label: 'Address',
			key: 'address',
			value: org.address,
			type: 'text',
			editable: true,
		},
		{
			label: 'City',
			key: 'city',
			value: org.city,
			type: 'text',
			editable: true,
		},
		{
			label: 'Country',
			key: 'country',
			value: org.country,
			type: 'text',
			editable: true,
		},
		{
			label: 'Use vessel company name on invoices',
			key: 'useVesselCompanyOnInvoice',
			value: org.useVesselCompanyOnInvoice?.toString(),
			type: 'select',
			options: [
				{ label: 'Yes', value: 'true' },
				{ label: 'No', value: 'false' },
			],
			editable: true,
		},
		{
			label: '[The Ship] Client ID',
			key: 'theShipClientId',
			value: org.theShipClientId,
			type: 'text',
			editable: true,
		},
		{
			label: '[The Ship] Client Secret',
			key: 'theShipClientSecret',
			value: org.theShipClientSecret,
			type: 'text',
			editable: true,
		},
		{
			label: '[The Ship] Company ID',
			key: 'theShipCompanyId',
			value: org.theShipCompanyId,
			type: 'text',
			editable: true,
		},
		{
			label: 'Accounting System Provider',
			key: 'accountingSystemProvider',
			value: org.accountingSystemProvider,
			type: 'select',
			options: [
				{ label: 'None', value: null },
				{ label: 'Codat', value: AccountingSystemProviders.CODAT },
				{ label: 'Navision', value: AccountingSystemProviders.NAVISION },
			],
			editable: true,
		},
		{
			label: 'Accounting System Strategy',
			key: 'accountingSystemStrategy',
			value: org.accountingSystemStrategy,
			type: 'select',
			options: [
				{ label: 'Global', value: AccountingSystemStrategies.GLOBAL },
				{ label: 'Voyage-based', value: AccountingSystemStrategies.VOYAGE },
				{ label: 'Vessel-based', value: AccountingSystemStrategies.VESSEL },
			],
			editable: true,
		},
		{
			label: 'Codat Company Id',
			key: 'codatCompanyId',
			value: org.codatCompanyId,
			type: 'text',
			editable: true,
		},
		{
			key: 'createdAt',
			label: 'Creation date',
			value: formatDate(org.createdAt, DATE_AND_TIME),
		},
		{
			key: 'updatedAt',
			label: 'Last updated',
			value: formatDate(org.updatedAt, DATE_AND_TIME),
		},
	];
};

export default getItems;
