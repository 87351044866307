import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getAccrualItems } from '@client/lib/api';
import { Links } from '@client/utils/links';
import Button from '@client/components/Button';
import styles from '@client/screens/financials/ArApScreen/AccountingItemsScreen.module.css';
import AccrualsTable from './AccrualsTable';

const AccrualsScreen = () => {
	const [items, refresh, _error, loading] = useFetchedState(getAccrualItems);

	return (
		<SimpleScreen
			title="Accruals Summary"
			rootPageTitle="Accruals"
			headerActions={[(
				<Link to={Links.Accounting.PeriodClose.get()}>
					<Button type="primary">
						Create a Closing Period
					</Button>
				</Link>
			)]}
		>
			<Card
				slim
			>
				<Tabs
					defaultActiveKey="open"
					type="card"
					items={[
						{
							key: 'open',
							label: 'Open',
							className: styles.tabs,
							children: (
								<AccrualsTable
									accrualItems={(items ?? []).filter((i) => !i.periodClosed)}
									refresh={refresh}
									loading={loading}
									columnsToHide={['shouldProrate']}
								/>
							),
						},
						{
							key: 'closed',
							label: 'Closed',
							className: styles.tabs,
							children: (
								<AccrualsTable
									accrualItems={(items ?? []).filter((i) => i.periodClosed)}
									refresh={refresh}
									loading={loading}
									columnsToHide={['shouldProrate']}
								/>
							),
						},
					]}
				/>
			</Card>
		</SimpleScreen>
	);
};

export default AccrualsScreen;
