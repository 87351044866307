// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V05_uhd_bn8lta7yz7eE {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tflex-direction: column;\n\theight: 100%;\n\tmargin-bottom: 10px;\n}\n\n.uIm4yJDYuj5NPRvvHRlD {\n\twidth: 100%;\n\twhite-space: nowrap;\n}\n\n.hDL0fZuHpSxnJeHSVWMy {\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 100%;\n\twhite-space: nowrap;\n\tline-height: 25px;\n}\n\n.hDL0fZuHpSxnJeHSVWMy > p,\n.w2j0QjAuBtb_nMUwHmlF > p {\n\theight: 100%;\n\tmargin: 0;\n\tpadding-left: 0.5em;\n\ttext-align: left;\n}\n\n.GtU_DVZOaj4ioTBVrvKi {\n\tfont-style: italic;\n\tdisplay: block;\n\twhite-space: nowrap;\n\tfont-size: 85%;\n\tline-height: 120%;\n\tposition: absolute;\n\ttop: 38px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/performance/PerformanceDetailsScreen/helpers/getPerformanceStatusColumns.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,2BAA2B;CAC3B,sBAAsB;CACtB,YAAY;CACZ,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,WAAW;CACX,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;;CAEC,YAAY;CACZ,SAAS;CACT,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;CAClB,cAAc;CACd,mBAAmB;CACnB,cAAc;CACd,iBAAiB;CACjB,kBAAkB;CAClB,SAAS;AACV","sourcesContent":[".outerCell {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tflex-direction: column;\n\theight: 100%;\n\tmargin-bottom: 10px;\n}\n\n.dateCell {\n\twidth: 100%;\n\twhite-space: nowrap;\n}\n\n.tableCell {\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 100%;\n\twhite-space: nowrap;\n\tline-height: 25px;\n}\n\n.tableCell > p,\n.tableCellRow > p {\n\theight: 100%;\n\tmargin: 0;\n\tpadding-left: 0.5em;\n\ttext-align: left;\n}\n\n.differenceText {\n\tfont-style: italic;\n\tdisplay: block;\n\twhite-space: nowrap;\n\tfont-size: 85%;\n\tline-height: 120%;\n\tposition: absolute;\n\ttop: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerCell": "V05_uhd_bn8lta7yz7eE",
	"dateCell": "uIm4yJDYuj5NPRvvHRlD",
	"tableCell": "hDL0fZuHpSxnJeHSVWMy",
	"tableCellRow": "w2j0QjAuBtb_nMUwHmlF",
	"differenceText": "GtU_DVZOaj4ioTBVrvKi"
};
export default ___CSS_LOADER_EXPORT___;
