// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JwhrLW8lZHN35a2Qo5Mf {\n\tbackground-color: #52c41a;\n\tborder-color: #52c41a;\n\tcolor: white;\n\tfont-weight: bold;\n}\n\n.WUgPqhC3QFmSdZW6NBGu {\n\tmargin-right: 7.5px;\n\tpadding-top: 3px;\n}\n\n.JwhrLW8lZHN35a2Qo5Mf:hover,\n.JwhrLW8lZHN35a2Qo5Mf:focus {\n\tbackground-color: #73d13d;\n\tborder-color: #73d13d;\n\tcolor: white;\n}\n\n.MGfihluzh_ISxbdiQktA {\n\tfont-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/HeaderAction.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,qBAAqB;CACrB,YAAY;CACZ,iBAAiB;AAClB;;AAEA;CACC,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;;CAEC,yBAAyB;CACzB,qBAAqB;CACrB,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".approveButton {\n\tbackground-color: #52c41a;\n\tborder-color: #52c41a;\n\tcolor: white;\n\tfont-weight: bold;\n}\n\n.icon {\n\tmargin-right: 7.5px;\n\tpadding-top: 3px;\n}\n\n.approveButton:hover,\n.approveButton:focus {\n\tbackground-color: #73d13d;\n\tborder-color: #73d13d;\n\tcolor: white;\n}\n\n.goToEstimate {\n\tfont-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"approveButton": "JwhrLW8lZHN35a2Qo5Mf",
	"icon": "WUgPqhC3QFmSdZW6NBGu",
	"goToEstimate": "MGfihluzh_ISxbdiQktA"
};
export default ___CSS_LOADER_EXPORT___;
