import React from 'react';
import {
	Modal,
	Spin,
} from 'antd';
import {
	DownloadOutlined,
	LinkOutlined,
} from '@ant-design/icons';
import { DATE_AND_TIME } from '@shared/utils/constants';
import type { AttachmentProps } from '@api/models/attachment';
import { formatDate } from '@client/utils/formatDate';
import Button from '@client/components/Button';
import { getAttachmentUrl } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import getLocalFileUrl from '@client/utils/getLocalFileUrl';
import FileViewer from './FileViewer';
import styles from './styles/AttachmentViewer.module.css';

export type AttachmentViewerProps = {
	attachment?: AttachmentProps | null;
	modal?: boolean;
	onCloseModal?: () => void;
}

const AttachmentViewer: React.FC<AttachmentViewerProps> = ({
	attachment,
	modal = false,
	onCloseModal,
}) => {
	const [url, _refresh, _error, loading] = useFetchedState(
		async () => {
			if (attachment) {
				const attachmentUrl = await getAttachmentUrl(attachment.id);

				if (attachment.type === 'link') {
					return attachmentUrl;
				}

				// We have to transform it to a local url for the 'download' property to work correctly
				return getLocalFileUrl(attachmentUrl);
			}

			return null;
		},
		[attachment],
		{ autoRefresh: false },
	);

	const {
		type,
		name,
	} = (attachment || {});

	let content = null;

	if (loading || !attachment) {
		content = (
			<div className={styles.loadingWrapper}>
				<Spin />
			</div>
		);
	} else if (url != null && type === 'link') {
		content = (
			<>
				<a href={url} target="_blank" rel="noopener noreferrer">
					<Button>
						<LinkOutlined />
						{` View ${name}`}
					</Button>
				</a>
			</>
		);
	} else if (url != null) {
		content = (
			<>
				<div className={styles.downloadWrapper}>
					<a
						href={url}
						download={name}
					>
						<Button>
							<DownloadOutlined />
							{` Download ${name}`}
						</Button>
					</a>
					{attachment.createdAt && (
						`Created at: ${formatDate(attachment.createdAt, DATE_AND_TIME)}`
					)}
				</div>
				<FileViewer
					url={url}
					type={type}
				/>
			</>
		);
	}

	if (!modal) {
		return content;
	}

	return (
		<Modal
			open={attachment != null}
			footer={null}
			centered
			title="View Attachment"
			onCancel={onCloseModal}
			className={styles.modal}
			width={720}
		>
			{content}
		</Modal>
	);
};

export default AttachmentViewer;
