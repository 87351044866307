import React, {
	useState,
	useEffect,
	useCallback,
	useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import createAuth0Client, {
	Auth0Client,
	CacheLocation,
	User,
} from '@auth0/auth0-spa-js';
import { Crisp } from 'crisp-sdk-web';
import {
	DateFormats,
	GlobalLaytimeCalculationMethod,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import { BASE_URL } from '@client/utils/constants';
import {
	getUserData,
	getWelcome,
	setJWT,
} from './api';

const url = window.location.origin + BASE_URL;
const returnUrl = `${window.location.pathname}${window.location.search}`.replace(BASE_URL!, '');

const initOptions = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN!,
	client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
	audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
	redirect_uri: url,
	cacheLocation: 'localstorage' as CacheLocation,
};

export type AuthValues = {
	isAuthenticated: boolean;
	user: User | undefined;
	loading: boolean;
	hasAccess: boolean;
	accessError: Error | null;
	isAdmin: boolean;
	isManagement: boolean;
	userInfo: UserData | DefaultUserData;
	refreshAuthInfo: () => void;
	getIdTokenClaims: () => void;
	loginWithRedirect: () => void;
	getTokenSilently: () => void;
	getTokenWithPopup: () => void;
	logout: () => void;
}

export type DefaultUserData = {
	isManagement: false;
	baseCurrency: 'USD';
	activeDepartment: string;
	featureFlags: string[];
	filterVessels: boolean;
	relevantVessels: Array<{ id: number; name: string }>;
	finishedSetup: false;
	defaultDashboard: string | null;
	selectedDashboard: string;
	name: string;
	organizationName: string;
	dateFormat: Values<typeof DateFormats>;
	globalLaytimeCalculationMethod: GlobalLaytimeCalculationMethod;
}

type DefaultContextValues = {
	isAuthenticated: false;
	loading: true;
	loginWithRedirect: () => null;
	refreshAuthInfo: () => null;
	hasAccess: false;
	accessError: null;
	userInfo: DefaultUserData;
}

const defaultUserInfoValues = {
	isManagement: false,
	baseCurrency: 'USD',
	activeDepartment: 'chartering',
	featureFlags: [],
	relevantVessels: [],
	filterVessels: false,
	finishedSetup: false,
	defaultDashboard: null,
	selectedDashboard: '',
	name: '',
	organizationName: '',
	dateFormat: DateFormats.YMD,
	globalLaytimeCalculationMethod: GlobalLaytimeCalculationMethod.TIMETOCOUNT,
} as DefaultUserData;

const AuthContext = React.createContext<AuthValues | DefaultContextValues>({
	isAuthenticated: false,
	loading: true,
	hasAccess: false,
	accessError: null,
	userInfo: defaultUserInfoValues,
	loginWithRedirect: () => null,
	refreshAuthInfo: () => null,
});

export const useAuth = () => useContext(AuthContext);

export const Auth0Provider = ({ children }: {children: React.ReactElement}) => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [hasAccess, setHasAccess] = useState(false);
	const [accessError, setAccessError] = useState<Error | null>(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const [user, setUser] = useState<User>();
	const [userInfo, setUserInfo] = useState<
		UserData |
		DefaultUserData
	>(defaultUserInfoValues);
	const [auth0Client, setAuth0] = useState<Auth0Client>();
	const [loading, setLoading] = useState(true);

	const history = useHistory();

	const refreshAuthInfo = useCallback(async () => {
		try {
			// If this is successful, we have access
			const result = await getWelcome() as { isAdmin: boolean; msg: string } | undefined;
			const info = await getUserData();

			setUserInfo(info);
			setIsAdmin(result?.isAdmin ?? false);

			setHasAccess(true);
		} catch (e) {
			setAccessError(e as Error);
			setHasAccess(false);
		}
	}, []);

	useEffect(() => {
		const info = userInfo as UserData;

		if (info == null || Crisp == null) {
			return;
		}

		try {
			if (info.email != null) {
				Crisp.user.setEmail(info.email);
			}

			if (info.name != null) {
				Crisp.user.setNickname(info.name);
			}

			Crisp.session.setData({
				organization: info.organizationName ?? 'Unknown organization',
				organzationId: info.organizationId ?? 'Unknown organization',
				department: info.activeDepartment ?? 'Unknown department',
				baseCurrency: info.baseCurrency ?? 'Unknown',
				dateFormat: info.dateFormat ?? 'Unknown',
				filterVessel: info.filterVessels ? 'Yes' : 'No',
				isManagement: info.isManagement ? 'Yes' : 'No',
			});
		} catch (e) {
			console.error('Could not initialize Crisp chat');
		}
	}, [userInfo]);

	useEffect(() => {
		localStorage.setItem('preferredDateFormat', userInfo.dateFormat);
	}, [userInfo.dateFormat]);

	useEffect(() => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client(initOptions);
			setAuth0(auth0FromHook);

			if (
				window.location.search.includes('code=') &&
				window.location.search.includes('state=')
			) {
				const { appState } = await auth0FromHook.handleRedirectCallback();

				history.push(appState.returnTo || '');
			}

			const newIsAuthenticated = await auth0FromHook.isAuthenticated();
			setIsAuthenticated(newIsAuthenticated);

			if (newIsAuthenticated) {
				const newUser = await auth0FromHook.getUser();
				setUser(newUser);

				const token = await auth0FromHook.getTokenSilently();

				setJWT(token);

				await refreshAuthInfo();
			}

			setLoading(false);
		};

		initAuth0();
	}, [refreshAuthInfo, history]);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				loading,
				hasAccess,
				accessError,
				isAdmin,
				isManagement: userInfo.isManagement,
				userInfo,
				refreshAuthInfo,
				getIdTokenClaims: (...p) => auth0Client?.getIdTokenClaims(...p),
				loginWithRedirect: () => auth0Client?.loginWithRedirect({
					appState: { returnTo: returnUrl },
				}),
				getTokenSilently: (...p) => auth0Client?.getTokenSilently(...p),
				getTokenWithPopup: (...p) => auth0Client?.getTokenWithPopup(...p),
				logout: () => auth0Client?.logout({ returnTo: url }),
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
