import {
	useCallback,
	useState,
} from 'react';

// If the items shouldn't be directly compared, you may define a custom key extractor
const useArrayState = <T>(
	initialState: T[] = [],
	getKey = (i: T) => i,
): [
	T[],
	(item: T) => void,
	(item: T) => void,
	(items: T[]) => void,
] => {
	const [state, setState] = useState(initialState);

	const addItem = useCallback((item: T) => (
		setState((oldItems) => [...oldItems, item])
	), []);

	const removeItem = useCallback((item: T) => (
		setState(((oldItems) => oldItems.filter((i) => getKey(i) !== getKey(item))))
	// eslint-disable-next-line react-hooks/exhaustive-deps
	), []);

	return [state, addItem, removeItem, setState];
};

export default useArrayState;
