import cloneDeep from 'lodash.clonedeep';
import { Moment } from 'moment';
import { toMoment } from '@shared/utils/date';

type Options = {
	clone?: boolean;
	newestFirst?: boolean;
};

export const sortByDates = <
	Entry extends Record<DateKey, Date | Moment | string | null | number> & {},
	DateKey extends keyof Entry,
>(
	entries: Entry[],
	dateKey: DateKey,
	options?: Options,
): Entry[] => {
	const newEntries = options?.clone ? cloneDeep(entries) : entries;

	return newEntries.sort((a, b) => {
		if (a?.[dateKey] == null || b[dateKey] == null) {
			return 1;
		}

		const [before, after] = options?.newestFirst ? [1, -1] : [-1, 1];

		const firstDate = a[dateKey] as Moment | Date;
		const secondDate = b[dateKey] as Moment | Date;

		return toMoment(firstDate).toISOString() < toMoment(secondDate).toISOString() ? before : after;
	});
};
