import { SizeType } from 'antd/es/config-provider/SizeContext';
import { TablePaginationConfig } from 'antd/es/table';
import { HireInvoiceItemStates } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

const getEditableTableProps = <
	Row extends { state: Values<typeof HireInvoiceItemStates> },
>() => ({
	// @ts-ignore
	pagination: false as TablePaginationConfig,
	size: 'small' as SizeType,
	enableDelete: (item: Row) => item.state === HireInvoiceItemStates.PENDING,
	enableEdit: (item: Row) => item.state === HireInvoiceItemStates.PENDING,
	iconButtons: true,
	actionsTitle: '',
});

export default getEditableTableProps;
