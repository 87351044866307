// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".D78Ct8sgE61CKWIaN9Mr {\n\ttext-align: center;\n\tmax-height: 100%;\n}\n\n.CfVrKLV5qTa5NeiRXGGe {\n\tmax-width: 100%;\n\tmax-height: 100%;\n}\n\n.NhlXHN9krw12YVDlUdnd {\n\twhite-space: pre-wrap;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/FileViewer.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":[".imageWrapper {\n\ttext-align: center;\n\tmax-height: 100%;\n}\n\n.image {\n\tmax-width: 100%;\n\tmax-height: 100%;\n}\n\n.textContent {\n\twhite-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWrapper": "D78Ct8sgE61CKWIaN9Mr",
	"image": "CfVrKLV5qTa5NeiRXGGe",
	"textContent": "NhlXHN9krw12YVDlUdnd"
};
export default ___CSS_LOADER_EXPORT___;
