import React, { useMemo } from 'react';
import { Skeleton } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { AccountTypes } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { toMoment } from '@shared/utils/date';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getBrokers } from '@client/lib/api';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import AddButton from '@client/components/AddButton';
import { Links } from '@client/utils/links';
import Button from '@client/components/Button';
import { OnUpdateCargo } from '@client/screens/estimates/details/context/hooks/useCargoHandlers';
import styles from './BrokerCommissionDetails.module.css';

export type BrokerCommission = {
	id?: number | undefined;
	brokerId: number;
	commission: number | null;
	paidBy: Values<typeof AccountTypes>;
}

type Props = {
	handleChange: OnUpdateCargo;
	cargoDetails: GetCargoDetailsResponse | undefined;
	refreshCargoDetails: () => void;
}

const BrokerCommissionDetails = ({
	cargoDetails,
	handleChange,
}: Props) => {
	const [brokers] = useFetchedState(getBrokers);

	const handleBrokerUpdate = (incomingBrokers: Array<Partial<BrokerCommission>>) => {
		if (cargoDetails?.id == null) {
			return;
		}

		handleChange(cargoDetails.id, { brokers: incomingBrokers });
	};

	const brokerData = useMemo(() => {
		return (cargoDetails?.brokers ?? []).map((b) => ({ ...b, key: b.id }));
	}, [cargoDetails?.brokers]);

	const canAddMoreBrokers = useMemo(() => {
		if (brokerData == null || brokers == null) {
			return true;
		}

		return brokerData.length < brokers?.length;
	}, [brokerData, brokers]);

	const handleAddBroker = async () => {
		if (brokers == null || brokers.length === 0) {
			return;
		}

		const newBroker = { brokerId: brokers[0].id, commission: 0, paidBy: AccountTypes.OWNER };

		// Check and add broker that's not already added
		if (brokerData != null) {
			const unaddedBrokers = brokers
				.filter((broker) => brokerData
					.find((b) => b.brokerId === broker.id) == null);

			if (unaddedBrokers != null && unaddedBrokers.length > 0) {
				newBroker.brokerId = unaddedBrokers[0].id;
			}
		}

		handleBrokerUpdate([
			...(brokerData != null ? brokerData : []),
			newBroker,
		]);
	};

	const handleRemoveBroker = async (entry: BrokerCommission) => {
		if (brokerData == null) {
			return;
		}

		const newData = [...brokerData].filter((b) => b.brokerId !== entry.brokerId);
		handleBrokerUpdate(newData);
	};

	const brokerOptions = useMemo(() => {
		if (brokers == null) {
			return [];
		}

		return brokers.map((b) => {
			const isAdded = brokerData.find((br) => br.brokerId === b.id) != null;

			return ({
				label: b.name,
				value: b.id,
				disabled: isAdded,
			});
		});
	}, [brokerData, brokers]);

	if (cargoDetails == null) {
		return (<Skeleton />);
	}

	const sortedBrokerData = brokerData.sort((a, b) => {
		if (a.brokerInCargo == null || b.brokerInCargo == null) {
			return 0;
		}

		const aDate = toMoment(a.brokerInCargo.createdAt);
		const bDate = toMoment(b.brokerInCargo.createdAt);

		return aDate.isBefore(bDate) ? -1 : 1;
	});

	return (
		<div className={styles.container}>
			<div>
				<EditableCellTableRedux<Partial<BrokerCommission>>
					columns={[
						{
							title: (
								<div>
									Broker
								</div>
							),
							dataIndex: 'brokerId',
							editable: true,
							type: 'select',
							inputProps: {
								options: brokerOptions,
							},
						},
						{
							title: (
								<div className={styles.commissionCol}>
									Commission
								</div>
							),
							dataIndex: 'commission',
							editable: true,
							width: 85,
							type: 'number',
							inputProps: {
								addonAfter: '%',
							},
						},
						{
							title: (
								<div className={styles.paidByCol}>
									Paid By
								</div>
							),
							dataIndex: 'paidBy',
							editable: true,
							type: 'select',
							width: 120,
							inputProps: {
								options: [
									{ label: 'Owner', value: AccountTypes.OWNER },
									{ label: 'Charterer', value: AccountTypes.CHARTERER },
								],
							},
						},
						{
							title: (
								<div className={styles.addCol}>
									<AddButton
										onClick={handleAddBroker}
										disabled={
											(brokers == null || brokers.length === 0) ||
												!canAddMoreBrokers
										}
										disabledTooltip={(
											<>
												No brokers registered, or all existing brokers have been added.
												<a href={`${Links.Brokers.path}`}> Create one?</a>
											</>
										)}
									/>
								</div>
							),
							// @ts-ignore
							dataIndex: 'addEntry',
							render: (record: BrokerCommission) => (
								<Button
									onClick={async () => await handleRemoveBroker(record)}
									type="text"
									confirmTitle="Are you sure you want to delete this row?"
									danger
									icon={(<DeleteOutlined />)}
									className={styles.addCol}
								/>
							),
							className: styles.addCol,
							width: 50,
						},
					]}
					dataSource={sortedBrokerData}
					size="small"
					pagination={false}
					onChange={handleBrokerUpdate}
					emptyText="No brokers"
				/>
			</div>
		</div>
	);
};

export default BrokerCommissionDetails;
