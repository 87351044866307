import React from 'react';
import { Moment } from 'moment';
import {
	TimeFormat,
	toMoment,
} from '@shared/utils/date';
import {
	capitalize,
	formatHumanReadable,
} from '@shared/utils/string';
import { formatDate } from '@client/utils/formatDate';
import styles from './styles/date.module.css';

type SortDirection = 'asc' | 'desc'

// Get an array of all the different filter options
export const getFilterOptions = <
	Data extends {[s: string]: string | number | null} | string | number | null
>(
	data: Array<Data>,
	key: string | null,
	sort: SortDirection,
	customCompare?: (a: any, b: any) => number,
) => {
	const values = data
		.map((row) => ((key) ? row?.[key] : row as string))
		.filter((v): v is string => v != null && v !== '');

	const unique = [...new Set(values)];

	if (sort === 'asc') {
		unique.sort(customCompare);
	} else if (sort === 'desc') {
		unique.sort(customCompare).reverse();
	}

	return unique.map((option) => ({
		text: capitalize(option),
		value: option,
	}));
};

export const renderUtcDate = (
	date: Moment,
	...props: any[]
) => (date == null ? null : (
	<div className={styles.utcDateWrapper}>
		<div>{formatDate(date, ...props)}</div>
		<div className={styles.utcTag}>UTC</div>
	</div>
));

export const filterEquals = (key: string) => (value: any, record: object) => record[key] === value || record[key] === '';

// rawValues is a boolean indicating whether the data array contains
// objects where we want the value of key or just an array of values
export const getFilterProps = <
	Data extends {[s: string]: string | number | null} | string | number | null,
>(
	data: Array<Data>,
	type: 'select' | 'dateRange',
	key: string,
	sort: SortDirection = 'asc',
	rawValues = false,
	customCompare?: (a: Data, b: Data) => number,
) => {
	switch (type) {
		case 'select':
			return {
				filters: getFilterOptions(
					data,
					rawValues ? null : key,
					sort,
					customCompare,
				),
				onFilter: filterEquals(key),
				key,
			};

		default:
			return {};
	}
};

export const renderDate = (
	format?: string,
	selectedTimeFormat?: TimeFormat,
) => (text: string) => text && formatDate(
	toMoment(text),
	format,
	true,
	selectedTimeFormat,
);

export const renderHumanReadable = () => (text: string) => formatHumanReadable(text);

export const invoiceIdentifierCompare = (a: string, b: string) => {
	const voyageNameA = a.split('-').slice(0, -1).join('-');
	const hireInvoiceNumA = Number(a.split('-').slice(-1)[0]);
	const voyageNameB = b.split('-').slice(0, -1).join('-');
	const hireInvoiceNumB = Number(b.split('-').slice(-1)[0]);

	if (voyageNameA !== voyageNameB) {
		return voyageNameA.localeCompare(voyageNameB);
	}

	return hireInvoiceNumA - hireInvoiceNumB;
};
