type Thresholds = { min: number; max: number };

const checkThresholds = ({
	value,
	thresholds,
}: {
	value: number;
	thresholds: Thresholds;
}): boolean => value < thresholds.min || value > thresholds.max;

export default checkThresholds;
