import {
	toMoment,
	formatDate,
} from '@shared/utils/date';
import {
	HIIFieldTypes,
	DATE_AND_TIME,
	PnlGroups,
	MillisecondsPer,
	InvoiceTemplates,
	HireUnit,
} from '@shared/utils/constants';
import { round } from '../utils/math';
import { formatCurrency } from '../utils/currency';
import TemplateItem from '../TemplateItem';
import getFirstNotNull from '../utils/getFirstNotNull';
import HireInvoiceItem from './HireInvoiceItem';

class HIIOffHirePeriod extends HireInvoiceItem {
	static get itemType() {
		return 'offHirePeriod';
	}

	constructor(id, isOriginal, params = {}) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.voyageOffHirePeriodId = params.voyageOffHirePeriodId;
		this.hireDaysDecimals = params.hireDaysDecimals;
		this.hirePerDay = params.hirePerDay;
		this.startTime = toMoment(params.startTime);
		this.endTime = toMoment(params.endTime);
		this.invoiceNote = params.invoiceNote;
		this.note = params.note;
		this.type = params.type;
		this.percentageForOwnersAccount = params.percentageForOwnersAccount;

		this._showTotal = true;
		this.itemTypeLabel = 'Off-hire';
		this.addManually = true;
		this.templateSection = TemplateItem.Sections.OFF_HIRE;
		this.pnlGroup = PnlGroups.OFFHIRE;

		this._fields = {
			hirePerDay: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Hire per day',
			},
			startTime: {
				type: HIIFieldTypes.DATE,
				label: 'From',
			},
			endTime: {
				type: HIIFieldTypes.DATE,
				label: 'To',
			},
			percentageForOwnersAccount: {
				type: HIIFieldTypes.PERCENTAGE,
				label: 'Proportion for Owner',
			},
		};
	}

	_calculateTotal(_invoiceItems) {
		const days = this.getDays();
		const reversedHire = round(this.hirePerDay * days);

		return -1 * reversedHire * (this.percentageForOwnersAccount / 100);
	}

	getDays() {
		return round(
			toMoment(this.endTime).diff(this.startTime) / MillisecondsPer.DAY, this.hireDaysDecimals ?? 6,
		);
	}

	getDuration() {
		return `
			${formatDate(this.startTime, 'MMM Do YYYY HH:mm')} - 
			${formatDate(this.endTime, 'MMM Do YYYY HH:mm')}
		`;
	}

	getDescription() {
		return `${this.getDays()} days`;
	}

	static async _getClassParams(model, _parentModel) {
		const voyageOffHirePeriod = await model.getVoyageOffHirePeriod();
		const offHirePeriod = await voyageOffHirePeriod.getOffHirePeriod();

		return {
			voyageOffHirePeriodId: voyageOffHirePeriod.id,
			hirePerDay: getFirstNotNull(model.hirePerDayOverride, voyageOffHirePeriod.hirePerDay),
			startTime: getFirstNotNull(
				model.startTimeOverride,
				offHirePeriod.startTime,
			),
			endTime: getFirstNotNull(
				model.endTimeOverride,
				offHirePeriod.endTime,
			),
			invoiceNote: voyageOffHirePeriod.invoiceNote,
			hireDaysDecimals: model.hireDaysDecimals,
			note: voyageOffHirePeriod.note,
			type: offHirePeriod.type,
			percentageForOwnersAccount: getFirstNotNull(
				model.percentageForOwnersAccountOverride,
				voyageOffHirePeriod.percentageForOwnersAccount,
			),
			fixtureCurrency: model.fixtureCurrency,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.voyageOffHirePeriodId = this.voyageOffHirePeriodId;
		model.hireDaysDecimals = this.hireDaysDecimals;
		model.invoiceNote = this.invoiceNote;
		model.note = this.note;
		model.type = this.type;

		await model.save();

		if (this.isOriginal) {
			const voyageOffHirePeriod = await model.getVoyageOffHirePeriod();
			const offHirePeriod = await voyageOffHirePeriod.getOffHirePeriod();
			const allVoyageHireInvoicePeriods = await offHirePeriod.getVoyageOffHirePeriods();

			voyageOffHirePeriod.hirePerDay = this.hirePerDay;

			// if the offhire is only associated to 1 contract = update the off hire globally
			if (allVoyageHireInvoicePeriods.length === 1) {
				offHirePeriod.startTime = this.startTime;
				offHirePeriod.endTime = this.endTime;
			}

			// if the offhire is associated to more than 1, we will only update it locally
			model.startTimeOverride = this.startTime;
			model.endTimeOverride = this.endTime;
			voyageOffHirePeriod.percentageForOwnersAccount = this.percentageForOwnersAccount;

			await voyageOffHirePeriod.save();
			await offHirePeriod.save();
			await model.save();
		} else {
			model.hirePerDayOverride = this.hirePerDay;
			model.startTimeOverride = this.startTime;
			model.endTimeOverride = this.endTime;
			model.percentageForOwnersAccountOverride = this.percentageForOwnersAccount;

			await model.save();
		}
	}

	_getTemplateItemParams(invoiceItems) {
		return {
			[InvoiceTemplates.CLASSIC]: {
				hideTotal: true,
				columns: [
					[
						['Off-hire period'],
						(this.invoiceNote == null || this.invoiceNote === '' ? [] : [{
							content: `Note: ${this.invoiceNote}`,
							italic: true,
						}]),
						(this.percentageForOwnersAccount === 100 ? [] : [{
							content: `Proportion for Owner's Account: ${this.percentageForOwnersAccount}%`,
							small: true,
							italic: true,
						}]),
					],
				],
				children: [
					{
						section: 'Hire',
						columns: [
							[
								['From:', formatDate(this.startTime, DATE_AND_TIME) || ''],
								['To:', formatDate(this.endTime, DATE_AND_TIME) || ''],
							],
							`${this.getDays()} days`,
							`at ${formatCurrency(this.hirePerDay, this.fixtureCurrency)}`,
						],
						total: this.getTotal(invoiceItems, false),
					},
				],
			},
			[InvoiceTemplates.NEO]: {
				hideTotal: false,
				columns: [
					`Off-hire ${this.getDuration()}`,
					`${this.getDays()}`,
					'days',
					// eslint-disable-next-line max-len
					`${formatCurrency(this.hirePerDay, this.fixtureCurrency, { hideSymbol: true })} ${this.hireUnit === HireUnit.MONTHS ? 'pcm' : ''}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIOffHirePeriod);

export default HIIOffHirePeriod;
