import React from 'react';
import styles from './styles/CVEFormulaLabel.module.css';

const CVEFormulaAnnual = () => (
	<>
		<span className={styles.division}>
			<span>
				<span className={styles.highlighted}>expense</span>
				<span className={styles.times} />
				12
			</span>
			<span>365</span>
		</span>
		<span className={styles.times} />
		<span className={styles.highlighted}>hire days</span>
	</>
);

export default CVEFormulaAnnual;
