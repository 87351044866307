// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UdUiTokZG1DN2aycj1sU {\n\tmargin-bottom: 12px;\n}\n\n.UdUiTokZG1DN2aycj1sU .QPJwIJ1ypFKf1H5_uSNo {\n\tmin-width: 300px;\n\tmin-height: 150px;\n}\n\n.UdUiTokZG1DN2aycj1sU .LLarMqoZ50PBb3L1psz8 {\n\theight: 70px;\n\toverflow: hidden;\n}\n\n.W1J48QCFIYvN_fRaWguy {\n\tcolor: red;\n}\n\n.hU4_PeFWSjYgeoU5LmRQ {\n\tcolor: rgb(255, 123, 0);\n}\n\n.Jc2KA379guARJ2w96yvB table {\n\ttable-layout: auto !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/admin/OrganizationDetailsScreen/components/styles/InviteUsersDrawer.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,gBAAgB;AACjB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,6BAA6B;AAC9B","sourcesContent":[".inputWrapper {\n\tmargin-bottom: 12px;\n}\n\n.inputWrapper .input {\n\tmin-width: 300px;\n\tmin-height: 150px;\n}\n\n.inputWrapper .placeholder {\n\theight: 70px;\n\toverflow: hidden;\n}\n\n.error {\n\tcolor: red;\n}\n\n.warning {\n\tcolor: rgb(255, 123, 0);\n}\n\n.table table {\n\ttable-layout: auto !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": "UdUiTokZG1DN2aycj1sU",
	"input": "QPJwIJ1ypFKf1H5_uSNo",
	"placeholder": "LLarMqoZ50PBb3L1psz8",
	"error": "W1J48QCFIYvN_fRaWguy",
	"warning": "hU4_PeFWSjYgeoU5LmRQ",
	"table": "Jc2KA379guARJ2w96yvB"
};
export default ___CSS_LOADER_EXPORT___;
