// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e12TgTXapp6lF7UcBvKj {\n\tmargin-left: 5px;\n}\n\n.e12TgTXapp6lF7UcBvKj > span {\n\tjustify-content: flex-end;\n\tdisplay: flex;\n}\n\n.meBtstRX8qkZcE8RPxra {\n\tmargin-bottom: 16px;\n}\n\n.cfZUyeRD46ekFBEGnVEQ {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n}\n\n.rgAV0n7o0xKiK69WKY88 {\n\tdisplay: flex;\n\tflex-direction: row;\n\tmargin-left: 15px;\n\talign-items: center;\n}\n\n.rgAV0n7o0xKiK69WKY88 > p {\n\tcolor: darkgray;\n\tfont-size: 13px;\n\tfont-weight: lighter;\n\tfont-style: italic;\n\tmargin: 0 0 0 5px;\n}\n\n.YrG2XPJKrXs1jKHdeJos {\n\tmargin-left: 5px;\n}\n\n.apZh2o9wfW4VjYTuL6K3 {\n\tdisplay: flex;\n\tflex-direction: row;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VesselDetailsScreen/components/styles/BankAccountTabs.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,yBAAyB;CACzB,aAAa;AACd;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,aAAa;CACb,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,eAAe;CACf,oBAAoB;CACpB,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB","sourcesContent":[".tabDeleteButton {\n\tmargin-left: 5px;\n}\n\n.tabDeleteButton > span {\n\tjustify-content: flex-end;\n\tdisplay: flex;\n}\n\n.bankAccount {\n\tmargin-bottom: 16px;\n}\n\n.emptyContainer {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n}\n\n.badgeContainer {\n\tdisplay: flex;\n\tflex-direction: row;\n\tmargin-left: 15px;\n\talign-items: center;\n}\n\n.badgeContainer > p {\n\tcolor: darkgray;\n\tfont-size: 13px;\n\tfont-weight: lighter;\n\tfont-style: italic;\n\tmargin: 0 0 0 5px;\n}\n\n.bankAccountBadge {\n\tmargin-left: 5px;\n}\n\n.titleContainer {\n\tdisplay: flex;\n\tflex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabDeleteButton": "e12TgTXapp6lF7UcBvKj",
	"bankAccount": "meBtstRX8qkZcE8RPxra",
	"emptyContainer": "cfZUyeRD46ekFBEGnVEQ",
	"badgeContainer": "rgAV0n7o0xKiK69WKY88",
	"bankAccountBadge": "YrG2XPJKrXs1jKHdeJos",
	"titleContainer": "apZh2o9wfW4VjYTuL6K3"
};
export default ___CSS_LOADER_EXPORT___;
