import { groupByProperty } from '@shared/utils/array';
import { Values } from '@shared/utils/objectEnums';
import BankAccount from '@api/models/bank-account';
import Fixture from '@api/models/fixture';
import FixtureCounterparty from '@api/models/fixture-counterparty';
import TcFixture from '@api/models/tc-fixture';
import SpotFixture from '@api/models/spot-fixture';
import Vessel from '@api/models/vessel';
import Cargo from '@api/models/cargo';
import type { VoyageBunkerEntry } from '@api/utils/sequelize/getVoyageBunkers';
import ExpenseSubjectToHireDays from '@api/models/expense-subject-to-hire-days';
import FixtureExpense from '@api/models/fixture-expense';
import {
	FixtureTypeLabels,
	DATE_AND_TIME,
	VoyageBunkerTypes,
	FixtureTypes,
	AccountTypes,
	DATE,
} from './constants';
import { formatCurrency } from './currency';
import { formatDate } from './date';
import formatTcAndBbFixtureDuration from './formatTcAndBbFixtureDuration';
import { capitalize } from './string';
import { formatPercentage } from './formatPercentage';

export const FixtureRecapCategories = {
	CP_TERMS: 'CP Terms',
	VESSEL_DETAILS: 'Vessel Details',
} as const;

export type RecapItemResult = (
	| [string, string | number | null]
	| Readonly<[string, string | number | null]>
);

export type RecapItem<ChildFixture extends (TcFixture | SpotFixture)> = {
	key: string;
	category: Values<typeof FixtureRecapCategories>;
	label: string;
	description: string;
	numberOfColumns: 1 | 2;
	getInfo: (params: {
		counterparty: FixtureCounterparty;
		fixture: Fixture;
		childFixture: ChildFixture;
		bankAccount: BankAccount;
		brokers: Array<{
			id: number;
			name: string;
			commission: number;
			paidBy: Values<typeof AccountTypes>;
		}>;
		vessel: Vessel;
		cargos: Cargo[];
		bunkers: VoyageBunkerEntry[];
		expensesSubjectToHireDays: ExpenseSubjectToHireDays[];
		ownersExpenses: FixtureExpense[];
		charterersExpenses: FixtureExpense[];
	}) => (
		| Array<RecapItemResult>
		| Readonly<Array<RecapItemResult>>
	);
};

const getFixtureSharedRecapItems = <T extends TcFixture | SpotFixture>(): RecapItem<T>[] => [
	{
		key: 'keyTerms',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Key Terms',
		description: 'Summary of the contract',
		numberOfColumns: 1,
		getInfo: ({ counterparty, fixture, brokers }) => [
			['Estimate Type', FixtureTypeLabels[fixture.type]],
			['Identifier', fixture.identifier],
			[fixture.type === FixtureTypes.TC_IN ? 'Owner' : 'Charterer', counterparty.name],
			[
				brokers.length === 1 ? 'Broker' : 'Brokers',
				brokers.length === 0 ?
					'None' :
					brokers.map((b) => b.name).join(', '),
			],
		] as const,
	},
	{
		key: 'cp',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'CP',
		description: 'Key Charter Party terms',
		numberOfColumns: 1,
		getInfo: ({ fixture }) => [
			['CP Date', formatDate(fixture.cpDate)],
			['CP Form', fixture.cpForm],
		] as const,
	},
	{
		key: 'laycan',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Laycan',
		description: 'Commencement of laytime & cancelling',
		numberOfColumns: 1,
		getInfo: ({ childFixture }) => [
			['Laycan From', formatDate(childFixture.laycanFrom, DATE_AND_TIME, true)],
			['Laycan To', formatDate(childFixture.laycanTo, DATE_AND_TIME, true)],
		] as const,
	},
	{
		key: 'commissions',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Commissions',
		description: 'Address and brokerage commissions',
		numberOfColumns: 1,
		getInfo: ({ fixture, brokers }) => [
			['Address Commission', formatPercentage(fixture.addressCommission ?? 0)],
			...brokers.reduce<Array<[string, string | null]>>((arr, b) => [
				...arr,
				[`${brokers.length === 1 ? 'Broker' : b.name} Commission`, formatPercentage(b.commission)],
				[`${brokers.length === 1 ? 'Broker' : b.name} Paid By`, capitalize(b.paidBy)],
			], []),
		] as const,
	},
	{
		key: 'notes',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Notes',
		description: 'General contract notes',
		numberOfColumns: 1,
		getInfo: ({ fixture }) => [
			['Estimate Notes', fixture.note],
		] as const,
	},
	{
		key: 'vesselBankInfo',
		category: FixtureRecapCategories.VESSEL_DETAILS,
		label: 'Bank Info',
		description: 'Account number, currency and other banking details',
		numberOfColumns: 1,
		getInfo: ({ bankAccount }) => [
			['Bank Name', bankAccount.bankName],
			['Account No.', bankAccount.accountNo],
			['SWIFT', bankAccount.swift],
			['IBAN', bankAccount.iban],
			['Currency', bankAccount.currency],
		] as const,
	},
	{
		key: 'vesselMeasurements',
		category: FixtureRecapCategories.VESSEL_DETAILS,
		label: 'Measurements',
		description: 'Tonnage, draft and other measurements',
		numberOfColumns: 2,
		getInfo: ({ vessel }) => [
			['Breadth', vessel.breadth],
			['Summer DWT', vessel.summerDWT],
			['Laden Draft', vessel.ladenDraft],
			['Lightship', vessel.lightship],
			['TPC', vessel.TPC],
			['Gross Tonnage', vessel.grossTonnage],
			['Net Tonnage', vessel.netTonnage],
			['Suez GRT', vessel.suezGRT],
			['Suez NRT', vessel.suezNRT],
			['Panama NRT', vessel.panamaNRT],
			['Grain Capacity', vessel.grainCapacity],
			['Bale Capacity', vessel.baleCapacity],
		] as const,
	},
	{
		key: 'vesselComms',
		category: FixtureRecapCategories.VESSEL_DETAILS,
		label: 'Communications',
		description: 'Call sign and Inmarsat info',
		numberOfColumns: 1,
		getInfo: ({ vessel }) => [
			['Call Sign', vessel.commsCallSign],
			['Inmarsat Telephone', vessel.commsInmarsatNumber],
			['Inmarsat Fax', vessel.commsInmarsatFax],
			['Inmarsat Email', vessel.commsInmarsatEmail],
		] as const,
	},
];

export const FixtureTcRecapItems: RecapItem<TcFixture>[] = [
	...getFixtureSharedRecapItems<TcFixture>(),
	{
		key: 'ports',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Ports',
		description: 'Delivery & redelivery locations',
		numberOfColumns: 1,
		getInfo: ({ childFixture }) => [
			['Delivery Port(s) / Range(s)', childFixture.deliveryPortsOrRangesLabel],
			['Redelivery Port(s) / Range(s)', childFixture.redeliveryPortsOrRangesLabel],
		] as const,
	},
	{
		key: 'duration',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Duration',
		description: 'Duration of the charter',
		numberOfColumns: 1,
		getInfo: ({ childFixture }) => [
			['Base Period', formatTcAndBbFixtureDuration(childFixture)],
			...(childFixture.minimumDurationExact ? [
				['Exact min. date', formatDate(childFixture.minimumDurationExact, DATE)],
			] as const : []),
			...(childFixture.maximumDurationExact ? [
				['Exact max. date', formatDate(childFixture.maximumDurationExact, DATE)],
			] as const : []),
			...(childFixture.durationDescription ? [
				['Description', childFixture.durationDescription],
			] as const : []),
		] as const,
	},
	{
		key: 'hire',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Hire',
		description: 'Hire rate, type and ballast bonus',
		numberOfColumns: 1,
		getInfo: ({ childFixture, bankAccount }) => [
			...(childFixture.hireRate != null && bankAccount.currency != null ? [
				['Hire Amount', formatCurrency(childFixture.hireRate, bankAccount.currency)],
			] as const : []),
			...(childFixture.hireDescription != null && childFixture.hireDescription !== '' ? [
				['Hire Description', childFixture.hireDescription],
			] as const : []),
			['Days per Hire Period', childFixture.invoicePerCalenderMonth ? 'PCM' : childFixture.daysPerHirePeriod],
			...(childFixture.grossBallastBonus != null && bankAccount.currency != null ? [
				['Gross Ballast Bonus', formatCurrency(childFixture.grossBallastBonus, bankAccount.currency)],
			] as const : []),
		],
	},
	{
		key: 'bunkers',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Bunkers',
		description: 'Bunkers on delivery and redelivery',
		numberOfColumns: 1,
		getInfo: ({ bunkers, bankAccount }) => {
			const grouped = groupByProperty(bunkers, 'type');
			const getBunkerOutput = (
				type: Values<typeof VoyageBunkerTypes>,
				label: string,
			): Array<Readonly<[string, string]>> => {
				bunkers = grouped[type];

				if (bunkers == null || bunkers.length === 0) {
					return [];
				}

				return bunkers
					.sort((a, b) => a.fuelGrade.localeCompare(b.fuelGrade))
					.map((b) => [
						`${b.fuelGrade} ${label}`,
						`${b.quantity} MT at ${formatCurrency(b.pricePerTon, bankAccount.currency)} / MT`,
					] as const);
			};

			// We mention the bunker types manually so we can control the order
			return [
				...getBunkerOutput(VoyageBunkerTypes.DELIVERY, 'on delivery'),
				...getBunkerOutput(VoyageBunkerTypes.REDELIVERY, 'on redelivery'),
				...getBunkerOutput(VoyageBunkerTypes.CHARTERERS_ACCOUNT, 'for owner\'s account'),
				...getBunkerOutput(VoyageBunkerTypes.OWNERS_ACCOUNT, 'for charterer\'s account'),
			] as const;
		},
	},
	{
		key: 'notices',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Notices',
		description: 'Notices of delivery and redelivery',
		numberOfColumns: 1,
		getInfo: ({ childFixture }) => [
			['Delivery Notices (approx.)', childFixture.deliveryNoticesApproximate!.join(', ')],
			['Delivery Notices (definite)', childFixture.deliveryNoticesDefinite!.join(', ')],
			['Redelivery Notices (approx.)', childFixture.redeliveryNoticesApproximate!.join(', ')],
			['Redelivery Notices (definite)', childFixture.redeliveryNoticesDefinite!.join(', ')],
		] as const,
	},
	{
		key: 'expenses',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Expenses Subject to Hire Days',
		description: 'C/V/E and other contracted expenses',
		numberOfColumns: 1,
		getInfo: ({ childFixture, expensesSubjectToHireDays, bankAccount }) => [
			['Expense Formula', capitalize(childFixture.calculationMethodForExpensesSubjectToHireDays)],
			...(expensesSubjectToHireDays.map((e) => [
				e.name,
				formatCurrency(e.amount, bankAccount.currency),
			] as const)),
		],
	},
	{
		key: 'charterersExpenses',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Charterer\'s Expenses',
		description: 'Expenses to be paid by charterer',
		numberOfColumns: 1,
		getInfo: ({ charterersExpenses, bankAccount }) => [
			...(charterersExpenses.map((e) => [
				e.name,
				formatCurrency(e.amount, bankAccount.currency),
			] as const)),
		],
	},
	{
		key: 'ownersExpenses',
		category: FixtureRecapCategories.CP_TERMS,
		label: 'Owner\'s Expenses',
		description: 'Expenses to be paid by the owner',
		numberOfColumns: 1,
		getInfo: ({ ownersExpenses, bankAccount }) => [
			...(ownersExpenses.map((e) => [
				e.name,
				formatCurrency(e.amount, bankAccount.currency),
			] as const)),
		],
	},
];
