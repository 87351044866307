import { Values } from '@shared/utils/objectEnums';
import { Currencies } from './constants';
import isDev from './isDev';

// forceDecimals = true means decimals will always be displayed
// forceDecimals = false means they will only be displayed if not .00
export const formatCurrency = (
	amount: number | string,
	currency: Values<typeof Currencies>,
	options: { forceDecimals?: boolean; maximumFractionDigits?: number; hideSymbol?: boolean } = {},
) => {
	const {
		forceDecimals = true,
		maximumFractionDigits = 2,
	} = options;

	const dollarFormatter = new Intl.NumberFormat('en-US', {
		style: options.hideSymbol ? 'decimal' : 'currency',
		currency: currency || Currencies.USD,
		minimumFractionDigits: forceDecimals ? 2 : 0,
		maximumFractionDigits,
	});

	// Only crash on dev
	if (isDev() && currency == null) {
		throw new Error(`Currency is required. Received ${currency}`);
	}

	if (isDev() && (amount == null || Number.isNaN(amount) || amount === '')) {
		const numberStr = typeof amount === 'string' ? `"${amount}"` : amount;

		throw new Error(`Amount to format (${numberStr}) was not a valid number.`);
	}

	let amountToFormat = Number(amount);

	if (Object.is(amountToFormat, -0)) {
		amountToFormat = 0;
	}

	const result = dollarFormatter.format(amountToFormat);

	// If not forcing decimals and amount is an integer
	// remove the decimals
	// @ts-ignore
	if (!forceDecimals && Number.parseInt(amountToFormat, 10) === Number.parseFloat(amountToFormat)) {
		return result.replace(/\.[0-9][0-9]/, '');
	}

	return result;
};

// Turns a dollar string (e.g. "$4,200.50") into a number (e.g. 4200.5)
export const parseDollar = (s: string) => Number(s.replace(/[^0-9.-]+/g, ''));
