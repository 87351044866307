import React from 'react';

type EmptyTextProps = {
	text?: string;
}

const EmptyText : React.FC<EmptyTextProps> = ({ text }) => (
	<em>{text || 'Empty'}</em>
);

export default EmptyText;
