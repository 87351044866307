import React from 'react';
import {
	FixtureCounterpartyTypes,
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { getTcExtraColumns } from '@client/screens/fixtures/tc/getTcExtraColumns';
import FixtureListScreen from '@client/screens/fixtures/shared/FixtureListScreen';

const FixtureTcInListScreen = () => {
	const counterpartyType = FixtureCounterpartyTypes.CHARTEREROWNER;

	return (
		<FixtureListScreen
			fixtureType={FixtureTypes.TC_IN}
			vesselOwnershipType={VesselOwnershipTypes.TC_IN}
			getExtraColumns={getTcExtraColumns}
			counterpartyType={counterpartyType}
			allowCreateNewVessel
			allowCreateNewOwner
		/>
	);
};

export default FixtureTcInListScreen;
