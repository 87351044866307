import { useGlobalState } from '@client/lib/globalState';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getVideoGuides } from '@client/lib/api';
import { RootPages } from '@client/utils/links';

export const useVideoGuides = () => {
	const [pageTitle] = useGlobalState('PAGE_TITLE');
	const [videos] = useFetchedState(
		() => getVideoGuides(pageTitle as typeof RootPages[number] ?? 'Contracts'), [pageTitle],
	);

	return videos;
};
