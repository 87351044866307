// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KrzrRoJQFxYeGkkQKX_p {\n\tposition: relative;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/DraggableList/DraggableList.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB","sourcesContent":[".itemsContainer {\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsContainer": "KrzrRoJQFxYeGkkQKX_p"
};
export default ___CSS_LOADER_EXPORT___;
