import { notification } from 'antd';

const showSuccessNotification = (
	title: string,
	description?: string | React.ReactElement,
	duration: number = 2,
) => notification.success({
	message: title,
	description,
	duration,
});

export default showSuccessNotification;
