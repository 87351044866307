import { Moment } from 'moment';
import { sortByDates } from '@shared/utils/sortByDates';

type RateSchedule = {
	id: number;
	from: Moment;
	to: Moment;
	rate: number;
};

const getRateForDate = (date: Moment, rateSchedule: RateSchedule[]): RateSchedule | null => {
	if (rateSchedule == null || rateSchedule.length === 0 || date == null) {
		return null;
	}

	// Sort rate schedules by from date
	const sortedSchedules = sortByDates(rateSchedule, 'from');

	// Find the first rate schedule that encompasses the given date
	const matchingSchedule = sortedSchedules.find((schedule) => (
		date.isSameOrAfter(schedule.from) && date.isSameOrBefore(schedule.to)
	));

	if (matchingSchedule) {
		return matchingSchedule;
	}

	// If the date is outside all schedules, use the latest rate or 0
	const lastSchedule = sortedSchedules[sortedSchedules.length - 1];

	return date.isAfter(lastSchedule.to) ? lastSchedule : null;
};

export default getRateForDate;
