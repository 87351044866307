const dynamicDelay = async (callback: Function, minimumDelayMs: number) => {
	const startTimestamp = Date.now();

	let result;
	let error;

	try {
		result = await callback();
	} catch (e) {
		error = e as Error;
	}

	const endTimestamp = Date.now();

	const timeSpent = endTimestamp - startTimestamp;

	if (timeSpent < minimumDelayMs) {
		await new Promise((resolve) => setTimeout(resolve, minimumDelayMs - timeSpent));
	}

	if (error) {
		throw error;
	}

	return result;
};

export default dynamicDelay;
