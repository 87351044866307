import React from 'react';
import { Typography } from 'antd';
import {
	HomeOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Links } from '@client/utils/links';
import Button from '@client/components/Button';
import APIError from '@client/utils/APIError';
import styles from './styles/ErrorPage.module.css';

const ErrorPage = ({ error }) => {
	const errorMessage = error instanceof APIError ? error.rawMessage : error.message;

	let content;

	if (error.statusCode === 404) {
		content = (
			<div className={styles.centeredContent}>
				<Typography.Title>
					{errorMessage || 'Something went wrong'}
				</Typography.Title>
				<Typography.Title
					level={4}
					type="secondary"
					className={styles.textBox}
				>
					We could not find the requested page
				</Typography.Title>
				<Link to={Links.Home.get()}>
					<Button
						icon={(<HomeOutlined />)}
						type="primary"
					>
						Home
					</Button>
				</Link>
			</div>
		);
	} else if (error.message.includes('Network Error')) {
		content = (
			<div className={styles.centeredContent}>
				<Typography.Title>
					No connection
				</Typography.Title>
				<Typography.Title
					level={4}
					type="secondary"
					className={styles.textBox}
				>
					<p>Could not connect to the ClearVoyage server.</p>
				</Typography.Title>
				<Button
					icon={(<SyncOutlined />)}
					type="primary"
					onClick={() => window.location.reload()}
				>
					Refresh
				</Button>
			</div>
		);
	} else {
		content = (
			<div className={styles.centeredContent}>
				<Typography.Title>
					Something went wrong
				</Typography.Title>
				<Typography.Title
					level={4}
					type="secondary"
					className={styles.textBox}
				>
					<p>The requested page could not be loaded.</p>
				</Typography.Title>
				<Link to={Links.Home.get()}>
					<Button
						icon={(<HomeOutlined />)}
						type="primary"
					>
						Home
					</Button>
				</Link>
			</div>
		);
	}

	return (
		<div className={classNames(styles.centeredContainer, styles.centeredContent)}>
			{content}
		</div>
	);
};

export default ErrorPage;
