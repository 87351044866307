// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B3g4aDKbwnuYzeBWkQEA {\r\n\tpadding-left: 0;\r\n}\r\n\r\n.ro5ue9hWYUWWl8MFyXw9 .ant-table-pagination-right {\r\n\tpadding-right: 20px;\r\n}\r\n\r\n.Ab7gd4rCkLQX4uc1_22s {\r\n\tpadding: 0 !important;\r\n}\r\n\r\n.bXcM7qPgtj9nZ7P68_OR {\r\n\tpadding: 8px 16px;\r\n}\r\n\r\n.nsabqJkDRs1PBVKAmG7p {\r\n\tbackground-color: white;\r\n\tbox-shadow: inset 0 0 3px #0004;\r\n}\r\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/PaymentsTable.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,uBAAuB;CACvB,+BAA+B;AAChC","sourcesContent":[".deleteButton {\r\n\tpadding-left: 0;\r\n}\r\n\r\n.paginationPadding :global(.ant-table-pagination-right) {\r\n\tpadding-right: 20px;\r\n}\r\n\r\n.editButton {\r\n\tpadding: 0 !important;\r\n}\r\n\r\n.expandedRow {\r\n\tpadding: 8px 16px;\r\n}\r\n\r\n.partsWrapper {\r\n\tbackground-color: white;\r\n\tbox-shadow: inset 0 0 3px #0004;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteButton": "B3g4aDKbwnuYzeBWkQEA",
	"paginationPadding": "ro5ue9hWYUWWl8MFyXw9",
	"editButton": "Ab7gd4rCkLQX4uc1_22s",
	"expandedRow": "bXcM7qPgtj9nZ7P68_OR",
	"partsWrapper": "nsabqJkDRs1PBVKAmG7p"
};
export default ___CSS_LOADER_EXPORT___;
