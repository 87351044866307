// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yD8LMw8lVZqE693OsGYQ {\n\twidth: fit-content;\n}\n\n.LbsvULpPgKzlV2AV96ig {\n\tmargin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/management/users/UserManagement.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".tableContainer {\n\twidth: fit-content;\n}\n\n.alert {\n\tmargin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "yD8LMw8lVZqE693OsGYQ",
	"alert": "LbsvULpPgKzlV2AV96ig"
};
export default ___CSS_LOADER_EXPORT___;
