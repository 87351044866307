import React from 'react';
import styles from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/ItineraryTab.module.css';

export const completedColor = 'rgb(76,148,255)';
export const inProgressColor = 'rgb(169,204,253)';

type Props = {
	connected: boolean;
	progress: number;
}

const TimelinePart = (props: Props) => {
	const { connected = false, progress } = props;
	const yVal = `${progress}%`;
	const height = `${100 - progress}%`;

	const marker = (
		<polygon
			points={`2,${progress - 10} 14,${progress + 19} 26,${progress - 10}`}
			style={{ fill: completedColor }}
		/>
	);

	return (
		<div
			className={styles.svgContainer}
		>
			<svg width="100%" height="100%">
				<rect
					x={10}
					y={0}
					width={8}
					height="100%"
					style={{
						fill: completedColor,
					}}
				/>
				<rect
					x={10}
					y={yVal}
					width={8}
					height={height}
					style={{
						fill: inProgressColor,
					}}
				/>
				{(progress > 0 && progress < 100) && marker}
				{connected && (
					<rect
						x={10}
						y={60}
						width="100%"
						height="5%"
						style={{
							fill: progress >= 50 ? completedColor : inProgressColor,
						}}
					/>
				)}
			</svg>
		</div>
	);
};

export default TimelinePart;
