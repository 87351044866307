import { FixtureStates } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

export const colors = [
	'magenta',
	'red',
	'volcano',
	'orange',
	'gold',
	'lime',
	'green',
	'cyan',
	'blue',
	'geekblue',
	'purple',
];

export const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

export const getStringModuloColor = (str: string) => {
	const value = str.split('').reduce((acc, _char, i) => acc + str.charCodeAt(i), 0);
	const colorIndex = value % colors.length;

	return colors[colorIndex];
};

export const getFixtureStateColor = (fixtureState: Values<typeof FixtureStates>) => {
	switch (fixtureState) {
		case FixtureStates.PENDING:
			return 'magenta';
		case FixtureStates.FIXED:
			return 'cyan';
		case FixtureStates.COMMENCED:
			return 'geekblue';
		case FixtureStates.COMPLETED:
			return 'black';

		default:
			return 'black';
	}
};
