import React, { useState } from 'react';
import { Card } from 'antd';
import { useHistory } from 'react-router';
import { FixtureTypes } from '@shared/utils/constants';
import type { GetEstimatesResponse } from '@api/features/estimates/getEstimates';
import type { EstimateResultProps } from '@api/models/estimate-result';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getEstimates } from '@client/lib/api';
import LoadingIndicator from '@client/components/LoadingIndicator';
import MatrixTable from '@client/components/Table/MatrixTable';
import { Links } from '@client/utils/links';
import VesselDescription from '@client/components/VesselDescription';
import styles from './CompareEstimatesScreen.module.css';

type ReturnedEstimate = GetEstimatesResponse[number];

type FlatEstimate =
	ReturnedEstimate &
	EstimateResultProps

const CompareEstimatesScreen = () => {
	const url = new URL(window.location.href);
	const { searchParams } = url;
	const ids = searchParams.get('ids')?.split(',').map((id) => Number(id));

	const history = useHistory();
	const [selectedIds, setSelectedIds] = useState(ids ?? []);

	const [estimates, _refresh, _error, loading] = useFetchedState(getEstimates);

	if (loading || estimates == null) {
		return (<LoadingIndicator />);
	}

	const removeHeader = (header: {id: number}) => {
		const newSelectedIds = selectedIds.filter((id) => id !== header.id);
		setSelectedIds(newSelectedIds);

		const newUrl = Links.Fixtures[FixtureTypes.SPOT].CompareEstimates.get(newSelectedIds);
		history.push(newUrl);
	};

	const onAddNewHeader = (id: number) => {
		const newSelectedIds = [...selectedIds, id];
		setSelectedIds(newSelectedIds);

		const newUrl = Links.Fixtures[FixtureTypes.SPOT].CompareEstimates.get(newSelectedIds);
		history.push(newUrl);
	};

	const remainingEstimates = estimates.filter((e) => !selectedIds?.some((id) => id === e.id));

	const flattenEstimate = (e: ReturnedEstimate): FlatEstimate => {
		return {
			...e,
			...e.result,
		};
	};

	const filteredEstimates: FlatEstimate[] = estimates
		.filter((e) => selectedIds?.some((id) => id === e.id))
		.map(flattenEstimate);

	return (
		<SimpleScreen
			canGoBack
			goBackToUrl={Links.Fixtures[FixtureTypes.SPOT].List.get()}
			title="Compare Estimates"
			rootPageTitle="Estimate Comparison"
			breadcrumbs={[['VC Out Estimates', Links.Fixtures[FixtureTypes.SPOT].List.get()]]}
		>
			<Card>
				<div className={styles.container}>
					<MatrixTable
						onAddNewHeader={onAddNewHeader}
						onRemoveHeader={removeHeader}
						remainingEntries={remainingEstimates}
						data={filteredEstimates}
						categories={[
							{ title: 'General', break: true },
							{ title: 'Vessel',
								key: 'vesselName',
								render: (_, est) => (
									<VesselDescription
										name={est.vesselName ?? '—'}
										flag={est.vesselFlag}
									/>
								) },
							{ title: 'Duration', key: 'estimatedDuration', type: 'number', inputProps: { addOnAfter: 'Days' } },
							{ title: 'Results', break: true },
							{ title: 'Voyage Profit', key: 'voyageProfit', type: 'currency' },
							{ title: 'TCE', key: 'tce', type: 'currency' },
							{ title: 'CAPEX', key: 'capex', type: 'currency' },
							{ title: 'OPEX', key: 'opex', type: 'currency' },
							{ title: 'Tc In Costs', key: 'tcInExpense', type: 'currency' },
							{ title: 'Net Result', key: 'netProfit', type: 'currency' },
							{ title: 'Net Result /day', key: 'netProfitPerDay', type: 'currency' },
							{ title: 'Revenue', break: true },
							{ title: 'Freight', key: 'freight', type: 'currency' },
							{ title: 'Commissions', key: 'commissions', type: 'currency' },
							{ title: 'Dem/Des', key: 'demurrageAndDespatch', type: 'currency' },
							{ title: 'Net Revenue', key: 'netRevenue', type: 'currency' },
							{ title: 'Expenses', break: true },
							{ title: 'Port Costs', key: 'portCharge', type: 'currency' },
							{ title: 'Bunker expenses', key: 'bunkerExpense', type: 'currency' },
							{ title: 'Total Voyage Expenses', key: 'totalVoyageExpenses', type: 'currency' },
						]}
					/>
				</div>
			</Card>
		</SimpleScreen>
	);
};

export default CompareEstimatesScreen;
