import React from 'react';
import {
	Row,
	Col,
	Grid,
} from 'antd';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import HireInvoicesTable from './InvoiceTables/HireInvoicesTable';
import PaymentsTable from './PaymentsTable';

const InvoicesTab = ({
	voyageDetails,
	fixtureDetails,
	refreshDetails,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshDetails: () => void;
}) => {
	const screens = Grid.useBreakpoint();

	return (
		<Row gutter={[16, 16]}>
			{screens.xxl ? (
				<>
					<Col span={8}>
						<PaymentsTable
							voyageId={voyageDetails.id}
							voyageDetails={voyageDetails}
							payments={voyageDetails.payments}
							refreshDetails={refreshDetails}
						/>
					</Col>
					<Col span={16}>
						<HireInvoicesTable
							voyageDetails={voyageDetails}
							fixtureDetails={fixtureDetails}
							refreshDetails={refreshDetails}
							loading={false}
						/>
					</Col>
				</>
			) : (
				<>
					<Col span={24}>
						<HireInvoicesTable
							voyageDetails={voyageDetails}
							fixtureDetails={fixtureDetails}
							refreshDetails={refreshDetails}
							loading={false}
						/>
					</Col>
					<Col span={12}>
						<PaymentsTable
							voyageId={voyageDetails.id}
							voyageDetails={voyageDetails}
							payments={voyageDetails.payments}
							refreshDetails={refreshDetails}
						/>
					</Col>
				</>
			)}

		</Row>
	);
};

export default InvoicesTab;
