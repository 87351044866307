import React from 'react';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import CountryFlag from '@client/components/CountryFlag';
import { Links } from '@client/utils/links';
import styles from './styles/VesselDescription.module.css';

const VesselDescription = ({
	name,
	flag,
	vesselId,
}: {
	name?: string;
	flag?: string | null;
	vesselId?: number | null;
}) => {
	if (flag == null) {
		if (vesselId != null) {
			return (
				<Link to={Links.Vessel.get(vesselId)}>
					<span className={styles.name}>
						{name}
					</span>
				</Link>
			);
		}

		return (<>{name ?? null}</>);
	}

	return (
		<Space
			align="baseline"
			className={styles.spacer}
		>
			<CountryFlag countryCode={flag} />
			{vesselId != null ? (
				<Link to={Links.Vessel.get(vesselId)}>
					<span className={styles.name}>
						{name}
					</span>
				</Link>
			) : (
				<span className={styles.name}>
					{name}
				</span>
			)}
		</Space>
	);
};

export default VesselDescription;
