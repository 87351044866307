// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pP4enZsaFjL67Olrx12Z .ant-input {\n\twidth: 80px !important;\n\tmargin: 0;\n}\n\n.pP4enZsaFjL67Olrx12Z.ant-input-affix-wrapper {\n\tpadding: 0 !important;\n\tpadding-left: 10px !important;\n\theight: 35px;\n}\n\n.axepfbipLQf9jUXCCFe6.ant-select {\n\theight: 35px;\n}\n\n.Fj7FPFppJ2M25P7ZJlLQ {\n\tfont-size: 12px;\n\tcolor: gray;\n}\n\n.iCDhhHZP4VxeCDywmRZe.ant-btn {\n\theight: 33px;\n\tborder-radius: 0 5px 5px 0 !important;\n\tborder-width: 0;\n\tborder-left-color: black;\n\tborder-left-width: 1 !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/CurrencySelect/CurrencySelect.module.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,SAAS;AACV;;AAEA;CACC,qBAAqB;CACrB,6BAA6B;CAC7B,YAAY;AACb;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,eAAe;CACf,WAAW;AACZ;;AAEA;CACC,YAAY;CACZ,qCAAqC;CACrC,eAAe;CACf,wBAAwB;CACxB,+BAA+B;AAChC","sourcesContent":[".input :global(.ant-input) {\n\twidth: 80px !important;\n\tmargin: 0;\n}\n\n.input:global(.ant-input-affix-wrapper) {\n\tpadding: 0 !important;\n\tpadding-left: 10px !important;\n\theight: 35px;\n}\n\n.select:global(.ant-select) {\n\theight: 35px;\n}\n\n.label {\n\tfont-size: 12px;\n\tcolor: gray;\n}\n\n.refreshButton:global(.ant-btn) {\n\theight: 33px;\n\tborder-radius: 0 5px 5px 0 !important;\n\tborder-width: 0;\n\tborder-left-color: black;\n\tborder-left-width: 1 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "pP4enZsaFjL67Olrx12Z",
	"select": "axepfbipLQf9jUXCCFe6",
	"label": "Fj7FPFppJ2M25P7ZJlLQ",
	"refreshButton": "iCDhhHZP4VxeCDywmRZe"
};
export default ___CSS_LOADER_EXPORT___;
