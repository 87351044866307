import {
	FixtureCounterpartyTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

export const fixtureCounterpartyTypeToName = (
	type: Values<typeof FixtureCounterpartyTypes>,
) => {
	switch (type) {
		case FixtureCounterpartyTypes.BROKER:
			return 'Broker';
		case FixtureCounterpartyTypes.SUPPLIER:
			return 'Supplier';
		case FixtureCounterpartyTypes.CHARTEREROWNER:
			return 'Charterer / Owner';
		default:
			throw new Error(`Invalid estimate counterparty type "${type}".`);
	}
};

export const fixtureTypeToName = (
	type: Values<typeof FixtureTypes>,
) => {
	switch (type) {
		case FixtureTypes.BB_OUT:
			return 'BB Out';
		case FixtureTypes.TC_IN:
			return 'TC In';
		case FixtureTypes.TC_OUT:
			return 'TC Out';
		case FixtureTypes.SPOT:
			return 'VC Out';
		default:
			throw new Error(`Invalid estimate type "${type}".`);
	}
};

export const fixtureTypeToNameLower = (
	type: Values<typeof FixtureTypes>,
) => {
	switch (type) {
		case FixtureTypes.BB_OUT:
			return 'BB out';
		case FixtureTypes.TC_IN:
			return 'TC in';
		case FixtureTypes.TC_OUT:
			return 'TC out';
		case FixtureTypes.SPOT:
			return 'VC out';
		default:
			throw new Error(`Invalid estimate type "${type}".`);
	}
};
