// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ORNpxf_QExXS89nmXC22 .ant-tabs-nav-wrap {\n\tpadding: 0 24px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/financials/ArApScreen/AccountingItemsScreen.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB","sourcesContent":[".tabs :global(.ant-tabs-nav-wrap) {\n\tpadding: 0 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "ORNpxf_QExXS89nmXC22"
};
export default ___CSS_LOADER_EXPORT___;
