import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { toMoment } from '@shared/utils/date';
import type { TransformedHireInvoices } from '@api/utils/getTransformedHireInvoices';
import { Links } from '@client/utils/links';
import StateTag from '@client/components/StateTag';
import { EditableDetailsItem } from '@client/components/EditableField';
import styles from '@client/components/ExpenseDetailsCard/private/styles/SelectedExpenseItems.module.css';

const getSelectedInvoiceItem = (
	selectedInvoice: TransformedHireInvoices[number],
) => {
	if (selectedInvoice == null) {
		return [];
	}

	const vessel = selectedInvoice.Voyage.Vessel;
	const { charterer } = selectedInvoice;

	return [
		{
			label: 'Status',
			key: 'state',
			value: (
				<StateTag state={selectedInvoice.state} />
			),
		},
		{
			label: 'Title',
			key: 'title',
			value: selectedInvoice.invoiceTitle,
		},
		{
			label: 'Invoice ID',
			key: 'customInvoiceId',
			value: selectedInvoice.invoiceNumber,
		},
		{
			key: 'amount',
			label: 'Amount',
			value: {
				value: selectedInvoice.amount,
				currency: selectedInvoice.currency,
			},
			type: 'multiCurrency',
			inputProps: {
				baseCurrency: selectedInvoice.currency,
			},
		},
		{
			label: 'Description',
			key: 'itemDescription',
			value: selectedInvoice.note,
		},
		{
			label: 'Contract',
			key: 'contract',
			value: (
				<Space direction="vertical" size={1}>
					<b>
						<Link to={Links.Voyage.get(selectedInvoice.voyageId)}>
							{selectedInvoice.voyageIdentifier}
						</Link>
					</b>
					{charterer != null && (
						<span className={styles.contractSubSection}>
							Charterer:
							{' '}
							{charterer}
						</span>
					)}
					<span className={styles.contractSubSection}>
						Vessel:
						{' '}
						<Link to={Links.Vessel.get(vessel.id)}>
							{vessel.name}
						</Link>
					</span>
				</Space>
			),
		},
		{
			label: 'Charterer',
			key: 'charterer',
			value: selectedInvoice.charterer,
		},
		{
			type: 'date',
			key: 'invoiceDate',
			label: 'Invoice Date',
			value: selectedInvoice.invoiceDate ? toMoment(selectedInvoice.invoiceDate) : '',
		},
		{
			label: 'Created By',
			value: selectedInvoice.createdByUserName,
			key: 'createdBy',
		},
		{
			type: 'textarea',
			key: 'note',
			label: 'Note',
			value: selectedInvoice?.note ?? '',
		},
	] as EditableDetailsItem<Partial<typeof selectedInvoice>>[];
};

export default getSelectedInvoiceItem;
