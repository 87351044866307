import { HireInvoiceItemStates } from './constants';
import { Values } from './objectEnums';

const filterHireInvoiceItemsByState = <T extends { state: Values<typeof HireInvoiceItemStates> }>(
	items: T[],
	allowedState: Values<typeof HireInvoiceItemStates>,
): T[] => (
	items.filter((i) => i.state === allowedState)
);

export default filterHireInvoiceItemsByState;
