import React from 'react';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Card,
	Col,
	Row,
} from 'antd';
import { Link } from 'react-router-dom';
import { VesselOwnershipTypeLabels } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import VesselDescription from '@client/components/VesselDescription';
import ReportsLayer from '@client/components/Map/layers/ReportsLayer';
import Button from '@client/components/Button';
import Details from '@client/components/Details';
import VesselsLayer from '@client/components/Map/layers/VesselsLayer';
import Map from '@client/components/Map/Map';
import VesselTimeline from '@client/components/VesselTimeline';
import styles from '@client/screens/fleet/VesselDetailsScreen/VesselDetailsScreen.module.css';
import { Links } from '@client/utils/links';

export const VesselSummaryTab = ({
	vessel,
	position,
	openReportDrawer,
}) => {
	/* const { userInfo } = useAuth();
	const hasActiveVoyage = vessel.activeVoyage != null;
 */
	/* const activeVoyageCurrency = hasActiveVoyage ?
		vessel.activeVoyage.activeVoyageCurrency :
		userInfo.baseCurrency;
 */
	const getVesselSummaryItems = (v) => [
		{
			key: 'name',
			label: 'Vessel name',
			value: (
				<VesselDescription
					name={v.name}
					flag={v.flag}
				/>
			),
		},
		{
			key: 'codename',
			label: 'Codename',
			value: v.codename,
		},
		{
			key: 'imo',
			label: 'IMO',
			value: v.imo,
		},
		{
			key: 'ownership',
			label: 'Ownership',
			value: VesselOwnershipTypeLabels[v.ownershipType],
		},
		{
			key: 'nextDryDocking',
			label: 'Next dry docking',
			value: formatDate(v.nextDryDocking),
		},
		{
			key: 'nextSpecialSurvey',
			label: 'Next special survey',
			value: formatDate(v.nextSpecialSurvey),
		},
	];

	return (
		<Row gutter={[16, 16]}>
			{vessel.timelineData != null && vessel.timelineData.fixtures?.length > 0 && (
				<Col span={24}>
					<VesselTimeline
						vessel={vessel}
						data={vessel.timelineData}
					/>
				</Col>
			)}
			<Col span={16}>
				<Card
					title="Vessel on Map"
					extra={(
						<Link to={`${Links.Map.get(vessel.id)}`}>
							<Button
								icon={(<FontAwesomeIcon className={styles.icon} icon={faLocationArrow} />)}
								type="link"
							/>
							View on full map
						</Link>
					)}
					className={styles.mapCard}
				>
					<Map anchorPosition={position}>
						{({ viewport }) => (
							<>
								<ReportsLayer
									openReportDrawer={openReportDrawer}
									reports={vessel.latestReports}
									connectReports
									zoom={viewport.zoom}
								/>
								<VesselsLayer vesselIdsToShow={[vessel.id]} />
							</>
						)}
					</Map>
				</Card>
			</Col>
			<Col span={8}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Card title="Summary">
							<Details
								colon={false}
								bordered
								size="small"
								hideHeader
								boldValue
								items={getVesselSummaryItems(vessel)}
								clean
							/>
						</Card>
					</Col>
					{/* <Col span={24}>
						<Card
							extra={hasActiveVoyage && (
								<LiveButton to={Links.Voyage.get(vessel.activeVoyage.id)}>
									{vessel.activeVoyage.identifier}
								</LiveButton>
							)}
							title="Active Contract"
						>
							{hasActiveVoyage && vessel.activeVoyage != null ? (
								<Row gutter={[16, 16]}>
									<Col span={12}>
										<Statistic title="Open Port"
										value={vessel.activeVoyage.redeliveryPortsOrRangesLabel} />
									</Col>
									<Col span={12}>
										<Statistic
											title="Open Date"
											value={formatDate(vessel.activeVoyage?.openDate, DATE)}
										/>
									</Col>
									<Col span={12}>
										<Statistic
											title="Next Hire Invoice Due"
											value={getNextHireInvoiceDueLabel(vessel.activeVoyage?.nextHireDue)}
										/>
									</Col>
									<Col span={12}>
										<Statistic
											title={`TCE ${currencySymbols[activeVoyageCurrency] ||
												activeVoyageCurrency}/day`}
											prefix={currencySymbols[activeVoyageCurrency] || activeVoyageCurrency}
											value={vessel.activeVoyage?.stats?.pnl?.tce}
										/>
									</Col>
									<Col span={12}>
										<Statistic
											title="Total Invoiced"
											prefix={currencySymbols[activeVoyageCurrency] || activeVoyageCurrency}
											value={vessel.activeVoyage?.stats?.invoiced}
										/>
									</Col>
									<Col span={12}>
										<Statistic
											title="Total Paid"
											prefix={currencySymbols[activeVoyageCurrency] || activeVoyageCurrency}
											value={vessel.activeVoyage?.stats?.paid}
										/>
									</Col>
								</Row>
							) : (
								<Empty description="No active contract" />
							)}
						</Card>
					</Col> */}
				</Row>
			</Col>
		</Row>
	);
};
