import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Row,
	Space,
	Switch,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faClock,
	faFlagCheckered,
	faPlay,
	faRoute,
	faDroplet,
} from '@fortawesome/pro-light-svg-icons';
import {
	Icon,
	IconProp,
} from '@fortawesome/fontawesome-svg-core';
import debounce from 'lodash.debounce';
import { Marker } from 'react-map-gl';
import { faAnchor } from '@fortawesome/pro-solid-svg-icons';
import { formatDuration } from '@shared/utils/date';
import {
	DATE_AND_TIME,
	MetersPer,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import { formatNumber } from '@shared/utils/formatNumber';
import type { ItinerarySeaPassageDto } from '@api/features/ops/getVesselItinerary';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import NumericInput from '@client/components/NumericInput';
import RouteLayer from '@client/components/Map/layers/RouteLayer';
import Map from '@client/components/Map/Map';
import {
	getSeaPassageConsumption,
	updateSeaPassage,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import useDeepMemo from '@client/utils/hooks/useDeepMemo';
import PointLayer from '@client/components/Map/layers/PointLayer';
import { formatDate } from '@client/utils/formatDate';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import EmptyText from '@client/components/EmptyText';
import Select from '@client/components/Select';
import styles from '../ItineraryTab.module.css';

const SeaPassageSummary = ({
	selectedEntry,
	refreshDetails,
	consumptionSets,
}: {
	selectedEntry: ItinerarySeaPassageDto;
	refreshDetails: () => void;
	consumptionSets: ConsumptionSetProps[] | undefined;
}) => {
	const {
		route,
		departureDate,
		arrivalDate,
		nextPortCallId,
		departurePort,
		arrivalPort,
	} = selectedEntry;

	const [seaPassageSettings, setSeaPassageSettings] = useState(selectedEntry.routeOptions);
	const [routeLoading, setRouteLoading] = useState(false);

	const ballastLeg = nextPortCallId === null && selectedEntry.departurePort != null;

	const defaultMapPosition = useMemo(() => {
		return ballastLeg ? {
			longitude: departurePort?.longitude ?? 55.6,
			latitude: departurePort?.latitude ?? 12.5,
			zoom: 5,
		} : {
			longitude: route?.features?.[0]?.geometry.coordinates[0][0] ?? 55.6,
			latitude: route?.features?.[0]?.geometry.coordinates[0][1] ?? 12.5,
			zoom: 3,
		};
	}, [ballastLeg, departurePort?.latitude, departurePort?.longitude, route?.features]);

	const mapContent = useMemo(() => (ballastLeg ? (
		() => (
			<Marker
				key={selectedEntry.departurePort?.id}
				longitude={selectedEntry.departurePort!.longitude}
				latitude={selectedEntry.departurePort!.latitude}
				offsetTop={-14}
				offsetLeft={-14}
			>
				<FontAwesomeIcon
					icon={faAnchor as Icon}
					color="cyan"
				/>
			</Marker>
		)
	) : (
		() => (
			<>
				{selectedEntry != null && (
					<>
						{departurePort != null && (
							<PointLayer
								key={departurePort.name}
								id={`destination-${departurePort.name}`}
								name={departurePort.name}
								latitude={departurePort.latitude}
								longitude={departurePort.longitude}
								layerStyle={{
									paint: {
										'circle-color': '#7FFF00',
										'circle-radius': 17.5,
										'circle-opacity': 0.55,
									},
								}}
							/>
						)}
						{arrivalPort != null && (
							<PointLayer
								key={arrivalPort.name}
								id={`destination-${arrivalPort.name}`}
								name={arrivalPort.name}
								latitude={arrivalPort.latitude}
								longitude={arrivalPort.longitude}
								layerStyle={{
									paint: {
										'circle-color': '#FF0000',
										'circle-radius': 17.5,
										'circle-opacity': 0.55,
									},
								}}
							/>
						)}
						<RouteLayer
							id="route"
							data={selectedEntry.route}
							loading={false}
						/>
					</>
				)}
			</>
		)
	)), [ballastLeg, selectedEntry, arrivalPort, departurePort]);

	let distanceNm = 0;
	let secaDistanceNm = 0;

	if (route?.properties != null) {
		distanceNm = round(route?.properties.distance / MetersPer.NAUTICAL_MILE, 0);
		secaDistanceNm = round(route?.properties.secaIntersection / MetersPer.NAUTICAL_MILE, 0);
	}

	const [seaPassageConsumption] = useFetchedState(async () => await getSeaPassageConsumption(
		distanceNm - secaDistanceNm,
		secaDistanceNm,
		selectedEntry.vesselId,
		selectedEntry.routeOptions.speed,
		selectedEntry.vesselCondition,
		selectedEntry.routeOptions.seaMargin,
	), [seaPassageSettings, distanceNm, secaDistanceNm, selectedEntry]);

	useEffect(() => {
		setSeaPassageSettings(selectedEntry.routeOptions);
	}, [selectedEntry.id]);

	const map = useDeepMemo(() => {
		return (route != null || ballastLeg) && (
			<Map
				defaultPosition={defaultMapPosition}
			>
				{mapContent}
			</Map>
		);
	}, [ballastLeg, defaultMapPosition, mapContent, route]);

	const changeSetting = useCallback(async (setting: string, value: number | boolean) => {
		setRouteLoading(true);

		const newSettings = {
			...seaPassageSettings,
		};
		newSettings[setting] = value;
		setSeaPassageSettings(newSettings);

		try {
			await updateSeaPassage(selectedEntry.vesselId, selectedEntry.id, newSettings, true);
			await refreshDetails();
		} catch (e) {
			showErrorNotification('Could not update sea passage settings', e as Error);
		}

		setRouteLoading(false);
	}, [
		refreshDetails,
		seaPassageSettings,
		selectedEntry.id,
		selectedEntry.vesselId,
	]);

	const debouncedOnChange = useMemo(() => {
		return debounce((setting, value) => changeSetting(setting, value), 2000);
	}, [changeSetting]);

	const showNormalConsumption = seaPassageConsumption?.normal != null &&
		seaPassageConsumption?.normal.length > 0;
	const showEcaConsumption = seaPassageConsumption?.eca != null &&
		seaPassageConsumption?.eca.length > 0;

	return (
		<Row gutter={[8, 8]}>
			<Col md={24} xl={8}>
				<Row gutter={[16, 16]}>
					<Col md={12} xl={24} xxl={12}>
						<div>
							<Space className={styles.infoWrapper}>
								<FontAwesomeIcon icon={faPlay as IconProp} className={styles.highlightIcon} />
								Departure
							</Space>
							<br />
							{departureDate != null && formatDate(departureDate, DATE_AND_TIME)}
						</div>
					</Col>
					<Col md={12} xl={24} xxl={12}>
						<div>
							<Space className={styles.infoWrapper}>
								<FontAwesomeIcon
									icon={faFlagCheckered as IconProp}
									className={styles.highlightIcon}
								/>
								Arrival
							</Space>
							<br />
							{arrivalDate != null && formatDate(arrivalDate, DATE_AND_TIME)}
						</div>
					</Col>
					<Col md={12} xl={24} xxl={12}>
						<div>
							<Space className={styles.infoWrapper}>
								<FontAwesomeIcon icon={faClock as IconProp} className={styles.highlightIcon} />
								{`${selectedEntry.estimated || selectedEntry.inProgress ? 'Estimated ' : ''}Duration`}
							</Space>
							<br />
							{(arrivalDate != null && departureDate != null) ? formatDuration(departureDate, arrivalDate) : 'N/A'}
						</div>
					</Col>
					<Col md={12} xl={24} xxl={12}>
						<div>
							<Space className={styles.infoWrapper}>
								<FontAwesomeIcon icon={faRoute as IconProp} className={styles.highlightIcon} />
								Estimated Distance
							</Space>
							<br />
							{arrivalDate != null ? (
								<>
									<div>
										{`${formatNumber(distanceNm)} NM`}
									</div>
									<div className={styles.secaDistance}>
										{`${formatNumber(secaDistanceNm)} NM of which in ECA`}
									</div>
								</>
							) : 'N/A'}
						</div>
					</Col>
					<Col md={12} xl={24} xxl={12}>
						<div>
							<Space className={styles.infoWrapper}>
								<FontAwesomeIcon icon={faDroplet as IconProp} className={styles.highlightIcon} />
								Estimated Consumption
							</Space>
							<br />
							{arrivalDate != null ? (
								<>
									{(showNormalConsumption) && (
										seaPassageConsumption?.normal.map((cons) => {
											return (
												<div>
													<b>{`${cons.fuelType}: `}</b>
													{`${formatNumber(cons.consumption, { separateThousands: true }, 2)} MT`}
												</div>
											);
										})
									)}
									{(showEcaConsumption) && (
										seaPassageConsumption?.eca.map((cons) => {
											return (
												<div>
													<b>{`${cons.fuelType}: `}</b>
													{`${formatNumber(cons.consumption, { separateThousands: true }, 2)} MT`}
												</div>
											);
										})
									)}
									{(!showEcaConsumption && !showNormalConsumption) && (
										<EmptyText />
									)}
								</>
							) : 'N/A'}
						</div>
					</Col>
				</Row>
				{arrivalDate != null && (
					<div className={styles.settingsContainer}>
						<Typography.Title level={5}>Settings</Typography.Title>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<b>Allow Panama</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('allowPanamaTransit', value)}
									checked={seaPassageSettings.allowPanamaTransit}
								/>
							</Col>
							<Col span={12}>
								<b>Allow Suez</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('allowSuezTransit', value)}
									checked={seaPassageSettings.allowSuezTransit}
								/>
							</Col>
							<Col span={12}>
								<b>Allow Kiel</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('allowKielTransit', value)}
									checked={seaPassageSettings.allowKielTransit}
								/>
							</Col>
							<Col span={12}>
								<b>Avoid ECA</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('avoidSeca', value)}
									checked={seaPassageSettings.avoidSeca}
								/>
							</Col>
							<Col span={24}>
								<b>Avoid HRA</b>
								<br />
								<Switch
									disabled={routeLoading}
									onChange={(value) => changeSetting('avoidHRA', value)}
									checked={seaPassageSettings.avoidHRA}
								/>
							</Col>
							<Col span={12}>
								<b>Active speed pair</b>
								<br />
								<Select
									disabled={!(selectedEntry.estimated || selectedEntry.inProgress)}
									value={selectedEntry.activeConsumptionSetId}
									onChange={(value) => changeSetting('activeConsumptionSetId', Number(value))}
									options={(consumptionSets ?? []).map((set) => ({
										value: set.id,
										label: set.name,
									}))}
								/>
							</Col>
							<Col span={12}>
								<b>Sea Margin</b>
								<br />
								<NumericInput
									disabled={routeLoading || !(selectedEntry.estimated || selectedEntry.inProgress)}
									onChange={(value) => debouncedOnChange('seaMargin', value)}
									value={seaPassageSettings.seaMargin}
									addonAfter="%"
								/>
							</Col>
						</Row>
					</div>
				)}
			</Col>
			<Col md={24} xl={16}>
				<div className={styles.map}>
					{map}
				</div>
			</Col>
		</Row>
	);
};

export default SeaPassageSummary;
