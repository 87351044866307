import React, {
	SetStateAction,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Drawer,
	Form,
	Grid,
	Modal,
} from 'antd';
import isEqual from 'lodash.isequal';
import isItemPortCall from '@shared/utils/isItemPortCall';
import { asyncMap } from '@shared/utils/array';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';
import type { AttachmentProps } from '@api/models/attachment';
import type { ItineraryPortCallDto } from '@api/features/ops/getVesselItinerary';
import type { VoyageWithFixtureAndCounterparty } from '@api/features/voyages/getVoyageDetails';
import type {
	Parcel,
	StemRob,
} from '@api/features/voyages/bunker-stems/createBunkerStem';
import type { GetBunkerStemsResponse } from '@api/features/voyages/bunker-stems/getBunkerStems';
import {
	createBunkerStem,
	createSupplier,
	getSuppliers,
	getVoyageItinerary,
	updateBunkerStem,
} from '@client/lib/api';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFileUpload, { Upload } from '@client/utils/hooks/useFileUpload';
import InvoiceAttachment from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceAttachment';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import getFileFromAttachment from '@client/utils/getFileFromAttachment';
import LoadingIndicator from '@client/components/LoadingIndicator';
import styles from './CreateBunkerStemForm.module.css';
import BunkerStemFormItems from './BunkerStemFormItems';

type Props = {
	open: boolean;
	onClose: () => void;
	refreshDetails: () => void;
	initialFiles?: Upload[];
	editing?: GetBunkerStemsResponse[number] | undefined;
	documentId?: number;
	voyageId?: number;
	selectedPortCall?: ItineraryPortCallDto;
	tcInContract?: VoyageWithFixtureAndCounterparty | null;
	editingStem: GetBunkerStemsResponse[number] | undefined | null;
	setEditingStem: React.Dispatch<SetStateAction<GetBunkerStemsResponse[number]| undefined | null>>;
	fixtureCurrency: Values<typeof Currencies>;
}

const CreateBunkerStemForm = ({
	open,
	onClose,
	initialFiles,
	voyageId,
	selectedPortCall,
	tcInContract,
	refreshDetails,
	editingStem,
	setEditingStem,
	fixtureCurrency,
}: Props) => {
	const [startEdit, setStartEdit] = useState(false);
	const [suppliers, refreshSuppliers] = useFetchedState(getSuppliers);
	const [loadingAttachments, setLoadingAttachments] = useState(false);
	const [creatingNewSupplier, setCreatingNewSupplier] = useState(false);
	const [createCreditNote, setCreateCreditNote] = useState(false);
	const [selectedCurrencyAndRate, setSelectedCurrencyAndRate] = useState({
		currency: fixtureCurrency,
		exchangeRate: 1,
	});
	const [parcels, setParcels] = useState<Parcel[]>([]);
	const [robs, setRobs] = useState<StemRob[]>([]);
	const [itineraryEntry, setItineraryEntry] = useState<
		ItineraryPortCallDto | undefined
	>(selectedPortCall);
	const [dirty, setDirty] = useState(false);
	const [form] = Form.useForm();

	// Check if we need to ask user for credit note generation
	const dirtyParcelIds = useMemo(() => {
		if (!editingStem) {
			return [];
		}

		const orignalParcels = editingStem.parcels;

		const changedIds: number[] = [];
		orignalParcels.forEach((parcel) => {
			const localParcel = parcels.find((p) => p.voyageBunkerId === parcel.voyageBunkerId);

			if (parcel.voyageBunkerId != null && parcel.isInvoiced && !isEqual(parcel, localParcel)) {
				changedIds.push(parcel.voyageBunkerId);
			}
		});

		return changedIds;
	}, [parcels, editingStem]);

	const {
		uploaded,
		uploadProps,
		onRemoveUpload,
		onSetUploaded,
		onResetUploaded,
	} = useFileUpload({ multiple: true });

	useEffect(() => {
		if (initialFiles == null || initialFiles.length === 0) {
			return;
		}

		onSetUploaded(initialFiles);
	}, [onSetUploaded, initialFiles]);

	useEffect(() => {
		if (!open) {
			onResetUploaded();
			setStartEdit(false);
		}
	}, [onResetUploaded, open]);

	useEffect(() => {
		if (editingStem != null) {
			form.setFieldsValue(editingStem);
			setSelectedCurrencyAndRate({
				currency: editingStem.currency,
				exchangeRate: editingStem.exchangeRate,
			});
		}
	}, [form, editingStem]);

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	const screens = Grid.useBreakpoint();

	const [itinerary] = useFetchedState(() => getVoyageItinerary(voyageId!), [voyageId]);
	const portCalls = useMemo(
		() => itinerary?.filter((i): i is ItineraryPortCallDto => isItemPortCall(i)) ?? [],
		[itinerary],
	);

	useEffect(() => {
		if (itineraryEntry == null && selectedPortCall == null && portCalls != null) {
			const relevantPortCall = portCalls.find((pc) => pc.id === editingStem?.portCallId);

			if (relevantPortCall == null) {
				return;
			}

			setItineraryEntry(relevantPortCall);
		}
	}, [editingStem, itineraryEntry, portCalls, selectedPortCall]);

	useEffect(() => {
		if (!startEdit && editingStem != null) {
			setParcels(editingStem.parcels);
			setRobs(editingStem.robs);
			setStartEdit(true);
			setDirty(false);
		}
	}, [startEdit, editingStem]);

	const close = () => {
		onClose();
		onClose();
		setDirty(false);
		setParcels([]);
		setRobs([]);
		setEditingStem(undefined);
		form.resetFields();
	};

	const onCloseForm = makeBlockable(close);

	const attachmentUpload = useMemo(() => (
		<div className={styles.attachmentsInner}>
			<InvoiceAttachment
				files={uploaded}
				uploadProps={uploadProps}
				onRemoveFile={onRemoveUpload}
			/>
		</div>
	), [onRemoveUpload, uploadProps, uploaded]);

	useBlocker(dirty);

	const saveBunkerStem = async () => {
		const {
			supplierName,
			invoiceDate,
			stemDate,
			portCallId,
		} = form.getFieldsValue(true);

		if (voyageId == null) {
			return;
		}

		let { supplierId } = form.getFieldsValue(true);

		try {
			if (creatingNewSupplier && supplierName != null) {
				const supplier = await createSupplier(supplierName);
				supplierId = supplier.id;
			}

			const attachments = Array.isArray(uploaded) ? uploaded.map((u) => u.file) : uploaded;

			if (editingStem != null) {
				await updateBunkerStem({
					voyageId,
					stemId: editingStem.id,
					supplierId,
					invoiceDate,
					stemDate,
					robs,
					parcels,
					attachments,
					currency: selectedCurrencyAndRate.currency,
					exchangeRate: selectedCurrencyAndRate.exchangeRate,
					generateCreditNote: createCreditNote,
				});
			} else {
				await createBunkerStem({
					voyageId,
					supplierId,
					invoiceDate,
					stemDate,
					portCallId,
					robs,
					parcels,
					attachments,
					currency: selectedCurrencyAndRate.currency,
					exchangeRate: selectedCurrencyAndRate.exchangeRate,
				});
			}

			refreshDetails();
			refreshSuppliers();
			close();
		} catch (e) {
			console.error(e);
			showErrorNotification('Could not save bunker stem', e as Error);
		}
	};

	useEffect(() => {
		const updateDefaultFiles = async () => {
			setLoadingAttachments(true);

			if (editingStem == null) {
				setLoadingAttachments(false);

				return;
			}

			try {
				const getUploadObject = async (attachment: AttachmentProps) => {
					const file: File & {
						uid?: string;
						attachmentId?: number;
						deleteable?: boolean;
					} = await getFileFromAttachment(attachment);

					file.uid = `${file.name}-${file.lastModified}`;
					file.attachmentId = attachment.id;

					return {
						file: file as File & {
							uid: string;
							attachmentId: number;
							deleteable?: boolean;
						},
						url: window.URL.createObjectURL(file),
					};
				};

				const stemAttachments = editingStem.attachments;

				if (stemAttachments == null) {
					return;
				}

				const stemFiles = await asyncMap(
					stemAttachments,
					async (attachment) => {
						const uploadObj: {
							url?: any;
							deleteable?: boolean;
							file: File & {
								uid?: string;
								attachmentId?: number;
							};
						} = await getUploadObject(attachment);

						uploadObj.deleteable = true;

						return uploadObj as {
							url: any;
							deleteable?: boolean;
							file: File & {
								uid: string;
								attachmentId: number;
							};
						};
					},
				);

				onSetUploaded(stemFiles);
			} catch (e) {
				showErrorNotification('Could not load attachments', e as Error);
			}

			setLoadingAttachments(false);
		};

		updateDefaultFiles();
	}, [onSetUploaded, editingStem]);

	const handleModalBackgroundClick = (e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			onCloseForm();
		}
	};

	return (
		<>
			<Modal
				open={open}
				wrapClassName={styles.rightSideModalWrapper}
				footer={null}
				width="100%"
				mask={false}
			>
				<div className={styles.drawerSpacerContainer}>
					<div className={styles.drawerSpacer} />
					<div
						className={styles.attachmentsDraggerContainer}
						onClick={handleModalBackgroundClick}
						role="presentation"
					>
						{loadingAttachments ? (
							<LoadingIndicator />
						) : (
							!screens.xs && attachmentUpload
						)}
					</div>
				</div>
			</Modal>
			<Drawer
				open={open}
				placement="left"
				width={650}
				title={editingStem != null ? 'Update bunker stem' : 'Create new bunker stem'}
				onClose={onCloseForm}
				mask={!open}
			>
				<Form
					layout="vertical"
					form={form}
					preserve
				>
					<BunkerStemFormItems
						onSubmit={saveBunkerStem}
						creatingNewSupplier={creatingNewSupplier}
						setCreatingNewSupplier={setCreatingNewSupplier}
						parcels={parcels}
						setParcels={(incParcels) => {
							setDirty(true);
							setParcels(incParcels);
						}}
						robs={robs}
						setRobs={(incRobs) => {
							setDirty(true);
							setRobs(incRobs);
						}}
						suppliers={suppliers}
						voyageId={voyageId!}
						onCloseForm={onCloseForm}
						portCalls={portCalls}
						disableItinerarySelect={editingStem != null || selectedPortCall != null}
						itineraryEntry={itineraryEntry}
						setItineraryEntry={setItineraryEntry}
						tcInContract={tcInContract}
						editingStem={editingStem}
						defaultCurrency={fixtureCurrency}
						selectedCurrencyAndRate={selectedCurrencyAndRate}
						setSelectedCurrencyAndRate={setSelectedCurrencyAndRate}
						createCreditNote={createCreditNote}
						setCreateCreditNote={setCreateCreditNote}
						dirty={dirtyParcelIds.length > 0}
					/>
				</Form>
			</Drawer>
		</>
	);
};

export default CreateBunkerStemForm;
