import {
	Currencies,
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import { formatPercentage } from '@shared/utils/formatPercentage';
import { Values } from '@shared/utils/objectEnums';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';
import HIIBbItem from './HIIBbItem';

class HIIBbItemAddressCommission extends HireInvoiceItem {
	static get itemType() {
		return 'bbItemAddressCommission';
	}

	bbItemId: number | undefined;
	percentage: number | undefined;
	fixtureCurrency: Values<typeof Currencies>;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			bbItemId: number;
			percentage: number;
			fixtureCurrency: Values<typeof Currencies>;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this.bbItemId = params.bbItemId;
		this.percentage = params.percentage;
		this.fixtureCurrency = params.fixtureCurrency;

		this._showTotal = true;
		this.itemTypeLabel = 'Address Commission';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.COMMISSIONS;
		this.pnlGroup = PnlGroups.ADDRESS_COMMISSIONS;
		this._fields = {
			percentage: {
				type: HIIFieldTypes.PERCENTAGE,
				label: 'Commission percentage',
			},
		};
	}

	_calculateTotal(invoiceItems: HireInvoiceItem[]) {
		const bbItem = this.getParent(invoiceItems) as HIIBbItem;

		if (!bbItem || !invoiceItems) {
			return 0;
		}

		const total = bbItem.getTotal(invoiceItems) * ((this.percentage ?? 0) / 100);

		return -1 * total;
	}

	getDescription() {
		return `${formatPercentage(this.percentage ?? 0, 2)}`;
	}

	static async _getClassParams(model: any, _parentModel: any) {
		return {
			bbItemId: model.id,
			percentage: model.percentage,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);
		if (this.bbItemId == null) {
			throw new Error('bbItemId is null');
		}

		if (this.percentage == null) {
			return;
		}

		model.bbItemId = this.bbItemId;
		model.percentage = this.percentage;

		const hiiBbItem = await model.getBbItem();

		if (hiiBbItem) {
			const bbItem = await hiiBbItem.getBbItem();

			if (bbItem) {
				bbItem.addComm = this.percentage;
				await bbItem.save();
			}

			hiiBbItem.addComm = this.percentage;
			await hiiBbItem.save();
		}

		await model.save();
	}

	_getTemplateItemParams(invoiceItems: HireInvoiceItem[]) {
		const bbItem = this.getParent(invoiceItems) as HIIBbItem;

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					'Address commission',
					`${this.getDescription()}`,
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					' — Address commission',
					`${formatPercentage(this.percentage ?? 0, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(bbItem.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return this.percentage === other.percentage;
	}
}

HireInvoiceItem.addItemClass(HIIBbItemAddressCommission);

export default HIIBbItemAddressCommission;
