import React from 'react';
import { Alert } from 'antd';
import type { ReportGroupAttributes } from '@api/features/organizations/updateReportGroups';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import {
	getReportGroups,
	updateReportGroups,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import LoadingIndicator from '@client/components/LoadingIndicator';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import styles from './ReportGroups.module.css';

const ReportGroups = () => {
	const [reportGroups, refresh, _error, isLoading] = useFetchedState(getReportGroups);

	return (
		<div className={styles.tableContainer}>
			<Alert
				className={styles.alert}
				type="info"
				message={(
					<p>
						Report groups inserted here can be selected by any user on TC estimates.
					</p>
				)}
			/>
			{isLoading ? (<LoadingIndicator />) : (
				<EditableDetails<ReportGroupAttributes>
					className={styles.details}
					items={[
						{
							key: 'holdingReportGroups',
							label: 'Holding Report Groups',
							editable: true,
							value: reportGroups?.holdingReportGroups ?? [],
							type: 'list',
							inputProps: {
								allowSpace: true,
							},
						},
						{
							key: 'investorReportGroups',
							label: 'Investor Report Groups',
							editable: true,
							value: reportGroups?.investorReportGroups ?? [],
							type: 'list',
							inputProps: {
								allowSpace: true,
							},
						},
						{
							key: 'clientReportGroups',
							label: 'Client Report Groups',
							editable: true,
							value: reportGroups?.clientReportGroups ?? [],
							type: 'list',
							inputProps: {
								allowSpace: true,
							},
						},
					]}
					onSave={async (values) => {
						try {
							await updateReportGroups(values);
							showSuccessNotification('Report groups updated');
							refresh();
						} catch (e) {
							showErrorNotification('Cannot update report group');
						}
					}}
				/>
			)}
		</div>
	);
};

export default ReportGroups;
