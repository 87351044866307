import React from 'react';
import {
	Badge,
	Card,
	Col,
	Row,
	Space,
} from 'antd';
import type AccountingSystemConnection from '@api/models/accounting-system-connection';
import Button from '@client/components/Button';
import { createConnection } from '@client/lib/api';

const CodatConnectionDetails = ({
	connection,
	selectedItemId,
	onDisconnect,
	refreshConnection,
}: {
	connection?: AccountingSystemConnection;
	selectedItemId?: number | null;
	onDisconnect: () => void;
	refreshConnection: () => void;
}) => {
	const onCreateCompany = async () => {
		// create codat company and get id
		// create clearvoyage connection with said companyId
		if (selectedItemId != null) {
			await createConnection(selectedItemId);
			await refreshConnection();
		}
	};

	return (
		<Card title="Connection details">
			<Row gutter={[16, 16]}>
				<Col span={24}>
					{connection == null ? (
						<Badge status="error" text="No connection" />
					) : (
						<Space direction="vertical">
							<Badge status="success" text="Connected" />
							<p>Remember to configure the connection</p>
						</Space>
					)}
				</Col>
				<Col span={6}>
					<Space>
						<Button
							disabled={connection != null}
							type="primary"
							onClick={onCreateCompany}
						>
							Create Connection
						</Button>
						{connection != null && (
							<Button href={`https://link.codat.io/company/${connection.companyId}`} type="primary">
								Configure
							</Button>
						)}
						<Button
							onClick={onDisconnect}
							disabled={connection == null}
						>
							Disconnect
						</Button>
					</Space>
				</Col>
			</Row>
		</Card>
	);
};

export default CodatConnectionDetails;
