// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WqvPVWHANNGNVJjOarJA {\n\ttext-align: right;\n}\n\n.EcwirADbMTic9H5gV8BE {\n\twhite-space: pre;\n}\n\n.GlrDyzGaT0IeRt9GSQRK {\n\twidth: 175px;\n}\n\n.UhA5rFdc3lujYQmKM4TK {\n\tmargin-top: 16px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/dashboards/TcOutDashboard.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".badge {\n\ttext-align: right;\n}\n\n.formulaTooltip {\n\twhite-space: pre;\n}\n\n.selector {\n\twidth: 175px;\n}\n\n.marginTop {\n\tmargin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "WqvPVWHANNGNVJjOarJA",
	"formulaTooltip": "EcwirADbMTic9H5gV8BE",
	"selector": "GlrDyzGaT0IeRt9GSQRK",
	"marginTop": "UhA5rFdc3lujYQmKM4TK"
};
export default ___CSS_LOADER_EXPORT___;
