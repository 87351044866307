import { round } from '@shared/utils/math';
import {
	StringToToValidNumericStringOptions,
	stringToValidNumericString,
} from './string';

export const formatNumber = (
	number: number,
	options?: StringToToValidNumericStringOptions,
	decimals?: number,
) => {
	let numberToFormat = number;

	if (decimals != null) {
		numberToFormat = round(number, decimals);
	}

	const formattedNumber = decimals != null ? numberToFormat.toFixed(decimals) : `${numberToFormat}`;

	return stringToValidNumericString(formattedNumber, options);
};
