import React, { useEffect } from 'react';
import classNames from 'classnames';
import { DepartmentKey } from '@shared/utils/constants';
import { useGlobalState } from '@client/lib/globalState';
import ErrorPage from '@client/components/ErrorPage';
import { useAuth } from '@client/lib/auth';
import { getDepartmentLabel } from '@client/utils/getDepartmentLabel';
import { RootPages } from '@client/utils/links';
import styles from './styles/RootScreen.module.css';

export type RootScreenProps = {
	className?: string;
	padding?: boolean;
	error?: Error;
	rootPageTitle: typeof RootPages[number] | null;
	children?: React.ReactElement | Array<React.ReactElement | null>;
};

// This component should not be used directly
// It should be used by other screen components to easily implement the basic screen functionality
const RootScreen: React.FC<RootScreenProps> = ({
	children,
	className,
	padding = true,
	error,
	rootPageTitle,
}) => {
	const { userInfo } = useAuth();

	const [_pageTitle, setPageTitle] = useGlobalState('PAGE_TITLE');

	useEffect(() => {
		setPageTitle((
			rootPageTitle as typeof RootPages[number]) ??
			getDepartmentLabel(userInfo.activeDepartment as DepartmentKey));
	}, [rootPageTitle, userInfo.activeDepartment, setPageTitle]);

	if (error != null) {
		return (<ErrorPage error={error} />);
	}

	return (
		<div className={classNames({ [styles.paddingWrapper]: padding }, className)}>
			{children}
		</div>
	);
};

export default RootScreen;
