import {
	CargoUnitSingularLabels,
	CargoUnitTypes,
	Currencies,
	FreightRateType,
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import TemplateItem from '@shared/TemplateItem';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import { formatQuantity } from '@shared/utils/string';
import { round } from '@shared/utils/math';
import { formatNumber } from '@shared/utils/formatNumber';
import type HireInvoiceItemModel from '@api/models/hire-invoice-item';
import type { HIIDeadfreightType } from '@api/models/hii-deadfreight';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

class HIIDeadfreight extends HireInvoiceItem {
	static get itemType() {
		return 'deadfreight';
	}

	cargoId: number;
	quantity: number;
	cargoType: string;
	freightRate: number;
	freightType: Values<typeof FreightRateType>;
	charterer: number | null;
	note: string;
	unit: Values<typeof CargoUnitTypes>;
	currency: Values<typeof Currencies>;
	exchangeRate: number;
	percentage: number;
	maxPercentage: number;
	addressCommission: number;
	brokerCommission: number;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			cargoId: number;
			quantity: number;
			cargoType: string;
			freightRate: number;
			freightType: Values<typeof FreightRateType>;
			charterer: number | null;
			note: string;
			unit: Values<typeof CargoUnitTypes>;
			currency: Values<typeof Currencies>;
			exchangeRate: number;
			percentage: number;
			maxPercentage: number;
			addressCommission: number;
			brokerCommission: number;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });
		this.cargoId = params.cargoId;
		this.quantity = params.quantity;
		this.cargoType = params.cargoType;
		this.freightRate = params.freightRate;
		this.freightType = params.freightType;
		this.charterer = params.charterer;
		this.note = params.note;
		this.unit = params.unit;
		this.currency = params.currency;
		this.exchangeRate = params.exchangeRate;
		this.addressCommission = params.addressCommission;
		this.brokerCommission = params.brokerCommission;

		this.percentage = params.percentage;
		this.maxPercentage = params.maxPercentage ?? params.percentage;

		this._showTotal = true;
		this.addManually = true;

		this.itemTypeLabel = 'Deadfreight';
		this.pnlGroup = PnlGroups.FREIGHT;
		this.templateSection = TemplateItem.Sections.FREIGHT;

		this._fields = {
			quantity: {
				type: HIIFieldTypes.NUMBER,
				label: 'Quantity',
			},
			freightRate: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Deadfreight Rate',
			},
		};
	}

	_calculateTotal(_invoiceItems?: HireInvoiceItem[]) {
		let total = 0;

		total = this.quantity * this.freightRate;

		if (this.fixtureCurrency !== this.currency) {
			total /= this.exchangeRate;
		}

		return total * (this.percentage / 100);
	}

	getDescription() {
		return `${formatQuantity(this.quantity, this.unit)} ${this.cargoType} at 
		${formatCurrency(this.freightRate, this.currency)}/${this.unit}`;
	}

	static async _getClassParams(model: HIIDeadfreightType, _parentModel: typeof HireInvoiceItemModel) {
		const cargo = await model.getCargo();

		return {
			cargoId: cargo.id,
			freightType: cargo.freightType,
			charterer: cargo.charterer,
			unit: cargo.unit,
			currency: cargo.currency,
			exchangeRate: cargo.exchangeRate,
			cargoType: cargo.type,
			freightRate: model.freightRate,
			quantity: model.quantity,
			percentage: model.percentage,
		};
	}

	async saveModel(model: HIIDeadfreightType) {
		await super.saveModel(model);

		model.cargoId = this.cargoId;
		model.percentage = this.percentage;
		model.quantity = this.quantity;
		model.freightRate = this.freightRate;

		await model.save();
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					`Deadfreight ${formatQuantity(
						(this.quantity / 100) * this.percentage, this.unit,
					)} ${this.cargoType}`,
					(
						this.freightType === FreightRateType.PER_UNIT ?
							// eslint-disable-next-line max-len
							`${formatCurrency(this.freightRate, this.currency)} / ${CargoUnitSingularLabels[this.unit]}` :
							`${formatCurrency(this.freightRate, this.currency)}`
					),
				],
				children: [
					[`(${this.percentage}% of ${formatNumber(
						round(this.quantity, 2),
						{ separateThousands: true },
					)})`, this.note],
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					`Deadfreight (${this.cargoType}) (${this.percentage}%)`,
					`${this.quantity}`,
					'MT',
					`${formatCurrency(this.freightRate, this.currency, { hideSymbol: true })}`,
				],
				children: this.note != null ? [
					[{
						content: this.note,
						small: true,
						italic: true,
					}],
				] : undefined,
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIDeadfreight);

export default HIIDeadfreight;
