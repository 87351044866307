import React, { useCallback } from 'react';
import {
	Card,
	Row,
	Col,
} from 'antd';
import { useParams } from 'react-router-dom';
import { DATE_AND_TIME } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import { useAuth } from '@client/lib/auth';
import {
	getOrganizationUserDetails,
	updateOrganizationUser,
} from '@client/lib/api';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { Links } from '@client/utils/links';

const getItems = ({
	name,
	createdAt,
	updatedAt,
}) => [
	{
		key: 'name',
		label: 'Name',
		value: name,
		editable: true,
	},
	{
		key: 'createdAt',
		label: 'Creation date',
		value: formatDate(createdAt, DATE_AND_TIME),
		editable: false,
	},
	{
		key: 'updatedAt',
		label: 'Last updated',
		value: formatDate(updatedAt, DATE_AND_TIME),
		editable: false,
	},
];

const UserDetailsScreen = () => {
	const { id } = useParams();

	const {
		refreshAuthInfo,
		userInfo,
	} = useAuth();

	const [
		user,
		refreshUser,
		error,
		loading,
	] = useFetchedState(() => getOrganizationUserDetails(id), [], { showNotification: false });

	const save = useCallback(async (values) => {
		if (Object.keys(values).length > 0) {
			await updateOrganizationUser(id, values);
		}

		await refreshUser();
		if (userInfo.id === Number(id)) {
			await refreshAuthInfo();
		}
	}, [id, refreshAuthInfo, refreshUser, userInfo.id]);

	return (
		<SimpleScreen
			canGoBack
			breadcrumbs={[['Team Members', Links.Users.get()]]}
			title={user?.name}
			rootPageTitle="Team Members"
			loading={loading}
			error={error}
		>
			<Row gutter={16}>
				<Col span={12}>
					<Card>
						<EditableDetails
							items={user != null ? getItems(user) : []}
							onSave={save}
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default UserDetailsScreen;
