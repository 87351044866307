// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".khK0K7iri7WRsoQ9bVXX {\n\tflex: 1;\n\talign-items: center;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.Nslw1TbHktJgqqqZEWne {\n\tpadding-bottom: 55px;\n\tflex: 1;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.lxg7b3TyXNxIrpu9nNFr {\n\tpadding-bottom: 100px;\n\tflex: 1;\n}\n\n.ZCpepSI11Pwexc9xP0bd {\n\twidth: 250px;\n\tmax-width: 100%;\n}\n\n.DeGpnyZbu1DnebOncMU_ {\n\ttext-align: center;\n\tmargin-bottom: 30px;\n}\n\n.mFVUK8TUc6h8wJy0licf {\n\tpadding: 40px 0 15px;\n\ttext-align: center;\n\tmargin: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/FirstTimeSetup.module.css"],"names":[],"mappings":"AAAA;CACC,OAAO;CACP,mBAAmB;CACnB,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,oBAAoB;CACpB,OAAO;CACP,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,qBAAqB;CACrB,OAAO;AACR;;AAEA;CACC,YAAY;CACZ,eAAe;AAChB;;AAEA;CACC,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,oBAAoB;CACpB,kBAAkB;CAClB,SAAS;AACV","sourcesContent":[".content {\n\tflex: 1;\n\talign-items: center;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.loading {\n\tpadding-bottom: 55px;\n\tflex: 1;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.error {\n\tpadding-bottom: 100px;\n\tflex: 1;\n}\n\n.input {\n\twidth: 250px;\n\tmax-width: 100%;\n}\n\n.text {\n\ttext-align: center;\n\tmargin-bottom: 30px;\n}\n\n.header {\n\tpadding: 40px 0 15px;\n\ttext-align: center;\n\tmargin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "khK0K7iri7WRsoQ9bVXX",
	"loading": "Nslw1TbHktJgqqqZEWne",
	"error": "lxg7b3TyXNxIrpu9nNFr",
	"input": "ZCpepSI11Pwexc9xP0bd",
	"text": "DeGpnyZbu1DnebOncMU_",
	"header": "mFVUK8TUc6h8wJy0licf"
};
export default ___CSS_LOADER_EXPORT___;
