import React from 'react';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getUserData,
	updateOrganizationDetails,
} from '@client/lib/api';
import styles from './EstimatorSettings.module.css';

const EstimatorSettings = () => {
	const [userData, refreshUserData] = useFetchedState(getUserData);

	return (
		<EditableDetails
			className={styles.details}
			labelWidth={300}
			items={[
				{
					key: 'defaultSeaMargin',
					label: 'Default sea margin %',
					editable: true,
					type: 'percentage',
					value: userData?.defaultSeaMargin ?? 5,
				},
			]}
			onSave={async (values) => {
				try {
					await updateOrganizationDetails(values);
					await refreshUserData();
				} catch (e) {
					showErrorNotification('Cannot update report group');
				}
			}}
		/>
	);
};

export default EstimatorSettings;
