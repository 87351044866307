import moment, { Moment } from 'moment';
import type { DatePickerValue } from '@client/components/DatePicker';
import {
	DATE,
	LaytimeIntervals,
	UtcOffsets,
} from './constants';
import { round } from './math';

export type TimeFormat = 'utc' | 'localTime';

export const formatDate = (
	date: Moment | Date | string | number | null,
	format = DATE,
	includeTimezoneLabel = true,
	selectedTimeFormat?: TimeFormat,
) => {
	if (date == null) {
		return date;
	}

	let m = toMoment(date);
	const offset = moment.isMoment(date) ? date.utcOffset() : m.utcOffset();

	if (selectedTimeFormat != null) {
		m = selectedTimeFormat === 'utc' ? toMoment(date).utc() : toMoment(date).local(includeTimezoneLabel);
	}

	const offsetLabel = UtcOffsets.find((o) => o.offset === offset);

	let finalFormat = format;

	if (format.includes('HH') && includeTimezoneLabel) {
		finalFormat = `${format}${offsetLabel != null ? ` [${offsetLabel.name}]` : ''}`;
	}

	return m.format(finalFormat);
};

export const formatDuration = (
	rawStart: Moment | Date,
	rawEnd: Moment | Date,
	zeroStr?: boolean,
	format?: 'day' | 'hour',
	dayDecimal = 2,
) => {
	const startTime = toMoment(rawStart);
	const endTime = toMoment(rawEnd);

	const rawMinutes = toMoment(endTime).diff(startTime, 'minutes');

	const days = Math.floor(rawMinutes / 1440);
	const hours = Math.floor((rawMinutes % 1440) / 60);
	const minutes = rawMinutes % 60;

	// Format the duration into "X days, Y hours, Z minutes"
	// Showing only non-zero values, except minutes
	// Yikes
	let result = `
		${days > 0 ? `${days} day${days !== 1 ? 's' : ''},` : ''}
		${hours > 0 ? `${hours} hour${hours !== 1 ? 's' : ''},` : ''}
		${minutes > 0 ? `${minutes} minute${minutes !== 1 ? 's' : ''}` : ''}
	`;

	if (format === 'day') {
		const daysWithDecimal = round(rawMinutes / 1440, dayDecimal);
		result = `
				${daysWithDecimal} day${daysWithDecimal !== 1 ? 's' : ''}
		`;
	}

	if (format === 'hour') {
		const hoursWithDecimal = round(rawMinutes / 60, 1);
		result = `
				${hoursWithDecimal} hour${hoursWithDecimal !== 1 ? 's' : ''}
		`;
	}

	if (zeroStr && result.trim() === '') {
		result = `0 ${format ?? 'days'}`;
	}

	// Remove trailing comma
	return result.trim().replace(/,$/, '');
};

export const formatLaytimeDuration = (
	minutes: number,
	interval: LaytimeIntervals,
	decimals = 1,
) => {
	const duration = round(interval === LaytimeIntervals.HOUR ? (minutes / 60) : ((minutes / 60) / 24), decimals);
	const intervalStr = `${interval}${duration === 1 ? '' : 's'}`;

	return `${duration} ${intervalStr}`;
};

export const dateIsInPeriod = (
	date: Moment,
	periodFrom: Moment | string,
	periodTo: Moment | string,
	unit: moment.unitOfTime.StartOf = 'day',
) => (
	date.isSameOrAfter(periodFrom, unit) &&
	date.isSameOrBefore(periodTo, unit)
);

// A period should be an array containing [from, to]
export const dateIsInOneOfPeriods = (
	date: Moment,
	periods: [[Moment, Moment]],
	unit: moment.unitOfTime.StartOf = 'day',
) => periods.some(
	([start, end]) => dateIsInPeriod(date, start, end, unit),
);

/* eslint-disable no-restricted-syntax */
export const toMoment = (input: Moment | Date | string | number) => {
	if (input == null) {
		throw new Error('Function \'toMoment\' was called with nullish input. Perhaps you meant to use \'nowMoment\'?');
	}

	if (moment.isMoment(input)) {
		return moment(input);
	}

	if (input instanceof Date) {
		return moment.parseZone(input.toJSON());
	}

	return moment.parseZone(input);
};

export function nowMoment() {
	if (arguments.length > 0) {
		throw new Error('Function \'nowMoment\' was called with arguments. Perhaps you meant to use \'toMoment\'?');
	}

	return moment.utc();
}
/* eslint-enable no-restricted-syntax */

type StringifyDatePickerType =
	| Moment
	| Date
	| [Moment | undefined, Moment | undefined]
	| DatePickerValue
	| string
	| undefined;

export const stringifyIfMoment = (value: StringifyDatePickerType): string | null => {
	if (Array.isArray(value)) {
		return value.map(stringifyIfMoment).join(', ');
	}

	if (value == null) {
		return null;
	}

	if (moment.isMoment(value)) {
		return value.toISOString(true);
	}

	if (value instanceof Date || typeof value === 'string') {
		const newMoment = toMoment(value);

		if (!newMoment.isValid() && !(value instanceof Date)) {
			return value;
		}

		return newMoment.toISOString(true);
	}

	return value;
};
