import {
	useState,
	useCallback,
	useEffect,
} from 'react';
import { Moment } from 'moment';
import { nowMoment } from '@shared/utils/date';
import { MillisecondsPer } from '@shared/utils/constants';
import showErrorNotification from '@client/utils/showErrorNotification';

// Default time for automatic refresh (in milliseconds)
const defaultAutoRefreshTime = MillisecondsPer.MINUTE * 3;

/*
 * Fetches state from 'fetchData'.
 * Re-fetches whenever dependencies change (like all hooks).
 * Returns the state and a function to force re-fetch the state.
 */
const useFetchedState = <State>(
	fetchData: () => Promise<State>,
	dependencies: any[] = [],
	options: {
		showNotification?: boolean;
		autoRefresh?: boolean;
		autoRefreshTime?: number;
		initialState?: State;
	} = {},
): [
	State | undefined,
	() => Promise<void> | void, // Refresh
	Error | null,
	boolean, // Loading (initial)
	{
		loading: boolean;
		lastFetchTime: Moment | null;
	},
] => {
	const {
		showNotification = true,
		autoRefresh = true,
		autoRefreshTime = defaultAutoRefreshTime,
		initialState = undefined,
	} = options;

	const [state, setState] = useState<State | undefined>(initialState);
	const [error, setError] = useState<Error | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [_latestRequestId, setLatestRequestId] = useState<number | null>(null);
	const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
	const [lastFetchTime, setLastFetchTime] = useState<Moment | null>(null);

	useEffect(() => {
		if (error != null) {
			setState(initialState); // Could be undefined!
		}
	}, [error, initialState]);

	useEffect(() => {
		if (initialState !== undefined && state === undefined) {
			setState(initialState);
		}
	}, [initialState, state]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getData = useCallback(fetchData, dependencies);

	const handle = useCallback(async () => {
		setLoading(true);

		try {
			const requestId = Math.random();

			setLatestRequestId(requestId);

			const result = await getData();

			// Make sure that only the latest request can update the state
			// This is important if the dependencies are updated really fast
			// Example:
			//   1. dependencies = [false]
			//   2. Start request 1
			//   3. dependencies = [true]
			//   4. Start request 2
			//   5. Request 2 finishes
			//   6. Request 1 finishes
			// In this case, the first request would normally overwrite the second one
			// To counter this, we run the following check
			setLatestRequestId((latestRequestId) => {
				if (latestRequestId === requestId) {
					setError(null);
					setState(result);
				}

				return latestRequestId;
			});
		} catch (e: unknown) {
			setError(e as Error);

			if (showNotification) {
				showErrorNotification('Something went wrong', e as Error);
			}

			console.error('Failed to fetch.', e);
		} finally {
			setLoading(false);
			setLoadingInitial(false);
			setLastFetchTime(nowMoment());
		}

		// We don't want to include `initialState` in the
		// As that would make the hook refresh every call
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getData, showNotification]);

	useEffect(() => {
		handle();
	}, [handle]);

	useEffect(() => {
		if (!autoRefresh) {
			return undefined;
		}

		const intervalId = setInterval(() => {
			handle();
		}, autoRefreshTime);

		return () => {
			clearInterval(intervalId);
		};
	}, [handle, autoRefresh, autoRefreshTime]);

	return [
		state,
		handle,
		error,
		loadingInitial,
		{
			loading,
			lastFetchTime,
		},
	];
};

export default useFetchedState;
