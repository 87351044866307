import React from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import NewFleetTimeline from '../components/NewFleetTimeline';
import { DashboardComponent } from '../helpers/dashboardInfo';

const FleetTimelineDashboard: DashboardComponent = ({
	setLastUpdated,
}) => {
	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Card title="Fleet Timeline">
					<NewFleetTimeline setLastUpdated={setLastUpdated} />
				</Card>
			</Col>
		</Row>
	);
};

export default FleetTimelineDashboard;
