// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WfhHlBujdC4btac4UMj8 {\n\twidth: 100%;\n}\n\n.aSqxrw6M1_xeonRI_mn9 .ant-select-selector {\n\twidth: 100%;\n\tfont-size: 12px;\n}\n\n.Q8YK6csA9NgA2b8QjIxK {\n\tfont-size: 15px;\n}\n\n.aSqxrw6M1_xeonRI_mn9:focus {\n\tborder-color: #40a9ff;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/details/components/styles/misc.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":[".fullWidth {\n\twidth: 100%;\n}\n\n.select :global(.ant-select-selector) {\n\twidth: 100%;\n\tfont-size: 12px;\n}\n\n.editIcon {\n\tfont-size: 15px;\n}\n\n.select:focus {\n\tborder-color: #40a9ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "WfhHlBujdC4btac4UMj8",
	"select": "aSqxrw6M1_xeonRI_mn9",
	"editIcon": "Q8YK6csA9NgA2b8QjIxK"
};
export default ___CSS_LOADER_EXPORT___;
