import React, { useMemo } from 'react';
import {
	Row,
	Col,
	Grid,
} from 'antd'; import { useQuery } from 'react-query';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import {
	DashboardTypes,
	YearQuarterDashboardPeriods,
} from '@shared/utils/constants';
import { getFleetAnalytics } from '@client/lib/api';
import { ChartColors } from '@client/utils/constants';
import { useAuth } from '@client/lib/auth';
import QuickBar from '@client/components/QuickBar/QuickBar';
import FleetTimelineWidget from '../widgets/FleetTimelineWidget';
import ForwardCoverageWidget from '../widgets/ForwardCoverageWidget';
import ForwardHireNumberWidget from '../widgets/ForwardHireNumberWidget';
import ForwardHireRateNumberWidget from '../widgets/ForwardHireRateNumberWidget';
import PieChartWidget from '../widgets/PieChartWidget';
import NumberWidget from '../components/NumberWidget';
import { DashboardComponent } from '../helpers/dashboardInfo';
import styles from './TcOutDashboard.module.css';

const TcOutDashboard: DashboardComponent<YearQuarterDashboardPeriods> = ({
	period,
	setLastUpdated,
}) => {
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const {
		data: fetched,
		isFetching: loading,
	} = useQuery(
		[DashboardTypes.TC_OUT, period],
		() => getFleetAnalytics(period),
		{
			onSuccess: (d) => setLastUpdated(d.lastUpdated),
			refetchOnWindowFocus: false,
		},
	);

	const fleetAnalytics = useMemo(() => fetched?.data, [fetched?.data]);

	return (
		<>
			<Row gutter={[16, 16]} data-tour="dashboardTop">
				<Col span={24}>
					<QuickBar />
				</Col>
				<Col md={12} xl={6} xs={24}>
					<NumberWidget
						title="TC Profit"
						headerTooltip={(
							<div className={styles.formulaTooltip}>
								+ Revenue
								{'\n'}
								- Voyage Expenses
								{'\n'}
								- CAPEX
								{'\n'}
								- OPEX
								{'\n'}
								- TC-In costs
								{'\n'}
								= Profit
							</div>
						)}
						badge="NET"
						transformData={(data) => formatCurrency(
							round(data.pnl.profit ?? 0, 0),
							userInfo.baseCurrency,
						)}
						staticData={fleetAnalytics}
						externalLoading={loading}
					/>
				</Col>
				<Col md={12} xl={6} xs={24}>
					<NumberWidget
						title="Avg. TC Profit per day"
						badge="NET"
						headerTooltip={(data) => (data != null ? (
							`Profit of ${formatCurrency(round(data.pnl.profit ?? 0, 0), userInfo.baseCurrency)} divided by ${round(data.pnl.totalVesselDays)} total vessel days`
						) : undefined)}
						staticData={fleetAnalytics}
						externalLoading={loading}
						transformData={(data) => {
							if (
								typeof data.pnl.profit === 'number' &&
								data.pnl.profit > 0 &&
								typeof data?.pnl.totalVesselDays === 'number'
							) {
								return formatCurrency(
									round((data?.pnl.profit) / (data?.pnl.totalVesselDays), 0),
									userInfo?.baseCurrency!,
								);
							}

							return '0';
						}}
					/>
				</Col>
				<Col md={12} xl={6} xs={24}>
					<ForwardHireNumberWidget
						staticData={fleetAnalytics}
						loading={loading}
					/>
				</Col>
				<Col md={12} xl={6} xs={24}>
					<ForwardHireRateNumberWidget
						staticData={fleetAnalytics}
						loading={loading}
					/>
				</Col>
				<Col md={12} xl={8} xs={24}>
					<PieChartWidget
						entries={[
							{
								label: 'Fixed days',
								key: 'fixedDays',
								fill: ChartColors.BLUE,
							},
							{
								label: 'Unfixed days',
								key: 'unfixedDays',
								fill: ChartColors.CYAN,
							},
							{
								label: 'Off-hire days',
								key: 'offHireDays',
								fill: ChartColors.RED,
							},
						]}
						staticData={fleetAnalytics?.utilisation}
						externalLoading={loading}
						fetchDataParams={[period]}
						unit="days"
						roundTotal
						header="Fleet Utilisation"
					/>
				</Col>
				<Col md={12} xl={8} xs={24}>
					<ForwardCoverageWidget
						staticData={fleetAnalytics}
						loading={loading}
					/>
				</Col>
				<Col md={24} xl={8} xs={24}>
					<PieChartWidget
						externalLoading={loading}
						header="Exposure to Floating Hire"
						headerTooltip="The number of forward days currently on floating hire versus fixed hire"
						staticData={fleetAnalytics?.forwardDays}
						unit="days"
						roundTotal
						forward
						entries={[
							{
								label: 'Fixed hire',
								key: 'fixedHireDays',
								fill: ChartColors.BLUE,
							},
							{
								label: 'Floating hire',
								key: 'floatingHireDays',
								fill: ChartColors.LIGHTBLUE,
							},
						]}
					/>
				</Col>
			</Row>
			<Row gutter={[16, 16]} className={styles.marginTop}>
				{screens.md && (
					<Col span={24}>
						<FleetTimelineWidget
							staticData={fleetAnalytics}
							externalLoading={loading}
						/>
					</Col>
				)}
			</Row>
		</>
	);
};

export default TcOutDashboard;

