import React from 'react';
import { formatCurrency } from '@shared/utils/currency';
import styles from './styles/PaymentPartRow.module.css';

const PaymentPartRow = ({
	identifier,
	amount,
	currency,
}) => (
	<div className={styles.part}>
		<span className={styles.partInvoice}>
			{identifier}
		</span>
		<span className={styles.partAmount}>
			{formatCurrency(amount, currency)}
		</span>
	</div>
);

export default PaymentPartRow;
