// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lNnkhzeXKXLGB6w6R35j {\n\twidth: 230px;\n}\n\n.lNnkhzeXKXLGB6w6R35j > div {\n\tdisplay: flex;\n\talign-items: flex-end;\n}\n\n.lNnkhzeXKXLGB6w6R35j > div:first-child {\n\talign-items: center;\n}\n\n.lNnkhzeXKXLGB6w6R35j > div > h2 {\n\ttext-align: center;\n\tmargin-bottom: 0;\n}\n\n.lNnkhzeXKXLGB6w6R35j > div > * {\n\tflex: 1;\n}\n\n.lNnkhzeXKXLGB6w6R35j > div > *:nth-child(2) {\n\tflex: unset;\n\twidth: 32px;\n\tmargin: 0 8px !important;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.lNnkhzeXKXLGB6w6R35j strong {\n\tdisplay: block;\n\tmargin-top: 12px;\n}\n\n.YkNGgoTqagVn852mQMyl {\n\twidth: 100%;\n}\n\n.tAK27TNaxBGvOTu9y8j6 {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/EditReceivedPopover.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,aAAa;CACb,qBAAqB;AACtB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,OAAO;AACR;;AAEA;CACC,WAAW;CACX,WAAW;CACX,wBAAwB;CACxB,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".editValuesContainer {\n\twidth: 230px;\n}\n\n.editValuesContainer > div {\n\tdisplay: flex;\n\talign-items: flex-end;\n}\n\n.editValuesContainer > div:first-child {\n\talign-items: center;\n}\n\n.editValuesContainer > div > h2 {\n\ttext-align: center;\n\tmargin-bottom: 0;\n}\n\n.editValuesContainer > div > * {\n\tflex: 1;\n}\n\n.editValuesContainer > div > *:nth-child(2) {\n\tflex: unset;\n\twidth: 32px;\n\tmargin: 0 8px !important;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.editValuesContainer strong {\n\tdisplay: block;\n\tmargin-top: 12px;\n}\n\n.inputField {\n\twidth: 100%;\n}\n\n.select {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editValuesContainer": "lNnkhzeXKXLGB6w6R35j",
	"inputField": "YkNGgoTqagVn852mQMyl",
	"select": "tAK27TNaxBGvOTu9y8j6"
};
export default ___CSS_LOADER_EXPORT___;
