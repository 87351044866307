import React, {
	ReactElement,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { Card } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useQuery } from 'react-query';
import isDev from '@shared/utils/isDev';
import Button from '@client/components/Button';
import getDashboardPeriodTag from '@client/utils/getDashboardPeriodTag';
import TooltipIcon from '@client/components/TooltipIcon';
import { useAuth } from '@client/lib/auth';
import { useDashboardPeriod } from '../helpers/dashboardInfo';
import styles from './styles/DashboardWidgetWrapper.module.css';

export type FetchDataParams = any[] | never;

export type DashboardWidgetProps<
	OwnProps extends object,
	OmitProps extends keyof DashboardWidgetWrapperProps<Data, Params>,
	Data,
	Params extends FetchDataParams,
> = (
	& OwnProps
	& Omit<DashboardWidgetWrapperProps<Data, Params>, OmitProps>
);

export type DashboardWidgetWrapperProps<Data, Params extends any[]> = {
	renderWidget: (params: {
		data: Data | undefined;
		loading: boolean;
	}) => ReactNode;
	staticData?: Data;
	externalLoading?: boolean;
	fetchData?: (...params: Params) => Promise<Data>;
	fetchDataParams?: Params;
	initialData?: Data;
	title?: string;
	extra?: ReactNode;
	fullWidth?: boolean;
	header?: ReactNode;
	forward?: boolean;
	longLoadingIndicator?: ReactElement;
	showPeriodLabel?: boolean;
	slim?: boolean;
	headerTooltip?: string | ReactNode | ((data: Data | undefined) => string | ReactNode | undefined);
	className?: string;
	noContainer?: boolean;
};

const DashboardWidgetWrapper = <Data, Params extends any[] | never>({
	staticData,
	externalLoading = false,
	fetchData,
	fetchDataParams,
	renderWidget,
	initialData,
	title,
	extra,
	fullWidth,
	header,
	forward,
	longLoadingIndicator,
	showPeriodLabel = false,
	slim,
	headerTooltip,
	className,
	noContainer,
}: DashboardWidgetWrapperProps<Data, Params>): React.ReactElement => {
	const period = useDashboardPeriod();
	const { userInfo } = useAuth();

	if (isDev() && fetchData?.name === 'fetchData') {
		throw new Error('fetchData function must not be passed as an anonymous function. Parameters can be passed with the `fetchDataParams` prop');
	}

	const query = useQuery(
		[
			'dashboard-widget',
			fetchData?.name,
			userInfo.filterVessels ? userInfo.relevantVessels : {},
			...(fetchDataParams ?? []),
		],
		() => fetchData!(...(fetchDataParams ?? [] as any)),
		{
			enabled: fetchData != null && staticData == null,
		},
	);

	// When pressing "Retry" after fetching fails (and an error is shown),
	// it will, by default, fetch too fast for the user to see that a request
	// was actually sent.
	// To counter this, we handle the error loading state ourselves
	// That way, we can make sure it loads for a bit (defined in the useEffect hook)
	const [loading, setLoading] = useState(false);
	const [longLoading, setLongLoading] = useState(false);

	useEffect(() => {
		if (query.isLoading) {
			setLoading(true);
		} else {
			setTimeout(() => setLoading(false), 1000);
		}
	}, [query.isLoading]);

	useEffect(() => {
		let loadingTimeLimit: any;

		if (loading) {
			loadingTimeLimit = setTimeout(() => setLongLoading(true), 2000);
		}

		return () => {
			if (loadingTimeLimit != null) {
				clearTimeout(loadingTimeLimit);
			}
		};
	}, [loading]);

	const data = staticData ?? query.data ?? initialData;
	const isLoading = fetchData == null ? externalLoading : query.isLoading;
	const error = staticData ? null : query.error;

	let content: React.ReactElement | null = null;

	if (error) {
		content = (
			<div className={styles.errorWrapper}>
				<i>
					{(loading ?
						'Loading...' :
						(error as Error).message
					)}
				</i>
				<Button
					icon={(<SyncOutlined spin={loading} />)}
					onClick={() => query.refetch()}
					disabled={loading}
				>
					Retry
				</Button>
			</div>
		);
	} else {
		content = (
			isLoading && longLoading && longLoadingIndicator != null ? (
				longLoadingIndicator
			) : (
				<>
					{renderWidget({
						data,
						loading: isLoading,
					})}
					{showPeriodLabel && !header && (
						<div className={styles.periodLabel}>
							{period != null ? getDashboardPeriodTag(period, forward) : ''}
						</div>
					)}
				</>
			)
		);
	}

	const tooltip = typeof headerTooltip === 'function' ?
		headerTooltip(data) :
		headerTooltip;

	if (noContainer) {
		return content;
	}

	if (header) {
		return (
			<Card
				extra={extra}
				className={classNames(className, {
					[styles.noPaddingCard]: slim,
				})}
				title={(
					<div className={styles.periodTag}>
						{header}
						{showPeriodLabel && (
							<div
								style={{ fontSize: slim ? 12 : 14 }}
								className={styles.periodLabel}
							>
								{period != null ? getDashboardPeriodTag(period, forward) : ''}
							</div>
						)}
						{tooltip != null && (
							<TooltipIcon iconProps={{
								style: {
									fontSize: slim ? 10 : 16,
								},
							}}
							>
								{tooltip}
							</TooltipIcon>
						)}
					</div>
				)}
			>
				{content}
			</Card>
		);
	}

	return (
		<Card className={className}>
			<div className={styles.titleWrapper}>
				<div className={styles.title}>
					{title}
					{tooltip != null && (
						<TooltipIcon>{tooltip}</TooltipIcon>
					)}
				</div>
			</div>
			{extra != null && (
				<div className={styles.extra}>
					{extra}
				</div>
			)}
			{fullWidth ? content : (
				<div className={styles.number}>
					{content}
				</div>
			)}
		</Card>
	);
};

export default DashboardWidgetWrapper;
