import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	FloatButton,
	Layout,
	Modal,
	Spin,
} from 'antd';
import {
	Switch,
	useHistory,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { nowMoment } from '@shared/utils/date';
import { FeatureFlags } from '@shared/utils/constants';
import styles from '@client/screens/Dashboard.module.css';
import ImpersonationBar from '@client/components/ImpersonationBar';
import { useAuth } from '@client/lib/auth';
import { getRoutes } from '@client/utils/routes';
import TopBar from '@client/components/TopBar/TopBar';
import { PRODUCTION } from '@client/utils/constants';
import ErrorPage from '@client/components/ErrorPage';
import useFeatureFlag from '@client/utils/hooks/useFeatureFlag';
import FirstTimeSetup from '../components/FirstTimeSetup';
import Navbar from './general/HomeScreen/navbar/Navbar';

const {
	Content,
	Footer,
} = Layout;

const Dashboard = () => {
	const [loadingInfo, setLoadingInfo] = useState({
		loading: false,
		text: null,
	});

	const layoutRef = useRef();
	const [layoutReady, setLayoutReady] = useState(false);

	useEffect(() => {
		setLayoutReady(true);
	}, []);

	const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

	const {
		isAdmin,
		isManagement,
		userInfo,
	} = useAuth();

	const history = useHistory();
	const performanceFeatureFlag = useFeatureFlag(FeatureFlags.PERFORMANCE);

	const userFeatureFlags = {
		[FeatureFlags.PERFORMANCE]: performanceFeatureFlag,
	};

	const routes = (
		<Switch>
			{getRoutes({ isAdmin, userFeatureFlags, isManagement })}
		</Switch>
	);

	return (
		(
			<div className={styles.dashboardWrapper}>
				{(loadingInfo.loading) && (
					<div
						className={styles.loadingOverlay}
					>
						<Spin size="large" />
						{loadingInfo.text != null && (
							<span>{loadingInfo.text}</span>
						)}
					</div>
				)}
				<Layout className={styles.layout}>
					<ImpersonationBar />
					<Navbar
						setLoadingInfo={setLoadingInfo}
						onCollapse={setSidebarCollapsed}
					/>
					<div
						className={styles.contentLayout}
						ref={layoutRef}
					>
						<TopBar sidebarCollapsed={sidebarCollapsed} />
						<Content className={styles.content}>
							{userInfo.activeDepartment != null && (
								PRODUCTION ? (
									<ErrorBoundary
										resetKeys={[history.location.pathname]}
										FallbackComponent={() => (<ErrorPage error={{ message: 'Something went wrong' }} />)}
									>
										{ routes }
									</ErrorBoundary>
								) : (
									<>
										{ routes }
									</>
								)
							)}
						</Content>
						<Footer className={styles.footer}>
							{`ClearVoyage © ${nowMoment().format('YYYY')}`}
						</Footer>
					</div>
				</Layout>
				<Modal
					open={userInfo.activeDepartment == null}
					footer={null}
					centered
					title="First-time setup"
					closable={false}
					width="95%"
					className={styles.setupModal}
				>
					<FirstTimeSetup />
				</Modal>
				{layoutReady && (
					<FloatButton.BackTop
						className={styles.backToTop}
						target={() => layoutRef.current}
					/>
				)}
			</div>
		)
	);
};

export default Dashboard;
