// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x07w9eXu3v_fNtWGnaCA {\n\twidth: 100%;\n}\n\n.t8MMYbdAuwQO1gY7YRoo {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/BunkerForm.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".inputNumber {\n\twidth: 100%;\n}\n\n.dateInput {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputNumber": "x07w9eXu3v_fNtWGnaCA",
	"dateInput": "t8MMYbdAuwQO1gY7YRoo"
};
export default ___CSS_LOADER_EXPORT___;
