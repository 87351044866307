import React from 'react';
import {
	Input,
	Popover,
	Tooltip,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
	HIIFieldTypes,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import { stringToValidNumericString } from '@shared/utils/string';
import { formatDate } from '@client/utils/formatDate';
import Select from '@client/components/Select';
import Button from '@client/components/Button';
import DatePicker from '@client/components/DatePicker';
import CurrencyInput from '@client/components/CurrencyInput';
import NumericInput from '@client/components/NumericInput';
import styles from './styles/EditReceivedPopover.module.css';

const FieldInput = ({
	type,
	options,
	placeholderValue,
	currency,
	baseCurrency,
	...props
}) => {
	switch (type) {
		case HIIFieldTypes.NUMBER:
			return (
				<NumericInput
					placeholder={placeholderValue}
					{...props}
				/>
			);
		case HIIFieldTypes.CURRENCY:
			return (
				<CurrencyInput
					currency={currency}
					placeholder={stringToValidNumericString(
						placeholderValue == null ? '' : placeholderValue.toString(),
						{ separateThousands: true },
					)}
					{...props}
				/>
			);

		case HIIFieldTypes.EXCHANGE_RATE:
			return (
				<NumericInput
					value={placeholderValue}
					placeholder="Exchange Rate"
					addonAfter={`${currency} = 1 ${baseCurrency}`}
					{...props}
				/>
			);
		case HIIFieldTypes.TEXT:
			return (
				<Input
					placeholder={placeholderValue}
					{...props}
					onChange={(e) => props.onChange(e.target.value)}
				/>
			);
		case HIIFieldTypes.PERCENTAGE:
			return (
				<NumericInput
					{...props}
					placeholder={placeholderValue}
					addonAfter="%"
				/>
			);
		case HIIFieldTypes.SELECT:
			return (
				<Select
					{...props}
					className={styles.select}
					options={options.map((o) => ({
						label: o.label,
						value: o.value,
					}))}
				/>
			);
		case HIIFieldTypes.DATE:
			return (
				<DatePicker
					placeholder={formatDate(placeholderValue, DATE_AND_TIME)}
					{...props}
					time
					allowClear={false}
				/>
			);

		default:
			return null;
	}
};

const EditReceivedPopover = ({
	item,
	changedItem,
	disabled,
	onEditOurValue,
	ourTotal,
	ourName,
	fixtureCurrency,
}) => {
	const ourItem = changedItem;

	const getValue = (matchingItem, fieldName, totalValue) => {
		if (matchingItem == null) {
			return 0;
		}

		if (fieldName === 'totalOverride' && matchingItem?.totalOverride == null) {
			return totalValue;
		}

		if (matchingItem?.[fieldName] == null) {
			return 0;
		}

		return matchingItem[fieldName];
	};

	const getItemCurrency = (fieldName) => {
		if (fieldName === 'totalOverride') {
			return fixtureCurrency;
		}

		return item.currency || fixtureCurrency;
	};

	return (
		<Popover
			trigger={disabled ? [] : ['click']}
			placement="right"
			content={(
				<div className={styles.editValuesContainer}>
					<div>
						<h2>{ourName}</h2>
					</div>
					{Object.entries(item.getFields())
						.map(([fieldName, properties]) => {
							const ourValue = getValue(ourItem, fieldName, ourTotal);

							return (
								<React.Fragment key={fieldName}>
									<div>
										<div>
											<strong>{properties.label}</strong>
											<FieldInput
												{...properties}
												className={styles.inputField}
												type={properties.type}
												value={ourValue}
												onChange={(newValue) => onEditOurValue(fieldName, newValue)}
												currency={getItemCurrency(fieldName)}
											/>
										</div>
									</div>
								</React.Fragment>
							);
						})}
					<br />
				</div>

			)}
		>
			<Tooltip
				trigger={disabled ? ['hover'] : []}
			>
				<Button
					type="link"
					icon={(<EditOutlined />)}
					disabled={disabled}
				/>
			</Tooltip>
		</Popover>
	);
};

export default EditReceivedPopover;
