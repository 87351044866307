import { round } from '@shared/utils/math';

const _DEGREES_PRECISION = 3;
const _MINUTES_PRECISION = 2;
const SECONDS_PRECISION = 1;

export const coordinatesToDMSString = (params: { lat: number; long: number }) => {
	const latHemisphere = params.lat >= 0 ? 'N' : 'S';
	const longHemisphere = params.long >= 0 ? 'E' : 'W';
	const latDMS = degreesToDMSString(params.lat);
	const longDMS = degreesToDMSString(params.long);

	return `${latDMS}${latHemisphere}, ${longDMS}${longHemisphere}`;
};

const degreesToDMSString = (decimalDegrees: number) => {
	const absDegrees = Math.abs(decimalDegrees);
	const degrees = Math.floor(absDegrees);
	const minutes = Math.floor(round((absDegrees - degrees) * 60, 10));
	const seconds = Math.abs((absDegrees - degrees) * (60 * 60) - minutes * 60);
	const roundedSeconds = round(seconds, SECONDS_PRECISION);

	return `${degrees}°${minutes}'${roundedSeconds}"`;
};
