import {
	AccountTypes,
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import TemplateItem from '../TemplateItem';
import getFirstNotNull from '../utils/getFirstNotNull';
import HireInvoiceItem from './HireInvoiceItem';

class HIIVoyageExpense extends HireInvoiceItem {
	static get itemType() {
		return 'voyageExpense';
	}

	constructor(id, isOriginal, params = {}) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.voyageExpenseReceivableId = params.voyageExpenseReceivableId;
		this.amount = params.amount;
		this.invoiceDescription = params.invoiceDescription;
		this.account = params.account;
		this.currency = params.currency;
		this.exchangeRate = params.exchangeRate;

		this.unit = params.unit;
		this.quantity = params.quantity;
		this.unitPrice = params.unitPrice;

		this._showTotal = this.currency !== params.fixtureCurrency;
		this.itemTypeLabel = 'Voyage expense';
		this.addManually = true;
		this.templateSection = (this.account === AccountTypes.OWNER ?
			TemplateItem.Sections.EXPENSES_OWNER :
			TemplateItem.Sections.EXPENSES_CHARTERER
		);

		this.pnlGroup = (this.account === AccountTypes.OWNER ?
			PnlGroups.OWNER_EXPENSES :
			PnlGroups.CHARTERER_EXPENSES
		);

		const totalAmount = this.quantity * this.unitPrice;

		if (totalAmount > 0) {
			this.amount = totalAmount;
		}

		this._fields = {
			...(!this.unit) && {
				amount: {
					type: HIIFieldTypes.CURRENCY,
					label: 'Amount',
					currency: this.currency,
				},
			},
			...(this.currency !== this.fixtureCurrency) && {
				exchangeRate: {
					type: HIIFieldTypes.EXCHANGE_RATE,
					currency: this.currency,
					baseCurrency: this.fixtureCurrency,
					label: 'Exchange Rate',
				},
			},
			...(this.unit && this.quantity) && {
				quantity: {
					type: HIIFieldTypes.NUMBER,
					label: 'Quantity',
				},
				unitPrice: {
					type: HIIFieldTypes.CURRENCY,
					label: 'Unit Price',
					currency: this.currency,
				},
			},
		};
	}

	_calculateTotal(_invoiceItems, convertCurrency) {
		let total = this.amount;

		if (convertCurrency && this.fixtureCurrency !== this.currency) {
			total = this.amount / this.exchangeRate;
		}

		if (this.account === AccountTypes.OWNER) {
			return -total;
		}

		return round(total, 2);
	}

	getDescription() {
		return this.invoiceDescription;
	}

	static async _getClassParams(model, _parentModel) {
		const voyageExpenseReceivable = await model.getVoyageExpenseReceivable();

		return {
			voyageExpenseReceivableId: voyageExpenseReceivable.id,
			amount: getFirstNotNull(model.amountOverride, voyageExpenseReceivable.amount),
			account: voyageExpenseReceivable.account,
			invoiceDescription: voyageExpenseReceivable.itemDescription,
			currency: voyageExpenseReceivable.currency,
			exchangeRate: getFirstNotNull(
				model.exchangeRateOverride, voyageExpenseReceivable.exchangeRate,
			),
			unit: voyageExpenseReceivable.unit,
			quantity: voyageExpenseReceivable.quantity,
			unitPrice: voyageExpenseReceivable.unitPrice,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.voyageExpenseReceivableId = this.voyageExpenseReceivableId;

		await model.save();

		if (this.isOriginal) {
			const voyageExpenseReceivable = await model.getVoyageExpenseReceivable();

			voyageExpenseReceivable.amount = this.amount;
			voyageExpenseReceivable.exchangeRate = this.exchangeRate;
			voyageExpenseReceivable.unit = this.unit;
			voyageExpenseReceivable.quantity = this.quantity;
			voyageExpenseReceivable.unitPrice = this.unitPrice;

			await voyageExpenseReceivable.save();
		} else {
			model.amountOverride = this.amount;
			model.exchangeRateOverride = this.exchangeRate;
			await model.save();
		}
	}

	_getTemplateItemParams(invoiceItems) {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.invoiceDescription,
					[
						[
							...(this.quantity && this.unit ? [{
								content: `${this.quantity} ${this.unit}`,
								paddingTop: true,
							}] : []),

							...(this.unitPrice && this.currency && this.unit ? [{
								content: `@ ${formatCurrency(this.unitPrice, this.currency)}/${this.unit}`,
								paddingTop: true,
							}] : []),
						],
						...(this.currency !== this.fixtureCurrency ? [
							[{
								content: `Original amount: ${formatCurrency(this.amount, this.currency)}`,
								small: true,
								italic: true,
							}],
							[{
								content: `Exchange Rate: ${this.exchangeRate}`,
								small: true,
								italic: true,
							}],
						] : []),
					],
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					this.invoiceDescription,
					this.quantity != null ? this.quantity : '1',
					this.unit != null ? this.unit : '',
					this.unitPrice != null ?
						`${formatCurrency(this.unitPrice, this.currency, { hideSymbol: true })}` :
						`${formatCurrency(this._calculateTotal(invoiceItems, true), this.currency, { hideSymbol: true })}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIVoyageExpense);

export default HIIVoyageExpense;
