// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VwMIw_p3Eht_k_oakcXb {\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 100%;\n\twhite-space: nowrap;\n\tline-height: 25px;\n}\n\n.C_hMoFs5dmYkoftLSGcE {\n\tdisplay: flex;\n\talign-items: center;\n\tline-height: 40px;\n}\n\n.ge7g8dPG0C1xPOmOJTH_ .ant-table {\n\tfont-size: 11px;\n}\n\ntbody:last-child .etHvE4de1Z7E7L0bzmVA {\n\tmargin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/performance/PerformanceListScreen/PerformanceListScreen.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,WAAW;CACX,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".tableCell {\n\tdisplay: flex;\n\talign-items: center;\n\twidth: 100%;\n\twhite-space: nowrap;\n\tline-height: 25px;\n}\n\n.tableCellRow {\n\tdisplay: flex;\n\talign-items: center;\n\tline-height: 40px;\n}\n\n.smallText :global(.ant-table) {\n\tfont-size: 11px;\n}\n\ntbody:last-child .outerCell {\n\tmargin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableCell": "VwMIw_p3Eht_k_oakcXb",
	"tableCellRow": "C_hMoFs5dmYkoftLSGcE",
	"smallText": "ge7g8dPG0C1xPOmOJTH_",
	"outerCell": "etHvE4de1Z7E7L0bzmVA"
};
export default ___CSS_LOADER_EXPORT___;
