import React from 'react';
import { ColumnsType } from 'antd/es/table';
import {
	Currencies,
	PnlItemTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import {
	round,
	roundFixedDecimals,
} from '@shared/utils/math';
import { Values } from '@shared/utils/objectEnums';
import TooltipIcon from '@client/components/TooltipIcon';
import { TablePnlEntry } from '../VcPnlTab';

const numberField = (v: number) => (v == null ? ' ' : round(v, 2));

const getPnlTableColumns = (
	currency: Values<typeof Currencies>,
	tcPnl?: boolean,
	actualOrExpected?: boolean,
):
ColumnsType<TablePnlEntry> => {
	const currencyField = (v: number) => (v == null ? ' ' : formatCurrency(v, currency, { forceDecimals: true, maximumFractionDigits: 2 }));

	return [
		{
			title: 'Description',
			dataIndex: 'item',
			key: 'item',
			render: (item: string, entry: TablePnlEntry) => (entry.bold ? (<b>{item}</b>) : item),
		},
		{
			title: 'Estimated',
			dataIndex: 'estimated',
			key: 'estimated',
			render: (v: number, entry: TablePnlEntry) => {
				if (tcPnl && (entry.item === PnlItemTypes.ADDRESS_COMMISSIONS ||
					entry.item === PnlItemTypes.BROKER_COMMISSIONS ||
					entry.item === PnlItemTypes.CAPEX ||
					entry.item === PnlItemTypes.OPEX ||
					entry.item === PnlItemTypes.TC_IN_COSTS)) {
					return currencyField(v * -1);
				}

				return entry.number != null ? numberField(v) : currencyField(v);
			},
			width: 150,
			align: 'right',
		},
		{
			title: tcPnl && actualOrExpected ? (
				<div>
					Expected
					<TooltipIcon>
						This is a forecast of how the contract is expected to finish.
						This is calculated by taking all invoiced numbers so far,
						and using estimated numbers for the remainder of the contract
					</TooltipIcon>
				</div>
			) : 'Actual',
			dataIndex: 'actual',
			key: 'actual',
			render: (v: number, entry: TablePnlEntry) => {
				if (tcPnl && (entry.item === PnlItemTypes.ADDRESS_COMMISSIONS ||
					entry.item === PnlItemTypes.BROKER_COMMISSIONS ||
					entry.item === PnlItemTypes.CAPEX ||
					entry.item === PnlItemTypes.OPEX ||
					entry.item === PnlItemTypes.TC_IN_COSTS)) {
					return currencyField(v * -1);
				}

				return entry.number != null ? numberField(v) : currencyField(v);
			},
			width: 150,
			align: 'right',
		},
		...(tcPnl ? [{
			title: 'Invoiced',
			dataIndex: 'invoiced',
			key: 'invoiced',
			render: (v: number, entry: TablePnlEntry) => {
				if (entry.item === PnlItemTypes.ADDRESS_COMMISSIONS ||
					entry.item === PnlItemTypes.BROKER_COMMISSIONS ||
					entry.item === PnlItemTypes.CAPEX ||
					entry.item === PnlItemTypes.OPEX ||
					entry.item === PnlItemTypes.TC_IN_COSTS) {
					return currencyField(v * -1);
				}

				return entry.number != null ? numberField(v) : currencyField(v);
			},
			width: 150,
			align: 'right' as 'right',
		}] : []),
		{
			title: tcPnl ? (`Var (${tcPnl && actualOrExpected ? 'Exp' : 'Act'} - Est)`) : 'Variance',
			key: 'varAmount',
			render: (entry: TablePnlEntry) => {
				const v = entry.actual - entry.estimated;

				return entry.number != null ? numberField(v) : currencyField(v);
			},
			align: 'right',
			width: 150,
		},
		{
			title: '%',
			key: 'varPercentage',
			render: (entry: TablePnlEntry) => {
				const variance = entry.actual - entry.estimated;
				const varianceInPercentage = entry.estimated === 0 ? 0 : (variance / entry.estimated) * 100;

				return `${roundFixedDecimals(varianceInPercentage, 2)}%`;
			},
			align: 'right',
			width: 80,
		},
	];
};

export default getPnlTableColumns;
