import React from 'react';
import classNames from 'classnames';
import styles from './styles/LaycanDate.module.css';

const LaycanDate = ({ date, referenceDate }) => (
	<div
		className={classNames(styles.date, {
			[styles.reference]: date.isSame(referenceDate, 'day'),
		})}
	>
		{date.format('D')}
	</div>
);

export default LaycanDate;
