import React from 'react';
import { Link } from 'react-router-dom';
import { Moment } from 'moment';
import { capitalize } from '@shared/utils/string';
import { standardSort } from '@shared/utils/standardSort';
import { formatDate } from '@client/utils/formatDate';
import EmptyText from '@client/components/EmptyText';
import { Links } from '@client/utils/links';

const getFixtureTableColumns = () => [
	{
		title: 'Contract',
		dataIndex: 'identifier',
		key: 'identifier',
		sorter: standardSort('identifier'),
		render: (identifier: string, contract: { id: number }) => (contract && (
			<Link
				to={Links.Voyage.get(contract.id)}
			>
				{identifier}
			</Link>
		)),
	},
	{
		title: 'Status',
		dataIndex: 'state',
		key: 'state',
		render: capitalize,
	},
	{
		title: 'CP date',
		dataIndex: 'cpDate',
		key: 'cpDate',
		sorter: standardSort('cpDate'),
		render: (value: Moment | undefined) => (value != null ? formatDate(value) : (<EmptyText />)),
	},
	{
		title: 'Duration',
		dataIndex: 'duration',
		key: 'duration',
		sorter: standardSort('duration'),
	},
];

export default getFixtureTableColumns;
