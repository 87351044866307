import {
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Moment } from 'moment';
import HIIBbItem from '@shared/hireInvoice/HIIBbItem';

type BbItemField = 'from' | 'to' | 'amount';

type UpdateBbItemProperty = (
	bbItemId: number,
	field: BbItemField,
	value: any,
) => void;

type UseBbItemPeriodSplit = (
	items: HIIBbItem[],
	splitDate?: Moment | null,
) => [
	itemsWithBbItems: HIIBbItem[],
	updateBbItemProperty: UpdateBbItemProperty,
];

const useBbItemPeriodSplit: UseBbItemPeriodSplit = (items) => {
	const [internalItems, setInternalItems] = useState<HIIBbItem[]>(items);

	useEffect(() => {
		setInternalItems(items);
	}, [items]);

	const updateHIIBbItemProperty = useCallback<UpdateBbItemProperty>(
		(bbItemId, field, value) => {
			setInternalItems((prevItems) => {
				const newItems = prevItems.map((item) => {
					if (item.id === bbItemId) {
						const newItem = item.copy();
						newItem[field] = value;

						return newItem;
					}

					return item;
				});

				return newItems;
			});
		},
		[],
	);

	return [
		internalItems,
		updateHIIBbItemProperty,
	];
};

export default useBbItemPeriodSplit;
