import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Row,
} from 'antd';
import {
	AccountingItemApprovalStates,
	AccountingItems,
	AccountTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { calculateTotal } from '@shared/utils/math';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import EditableTable from '@client/components/EditableTable';
import {
	getEstimatedBrokerCommissions,
	getRequiredApprovals,
	getVoyageInvoices,
	updateVoyageBrokerPayment,
} from '@client/lib/api';
import { getBrokerInvoiceMap } from '@client/screens/fleet/VoyageDetailsScreen/components/CommissionsTab/utils/getBrokerInvoiceMap';
import {
	getBrokerInvoiceColumns,
	getTcCalculatedCommissionColumns,
	getVcCalculatedCommissionColumns,
	getVcEstimatedCommissionColumns,
} from '@client/screens/fleet/VoyageDetailsScreen/components/CommissionsTab/utils/columns';
import AddButton from '@client/components/AddButton';
import BrokerInvoiceDrawer from '@client/screens/fleet/VoyageDetailsScreen/components/BrokerInvoiceDrawer/BrokerInvoiceDrawer';
import Table from '@client/components/Table/Table';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import StatisticCard from '@client/components/StatisticCard';
import showErrorNotification from '@client/utils/showErrorNotification';
import styles from './CommissionsTab.module.css';

export type BrokerTab = {
	tab: string;
	key: string;
	id: string;
}

type Props = {
	fixtureDetails:
	GetFixtureDetailsResponse<SpotFixtureProps> |
	GetFixtureDetailsResponse<TcFixtureProps>;
	voyageDetails: GetVoyageDetailsResponse;
	refreshDetails: () => void;
}

const CommissionsTab = ({
	fixtureDetails,
	voyageDetails,
	refreshDetails,
}: Props) => {
	const [
		hireInvoices,
		_refreshHireInvoices,
		_hireInvoicesError,
		_hireInvoicesLoading,
	] = useFetchedState(async () => {
		if (voyageDetails != null) {
			try {
				const invoices = await getVoyageInvoices(voyageDetails.id);

				return (invoices ?? []).map((hireInvoice) => ({
					...hireInvoice,
					items: hireInvoice.items.map((i) => HireInvoiceItem.fromJSON(i)),
				}));
			} catch (e) {
				showErrorNotification('Could not load voyage invoices', e as Error);

				return [];
			}
		}

		return [];
	});
	const statCardSpan = fixtureDetails.type === FixtureTypes.SPOT ? 6 : 8;
	const [activeTab, setActiveTab] = useState<BrokerTab | undefined>();
	const [open, setOpen] = useState(false);
	const [editValues, setEditValues] = useState<GetVoyageDetailsResponse['brokerInvoices'][number] | null>(null);
	const expandedRowKeys = useMemo(() => {
		return voyageDetails?.brokerInvoices.reduce<Array<number>>((acc, h) => {
			if (h.state === AccountingItemApprovalStates.REJECTED) {
				acc.push(h.id);
			}

			return acc;
		}, []);
	}, [voyageDetails?.brokerInvoices]);

	const [estimatedBrokerCommissions] = useFetchedState(
		async () => {
			if (fixtureDetails.type === FixtureTypes.SPOT) {
				return await getEstimatedBrokerCommissions(
					(fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>).estimateId,
				);
			}

			return null;
		},
	);

	const tabList = useMemo(() => {
		const brokers: Array<{ tab: string; key: string; id: string }> = [];

		if (fixtureDetails.type === FixtureTypes.SPOT) {
			fixtureDetails.cargos
				.forEach((cargo) => cargo.Brokers
					.forEach((broker) => {
						if (
							brokers.find((b) => Number(b.id) === broker.id) ||
							broker.BrokerInCargo.paidBy === AccountTypes.CHARTERER
						) {
							return;
						}

						brokers.push({ tab: broker.name, key: broker.name, id: broker.id.toString() });
					}));
		} else {
			fixtureDetails.brokers.forEach((broker) => {
				const accountType = fixtureDetails.type === 'tcIn' ? AccountTypes.CHARTERER : AccountTypes.OWNER;

				if (broker.paidBy === accountType) {
					brokers.push({ tab: broker.name, key: broker.name, id: broker.id.toString() });
				}
			});
		}

		return brokers;
	},
	[fixtureDetails.brokers, fixtureDetails.cargos, fixtureDetails.type]);

	useEffect(() => {
		if (tabList != null && activeTab == null) {
			setActiveTab(tabList[0]);
		}
	}, [activeTab, tabList]);

	const calculatedData = useMemo(() => {
		if (hireInvoices == null || voyageDetails.hireInvoiceCount === 0) {
			return [];
		}

		return getBrokerInvoiceMap({ voyageDetails, fixtureDetails, hireInvoices: hireInvoices ?? [] });
	}, [fixtureDetails, hireInvoices, voyageDetails]);

	const activeCalculatedData = useMemo(() => {
		if (calculatedData == null || activeTab == null || Array.isArray(calculatedData)) {
			return [];
		}

		return calculatedData[activeTab.tab];
	}, [activeTab, calculatedData]);

	const estimatedData = useMemo(() => {
		const result = estimatedBrokerCommissions?.reduce((acc, bc) => {
			return {
				...acc,
				[bc.brokerName]: bc.commissions,
			};
		}, {});

		return result;
	}, [estimatedBrokerCommissions]);

	const activeEstimatedData = useMemo(() => {
		if (estimatedData == null || activeTab == null || Array.isArray(estimatedData)) {
			return [];
		}

		return estimatedData[activeTab.tab];
	}, [activeTab, estimatedData]);

	const activeBrokerInvoices = useMemo(() => {
		if (
			voyageDetails.brokerInvoices == null ||
			voyageDetails.brokerInvoices.length === 0 ||
			activeTab == null
		) {
			return [];
		}

		return voyageDetails.brokerInvoices
			.filter((invoice) => invoice.brokerId === Number(activeTab.id));
	}, [activeTab, voyageDetails.brokerInvoices]);

	const saveNote = useCallback(async (hiiId: number, { note }: { note: string }) => {
		if (activeTab?.id == null) {
			return;
		}

		await updateVoyageBrokerPayment(voyageDetails.id, Number(activeTab.id), hiiId, note);
		await refreshDetails();
	}, [activeTab?.id, refreshDetails, voyageDetails.id]);

	const handleEdit = useCallback((values: GetVoyageDetailsResponse['brokerInvoices'][number]) => {
		setEditValues(values);
		setOpen(true);
	}, []);

	const calculatedColumns = useMemo(
		() => {
			if (fixtureDetails.type === FixtureTypes.SPOT) {
				return getVcCalculatedCommissionColumns({
					currency: fixtureDetails.bankAccount.currency,
				});
			}

			return getTcCalculatedCommissionColumns(
				fixtureDetails as GetFixtureDetailsResponse<TcFixtureProps>,
			);
		},
		[fixtureDetails],
	);

	const [
		approvalsList,
	] = useFetchedState(getRequiredApprovals);

	const requiresApproval = useMemo(() => (
		approvalsList?.find((i) => i.type === AccountingItems.BrokerInvoices)?.requiresApproval
	), [approvalsList]);

	const confirmTitle = useCallback((row: GetVoyageDetailsResponse['brokerInvoices'][number], form?: boolean) => {
		let result = null;

		if (requiresApproval) {
			result = 'The invoice is being edited and will need approval again';
		}

		if (row.posted) {
			result = `Editing this invoice will unpost it${requiresApproval ? ' and would need approval again' : ''}`;

			if (form) {
				result = `This invoice will require re-posting after editing${requiresApproval ? ' and would need approval again' : ''}`;
			}
		}

		return row.state !== AccountingItemApprovalStates.PENDING ? result : null;
	}, [requiresApproval]);

	const brokerInvoiceColumns = useMemo(
		() => getBrokerInvoiceColumns(
			fixtureDetails.bankAccount.currency,
			handleEdit,
			refreshDetails,
			confirmTitle,
		),
		[confirmTitle, fixtureDetails.bankAccount.currency, handleEdit, refreshDetails],
	);

	const commissionsStats = useMemo(
		() => {
			let totalCommissions;
			let totalEstimatedCommissions = 0;

			if (voyageDetails.fixture.type === FixtureTypes.SPOT) {
				totalCommissions = calculateTotal(
					activeCalculatedData,
					(d: {commissionAmount: number}) => d.commissionAmount,
				);
			} else {
				totalCommissions = (activeCalculatedData || []).find((r: any) => r.isTotal)?.amount || 0;
			}

			totalEstimatedCommissions = activeEstimatedData.reduce((acc: number, d: {
				cargoId: number;
				cargoType: string;
				type: string;
				commissionPercent: number;
				commissionAmount: number;
			}) => {
				return acc + d.commissionAmount;
			}, 0) ?? 0;

			const amountInvoiced = calculateTotal(activeBrokerInvoices, (i) => i.amount);
			const difference = totalCommissions - amountInvoiced;

			return {
				totalCommissions,
				totalEstimatedCommissions,
				amountInvoiced,
				difference,
			};
		}, [
			activeBrokerInvoices,
			activeCalculatedData,
			activeEstimatedData,
			voyageDetails.fixture.type,
		],
	);

	return (
		<Card
			activeTabKey={activeTab?.tab}
			tabList={tabList ?? []}
			onTabChange={(tabName) => {
				const broker = tabList.find((tab) => tab.tab === tabName);
				setActiveTab(broker);
			}}
		>
			<Row gutter={[40, 16]}>
				{fixtureDetails.type === FixtureTypes.SPOT && (
					<Col span={6}>
						<StatisticCard
							items={[
								{
									label: 'Total Estimated Commissions',
									children: formatCurrency(
										commissionsStats.totalEstimatedCommissions,
										fixtureDetails.bankAccount.currency,
									),
								},
							]}
						/>
					</Col>
				)}
				<Col span={statCardSpan}>
					<StatisticCard
						items={[
							{
								label: 'Total Calculated Commissions',
								children: formatCurrency(
									commissionsStats.totalCommissions,
									fixtureDetails.bankAccount.currency,
								),
							},
						]}
					/>
				</Col>
				<Col span={statCardSpan}>
					<StatisticCard
						items={[
							{
								label: 'Total Invoiced',
								children: formatCurrency(
									commissionsStats.amountInvoiced,
									fixtureDetails.bankAccount.currency,
								),
							},
						]}
					/>
				</Col>
				<Col span={statCardSpan}>
					<StatisticCard
						items={[
							{
								label: 'Difference',
								children: formatCurrency(
									commissionsStats.difference,
									fixtureDetails.bankAccount.currency,
								),
							},
						]}
					/>
				</Col>
				<BrokerInvoiceDrawer
					brokerId={Number(activeTab?.id)}
					brokerName={activeTab?.tab ?? ''}
					voyageId={voyageDetails.id}
					contractIdentifier={voyageDetails.identifier}
					currency={fixtureDetails.bankAccount.currency}
					editValues={editValues}
					setEditValues={setEditValues}
					open={open}
					setOpen={setOpen}
					voyageDetails={voyageDetails}
					refreshDetails={refreshDetails}
					confirmTitle={confirmTitle}
				/>
				<Col span={24}>
					<Card
						className={styles.tableContainer}
						title="Calculated Commissions"
						slim
					>
						<EditableTable
							pagination={false}
							dataSource={activeCalculatedData ?? []}
							// @ts-ignore
							columns={calculatedColumns ?? []}
							// @ts-ignore
							onSave={saveNote}
							keyDataIndex="itemId"
							enableEdit={(row) => !row.isTotal}
						/>
					</Card>
				</Col>
				{fixtureDetails.type === FixtureTypes.SPOT && (
					<Col span={24}>
						<Card
							className={styles.tableContainer}
							title="Estimated Commissions"
							slim
						>
							<Table
								pagination={false}
								dataSource={activeEstimatedData ?? []}
								columns={getVcEstimatedCommissionColumns({
									currency: fixtureDetails.bankAccount.currency,
								}) ?? []}
							/>
						</Card>
					</Col>
				)}
				<Col span={24}>
					<Card
						className={styles.tableContainer}
						title="Broker Invoices"
						slim
						extra={(
							<AddButton
								onClick={() => setOpen(true)}
							>
								New Invoice
							</AddButton>
						)}
					>
						<Table
							pagination={false}
							dataSource={activeBrokerInvoices}
							columns={brokerInvoiceColumns ?? []}
							emptyText="Created broker invoices will appear here"
							expandable={{
								expandedRowRender: (row) => (row.stateNote != null && (
									<div>
										<strong>Reason:</strong>
										<p>{row.stateNote}</p>
									</div>
								)),
								rowExpandable: (row) => row.stateNote != null,
								defaultExpandedRowKeys: expandedRowKeys,
							}}
						/>
					</Card>
				</Col>
			</Row>
		</Card>
	);
};

export default CommissionsTab;
