// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Y5gKOV71XYtJk2EZHoMG {\n\tposition: fixed;\n\tbottom: 0;\n\toverflow: hidden;\n\tdisplay: flex;\n\talign-items: flex-end;\n\tbackground-color: white;\n\tbox-shadow: 0 -5px 7px -7px #333;\n\ttransition: box-shadow 300ms;\n}\n\n.oOvSFJMbnQ_wF5_52Qrj {\n\tbox-shadow: none;\n\topacity: 0;\n\tdisplay: none;\n}\n\n/* This class must be here so the class name is generated */\n/* stylelint-disable block-no-empty */\n.y6A6dbfkqxaETgPvhvb1 {}\n/* stylelint-enable block-no-empty */\n", "",{"version":3,"sources":["webpack://./client/src/components/Table/private/useFixedRows.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,SAAS;CACT,gBAAgB;CAChB,aAAa;CACb,qBAAqB;CACrB,uBAAuB;CACvB,gCAAgC;CAChC,4BAA4B;AAC7B;;AAEA;CACC,gBAAgB;CAChB,UAAU;CACV,aAAa;AACd;;AAEA,2DAA2D;AAC3D,qCAAqC;AACrC,uBAAW;AACX,oCAAoC","sourcesContent":[".ghostTable {\n\tposition: fixed;\n\tbottom: 0;\n\toverflow: hidden;\n\tdisplay: flex;\n\talign-items: flex-end;\n\tbackground-color: white;\n\tbox-shadow: 0 -5px 7px -7px #333;\n\ttransition: box-shadow 300ms;\n}\n\n.ghostHidden {\n\tbox-shadow: none;\n\topacity: 0;\n\tdisplay: none;\n}\n\n/* This class must be here so the class name is generated */\n/* stylelint-disable block-no-empty */\n.fixedRow {}\n/* stylelint-enable block-no-empty */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ghostTable": "Y5gKOV71XYtJk2EZHoMG",
	"ghostHidden": "oOvSFJMbnQ_wF5_52Qrj",
	"fixedRow": "y6A6dbfkqxaETgPvhvb1"
};
export default ___CSS_LOADER_EXPORT___;
