import React from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import { toMoment } from '@shared/utils/date';
import {
	HireTypes,
	UnnamedBankAccountName,
} from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import DatePicker from '@client/components/DatePicker';
import Button from '@client/components/Button';
import Select from '@client/components/Select';
import CurrencyInput from '@client/components/CurrencyInput';
import TooltipIcon from '@client/components/TooltipIcon';
import styles from './styles/voyageInvoiceForm.module.css';

export const getTitleSetting = (
	invoiceTitle,
	setInvoiceTitle,
	cumulative = false,
) => ({
	hide: cumulative,
	label: 'Title',
	content: (
		<Input
			value={invoiceTitle}
			onChange={(e) => setInvoiceTitle(e.target.value)}
		/>
	),
});

export const getShowPaymentTermsSetting = (
	showPaymentTerms,
	setShowPaymentTerms,
) => ({
	label: 'Include payment term',
	content: (
		<Select
			value={showPaymentTerms}
			onChange={(v) => typeof v === 'boolean' && setShowPaymentTerms(v)}
			options={[
				{ label: 'Include', value: true },
				{ label: 'Do not include', value: false },
			]}
		/>
	),
});

export const getInvoiceDateSetting = (
	invoiceDate,
	setInvoiceDate,
	cumulative = false,
) => ({
	hide: cumulative,
	label: 'Invoice Date',
	content: (
		<DatePicker
			allowClear={false}
			showNow={false}
			className={styles.settingsInput}
			value={invoiceDate}
			onChange={(date) => setInvoiceDate(date)}
		/>
	),
});

export const getBankAccountSetting = ({
	bankAccounts,
	currency,
	selectedBankAccount,
	setSelectedBankAccount,
}) => {
	const orgBankAccounts = bankAccounts
		.filter((account) => account?.vesselId === null)
		.map((account) => ({
			label: `${account.name || UnnamedBankAccountName} (${account.currency})`,
			value: account.id,
		}));

	const vesselBankAccounts = bankAccounts
		.filter((account) => account?.vesselId != null)
		.map((account) => {
			const label = account.name || UnnamedBankAccountName;
			const suffix = account.businessUnit != null ?
				`(${account.businessUnit}) (${account.currency})` :
				`(${account.currency})`;

			return {
				label: `${label} ${suffix}`,
				value: account.id,
			};
		});

	const noAvailableAccounts = orgBankAccounts.length === 0 && vesselBankAccounts.length === 0;

	return {
		label: 'Bank Account',
		content: (
			noAvailableAccounts ? (
				<strong>
					No available bank accounts with currency
					{' '}
					{currency}
				</strong>
			) : (
				<Select
					value={selectedBankAccount}
					onSelect={setSelectedBankAccount}
					options={[
						...(orgBankAccounts.length > 0 ? [{
							label: 'Organization',
							options: [
								...orgBankAccounts,
							],
						}] : []),
						...(vesselBankAccounts.length > 0 ? [{
							label: 'Vessel',
							options: [
								...vesselBankAccounts,
							],
						}] : []),
					]}
				/>
			)

		),
	};
};

export const getHireTypeSetting = (
	includeHirePeriod,
	hireItem,
	setHireType,
) => ({
	hide: !includeHirePeriod,
	label: 'Hire Type',
	content: (
		<Select
			value={hireItem.hireType}
			onChange={(v) => setHireType(v)}
			options={Object.values(HireTypes).map((v) => ({
				label: capitalize(v),
				value: v,
			}))}
		/>
	),
});

export const getHireRateSetting = (
	includeHirePeriod,
	fixtureCurrency,
	hireItem,
	setHireRate,
) => ({
	hide: !includeHirePeriod,
	label: 'Hire rate',
	content: (
		<CurrencyInput
			currency={fixtureCurrency}
			className={classNames(styles.settingsInput, {
				[styles.missingHire]: includeHirePeriod && hireItem.hireRate === 0,
			})}
			value={hireItem.hireRate}
			onChange={setHireRate}
		/>
	),
});

export const getHireDescriptionSetting = (
	includeHirePeriod,
	fixtureDetails,
	hireItem,
	setHireDescription,
) => ({
	hide: !includeHirePeriod,
	label: 'Hire description',
	content: (
		<Input
			placeholder={fixtureDetails.hireDescription}
			className={styles.settingsInput}
			value={hireItem.hireDescription}
			onChange={setHireDescription}
		/>
	),
});

export const getIncludeHirePeriodSetting = (
	cumulative,
	includeHirePeriod,
	setIncludeHirePeriod,
) => ({
	hide: cumulative,
	label: 'Include hire period',
	content: (
		<Select
			value={includeHirePeriod}
			onChange={(v) => setIncludeHirePeriod(v)}
			options={[
				{ label: 'Include', value: true },
				{ label: 'Do not include', value: false },
			]}
		/>
	),
});

export const getHirePeriodEndSetting = (
	cumulative,
	includeHirePeriod,
	hireItem,
	setHirePeriodEnd,
	completionDate,
	details,
	hirePeriodEndPickerRef,
) => ({
	hide: cumulative || !includeHirePeriod,
	label: 'Hire period end',
	content: (
		<DatePicker
			ref={hirePeriodEndPickerRef}
			allowClear={false}
			time
			showNow={false}
			className={styles.settingsInput}
			dropdownClassName={styles.hireEndDatePicker}
			value={hireItem.to}
			onChange={setHirePeriodEnd}
			minDate={hireItem.from}
			maxDate={completionDate}
			renderExtraFooter={() => (
				<>
					<Button
						type="link"
						size="small"
						disabled={details.completionDate == null}
						onClick={() => {
							setHirePeriodEnd(toMoment(completionDate).utc());
							hirePeriodEndPickerRef.current.blur();
						}}
					>
						Add remaining hire
					</Button>
					{details.completionDate == null && (
						<TooltipIcon>
							Select a Redelivery Date to enable this option
						</TooltipIcon>
					)}
				</>
			)}
		/>
	),
});

export const getIncludePreviousSetting = (cumulative, showPreviousItems, setShowPreviousItems) => ({
	hide: cumulative,
	label: 'Include previous items and payments',
	content: (
		<Select
			value={showPreviousItems}
			onChange={(v) => setShowPreviousItems(v)}
			options={[
				{ label: 'Do not include', value: false },
				{ label: 'Include', value: true },
			]}
		/>
	),
});
