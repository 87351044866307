import React, { ReactNode } from 'react';
import classNames from 'classnames';
import EditableFieldRedux, { EditableFieldReduxProps } from './EditableFieldRedux';
import styles from './styles/EditableCellRedux.module.css';

export type EditableCellReduxProps = (
	| (EditableFieldReduxProps & { render?: never })
	| { render: () => ReactNode }
) & {
	className?: string;
	draggable?: boolean;
	missing?: boolean;
};

export const EditableCellRedux = (
	props: EditableCellReduxProps,
) => {
	const { render, className, draggable, missing } = props;

	if (render != null) {
		return (
			<td className={classNames(className, { [`${styles.missing}`]: missing })} draggable={draggable}>
				<div>
					{render()}
				</div>
			</td>
		);
	}

	// eslint-disable-next-line react/destructuring-assignment
	if (props.type == null) {
		return null;
	}

	return (
		<td className={classNames(className, { [`${styles.missing}`]: missing })} draggable={draggable}>
			<div>
				<EditableFieldRedux
					{...props}
				/>
			</div>
		</td>
	);
};

export default EditableCellRedux;
