// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jj7aVdSQ3zihc3dbvLI3 {\n\tmin-width: 40%;\n\tmax-width: 850px;\n}\n\n.EM3EymiR1FTpcEMJ_m8E {\n\tmargin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/management/approvals/ApprovalSettings.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".tableContainer {\n\tmin-width: 40%;\n\tmax-width: 850px;\n}\n\n.alert {\n\tmargin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "jj7aVdSQ3zihc3dbvLI3",
	"alert": "EM3EymiR1FTpcEMJ_m8E"
};
export default ___CSS_LOADER_EXPORT___;
