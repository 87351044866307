import React, {
	useEffect,
	useRef,
} from 'react';
import { faGripLines } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';
import classNames from 'classnames';
import {
	useDrag,
	useDrop,
} from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styles from './DraggableListItem.module.css';

const DefaultSeparator = () => (<Divider className={styles.slimDivider} />);

const DraggableListItem = ({
	dragItem,
	onDragItemChange,
	renderItem,
	renderSeparator,
	index,
	includeSeparator,
	onMove,
	onDragStart,
	onDrop,
	getCanDrag,
}) => {
	const hasDragged = useRef(false);
	const ref = useRef(null);

	const [_params, drop] = useDrop({
		accept: 'card',
		collect: () => ({}),
		hover: (hoverItem, monitor) => {
			if (!ref.current) {
				return;
			}

			const dragIndex = hoverItem.dragItem.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			if (onMove != null) {
				onMove(dragIndex, hoverIndex);
			}

			hoverItem.dragItem.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag, preview] = useDrag({
		item: {
			type: 'card',
			dragItem,
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		begin() {
			onDragItemChange({ ...dragItem, isDragging: true });
			if (onDragStart != null) {
				onDragStart();
			}
		},
		end() {
			onDragItemChange({ ...dragItem, isDragging: false });
		},
	});

	useEffect(() => {
		if (isDragging) {
			hasDragged.current = true;

			return;
		}

		if (hasDragged.current) {
			if (onDrop != null) {
				onDrop();
			}

			hasDragged.current = false;
		}
	}, [isDragging, onDrop, dragItem]);

	useEffect(() => {
		preview(getEmptyImage());
	}, [preview]);

	const canDrag = getCanDrag == null || getCanDrag(dragItem.data, index, dragItem);

	if (canDrag) {
		drag(drop(ref));
	}

	return (
		<div ref={ref}>
			<div
				className={classNames(styles.item, {
					[styles.dragging]: isDragging,
				})}
			>
				<FontAwesomeIcon
					className={classNames(styles.grip, {
						[styles.dragDisabled]: !canDrag,
					})}
					icon={faGripLines}
				/>
				<div className={styles.content}>
					{renderItem(dragItem.data, index, dragItem)}
				</div>
			</div>
			{includeSeparator && renderSeparator != null && (
				renderSeparator(dragItem.data, index, dragItem)
			)}
			{includeSeparator && renderSeparator == null && (
				<DefaultSeparator />
			)}
		</div>
	);
};

export default DraggableListItem;
