import React from 'react';
import { useHistory } from 'react-router';
import PopoverList from '@client/components/PopoverList';
import { useAuth } from '@client/lib/auth';
import { setSelectedOrganizationId } from '@client/lib/api';

const OrganizationSelect = ({ children }) => {
	const { userInfo } = useAuth();
	const history = useHistory();

	if (userInfo.allOrganizations.length === 1) {
		return null;
	}

	return (
		<PopoverList
			title="Select Organization"
			emptyText="No Organizations"
			items={userInfo.allOrganizations.map((org) => {
				const isCurrent = org.id === userInfo.organizationId;

				return {
					key: org.id,
					title: isCurrent ? `${org.name} (current)` : org.name,
					disabled: isCurrent,
					onClick: () => {
						setSelectedOrganizationId(org.id);
						history.push('/');
						window.location.reload();
					},
				};
			})}
		>
			{children}
		</PopoverList>
	);
};

export default OrganizationSelect;
