// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VAyIFR5sO5YHXvyGua37 {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.VAyIFR5sO5YHXvyGua37.ant-typography p {\n\tmargin: 0;\n}\n\n.xNPdFPU4zV0vEVojfa_k {\n\tbackground-color: white;\n\tcolor: red;\n}\n\n.uYkqTyuWUEzil2yUbXNz {\n\tpadding: 0 !important;\n\tmargin-left: 5px;\n}\n\n.s3L3D7eMPOd469_8hixx {\n\twidth: 100px;\n}\n\n.tZBFDwhjymK9KsbtgsB1 .ant-skeleton-button {\n\twidth: 100px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/EditableText.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,uBAAuB;CACvB,UAAU;AACX;;AAEA;CACC,qBAAqB;CACrB,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;AACb","sourcesContent":[".customEditField {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.customEditField:global(.ant-typography p) {\n\tmargin: 0;\n}\n\n.customEditFieldRequired {\n\tbackground-color: white;\n\tcolor: red;\n}\n\n.customEditButton {\n\tpadding: 0 !important;\n\tmargin-left: 5px;\n}\n\n.stateSelect {\n\twidth: 100px;\n}\n\n.skeleton :global(.ant-skeleton-button) {\n\twidth: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customEditField": "VAyIFR5sO5YHXvyGua37",
	"customEditFieldRequired": "xNPdFPU4zV0vEVojfa_k",
	"customEditButton": "uYkqTyuWUEzil2yUbXNz",
	"stateSelect": "s3L3D7eMPOd469_8hixx",
	"skeleton": "tZBFDwhjymK9KsbtgsB1"
};
export default ___CSS_LOADER_EXPORT___;
