import React from 'react';
import {
	Card,
	Col,
	Row,
} from 'antd';
import {
	CapexOpexInput,
	Currencies,
	currencySymbols,
} from '@shared/utils/constants';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { formatCurrency } from '@shared/utils/currency';
import { standardSort } from '@shared/utils/standardSort';
import { formatDate } from '@client/utils/formatDate';
import {
	deleteVesselFinancialData,
	createVesselFinancialData,
	updateVesselFinancialData,
} from '@client/lib/api';
import EditableTable from '@client/components/EditableTable';
import { useAuth } from '@client/lib/auth';

export const VesselFinanceTab = ({
	vessel,
	vesselId,
	refreshVessel,
}) => {
	const { userInfo } = useAuth();

	const saveFinancialDetails = async ({ capex, opex, momentYear, momentMonth }) => {
		await createVesselFinancialData(
			vesselId,
			momentYear ? toMoment(momentYear).year() : null,
			momentMonth ? toMoment(momentMonth).format('YYYY-MM-01') : null,
			capex,
			opex,
		);
		await refreshVessel();
	};

	const deleteFinancialDetailsEntry = async (id) => {
		await deleteVesselFinancialData(vessel.id, id);
		await refreshVessel();
	};

	const updateFinancialDetailsEntry = async (entryId, values) => {
		await updateVesselFinancialData(vessel.id, entryId, {
			...values,
			year: values.momentYear ? toMoment(values.momentYear).year() : null,
		});
		await refreshVessel();
	};

	const vesselFinanceEntries = vessel.vesselFinanceEntries.map((entry) => {
		const momentYear = nowMoment().set('year', entry.year);
		const momentMonth = entry.month;

		return {
			...entry,
			momentYear,
			momentMonth,
		};
	});

	return (
		<Row gutter={[16, 16]}>
			{vessel.ownershipType !== 'tcIn' && (
				<Col span={12}>
					<Card title="Vessel Finance">
						<EditableTable
							pagination={false}
							emptyText="Add Opex & Capex"
							keyDataIndex="id"
							key={vesselFinanceEntries.length}
							iconButtons
							size="small"
							dataSource={vesselFinanceEntries}
							onSave={updateFinancialDetailsEntry}
							enableDelete
							allowAddNew
							onAddNew={saveFinancialDetails}
							addNewText="Vessel Opex & Capex"
							onDelete={deleteFinancialDetailsEntry}
							columns={[
								...(userInfo.capexOpexInput === CapexOpexInput.Yearly ?
									[
										{
											dataIndex: 'momentYear',
											name: 'year',
											title: 'Year',
											sorter: standardSort('year'),
											sortOrder: 'ascend',
											render: (c) => (c == null ? (<em>Empty</em>) : formatDate(c, 'YYYY')),
											editingProps: {
												type: 'date',
												required: true,
												inputProps: {
													allowClear: false,
													picker: 'year',
													format: 'YYYY',
												},
											},
											editable: true,
										},
									] :
									[
										{
											dataIndex: 'momentMonth',
											name: 'month',
											title: 'Month',
											sorter: standardSort('month'),
											sortOrder: 'ascend',
											width: 170,
											render: (c) => (c == null ? (<em>Empty</em>) : formatDate(c, 'MMMM YYYY')),
											editingProps: {
												type: 'date',
												required: true,
												inputProps: {
													allowClear: false,
													picker: 'month',
													format: 'MMMM YYYY',
												},
											},
											editable: true,
										},
									]
								),
								{
									dataIndex: 'capex',
									title: `CAPEX ${currencySymbols[Currencies[userInfo.baseCurrency]]}/day`,
									render: (c) => (
										c == null ? (
											<em>Empty</em>
										) : formatCurrency(c, userInfo.baseCurrency)
									),
									editingProps: {
										type: 'currency',
										required: true,
										inputProps: {
											placeholder: '',
										},
									},
									editable: true,
								},
								{
									dataIndex: 'opex',
									title: `OPEX ${currencySymbols[Currencies[userInfo.baseCurrency]]}/day`,
									render: (c) => (
										c == null ? (
											<em>Empty</em>
										) : formatCurrency(c, userInfo.baseCurrency)
									),
									editingProps: {
										type: 'currency',
										required: true,
										inputProps: {
											placeholder: '',
										},
									},
									editable: true,
								},
							]}
						/>
					</Card>
				</Col>
			)}
		</Row>
	);
};
