import moment from 'moment';
import { toMoment } from '@shared/utils/date';

export const standardSort = (keyOrKeys) => (a, b) => {
	let valueA;
	let valueB;

	if (Array.isArray(keyOrKeys)) {
		valueA = keyOrKeys.reduce((val, k) => val[k], a);
		valueB = keyOrKeys.reduce((val, k) => val[k], b);
	} else {
		valueA = a[keyOrKeys];
		valueB = b[keyOrKeys];
	}

	if (
		// eslint-disable-next-line no-restricted-syntax
		typeof valueA === 'string' && moment(valueA, moment.ISO_8601)
			.isValid() &&
		// eslint-disable-next-line no-restricted-syntax
		typeof valueB === 'string' && moment(valueB, moment.ISO_8601)
			.isValid()
	) {
		return toMoment(valueA)
			.unix() - toMoment(valueB)
			.unix();
	}

	if (moment.isMoment(valueA) && moment.isMoment(valueB)) {
		return valueA.unix() - valueB.unix();
	}

	if (typeof valueA === 'number' && typeof valueB === 'number') {
		return valueA - valueB;
	}

	// If valueA is null, sort it to a lower index than valueB
	if (valueA == null) {
		return -1;
	}

	return valueA.toString()
		.localeCompare(valueB);
};
