import React from 'react';
import classNames from 'classnames';
import styles from './styles/ItemCard.module.css';

const ItemCard = ({ children, className }) => (
	<div className={classNames(styles.container, className)}>
		{children}
	</div>
);

export default ItemCard;
