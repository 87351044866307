import React from 'react';
import {
	RADIAN,
	round,
} from '@shared/utils/math';

const PieChartLabel = ({ cx, cy, midAngle, outerRadius, payload, slim = false }) => {
	// calculates the position of where to place the label
	// cx and cy is the center coordinates of the pie
	const radius = outerRadius + 10;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	if (round(payload.percent, 2) === 0) {
		return null;
	}

	return (
		<text
			style={{ fontSize: slim ? '100%' : '75%', fontWeight: 600 }}
			x={x}
			y={y}
			fill={payload.fill}
			textAnchor={x > cx ? 'start' : 'end'}
			dominantBaseline="central"
		>
			{`${round(payload.percent, 2)}%`}
		</text>
	);
};

export default PieChartLabel;
