import React, { SetStateAction } from 'react';
import { Moment } from 'moment';
import { TimeFormat } from '@shared/utils/date';
import isItemPortCall from '@shared/utils/isItemPortCall';
import type {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@api/features/ops/getVesselItinerary';
import type { Port } from '@api/utils/ports';
import type { GetVoyageItineraryResponse } from '@api/features/voyages/getVoyageItinerary';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import PortCallSummaryTab
	from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/PortCallSummaryTab';
import SeaPassageSummary
	from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/SeaPassageSummary';
import ConditionTab from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/ConditionTab';
import ConsumptionContainer
	from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/ConsumptionContainer';
import { updateSeaPassage } from '@client/lib/api';
import { PortCallBunkersSection } from './PortCallBunkersSection';

type Props = {
	itinerary: GetVoyageItineraryResponse | undefined;
	selectedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto | undefined;
	setSelectedEntry: React.Dispatch<SetStateAction<
		ItinerarySeaPassageDto |
		ItineraryPortCallDto |
		undefined
	>>;
	voyageDetails: GetVoyageDetailsResponse;
	setSelectedIndex: React.Dispatch<SetStateAction<number | undefined | null>>;
	refreshDetails: () => void;
	refreshVessel: () => Promise<void> | void;
	setActiveTab: (tab: string) => void;
	selectedTimeFormat: TimeFormat;
	ports: Port[] | undefined;
	latestActualDate: Moment | null;
	latestEstimatedDate: Moment | null;
	isPreviousActual: boolean;
	vessel: GetVesselDetailsResponse | undefined;
	isConsMissingForSpeed: boolean;
}

const getTabList = ({
	selectedEntry,
}: {
	selectedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto;
	isConsMissingForSpeed: boolean;
}) => [
	{
		tab: 'Summary',
		key: 'summary',
	},
	...(isItemPortCall(selectedEntry) ? [{
		tab: 'Bunkers',
		key: 'bunkers',
	}, {
		tab: 'Condition',
		key: 'condition',
	}] : []),
	...((!isItemPortCall(selectedEntry) && (
		selectedEntry.estimated ||
		(selectedEntry as ItinerarySeaPassageDto).inProgress)
	) ? [{
			tab: (
				<>
					Condition / Consumption
				</>
			),
			key: 'condition',
		}] : []),
];

const getTabContent = ({
	selectedEntry,
	voyageDetails,
	refreshDetails,
	selectedTimeFormat,
	latestActualDate,
	latestEstimatedDate,
	ports,
	isPreviousActual,
	vessel,
	refreshVessel,
	isConsMissingForSpeed,
	setSelectedIndex,
}: Omit<Props, 'selectedEntry'> & {
	selectedEntry: ItinerarySeaPassageDto | ItineraryPortCallDto;
}) => {
	if (isItemPortCall(selectedEntry)) {
		return {
			summary: (
				<PortCallSummaryTab
					key={selectedEntry.id}
					selectedEntry={selectedEntry}
					refreshDetails={refreshDetails}
					ports={ports}
					selectedTimeFormat={selectedTimeFormat}
					allowDelete
					latestActualDate={latestActualDate}
					latestEstimatedDate={latestEstimatedDate}
					isPreviousActual={isPreviousActual}
					setSelectedIndex={setSelectedIndex}
				/>),
			bunkers: (
				<PortCallBunkersSection
					selectedEntry={selectedEntry}
					voyageDetails={voyageDetails}
				/>
			),
			condition: (
				<ConsumptionContainer
					vessel={vessel}
					refreshVessel={refreshVessel}
				/>
			),
		};
	}

	const makePrimary = async (id: number) => {
		await updateSeaPassage(vessel?.id!, selectedEntry.id, { activeConsumptionSetId: id });
		await refreshDetails();
	};

	return {
		summary: (
			<SeaPassageSummary
				selectedEntry={selectedEntry}
				refreshDetails={refreshDetails}
				consumptionSets={vessel?.consumptionSets}
			/>
		),
		condition: (selectedEntry.estimated || selectedEntry.inProgress) && (
			<ConditionTab
				selectedEntry={selectedEntry}
				refreshDetails={refreshDetails}
				vessel={vessel}
				refreshVessel={refreshVessel}
				isConsMissingForSpeed={isConsMissingForSpeed}
				makePrimary={makePrimary}
			/>
		),
	};
};

export const getTabs = ({ selectedEntry, isConsMissingForSpeed, ...rest }: Props) => {
	if (selectedEntry == null) {
		return {
			tabList: [],
			tabContent: [],
		};
	}

	const tabContent = getTabContent({
		selectedEntry,
		isConsMissingForSpeed,
		...rest,
	});

	const tabList = getTabList({
		selectedEntry,
		isConsMissingForSpeed,
	});

	return {
		tabContent,
		tabList,
	};
};
