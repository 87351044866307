import React, { useMemo } from 'react';
import {
	faCalendar,
	faShip,
	faBuilding,
	faThumbsUp,
} from '@fortawesome/pro-light-svg-icons';
import {
	Badge,
	Space,
	Tag,
	Typography,
} from 'antd';
import classNames from 'classnames';
import {
	CheckOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import { Moment } from 'moment';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { toMoment } from '@shared/utils/date';
import {
	DATE,
	AccountingItemApprovalStates,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { capitalize } from '@shared/utils/string';
import type { GetAccountingItemsForApprovalResponse } from '@api/features/invoices/getAccountingItemsForApproval';
import { formatDate } from '@client/utils/formatDate';
import {
	approveExpense,
	rejectExpense,
	getAccountingItemsForApproval,
	approveHireInvoice,
	rejectHireInvoice,
	approveBrokerInvoice,
	rejectBrokerInvoice,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import ListDetailsScreen from '@client/components/screens/ListDetailsScreen';
import ExpenseDetailsCard from '@client/components/ExpenseDetailsCard/ExpenseDetailsCard';
import Button from '@client/components/Button';
import FormPopover from '@client/components/FormPopover';
import asyncWrapper from '@client/utils/asyncWrapper';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import InvoiceDetailsCard from '@client/components/InvoiceDetailsCard/InvoiceDetailsCard';
import styles from './ExpenseApprovalsScreen.module.css';

const ApprovalsScreen = () => {
	const [items, refreshItems] = useFetchedState(async () => {
		const rawItems = await getAccountingItemsForApproval();

		return rawItems.map((item) => ({
			...item,
			key: `${item.id}_${item.itemType}`,
		}));
	});

	const tabs: Array<{
		key: AccountingItemApprovalStates;
		title: React.ReactElement | string;
		filterItems: (e: GetAccountingItemsForApprovalResponse[number]) => boolean;
	}> = useMemo(() => [
		{
			key: AccountingItemApprovalStates.SUBMITTED,
			title: (
				<Badge
					count={items?.filter((i) => i.state === AccountingItemApprovalStates.SUBMITTED).length}
					offset={[12, 0]}
				>
					Waiting for approval
				</Badge>
			),
			filterItems: (e) => e.state === AccountingItemApprovalStates.SUBMITTED,
		},
		{
			key: AccountingItemApprovalStates.APPROVED,
			title: 'Approved',
			filterItems: (e) => e.state === AccountingItemApprovalStates.APPROVED,
		},
	], [items]);

	const onApprove = asyncWrapper(
		async (
			item: GetAccountingItemsForApprovalResponse[number],
			{ note }: { note: string},
		) => {
			let description = '';

			switch (item.itemType) {
				case 'expense':
					await approveExpense(item.id, note);
					description = item.itemDescription;
					break;

				case 'invoice':
					await approveHireInvoice(item.id, note);
					description = item.invoiceTitle;
					break;

				case 'brokerInvoice':
					await approveBrokerInvoice(item.id, note);
					description = item.itemDescription;
					break;

				default:
					break;
			}

			await refreshItems();
			showSuccessNotification(
				'Expense approved',
				`"${description}" was successfully approved.`,
			);
		}, 'Cannot approve item',
	);

	const onReject = async (
		item: GetAccountingItemsForApprovalResponse[number],
		{ reason }: { reason: string },
	) => {
		let description = '';

		switch (item.itemType) {
			case 'expense':
				await rejectExpense(item.id, reason);
				description = item.itemDescription;
				break;

			case 'invoice':
				await rejectHireInvoice(item.id, reason);
				description = item.invoiceTitle;
				break;

			case 'brokerInvoice':
				await rejectBrokerInvoice(item.id, reason);
				description = item.itemDescription;
				break;

			default:
				break;
		}

		await refreshItems();
		showSuccessNotification(
			'Item rejected',
			`"${description}" was successfully rejected.`,
		);
	};

	const getRenderDetails = (
		item: GetAccountingItemsForApprovalResponse[number],
	) => {
		let content;

		switch (item.itemType) {
			case 'expense':
				content = (
					// @ts-ignore - not typed
					<ExpenseDetailsCard
						expense={item}
						refreshExpenses={refreshItems}
						readOnly
					/>
				);
				break;
			case 'invoice':
			case 'brokerInvoice':
				content = (
					<InvoiceDetailsCard
						invoice={item}
					/>
				);
				break;
			default:
				break;
		}

		return (
			<>
				<Typography.Title
					level={5}
				>
					Actions
				</Typography.Title>
				<Space className={styles.buttons}>
					<FormPopover
						title="Optional approval note"
						onSubmit={(values) => onApprove(
							item,
							values?.note?.length === 0 ? { note: null } : values,
						)}
						fields={[
							{
								label: 'Note',
								name: 'note',
								type: 'textarea',
								required: false,
								rows: undefined,
							},
						]}
					>
						<Button
							icon={(
								<CheckOutlined />
							)}
							className={styles.approveButton}
							disabled={item.state !== AccountingItemApprovalStates.SUBMITTED}
							disabledTooltip="Item is already approved"
						>
							Approve
						</Button>
					</FormPopover>
					<FormPopover
						title="Reason for rejecting"
						onSubmit={(values) => onReject(
							item,
							values?.reason?.length === 0 ? { reason: null } : values,
						)}
						fields={[
							{
								label: 'Reason',
								name: 'reason',
								type: 'textarea',
								required: true,
								rows: undefined,
							},
						]}
					>
						<Button
							danger
							icon={(<CloseOutlined />)}
						>
							Reject
						</Button>
					</FormPopover>
				</Space>
				<br />
				<br />
				{content}
			</>
		);
	};

	return (
		<ListDetailsScreen
			title="Approve Accounting Items"
			rootPageTitle="Approvals"
			items={items ?? []}
			idKey="key"
			searchAttributes={[
				'invoiceNumber',
				'itemDescription',
				'charterer',
				'brokerName',
				'invoiceDate',
				'dateReceived',
				'dueDate',
				'invoiceTitle',
				'createdAt',
				'voyageIdentifier',
			]}
			tabs={tabs}
			getItemInfo={(e: GetAccountingItemsForApprovalResponse[number]) => {
				let invoiceId = '';
				let invoiceDate: Moment | null = null;
				let counterparty: string | null | undefined = '';

				switch (e.itemType) {
					case 'expense':
						invoiceId = e.customInvoiceId ?? '';
						invoiceDate = e.invoiceDate != null ? toMoment(e.invoiceDate) : null;
						counterparty = e.supplierName;
						break;
					case 'invoice':
						invoiceId = e.invoiceNumber.toString();
						invoiceDate = e.invoiceDate;
						counterparty = e.charterer;
						break;
					case 'brokerInvoice':
						invoiceId = e.itemDescription;
						invoiceDate = e.dateReceived;
						counterparty = e.brokerName;
						break;
					default:
						break;
				}

				return [
					{
						key: 'customInvoiceId',
						text: (<b>{invoiceId}</b>),
					},
					{
						key: 'amount',
						text: e.amount == null ? '—' : formatCurrency(e.amount, e.currency),
					},
					{
						key: 'identifier',
						icon: faShip,
						text: e.voyageIdentifier,
					},
					{
						key: 'invoiceDate',
						icon: faCalendar,
						text: invoiceDate == null ? '—' : formatDate(invoiceDate, DATE),
					},
					{
						key: 'counterparty',
						icon: faBuilding,
						text: counterparty,
					},
				];
			}}
			getItemTitle={(e: GetAccountingItemsForApprovalResponse[number]) => {
				let type = '';
				let title = '';

				switch (e.itemType) {
					case 'expense':
						type = e.itemType;
						title = e.itemDescription;
						break;
					case 'invoice':
						type = e.invoiceType;
						title = e.invoiceTitle;
						break;
					case 'brokerInvoice':
						type = e.invoiceType;
						title = '';
						break;
					default:
						break;
				}

				return (
					<div className={styles.listItemTitle}>
						<Tag>{capitalize(type)}</Tag>
						<Typography.Title
							level={5}
						>
							{title}
						</Typography.Title>
					</div>
				);
			}}
			getItemClassName={(_e, _index, { active }: {active?: boolean}) => classNames(styles.item, {
				[styles.active]: active,
			})}
			getDetailsCardProps={(e) => {
				return ({
					title: 'itemDescription' in e ? e.itemDescription : e.invoiceTitle,
				});
			}}
			renderDetails={getRenderDetails}
			emptyText="No items to approve"
			emptyIcon={faThumbsUp as IconDefinition}
			noItemSelectedText="Select an item to view details"
		/>
	);
};

export default ApprovalsScreen;
