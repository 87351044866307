import { ReceivableIntervalByPeriod } from './constants';
import { nowMoment } from './date';

export const RangeModes = {
	SHORT: 'short',
	LONG: 'long',
};

export const shortReceivableRanges = [
	[nowMoment(), nowMoment().subtract(15, 'days')],
	[nowMoment().subtract(15, 'days'), nowMoment().subtract(30, 'days')],
	[nowMoment().subtract(30, 'days'), nowMoment().subtract(45, 'days')],
	[nowMoment().subtract(45, 'days'), nowMoment().subtract(60, 'days')],
];

export const longReceivableRanges = [
	[nowMoment(), nowMoment().subtract(30, 'days')],
	[nowMoment().subtract(30, 'days'), nowMoment().subtract(60, 'days')],
	[nowMoment().subtract(60, 'days'), nowMoment().subtract(90, 'days')],
	[nowMoment().subtract(90, 'days'), nowMoment().subtract(120, 'days')],
];

export const getReceivableLabel = (i, period) => {
	const interval = ReceivableIntervalByPeriod[period];

	return `${i * interval} - ${(i * interval) + interval} days`;
};
