// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h7Vo83Bv4KRWYWbMJtjv {\n\tposition: relative;\n\tdisplay: flex;\n\tmargin-bottom: 8px;\n}\n\n.DC4iyCSDR6y0pVeVBsfL {\n\tposition: absolute;\n\tleft: 0;\n\tright: 0;\n\ttop: 0;\n\tbottom: 0;\n\tz-index: 2;\n\tbackground-color: hsl(0, 0%, 80%);\n\tdisplay: none;\n}\n\n.h7Vo83Bv4KRWYWbMJtjv.qracLX9wcBvmx3_3mmKh .DC4iyCSDR6y0pVeVBsfL {\n\tdisplay: block;\n}\n\n.h7Vo83Bv4KRWYWbMJtjv.Q9nXalraotzXF7tqiIlP .DC4iyCSDR6y0pVeVBsfL {\n\tdisplay: block;\n\tbackground-color: hsla(0, 0%, 100%, 0.5);\n}\n\n.EUnySQmK6Uqz5lHIexgg,\n.EUnySQmK6Uqz5lHIexgg:focus {\n\tdisplay: block;\n\tposition: absolute;\n\tright: 0;\n\ttop: 0;\n\tbottom: 0;\n\theight: 100%;\n\tz-index: 2;\n\tfont-size: 120%;\n}\n\n.rQX7t0quDykGr1dsS1AB {\n\twidth: 18px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/PdfGenerator/private/styles/DraggableCard.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,OAAO;CACP,QAAQ;CACR,MAAM;CACN,SAAS;CACT,UAAU;CACV,iCAAiC;CACjC,aAAa;AACd;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;CACd,wCAAwC;AACzC;;AAEA;;CAEC,cAAc;CACd,kBAAkB;CAClB,QAAQ;CACR,MAAM;CACN,SAAS;CACT,YAAY;CACZ,UAAU;CACV,eAAe;AAChB;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".container {\n\tposition: relative;\n\tdisplay: flex;\n\tmargin-bottom: 8px;\n}\n\n.dragBackground {\n\tposition: absolute;\n\tleft: 0;\n\tright: 0;\n\ttop: 0;\n\tbottom: 0;\n\tz-index: 2;\n\tbackground-color: hsl(0, 0%, 80%);\n\tdisplay: none;\n}\n\n.container.dragging .dragBackground {\n\tdisplay: block;\n}\n\n.container.added .dragBackground {\n\tdisplay: block;\n\tbackground-color: hsla(0, 0%, 100%, 0.5);\n}\n\n.removeButton,\n.removeButton:focus {\n\tdisplay: block;\n\tposition: absolute;\n\tright: 0;\n\ttop: 0;\n\tbottom: 0;\n\theight: 100%;\n\tz-index: 2;\n\tfont-size: 120%;\n}\n\n.undo {\n\twidth: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "h7Vo83Bv4KRWYWbMJtjv",
	"dragBackground": "DC4iyCSDR6y0pVeVBsfL",
	"dragging": "qracLX9wcBvmx3_3mmKh",
	"added": "Q9nXalraotzXF7tqiIlP",
	"removeButton": "EUnySQmK6Uqz5lHIexgg",
	"undo": "rQX7t0quDykGr1dsS1AB"
};
export default ___CSS_LOADER_EXPORT___;
