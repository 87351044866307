import React, { useMemo } from 'react';
import {
	Alert,
	Checkbox,
} from 'antd';
import { DepartmentKey } from '@shared/utils/constants';
import EditableCellTableRedux, { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getUsers,
	updateUserDepartments,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import { useAuth } from '@client/lib/auth';
import styles from './UserManagement.module.css';

export type UserSectionColumns = {
	id: number;
	username: string;
	chartering: boolean;
	operations: boolean;
	finance: boolean;
}

const UserManagement = () => {
	const { refreshAuthInfo } = useAuth();
	const [userData, refreshData] = useFetchedState(async () => {
		const users = await getUsers();

		return users
			.map((user) => ({
				id: user.id,
				username: user.name ?? user.email ?? '',
				chartering: user.departments.includes('chartering'),
				operations: user.departments.includes('operations'),
				finance: user.departments.includes('finance'),
			}))
			.sort((a, b) => b.id - a.id);
	});

	const columns: EditableColumns<UserSectionColumns> = useMemo(() => [
		{
			title: 'Team Member',
			dataIndex: 'username',
			editable: false,
			type: 'text',
		},
		{
			title: 'Operations',
			dataIndex: 'operations',
			editable: true,
			width: 50,
			type: 'checkbox',
			align: 'center',
			renderInput: ({ value, onChange }) => (
				<div style={{ textAlign: 'center' }}>
					<Checkbox
						onChange={(e) => onChange(e.target.checked)}
						checked={value}
					/>
				</div>
			),
		},
		{
			title: 'Chartering',
			dataIndex: 'chartering',
			editable: true,
			width: 50,
			type: 'checkbox',
			renderInput: ({ value, onChange }) => (
				<div style={{ textAlign: 'center' }}>
					<Checkbox
						onChange={(e) => onChange(e.target.checked)}
						checked={value}
					/>
				</div>
			),
		},
		{
			title: 'Finance',
			dataIndex: 'finance',
			editable: true,
			width: 50,
			type: 'checkbox',
			renderInput: ({ value, onChange }) => (
				<div style={{ textAlign: 'center' }}>
					<Checkbox
						onChange={(e) => onChange(e.target.checked)}
						checked={value}
					/>
				</div>
			),
		},
	], []);

	const handleChange = async (values: UserSectionColumns) => {
		const departments = Object.entries(values)
			.filter(([key, value]) => (key !== 'id' && key !== 'username') && value)
			.flat()
			.filter((d) => typeof d === 'string') as DepartmentKey[];

		if (departments.length < 1) {
			showErrorNotification('Team Members need to be part of at least one department');

			return;
		}

		try {
			await updateUserDepartments({ userId: values.id, departments });
			await refreshData();
			await refreshAuthInfo();
		} catch (e) {
			showErrorNotification('Could not update permissions');
		}
	};

	return (
		<div className={styles.tableContainer}>
			<Alert
				className={styles.alert}
				message="Team Members will only be able to switch between the departments defined below"
			/>
			<EditableCellTableRedux<UserSectionColumns>
				columns={columns}
				dataSource={userData ?? []}
				pagination={false}
				onRowChange={handleChange}
				onChange={() => null}
			/>
		</div>
	);
};

export default UserManagement;
