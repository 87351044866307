import {
	HIIFieldTypes,
	AccountTypes,
	PnlGroups,
	InvoiceTemplates,
} from '@shared/utils/constants';
import { formatCurrency } from '../utils/currency';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';

class HIICustomItem extends HireInvoiceItem {
	static get itemType() {
		return 'custom';
	}

	constructor(id, isOriginal, params = {}) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.amount = params.amount;
		this.description = params.description;
		this.account = params.account;

		this._showTotal = false;
		this.itemTypeLabel = 'Custom';
		this.addManually = false;
		if (this.account === AccountTypes.OWNER) {
			this.templateSection = TemplateItem.Sections.EXPENSES_OWNER;
			this.pnlGroup = PnlGroups.OWNER_EXPENSES;
		} else {
			this.templateSection = TemplateItem.Sections.EXPENSES_CHARTERER;
			this.pnlGroup = PnlGroups.CHARTERER_EXPENSES;
		}

		this._fields = {
			account: {
				label: 'Account',
				type: HIIFieldTypes.SELECT,
				options: [
					{ label: 'Owner', value: AccountTypes.OWNER },
					{ label: 'Charterer', value: AccountTypes.CHARTERER },
				],
			},
			amount: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Amount',
			},
		};
	}

	_calculateTotal(_invoiceItems) {
		if (this.account === AccountTypes.OWNER) {
			return -1 * this.amount;
		}

		return this.amount;
	}

	getDescription() {
		return this.description;
	}

	static _getClassParams(model, _parentModel) {
		return {
			amount: model.amount,
			description: model.description,
			account: model.account,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.amount = this.amount;
		model.description = this.description;
		model.account = this.account;

		await model.save();
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.description,
					formatCurrency(this.amount, this.fixtureCurrency),
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					this.description,
					formatCurrency(this.amount, this.fixtureCurrency),
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIICustomItem);

export default HIICustomItem;

