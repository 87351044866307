import React from 'react';
import type { GetFleetAnalyticsResponse } from '@api/features/statistics/getFleetAnalytics';
import { ChartColors } from '@client/utils/constants';
import PieChartWidget from './PieChartWidget';

type Props = {
	staticData: GetFleetAnalyticsResponse['data'] | undefined;
	loading: boolean;
}

const ForwardCoverageWidget = ({
	staticData,
	loading,
}: Props) => {
	const entries = [
		{
			label: 'Fixed days',
			key: 'fixedDays',
			fill: ChartColors.BLUE,
		},
		{
			label: 'Unfixed days',
			key: 'unfixedDays',
			fill: ChartColors.CYAN,
		},
		{
			label: 'Redelivery days',
			key: 'redeliveryPeriodDays',
			fill: ChartColors.LIGHTBLUE,
		},
	];

	return (
		<PieChartWidget
			entries={entries}
			// @ts-ignore i know, sue me
			staticData={staticData}
			externalLoading={loading}
			transformData={(data) => data.forwardCoverage}
			header="Forward Coverage"
			unit="days"
			roundTotal
			forward
		/>
	);
};

export default ForwardCoverageWidget;
