import React, {
	useState,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import {
	Row,
	Col,
	Form,
	Drawer,
	Radio,
	Input,
	Typography,
} from 'antd';
import moment, { Moment } from 'moment/moment';
import sortBy from 'lodash.sortby';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import Button from '@client/components/Button';
import {
	createOffHirePeriod,
	updateOffHirePeriod,
} from '@client/lib/api';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import CurrencyInput from '@client/components/CurrencyInput';
import DatePicker from '@client/components/DatePicker';
import TooltipIcon from '@client/components/TooltipIcon';
import showErrorNotification from '@client/utils/showErrorNotification';
import { rules } from '@client/utils/form';
import { MultiCurrencyValuesType } from '@client/components/MultiCurrencyAmount';
import PercentageInput from '@client/components/PercentageInput';
import Select from '@client/components/Select';
import { useAuth } from '@client/lib/auth';
import styles from './styles/OffHireForm.module.css';
import { OffHire } from './OffHireTable';
import OffHireBunkersTable from './OffHireBunkersTable';

type OffHireFormProps = {
	initialValues?: (Partial<{
		id: number;
		preferDuration: boolean;
		startTime: Moment;
		endTime: Moment;
		bunkers: Array<OffHireBunker> | [];
		hirePerDay: number | null;
		percentageForOwnersAccount: number;
		type: string | null;
	}> | OffHire) | null;
	voyageId: number;
	visible: boolean;
	hireDescription?: string;
	onClose: () => void;
	onSaved: () => void;
	currency: Values<typeof Currencies>;
};

export type OffHireBunkerValues = {
	id: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon: MultiCurrencyValuesType;
};

type OffHireBunker = {
	id: number;
	quantity: number;
	pricePerTon: number;
	fuelGrade: Values<typeof FuelTypes>;
	exchangeRate: number;
	currency: Values<typeof Currencies>;
}

const dateProps = {
	time: true,
	fullWidth: true,
};

const OffHireForm = ({
	initialValues = null,
	voyageId,
	visible,
	hireDescription,
	onClose: forceOnClose,
	onSaved,
	currency,
}: OffHireFormProps) => {
	const [form] = Form.useForm();
	const [dirty, setDirty] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [bunkers, setBunkers] = useState<OffHireBunker[] | []>([]);
	const [editingBunkers, setEditingBunkers] = useState<number | null>(null);
	const [durationType, setDurationType] = useState(initialValues?.preferDuration ? 'duration' : 'date');
	const { userInfo } = useAuth();

	const offHireTypes = useMemo(() => (userInfo as UserData)?.orgOffHireTypes, [userInfo]);

	const getDurationFromDates = useCallback((startTime: Moment, endTime: Moment) => {
		if (startTime == null || endTime == null) {
			return {};
		}

		const duration = moment.duration(toMoment(endTime).diff(startTime));

		return {
			'durationDays': duration.days(),
			'durationHours': duration.hours(),
			'durationMinutes': duration.minutes(),
		};
	}, []);

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	const onClose = makeBlockable(forceOnClose);

	useBlocker(dirty || editingBunkers != null);

	useEffect(() => {
		if (!visible) {
			setDirty(false);
			form.resetFields();
			setBunkers([]);
			setEditingBunkers(null);
			setDurationType('date');
		} else if (initialValues != null) {
			let formValues = initialValues;

			if (initialValues?.startTime != null && initialValues?.endTime != null) {
				const durations = getDurationFromDates(
					toMoment(initialValues.startTime),
					toMoment(initialValues.endTime),
				);

				formValues = { ...formValues, ...durations };
			}

			form.setFieldsValue(formValues);
			setBunkers(initialValues.bunkers || []);
			setDurationType(initialValues.preferDuration ? 'duration' : 'date');
		}
	}, [form, visible, initialValues, getDurationFromDates]);

	useEffect(() => {
		if (editingBunkers == null) {
			return;
		}

		setDirty(true);
	}, [editingBunkers]);

	const saveOffHire = async ({
		startTime,
		endTime,
		hirePerDay,
		percentageForOwnersAccount,
		note,
		invoiceNote,
		type,
	}: {
		startTime: Moment;
		endTime: Moment;
		hirePerDay: number;
		percentageForOwnersAccount: number;
		note: string;
		invoiceNote: string;
		type: string;
	}) => {
		const preferDuration = durationType === 'duration';

		try {
			setSaveLoading(true);
			if (initialValues != null && initialValues.id != null) {
				await updateOffHirePeriod(
					voyageId,
					initialValues.id,
					{
						startTime,
						endTime,
						preferDuration,
						bunkers,
						hirePerDay,
						note,
						invoiceNote,
						percentageForOwnersAccount,
						type: type ?? null,
					},
				);
			} else {
				await createOffHirePeriod(
					voyageId,
					startTime,
					endTime,
					preferDuration,
					bunkers,
					hirePerDay,
					percentageForOwnersAccount,
					note,
					invoiceNote,
					type,
				);
			}

			forceOnClose();
			onSaved();
		} catch (e) {
			showErrorNotification('Could not save off-hire', e as Error);
		} finally {
			setSaveLoading(false);
		}
	};

	const setEndTimeFromDurations = useCallback((startTime: Moment) => {
		const days = form.getFieldValue('durationDays');
		const hours = form.getFieldValue('durationHours');
		const minutes = form.getFieldValue('durationMinutes');
		const endTime = toMoment(startTime)
			.add(days, 'days')
			.add(hours, 'hours')
			.add(minutes, 'minutes');
		form.setFieldsValue({
			'endTime': endTime,
		});
	}, [form]);

	const handleDateChange = useCallback(() => {
		const startTime = form.getFieldValue('startTime');

		if (startTime == null) {
			return;
		}

		if (durationType === 'date') {
			const endTime = form.getFieldValue('endTime');

			if (endTime == null) {
				return;
			}

			const durations = getDurationFromDates(startTime, endTime);
			form.setFieldsValue(durations);
		} else {
			setEndTimeFromDurations(startTime);
		}
	}, [durationType, form, getDurationFromDates, setEndTimeFromDurations]);

	useEffect(() => {
		handleDateChange();
	}, [durationType, handleDateChange]);

	return (
		(
			<Drawer
				title="Add off-hire"
				placement="left"
				onClose={onClose}
				open={visible}
				width={520}
				className={styles.drawer}
			>
				<Form
					layout="vertical"
					form={form}
					onFinish={saveOffHire}
					onValuesChange={() => {
						handleDateChange();
						setDirty(true);
					}}
				>
					<Row
						gutter={16}
						className={styles.rowsWithLessMargin}
					>
						<Radio.Group
							onChange={({ target: { value } }) => setDurationType(value)}
							value={durationType}
						>
							<Col
								span={15}
								className={styles.dateRow}
							>
								<Form.Item
									name="startTime"
									label="From"
									rules={[rules.required]}
								>
									<DatePicker
										{...dateProps}
									/>
								</Form.Item>
							</Col>
							<Col
								span={24}
								className={styles.dateRow}
							>
								<Row
									align="middle"
									gutter={10}
								>
									<Col span={15}>
										<Form.Item
											name="endTime"
											label="To"
											rules={durationType === 'date' ? [rules.required] : []}
										>
											<DatePicker
												disabled={durationType !== 'date'}
												{...dateProps}
											/>
										</Form.Item>
									</Col>
									<Col
										span={1}
										className={styles.radioBtnCol}
									>
										<Radio
											value="date"
											className={styles.radioBtn}
										/>
									</Col>
									<Col className={styles.radioLabelCol} span={5}>
										<Typography.Text className={styles.radioLabel}>
											Use date
										</Typography.Text>
									</Col>
								</Row>
							</Col>
							<Col
								span={24}
							>
								<Row
									align="middle"
									gutter={10}
									className={styles.durationRow}
								>
									<Col span={5}>
										<Form.Item
											label="Days"
											initialValue={0}
											name="durationDays"
											rules={durationType === 'duration' ? [rules.requiredShort] : []}
											className={styles.durationEntryForm}
										>
											<Input
												disabled={durationType !== 'duration'}
											/>
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											label="Hours"
											initialValue={0}
											name="durationHours"
											rules={durationType === 'duration' ? [rules.requiredShort] : []}
											className={styles.durationEntryForm}
										>
											<Input
												disabled={durationType !== 'duration'}
											/>
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											label="Minutes"
											initialValue={0}
											name="durationMinutes"
											rules={durationType === 'duration' ? [rules.requiredShort] : []}
											className={styles.durationEntryForm}
										>
											<Input
												disabled={durationType !== 'duration'}
											/>
										</Form.Item>
									</Col>
									<Col
										span={1}
										className={styles.radioBtnCol}
									>
										<Radio
											value="duration"
											className={styles.radioBtn}
										/>
									</Col>
									<Col className={styles.radioLabelCol} span={5}>
										<Typography.Text className={styles.radioLabel}>
											Use duration
										</Typography.Text>
									</Col>
								</Row>
							</Col>
						</Radio.Group>
						<Col span={12}>
							<Form.Item
								name="hirePerDay"
								label={(
									<>
										Hire per day
										<TooltipIcon>
											The hire amount per day the
											off-hire period should be based on.
										</TooltipIcon>
									</>
								)}
								rules={[rules.required]}
							>
								<CurrencyInput
									placeholder={hireDescription}
									currency={currency}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="percentageForOwnersAccount"
								label="Proportion for owner's account"
								rules={[rules.required]}
							>
								<PercentageInput min={0} max={100} />
							</Form.Item>
						</Col>
					</Row>
					{
						((offHireTypes != null &&
						offHireTypes.length !== 0) ||
						initialValues?.type != null) && (
							<Row>
								<Col span={12}>
									<Form.Item
										name="type"
										label="Off-hire type"
									>
										<Select
											options={(sortBy(offHireTypes) ?? []).map((ot) => ({
												label: ot,
												value: ot,
											}))}
											allowClear
											placeholder="Select an off-hire type"
										/>
									</Form.Item>
								</Col>
							</Row>
						)
					}
					<Row gutter={16}>
						<Col span={24}>
							<OffHireBunkersTable
								key={`table-${bunkers.length}`}
								bunkers={bunkers}
								setBunkers={setBunkers}
								editingBunkers={editingBunkers}
								setEditingBunkers={setEditingBunkers}
								currency={currency}
								setDirty={setDirty}
							/>
						</Col>
					</Row>
					<Row className={styles.noteInputRow} gutter={16}>
						<Col
							span={24}
							className={styles.rowsWithLessMargin}
						>
							<Form.Item
								name="note"
								label="Internal note"
							>
								<Input.TextArea />
							</Form.Item>
						</Col>
					</Row>
					<Row className={styles.noteInputRow} gutter={16}>
						<Col span={24}>
							<Form.Item
								name="invoiceNote"
								label="Invoice note"
							>
								<Input.TextArea />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="end" gutter={[0, 16]}>
						<Col
							className={styles.rowsWithLessMargin}
						>
							<Form.Item>
								{editingBunkers != null && (
									<span className={styles.bunkerSaveWarning}>
										Please confirm bunkers consumed before saving
									</span>
								)}
								<Button
									type="primary"
									htmlType="submit"
									disabled={editingBunkers != null}
									loading={saveLoading}
								>
									Save
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Drawer>
		)
	);
};

export default OffHireForm;
