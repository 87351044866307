import React from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { stringToValidNumericString } from '@shared/utils/string';
import ListInput, { ListInputProps } from './ListInput';

const notFound = (
	<div style={{ textAlign: 'center' }}>
		<SmileOutlined />
		<p>Input a number and finish by pressing enter</p>
	</div>
);

export type NumberListInputProps = ListInputProps & {
	integersOnly?: boolean;
	allowNegative?: boolean;
	sortValues?: boolean;
	sortDescending?: boolean;
};

const NumberListInput: React.FC<NumberListInputProps> = ({
	integersOnly = true,
	allowNegative = false,
	sortValues = true,
	sortDescending = true,
	...props
}) => {
	const formatValues = (values: string[]) => {
		let newValues = values.map((v) => Number(v));

		if (sortValues) {
			newValues = newValues.sort((a, b) => a - b);

			if (sortDescending) {
				newValues = newValues.reverse();
			}
		}

		return newValues.map((n) => n.toString());
	};

	const formatSearchString = (searchValue: string) => stringToValidNumericString(
		searchValue,
		{
			integersOnly,
			allowNegative,
		},
	);

	return (
		<ListInput
			{...props}
			filterSort={(a, b) => {
				if (
					a.value != null &&
					b.value != null &&
					typeof a.value === 'number' &&
					typeof b.value === 'number'
				) {
					return a.value - b.value;
				}

				return -1;
			}}
			notFoundContent={notFound}
			formatValues={formatValues}
			formatSearchString={formatSearchString}
		/>
	);
};

export default NumberListInput;
