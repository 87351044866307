import React, {
	useEffect,
	useState,
} from 'react';
import {
	Input,
	Space,
	Spin,
} from 'antd';
import Select from '@client/components/Select';
import Button from '@client/components/Button';
import showErrorNotification from '@client/utils/showErrorNotification';
import { useAuth } from '@client/lib/auth';
import ErrorMessage from '@client/components/ErrorMessage';
import {
	getOrganizationUserDetails,
	updateUser,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import getAvailableDepartments from '@client/utils/getAvailableDepartments';
import styles from './styles/FirstTimeSetup.module.css';
import Label from './Label';

const FirstTimeSetup = () => {
	const {
		userInfo: {
			id: userId,
			email: currentEmail,
		},
		isAdmin,
	} = useAuth();

	const [selectedActiveDepartment, setSelectedActiveDepartment] = useState([]);
	const [email, setEmail] = useState(currentEmail || '');
	const [saveLoading, setSaveLoading] = useState(false);

	const showEmailField = currentEmail == null;

	const [
		userInfo,
		_refreshUser,
		userError,
		userLoading,
	] = useFetchedState(
		() => getOrganizationUserDetails(userId),
		[],
		{
			autoRefresh: false,
		},
	);

	useEffect(() => setSelectedActiveDepartment(userInfo?.activeDepartment), [userInfo]);

	const handleSave = async () => {
		try {
			setSaveLoading(true);
			await updateUser({
				attributes: {
					activeDepartment: selectedActiveDepartment,
					email: showEmailField ? email : undefined,
				},
			});
			window.location.reload();
		} catch (error) {
			showErrorNotification('Could not finish setup', error);
		} finally {
			setSaveLoading(false);
		}
	};

	if (userLoading) {
		return (
			<Spin
				size="large"
				className={styles.loading}
			/>
		);
	}

	if (userError != null) {
		return (
			<div className={styles.error}>
				<ErrorMessage>{userError.message}</ErrorMessage>
			</div>
		);
	}

	return (
		<div className={styles.content}>
			<h1 className={styles.header}>Welcome to ClearVoyage</h1>
			<p className={styles.text}>
				We just need a few more details.
				You can always change this later.
			</p>
			<Space direction="vertical" align="center" size="large">
				<Label text="Department">
					<Select
						type="select"
						placeholder="Select department..."
						value={selectedActiveDepartment}
						onSelect={setSelectedActiveDepartment}
						className={styles.input}
						showArrow
						options={(
							Object.values(getAvailableDepartments(
								userInfo?.isManagement ?? false,
								isAdmin ?? false,
							)).map((department) => ({
								label: department.name,
								value: department.key,
							}))
						)}
					/>
				</Label>
				{showEmailField && (
					<Label text="Work Email">
						<Input
							placeholder="Enter your work email"
							className={styles.input}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</Label>
				)}
				<Button
					type="primary"
					onClick={handleSave}
					loading={saveLoading}
					className={styles.input}
					disabled={(
						selectedActiveDepartment == null ||
						(showEmailField && !email.includes('@'))
					)}
				>
					Finish
				</Button>
			</Space>
		</div>
	);
};

export default FirstTimeSetup;
