const getPointOfContactItems = ({
	pointOfContactName,
	pointOfContactEmail,
	pointOfContactPhoneNumber,
}: {
	pointOfContactName: string;
	pointOfContactEmail: string;
	pointOfContactPhoneNumber: string;
}) => [
	{
		key: 'pointOfContactName',
		label: 'Name',
		value: pointOfContactName,
		editable: true,
	},
	{
		key: 'pointOfContactEmail',
		label: 'Email',
		value: pointOfContactEmail,
		editable: true,
	},
	{
		key: 'pointOfContactPhoneNumber',
		label: 'Phone number',
		value: pointOfContactPhoneNumber,
		editable: true,
	},
];

export default getPointOfContactItems;
