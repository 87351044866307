import React, {
	useEffect,
	useState,
} from 'react';
import {
	Cell,
	Pie,
	PieChart,
	ResponsiveContainer,
} from 'recharts';
import { Grid } from 'antd';
import { round } from '@shared/utils/math';

const PrettyPieChart = ({
	data,
	label,
	unit,
	textProps,
	total,
	height,
	defaultPieKey = undefined,
	small = false,
	roundTotal = false,
}) => {
	const [activePie, setActivePie] = useState(null);

	useEffect(() => {
		if (defaultPieKey != null) {
			const entry = data.find((e) => e.name === defaultPieKey);
			setActivePie({ payload: entry });
		}
	}, [defaultPieKey, data]);

	const screens = Grid.useBreakpoint();

	const innerRadius = screens.xl && !small ? 70 : 50;
	const outerRadius = screens.xl && !small ? 90 : 65;
	const font = screens.xl && !small ? 19 : 13;

	const formattedTotal = roundTotal ? round(total, 0) : total;

	return (
		<ResponsiveContainer
			width="100%"
			height={height}
		>
			<PieChart>
				<Pie
					data={data}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					cx="50%"
					label={label}
					cy="50%"
					dataKey="value"
					isAnimationActive={false}
					labelLine={false}
					onMouseEnter={(pie) => setActivePie(pie)}
					onMouseLeave={() => setActivePie(null)}
				>
					{data.map((entry) => (
						<Cell
							key={entry.name}
							fill={entry.fill}
							percent={(entry.value / total) * 100}
						/>
					))}
				</Pie>
				<text
					style={{ fontSize: font }}
					x="50%"
					y="53%"
					textAnchor="middle"
					fill={activePie == null ? 'black' : activePie.payload.fill}
					{...textProps}
				>
					{`${activePie == null ? formattedTotal : activePie.payload.value} ${screens.xl ? '' : unit}`}
				</text>
				<text
					style={{ fontSize: 11 }}
					x="50%"
					y="60%"
					textAnchor="middle"
					fill={activePie == null ? 'black' : activePie.payload.fill}
					{...textProps}
				>
					{screens.xl ? (`${activePie == null ? `Total ${unit}` : activePie.payload.label}`) : ''}
				</text>
			</PieChart>
		</ResponsiveContainer>
	);
};

export default PrettyPieChart;
