import React from 'react';
import {
	Empty,
	Space,
} from 'antd';
import { Link } from 'react-router-dom';
import { Links } from '@client/utils/links';

const EmptySOA = ({ id }: { id: number }) => {
	return (
		<Empty
			description={(
				<Space direction="vertical">
					<p>
						The SOA is an overview of all invoiced items. Start invoicing to use this screen
					</p>
					<Link to={`${Links.Voyage.get(id)}/#/invoices`}>
						Go to invoicing
					</Link>
				</Space>
			)}
		/>
	);
};

export default EmptySOA;
