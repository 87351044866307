import React, {
	useState,
	useEffect,
	useMemo,
} from 'react';
import {
	Typography,
	Grid,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEngineWarning } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { ColumnsType } from 'antd/es/table';
import { ColumnGroupType } from 'antd/lib/table';
import { formatCurrency } from '@shared/utils/currency';
import { sortByDates } from '@shared/utils/sortByDates';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type getVoyageOffHirePeriods from '@api/utils/sequelize/getVoyageOffHirePeriods';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import { unAllocateVesselOffHirePeriod } from '@client/lib/api';
import AddButton from '@client/components/AddButton';
import EditableTable, { EditableColumn } from '@client/components/EditableTable';
import Table from '@client/components/Table/Table';
import { useAuth } from '@client/lib/auth';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getOffHireColumns,
	getOffHireBunkerColumns,
} from '../helpers/tableColumns';
import getEditableTableProps from '../helpers/getEditableTableProps';
import OffHireForm, { OffHireBunkerValues } from './OffHireForm';
import styles from './styles/OffHireTable.module.css';

// eslint-disable-next-line no-undef
export type OffHire = Awaited<ReturnType<typeof getVoyageOffHirePeriods>>[0];

const OffHireTable = ({
	id,
	details,
	fixtureDetails,
	refreshDetails,
}: {
	id: number;
	details: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshDetails: () => void;
}) => {
	const [open, setOpen] = useState(false);
	const [offHire, setOffHire] = useState<OffHire[] | []>([]);
	const [editingOffHire, setEditingOffHire] = useState<OffHire | null>(null);
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const [orgHasOffhireTypes] = useFetchedState(async () => {
		const offHireTypes = (userInfo as UserData)?.orgOffHireTypes;

		return (offHireTypes != null && offHireTypes.length !== 0);
	});

	const defaultInitialValues = useMemo(
		() => ({
			hirePerDay: fixtureDetails.hireRate,
			percentageForOwnersAccount: 100,
		}),
		[fixtureDetails.hireRate],
	);

	useEffect(() => {
		const { voyageOffHirePeriods } = details;

		if (voyageOffHirePeriods != null) {
			setOffHire(voyageOffHirePeriods);
		}
	}, [details]);

	const updateEditingOffHire = (offHireId: number | null) => {
		const editOffHire = offHire.find((o) => o.id === offHireId);

		if (editOffHire != null) {
			setEditingOffHire(editOffHire);
		}
	};

	const unAllocate = async (offHireId: number) => {
		await unAllocateVesselOffHirePeriod(offHireId, details.vesselId, id);
		await refreshDetails();
	};

	const fixtureCurrency =
		details.bankAccount != null ?
			details.bankAccount.currency :
			userInfo.baseCurrency;

	return (
		<>
			<Card
				slim
				title={(
					<Space>
						<FontAwesomeIcon color="orange" icon={faEngineWarning as Icon} />
						Off-hire
					</Space>
				)}
				extra={(
					<AddButton
						disabled={!fixtureDetails.fixed || editingOffHire != null}
						onClick={() => setOpen(true)}
					>
						New Off-hire
					</AddButton>
				)}
			>
				<EditableTable<OffHire, 'id'>
					deleteMessage="Are you sure you want to un-allocate this off-hire from the vessel?"
					useCards={screens.xs}
					// Not completely sure why we use editable table here
					// (perhaps to get expandedrows?),
					// Our edit in this case, is just opening a form. Hence empty onSave
					onSave={() => {}}
					expandable={{
						expandedRowRender: (record) => (
							<div>
								<Typography.Title level={4}>Hire per day</Typography.Title>
								<Typography.Text>
									{`${formatCurrency(
										record.hirePerDay,
										fixtureCurrency,
									)} per day`}
								</Typography.Text>
								<Typography.Title level={4}>
									Proportion for Owner&apos;s Account
								</Typography.Title>
								<Typography.Text>
									{`${record.percentageForOwnersAccount}%`}
								</Typography.Text>
								<Typography.Title level={4}>
									Bunkers consumed during off-hire
								</Typography.Title>
								{record.bunkers && record.bunkers.length > 0 ? (
									<Table<OffHireBunkerValues>
										columns={
											getOffHireBunkerColumns(
												fixtureCurrency,
											) as ColumnsType<OffHireBunkerValues>
										}
										pagination={false}
										className={styles.offHireBunkerTable}
										rowClassName={styles.offHireBunkerColumn}
										dataSource={
											record.bunkers.length > 0 ?
												record.bunkers.map((bunker) => ({
													...bunker,
													pricePerTon: {
														value: bunker.pricePerTon,
														currency: bunker.currency,
														exchangeRate: bunker.exchangeRate,
													},
												})) :
												[]
										}
										rowKey="id"
									/>
								) : (
									<Typography.Text>No bunkers consumed</Typography.Text>
								)}
								{record.note && (
									<>
										<Typography.Title level={4}>Internal note</Typography.Title>
										<Typography.Text>{record.note}</Typography.Text>
									</>
								)}
								{record.invoiceNote && (
									<>
										<Typography.Title level={4}>Invoice note</Typography.Title>
										<Typography.Text>{record.invoiceNote}</Typography.Text>
									</>
								)}
							</div>
						),
					}}
					keyDataIndex="id"
					columns={
						getOffHireColumns(fixtureDetails.useUTC, orgHasOffhireTypes || false,
							fixtureCurrency) as (
							| ColumnGroupType<OffHire>
							| EditableColumn<OffHire, 'id'>
						)[]
					}
					dataSource={sortByDates(offHire, 'startTime')}
					editingRow={null}
					onEditingRowChange={updateEditingOffHire}
					onDelete={unAllocate}
					{...getEditableTableProps()}
				/>
			</Card>
			<OffHireForm
				voyageId={id}
				visible={open || editingOffHire != null}
				initialValues={editingOffHire || defaultInitialValues}
				currency={fixtureCurrency}
				onClose={() => {
					setOpen(false);
					setEditingOffHire(null);
				}}
				onSaved={refreshDetails}
			/>
		</>
	);
};

export default OffHireTable;
