import React, {
	useMemo,
	useState,
} from 'react';
import {
	Alert,
	Flex,
	Space,
	Statistic,
	Switch,
	Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { FilterValue } from 'antd/es/table/interface';
import { useQuery } from 'react-query';
import { formatCurrency } from '@shared/utils/currency';
import {
	Currencies,
	DashboardTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { standardSort } from '@shared/utils/standardSort';
import { toMoment } from '@shared/utils/date';
import { fixtureTypeToPascalCase } from '@shared/utils/string';
import {
	calculateTotal,
	round,
} from '@shared/utils/math';
import type { OutStandingData } from '@api/features/statistics/getOutstandingsData';
import { getOutstandingsData } from '@client/lib/api';
import Table from '@client/components/Table/Table';
import { Links } from '@client/utils/links';
import { formatDate } from '@client/utils/formatDate';
import { stringSetToSortedList } from '@client/utils/stringSetToSortedList';
import Card from '@client/components/Card/Card';
import { useAuth } from '@client/lib/auth';
import { DashboardComponent } from '../helpers/dashboardInfo';
import styles from './OutstandingsDashboard.module.css';

const getColumns = (
	filterMaps: {
		contractTypes: Array<string>;
		vessels: Array<string>;
		contracts: Array<string>;
		counterparties: Array<string>;
	},
	hideCurrencies: boolean = false,
) => {
	const columns: ColumnsType<OutStandingData> = [
		{
			dataIndex: 'vessel',
			title: 'Vessel',
			render: (vessel) => (
				<Typography.Text className={styles.totalCell}>
					{vessel.name}
				</Typography.Text>
			),
			filters: filterMaps.vessels.map((vessel) => ({
				text: vessel,
				value: vessel,
			})),
			filterMultiple: true,
			key: 'vessel',
			onFilter: (value, record) => record.vessel?.name === value,
		},
		{
			title: 'Contract',
			dataIndex: 'contractIdentifier',
			render: (identifier, row) => (
				<Space size="small">
					<Link to={Links.Voyage.get(row.contractId)}>{identifier}</Link>
				</Space>
			),
			filters: filterMaps.contracts.map((contract) => ({
				text: contract,
				value: contract,
			})),
			filterMultiple: true,
			key: 'contract',
			onFilter: (value, record) => record.contractIdentifier === value,
		},
		{
			title: 'Contract type',
			dataIndex: 'contractType',
			render: (identifier) => (
				<Space size="small">
					{fixtureTypeToPascalCase(identifier)}
				</Space>
			),
			filters: filterMaps.contractTypes.map((contractType) => ({
				// @ts-ignore
				text: fixtureTypeToPascalCase(contractType).toString(),
				value: contractType,
			})),
			filterMultiple: true,
			key: 'contractType',
			onFilter: (value, record) => record.contractType === value,
			width: 50,
		},
		{
			title: 'Counterparty',
			dataIndex: 'counterparty',
			render: (cp) => cp?.name ?? '',
			filters: filterMaps.counterparties.map((cp) => ({
				text: cp,
				value: cp,
			})),
			filterMultiple: true,
			key: 'counterparty',
			onFilter: (
				value: any,
				record: OutStandingData,
			) => record?.counterparty.name === value,
		},
		{
			title: 'Description',
			dataIndex: 'description',
			render: (description) => description ?? (<em>-</em>),
		},
		{
			title: 'Invoice #',
			dataIndex: 'invoiceNumber',
			render: (invoiceNumber) => invoiceNumber ?? (<em>-</em>),
			sorter: standardSort('invoiceNumber'),
		},
		{
			title: 'Invoice date',
			dataIndex: 'invoiceDate',
			render: (date) => (date != null ? formatDate(date) : (<em>-</em>)),
			sorter: (a, b) => {
				if (a.invoiceDate == null) {
					return 1;
				}

				if (b.invoiceDate == null) {
					return -1;
				}

				return toMoment(a.invoiceDate).unix() - toMoment(b.invoiceDate).unix();
			},
		},
		{
			title: 'Due date',
			dataIndex: 'dueDate',
			render: (date) => (date != null ? formatDate(date) : (<em>-</em>)),
			sorter: (a, b) => {
				if (a.dueDate == null || b.dueDate == null) {
					return 1;
				}

				return toMoment(a.dueDate).unix() - toMoment(b.dueDate).unix();
			},
		},
		{
			title: 'Days outstanding',
			dataIndex: 'daysOutstanding',
			render: (days) => (days != null && days >= 0 ? days : (<em>-</em>)),
			align: 'right',
			sorter: standardSort('daysOutstanding'),
			width: 75,
		},
		{
			title: 'Invoiced',
			dataIndex: 'amount',
			render: (amount, row) => (
				<Typography.Text className={styles.totalCell}>
					{formatCurrency(
						amount,
						row.currency,
						{
							forceDecimals: true,
							hideSymbol: hideCurrencies,
							maximumFractionDigits: 2,
						},
					)}
				</Typography.Text>
			),
			sorter: standardSort('amount'),
			align: 'right',
		},
		{
			title: 'Paid',
			dataIndex: 'paid',
			render: (paid, row) => (
				<Typography.Text className={styles.totalCell}>
					{formatCurrency(
						paid,
						row.currency,
						{
							forceDecimals: true,
							hideSymbol: hideCurrencies,
							maximumFractionDigits: 2,
						},
					)}
				</Typography.Text>
			),
			sorter: standardSort('paid'),
			align: 'right',
		},
		{
			title: 'Outstanding',
			dataIndex: 'outstanding',
			render: (outstanding, row) => (
				<Typography.Text className={styles.totalCell}>
					{formatCurrency(
						outstanding,
						row.currency,
						{
							forceDecimals: true,
							hideSymbol: hideCurrencies,
							maximumFractionDigits: 2,
						},
					)}
				</Typography.Text>
			),
			sorter: standardSort('outstanding'),
			align: 'right',
		},
	];

	return columns;
};

const getSummaryRows = (stats: Array<{
	currency: Values<typeof Currencies>;
	outstanding: number;
	invoiced: number;
	allocated: number;
	unallocated: number;
}>) => {
	const multipleCurrencies = stats.length > 1;
	const rows = [
		{ label: 'Total invoiced', key: 'invoiced' },
		...(multipleCurrencies ? [] : [{ label: 'Total allocated payments', key: 'allocated' }]),
		...(multipleCurrencies ? [] : [{ label: 'Total unallocated payments', key: 'unallocated' }]),
		{ label: 'Total outstanding', key: 'outstanding' },
	];

	if (stats.length === 0) {
		return null;
	}

	if (stats.length > 1) {
		return stats.map((stat) => (
			<Card
				key={stat.currency}
				size="small"
				title={stat.currency}
				className={styles.card}
			>
				<Space direction="vertical">
					{rows.map(({ label, key }) => (
						<Statistic
							value={stat[key]}
							title={label}
							valueRender={
								() => formatCurrency(stat[key], stat.currency,
									{ forceDecimals: true, maximumFractionDigits: 2 })
							}
						/>
					))}
				</Space>
			</Card>
		));
	}

	const values = stats[0];

	if (values == null) {
		return null;
	}

	return rows.map(({ label, key }) => (
		<Card
			key={`${label}-${key}-${values.currency}`}
			size="small"
			title={`${label}${multipleCurrencies ? ` - ${values.currency}` : ''}`}
			className={styles.card}
		>
			<Statistic
				value={values[key]}
				valueRender={
					() => formatCurrency(values[key], values.currency,
						{ forceDecimals: true, maximumFractionDigits: 2 })
				}
			/>
		</Card>
	));
};

const calculateStats = (data: Array<OutStandingData> | undefined) => {
	if (data == null) {
		return [];
	}

	const currencyObj: {
		[key in typeof Currencies as string]: {
			outstanding: number;
			invoiced: number;
			unallocated: number;
			allocated: number;
		}
	} = {};

	const filteredContracts = new Set<number>();
	const currencies = new Set<string>();
	data.forEach((row) => {
		filteredContracts.add(row.contractId);
		currencies.add(row.currency);
	});

	const sortedArr = Array.from(filteredContracts).sort((a, b) => a - b);
	currencies.forEach((c) => {
		currencyObj[c] = {
			invoiced: 0,
			outstanding: 0,
			unallocated: 0,
			allocated: 0,
		};
	});

	sortedArr.forEach((contractId) => {
		const relevantRows = data.filter((row) => row.contractId === contractId);
		const { currency } = relevantRows[0];

		const invoiced = calculateTotal(relevantRows, (row) => row.amount);
		const allocated = relevantRows[0].allocatedAmountContract;
		const unallocated = relevantRows[0].unallocatedAmountContract;
		const outstanding = invoiced - Math.abs(allocated) - Math.abs(unallocated);

		currencyObj[currency] = {
			invoiced: currencyObj[currency].invoiced + invoiced,
			outstanding: currencyObj[currency].outstanding + outstanding,
			allocated: currencyObj[currency].allocated + allocated,
			unallocated: currencyObj[currency].unallocated + unallocated,
		};
	});

	return Object.entries(currencyObj).map((c) => {
		const { outstanding, invoiced, unallocated, allocated } = c[1];

		const parsedOutstanding = typeof outstanding === 'number' ? round(outstanding, 2) : 0;
		const parsedInvoiced = typeof invoiced === 'number' ? round(invoiced, 2) : 0;
		const parsedUnallocated = typeof unallocated === 'number' ? round(unallocated, 2) : 0;
		const parsedAllocated = typeof allocated === 'number' ? round(allocated, 2) : 0;

		return {
			currency: c[0] as Values<typeof Currencies>,
			outstanding: parsedOutstanding,
			invoiced: parsedInvoiced,
			unallocated: parsedUnallocated,
			allocated: parsedAllocated,
		};
	});
};

const OutstandingsDashboard: DashboardComponent = ({ setLastUpdated }) => {
	const { userInfo } = useAuth();
	const [localFilters, setLocalFilters] = useState<
        Record<string, FilterValue | null> | undefined
    >();
	const [showZero, setShowZero] = useState(false);

	const {
		data: outstandings,
		isFetching: isLoading,
	} = useQuery(
		DashboardTypes.OUTSTANDINGS,
		getOutstandingsData,
		{
			onSuccess: (d) => setLastUpdated(d.lastUpdated),
			refetchOnWindowFocus: false,
		},
	);

	const localData = useMemo(() => {
		const data = outstandings?.data;

		if (data == null) {
			return [];
		}

		let filteredData = data;

		// Apply user vessel filter if enabled
		if (userInfo.filterVessels) {
			const relevantVesselIds = new Set(userInfo.relevantVessels.map((vessel) => vessel.id));
			filteredData = filteredData.filter((d) => relevantVesselIds.has(d.vessel.id));
		}

		// Apply local filters
		if (localFilters != null) {
			filteredData = filteredData.filter((d) => {
				const filterOnContract = localFilters.contract != null && localFilters.contract.length > 0;
				const filterOnCounterparty = localFilters.counterparty != null &&
                    localFilters.counterparty.length > 0;
				const filterOnVessel = localFilters.vessel != null && localFilters.vessel.length > 0;

				return (
					(filterOnContract ? localFilters.contract!.includes(d.contractIdentifier) : true) &&
                    (filterOnVessel ? localFilters.vessel!.includes(d.vessel.name) : true) &&
                    (filterOnCounterparty ? localFilters.counterparty!.includes(d.counterparty?.name ?? '') : true)
				);
			});
		}

		return filteredData;
	}, [outstandings, localFilters, userInfo]);

	const totalStats = useMemo(() => calculateStats(localData), [localData]);

	const filterMaps = useMemo(() => {
		const vessels = new Set<string>();
		const contracts = new Set<string>();
		const contractType = new Set<string>();
		const counterparties = new Set<string>();

		outstandings?.data?.forEach((item) => {
			vessels.add(item.vessel.name);
			contracts.add(item.contractIdentifier);
			contractType.add(item.contractType);
			if (item.counterparty != null && item.counterparty.name != null) {
				counterparties.add(item.counterparty.name);
			}
		});

		return {
			vessels: stringSetToSortedList<string>(vessels),
			contracts: stringSetToSortedList<string>(contracts),
			contractTypes: stringSetToSortedList<string>(contractType),
			counterparties: stringSetToSortedList<string>(counterparties),
		};
	}, [outstandings]);

	return (
		<>
			<Space direction="vertical" className={styles.mainContainer}>
				<Space>
					{getSummaryRows(totalStats)}
				</Space>
				<div className={styles.controlContainer}>
					<Flex gap={20} align="center">
						<Alert
							className={styles.alert}
							closable
							message="It's possible to filter on many different parameters, such as vessel, contract & counterparty."
							showIcon
						/>
					</Flex>
					<Space>
						<Typography.Text>Show zero outstanding?</Typography.Text>
						<Switch
							checked={showZero}
							onChange={setShowZero}
						/>
					</Space>

				</div>
			</Space>
			<Table
				className={styles.table}
				size="middle"
				onChange={(
					_pagination,
					filters,
				) => {
					setLocalFilters(filters);
				}}
				scroll={{ x: true }}
				loading={isLoading}
				columns={getColumns(filterMaps, totalStats.length === 1)}
				dataSource={localData.filter((d) => {
					if (!showZero) {
						const isEffectivelyZero = d.outstanding >= -0.01 && d.outstanding <= 0.01;

						return !isEffectivelyZero;
					}

					return true;
				}) ?? []}
				pagination={{
					position: ['bottomCenter'],
				}}
			/>
		</>
	);
};

export default OutstandingsDashboard;
