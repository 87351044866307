import React from 'react';
import { Form } from 'antd';
import { ColumnGroupType } from 'antd/lib/table';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import EditableTable, { EditableColumn } from '@client/components/EditableTable';
import { MultiCurrencyValuesType } from '@client/components/MultiCurrencyAmount';
import { getOffHireBunkerColumns } from '../helpers/tableColumns';

export type OffHireBunkerValues = {
	id: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon: MultiCurrencyValuesType;
};

export type OffHireBunker = {
	id: number;
	quantity: number;
	pricePerTon: number;
	fuelGrade: Values<typeof FuelTypes>;
	exchangeRate: number;
	currency: Values<typeof Currencies>;
}

const OffHireBunkersTable = ({
	bunkers,
	setBunkers,
	editingBunkers,
	setEditingBunkers,
	currency,
	setDirty,
	disabled = false,
}: {
	bunkers: OffHireBunker[];
	setBunkers: (val: OffHireBunker[]) => void;
	editingBunkers: number | null;
	setEditingBunkers: (val: number | null) => void;
	currency: Values<typeof Currencies>;
	setDirty?: (val: boolean) => void;
	disabled?: boolean;
}) => {
	const [bunkersForm] = Form.useForm();

	const saveBunkers = (id: number, values: OffHireBunkerValues) => {
		setBunkers(bunkers.map((b) => (b.id === id ? ({
			...values,
			pricePerTon: values.pricePerTon.value,
			currency: values.pricePerTon.currency,
			exchangeRate: values.pricePerTon.exchangeRate,
			value: undefined,
			// Give it a random negative ID
			// This makes sure it will be updated in the backend
			id: -Math.random(),
		}) : b)));

		if (setDirty != null && typeof setDirty === 'function') {
			setDirty(true);
		}
	};

	const addNewBunkers = (values: OffHireBunkerValues) => {
		setBunkers([
			...bunkers,
			{
				...values,
				id: -Math.random(),
				pricePerTon: values.pricePerTon.value,
				currency: values.pricePerTon.currency,
				exchangeRate: values.pricePerTon.exchangeRate,
			},
		]);
	};

	const deleteBunkers = (id: number) => {
		setBunkers(bunkers.filter((b) => b.id !== id));

		if (setDirty != null && typeof setDirty === 'function') {
			setDirty(true);
		}
	};

	const updateEditingBunkers = (newEditing: number | null) => {
		setEditingBunkers(newEditing);
	};

	return (
		<EditableTable<OffHireBunkerValues, 'id'>
			allowAddNew={!disabled}
			addNewText="Bunkers consumed"
			keyDataIndex="id"
			form={bunkersForm}
			columns={
				getOffHireBunkerColumns(
					currency,
				) as (ColumnGroupType<OffHireBunkerValues> | EditableColumn<OffHireBunkerValues, 'id'>)[]
			}
			dataSource={bunkers.length > 0 ?
				bunkers.map((e) => ({
					...e,
					pricePerTon: {
						value: e.pricePerTon,
						exchangeRate: e.exchangeRate || 1,
						currency: e.currency,
						id: e.id,
					},
				})) : []}
			onSave={saveBunkers}
			onDelete={deleteBunkers}
			editingRow={editingBunkers}
			onAddNew={addNewBunkers}
			onEditingRowChange={updateEditingBunkers}
			pagination={false}
			size="small"
			actionsTitle=""
			enableDelete
			iconButtons
		/>
	);
};

export default OffHireBunkersTable;
