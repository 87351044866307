import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from '@client/components/Button';
import styles from './styles/LinkButton.module.css';

const LinkButton = ({
	url,
	icon = undefined,
	children,
	...props
}: {
	url: string;
	icon?: ReactNode;
	children?: ReactNode;
} & ButtonProps) => (
	<Link to={url}>
		<Button
			type="link"
			icon={icon}
			className={styles.linkButton}
			{...props}
		>
			{children}
		</Button>
	</Link>
);

export default LinkButton;

