import React, {
	useState,
	useMemo,
	useCallback,
	useEffect,
} from 'react';
import {
	Checkbox,
	Col,
	Drawer,
	Form,
	Input,
	Modal,
	Radio,
	Row,
	Space,
	Typography,
} from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import sortBy from 'lodash.sortby';
import moment, { Moment } from 'moment';
import { standardSort } from '@shared/utils/standardSort';
import {
	formatDuration,
	toMoment,
} from '@shared/utils/date';
import {
	Currencies,
	DATE_AND_TIME,
	FixtureTypes,
} from '@shared/utils/constants';
import type { UserData } from '@api/utils/sequelize/calculateUserData';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import { rules } from '@client/utils/form';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import Select from '@client/components/Select';
import { useAuth } from '@client/lib/auth';
import Button from '@client/components/Button';
import DatePicker from '@client/components/DatePicker';
import styles from '@client/screens/fleet/VoyageDetailsScreen/components/styles/OffHireForm.module.css';
import {
	allocateVesselOffHirePeriod,
	createVesselOffHirePeriod,
	deleteVesselOffHirePeriod,
	unAllocateVesselOffHirePeriod,
	updateVesselOffHirePeriod,
} from '@client/lib/api';
import TooltipIcon from '@client/components/TooltipIcon';
import CurrencyInput from '@client/components/CurrencyInput';
import PercentageInput from '@client/components/PercentageInput';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import StateTag from '@client/components/StateTag';
// eslint-disable-next-line no-restricted-syntax
import { formatDate } from '@client/utils/formatDate';
import OffHireBunkersTable, { OffHireBunker } from '@client/screens/fleet/VoyageDetailsScreen/components/OffHireBunkersTable';

const VesselOffHireTab = ({
	vessel,
	refreshVessel,
}: {
	vessel: GetVesselDetailsResponse | undefined;
	refreshVessel: () => void;
}) => {
	const [drawerOpen, setDrawerOpen] = useState<number | boolean>(false);
	const [selectedVoyageId, setSelectedVoyageId] = useState<number | null>(null);
	const [bunkers, setBunkers] = useState<OffHireBunker[]>([]);
	const [editingBunkers, setEditingBunkers] = useState<number | null>(null);
	const [contractModalOpen, setContractModalOpen] = useState<number | null>(null);
	const [tableData, setTableData] = useState<Array<
		{voyageOffHireId: number; creditNote: boolean}>
	>([]);
	const [form] = Form.useForm();
	const [contractForm] = Form.useForm();
	const [durationType, setDurationType] = useState<'duration' | 'date'>('duration');

	const { userInfo } = useAuth();
	const dateProps = {
		time: true,
		fullWidth: true,
	};

	const selectedOffHireEntry = useMemo(() => {
		if (!drawerOpen || drawerOpen == null || vessel == null) {
			return null;
		}

		const item = vessel.OffHirePeriods.find((entry) => entry.id === drawerOpen);

		if (item == null) {
			return null;
		}

		return item;
	}, [drawerOpen, vessel]);

	const hasInvoicedPeriods = useMemo(() => {
		if (selectedOffHireEntry == null) {
			return false;
		}

		return selectedOffHireEntry.VoyageOffHirePeriods.some((vohp) => (
			vohp.HIIOffHirePeriod != null
		));
	}, [selectedOffHireEntry]);

	const offHireTypes = useMemo(() => (userInfo as UserData)?.orgOffHireTypes, [userInfo]);

	const getDurationFromDates = useCallback((startTime: Moment, endTime: Moment) => {
		if (startTime == null || endTime == null) {
			return {};
		}

		const duration = moment.duration(toMoment(endTime).diff(startTime));

		return {
			'durationDays': duration.days(),
			'durationHours': duration.hours(),
			'durationMinutes': duration.minutes(),
		};
	}, []);

	const setEndTimeFromDurations = useCallback((startTime: Moment) => {
		const days = form.getFieldValue('durationDays');
		const hours = form.getFieldValue('durationHours');
		const minutes = form.getFieldValue('durationMinutes');
		const endTime = toMoment(startTime)
			.add(days, 'days')
			.add(hours, 'hours')
			.add(minutes, 'minutes');
		form.setFieldsValue({
			'endTime': endTime,
		});
	}, [form]);

	const unAllocate = async (voyageId: number, offHireId: number) => {
		if (offHireId != null && vessel?.id != null) {
			try {
				await unAllocateVesselOffHirePeriod(offHireId, vessel.id, voyageId);
				await refreshVessel();
				showSuccessNotification('Off-hire has been un-allocated');
			} catch (e) {
				showErrorNotification('Unable to un-allocate off-hire', e as Error);
			}
		}
	};

	useEffect(() => {
		if (selectedOffHireEntry != null) {
			const data = selectedOffHireEntry.VoyageOffHirePeriods.map((p) => ({
				voyageOffHireId: p.id,
				identifier: p.Voyage.identifier,
				creditNote: true,
			}));
			setDurationType(selectedOffHireEntry.preferDuration ? 'duration' : 'date');
			setTableData(data);
		}
	}, [selectedOffHireEntry]);

	const onAllocate = async () => {
		const {
			contract,
			hirePerDay,
			percentageForOwnersAccount,
			note,
			invoiceNote,
		} = contractForm.getFieldsValue();

		const offHireId = contractModalOpen;

		if (offHireId != null && vessel?.id != null && contract != null) {
			try {
				await allocateVesselOffHirePeriod(
					offHireId,
					vessel.id,
					contract,
					hirePerDay,
					percentageForOwnersAccount,
					note,
					invoiceNote,
					bunkers,
				);
				showSuccessNotification('Off-hire successfully allocated');
				setContractModalOpen(null);
				refreshVessel();
				contractForm.resetFields();
			} catch (e) {
				showErrorNotification('Could not allocate off-hire', e as Error);
			}
		}
	};

	const handleDateChange = useCallback(() => {
		const startTime = form.getFieldValue('startTime');

		if (startTime == null) {
			return;
		}

		if (durationType === 'date') {
			const endTime = form.getFieldValue('endTime');

			if (endTime == null) {
				return;
			}

			const durations = getDurationFromDates(startTime, endTime);
			form.setFieldsValue(durations);
		} else {
			setEndTimeFromDurations(startTime);
		}
	}, [durationType, form, getDurationFromDates, setEndTimeFromDurations]);

	useEffect(() => {
		handleDateChange();
	}, [durationType, handleDateChange]);

	const saveOffHire = async () => {
		const values = form.getFieldsValue();
		const { startTime, endTime, type } = values;

		if (vessel == null) {
			return;
		}

		const preferDuration = durationType === 'duration';

		if (selectedOffHireEntry != null) {
			try {
				await updateVesselOffHirePeriod(
					selectedOffHireEntry.id,
					selectedOffHireEntry.vesselId,
					hasInvoicedPeriods ? tableData : [],
					values,
				);
				showSuccessNotification('Off-hire updated successfully');
			} catch (e) {
				showErrorNotification('Could not update off-hire', e as Error);
			}
		} else {
			try {
				await createVesselOffHirePeriod(vessel.id, startTime, endTime, type, preferDuration);
				showSuccessNotification('Off-hire created successfully');
			} catch (e) {
				showErrorNotification('Could not created off-hire', e as Error);
			}
		}

		await refreshVessel();
		setDrawerOpen(false);
	};

	useEffect(() => {
		if (selectedOffHireEntry != null) {
			let formValues = selectedOffHireEntry;

			if (selectedOffHireEntry.startTime != null && selectedOffHireEntry.endTime != null) {
				const durations = getDurationFromDates(
					toMoment(selectedOffHireEntry.startTime),
					toMoment(selectedOffHireEntry.endTime),
				);

				formValues = { ...formValues, ...durations };
			}

			form.setFieldsValue(formValues);
		}
	}, [selectedOffHireEntry, getDurationFromDates, handleDateChange, form]);

	const deleteOffHirePeriod = async (id: number) => {
		if (vessel == null) {
			return;
		}

		try {
			await deleteVesselOffHirePeriod(vessel?.id, id);
			await refreshVessel();
			showSuccessNotification('Off-hire period deleted successfully');
		} catch (e) {
			showErrorNotification('Could not delete off-hire period', e as Error);
		}
	};

	const availableVoyages = useMemo(() => {
		if (vessel == null || contractModalOpen == null) {
			return [];
		}

		const { voyages } = vessel;
		const item = vessel.OffHirePeriods.find((entry) => entry.id === contractModalOpen);

		const filtered = voyages.filter((v) => (
			v.fixture.type !== FixtureTypes.SPOT &&
			!item?.VoyageOffHirePeriods.some((period) => period.voyageId === v.id)
		));

		return filtered;
	}, [vessel, contractModalOpen]);

	const selectedVoyage = useMemo(() => {
		if (selectedVoyageId != null && vessel != null) {
			const voyage = vessel.voyages.find((v) => v.id === selectedVoyageId);

			return voyage;
		}

		return null;
	}, [selectedVoyageId, vessel]);

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Card
						extra={(
							<Button
								onClick={() => {
									form.resetFields();
									setDrawerOpen(-1);
								}}
								type="primary"
							>
								Create off-hire
							</Button>
						)}
						slim
						title="Vessel off-hire"
					>
						<Table
							dataSource={vessel?.OffHirePeriods ?? []}
							pagination={false}
							size="small"
							rowKey="id"
							expandable={{
								expandedRowRender: (record) => (
									<ul style={{ margin: 0 }}>
										{
											record.VoyageOffHirePeriods.map((p) => (
												<li>
													Allocated to:
													{' '}
													<Space size="small">
														<LinkButton url={Links.Voyage.get(p.Voyage.id)}>
															{p.Voyage.identifier}
														</LinkButton>
														<StateTag state={p.state} showUnresolved={false} />
														<Button
															confirmTitle="Are you sure you want to unallocate this off-hire from the selected contract?"
															onClick={() => unAllocate(p.voyageId, p.id)}
															type="link"
															danger
														>
															Unallocate
														</Button>
													</Space>
												</li>
											))
										}
									</ul>
								),
								rowExpandable: (record) => record?.VoyageOffHirePeriods?.length > 0,
							}}
							columns={[
								{
									title: 'Type',
									dataIndex: 'type',
									render: (type) => (type == null ? 'Off-hire' : type),
								},
								{
									title: 'Start',
									dataIndex: 'startTime',
									render: (date) => formatDate(date, DATE_AND_TIME),
									sorter: standardSort('startTime'),
									defaultSortOrder: 'descend',
								},
								{
									title: 'End',
									dataIndex: 'endTime',
									render: (date) => formatDate(date, DATE_AND_TIME),
									sorter: standardSort('endTime'),
								},
								{
									title: 'Duration',
									render: (row) => formatDuration(row.startTime, row.endTime),
								},
								{
									title: 'Allocations',
									render: (row: GetVesselDetailsResponse['OffHirePeriods'][number]) => {
										if (row.VoyageOffHirePeriods.length === 0) {
											return 'No allocations';
										}

										const firstVoyage = row.VoyageOffHirePeriods[0].Voyage;

										return (
											<>
												<LinkButton url={Links.Voyage.get(firstVoyage.id)}>
													{firstVoyage.identifier}
												</LinkButton>
												{row.VoyageOffHirePeriods.length > 1 && ` and ${row.VoyageOffHirePeriods.length - 1} other`}
												{row.VoyageOffHirePeriods.length > 2 && 's'}
											</>
										);
									},
								},
								{
									title: 'Actions',
									align: 'right',
									render: (row) => (
										<Space>
											<Button onClick={() => setContractModalOpen(row.id)} type="primary">
												Allocate to contract
											</Button>
											<Button
												onClick={() => setDrawerOpen(row.id)}
												icon={(<EditOutlined />)}
											/>
											<Button
												confirmTitle="Are you sure you want to delete this off-hire entry? All off-hire periods allocated to contracts will also be deleted"
												onClick={() => deleteOffHirePeriod(row.id)}
												danger
												disabled={row.VoyageOffHirePeriods.length > 0}
												disabledTooltip="This off-hire has already been allocated to one or more contracts, and therefore cannot be deleted"
												icon={(<DeleteOutlined />)}
											/>
										</Space>
									),
								},
							]}
						/>
					</Card>
				</Col>
			</Row>
			<Drawer
				onClose={() => setDrawerOpen(false)}
				open={!!drawerOpen}
				key={selectedOffHireEntry?.id ?? 'contractDrawer'}
			>

				<Row
					gutter={16}
					className={styles.rowsWithLessMargin}
				>
					<Form
						onValuesChange={() => {
							handleDateChange();
						}}
						form={form}
						layout="vertical"
					>
						<Radio.Group
							onChange={({ target: { value } }) => setDurationType(value)}
							value={durationType}
						>
							<Col
								span={15}
								className={styles.dateRow}
							>
								<Form.Item
									name="startTime"
									label="From"
									rules={[rules.required]}
								>
									<DatePicker
										{...dateProps}
									/>
								</Form.Item>
							</Col>
							<Col
								span={24}
								className={styles.dateRow}
							>
								<Row
									align="middle"
									gutter={10}
								>
									<Col span={15}>
										<Form.Item
											name="endTime"
											label="To"
											rules={durationType === 'date' ? [rules.required] : []}
										>
											<DatePicker
												disabled={durationType !== 'date'}
												{...dateProps}
											/>
										</Form.Item>
									</Col>
									<Col
										span={1}
										className={styles.radioBtnCol}
									>
										<Radio
											value="date"
											className={styles.radioBtn}
										/>
									</Col>
									<Col className={styles.radioLabelCol} span={5}>
										<Typography.Text className={styles.radioLabel}>
											Use date
										</Typography.Text>
									</Col>
								</Row>
							</Col>
							<Col
								span={24}
							>
								<Row
									align="middle"
									gutter={10}
									className={styles.durationRow}
								>
									<Col span={5}>
										<Form.Item
											label="Days"
											name="durationDays"
											initialValue={0}
											rules={durationType === 'duration' ? [rules.requiredShort] : []}
											className={styles.durationEntryForm}
										>
											<Input
												disabled={durationType !== 'duration'}
											/>
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											label="Hours"
											initialValue={0}
											name="durationHours"
											rules={durationType === 'duration' ? [rules.requiredShort] : []}
											className={styles.durationEntryForm}
										>
											<Input
												disabled={durationType !== 'duration'}
											/>
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item
											label="Minutes"
											initialValue={0}
											name="durationMinutes"
											rules={durationType === 'duration' ? [rules.requiredShort] : []}
											className={styles.durationEntryForm}
										>
											<Input
												disabled={durationType !== 'duration'}
											/>
										</Form.Item>
									</Col>
									<Col
										span={1}
										className={styles.radioBtnCol}
									>
										<Radio
											value="duration"
											className={styles.radioBtn}
										/>
									</Col>
									<Col className={styles.radioLabelCol} span={5}>
										<Typography.Text className={styles.radioLabel}>
											Use duration
										</Typography.Text>
									</Col>
								</Row>
							</Col>
						</Radio.Group>
						<Col span={24}>
							{offHireTypes != null && offHireTypes.length > 0 && (
								<Form.Item
									name="type"
									label="Off-hire type"
								>
									<Select
										options={(sortBy(offHireTypes) ?? []).map((ot) => ({
											label: ot,
											value: ot,
										}))}
										allowClear
										placeholder="Select an off-hire type"
									/>
								</Form.Item>
							)}
						</Col>
					</Form>
					{(
						drawerOpen !== -1 &&
							selectedOffHireEntry != null &&
							selectedOffHireEntry.VoyageOffHirePeriods.length > 0 &&
							hasInvoicedPeriods
					) && (
						<Col span={24}>
							<b>Associated contracts</b>
							<Table
								dataSource={tableData}
								columns={[
									{
										title: 'Contract',
										dataIndex: 'identifier',
									},
									{
										dataIndex: 'creditNote',
										title: 'Generate credit note?',
										render: (creditNote, row) => (
											<Checkbox
												checked={creditNote}
												onChange={(e) => {
													const newData = [...tableData];
													const index = newData.findIndex((item) => (
														item.voyageOffHireId === row.voyageOffHireId
													));
													newData[index] = { ...newData[index], creditNote: e.target.checked };
													setTableData(newData);
												}}
											/>
										),
									},
								]}
							/>
						</Col>
					)}
					<Col span={24}>
						<Button onClick={saveOffHire} type="primary">
							{drawerOpen === -1 ? 'Create' : 'Save'}
						</Button>
					</Col>
				</Row>

			</Drawer>
			<Modal
				onOk={onAllocate}
				okText="Allocate"
				onCancel={() => {
					setContractModalOpen(null);
					contractForm.resetFields();
				}}
				title="Allocate to contract"
				open={contractModalOpen != null}
			>
				<Form
					onValuesChange={(values) => {
						if (values.contract != null) {
							setSelectedVoyageId(values.contract);
						}
					}}
					layout="vertical"
					form={contractForm}
				>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Form.Item name="contract" label="Select a contract">
								<Select
									placeholder={availableVoyages.length === 0 ? 'No available contracts' : 'Select a contract'}
									disabled={availableVoyages.length === 0}
									optionLabelProp="identifier"
									options={availableVoyages.map((v) => ({
										label: (
											<Space size="small" direction="vertical">
												{v.identifier}
												{`Commencing: ${v.commencementDate == null ? 'Unknown' : formatDate(v.commencementDate, DATE_AND_TIME)}`}
											</Space>
										),
										identifier: v.identifier,
										value: v.id,
									}))}

								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="hirePerDay"
								label={(
									<>
										Hire per day
										<TooltipIcon>
											The hire amount per day the
											off-hire period should be based on.
										</TooltipIcon>
									</>
								)}
								rules={[rules.required]}
							>
								<CurrencyInput
									disabled={selectedVoyage == null}
									placeholder="Hire rate per day"
									currency={selectedVoyage?.activeVoyageCurrency ?? Currencies.USD}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="percentageForOwnersAccount"
								label="Proportion for owner's account"
								rules={[rules.required]}
							>
								<PercentageInput disabled={selectedVoyage == null} min={0} max={100} />
							</Form.Item>
						</Col>
						<Col span={24}>
							<OffHireBunkersTable
								key={`${selectedVoyageId}-${bunkers.length}`}
								disabled={selectedVoyage == null}
								currency={selectedVoyage?.activeVoyageCurrency ?? Currencies.USD}
								bunkers={bunkers}
								setBunkers={setBunkers}
								editingBunkers={editingBunkers}
								setEditingBunkers={setEditingBunkers}
							/>
						</Col>
						<Col
							span={24}
							className={styles.rowsWithLessMargin}
						>
							<Form.Item
								name="note"
								label="Internal note"
							>
								<Input.TextArea />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item
								name="invoiceNote"
								label="Invoice note"
							>
								<Input.TextArea />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>
	);
};

export default VesselOffHireTab;
