import React, {
	useMemo,
	useState,
} from 'react';
import {
	Badge,
	Empty,
	List,
	Popover,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLifeRing } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import {
	ArrowLeftOutlined,
	CloseOutlined,
} from '@ant-design/icons';
import type { VideoGuideProps } from '@api/models/video-guide';
import { useVideoGuides } from '@client/utils/hooks/useVideoGuides';
import LoomVideo from '@client/components/LoomVideo';
import TopBarButton from '@client/components/TopBar/private/TopBarButton';
import { useGlobalState } from '@client/lib/globalState';
import Button from '@client/components/Button';
import styles from './VideoGuidePopover.module.css';

const VideoGuidePopover = () => {
	const list = useVideoGuides();
	const hasTours = (list?.length ?? 0) > 0;
	const [pageTitle] = useGlobalState('PAGE_TITLE');
	const [open, setOpen] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState<VideoGuideProps | null>(null);

	const listContent = useMemo(() => {
		if (list == null || !hasTours) {
			return (
				<div className={styles.emptyWrapper}>
					<Empty description="No videos available for this page" />
				</div>
			);
		}

		return (
			<div>
				{list.map((item) => (
					<List.Item
						className={styles.item}
						key={item.title}
						onClick={() => {
							setSelectedVideo(item);
						}}
					>
						<List.Item.Meta
							title={item.title}
							description={item.description}
							className={styles.listElement}
						/>
					</List.Item>
				))}
			</div>
		);
	}, [hasTours, list]);

	return (
		<Popover
			overlayClassName={styles.overlay}
			open={open}
			onOpenChange={(v) => {
				if (v) {
					setOpen(v);
				}

				if (!v && selectedVideo == null) {
					setOpen(v);
				}
			}}
			trigger="click"
			content={(
				<List
					header={(
						<div
							className={styles.listHeaderContainer}
						>
							<Typography.Title level={4} className={styles.header}>
								{selectedVideo?.title ?? 'Video Guides'}
							</Typography.Title>
							<div>
								{selectedVideo != null && (
									<Button
										type="link"
										className={styles.closeIcon}
										onClick={() => {
											setSelectedVideo(null);
										}}
										icon={(<ArrowLeftOutlined />)}
									/>
								)}
								<Button
									type="link"
									className={styles.closeIcon}
									onClick={() => {
										setOpen(false);
										setSelectedVideo(null);
									}}
									icon={(<CloseOutlined />)}
								/>
							</div>
						</div>
					)}
					className={styles.list}
				>
					<div className={styles.innerContent}>
						{selectedVideo != null ? (
							<LoomVideo
								url={selectedVideo.url}
								title={selectedVideo.title}
							/>
						) : listContent}
					</div>
				</List>
			)}
		>
			<div
				className={styles.buttonWrapper}
				data-tour="guidedTours"
			>
				<TopBarButton
					icon={(
						<FontAwesomeIcon
							icon={faLifeRing as Icon}
						/>
					)}
					onClick={() => {
						if (open) {
							setOpen(false);
						}
					}}
				>
					<Badge
						className={styles.badge}
						count={list?.length}
						title={`${list?.length ?? 0} tours for this page`}
						// eslint-disable-next-line react/forbid-component-props
						style={{
							display: hasTours ? undefined : 'none',
						}}
					>
						<div
							className={styles.guideTourButton}
							style={{
								marginRight: hasTours ? '10px' : undefined,
							}}
						>
							<span className={styles.mainTitle}>
								Video Guides
							</span>
							<span className={styles.subTitle}>
								{pageTitle}
							</span>
						</div>
					</Badge>
				</TopBarButton>
			</div>
		</Popover>
	);
};

export default VideoGuidePopover;
