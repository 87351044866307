import React, {
	createContext,
	useContext,
} from 'react';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { Port } from '@api/utils/ports';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import type { SeaPassageSettings } from '@api/utils/sequelize/calculateVoyageItineraryDetails/helpers/itineraryConstants';
import { useMiscHandlers } from './hooks/useMiscHandlers';
import {
	LocalPortRotationWithKey,
	NewPortRotationEntryFormValues,
	PartialPortRotationEntry,
	usePortRotationHandlers,
} from './hooks/usePortRotationHandlers';
import { useCentralStore } from './CentralStoreContext';

export type PortRotationContextType = {
	portRotation: LocalPortRotationWithKey[];
	onPortRotationChange: (entries: PartialPortRotationEntry[]) => Promise<void> | void;
	onPortRotationRowChange: (
		id: number | undefined,
		attributes: PartialPortRotationEntry
	) => Promise<void> | void;
	onOrderChange: (entries: PartialPortRotationEntry[]) => Promise<void>;
	onConfigUpdate: (id: number, routeOptions: SeaPassageSettings) => Promise<void>;
	onDeletePortRotationEntry: (entryToDelete: PartialPortRotationEntry) => void;
	onAddPortRotationEntry: (
		finalFormValues: NewPortRotationEntryFormValues,
		onAdd: (editing: boolean) => void
	) => void;
	estimateResult: GetEstimateDetailsResponse | null | undefined;
	ports: Port[] | undefined;
	portOptions: {
		label: string;
		value: number;
	}[];
	consumptionSets: Array<ConsumptionSetProps>;
	forceRenderKey: number;
}

export const PortRotationContext = createContext<PortRotationContextType>(undefined!);
export const usePortRotationProvider = () => useContext(PortRotationContext);

const PortRotationProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const {
		estimate,
		portRotation: centralPortRotation,
		portRotationChanged,
		setPortRotationChanged,
		syncPortRotation,
		setSyncPortRotation,
		vessel,
		selectedEstimateId,
		setLoading,
	} = useCentralStore();

	const {
		portRotation,
		onPortRotationChange,
		onPortRotationRowChange,
		onOrderChange,
		onConfigUpdate,
		onAddPortRotationEntry,
		onDeletePortRotationEntry,
		forceRenderKey,
	} = usePortRotationHandlers({
		estimate,
		portRotationChanged,
		setPortRotationChanged,
		syncPortRotation,
		setSyncPortRotation,
		centralPortRotation,
		selectedEstimateId,
		setLoading,
	});

	const {
		ports,
		portOptions,
	} = useMiscHandlers();

	return (
		<PortRotationContext.Provider
			value={{
				portRotation,
				onPortRotationChange,
				onPortRotationRowChange,
				onOrderChange,
				onConfigUpdate,
				onDeletePortRotationEntry,
				onAddPortRotationEntry,
				estimateResult:	estimate,
				ports,
				portOptions,
				consumptionSets: vessel?.consumptionSets ?? [],
				forceRenderKey,
			}}
		>
			{children}
		</PortRotationContext.Provider>
	);
};

export default PortRotationProvider;
