import React, {
	useState,
	useContext,
	useCallback,
	createContext,
} from 'react';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { RootPages } from '@client/utils/links';

type GlobalState = {
	PAGE_TITLE?: typeof RootPages[number];
} & {
	[Value in Values<typeof Currencies> as `exchange-rate-${Value}`]?: number
}

type Props = {
	children: React.ReactElement | Array<React.ReactElement | null>;
}

type SetState = (param: GlobalState | ((oldState: GlobalState) => GlobalState)) => void;

const GlobalStateContext = createContext<{
	state: GlobalState;
	setState: SetState;
} | undefined>(undefined);

export const useGlobalState = <Key extends keyof GlobalState>(
	key: Key,
): [GlobalState[Key], (val: GlobalState[Key]) => void] => {
	const context = useContext(GlobalStateContext);

	if (context == null) {
		throw new Error('Cannot use global state, the context hasn\'t been provided any values. Perhaps the hook was used outside the GlobalStateContext Provider?');
	}

	const { state, setState } = context;

	const setValue = useCallback((value: GlobalState[Key]) => {
		setState((oldState) => ({
			...oldState,
			[key]: value,
		}));
	}, [key, setState]);

	return [state[key], setValue];
};

export const GlobalStateProvider = ({ children }: Props) => {
	const [state, setState] = useState<GlobalState>({});

	return (
		<GlobalStateContext.Provider value={{ state, setState }}>
			{children}
		</GlobalStateContext.Provider>
	);
};
