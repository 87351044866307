import { Currencies } from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import {
	calculateTotal,
	round,
} from '@shared/utils/math';
import { Values } from '@shared/utils/objectEnums';

const validatePaymentInvoiceInput = (
	totalAmount: number,
	value: Array<{
		hireInvoiceId: number;
		amount: number;
	}>,
	currency: Values<typeof Currencies>,
) => {
	if (value == null || value.length <= 1 || totalAmount == null) {
		return;
	}

	const valueTotal = calculateTotal(value, (v) => v.amount);

	if (round(valueTotal, 2) !== round(totalAmount, 2)) {
		throw new Error(`
			Total payments (${formatCurrency(valueTotal, currency)}) 
			do not match the "Amount" field (${formatCurrency(totalAmount, currency)})
		`);
	}

	const hasEmptyField = value.some((row) => (
		row.hireInvoiceId === undefined ||
		row.amount == null
	));

	if (hasEmptyField) {
		throw new Error('All fields must be filled out');
	}
};

export default validatePaymentInvoiceInput;
