// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RtimjOHngvAvxx4YGvow {\n\theight: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\ttext-align: center;\n\tbackground-color: #001529;\n}\n\n.U159SRab_dcO0DzBHRfw {\n\twidth: 400px;\n\tmin-height: 200px;\n\tmax-width: 100%;\n\tmax-height: 100%;\n\tbox-shadow: #002a52 0 0 20px 10px;\n\tborder-radius: 8px;\n}\n\n.M2WRMEWWVJfr8XzbclCv {\n\twidth: 256px;\n\tmargin-bottom: 8px;\n}\n\n.RtimjOHngvAvxx4YGvow .U159SRab_dcO0DzBHRfw .kN2kyDBWvMPypAmy1CLo {\n\tmargin-top: 8px;\n\tmargin-bottom: 16px;\n}\n\n.BLN76EmTQYZcFlT3XPBo {\n\tpadding: 8px 8px;\n\theight: auto;\n}\n\n.bpkPcxVelplFW7rWSR1V {\n\tfont-weight: bold;\n\tfont-size: 18px;\n\tcolor: red;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/auth/styles/ActivationScreen.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,kBAAkB;CAClB,yBAAyB;AAC1B;;AAEA;CACC,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,gBAAgB;CAChB,iCAAiC;CACjC,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,YAAY;AACb;;AAEA;CACC,iBAAiB;CACjB,eAAe;CACf,UAAU;AACX","sourcesContent":[".container {\n\theight: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\ttext-align: center;\n\tbackground-color: #001529;\n}\n\n.inner {\n\twidth: 400px;\n\tmin-height: 200px;\n\tmax-width: 100%;\n\tmax-height: 100%;\n\tbox-shadow: #002a52 0 0 20px 10px;\n\tborder-radius: 8px;\n}\n\n.logo {\n\twidth: 256px;\n\tmargin-bottom: 8px;\n}\n\n.container .inner .accountName {\n\tmargin-top: 8px;\n\tmargin-bottom: 16px;\n}\n\n.activateButton {\n\tpadding: 8px 8px;\n\theight: auto;\n}\n\n.errorMessage {\n\tfont-weight: bold;\n\tfont-size: 18px;\n\tcolor: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RtimjOHngvAvxx4YGvow",
	"inner": "U159SRab_dcO0DzBHRfw",
	"logo": "M2WRMEWWVJfr8XzbclCv",
	"accountName": "kN2kyDBWvMPypAmy1CLo",
	"activateButton": "BLN76EmTQYZcFlT3XPBo",
	"errorMessage": "bpkPcxVelplFW7rWSR1V"
};
export default ___CSS_LOADER_EXPORT___;
