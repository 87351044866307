import React from 'react';
import { useParams } from 'react-router';
import { FixtureTypes } from '@shared/utils/constants';
import FixtureDetailsScreen from '@client/screens/fixtures/shared/FixtureDetailsScreen';

const FixtureTcInDetailsScreen = () => {
	const { id } = useParams();

	return (
		<FixtureDetailsScreen
			fixtureType={FixtureTypes.TC_IN}
			fixtureId={id}
		/>
	);
};

export default FixtureTcInDetailsScreen;
