// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lw3G_1eNzAayUYQfJvbS {\n\tmargin-top: 10px;\n}\n\n.pYrTiwMyeBeX_LHZ5nH2 {\n\tmargin-right: 5px;\n}\n\n.GY8eCJpYBlBVka5MOajl {\n\tpadding: 7px;\n}\n\n.qozgB3e2z0qv9mMjIygj {\n\tborder-radius: 6;\n\tborder: 1px solid #e6e6e6;\n\tborder-top: none;\n\tfont-weight: bold;\n}\n\n.Llyu6YtFN0ppOyQ1_3EA {\n\ttext-align: center;\n}\n\n.YyPYSus25lfTHlYZ6g8N {\n\tmin-width: 120px;\n\tmax-width: 220px;\n}\n\n.Cc3MH_HlJraMTXOxzr3d {\n\tmargin: 10px 0;\n}\n\n.BLSsG0XppFnUf9Drf97Q {\n\ttext-align: left;\n}\n\n.Lj_HgicuqPWk55HPuW7U {\n\ttext-align: center;\n}\n\n.ceODVNld6GOGazRQA89m {\n\tmax-width: 280px;\n\tmin-width: 240px;\n}\n\n.Hlj4U_rZtrIsmteCXXst {\n\tmargin: 8px 0;\n}\n\n.b4JAp41z0aDuYRvCQeU7 {\n\tpadding: 0 8px 4px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/LaytimeCalculationDetails.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gBAAgB;CAChB,yBAAyB;CACzB,gBAAgB;CAChB,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".saveResetBtnContainer {\n\tmargin-top: 10px;\n}\n\n.saveResetBtn {\n\tmargin-right: 5px;\n}\n\n.totalRowCell {\n\tpadding: 7px;\n}\n\n.totalRow {\n\tborder-radius: 6;\n\tborder: 1px solid #e6e6e6;\n\tborder-top: none;\n\tfont-weight: bold;\n}\n\n.toCountRow {\n\ttext-align: center;\n}\n\n.actionCol {\n\tmin-width: 120px;\n\tmax-width: 220px;\n}\n\n.btnContainer {\n\tmargin: 10px 0;\n}\n\n.importBtn {\n\ttext-align: left;\n}\n\n.durationCol {\n\ttext-align: center;\n}\n\n.dateCol {\n\tmax-width: 280px;\n\tmin-width: 240px;\n}\n\n.customAddDivider {\n\tmargin: 8px 0;\n}\n\n.customAddSpace {\n\tpadding: 0 8px 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"saveResetBtnContainer": "Lw3G_1eNzAayUYQfJvbS",
	"saveResetBtn": "pYrTiwMyeBeX_LHZ5nH2",
	"totalRowCell": "GY8eCJpYBlBVka5MOajl",
	"totalRow": "qozgB3e2z0qv9mMjIygj",
	"toCountRow": "Llyu6YtFN0ppOyQ1_3EA",
	"actionCol": "YyPYSus25lfTHlYZ6g8N",
	"btnContainer": "Cc3MH_HlJraMTXOxzr3d",
	"importBtn": "BLSsG0XppFnUf9Drf97Q",
	"durationCol": "Lj_HgicuqPWk55HPuW7U",
	"dateCol": "ceODVNld6GOGazRQA89m",
	"customAddDivider": "Hlj4U_rZtrIsmteCXXst",
	"customAddSpace": "b4JAp41z0aDuYRvCQeU7"
};
export default ___CSS_LOADER_EXPORT___;
