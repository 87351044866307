import React, { useState } from 'react';
import {
	Empty,
	List,
	Popover,
	Typography,
} from 'antd';
import classNames from 'classnames';
import styles from './styles/PopoverList.module.css';

const PopoverList = ({
	title,
	items,
	emptyText = 'No Data',
	children,
}) => {
	const [open, setOpen] = useState(false);

	return (
		<Popover
			overlayClassName={styles.overlay}
			className={styles.popover}
			open={open}
			onOpenChange={(v) => setOpen(v)}
			trigger="click"
			content={(
				<List
					header={(
						<Typography.Title level={4} className={styles.header}>
							{title}
						</Typography.Title>
					)}
					className={styles.list}
				>
					{items.length > 0 ? items.map((item) => (
						<List.Item
							className={classNames(styles.item, {
								[styles.disabled]: item.disabled,
							})}
							key={item.key}
							onClick={() => {
								if (item.disabled) {
									return;
								}

								if (typeof item.onClick === 'function') {
									item.onClick();
								}

								setOpen(false);
							}}
						>
							<List.Item.Meta
								title={item.title}
								description={item.description}
							/>
						</List.Item>
					)) : (
						<div className={styles.emptyWrapper}>
							<Empty description={emptyText} />
						</div>
					)}
				</List>
			)}
		>
			{children}
		</Popover>
	);
};

export default PopoverList;
