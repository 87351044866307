import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { formatCurrency } from '@shared/utils/currency';
import {
	AccountingItemApprovalStates,
	Currencies,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatPercentage } from '@shared/utils/formatPercentage';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import { formatDate } from '@client/utils/formatDate';
import EmptyText from '@client/components/EmptyText';
import styles from '@client/screens/fleet/VoyageDetailsScreen/components/CommissionsTab/CommissionsTab.module.css';
import StateTag from '@client/components/StateTag';
import Button from '@client/components/Button';
import {
	deleteBrokerInvoice,
	submitBrokerInvoice,
	unsubmitBrokerInvoice,
} from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';

export type TcCommissionRow = {
	type: React.ReactElement | string;
	invoiceId: number;
	brokerId?: number;
	itemId: number;
	invoiceIdentifier?: string;
	hireRate?: number;
	period?: string;
	days?: number | string;
	commission?: number;
	amount?: number;
	note?: string;
	isTotal?: boolean;
}

export type VcCommissionRow = {
	type: React.ReactElement | string;
	id: number;
	itemId: number;
	brokerId: number;
	brokerCommission: number;
	commissionAmount: number;
	chartererAmount: number;
	freightType: string;
	invoiceId: number;
	isTotal?: boolean;
}

export const getTcCalculatedCommissionColumns = (
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>,
): ColumnsType<TcCommissionRow> & { editable?: boolean } => ([
	{
		title: 'Type',
		dataIndex: 'type',
		render: (type, row) => (
			<>
				{type}
				<br />
				<span className={styles.invoiceIdentifier}>{row.invoiceIdentifier}</span>
			</>
		),
	},
	{
		title: 'Hire rate',
		dataIndex: 'hireRate',
		render: (v) => v && formatCurrency(v, fixtureDetails.bankAccount.currency),
	},
	{
		title: 'Period',
		dataIndex: 'period',
	},
	{
		title: 'Days',
		dataIndex: 'days',
	},
	{
		title: 'Commission',
		dataIndex: 'commission',
		render: (c) => formatPercentage(c),
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		render: (v, row) => {
			if (v == null) {
				return null;
			}

			const formatted = formatCurrency(v, fixtureDetails.bankAccount.currency);

			if (row.isTotal) {
				return (<strong>{formatted}</strong>);
			}

			return formatted;
		},
	},
	{
		title: 'Note',
		dataIndex: 'note',
		// @ts-ignore
		editable: true,
		width: 200,
		editingProps: {
			type: 'textarea',
		},
		render: (note, row) => {
			if (row.isTotal) {
				return null;
			}

			if (note == null || note === '') {
				return (<EmptyText />);
			}

			return (
				<div className={styles.note}>{note.trim()}</div>
			);
		},
	},
]);

export const getVcCalculatedCommissionColumns = ({
	currency,
}: {
	currency: Values<typeof Currencies>;
}): ColumnsType<VcCommissionRow> & { editable?: boolean } => ([
	{
		title: 'Identifier',
		dataIndex: 'invoiceIdentifier',
	},
	{
		title: 'Cargo',
		dataIndex: 'type',
	},
	{
		title: 'Type',
		dataIndex: 'freightType',
	},
	{
		title: 'Amount charged to charterer',
		dataIndex: 'invoiceAmount',
		render: (val, row) => (!row.isTotal ?
			formatCurrency(val ?? 0, currency) :
			null
		),
	},
	{
		title: 'Commission %',
		dataIndex: 'commissionPercent',
		render: (val) => (val != null ? `${val}%` : null),
	},
	{
		title: 'Amount to broker',
		dataIndex: 'commissionAmount',
		render: (val) => formatCurrency(val ?? 0, currency),
	},
	{
		title: 'Note',
		dataIndex: 'note',
		// @ts-ignore
		editable: true,
		width: 200,
		editingProps: {
			type: 'textarea',
		},
		render: (note, row) => {
			if (row.isTotal) {
				return null;
			}

			if (note == null || note === '') {
				return (<EmptyText />);
			}

			return (
				<div className={styles.note}>{note.trim()}</div>
			);
		},
	},
]);

export const getVcEstimatedCommissionColumns = ({
	currency,
}: {
	currency: Values<typeof Currencies>;
}): ColumnsType<VcCommissionRow> & { editable?: boolean } => ([
	{
		title: 'Cargo',
		dataIndex: 'cargoType',
	},
	{
		title: 'Type',
		dataIndex: 'type',
	},
	{
		title: 'Amount charged to charterer',
		dataIndex: 'amountChargedToCharterer',
		render: (val) => formatCurrency(val ?? 0, currency),
	},
	{
		title: 'Commission %',
		dataIndex: 'commissionPercent',
		render: (val) => (val != null ? `${val}%` : null),
	},
	{
		title: 'Amount to broker',
		dataIndex: 'commissionAmount',
		render: (val) => formatCurrency(val ?? 0, currency),
	},
]);

export const getBrokerInvoiceColumns = (
	currency: Values<typeof Currencies>,
	handleEdit: (values: GetVoyageDetailsResponse['brokerInvoices'][number]) => void,
	refreshDetails: () => void,
	confirmTitle: (row: GetVoyageDetailsResponse['brokerInvoices'][number]) => string | null,
): ColumnsType<GetVoyageDetailsResponse['brokerInvoices'][number]> => [
	{
		title: 'Description',
		dataIndex: 'itemDescription',
	},
	{
		title: 'Date Received',
		dataIndex: 'dateReceived',
		render: (val) => formatDate(val),
	},
	{
		title: 'Date Due',
		dataIndex: 'dueDate',
		render: (val) => formatDate(val),
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		render: (val) => formatCurrency(val, currency),
	},
	{
		dataIndex: 'state',
		title: 'State',
		render: (
			state: AccountingItemApprovalStates,
		) => (<StateTag state={state} />),
	},
	{
		dataIndex: '',
		title: '',
		align: 'center' as const,
		render: (_v: any, row: GetVoyageDetailsResponse['brokerInvoices'][number]) => {
			const canBeSubmitted = row.state === AccountingItemApprovalStates.PENDING;

			const { requiresApproval } = row;

			return (
				<div>
					{canBeSubmitted && (
						<Button
							onClick={async () => {
								try {
									await submitBrokerInvoice(row.id);
									showSuccessNotification('Broker invoice submitted!');
								} catch (e) {
									showErrorNotification('Could not submit broker invoice!');
								} finally {
									await refreshDetails();
								}
							}}
							type="primary"
						>
							{`${requiresApproval ? 'Submit for approval' : 'Approve'}`}
						</Button>
					)}
					{(requiresApproval && !canBeSubmitted) && (
						<Button
							icon={(
								<FontAwesomeIcon
									icon={faUndo as Icon}
									className={styles.undoIcon}
								/>
							)}
							type="link"
							className={styles.undoButton}
							disabled={row.state === AccountingItemApprovalStates.REJECTED}
							disabledTooltip="You cannot unsubmit a rejected item. If you wish to make changes, delete the item and re-submit."
							onClick={async () => {
								try {
									await unsubmitBrokerInvoice(row.id);
									showSuccessNotification('Broker invoice unsubmitted!');
								} catch (e) {
									showErrorNotification('Could not unsubmit broker invoice!');
								} finally {
									await refreshDetails();
								}
							}}
						>
							Undo submission
						</Button>
					)}
					<Button
						confirmTitle={
							row.state !== AccountingItemApprovalStates.PENDING ? (
								row.posted ? 'This item has already been posted. Deleting this item will also delete/reverse the item in the accounting system' :
									'This invoice has been submitted for approvel or is approved, are you sure you want to delete it?'
							) : 'Are you sure you want to delete this invoice?'
						}
						className={styles.dismissButton}
						onClick={async () => {
							try {
								await deleteBrokerInvoice(row.id);
								showSuccessNotification('Broker invoice deleted!');
							} catch (e) {
								showErrorNotification('Could not delete broker invoice!');
							} finally {
								await refreshDetails();
							}
						}}
						type="link"
						icon={(<DeleteOutlined />)}
						danger
						popconfirmProps={{
							placement: 'topLeft',
						}}
					/>
					<Button
						className={styles.editButton}
						onClick={() => handleEdit(row)}
						type="link"
						icon={(<EditOutlined />)}
						confirmTitle={confirmTitle(row)}
						popconfirmProps={{
							placement: 'topLeft',
						}}
					/>
				</div>
			);
		},
	},
];
