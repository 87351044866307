/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from 'react';
import {
	Col,
	Empty,
	Form,
	Input,
	Row,
} from 'antd';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { CargoUnitSingularLabels } from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import type { EditableCargo } from '@api/features/cargos/updateCargo';
import type { CargoPortWithEstimatedValues } from '@api/features/cargos/transformCargos';
import NumericInput from '@client/components/NumericInput';
import styles from './CargosListScreen.module.css';

const EstimatedCargoPortForm = ({
	cargo,
	cargoPort,
	handleCargoPortChange,
}: {
	cargo: EditableCargo;
	cargoPort: GetCargoDetailsResponse['cargoPorts'][number];
	refreshCargoDetails: () => void;
	handleCargoPortChange: (values: {
		cargoPortId: number; cargoId: number; attributes: any; }
	) => void;
}) => {
	if (cargoPort == null) {
		return (<Empty description="No cargo-port selected" />);
	}

	const handleUpdate = (fieldName: keyof CargoPortWithEstimatedValues, value: number | null) => {
		handleCargoPortChange({
			cargoPortId: cargoPort.id,
			cargoId: cargo.id,
			attributes: {
				[fieldName]: value,
			},
		});
	};

	/*
	* NOTE: You may be wondering - why "valuePropName=v" on all the form items components?
	* Thats to avoid Form.Item from overriding the value on the nested input element.
	* We'd like to control the value ourselves but still keep the label stuff from Form.Item
	*/

	return (
		<>
			<Row gutter={[16, 0]}>
				<Col span={12}>
					<Form.Item
						key={cargoPort.id}
						name="estimatedLoadingRate"
						initialValue={cargoPort.estimatedLoadingRate}
						label={
							splitActionKey(cargoPort.portAndActionKey).action ===
                                'loading' ?
								'Est. Loading Rate' :
								'Est. Discharge Rate'
						}
						valuePropName="v"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							tabIndex={19}
							addonAfter={`/${cargoPort.laytimeInterval}`}
							placeholder={`${
								CargoUnitSingularLabels[cargo.unit] ?? 'MT'
							}/ ${cargoPort.laytimeInterval}`}
							defaultValue={cargoPort.estimatedLoadingRate ?? 0}
							value={cargoPort.estimatedLoadingRate}
							onChange={(value) => handleUpdate('estimatedLoadingRate', value)}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name="estimatedTimeAllowed"
						label="Est. L/D Time"
						valuePropName="v"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							tabIndex={20}
							disabled
							placeholder="Est. L/D Time"
							addonAfter={`${cargoPort.laytimeInterval}s`}
							value={round(cargoPort.estimatedTimeAllowed, 2)}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name="holidaysNotWorked"
						label="Holidays not worked"
						valuePropName="v"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							value={cargoPort.holidaysNotWorked}
							onChange={(value) => handleUpdate('holidaysNotWorked', value)}
							tabIndex={21}
							addonAfter={cargoPort.laytimeInterval}
							placeholder={cargoPort.laytimeInterval}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name="holidaysWorked"
						label="Holidays worked"
						valuePropName="v"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							value={cargoPort.holidaysWorked}
							onChange={(value) => handleUpdate('holidaysWorked', value)}
							tabIndex={22}
							addonAfter={cargoPort.laytimeInterval}
							placeholder={cargoPort.laytimeInterval}
						/>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item
						name="timeToCount"
						label="Time to count"
						valuePropName="v"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<Input
							key={cargoPort.id}
							value={round(cargoPort.timeToCount, 2)}
							tabIndex={23}
							disabled
							placeholder={`${cargoPort.laytimeInterval}s`}
							addonAfter={`${cargoPort.laytimeInterval}s`}
						/>
					</Form.Item>
				</Col>
				{(cargoPort.estimatedDespatch > 0 || cargoPort.estimatedDemurrage > 0) ? (
					<Col span={12}>
						{/* Only one can be larger than zero - either demurrage or despatch */}
						{
							cargoPort.estimatedDemurrage > 0 && (
								<Form.Item
									name="estimatedDemurrage"
									label="Est. Demurrage"
									valuePropName="v"
									colon={false}
									className={styles.formItemLabel}
									labelCol={{ span: 24 }}
								>
									<NumericInput
										key={cargoPort.id}
										value={round(cargoPort.estimatedDemurrage, 2)}
										tabIndex={24}
										disabled
										addonAfter={cargo.currency}
										placeholder="Est demurrage"
									/>
								</Form.Item>
							)
						}
						{
							cargoPort.estimatedDespatch > 0 && (
								<Form.Item
									name="estimatedDespatch"
									label="Est. Despatch"
									valuePropName="v"
									colon={false}
									className={styles.formItemLabel}
									labelCol={{ span: 24 }}
								>
									<NumericInput
										key={cargoPort.id}
										value={round(cargoPort.estimatedDespatch, 2)}
										tabIndex={25}
										disabled
										addonAfter={cargo.currency}
										placeholder="Est despatch"
									/>
								</Form.Item>
							)
						}
					</Col>
				) : (
					<Col span={12}>
						<Form.Item
							name=""
							label="Est. Demurrage / Despatch"
							valuePropName="v"
							colon={false}
							className={styles.formItemLabel}
							labelCol={{ span: 24 }}
						>
							<Input
								key={cargoPort.id}
								tabIndex={26}
								disabled
								value="No estimated demurrage or despatch"
								placeholder="No estimated demurrage or despatch"
							/>
						</Form.Item>
					</Col>
				)}
				<Col span={12}>
					<Form.Item
						name="formattedTimeGainedOrLost"
						label={`Laytime ${
							cargoPort.laytimeLostOrGained != null && cargoPort?.laytimeLostOrGained > 0 ?
								'Gained' :
								'Lost'
						}`}
						valuePropName="v"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							value={round(Math.abs(cargoPort.laytimeLostOrGained), 2)}
							tabIndex={27}
							disabled
							addonAfter={`${cargoPort.laytimeInterval}s`}
							placeholder="Laytime"
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name="idleDays"
						label={`Idle ${cargoPort.laytimeInterval}s`}
						valuePropName="v"
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
						colon={false}
					>
						<NumericInput
							key={cargoPort.id}
							value={cargoPort.idleDays}
							onChange={(value) => handleUpdate('idleDays', value)}
							tabIndex={28}
							addonAfter={cargoPort.laytimeInterval}
							placeholder={`Idle ${cargoPort.laytimeInterval}`}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name="totalTimeInPort"
						label="Total time in port"
						valuePropName="v"
						colon={false}
						className={styles.formItemLabel}
						labelCol={{ span: 24 }}
					>
						<NumericInput
							key={cargoPort.id}
							value={round(cargoPort.totalTimeInPort, 2)}
							tabIndex={29}
							disabled
							addonAfter={`${cargoPort.laytimeInterval}s`}
							placeholder="Total time in port"
						/>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

export default EstimatedCargoPortForm;
