import React, {
	useEffect,
	useState,
} from 'react';
import {
	Drawer,
	Form,
} from 'antd';
import { Moment } from 'moment';
import {
	CrewReportTypes,
	Currencies,
	FuelTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { ensureNegative } from '@shared/utils/math';
import type { RobProps } from '@api/models/rob';
import type { RobBunkerType } from '@api/models/rob-bunker';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { Port } from '@api/utils/ports';
import {
	createRobs,
	updateRob,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import { EditValues } from '@client/screens/fleet/VoyageDetailsScreen/tabs/BunkerExpenditureTab/BunkerExpenditureTab';
import RobEntryFormItems from './RobEntryFormItems';

export type BunkerRecord = {
	id: number;
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon?: number;
	adjustment?: number;
}

export type NullableBunkerRecord = {
	quantity: number | null;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon?: number;
}

type Props = {
	open: boolean;
	setRobDrawerOpen: (state: boolean) => void;
	fixtureCurrency: Values<typeof Currencies>;
	refreshData: () => void;
	vesselId: number;
	editing: EditValues | null;
	voyageDetails: GetVoyageDetailsResponse;
	setEditing: (record: RobBunkerType | null) => void;
	acceptableFuels: Array<Values<typeof FuelTypes>>;
}

const RobEntryDrawer = ({
	open,
	setRobDrawerOpen,
	fixtureCurrency,
	vesselId,
	editing,
	setEditing,
	refreshData,
	voyageDetails,
	acceptableFuels,
} : Props) => {
	const [form] = Form.useForm<{
		date: Moment;
		event: Values<typeof CrewReportTypes>;
		port: Port | string;
	}>();
	const [eventType, setEventType] = useState<null | Values<typeof CrewReportTypes>>(null);
	const [remainingOnBoard, setRemainingOnBoard] = useState<NullableBunkerRecord[]>([]);
	const [bunkersReceived, setBunkersReceived] = useState<NullableBunkerRecord[]>([]);
	const [selectedDate, setSelectedDate] = useState<Moment | null>(null);

	useEffect(() => {
		if (editing != null) {
			if (editing.robs != null) {
				const vals = editing.robs.map((r) => ({
					id: r.id,
					fuelGrade: r.fuelGrade,
					quantity: r.totalQuantity,
					pricePerTon: r.fuelQueue?.[0]?.pricePerTon,
				}));

				setRemainingOnBoard(vals);
			}

			if (editing.event != null) {
				setEventType(editing.event);
			}

			if (editing.quantity != null && editing.fuelGrade != null) {
				setRemainingOnBoard([{ quantity: editing.quantity, fuelGrade: editing.fuelGrade }]);
			}

			if (editing.VesselBunkers != null) {
				setBunkersReceived(editing.VesselBunkers.map((vb) => vb.Bunker));
			}
		} else {
			const fuelTypeToUse = acceptableFuels?.[0] ?? FuelTypes.VLSFO;
			setRemainingOnBoard([{ quantity: null, fuelGrade: fuelTypeToUse, pricePerTon: 0 }]);
			setBunkersReceived([{ quantity: null, fuelGrade: fuelTypeToUse, pricePerTon: 0 }]);
		}
	}, [acceptableFuels, editing]);

	const deleteEntry = (rob: boolean, record: NullableBunkerRecord): void => {
		const arr: NullableBunkerRecord[] = rob ? remainingOnBoard : bunkersReceived;

		const indexOfEntry = arr?.findIndex((entry) => {
			return ((entry.fuelGrade === record.fuelGrade) &&
			(entry.quantity === record.quantity) &&
			(entry.pricePerTon === record.pricePerTon));
		});

		if (arr && indexOfEntry != null) {
			if (rob) {
				remainingOnBoard.splice(indexOfEntry, 1);
				setRemainingOnBoard([...remainingOnBoard]);
			} else {
				bunkersReceived.splice(indexOfEntry, 1);
				setBunkersReceived([...bunkersReceived]);
			}
		}
	};

	const onClose = async () => {
		await refreshData();
		setRobDrawerOpen(false);
		form.resetFields();
		setRemainingOnBoard([]);
		setBunkersReceived([]);
		setEditing(null);
		setEventType(null);
		setSelectedDate(null);
	};

	const onAddNewBunkerRob = async (values: Pick<RobProps, 'event' | 'port' | 'date'>) => {
		const {
			event,
			port,
			date,
		} = values;

		const filteredEntries = remainingOnBoard.filter(
			(entry): entry is BunkerRecord => entry.quantity !== null,
		);

		const filteredBunkersReceived = bunkersReceived.filter(
			(entry): entry is BunkerRecord => entry.quantity !== null,
		);

		await createRobs({
			vesselId,
			voyageId: voyageDetails.id,
			event: event ?? editing?.event,
			port: port ?? editing?.port ?? null,
			date: date ?? editing?.date,
			currency: fixtureCurrency,
			robs: filteredEntries,
			bunkersReceived: filteredBunkersReceived,
		});
	};

	const onSave = async () => {
		if (editing != null && editing.robId != null) {
			let transformedBunkersReceived = bunkersReceived
				.filter((b) => b.quantity != null);

			if (editing.event === CrewReportTypes.REDELIVERY) {
				transformedBunkersReceived = bunkersReceived.map((b) => ({
					...b,
					quantity: ensureNegative(b.quantity ?? 0),
				}));
			}

			const attributes = {
				currency: fixtureCurrency,
				bunkersReceived: transformedBunkersReceived,
				remainingOnBoard: remainingOnBoard.length > 1 ?
					remainingOnBoard as BunkerRecord[] :
					remainingOnBoard[0] as BunkerRecord,
			};

			await updateRob({
				robId: editing.robId,
				bunkerId: editing.bunkerId,
				robBunkerId: editing.robBunkerId,
				vesselId,
				voyageId: voyageDetails.id,
				attributes,
				currency: fixtureCurrency,
				date: selectedDate ?? undefined,
				port: form.getFieldValue('port'),
				event: editing.event,
				deleteMissingEntries: true,
			});

			await onClose();

			return;
		}

		try {
			if (editing?.event !== CrewReportTypes.COMMENCEMENT) {
				await form.validateFields();
			}

			const values = form.getFieldsValue();
			await onAddNewBunkerRob({ ...values, port: values.port as Port });
			await onClose();
		} catch (e) {
			showErrorNotification('Could not create ROB entry', e as Error);
		}
	};

	useEffect(() => {
		if (editing != null) {
			const transformedValues = {
				event: editing.event,
				port: editing?.port?.name,
				date: editing.date,
			};

			setSelectedDate(editing.date ?? null);

			form.setFieldsValue(transformedValues);
		}
	}, [editing, form]);

	return (
		<Drawer
			open={open || editing != null}
			title={editing == null ? 'New ROB Entry' : 'Update entry'}
			width={500}
			onClose={() => {
				setEditing(null);
				setRemainingOnBoard([]);
				setBunkersReceived([]);
				setRobDrawerOpen(false);
				form.resetFields();
				setEventType(null);
				setSelectedDate(null);
			}}
		>
			<Form
				layout="vertical"
				form={form}
				onFieldsChange={(fields) => {
					const field = fields[0];

					if (field.name[0] === 'date') {
						setSelectedDate(field.value);
					}

					if (field.name[0] === 'event') {
						setEventType(field.value);
					}
				}}
			>
				<RobEntryFormItems
					fixtureCurrency={fixtureCurrency}
					eventType={eventType}
					onSaveRob={onSave}
					onDeleteRob={deleteEntry}
					newRobs={remainingOnBoard}
					setNewRobs={setRemainingOnBoard}
					bunkersReceived={bunkersReceived}
					setBunkersReceived={setBunkersReceived}
					selectedDate={selectedDate}
					acceptableFuels={acceptableFuels}
					voyageDetails={voyageDetails}
				/>
			</Form>
		</Drawer>
	);
};

export default RobEntryDrawer;
