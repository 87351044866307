import React from 'react';
import { Typography } from 'antd';
import {
	CrewReportTypes,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import { TimeFormat } from '@shared/utils/date';
import { round } from '@shared/utils/math';
import type { BunkeringReportProps } from '@api/models/bunkering-report';
import { formatDate } from '@client/utils/formatDate';
import Details from '@client/components/Details';
import Table from '@client/components/Table/Table';
import styles
	from '@client/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/styles/NoonReportDrawerContent.module.css';
import Map from '@client/components/Map/Map';
import ReportsLayer from '@client/components/Map/layers/ReportsLayer';

const BunkeringReportDrawerContent = ({
	report,
	selectedTimeFormat,
}: {
	report: BunkeringReportProps;
	selectedTimeFormat?: TimeFormat;
}) => {
	const bunkersReceivedColumns = [
		{
			title: 'Fuel Grade',
			dataIndex: 'fuelType',
			key: 'fuelType',
		},
		{
			title: 'Quantity',
			dataIndex: 'tons',
			key: 'tons',
			render: (tons: number) => (tons != null ? `${round(tons, 3)} tons` : ''),
		},
	];

	const detailItems = [
		{
			key: 'type',
			label: 'Type',
			value: CrewReportTypes.BUNKERING,
		},
		{
			key: 'date',
			label: 'Date',
			value: formatDate(report.date, DATE_AND_TIME, true, selectedTimeFormat),
		},
		{
			key: 'remarks',
			label: 'Remarks',
			value: report.remarks,
			maxHeight: 200,
			style: {
				whiteSpace: 'pre-wrap',
				overflow: 'auto',
			} as React.CSSProperties,
		},
		{
			key: 'port',
			label: 'Port',
			value: report.portName,
		},
		{
			key: 'date',
			label: 'Start of bunkering',
			value: formatDate(report.startOfBunkeringTime, DATE_AND_TIME, true, selectedTimeFormat),
		},
		{
			key: 'date',
			label: 'End of bunkering',
			value: formatDate(report.endOfBunkeringTime, DATE_AND_TIME, true, selectedTimeFormat),
		},
	];

	return (
		<>
			<Typography.Title level={5}>Details</Typography.Title>
			<Details
				items={detailItems}
				hideHeader
			/>
			<br />
			<Typography.Title level={5}>Bunkers received</Typography.Title>
			<Table
				columns={bunkersReceivedColumns}
				dataSource={report.bunkersReceived || []}
				pagination={false}
			/>
			<br />
			<>
				<div className={styles.map}>
					<Map
						defaultPosition={{
							latitude: report.latitude,
							longitude: report.longitude,
						}}
					>
						{({ viewport }) => (
							<ReportsLayer
								reports={[{ ...report, type: CrewReportTypes.BUNKERING }]}
								zoom={viewport.zoom}
								openReportDrawer={undefined}
							/>
						)}
					</Map>
				</div>
			</>
		</>
	);
};

export default BunkeringReportDrawerContent;
