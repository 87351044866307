import React, {
	useEffect,
	useState,
} from 'react';
import PdfViewer from './PdfViewer';
import styles from './styles/FileViewer.module.css';

const FileViewer = ({
	url,
	type,
}: {
	url: string;
	type?: string;
}) => {
	const [content, setContent] = useState<string | null>(null);
	useEffect(() => {
		if (!type?.startsWith('text') || url == null) {
			return;
		}

		(async () => {
			const response = await fetch(url);
			const text = await response.text();

			setContent(text);
		})();
	}, [url, type]);

	if (type === 'application/pdf') {
		return (
			<PdfViewer source={url} />
		);
	}

	if (type?.startsWith('image/')) {
		return (
			<div className={styles.imageWrapper}>
				<img
					src={url}
					alt="File"
					className={styles.image}
				/>
			</div>
		);
	}

	if (type?.startsWith('text')) {
		return (
			<div className={styles.textContent}>
				{content}
			</div>
		);
	}

	return (
		<div>
			The file cannot be previewed.
		</div>
	);
};

export default FileViewer;
