// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eliODBabQ1XBfqn207oA {\n\tpadding: var(--content-padding);\n\tflex: 1;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/screens/styles/RootScreen.module.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,OAAO;CACP,aAAa;CACb,sBAAsB;AACvB","sourcesContent":[".paddingWrapper {\n\tpadding: var(--content-padding);\n\tflex: 1;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paddingWrapper": "eliODBabQ1XBfqn207oA"
};
export default ___CSS_LOADER_EXPORT___;
