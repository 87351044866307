import { FixtureTypes } from '@shared/utils/constants';
import { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import { TcFixtureProps } from '@api/models/tc-fixture';
import getAvailableHireInvoiceItems from './getAvailableHireInvoiceItems';

// voyageDetails and fixtureDetails are the objects returned from
// the getVoyageDetails and getFixtureDetails features respectively
const getAvailableHIIsFromTcDetails = (
	voyageDetails: GetVoyageDetailsResponse,
	fixtureDetails: GetFixtureDetailsResponse<TcFixtureProps>,
	hireInvoices: GetVoyageDetailsResponse['hireInvoices'],
	isHireInvoice: boolean,
) => {
	const {
		completionDate,
		deliveryDate,
		voyageBunkers,
		voyageExpenseReceivables,
		voyageOffHirePeriods,
		readyForCumulative,
		ballastBonusAvailable,
	} = voyageDetails;

	const {
		hireType,
		hireRate,
		hireDescription,
		addressCommission,
		brokers,
		expensesSubjectToHireDays,
		revenuesSubjectToDays,
		calculationMethodForExpensesSubjectToHireDays,
		grossBallastBonus,
		daysPerHirePeriod,
		invoicePerCalenderMonth,
		bankAccount,
		type,
		useUTC,
		hireDaysDecimals,
		hireUnit,
	} = fixtureDetails;

	if (type === FixtureTypes.SPOT) {
		return [];
	}

	// Users cannot fix TC estimates without most of these values - so we assume they are not null
	return getAvailableHireInvoiceItems({
		hireDaysDecimals,
		isHireInvoice,
		commencementDate: deliveryDate,
		completionDate: completionDate!,
		hireInvoices,
		daysPerHirePeriod: daysPerHirePeriod!,
		invoicePerCalenderMonth,
		hireType: hireType!,
		type: type!,
		hireRate: hireRate ?? 0,
		hireDescription,
		expensesSubjectToHireDays,
		revenuesSubjectToDays,
		calculationMethodForExpensesSubjectToHireDays,
		addressCommission: addressCommission!,
		brokers,
		grossBallastBonus,
		ballastBonusAvailable,
		readyForCumulative,
		voyageBunkers,
		voyageExpenseReceivables,
		voyageOffHirePeriods,
		bankAccount,
		useUTC,
		hireUnit,
	});
};

export default getAvailableHIIsFromTcDetails;
