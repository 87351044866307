import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FuelTypes,
} from './constants';
import { formatCurrency } from './currency';

type Props = {
	quantity: number;
	fuelGrade: Values<typeof FuelTypes>;
	pricePerTon?: number | null;
};

const getBunkerDescription = (
	{
		quantity,
		fuelGrade,
		pricePerTon,
	}: Props,
	currency: Values<typeof Currencies>,
) => (
	pricePerTon != null ?
		`${quantity}MT ${fuelGrade} at ${formatCurrency(pricePerTon, currency)}/MT` :
		`${quantity}MT ${fuelGrade}`
);

export default getBunkerDescription;
