// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JhYG9C7wpHgPSweZO5Z_ {\n\theight: 100%;\n\tbackground: white;\n\tpadding: 8px;\n\tborder: 1px solid #f0f0f0;\n\tborder-top: 0;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: stretch;\n}\n\n.JhYG9C7wpHgPSweZO5Z_ > * {\n\tflex: 1;\n}\n\n.JhYG9C7wpHgPSweZO5Z_ .ant-select-selector {\n\theight: 3.5em !important;\n}\n\n.dUystNhRFt2gbCdEH7gf {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/DateFilterDropDown/DateFilterDropDown.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,iBAAiB;CACjB,YAAY;CACZ,yBAAyB;CACzB,aAAa;CACb,aAAa;CACb,mBAAmB;CACnB,wBAAwB;AACzB;;AAEA;CACC,OAAO;AACR;;AAEA;CACC,wBAAwB;AACzB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB","sourcesContent":[".rangeSelectorWrapper {\n\theight: 100%;\n\tbackground: white;\n\tpadding: 8px;\n\tborder: 1px solid #f0f0f0;\n\tborder-top: 0;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: stretch;\n}\n\n.rangeSelectorWrapper > * {\n\tflex: 1;\n}\n\n.rangeSelectorWrapper :global(.ant-select-selector) {\n\theight: 3.5em !important;\n}\n\n.filterDateWrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rangeSelectorWrapper": "JhYG9C7wpHgPSweZO5Z_",
	"filterDateWrapper": "dUystNhRFt2gbCdEH7gf"
};
export default ___CSS_LOADER_EXPORT___;
