import React, { SetStateAction } from 'react';
import {
	CargoUnitSingularLabels,
	FreightRateType,
	LaytimeTerms,
	QuantityOptions,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import type { EditableCargo } from '@api/features/cargos/updateCargo';
import EmptyText from '@client/components/EmptyText';
import styles from '@client/screens/cargos/details/CargoDetailsScreen.module.css';
import TooltipIcon from '@client/components/TooltipIcon';
import { EditableDetailsItem } from '@client/components/EditableField';

export const getCargoDetailsFields = ({
	cargoDetails,
	laytimeTerms,
	setLaytimeTerms,
}: {
	cargoDetails: EditableCargo;
	laytimeTerms: LaytimeTerms;
	setLaytimeTerms: React.Dispatch<SetStateAction<LaytimeTerms>>;
}) => {
	const addonAfterFreightRate = cargoDetails.freightType === FreightRateType.PER_UNIT ? `/ ${CargoUnitSingularLabels[cargoDetails.unit]}` : '';

	const getDemurrageDespatchFields = () => {
		if (cargoDetails == null) {
			return [];
		}

		if (laytimeTerms === LaytimeTerms.AVERAGED) {
			return [
				{
					key: 'demurrageLoading',
					label: 'Loading Demurrage',
					value: cargoDetails.demurrageLoading,
					type: 'currency',
					inputProps: {
						currency: cargoDetails.currency,
					},
					editable: true,
					render: () => (
						<>
							{cargoDetails.demurrageLoading != null ?
								`${formatCurrency(cargoDetails.demurrageLoading, cargoDetails.currency)} / day` :
								(<EmptyText />)}
						</>
					),
				},
				{
					key: 'despatchLoading',
					label: 'Loading Despatch',
					value: cargoDetails.despatchLoading,
					type: 'currency',
					inputProps: {
						currency: cargoDetails.currency,
					},
					editable: true,
					render: () => (
						<>
							{cargoDetails.despatchLoading != null ?
								`${formatCurrency(cargoDetails.despatchLoading, cargoDetails.currency)} / day` :
								(<EmptyText />)}
						</>
					),
				},
				{
					key: 'demurrageDischarge',
					label: 'Discharge Demurrage',
					value: cargoDetails.demurrageDischarge,
					type: 'currency',
					inputProps: {
						currency: cargoDetails.currency,
					},
					editable: true,
					render: () => (
						<>
							{cargoDetails.demurrageDischarge != null ?
								`${formatCurrency(cargoDetails.demurrageDischarge, cargoDetails.currency)} / day` :
								(<EmptyText />)}
						</>
					),
				},
				{
					key: 'despatchDischarge',
					label: 'Discharge Despatch',
					value: cargoDetails.despatchDischarge,
					type: 'currency',
					inputProps: {
						currency: cargoDetails.currency,
					},
					editable: true,
					render: () => (
						<>
							{cargoDetails.despatchDischarge != null ?
								`${formatCurrency(cargoDetails.despatchDischarge, cargoDetails.currency)} / day` :
								(<EmptyText />)}
						</>
					),
				},
			];
		}

		let demurrageLabel: string | React.ReactElement = 'Demurrage rate';
		let despatchLabel: string | React.ReactElement = 'Despatch rate';

		if (laytimeTerms === LaytimeTerms.NON_REVERSIBLE) {
			demurrageLabel = (
				<div className={styles.tooltipContainer}>
					<p>Demurrage rate</p>
					<TooltipIcon>
						When laytime terms is non-reversible, refer to cargo-ports for demurrage rates
					</TooltipIcon>
				</div>
			);

			despatchLabel = (
				<div className={styles.tooltipContainer}>
					<p>Despatch rate</p>
					<TooltipIcon>
						When laytime terms is non-reversible, refer to cargo-ports for despatch rates
					</TooltipIcon>
				</div>
			);
		}

		return [
			{
				key: 'demurrage',
				label: demurrageLabel,
				value: cargoDetails.demurrage,
				type: 'currency',
				inputProps: {
					currency: cargoDetails.currency,
					disabled: laytimeTerms === LaytimeTerms.NON_REVERSIBLE,
				},
				editable: true,
				render: () => (
					<>
						{cargoDetails.demurrage != null ?
							`${formatCurrency(cargoDetails.demurrage, cargoDetails.currency)} / day` :
							(<EmptyText />)}
					</>
				),
			},
			{
				key: 'despatch',
				label: despatchLabel,
				value: cargoDetails.despatch,
				type: 'currency',
				inputProps: {
					currency: cargoDetails.currency,
					disabled: laytimeTerms === LaytimeTerms.NON_REVERSIBLE,
				},
				editable: true,
				render: () => (
					<>
						{cargoDetails.despatch != null ?
							`${formatCurrency(cargoDetails.despatch, cargoDetails.currency)} / day` :
							(<EmptyText />)}
					</>
				),
			},
		];
	};

	return (
		[
			{
				key: 'addressCommission',
				label: 'Address Commission',
				value: cargoDetails.addressCommission,
				type: 'percentage',
				editable: true,
			},
			{
				key: 'quantityTolerance',
				label: 'Quantity Tolerance',
				value: cargoDetails.quantityTolerance,
				type: 'percentage',
				editable: true,
			},
			{
				key: 'quantityOption',
				label: 'Quantity Option',
				type: 'select',
				value: cargoDetails.quantityOption,
				options: Object.values(QuantityOptions).map((c) => ({
					label: QuantityOptions[c],
					value: c,
				})),
				inputProps: {
					placeholder: 'Select Option',
				},
				render: () => (
					<>
						{cargoDetails.quantityOption != null ?
							QuantityOptions[cargoDetails.quantityOption] : (<EmptyText />)}
					</>
				),
				editable: true,
			},
			{
				key: 'overageAmount',
				label: 'Overage Rate',
				type: 'currency',
				value: cargoDetails.overageAmount,
				inputProps: {
					currency: cargoDetails.currency,
				},
				editable: true,
				render: () => (
					<>
						{cargoDetails.overageAmount != null ?
							`${formatCurrency(cargoDetails.overageAmount, cargoDetails.currency)}
									${addonAfterFreightRate}
								` : (<EmptyText />)}
					</>
				),
			},
			{
				key: 'deadfreightAmount',
				label: 'Deadfreight Rate',
				type: 'currency',
				value: cargoDetails.deadfreightAmount,
				inputProps: {
					currency: cargoDetails.currency,
				},
				editable: true,
				render: () => (
					<>
						{cargoDetails.deadfreightAmount != null ?
							`${formatCurrency(cargoDetails.deadfreightAmount, cargoDetails.currency)}
										${addonAfterFreightRate}` : (<EmptyText />)}
					</>
				),
			},
			{
				key: 'laytimeTerms',
				label: 'Laytime type',
				value: laytimeTerms,
				type: 'select',
				options: Object.values(LaytimeTerms).map((c) => ({
					label: c,
					value: c,
				})),
				editable: true,
				inputProps: {
					placeholder: 'Select Type',
					onChange: (v: LaytimeTerms) => setLaytimeTerms(v),
				},
				render: () => (
					<>
						{cargoDetails.laytimeTerms != null ? cargoDetails.laytimeTerms : (<EmptyText />)}
					</>
				),
			},
			...getDemurrageDespatchFields(),
		] as EditableDetailsItem<any>[]
	);
};
