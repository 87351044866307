// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XvrkxGfwRj9qvEQARDr8 {\n\tbackground-color: #52c41a;\n\tborder-color: #52c41a;\n\tcolor: white;\n}\n\n.XvrkxGfwRj9qvEQARDr8:hover,\n.XvrkxGfwRj9qvEQARDr8:focus {\n\tbackground-color: #73d13d;\n\tborder-color: #73d13d;\n\tcolor: white;\n}\n\n.hmHlLbf1SBGm3cJw2bOh {\n\tdisplay: block;\n\tcolor: darkgray;\n\tfont-size: 2em;\n}\n\n.Q7jQxFxt9JPbcnpUryq4 {\n\tcolor: lightgray;\n\tfont-size: 4em;\n\tmargin-top: 12px;\n\tmargin-bottom: 24px;\n}\n\n.StZ8_dk3ndsx6HUPYc_I {\n\talign-items: center;\n\tdisplay: flex;\n}\n\n.StZ8_dk3ndsx6HUPYc_I > .ant-typography {\n\tmargin: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/financials/ExpenseApprovalsScreen/ExpenseApprovalsScreen.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,qBAAqB;CACrB,YAAY;AACb;;AAEA;;CAEC,yBAAyB;CACzB,qBAAqB;CACrB,YAAY;AACb;;AAEA;CACC,cAAc;CACd,eAAe;CACf,cAAc;AACf;;AAEA;CACC,gBAAgB;CAChB,cAAc;CACd,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;CACnB,aAAa;AACd;;AAEA;CACC,SAAS;AACV","sourcesContent":[".approveButton {\n\tbackground-color: #52c41a;\n\tborder-color: #52c41a;\n\tcolor: white;\n}\n\n.approveButton:hover,\n.approveButton:focus {\n\tbackground-color: #73d13d;\n\tborder-color: #73d13d;\n\tcolor: white;\n}\n\n.emptyTitle {\n\tdisplay: block;\n\tcolor: darkgray;\n\tfont-size: 2em;\n}\n\n.emptyIcon {\n\tcolor: lightgray;\n\tfont-size: 4em;\n\tmargin-top: 12px;\n\tmargin-bottom: 24px;\n}\n\n.listItemTitle {\n\talign-items: center;\n\tdisplay: flex;\n}\n\n.listItemTitle > :global(.ant-typography) {\n\tmargin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"approveButton": "XvrkxGfwRj9qvEQARDr8",
	"emptyTitle": "hmHlLbf1SBGm3cJw2bOh",
	"emptyIcon": "Q7jQxFxt9JPbcnpUryq4",
	"listItemTitle": "StZ8_dk3ndsx6HUPYc_I"
};
export default ___CSS_LOADER_EXPORT___;
