const isItemPortCall = <
	PC extends { isPortCall: true },
	SP extends { isSeaPassage: true },
>(
	item: SP | PC,
): item is PC => {
	return (item as PC).isPortCall;
};

export default isItemPortCall;
