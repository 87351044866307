import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Flex,
	Grid,
	Row,
} from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { splitActionKey } from '@shared/utils/splitActionKey';
import {
	CargoUnitLabels,
	EstimateStatus,
	LaytimeTerms,
} from '@shared/utils/constants';
import { formatNumber } from '@shared/utils/formatNumber';
import type { GetCargoDetailsResponse } from '@api/features/cargos/getCargoDetails';
import type { CargoPortProps } from '@api/models/cargo-port';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import EstimatedItemsCard from '@client/screens/cargos/components/EstimatedItemsCard/EstimatedItemsCard';
import { useCargoHandlers } from '@client/screens/estimates/details/context/hooks/useCargoHandlers';
import SavingIndicator from '@client/components/SavingIndicator';
import CargoKeyDetails from './CargoKeyDetails';
import { CargoPortsDetails } from './CargoPortsDetails';

export type CargoPortObj = { [s: string]: CargoPortProps }

const getLoadedAndDischarged = (fields: CargoPortObj) => {
	const obj = Object.entries(fields);

	return obj.reduce((acc, [key, values]) => {
		const { action } = splitActionKey(key);

		if (values == null) {
			return acc;
		}

		if (action === 'loading') {
			return {
				...acc,
				loaded: acc.loaded + (values.cpQuantity ?? 0),
			};
		}

		return {
			...acc,
			discharged: acc.discharged + (values.cpQuantity ?? 0),
		};
	}, { loaded: 0, discharged: 0 });
};

const getCargoDescription = (cargo: GetCargoDetailsResponse | undefined) => {
	let string = '';

	if (cargo == null || (cargo.quantity == null && cargo.type == null)) {
		return 'Unknown cargo';
	}

	if (cargo.quantity != null) {
		string += `${formatNumber(cargo.quantity, { separateThousands: true })} ${CargoUnitLabels[cargo.unit]} `;
	}

	if (cargo.type != null) {
		string += `${cargo.type} `;
	}

	if (cargo.charterer != null && cargo.chartererName != null) {
		string += `for ${cargo.chartererName}`;
	}

	return string;
};

// Props used when containing CargoDetailsScreen in modal (CargoDetailsModal)
type Props = {
	cargoId?: number;
	inEstimate?: boolean;
	isTceLocked?: boolean;
	selectedEstimateId?: number;
	blockClose?: boolean;
	setBlockClose?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CargosDetailsScreen = ({
	cargoId,
	isTceLocked,
	inEstimate,
	blockClose,
	setBlockClose,
	selectedEstimateId,
}: Props) => {
	const { id } = useParams<{ id: string }>();
	const parsedCargoId = inEstimate ? cargoId : Number(id);
	const [laytimeTerms, setLaytimeTerms] = useState(LaytimeTerms.NON_REVERSIBLE);
	const history = useHistory();
	const screens = Grid.useBreakpoint();

	const {
		cargo: cargoDetails,
		cargoPorts,
		onUpdateCargo,
		onUpdateCargoPort,
		onDeleteCargoMutator,
		pendingChanges,
	} = useCargoHandlers({
		cargoId: parsedCargoId,
		selectedEstimateId: selectedEstimateId ?? null,
	});

	useEffect(() => {
		setBlockClose?.(pendingChanges);
	}, [pendingChanges, blockClose, setBlockClose]);

	const { loaded, discharged } = useMemo(() => {
		if (cargoDetails != null && cargoDetails.cargoPorts != null) {
			return getLoadedAndDischarged(cargoDetails.cargoPorts);
		}

		return {
			loaded: 0,
			discharged: 0,
		};
	}, [cargoDetails]);

	const quantitiesRemaining = useMemo(() => {
		if (cargoDetails == null) {
			return null;
		}

		const loadedRemaining = cargoDetails.quantity - loaded;
		const dischargeRemaining = loaded - discharged;

		return {
			loadedRemaining,
			dischargeRemaining,
		};
	}, [cargoDetails, loaded, discharged]);

	const loadingPorts = useMemo(() => {
		if (cargoPorts == null) {
			return [];
		}

		return cargoPorts
			.filter((cp) => {
				const { action } = splitActionKey(cp.portAndActionKey);

				return action === 'loading';
			}).map((cp) => ({
				...cp,
				key: cp.id,
			}));
	}, [cargoPorts]);

	const dischargePorts = useMemo(() => {
		if (cargoPorts == null) {
			return [];
		}

		return cargoPorts
			.filter((cp) => {
				const { action } = splitActionKey(cp.portAndActionKey);

				return action === 'discharging';
			})
			.map((cp) => ({
				...cp,
				key: cp.id,
			}));
	}, [cargoPorts]);

	const sortedCargoPorts = [
		...loadingPorts.sort((a, b) => a.id - b.id),
		...dischargePorts.sort((a, b) => a.id - b.id),
	];

	return (
		<SimpleScreen
			title={getCargoDescription(cargoDetails)}
			breadcrumbs={
				[[
					'Cargos',
					Links.Cargos.get(),
				]]
			}
			headerActions={[
				(
					<Flex
						gap={10}
					>
						<SavingIndicator spinning={pendingChanges} />
						<Button
							danger
							confirmTitle="Are you sure you want to delete this cargo?"
							disabled={cargoDetails?.status === EstimateStatus.Fixed}
							disabledTooltip="You cannot remove cargos that are attached to a fixed estimate"
							onClick={() => {
								onDeleteCargoMutator.mutate(cargoId ?? Number(id), {
									onSuccess: () => {
										history.replace(Links.Cargos.get());
									},
								});
							}}
						>
							Delete Cargo
						</Button>
					</Flex>

				),
			]}
			canGoBack
			rootPageTitle="Cargos"
		>
			<Row gutter={[16, 16]}>
				<Col span={screens.lg ? 12 : 24}>
					<CargoKeyDetails
						refreshCargoDetails={() => null}
						handleChange={onUpdateCargo}
						cargoDetails={cargoDetails}
						laytimeTerms={laytimeTerms}
						setLaytimeTerms={setLaytimeTerms}
						isTceLocked={isTceLocked}
					/>
				</Col>
				<Col span={screens.lg ? 12 : 24}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<CargoPortsDetails
								handleChange={onUpdateCargoPort}
								cargoPorts={cargoPorts ?? []}
								sortedCargoPorts={sortedCargoPorts}
								cargoDetails={cargoDetails}
								laytimeTerms={laytimeTerms}
								quantitiesRemaining={quantitiesRemaining}
								refreshDetails={() => null}
							/>
						</Col>
						<Col span={24}>
							<EstimatedItemsCard
								handleChange={onUpdateCargo}
								cargoDetails={cargoDetails}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default CargosDetailsScreen;
