import React from 'react';
import {
	Col,
	Row,
	Typography,
} from 'antd';
import classNames from 'classnames';
import {
	Currencies,
	LaytimeIntervals,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import { formatLaytimeDuration } from '@shared/utils/date';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import type { GetEstimatedDespatchAndDemurrageResponse } from '@api/features/estimates/getEstimatedDespatchAndDemurrage';
import StatisticCard from '@client/components/StatisticCard';
import styleColorPicker
	from '@client/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/utils/styleColorPicker';
import styles from '@client/screens/fleet/VoyageDetailsScreen/tabs/components/Stats.module.css';

type Props = {
	stats: GetCargosAndLaytimesResponse[number]['stats'];
	currency: Values<typeof Currencies>;
	demurrageRate: number | null | undefined;
	despatchRate: number | null | undefined;
	interval: LaytimeIntervals;
	estimatedDemDes?: GetEstimatedDespatchAndDemurrageResponse[number] | null;
}

const ReversibleStats = ({
	stats,
	currency,
	demurrageRate,
	despatchRate,
	interval,
	estimatedDemDes,
}: Props) => {
	const {
		totalDemurrage,
		totalDespatch,
		totalTimeDiff,
		totalReceivable,
	} = stats;

	const isDem = (totalDemurrage ?? 0) > (totalDespatch ?? 0);
	const isZero = isDem ?
		(totalDemurrage == null || totalDemurrage === 0) :
		(totalDespatch == null || totalDespatch === 0);

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col>
					<StatisticCard
						items={[{
							label: `Total Time ${totalTimeDiff != null && totalTimeDiff < 0 ? 'Lost' : 'Gained'}`,
							children: totalTimeDiff === 0 ? 'N/A' : formatLaytimeDuration(Math.abs(totalTimeDiff ?? 0), interval, 3),
							childColor: styleColorPicker(totalTimeDiff, true).color,
						}]}
						customStyles={{
							maxWidth: '300px',
						}}
					/>
				</Col>
				<Col>
					<StatisticCard
						items={[{
							label: `Total ${isDem ? 'Demurrage' : 'Despatch'}`,
							children: (
								<>
									<Typography.Text
										className={classNames({
											[styles.neutralTotalLabel]: isZero,
											[styles.positiveTotalLabel]: isDem,
											[styles.negativeTotalLabel]: !isDem,
										})}
									>
										{formatCurrency(
											isDem ?
												(totalDemurrage ?? 0) :
												(totalDespatch ?? 0),
											currency,
										)}
									</Typography.Text>
									<Typography.Text
										className={styles.rateLabel}
									>
										(
										{formatCurrency(isDem ?
											demurrageRate ?? 0 :
											despatchRate ?? 0,
										currency)}
										{' '}
										/ day)
									</Typography.Text>
								</>),
						}]}
						customStyles={{
							maxWidth: '300px',
						}}
					/>
				</Col>
				<Col>
					<StatisticCard
						items={[{
							label: `Total ${totalReceivable != null && totalReceivable >= 0 ? 'Receivable' : 'Payable'}`,
							children: formatCurrency(Math.abs(totalReceivable ?? 0), currency),
							childColor: styleColorPicker(totalReceivable).color,
						}]}
						customStyles={{
							maxWidth: '300px',
						}}
					/>
				</Col>
				{estimatedDemDes != null && (
					<>
						<Col>
							<StatisticCard
								items={[{
									label: `Estimated Time ${estimatedDemDes?.timeDifference != null && estimatedDemDes?.timeDifference < 0 ? 'Lost' : 'Gained'}`,
									children: estimatedDemDes?.timeDifference === 0 ? 'N/A' : formatLaytimeDuration(Math.abs(estimatedDemDes?.timeDifference ?? 0) * 1440, interval, 4),
									childColor: styleColorPicker(estimatedDemDes?.timeDifference ?? 0, true).color,
								}]}
								customStyles={{
									maxWidth: '300px',
								}}
							/>
						</Col>
						<Col>
							<StatisticCard
								items={[{
									label: 'Estimated Total Demurrage',
									children: formatCurrency(
										Math.abs(estimatedDemDes?.estimatedDemurrage ?? 0),
										currency,
										{ forceDecimals: true },
									),
									childColor: styleColorPicker(estimatedDemDes?.estimatedDemurrage, true).color,
								}]}
								customStyles={{
									maxWidth: '300px',
								}}
							/>
						</Col>
						<Col>
							<StatisticCard
								items={[{
									label: 'Estimated Total Despatch',
									children: formatCurrency(
										Math.abs(estimatedDemDes?.estimatedDespatch ?? 0),
										currency,
										{ forceDecimals: true },
									),
									childColor: styleColorPicker(estimatedDemDes?.estimatedDespatch, true).color,
								}]}
								customStyles={{
									maxWidth: '300px',
								}}
							/>
						</Col>
					</>
				)}
			</Row>
		</>
	);
};

export default ReversibleStats;
