// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nB6YCWMdYlXKDLM_8VlG {\n\tpadding: 0;\n\tpadding-left: 1px;\n\twidth: auto;\n\tline-height: 1;\n\tcursor: help;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/TooltipIcon.module.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,iBAAiB;CACjB,WAAW;CACX,cAAc;CACd,YAAY;AACb","sourcesContent":[".helpButton {\n\tpadding: 0;\n\tpadding-left: 1px;\n\twidth: auto;\n\tline-height: 1;\n\tcursor: help;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpButton": "nB6YCWMdYlXKDLM_8VlG"
};
export default ___CSS_LOADER_EXPORT___;
