// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vvpdjd79srSsmOl_OGuQ {\n\tmargin-left: 8px;\n\tmargin-bottom: -6px;\n}\n\n.xkSv02nQ0gPKVFko41eb {\n\tmargin-right: 6px;\n\tmargin-bottom: 6px;\n}\n\n.j1seh4JSLm6TA7DHvGtd {\n\tmin-width: 180px;\n\tpadding-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/TagList.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;AAClB","sourcesContent":[".list {\n\tmargin-left: 8px;\n\tmargin-bottom: -6px;\n}\n\n.tag {\n\tmargin-right: 6px;\n\tmargin-bottom: 6px;\n}\n\n.input {\n\tmin-width: 180px;\n\tpadding-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "vvpdjd79srSsmOl_OGuQ",
	"tag": "xkSv02nQ0gPKVFko41eb",
	"input": "j1seh4JSLm6TA7DHvGtd"
};
export default ___CSS_LOADER_EXPORT___;
