import { sortByDates } from '@shared/utils/sortByDates';
import { LaytimeIntervals } from '@shared/utils/constants';
import { splitActionKey } from '@shared/utils/splitActionKey';
import { round } from '@shared/utils/math';
import type {
	CargoStats,
	CargoWithPortsAndCalculationsFromDb,
} from '@api/features/laytime/getCargosAndLaytimes';
import { CargoPortProps } from '@api/models/cargo-port';
import { LaytimeCalculationProps } from '@api/models/laytime-calculation';
import { CargoProps } from '@api/models/cargo';

export const calculateReversibleLaytime = (
	cargo: CargoWithPortsAndCalculationsFromDb,
	stats: CargoStats,
) => {
	let totalDemurrage = 0;
	let totalDespatch = 0;

	const blTimeAllowed = cargo.CargoPorts.reduce(
		(acc, c) => {
			if (c.LaytimeCalculations.length === 0) {
				return acc;
			}

			const isHour = c.laytimeInterval === LaytimeIntervals.HOUR;

			return acc + ((c.blTimeAllowed ?? 0) * (isHour ? 60 : 1440));
		},
		0,
	);

	const timeUsed = cargo.CargoPorts.reduce((acc, c) => {
		if (c.LaytimeCalculations.length === 0) {
			return acc;
		}

		const newestLaytimeCalc = sortByDates(c.LaytimeCalculations, 'createdAt', { newestFirst: true })[0];

		return acc + (newestLaytimeCalc.timeUsedInMinutes ?? 0);
	}, 0);

	const diff = (blTimeAllowed - timeUsed) / 60 / 24;

	if (diff < 0) {
		totalDemurrage = diff * (cargo.demurrage ?? 0);
	}

	if (diff > 0) {
		totalDespatch = diff * (cargo.despatch ?? 0);
	}

	return {
		...stats,
		totalDemurrage: round(Math.abs(totalDemurrage), 2),
		totalDespatch: round(Math.abs(totalDespatch), 2),
		totalReceivable: round(Math.abs(totalDemurrage) - Math.abs(totalDespatch), 2),
		totalTimeDiff: blTimeAllowed - timeUsed,
	};
};

export const calculateNonReversibleLaytime = (
	cargo: CargoWithPortsAndCalculationsFromDb,
	stats: CargoStats,
) => {
	const {
		totalDemurrage,
		totalDespatch,
		totalTimeAllowed,
		totalTimeUsed,
	} = cargo.CargoPorts.reduce((acc, c) => {
		if (c.LaytimeCalculations.length === 0) {
			return acc;
		}

		const isHour = c.laytimeInterval === LaytimeIntervals.HOUR;

		const blTimeAllowed = (c.blTimeAllowed ?? 0) * (isHour ? 60 : 1440);
		const timeUsed = sortByDates(
			c.LaytimeCalculations,
			'createdAt', { newestFirst: true },
		)[0]?.timeUsedInMinutes ?? 0;

		const diff = (blTimeAllowed - timeUsed) / 60 / (isHour ? 1 : 24);
		const dem = diff < 0 ? diff * (c.demurrage ?? 0) : 0;
		const des = diff > 0 ? diff * (c.despatch ?? 0) : 0;

		return {
			totalDemurrage: dem + acc.totalDemurrage,
			totalDespatch: des + acc.totalDespatch,
			totalTimeUsed: timeUsed + acc.totalTimeUsed,
			totalTimeAllowed: blTimeAllowed + acc.totalTimeAllowed,
		};
	}, {
		totalDemurrage: 0,
		totalDespatch: 0,
		totalTimeUsed: 0,
		totalTimeAllowed: 0,
	});

	return {
		...stats,
		totalDemurrage: round(Math.abs(totalDemurrage), 2),
		totalDespatch: round(Math.abs(totalDespatch), 2),
		totalReceivable: round(Math.abs(totalDemurrage) - Math.abs(totalDespatch), 2),
		totalTimeDiff: totalTimeAllowed - totalTimeUsed,
	};
};

export type CargoPortsWithCalcs = Array<CargoPortProps & {
	LaytimeCalculations: Array<LaytimeCalculationProps>;
}>;

export const calculateAveragedLaytime = (
	cargo: CargoProps & { CargoPorts: CargoPortsWithCalcs },
	stats: CargoStats,
	cargoPortOverride?: CargoPortsWithCalcs,
) => {
	const cargoPortsToUse = cargoPortOverride ?? cargo.CargoPorts;
	const loadingPorts = cargoPortsToUse.filter((cp) => splitActionKey(cp.portAndActionKey).action === 'loading');
	const dischargePorts = cargoPortsToUse.filter(
		(cp) => splitActionKey(cp.portAndActionKey).action === 'discharging',
	);

	const calculateDemDes = (
		cargoPorts: CargoPortsWithCalcs,
		type: 'loading' | 'discharging',
	) => cargoPorts.reduce(
		(acc, c) => {
			if (c.LaytimeCalculations.length === 0) {
				return acc;
			}

			const demurrage = type === 'loading' ? cargo.demurrageLoading : cargo.demurrageDischarge;
			const despatch = type === 'loading' ? cargo.despatchLoading : cargo.despatchDischarge;

			const isHour = c.laytimeInterval === LaytimeIntervals.HOUR;

			const blTimeAllowed = (c.blTimeAllowed ?? 0) * (isHour ? 60 : 1440);
			const timeUsed = sortByDates(
				c.LaytimeCalculations,
				'createdAt', { newestFirst: true },
			)[0].timeUsedInMinutes ?? 0;

			const diff = (blTimeAllowed - timeUsed) / 60 / 24;
			const dem = diff < 0 ? diff * (demurrage ?? 0) : 0;
			const des = diff > 0 ? diff * (despatch ?? 0) : 0;

			return {
				totalDemurrage: dem + acc.totalDemurrage,
				totalDespatch: des + acc.totalDespatch,
				totalTimeUsed: timeUsed + acc.totalTimeUsed,
				totalTimeAllowed: blTimeAllowed + acc.totalTimeAllowed,
			};
		}, {
			totalDemurrage: 0,
			totalDespatch: 0,
			totalTimeAllowed: 0,
			totalTimeUsed: 0,
		},
	);

	const {
		totalDemurrage: totalDemurrageLoading,
		totalDespatch: totalDespatchLoading,
		totalTimeAllowed: totalTimeAllowedLoading,
		totalTimeUsed: totalTimeUsedLoading,
	} = calculateDemDes(loadingPorts, 'loading');

	const {
		totalDemurrage: totalDemurrageDischarging,
		totalDespatch: totalDespatchDischarging,
		totalTimeAllowed: totalTimeAllowedDischarging,
		totalTimeUsed: totalTimeUsedDischarging,
	} = calculateDemDes(dischargePorts, 'discharging');

	const totalDemurrage = totalDemurrageLoading + totalDemurrageDischarging;
	const totalDespatch = totalDespatchLoading + totalDespatchDischarging;

	const totalTimeLoading = totalTimeAllowedLoading - totalTimeUsedLoading;
	const totalTimeDischarging = totalTimeAllowedDischarging - totalTimeUsedDischarging;

	return {
		...stats,
		totalDemurrage: round(Math.abs(totalDemurrage), 2),
		totalDespatch: round(Math.abs(totalDespatch), 2),
		totalReceivable: round(Math.abs(totalDemurrage) - Math.abs(totalDespatch), 2),
		totalTimeLoading,
		totalTimeDischarging,
		totalDemurrageLoading: round(Math.abs(totalDemurrageLoading), 2),
		totalDemurrageDischarging: round(Math.abs(totalDemurrageDischarging), 2),
		totalDespatchLoading: round(Math.abs(totalDespatchLoading), 2),
		totalDespatchDischarging: round(Math.abs(totalDespatchDischarging), 2),
	};
};
