import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Card,
	Row,
	Col,
	notification,
} from 'antd';
import {
	useParams,
	useHistory,
} from 'react-router-dom';
import { FixtureCounterpartyTypes } from '@shared/utils/constants';
import Table from '@client/components/Table/Table';
import {
	deleteBroker,
	getBrokerDetails,
	updateBroker,
	deleteCounterparty,
	getCounterpartyDetails,
	updateCounterparty,
	deleteSupplier,
	getSupplierDetails,
	updateSupplier,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showErrorNotification from '@client/utils/showErrorNotification';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import getFixtureTableColumns from '@client/utils/getFixtureTableColumns';
import getPointOfContactItems from '@client/utils/getPointOfContactItems';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import getItems from './helpers/getItems';

const CounterpartyDetailsScreen = () => {
	const { id, type } = useParams();
	const history = useHistory();

	const [
		counterparties,
		refreshCounterparty,
		error,
		loading,
	] = useFetchedState(() => {
		switch (type) {
			case FixtureCounterpartyTypes.BROKER:
				return getBrokerDetails(id);
			case FixtureCounterpartyTypes.SUPPLIER:
				return getSupplierDetails(id);
			default:
				return getCounterpartyDetails(id);
		}
	},
	[], { showNotification: false });

	const fixtureColumns = useMemo(() => getFixtureTableColumns(), []);

	const saveDetails = useCallback(async (values) => {
		switch (type) {
			case FixtureCounterpartyTypes.BROKER:
				await updateBroker(id, values);
				break;
			case FixtureCounterpartyTypes.SUPPLIER:
				await updateSupplier(id, values);
				break;
			default:
				await updateCounterparty(id, values);
		}

		await refreshCounterparty();
	}, [id, refreshCounterparty, type]);

	const onDeleteCounterparty = useCallback(async () => {
		try {
			switch (type) {
				case FixtureCounterpartyTypes.BROKER:
					await deleteBroker(id);
					break;
				case FixtureCounterpartyTypes.SUPPLIER:
					await deleteSupplier(id);
					break;
				default:
					await deleteCounterparty(id);
			}

			notification.success({
				message: 'Counterparty was deleted',
				description: `${counterparties?.name} has successfully been deleted.`,
			});

			history.goBack();
		} catch (e) {
			showErrorNotification('Could not delete counterparty', e);
		}
	}, [type, counterparties?.name, history, id]);

	return (
		<SimpleScreen
			error={error}
			canGoBack
			breadcrumbs={[['Counterparties', Links.Counterparties.get()]]}
			title={`${counterparties?.name} (${counterparties?.type ?? type})`}
			rootPageTitle="Counterparties"
			loading={loading}
			headerActions={[(
				<Button
					disabled={loading}
					onClick={onDeleteCounterparty}
					confirmTitle={`Are you sure you want to delete ${counterparties?.name}?`}
					key="delete"
					type="primary"
					danger
				>
					Delete counterparty
				</Button>
			)]}
		>
			<Row gutter={16}>
				<Col span={12}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									data-cy="details"
									items={counterparties != null ? getItems(counterparties) : []}
									onSave={saveDetails}
								/>
							</Card>
						</Col>
						<Col span={24}>
							<Card bordered>
								<EditableDetails
									data-cy="pointOfContact"
									items={counterparties != null ? getPointOfContactItems(counterparties) : []}
									onSave={saveDetails}
									title="Point of contact"
								/>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Card title="Estimates">
						<Table
							data-cy="fixtures"
							rowKey="id"
							dataSource={counterparties?.fixtures ?? []}
							columns={fixtureColumns}
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default CounterpartyDetailsScreen;
