import { ReactNode } from 'react';
import { SelectOption } from '@client/components/Select';

type ValueType = string | number | boolean | (string | number)[];

const getOptionsLabel = (
	value: ValueType,
	options: Array<SelectOption> | undefined,
) => {
	if (options == null) {
		return null;
	}

	const match = options.find((i) => i.value === value);

	if (match != null && match.label != null) {
		return match.label as ReactNode;
	}

	return null;
};

export default getOptionsLabel;
