import React from 'react';
import {
	FixtureCounterpartyTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import FixtureListScreen from '@client/screens/fixtures/shared/FixtureListScreen';
import { getBbExtraColumns } from './getBbExtraColumns';

const FixtureBbOutListScreen = () => {
	const counterpartyType = FixtureCounterpartyTypes.CHARTEREROWNER;

	return (
		<FixtureListScreen
			fixtureType={FixtureTypes.BB_OUT}
			counterpartyType={counterpartyType}
			getExtraColumns={getBbExtraColumns}
			allowCreateNewCharterer
		/>
	);
};

export default FixtureBbOutListScreen;
