import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import HIITotal from '@shared/hireInvoice/HIITotal';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetVoyageInvoicesResponse } from '@api/features/invoices/getVoyageInvoices';
import type { VcRowType } from '@client/screens/fleet/VoyageDetailsScreen/components/VcStatementOfAccountsTab';
import getHireInvoiceFromHII from '@client/utils/getHireInvoiceFromHII';

export const flattenItems = (items: HireInvoiceItem[]) => (
	items.reduce((arr: HireInvoiceItem[], i) => [...arr, i, ...i.children], [])
);

export const getUniqueCharterers = (
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>,
) => {
	const charterers = fixtureDetails.cargos.map((c) => ({
		id: c.charterer,
		name: c.FixtureCounterparty.name,
	}));

	const uniqueCharterers = charterers.filter(
		(c, index) => charterers.findIndex((cc) => cc.id === c.id) === index,
	);

	return uniqueCharterers;
};

export const getFilters = (
	voyageInvoices: GetVoyageInvoicesResponse | undefined,
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>,
	items: HireInvoiceItem[],
	data: VcRowType[],
) => {
	const uniqueCharterers = getUniqueCharterers(fixtureDetails);

	return [
		{
			key: 'invoice',
			label: 'Filter invoices',
			allLabel: 'All invoices',
			options: [
				{
					label: 'Added directly',
					value: 'Cumulative',
				},
				...(voyageInvoices ?? []).map((hireInvoice) => ({
					label: hireInvoice.invoiceIdentifier,
					value: hireInvoice.invoiceIdentifier,
				})),
			],
			multiple: true,
			onFilter: (value: any, row: VcRowType) => row.isTotal || value.includes(row.invoiceIdentifier),
		},
		{
			key: 'itemType',
			label: 'Filter items',
			allLabel: 'All items',
			options: Array.from(
				new Set(
					data
						.filter((d) => d != null && !d.isTotal && !d.item.isChild())
						.map((d) => d != null && d.type),
				),
			).map((type) => ({
				label: type,
				value: type,
			})),
			multiple: true,
			onFilter: (value: any, row: VcRowType) => {
				if (row.isTotal) {
					return true;
				}

				if (row.item.isChild()) {
					const parent = row.item.getParent(items) as HireInvoiceItem;

					return parent != null && value.includes(parent.itemTypeLabel);
				}

				return value.includes(row.type);
			},
		},
		{
			key: 'chartererId',
			label: 'Filter Charterers',
			allLabel: 'All charterers',
			options: [
				...(uniqueCharterers ?? []).map((charterer) => ({
					label: charterer.name,
					value: charterer.id,
				})),
			],
			multiple: true,
			onFilter: (value: any, row: VcRowType) => row.isTotal || value.includes(row.chartererId),
		},
	];
};

export const getDataObject = ({
	item,
	voyageInvoices,
	fixtureDetails,
	items,
}: {
	item: HireInvoiceItem;
	voyageInvoices: GetVoyageInvoicesResponse | undefined;
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
	items: HireInvoiceItem[];
}): VcRowType => {
	const isTotal = item instanceof HIITotal;
	const invoice = getHireInvoiceFromHII(item, voyageInvoices ?? []);

	const invoiceIdentifier = invoice?.invoiceIdentifier ?? 'Cumulative';

	const ours = item.getTotal(items);

	// @ts-ignore
	let chartererId = item?.charterer ?? invoice?.chartererId;

	if (chartererId == null) {
		if (item.cargoId != null) {
			const cargo = fixtureDetails.cargos.find((c) => c.id === item.cargoId);

			if (cargo != null) {
				chartererId = cargo.charterer;
			}
		} else if (item.parentId != null) {
			const parent = items.find((i) => i.id === item.parentId);

			if (parent != null) {
				// @ts-ignore
				chartererId = parent.charterer;
			}
		}
	}

	return {
		item,
		isTotal,
		id: item.id,
		parentId: item.parentId,
		type: item.itemTypeLabel,
		description: item.getDescription(items),
		ours,
		invoiceIdentifier,
		chartererId,
	};
};
