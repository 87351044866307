// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vRZetSKY8fmQVjsELwy4 {\n\twidth: 100%;\n\tborder: 1px solid rgba(0, 0, 0, 0.06);\n\toverflow-x: scroll;\n}\n\n.Ew88tR2JDusP7fLJoihR {\n\tcolor: #cf1322;\n}\n\n.QzuGW_HzFbZdXSJ0Cle5 {\n\tcolor: #389e0d;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/compare/CompareEstimatesScreen.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,qCAAqC;CACrC,kBAAkB;AACnB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;AACf","sourcesContent":[".container {\n\twidth: 100%;\n\tborder: 1px solid rgba(0, 0, 0, 0.06);\n\toverflow-x: scroll;\n}\n\n.negative {\n\tcolor: #cf1322;\n}\n\n.profit {\n\tcolor: #389e0d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vRZetSKY8fmQVjsELwy4",
	"negative": "Ew88tR2JDusP7fLJoihR",
	"profit": "QzuGW_HzFbZdXSJ0Cle5"
};
export default ___CSS_LOADER_EXPORT___;
