// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x8ayO1xWNdrKmIG5YIHY {\n\theight: 100%;\n}\n\n.or_Y3WueM75nCDTvJeTL {\n\theight: 100%;\n}\n\n.or_Y3WueM75nCDTvJeTL .ant-card-body {\n\tpadding-bottom: 0;\n\tpadding-top: 0;\n\theight: 100%;\n}\n\n.IiIteIUnYWJin7G2EC0f {\n\tcursor: pointer;\n}\n\n.IiIteIUnYWJin7G2EC0f:hover {\n\tcolor: blue;\n}\n\n.Av1vgyQrnKwpr3lVr0XA {\n\tcolor: blue;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/details/components/styles/ModePicker.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,iBAAiB;CACjB,cAAc;CACd,YAAY;AACb;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".fullHeight {\n\theight: 100%;\n}\n\n.card {\n\theight: 100%;\n}\n\n.card :global(.ant-card-body) {\n\tpadding-bottom: 0;\n\tpadding-top: 0;\n\theight: 100%;\n}\n\n.modeButton {\n\tcursor: pointer;\n}\n\n.modeButton:hover {\n\tcolor: blue;\n}\n\n.active {\n\tcolor: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullHeight": "x8ayO1xWNdrKmIG5YIHY",
	"card": "or_Y3WueM75nCDTvJeTL",
	"modeButton": "IiIteIUnYWJin7G2EC0f",
	"active": "Av1vgyQrnKwpr3lVr0XA"
};
export default ___CSS_LOADER_EXPORT___;
