import React from 'react';
import {
	nowMoment,
	toMoment,
} from '@shared/utils/date';
import { DATE_AND_TIME } from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import { formatDate } from '@client/utils/formatDate';
import VesselDescription from '@client/components/VesselDescription';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';
import TooltipIcon from '@client/components/TooltipIcon';

const getVesselMarkerDetails = (vessel) => {
	const { timestamp } = vessel.position;

	const formattedDate = formatDate(new Date(timestamp), DATE_AND_TIME);

	const now = nowMoment();

	const datetime = toMoment(timestamp);

	const outdated = now.diff(datetime, 'days') > 1;

	const vesselDetails = [{
		label: 'Name',
		value: (
			<LinkButton
				url={Links.Vessel.get(vessel.id)}
			>
				<VesselDescription
					name={vessel.name}
					flag={vessel.flag}
				/>
			</LinkButton>
		),
	},
	{
		label: 'Coordinates',
		value: `
			${round(vessel.position.latitude, 3)}° / 
			${round(vessel.position.longitude, 3)}°
		`,
	},
	{
		label: 'Contract',
		value: vessel.voyageId == null ? (
			vessel.voyageIdentifier
		) : (
			<LinkButton url={Links.Voyage.get(vessel.voyageId)}>{vessel.voyageIdentifier}</LinkButton>
		),
	},
	{ label: 'Charterer', value: vessel.chartererName },
	{ label: 'Open Date', value: formatDate(vessel.openDate, DATE_AND_TIME) },
	{
		label: 'Position received',
		value: outdated ? (
			<span style={{ color: 'red' }}>
				{formattedDate}
				<TooltipIcon danger>
					Data is more than one day old
				</TooltipIcon>
			</span>
		) : formattedDate,
	},
	];

	return { vesselDetails, outdated };
};

export default getVesselMarkerDetails;
