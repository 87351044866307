import React from 'react';
import { useDragLayer } from 'react-dnd';
import ItemCard from './ItemCard';
import styles from './styles/CustomDragLayer.module.css';

const CustomDragLayer = () => {
	const {
		item,
		isDragging,
		offset,
		clientOffset,
	} = useDragLayer((monitor) => ({
		isDragging: monitor.isDragging(),
		offset: monitor.getSourceClientOffset(),
		clientOffset: monitor.getClientOffset(),
		item: monitor.getItem(),
	}));

	if (!isDragging || offset == null || clientOffset == null) {
		return null;
	}

	let x = 0;
	let y = 0;

	// The first drag on a touch device will result in offset being { x: NaN, y: NaN }
	// This means the drag overlay will be rendered in the wrong place (top left corner)
	// To prevent this, we instead use the clientOffset to place it
	// (which doesn't take the start position into account).
	// We then estimate the placement (around the middle).
	// REMEMBER: Only happens on FIRST drag on TOUCH device
	if (Number.isNaN(offset.x) || Number.isNaN(offset.y)) {
		x = clientOffset.x - 100;
		y = clientOffset.y - 20;
	} else {
		({ x, y } = offset);
	}

	const transform = `translate(${x}px, ${y}px)`;

	return (
		<div className={styles.container}>
			<div
				style={{ transform }}
				className={styles.element}
			>
				<ItemCard className={styles.rotated}>
					{item.content}
				</ItemCard>
			</div>
		</div>
	);
};

export default CustomDragLayer;
