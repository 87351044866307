import { Moment } from 'moment/moment';
import type { ItineraryEntryState } from '@api/features/ops/getVesselItinerary';

export const getEtaText = (
	state: ItineraryEntryState,
	departureDate: Moment | null,
	arrivalDate: Moment | null,
	speed: number,
): string => {
	if (arrivalDate == null) {
		return 'Unknown arrival date';
	}

	if (departureDate == null) {
		return 'Unknown departure date';
	}

	const daysUntilArrival = arrivalDate.diff(departureDate, 'days', true);
	const daysUntilArrivalRounded = Math.floor(daysUntilArrival);
	const hoursUntilArrivalRounded = Math.round((daysUntilArrival % 1) * 24);

	let timeText = '';

	if (daysUntilArrivalRounded !== 0) {
		timeText += `${daysUntilArrivalRounded} days`;
	}

	if (hoursUntilArrivalRounded !== 0 || daysUntilArrivalRounded === 0) {
		if (timeText !== '') {
			timeText += ' and ';
		}

		timeText += `${hoursUntilArrivalRounded} hours`;
	}

	timeText = `${timeText} at ${speed == null ? 'unknown speed' : `${speed} knots`}`;

	return (state === 'in progress' || state === 'estimated') ?
		`Arrives after ${timeText}` :
		`Arrived after ${timeText}`;
};
