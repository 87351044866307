import React, {
	useRef,
	useState,
	useEffect,
	useCallback,
} from 'react';
import {
	DragObjectWithType,
	useDrop,
} from 'react-dnd';
import classNames from 'classnames';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import checkSrcDocSupported from '@client/utils/checkSrcDocSupported';
import styles from './styles/Preview.module.css';
import { ItemType } from './DraggableCard';

type PreviewProps = {
	addedItemIds: (number | string)[];
	onAddItem: (id: number) => void;
	className?: string;
	getPreview: (items: (number | string)[]) => Promise<string>;
}

const Preview: React.FC<PreviewProps> = ({
	addedItemIds,
	onAddItem,
	className,
	getPreview,
}) => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const [scrollPosition, setScrollPosition] = useState(0);

	const [previewItems, setPreviewItems] = useState(addedItemIds);

	// Only update preview after 500ms without changes
	useEffect(() => {
		const updateItems = () => setPreviewItems(addedItemIds);

		// If items are exactly the same, don't update
		if (addedItemIds === previewItems) {
			return undefined;
		}

		// If items are the same length, one of the items must have been changed
		// This is most likely the hire per day or hire description
		// Wait 500 ms before updating so we don't spam the preview api
		if (addedItemIds.length === previewItems.length) {
			const timeout = setTimeout(updateItems, 500);

			return () => clearTimeout(timeout);
		}

		// If an item has been added or removed, update immediately
		updateItems();

		return undefined;
	}, [addedItemIds, previewItems]);

	const [preview] = useFetchedState(
		() => getPreview(previewItems),
		[getPreview, previewItems],
	);

	useEffect(() => {
		if (iframeRef.current != null) {
			setScrollPosition(iframeRef.current?.contentWindow?.scrollY ?? 0);
		}
	}, [preview]);

	const updateScroll = useCallback(() => {
		iframeRef.current?.contentWindow?.scrollTo(0, scrollPosition);
	}, [scrollPosition]);

	const [{ dragging, hovering }, dropRef] = useDrop({
		accept: ItemType,
		collect: (monitor) => ({
			dragging: !!monitor.canDrop(),
			hovering: !!monitor.isOver(),
		}),
		drop: (item: DragObjectWithType & { itemId: number }) => {
			onAddItem(item.itemId);
		},
	});

	return (
		<div
			ref={dropRef}
			className={classNames(styles.previewWrapper, className)}
		>
			<div
				className={classNames(styles.overlay, {
					[styles.dragging]: dragging,
					[styles.hovering]: hovering,
				})}
			>
				{hovering ? (
					<span>Drop to add</span>
				) : (dragging ? (
					<span>Drag here to add item</span>
				) : null)}
			</div>
			{(() => {
				if (preview == null) {
					return null;
				}

				if (!checkSrcDocSupported()) {
					return (
						<div className={styles.frameNotSupported}>
							<p>
								PDF preview is not supported in your browser.
								<br />
								Please update your browser to the newest version.
							</p>
						</div>
					);
				}

				return (
					<iframe
						title="PDF preview"
						srcDoc={preview}
						className={styles.iframe}
						ref={iframeRef}
						onLoad={updateScroll}
					/>
				);
			})()}
		</div>
	);
};

export default Preview;
