import React from 'react';
import {
	Card,
	List,
	Radio,
	Space,
	Tag,
} from 'antd';
import type { GetVesselsResponse } from '@api/features/vessels/getVessels';
import styles from './styles/List.module.css';

const VesselsList = ({
	items,
	selectedItemId,
	setSelectedItemId,
}: {
	items: GetVesselsResponse;
	selectedItemId: number | null;
	setSelectedItemId: (id: number) => void;
}) => {
	return (
		<Card title="Vessels">
			<List
				dataSource={items}
				renderItem={(item) => (
					<div
						className={styles.listEntry}
						onClick={() => setSelectedItemId(item.id)}
						role="button"
						tabIndex={0}
						onKeyDown={() => {}}
					>
						<Space>
							<Radio checked={selectedItemId === item.id} />
							<List.Item>
								{item.accountingSystemConnection == null ? (
									<Tag color="red">No connection</Tag>
								) : (
									<Tag color="green">Connected</Tag>
								)}
								{item.name}
							</List.Item>
						</Space>
					</div>
				)}
			/>
		</Card>

	);
};

export default VesselsList;
