import type { HireInvoiceItemType } from '@shared/hireInvoice/HireInvoiceItem';
import { PnlAmountCalculatorParams } from './index';

type GetPnlAmountAveragedOverFullPeriodCalculator = <T extends HireInvoiceItemType>(item: T) => (
	allItems: HireInvoiceItemType[],
	parameters: PnlAmountCalculatorParams,
) => number;

const getPnlAmountAveragedOverFullPeriodCalculator: GetPnlAmountAveragedOverFullPeriodCalculator = (
	item,
) => (
	allItems,
	{
		fixtureDays,
		elapsedDays,
	},
) => {
	const total = item.getTotal(allItems, false);

	if (fixtureDays === 0) {
		return total;
	}

	return total * Math.min(1, elapsedDays / fixtureDays);
};

export default getPnlAmountAveragedOverFullPeriodCalculator;

