// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ITpFSPDy3aosA7yAtNLm {\n\tmargin-bottom: 10px;\n}\n\n.ITpFSPDy3aosA7yAtNLm .ant-badge {\n\tmargin-right: 5px;\n}\n\n.jRDYztmbLF_RdlSR_QIg {\n\tmargin-right: 5px;\n}\n\n.dK2wmjO2DoIm8KPj4mAO {\n\tmargin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/TagFilter.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".categoryWrapper {\n\tmargin-bottom: 10px;\n}\n\n.categoryWrapper :global(.ant-badge) {\n\tmargin-right: 5px;\n}\n\n.categoryLabel {\n\tmargin-right: 5px;\n}\n\n.button {\n\tmargin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoryWrapper": "ITpFSPDy3aosA7yAtNLm",
	"categoryLabel": "jRDYztmbLF_RdlSR_QIg",
	"button": "dK2wmjO2DoIm8KPj4mAO"
};
export default ___CSS_LOADER_EXPORT___;
