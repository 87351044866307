import React from 'react';
import {
	Card,
	List,
	Radio,
	Space,
	Tag,
} from 'antd';
import { FixtureTypeLabels } from '@shared/utils/constants';
import type { GetVoyagesResponse } from '@api/features/voyages/getVoyages';
import styles from './styles/List.module.css';

const ContractsList = ({
	items,
	selectedItemId,
	setSelectedItemId,
}: {
	items: GetVoyagesResponse;
	selectedItemId: number | null;
	setSelectedItemId: (id: number) => void;
}) => {
	return (
		<Card title="Contracts">
			<List
				dataSource={items}
				renderItem={(item) => (
					<div
						className={styles.listEntry}
						onClick={() => setSelectedItemId(item.id)}
						role="button"
						tabIndex={0}
						onKeyDown={() => {}}
					>
						<Space>
							<Radio checked={selectedItemId === item.id} />
							<List.Item>
								{item.accountingSystemConnection == null ? (
									<Tag color="red">No connection</Tag>
								) : (
									<Tag color="green">Connected</Tag>
								)}
								<Tag className={styles.tag}>{FixtureTypeLabels[item.fixtureType]}</Tag>
								{`${item.identifier} on ${item.vesselName}`}
							</List.Item>
						</Space>
					</div>
				)}
			/>
		</Card>

	);
};

export default ContractsList;
