import type {
	ItineraryPortCallDto,
	ItinerarySeaPassageDto,
} from '@api/features/ops/getVesselItinerary';
import getPortAndRangeOptions from '@client/utils/getPortAndRangeOptions';

const getFormFields = (
	portOptions: ReturnType<typeof getPortAndRangeOptions>,
	entries: Array<ItinerarySeaPassageDto | ItineraryPortCallDto>,
) => [
	{
		label: 'Port',
		required: true,
		name: 'portId',
		type: 'select',
		options: portOptions,
		inputProps: { showSearch: true },
	},
	...(entries.length === 0 ? [{
		label: 'Est. Departure Date',
		required: true,
		name: 'estimatedDepartureDate',
		type: 'date',
		inputProps: {},
	}] : []),
];

export default getFormFields;
