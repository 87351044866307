import gradstop from 'gradstop';

export const gradients = {
	red: ['#b30030', '#d97f97'],
	blue: ['#07a7eb', '#6acaf3'],
	magic: ['#003eb3', '#03389c'],
};

// This type describes an object of any shape that will have a color property added to it.
type WithColor<T> = T & { color: string };

const getDataWithGradient = <T extends {}>(data: T[], colorArray: string[]): WithColor<T>[] => {
	const gradient = data.length <= 2 ?
		colorArray :
		gradstop({
			stops: data.length,
			inputFormat: 'hex',
			colorArray,
		});

	const result = data.map((entry, index: number) => ({
		...entry,
		color: gradient[index],
	}));

	return result;
};

export default getDataWithGradient;
