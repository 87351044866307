export const splitActionKey = (key: string): { action: 'discharging' | 'loading'; portId: string; index: string } => {
	const split = key.split('_');
	const action = split[split.length - 1];
	const index = split[1];
	const portId = split[0];

	if (action !== 'discharging' && action !== 'loading') {
		throw new Error('Error encountered');
	}

	return {
		action,
		index,
		portId,
	};
};
