import React from 'react';
import { useDragLayer } from 'react-dnd';
import DraggableListItem from '@client/components/DraggableList/private/DraggableListItem';
import styles from './DraggableListDragLayer.module.css';

const DraggableListDragLayer = ({
	width,
	renderItem,
}) => {
	const {
		item,
		isDragging,
		offset,
		sourceOffset,
	} = useDragLayer((monitor) => ({
		isDragging: monitor.isDragging(),
		offset: monitor.getClientOffset(),
		sourceOffset: monitor.getInitialSourceClientOffset(),
		item: monitor.getItem(),
	}));

	const dragItem = item?.dragItem;

	if (!isDragging || offset == null || sourceOffset == null) {
		return null;
	}

	const transform = `translate(${sourceOffset.x}px, calc(${offset.y}px - 50%))`;

	return (
		<div className={styles.container}>
			<div
				style={{
					transform,
					width,
				}}
				className={styles.element}
			>
				<DraggableListItem
					dragItem={dragItem}
					includeSeparator={false}
					index={dragItem.index}
					renderItem={renderItem}
				/>
			</div>
		</div>
	);
};

export default DraggableListDragLayer;
