import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
} from 'react';
import { Pagination } from 'antd';
import {
	Document,
	Page,
} from 'react-pdf';
import getScrollBarWidth from '@client/utils/getScrollBarWidth';
import styles from './styles/PdfViewer.module.css';

/*
 * PLEASE READ:
 * For best result (meaning scrollbar is always visible),
 * this component should be rendered inside a flexbox element.
 * Furthermore, all elements between this one and the first parent element
 * with a set height (meaning not just flex: 1;) must have overflow-y: hidden;
 */
const PdfViewer = ({
	source,
	currentPage: currentPageProp,
	onTotalNumberOfPagesChange,
}: {
	source: string;
	currentPage?: number;
	onTotalNumberOfPagesChange?: (numPages: number) => void;
}) => {
	const [width, setWidth] = useState(0);
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [currentPageState, setCurrentPage] = useState(1);

	const currentPage = currentPageProp != null ? currentPageProp : currentPageState;

	const containerRef = useRef<HTMLDivElement>();

	const handleResize = useCallback(() => {
		const {
			width: containerWidth,
		} = containerRef.current!.getBoundingClientRect();

		const scrollBarWidth = getScrollBarWidth() || 0;

		setWidth(containerWidth - scrollBarWidth);
	}, []);

	const loaded = useCallback(({ numPages }: { numPages: number }) => {
		setNumberOfPages(numPages);

		if (typeof onTotalNumberOfPagesChange === 'function') {
			onTotalNumberOfPagesChange(numPages);
		}

		handleResize();
	}, [onTotalNumberOfPagesChange, handleResize]);

	useEffect(() => {
		// Do it once to set initial size
		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);

	const divRef = containerRef! as React.MutableRefObject<HTMLDivElement>;

	return (
		<div className={styles.container}>
			<div className={styles.pdfOuterWrapper}>
				<div ref={divRef} className={styles.pdfWrapper}>
					<Document
						file={source}
						onLoadSuccess={loaded}
					>
						<Page
							width={width}
							pageNumber={currentPage}
						/>
					</Document>
				</div>
				{/* Show navigation if page is not controlled by prop */}
			</div>
			{currentPageProp == null && (
				<Pagination
					className={styles.pagination}
					size="small"
					current={currentPage}
					// Total refers to the total number of items
					// We need to set a page size of 1 item
					total={numberOfPages}
					pageSize={1}
					onChange={setCurrentPage}
				/>
			)}
		</div>
	);
};

export default PdfViewer;
