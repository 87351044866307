import {
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import type Vessel from '@api/models/vessel';

type FixtureType = (typeof FixtureTypes)[keyof typeof FixtureTypes];

const getVesselOptions = (
	vessels: any[],
	type: 'myVessels' | 'marketVessels',
	fixtureType?: FixtureType,
): { label: string; value: number; ownershipType: string }[] => {
	let filterFunction: (v: Vessel) => boolean;

	if (type === 'myVessels') {
		filterFunction = (v: Vessel) => (
			fixtureType !== FixtureTypes.TC_IN ||
            v.ownershipType === VesselOwnershipTypes.TC_IN
		) && v.ownershipType !== VesselOwnershipTypes.MARKET;
	} else if (type === 'marketVessels') {
		filterFunction = (v: Vessel) => v.ownershipType === VesselOwnershipTypes.MARKET;
	} else {
		return [];
	}

	return (vessels || [])
		.filter(filterFunction)
		.sort((a, b) => a.name.toString().localeCompare(b.name))
		.map((v) => ({
			label: v.name,
			value: v.id,
			ownershipType: v.ownershipType,
		}));
};

export default getVesselOptions;
