import { Moment } from 'moment';
import {
	DATE,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import {
	formatDate as formatDateShared,
	TimeFormat,
} from '@shared/utils/date';

export const formatDate = (
	date: Moment | Date | string | number | null,
	format = DATE,
	includeTimezoneLabel = true,
	selectedTimeFormat?: TimeFormat,
) => {
	const userDateFormat = localStorage.getItem('preferredDateFormat');

	let finalFormat = format;

	if (userDateFormat != null && format === DATE_AND_TIME) {
		finalFormat = userDateFormat;
	}

	if (userDateFormat != null && format === DATE) {
		finalFormat = userDateFormat.slice(0, 10);
	}

	return formatDateShared(date, finalFormat, includeTimezoneLabel, selectedTimeFormat);
};
