// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._Ez4B8xHj1hCYuPepf2e {\n\tcursor: pointer;\n\tfont-size: 16px;\n\tborder-radius: 4px;\n\tpadding: 8px;\n\tbackground-color: white;\n\tbox-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.3), 0 1px 3px hsla(0, 0%, 100%, 0.8);\n\tposition: relative;\n\twidth: 320px;\n\tmin-height: 40px;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n._Ez4B8xHj1hCYuPepf2e.aBQW0nbZHQhh9A1WmRSf {\n\ttransform: rotate(7deg);\n\tborder: 1px solid lightgray;\n}\n\n._Ez4B8xHj1hCYuPepf2e._93ttn3gDyzWNC1lVK5m {\n\tcursor: not-allowed;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/PdfGenerator/private/styles/ItemCard.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,eAAe;CACf,kBAAkB;CAClB,YAAY;CACZ,uBAAuB;CACvB,4EAA4E;CAC5E,kBAAkB;CAClB,YAAY;CACZ,gBAAgB;CAChB,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,uBAAuB;CACvB,2BAA2B;AAC5B;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".container {\n\tcursor: pointer;\n\tfont-size: 16px;\n\tborder-radius: 4px;\n\tpadding: 8px;\n\tbackground-color: white;\n\tbox-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.3), 0 1px 3px hsla(0, 0%, 100%, 0.8);\n\tposition: relative;\n\twidth: 320px;\n\tmin-height: 40px;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.container.moving {\n\ttransform: rotate(7deg);\n\tborder: 1px solid lightgray;\n}\n\n.container.disabled {\n\tcursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_Ez4B8xHj1hCYuPepf2e",
	"moving": "aBQW0nbZHQhh9A1WmRSf",
	"disabled": "_93ttn3gDyzWNC1lVK5m"
};
export default ___CSS_LOADER_EXPORT___;
