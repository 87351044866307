import { formatCurrency } from '@shared/utils/currency';
import { InvoiceTemplates } from '@shared/utils/constants';
import { formatDate } from '../utils/date';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';

/*
 * Only to be used on the backend while generating cumulative hire invoices
 */
class HIIPayment extends HireInvoiceItem {
	static get itemType() {
		return 'payment';
	}

	constructor(id, isOriginal, params) {
		super(id, isOriginal, params);

		this.hireInvoiceNumber = params.hireInvoiceNumber;
		this.amount = params.amount;
		this.paymentDate = params.paymentDate;
		this.bankCharge = params.bankCharge;

		this._showTotal = false;
		this.itemTypeLabel = 'Payment';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.PAYMENTS;
	}

	_calculateTotal() {
		return -1 * this.amount;
	}

	getDescription() {
		return `${this.hireInvoiceNumber}, ${formatDate(this.paymentDate)}`;
	}

	static _getClassParams(_model, _parentModel) {
		throw new Error('Cannot run _getClassParams on HIIPayment');
	}

	async saveModel(_model) {
		throw new Error('Cannot run saveModel on HIIPayment');
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.hireInvoiceNumber,
					formatDate(this.paymentDate),
				],
				children: (this.bankCharge == null || this.bankCharge === 0) ? undefined : [
					{
						hideTotal: true,
						columns: [
							{
								italic: true,
								content: `${formatCurrency(this.bankCharge, this.fixtureCurrency)} short due to Charterer's bank charges`,
							},
						],
					},
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					`${this.hireInvoiceNumber} on ${formatDate(this.paymentDate)}`,
					' ',
					' ',
					' ',
				],
				children: (this.bankCharge == null || this.bankCharge === 0) ? undefined : [
					{
						hideTotal: true,
						columns: [
							{
								italic: true,
								content: `${formatCurrency(this.bankCharge, this.fixtureCurrency)} short due to Charterer's bank charges`,
							},
						],
					},
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIPayment);

export default HIIPayment;
