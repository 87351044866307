import React from 'react';
import { Flex } from 'antd';
import {
	DATE_AND_TIME,
	FixtureTypes,
} from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { Port } from '@api/utils/ports';
import EmptyText from '@client/components/EmptyText';
import TooltipIcon from '@client/components/TooltipIcon';
import EditableText from '@client/components/EditableText';
import { formatDate } from '@client/utils/formatDate';
import styles from './EstimatedDates.module.css';

export const getDateDetailsItems = (
	voyageDetails: GetVoyageDetailsResponse,
	updateVoyageField: (field: string, value: any, contractId?: number | null) => void,
	portOptions: Array<{
		label: string;
		value: number;
	}>,
) => {
	const {
		missingCommencementDateAlert,
		missingCommencementPortAlert,
	} = getMissingAlerts(voyageDetails);

	const commencementPort = missingCommencementPortAlert != null ?
		(
			<p className={styles.dateAlert}>
				{missingCommencementPortAlert}
			</p>
		) :
		voyageDetails.commencementPort?.name;

	return ([
		{
			key: '1',
			label: (
				<Flex justify="space-between">
					Est. commencement date
					<TooltipIcon>{voyageDetails.estimatedCommencementDateOrigin}</TooltipIcon>
				</Flex>
			),
			value: formatDate(voyageDetails?.estimatedCommencementDate, DATE_AND_TIME),
			style: {
				width: 210,
			},
		},
		{
			key: '2',
			label: (
				<Flex justify="space-between">
					Est. completion date
					<TooltipIcon>{voyageDetails.estimatedCompletionDateOrigin}</TooltipIcon>
				</Flex>
			),
			value: formatDate(voyageDetails?.estimatedCompletionDate, DATE_AND_TIME),
			style: {
				width: 210,
			},
		},
		{
			key: '3',
			label: (
				<Flex justify="space-between">
					Est. commencement port
					{voyageDetails.estimatedCommencementPortOrigin != null && (
						<TooltipIcon>{voyageDetails.estimatedCommencementPortOrigin}</TooltipIcon>
					)}
				</Flex>
			),
			value: voyageDetails?.estimatedCommencementPort?.name,
			style: {
				width: 210,
			},
		},
		{
			key: '4',
			label: (
				<Flex justify="space-between">
					Est. completion port
					{voyageDetails.estimatedCompletionPortOrigin != null && (
						<TooltipIcon>{voyageDetails.estimatedCompletionPortOrigin}</TooltipIcon>
					)}
				</Flex>
			),
			value: voyageDetails?.estimatedCompletionPort?.name,
			style: {
				width: 210,
			},
		},
		{
			key: '5',
			label: (
				<Flex justify="space-between">
					<span>Act. commencement date</span>
					{voyageDetails?.isFirstContract ? (
						<TooltipIcon>
							This is the first contract for
							{' '}
							{voyageDetails.vessel.name}
							,
							which means the commencement date is the same as the
							{' '}
							{getCommencementDateStr(voyageDetails)}
						</TooltipIcon>
					) : (
						<TooltipIcon>
							This is the completion date of the previous contract
						</TooltipIcon>
					)}
				</Flex>
			),
			value: (
				<>
					<span style={{ fontSize: missingCommencementDateAlert == null ? 14 : 12 }}>
						{missingCommencementDateAlert == null ?
							formatDate(voyageDetails.commencementDate, DATE_AND_TIME) : (
								<p className={styles.dateAlert}>{missingCommencementDateAlert}</p>
							)}
					</span>
				</>
			),
			style: {
				width: 210,
			},
		},
		{
			key: '6',
			label: (
				<Flex justify="space-between">
					<span>Act. commencement port</span>
					{voyageDetails?.isFirstContract ? (
						<TooltipIcon>
							This is the first contract for
							{' '}
							{voyageDetails.vessel.name}
							,
							which means the commencement port
							{' '}
							{getCommencementPortStr(voyageDetails)}
						</TooltipIcon>
					) : (
						<TooltipIcon>
							This is the completion port of the previous contract
						</TooltipIcon>
					)}
				</Flex>
			),
			value: (
				<>
					{(voyageDetails.isFirstContract &&
						voyageDetails?.previousVoyage?.fixture?.type !== FixtureTypes.TC_IN) ? (
							// @ts-ignore - not typed
							<EditableText
								defaultValue={voyageDetails.commencementPort?.name}
								placeholder={(<EmptyText />)}
								type="select"
								options={portOptions}
								onChange={(value: Port) => updateVoyageField('commencementPort', value)}
								inputProps={{
									className: styles.portSelect,
									showSearch: true,
									autoFocus: true,
									allowClear: true,
									onClear: () => updateVoyageField('commencementPort', null),
								}}
							/>
						) : (
							commencementPort
						)}
				</>
			),
			style: {
				width: 210,
			},
		},
	]);
};

export const getMissingAlerts = (voyageDetails: GetVoyageDetailsResponse) => {
	let missingCommencementDateAlert: string | null = null;
	let missingCommencementPortAlert = null;

	if (voyageDetails.previousVoyage == null && voyageDetails.commencementDate == null) {
		if (voyageDetails.commencementDate == null) {
			missingCommencementDateAlert = 'Link previous contract to set commencement date';
		}
	}

	if (voyageDetails.previousVoyage != null && voyageDetails.commencementDate == null) {
		missingCommencementDateAlert = 'Set the completion date of the previous contract to set the commencement date';
	}

	if (voyageDetails.previousVoyage != null && voyageDetails.commencementPort == null) {
		missingCommencementPortAlert = 'Set the completion port of the previous contract to set the commencement port';
	}

	if (
		(voyageDetails.previousVoyage == null && voyageDetails.linkedTcInVoyageId == null) &&
		voyageDetails.commencementPort == null
	) {
		missingCommencementPortAlert = 'Link previous voyage to set commencement port';
	}

	return {
		missingCommencementDateAlert,
		missingCommencementPortAlert,
	};
};

export const getCommencementDateStr = (voyageDetails: GetVoyageDetailsResponse) => {
	if (voyageDetails?.previousVoyage?.fixture?.type === FixtureTypes.TC_IN) {
		return 'delivery date of the linked TC in contract';
	}

	return 'start date of the first ownership period';
};

export const getCommencementPortStr = (voyageDetails: GetVoyageDetailsResponse) => {
	if (voyageDetails?.previousVoyage?.fixture?.type === FixtureTypes.TC_IN) {
		return 'is the same as the delivery port of the linked TC in contract';
	}

	return 'needs to be set manually';
};
