import React from 'react';
import { Alert } from 'antd';
import type { VesselProps } from '@api/models/vessel';
import styles from '@client/screens/fleet/VesselDetailsScreen/tabs/styles/VesselCrewReportsTab.module.css';

export const getReportSettingsAlert = (vessel: VesselProps | undefined) => {
	if (vessel?.crewAppActivated) {
		return (
			<Alert
				type="success"
				className={styles.alert}
				message={(
					<p className={styles.alertText}>
						The crew app is activated, and the crew has logged in with the email
						{' '}
						<strong>{vessel.crewAppEmail}</strong>
						.
						To change the associated crew email address,
						enter a new email in the input field above and click
						{' '}
						<strong>Send invitation</strong>
						.
					</p>
				)}
			/>
		);
	}

	if (!vessel?.crewAppInvitationSent) {
		return (
			<Alert
				className={styles.alert}
				message={(
					<p className={styles.alertText}>
						To invite the crew to use the ClearVoyage Crew app,
						enter an email above and click
						{' '}
						<strong>Send invitation</strong>
						.
						They will then be sent instructions as to how to use the reporting app.
					</p>
				)}
			/>
		);
	}

	if (vessel?.crewAppInvitationSent && !vessel.crewAppActivated) {
		const emailField = (<strong>{vessel.crewAppEmail}</strong>);

		return (
			<Alert
				type="warning"
				className={styles.alert}
				message={(
					<p className={styles.alertText}>
						An email has been sent to the crew (at
						{' '}
						{emailField}
						) with instructions for noon reporting.
						Please ask them to check their junk folder if they do not receive it.
					</p>
				)}
			/>
		);
	}

	return null;
};
