import moment from 'moment';
import { toMoment } from '@shared/utils/date';

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: any): boolean {
	return value && typeof value === 'string' && isoDateFormat.test(value);
}

export const handleDates = (data: any) => {
	if (data == null || moment.isMoment(data)) {
		return data;
	}

	// Check if the data is shaped similarly to ports.json - we don't want to parse that
	if (Array.isArray(data) && data.length > 0) {
		if (data.length > 6400) {
			return data;
		}

		data.forEach(handleDates);
	}

	if (typeof data === 'object') {
		for (const key of Object.keys(data)) {
			const val = data[key];

			if (isIsoDateString(val)) {
				data[key] = toMoment(val);

				// eslint-disable-next-line no-continue
				continue;
			}

			if (Array.isArray(val) && val.length > 0) {
				val.forEach(handleDates);
			}

			if (typeof val === 'object') {
				handleDates(val);
			}
		}
	}

	return data;
};
