import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Checkbox,
	Col,
	Divider,
	Drawer,
	Form,
	Input,
	Row,
	Space,
	Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Moment } from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import {
	Currencies,
	DATE_AND_TIME,
	AccountingItemApprovalStates,
	FixtureCounterpartyTypes,
	HireInvoiceItemStates,
	AccountTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { standardSort } from '@shared/utils/standardSort';
import { nowMoment } from '@shared/utils/date';
import { formatCurrency } from '@shared/utils/currency';
import { formatHumanReadable } from '@shared/utils/string';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { Port } from '@api/utils/ports';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { GetVoyageExpensesResponse } from '@api/features/invoices/getVoyageExpenses';
import type { RevenueItemProps } from '@api/models/revenue-item';
import type { VoyageExpenseReceivableProps } from '@api/models/voyage-expense-receivable';
import type { VoyageExpenseProps } from '@api/models/voyage-expense';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import { formatDate } from '@client/utils/formatDate';
import Card from '@client/components/Card/Card';
import EditableTable from '@client/components/EditableTable';
import StateTag from '@client/components/StateTag';
import { getFilterProps } from '@client/utils/table';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	createVoyageExpenseReceivable,
	deleteRevenueItem,
	deleteVoyageExpense,
	deleteVoyageExpenseReceivable,
	getCounterparties,
	updateVoyageExpenseReceivable,
} from '@client/lib/api';
import Button from '@client/components/Button';
import EmptyText from '@client/components/EmptyText';
import CreateExpenseForm from '@client/components/CreateExpenseForm/CreateExpenseForm';
import CreateRevenueForm from '@client/components/CreateRevenueForm/CreateRevenueForm';
import MultiCurrencyInput from '@client/components/MultiCurrencyInput';
import Select from '@client/components/Select';
import TooltipIcon from '@client/components/TooltipIcon';

type VcExpensesTabProps = {
	fixtureCurrency: Values<typeof Currencies>;
	voyageDetails: GetVoyageDetailsResponse;
	vendorExpenses: GetVoyageExpensesResponse | undefined;
	refreshVendorExpenses: () => void;
	refreshDetails: () => void;
	expenses: GetVoyageDetailsResponse['voyageExpenseReceivables'];
	fixtureDetails: GetFixtureDetailsResponse<
		TcFixtureProps | SpotFixtureProps | BbFixtureProps
	>;
}
const VcExpensesTab = ({
	voyageDetails,
	vendorExpenses,
	refreshVendorExpenses,
	fixtureCurrency,
	refreshDetails,
	fixtureDetails,
	expenses,
}: VcExpensesTabProps) => {
	const [charterers] = useFetchedState(() => getCounterparties(
		FixtureCounterpartyTypes.CHARTEREROWNER,
	));
	const [editingId, setEditingId] = useState<number | null>(null);
	const [editingVendorExpenseId, setEditingVendorExpenseId] = useState<number | null>(null);
	const [editingRevenueItemId, setEditingRevenueItemId] = useState<number | null>(null);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [vendorExpenseDrawerVisible, setVendorExpenseDrawerVisible] = useState(false);
	const [revenueDrawerVisible, setRevenueDrawerVisible] = useState(false);

	const { revenueItems } = voyageDetails;

	const cargoCharterers = useMemo(() => {
		const allCharterers = fixtureDetails.cargos.map((c) => c.charterer);

		return [...new Set(allCharterers)];
	}, [fixtureDetails]);

	const required = [{ required: true, message: 'Field is required' }];

	const [form] = useForm();

	const onClose = () => {
		setEditingId(null);
		setEditingVendorExpenseId(null);
		form.resetFields();
		setDrawerOpen(false);
	};

	const revenueFormOnClose = () => {
		setRevenueDrawerVisible(false);
		setEditingRevenueItemId(null);
		refreshDetails();
	};

	const saveExpense = async () => {
		const values = form.getFieldsValue();

		const {
			itemDescription,
			chartererId,
			account,
			note,
			amount,
			estimated = false,
		} = values;

		const {
			value,
			currency,
			exchangeRate,
		} = amount;

		if (editingId != null) {
			await updateVoyageExpenseReceivable(voyageDetails.id, editingId, {
				itemDescription,
				chartererId,
				account,
				amount: value,
				currency,
				exchangeRate,
				note,
				estimated,
			}, []);
		} else {
			await createVoyageExpenseReceivable({
				voyageId: voyageDetails.id,
				itemDescription,
				chartererId,
				account,
				amount: value,
				currency,
				exchangeRate,
				invoiceDate: nowMoment(),
				note,
				estimated,
			});
		}

		await refreshDetails();
		onClose();
	};

	const deleteReceivable = async (expenseId: number) => {
		await deleteVoyageExpenseReceivable(voyageDetails.id, expenseId);
		await refreshDetails();
	};

	const deleteVendorExpense = async (expenseId: number) => {
		await deleteVoyageExpense(expenseId);
		await refreshDetails();
	};

	const onDeleteRevenueItem = async (revenueItemId: number) => {
		await deleteRevenueItem(revenueItemId);
		refreshDetails();
	};

	const expenseColumns = [
		{
			title: 'Creation date',
			dataIndex: 'createdAt',
			render: (date: Moment) => formatDate(date, DATE_AND_TIME),
		},
		{
			title: 'Account',
			dataIndex: 'account',
			render: (account: string) => formatHumanReadable(account),
		},
		{
			title: 'Charterer',
			dataIndex: 'chartererId',
			render: (id: number) => charterers?.find((c) => c.id === id)?.name ?? 'N/A',
		},
		{
			title: 'Item description',
			dataIndex: 'itemDescription',
			render: (description: string) => (description === '' ? (<EmptyText />) : description),
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			align: 'right',
			render: (
				amount: number,
				record: VoyageExpenseReceivableProps,
			) => amount != null && formatCurrency(amount, record.currency),
		},
		{
			dataIndex: 'state',
			title: 'Status',
			render: (state: Values<typeof AccountingItemApprovalStates>, record: VoyageExpenseProps) => (
				<StateTag state={record.estimated ? 'estimated' : state} />
			),
			width: 100,
			sorter: standardSort('state'),
			...getFilterProps([
				HireInvoiceItemStates.PENDING,
				HireInvoiceItemStates.INVOICED,
				HireInvoiceItemStates.UNRESOLVED,
				HireInvoiceItemStates.PAID,
			], 'select', 'state', undefined, true),
		},
	];

	const revenueColumns = [
		{
			title: 'Creation date',
			dataIndex: 'createdAt',
			render: (date: Moment) => formatDate(date, DATE_AND_TIME),
		},
		{
			title: 'Subject to commissions',
			dataIndex: 'subjectToCommissions',
			render: (value: boolean) => (<span>{value ? 'Yes' : 'No'}</span>),
		},
		{
			title: 'Charterer',
			dataIndex: 'chartererId',
			render: (id: number) => charterers?.find((c) => c.id === id)?.name ?? 'N/A',
		},
		{
			title: 'Item description',
			dataIndex: 'itemDescription',
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			align: 'right',
			render: (
				amount: number,
				record: RevenueItemProps,
			) => amount != null && formatCurrency(amount, record.currency),
		},
		{
			dataIndex: 'state',
			title: 'Status',
			render: (state: Values<typeof AccountingItemApprovalStates>, record: VoyageExpenseProps) => (
				<StateTag state={record.estimated ? 'estimated' : state} />
			),
			width: 100,
			sorter: standardSort('state'),
			...getFilterProps([
				HireInvoiceItemStates.PENDING,
				HireInvoiceItemStates.INVOICED,
			], 'select', 'state', undefined, true),
		},
	];

	const editingVendorExpense = useMemo(() => {
		const vendorExpense = vendorExpenses?.find((e) => e.id === editingVendorExpenseId);

		if (vendorExpense?.receivable != null) {
			return {
				...vendorExpense,
				charterer: vendorExpense.receivable.chartererId,
			};
		}

		return vendorExpense;
	}, [vendorExpenses, editingVendorExpenseId]);

	const editingRevenueItem = useMemo(() => {
		return revenueItems?.find((i) => i.id === editingRevenueItemId);
	}, [revenueItems, editingRevenueItemId]);

	useEffect(() => {
		const expense = expenses.find((e) => e.id === editingId);

		if (expense != null) {
			form.setFieldsValue({
				...expense,
				amount: {
					value: expense.amount,
					currency: expense.currency,
					exchangeRate: expense.exchangeRate,
				},
			});
		}
	}, [editingId, expenses, form]);

	const filteredVendorExpenses = vendorExpenses?.filter((ve) => ve.voyageId === voyageDetails.id);

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Card
					title="Expenses"
					slim
					extra={(
						<Button
							onClick={() => setDrawerOpen(true)}
							type="primary"
							icon={(<PlusOutlined />)}
						>
							Create expense to/from charterer
						</Button>
					)}
				>
					<EditableTable<GetVoyageDetailsResponse['voyageExpenseReceivables'][number], 'id'>
						dataSource={expenses}
						// @ts-ignore (align apparently makes an error? but its valid)
						columns={expenseColumns}
						onSave={() => null}
						keyDataIndex="id"
						editingRow={null}
						onDelete={deleteReceivable}
						onEditingRowChange={(_i, e) => e != null && setEditingId(e.id)}
						size={'small' as SizeType}
						pagination={false}
						enableEdit={(item) => item.state === HireInvoiceItemStates.PENDING}
						enableDelete={(item) => item.state === HireInvoiceItemStates.PENDING}
						iconButtons
						actionsTitle=""
						expandable={{
							expandedRowRender: (record) => (
								<>
									<Typography.Title level={5}>Internal note</Typography.Title>
									{record.note != null && record.note !== '' ? record.note : (
										<EmptyText />
									)}
									{record.currency !== fixtureCurrency && (
										<>
											<Divider />
											<b>Original Amount:</b>
											{` ${formatCurrency(record.amount, record.currency)}`}
											<br />
											<b>Exchange Rate:</b>
											{` ${record.exchangeRate} ${record.currency} = 1 ${fixtureCurrency} `}
										</>
									) }
								</>
							),
						}}
					/>
				</Card>
			</Col>
			<Col span={24}>
				<Card
					title="Revenues"
					slim
					extra={(
						<Button
							onClick={() => setRevenueDrawerVisible(true)}
							type="primary"
							icon={(<PlusOutlined />)}
						>
							Create revenue item
						</Button>
					)}
				>
					<EditableTable
						pagination={false}
						dataSource={voyageDetails.revenueItems ?? []}
						// @ts-ignore (align apparently makes an error? but its valid)
						columns={revenueColumns}
						onSave={() => {}}
						keyDataIndex="id"
						editingRow={null}
						iconButtons
						enableEdit={(item) => item.state === AccountingItemApprovalStates.PENDING}
						enableDelete={(item) => item.state === AccountingItemApprovalStates.PENDING}
						onDelete={onDeleteRevenueItem}
						onEditingRowChange={(id: number | null) => {
							setEditingRevenueItemId(id);
							setRevenueDrawerVisible(true);
						}}
						actionsTitle=""
						size={'small' as SizeType}
						expandable={{
							expandedRowRender: (record) => (
								<>
									<Typography.Title level={5}>Internal note</Typography.Title>
									{record.note != null && record.note !== '' ? record.note : (
										<EmptyText />
									)}
									{record.currency !== fixtureCurrency && (
										<>
											<Divider />
											<b>Original Amount:</b>
											{` ${formatCurrency(record.amount, record.currency)}`}
											<br />
											<b>Exchange Rate:</b>
											{` ${record.exchangeRate} ${record.currency} = 1 ${fixtureCurrency} `}
										</>
									) }
								</>
							),
						}}
					/>
				</Card>
			</Col>
			<Col span={24}>
				<Card
					title="Vendor Expenses & DAs"
					slim
					extra={(
						<Button
							key="createExpense"
							onClick={() => setVendorExpenseDrawerVisible(true)}
							type="primary"
							icon={(<PlusOutlined />)}
						>
							Create vendor expense or DA
						</Button>
					)}
				>
					{filteredVendorExpenses != null && (
						<EditableTable<TransformedExpenses, 'id'>
							keyDataIndex="id"
							editingRow={null}
							onSave={() => null}
							onDelete={deleteVendorExpense}
							onEditingRowChange={(id: number | null) => {
								setEditingVendorExpenseId(id);
								setVendorExpenseDrawerVisible(true);
							}}
							size={'small' as SizeType}
							pagination={false}
							enableEdit={(item) => item.state === AccountingItemApprovalStates.PENDING}
							enableDelete={(item) => item.state === AccountingItemApprovalStates.PENDING}
							iconButtons
							actionsTitle=""
							dataSource={filteredVendorExpenses}
							columns={[
								{
									title: 'Creation date',
									dataIndex: 'createdAt',
									render: (createdAt: Moment) => formatDate(createdAt, DATE_AND_TIME),
								},
								{
									title: 'Item description',
									dataIndex: 'itemDescription',
									render: (description: string) => (description === '' ? (<EmptyText />) : description),
								},
								{
									title: 'Supplier',
									dataIndex: 'supplierName',
									render: (supplier: string) => (supplier == null ? (<EmptyText />) : supplier),
								},
								{
									title: 'Port',
									dataIndex: 'port',
									render: (port: Port) => (port == null ? ' - ' : port.name),
								},
								{
									title: 'Amount',
									dataIndex: 'amount',
									align: 'right',
									render: (
										amount: number,
										record: TransformedExpenses,
									) => formatCurrency(amount, record.currency),
								},
								{
									dataIndex: 'state',
									title: 'Status',
									render: (
										state: Values<typeof AccountingItemApprovalStates>,
										record: TransformedExpenses,
									) => (
										<StateTag state={record.estimated ? 'estimated' : state} />
									),
									width: 100,
									sorter: standardSort('state'),
									...getFilterProps([
										HireInvoiceItemStates.PENDING,
										HireInvoiceItemStates.INVOICED,
										HireInvoiceItemStates.UNRESOLVED,
										HireInvoiceItemStates.PAID,
									], 'select', 'state', undefined, true),
								},
							]}
							expandable={{
								expandedRowRender: (record) => (
									<Space direction="vertical">
										<Typography.Title level={5}>Internal note</Typography.Title>
										{record.note != null && record.note !== '' ? record.note : (
											<EmptyText />
										)}
										{record.customInvoiceId != null && (
											<>
												<b>Invoice ID:</b>
												{record.customInvoiceId}
											</>
										)}
										{record.invoiceDate != null && (
											<>
												<b>Invoice Date:</b>
												{formatDate(record.invoiceDate)}
											</>
										)}
										{record.dueDate != null && (
											<>
												<b>Due Date:</b>
												{formatDate(record.dueDate)}
											</>
										)}
										{record.currency !== fixtureCurrency && (
											<>
												<Divider />
												<b>Original Amount:</b>
												{` ${formatCurrency(record.amount, record.currency)}`}
												<br />
												<b>Exchange Rate:</b>
												{` ${record.exchangeRate} ${record.currency} = 1 ${fixtureCurrency} `}
											</>
										) }
									</Space>
								),
							}}
						/>
					)}
				</Card>
			</Col>
			<Drawer
				title={editingId != null ? 'Editing expense' : 'Create expense to/from charterer'}
				open={drawerOpen || editingId != null}
				onClose={onClose}
				width={500}
			>
				<Form
					form={form}
					layout="vertical"
				>
					<Form.Item
						name="account"
						label="Account"
						rules={required}
					>
						<Select
							options={[{
								label: 'Charterer',
								value: AccountTypes.CHARTERER,
							},
							{
								label: 'Owner',
								value: AccountTypes.OWNER,
							}]}
						/>
					</Form.Item>
					<Form.Item
						name="chartererId"
						label="Charterer"
						rules={required}
					>
						<Select
							options={
								charterers?.filter((c) => cargoCharterers.some((cc) => cc === c.id)).map((c) => ({
									label: c.name,
									value: c.id,
								}))
							}
						/>
					</Form.Item>
					<Form.Item
						name="itemDescription"
						label="Item Description"
						rules={required}
					>
						<Input type="text" />
					</Form.Item>
					<Form.Item
						name="amount"
						label="Amount"
						rules={required}
					>
						<MultiCurrencyInput baseCurrency={fixtureCurrency} />
					</Form.Item>
					<Form.Item
						name="estimated"
						valuePropName="checked"
						initialValue={false}
						label={(
							<Space>
								Estimated
								<TooltipIcon>
									Estimated items are used for estimating P&L items,
									and cannot be invoiced or put through
									an approvals process.
									If you wish to invoice the item,
									convert the item to an actual by
									deselecting the &quot;estimated&quot; checkbox
								</TooltipIcon>
							</Space>
						)}
					>
						<Checkbox />
					</Form.Item>
					<Form.Item
						name="note"
						label="Internal note"
					>
						<Input.TextArea />
					</Form.Item>
				</Form>
				<Space>
					<Button
						onClick={saveExpense}
						type="primary"
					>
						Save & Close
					</Button>
					<Button>
						Close
					</Button>
				</Space>
			</Drawer>
			<CreateRevenueForm
				extraOnSave={revenueFormOnClose}
				voyageId={voyageDetails.id}
				cargos={fixtureDetails.cargos ?? []}
				editingItem={editingRevenueItem}
				open={revenueDrawerVisible}
				charterers={charterers}
				cargoCharterers={cargoCharterers}
				fixtureCurrency={fixtureCurrency}
				onClose={revenueFormOnClose}
			/>
			<CreateExpenseForm
				open={vendorExpenseDrawerVisible}
				voyageId={voyageDetails.id}
				editing={editingVendorExpense}
				onClose={() => {
					setVendorExpenseDrawerVisible(false);
					setEditingVendorExpenseId(null);
					refreshVendorExpenses();
					refreshDetails();
				}}
			/>
		</Row>
	);
};

export default VcExpensesTab;
