// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fmsvbuMCKbY2ztCGGYVu tr:nth-child(odd) {\n\tbackground-color: #f6f6f6;\n}\n\n.KdCgmX7qtZS1vK7_MSL3 {\n\tmax-height: 70vh;\n\toverflow-y: scroll;\n}\n\n.hAl8CAmNx5nlF6wy7DEt {\n\twidth: 90px;\n\ttext-align: center;\n}\n\n.fmsvbuMCKbY2ztCGGYVu .ant-table-tbody .SnOMYfh7lVe8uwQjfGDJ > .ant-table-cell {\n\tbackground-color: #dff3ff;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/widgets/styles/VesselActivityWidget.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,yBAAyB;AAC1B","sourcesContent":[".table tr:nth-child(odd) {\n\tbackground-color: #f6f6f6;\n}\n\n.itinerary-card {\n\tmax-height: 70vh;\n\toverflow-y: scroll;\n}\n\n.tag {\n\twidth: 90px;\n\ttext-align: center;\n}\n\n.table :global(.ant-table-tbody) .highlightedRow > :global(.ant-table-cell) {\n\tbackground-color: #dff3ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "fmsvbuMCKbY2ztCGGYVu",
	"itinerary-card": "KdCgmX7qtZS1vK7_MSL3",
	"tag": "hAl8CAmNx5nlF6wy7DEt",
	"highlightedRow": "SnOMYfh7lVe8uwQjfGDJ"
};
export default ___CSS_LOADER_EXPORT___;
