import React from 'react';
import { Tag } from 'antd';
import { capitalize } from '@shared/utils/string';
import {
	AccountingItemApprovalStates,
	HireInvoiceItemStates,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

type StateTypes = (
	Values<typeof AccountingItemApprovalStates> |
	Values<typeof HireInvoiceItemStates> |
	string |
	'estimated'
)

const getStateColor = (
	state: StateTypes,
) => {
	switch (state) {
		case 'estimated':
			return 'cyan';
		case HireInvoiceItemStates.PENDING:
		case AccountingItemApprovalStates.PENDING:
			return 'orange';

		case HireInvoiceItemStates.INVOICED:
		case AccountingItemApprovalStates.SUBMITTED:
			return 'purple';

		case HireInvoiceItemStates.UNRESOLVED:
		case AccountingItemApprovalStates.REJECTED:
			return 'red';

		case HireInvoiceItemStates.PAID:
			return 'blue';
		case AccountingItemApprovalStates.APPROVED:
			return 'green';

		case AccountingItemApprovalStates.PAID:
			return 'grey';

		default:
			return 'grey';
	}
};

const StateTag = ({
	state,
	showUnresolved = true,
	className,
}: {
	state: StateTypes; showUnresolved?:boolean;
	className?: string;
}) => {
	const formattedState = state === HireInvoiceItemStates.UNRESOLVED && !showUnresolved ?
		HireInvoiceItemStates.INVOICED : state;

	return (
		<Tag className={className} color={getStateColor(formattedState)}>
			{capitalize(formattedState)}
		</Tag>
	);
};

export default StateTag;
