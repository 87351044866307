import React, {
	useMemo,
	useState,
} from 'react';
import {
	Typography,
	Divider,
	Grid,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { formatCurrency } from '@shared/utils/currency';
import { capitalize } from '@shared/utils/string';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	FixtureTypes,
} from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { deleteVoyageExpenseReceivable } from '@client/lib/api';
import EmptyText from '@client/components/EmptyText';
import EditableTable from '@client/components/EditableTable';
import CreateVoyageExpenseReceivableForm from '@client/components/CreateVoyageExpenseReceivableForm';
import AddButton from '@client/components/AddButton';
import Details from '@client/components/Details';
import Card from '@client/components/Card/Card';
import { getExpenseColumns } from '../helpers/tableColumns';
import getEditableTableProps from '../helpers/getEditableTableProps';

type ExpenseEntry = GetVoyageDetailsResponse['voyageExpenseReceivables'][number];

const ExpensesTable = ({
	id,
	refreshDetails,
	expenses,
	fixtureCurrency,
	fixtureType,
	title = 'Expenses',
}: {
	id: number;
	refreshDetails: () => void;
	title?: string;
	fixtureType: Values<typeof FixtureTypes>;
	fixtureCurrency: Values<typeof Currencies>;
	expenses: GetVoyageDetailsResponse['voyageExpenseReceivables'];
}) => {
	const [open, setOpen] = useState(false);
	const [editingExpense, setEditingExpense] = useState<null | ExpenseEntry>(null);
	const screens = Grid.useBreakpoint();

	const deleteExpense = async (expenseReceivableId: number) => {
		await deleteVoyageExpenseReceivable(id, expenseReceivableId);
		await refreshDetails();
	};

	const hasDifferences = expenses.some((e) => e.currency !== fixtureCurrency);

	const expenseColumns = useMemo(() => getExpenseColumns(
		fixtureCurrency, fixtureType, hasDifferences,
	), [fixtureCurrency, fixtureType, hasDifferences]);

	const editableTableProps = getEditableTableProps();

	return (
		<>
			<Card
				title={(
					<Space>
						<FontAwesomeIcon
							color="green"
							icon={faFileInvoiceDollar as IconProp}
						/>
						{title}
					</Space>
				)}
				slim
				extra={(
					<AddButton
						disabled={open}
						onClick={() => setOpen(true)}
					>
						New Expense
					</AddButton>
				)}
			>
				<EditableTable<ExpenseEntry, 'id'>
					useCards={screens.xs}
					keyDataIndex="id"
					editingRow={null}
					onSave={() => null}
					onEditingRowChange={(_i, e) => e != null && setEditingExpense(e)}
					{...editableTableProps}
					enableEdit={(row) => editableTableProps.enableEdit(row)}
					enableDelete={(row) => editableTableProps.enableDelete(row)}
					pagination={false}
					// @ts-ignore (align apparently makes an error? but its valid)
					columns={expenseColumns}
					dataSource={expenses}
					onDelete={deleteExpense}
					expandable={{
						expandedRowRender: (record) => (
							<div>
								{record.customInvoiceId != null ? (
									<>
										<Typography.Title level={5}>
											Invoice ID:
											{' '}
										</Typography.Title>
										<Typography.Text>{record.customInvoiceId}</Typography.Text>
										<Divider />
									</>
								) : null}
								<Typography.Title level={5}>Internal note</Typography.Title>
								{record.note != null && record.note !== '' ? record.note : (
									<EmptyText />
								)}
								{record.currency !== fixtureCurrency && (
									<>
										<Divider />
										<b>Original Amount:</b>
										{` ${formatCurrency(record.amount, record.currency)}`}
										<br />
										<b>Exchange Rate:</b>
										{` ${record.exchangeRate} ${record.currency} = 1 ${fixtureCurrency} `}
									</>
								) }
								{record.expense != null && (
									<>
										<br />
										<br />
										<Typography.Title level={5}>Expense</Typography.Title>
										<Details
											boldValue
											hideHeader
											items={[
												{
													key: 'amount',
													label: 'Amount',
													value: formatCurrency(record.expense.amount, record.expense.currency),
												},
												...(fixtureCurrency !== record.expense.currency ? [{
													key: 'exchangeRate',
													label: 'Exchange Rate',
													value: ` ${record.exchangeRate} ${record.currency} = 1 ${fixtureCurrency}`,
												}] : []),
												{
													key: 'status',
													label: 'Status',
													value: capitalize(record.expense.state),
												},
												{
													key: 'description',
													label: 'Description',
													value: record.expense.itemDescription,
												},
												{
													key: 'supplier',
													label: 'Supplier',
													value: record.expense.supplierName,
												},
												{
													key: 'internalNote',
													label: 'Internal note',
													value: record.expense.note,
												},
											]}
										/>
									</>
								)}
							</div>
						),
					}}
				/>
			</Card>
			<CreateVoyageExpenseReceivableForm
				voyageId={id}
				open={open}
				fixtureCurrency={fixtureCurrency}
				editingExpense={editingExpense}
				onClose={() => {
					setEditingExpense(null);
					setOpen(false);
				}}
				onInvoiceCreated={refreshDetails}
				fixtureType={fixtureType}
			/>
		</>
	);
};

export default ExpensesTable;
