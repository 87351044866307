// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qj2UKQjp0krIVjbf5reU {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.K04zsadI4pFPwTgnw76q {\n\tfont-weight: bold;\n\tmargin-bottom: 5px;\n}\n\n.p37RgOyZpRmu6cReBWup {\n\tdisplay: inline-block;\n\tcolor: red;\n\tmargin-left: 4px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/Label.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,qBAAqB;CACrB,UAAU;CACV,gBAAgB;AACjB","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.label {\n\tfont-weight: bold;\n\tmargin-bottom: 5px;\n}\n\n.required {\n\tdisplay: inline-block;\n\tcolor: red;\n\tmargin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Qj2UKQjp0krIVjbf5reU",
	"label": "K04zsadI4pFPwTgnw76q",
	"required": "p37RgOyZpRmu6cReBWup"
};
export default ___CSS_LOADER_EXPORT___;
