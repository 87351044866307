import {
	useState,
	useCallback,
} from 'react';

/*
 * Returns a seed that can be updated with 'updateSeed'
 * Can be used in hook dependencies when you just need to force-update something
 */
const useSeed = (): [number, () => void] => {
	const [seed, setSeed] = useState(Math.random());

	const updateSeed = useCallback(() => setSeed(Math.random()), []);

	return [seed, updateSeed];
};

export default useSeed;
