// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bDPkM5Lr2z1DdjFYwC1G {\n\tposition: relative;\n\tz-index: 2;\n\tdisplay: inline-block;\n\tmin-width: 24px;\n\theight: 24px;\n\tline-height: 24px;\n\tborder-radius: 2px;\n\ttransition: background-color 0.3s, border 0.3s;\n}\n\n.ant-picker-cell:hover .bDPkM5Lr2z1DdjFYwC1G {\n\tbackground-color: #f5f5f5;\n}\n\n.ant-picker-cell.ant-picker-cell-selected .bDPkM5Lr2z1DdjFYwC1G {\n\tbackground-color: #096dd9;\n\tcolor: white;\n}\n\n.bDPkM5Lr2z1DdjFYwC1G.im1I8F0ymIkXAbPTvpow {\n\tborder: 1px solid var(--primary-light);\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fixtures/shared/styles/LaycanDate.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,UAAU;CACV,qBAAqB;CACrB,eAAe;CACf,YAAY;CACZ,iBAAiB;CACjB,kBAAkB;CAClB,8CAA8C;AAC/C;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;CACzB,YAAY;AACb;;AAEA;CACC,sCAAsC;AACvC","sourcesContent":[".date {\n\tposition: relative;\n\tz-index: 2;\n\tdisplay: inline-block;\n\tmin-width: 24px;\n\theight: 24px;\n\tline-height: 24px;\n\tborder-radius: 2px;\n\ttransition: background-color 0.3s, border 0.3s;\n}\n\n:global(.ant-picker-cell:hover) .date {\n\tbackground-color: #f5f5f5;\n}\n\n:global(.ant-picker-cell.ant-picker-cell-selected) .date {\n\tbackground-color: #096dd9;\n\tcolor: white;\n}\n\n.date.reference {\n\tborder: 1px solid var(--primary-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date": "bDPkM5Lr2z1DdjFYwC1G",
	"reference": "im1I8F0ymIkXAbPTvpow"
};
export default ___CSS_LOADER_EXPORT___;
