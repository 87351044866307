// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Hide tooltip in selected item box */\n.ant-select-selection-item .Uj8sihhnxLXgS3W86RDT {\n\tdisplay: none;\n}\n\n.WRk6oGSjFJfl197JqiHU {\n\tfont-family: monospace;\n\tfont-size: 85%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.qGz_ifYMEeiNuQB_jtUE {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n}\n\n.qGz_ifYMEeiNuQB_jtUE > * {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.qGz_ifYMEeiNuQB_jtUE > *:first-child {\n\tborder-bottom: 1px solid black;\n}\n\n.bOpLd98gLLxD73izkNUV {\n\tdisplay: block;\n\twidth: 3px;\n\theight: 3px;\n\tborder-radius: 6px;\n\tbackground-color: black;\n\tmargin: 0 4px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/CVEFormulaLabel.module.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;CACC,aAAa;AACd;;AAEA;CACC,sBAAsB;CACtB,cAAc;CACd,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,cAAc;CACd,UAAU;CACV,WAAW;CACX,kBAAkB;CAClB,uBAAuB;CACvB,aAAa;AACd","sourcesContent":["/* Hide tooltip in selected item box */\n:global(.ant-select-selection-item) .tooltip {\n\tdisplay: none;\n}\n\n.formula {\n\tfont-family: monospace;\n\tfont-size: 85%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.division {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n}\n\n.division > * {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.division > *:first-child {\n\tborder-bottom: 1px solid black;\n}\n\n.times {\n\tdisplay: block;\n\twidth: 3px;\n\theight: 3px;\n\tborder-radius: 6px;\n\tbackground-color: black;\n\tmargin: 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "Uj8sihhnxLXgS3W86RDT",
	"formula": "WRk6oGSjFJfl197JqiHU",
	"division": "qGz_ifYMEeiNuQB_jtUE",
	"times": "bOpLd98gLLxD73izkNUV"
};
export default ___CSS_LOADER_EXPORT___;
