// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DFm22wElHY05zChpQvf_ .ant-input-number {\n\twidth: 100%;\n}\n\n.DFm22wElHY05zChpQvf_ .ant-form-item-label {\n\twhite-space: normal;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-end;\n\theight: 36px;\n}\n\n.DFm22wElHY05zChpQvf_ > .ant-form-item-label > label {\n\theight: auto;\n}\n\n.iPglT8IcoWU_3LLEf_3U {\n\tcolor: red;\n\tmargin-top: 2px;\n\tdisplay: inline-block;\n}\n\n.xQxCVdf8cbZez2wby9WK {\n\tmargin-left: 8px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/SimpleForm.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,mBAAmB;CACnB,aAAa;CACb,mBAAmB;CACnB,yBAAyB;CACzB,YAAY;AACb;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,UAAU;CACV,eAAe;CACf,qBAAqB;AACtB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".formItem :global(.ant-input-number) {\n\twidth: 100%;\n}\n\n.formItem :global(.ant-form-item-label) {\n\twhite-space: normal;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-end;\n\theight: 36px;\n}\n\n.formItem > :global(.ant-form-item-label) > label {\n\theight: auto;\n}\n\n.error {\n\tcolor: red;\n\tmargin-top: 2px;\n\tdisplay: inline-block;\n}\n\n.tooltipIcon {\n\tmargin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "DFm22wElHY05zChpQvf_",
	"error": "iPglT8IcoWU_3LLEf_3U",
	"tooltipIcon": "xQxCVdf8cbZez2wby9WK"
};
export default ___CSS_LOADER_EXPORT___;
