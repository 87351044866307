import React, {
	useLayoutEffect,
	useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import styles from './TableEmptyText.module.css';

const TableEmptyText = ({
	icon,
	title,
	description,
	loading,
	showArrow,
	// Needed to position the arrow
	arrowTargetRef,
	containerRef,
	hasTabs,
}) => {
	const [newButtonWidth, setNewButtonWidth] = useState(0);
	const [tableWidth, setTableWidth] = useState(800);

	const arrowWidth = 600;
	const arrowHeight = 450;

	useLayoutEffect(() => {
		if (
			arrowTargetRef == null ||
			containerRef == null ||
			arrowTargetRef.current == null ||
			containerRef.current == null
		) {
			return undefined;
		}

		const container = containerRef.current;
		const target = arrowTargetRef.current;

		const onResize = () => {
			setTableWidth(container.offsetWidth);
			setNewButtonWidth(target.offsetWidth);
		};

		onResize();

		const observer = new ResizeObserver(onResize);

		observer.observe(container);
		observer.observe(target);

		return () => {
			observer.unobserve(container);
			observer.unobserve(target);
		};
	}, [arrowTargetRef, containerRef, loading]);

	// On larger screens, the svg canvas will be stretched
	// To avoid the arrowhead looking weird and pointing at the wrong place
	// We have include that factor in the calculations
	const stretchFactor = (arrowWidth * 2) / tableWidth;
	const buttonOffset = (newButtonWidth / 2) * stretchFactor;

	const arrowHeadHeight = 8;
	const arrowHeadWidth = arrowHeadHeight * 1.45;

	const path = `
		M0,${arrowHeight * (hasTabs ? 0.775 : 0.75)}
		C
			85,${arrowHeight * 1.25}
			${25 + arrowWidth - buttonOffset},245
			${5 + arrowWidth - buttonOffset},${arrowHeadHeight * 3}
	`;

	return (
		<div className={styles.emptyWrapper}>
			{icon != null && (
				<FontAwesomeIcon icon={icon} className={styles.emptyIcon} />
			)}
			<span className={styles.emptyTitle}>{title}</span>
			<span className={styles.emptyDescription}>{description}</span>
			{showArrow && !loading && (
				<svg
					viewBox={`0 0 ${arrowWidth} ${arrowHeight}`}
					className={classNames(styles.emptyArrow, {
						[styles.withTabs]: hasTabs,
					})}
					preserveAspectRatio="none"
				>
					<defs>
						<marker
							id="arrowhead"
							markerWidth={arrowHeadWidth}
							markerHeight={arrowHeadHeight}
							refX="0"
							refY={arrowHeadHeight / 2}
							orient="auto"
						>
							<polygon points={`0 0, ${arrowHeadWidth} ${arrowHeadHeight / 2}, 0 ${arrowHeadHeight}`} />
						</marker>
					</defs>
					<path
						d={path}
						markerEnd="url(#arrowhead)"
					/>
				</svg>
			)}
		</div>
	);
};

export default TableEmptyText;
