import React, {
	useMemo,
	useState,
} from 'react';
import {
	Col,
	Divider,
	Row,
	Space,
	Typography,
} from 'antd';
import { Moment } from 'moment';
import { useHistory } from 'react-router';
import { Values } from '@shared/utils/objectEnums';
import {
	AccrualItemClosingPeriodFormats,
	AccrualItemStates,
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import { Links } from '@client/utils/links';
import Card from '@client/components/Card/Card';
import Select from '@client/components/Select';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	closeAccountingItems,
	getAccrualItems,
	getVessels,
	getVoyageDurations,
	getVoyages,
} from '@client/lib/api';
import Button from '@client/components/Button';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import DatePicker from '@client/components/DatePicker';
import Table from '@client/components/Table/Table';
import AccrualsTable from '../AccrualsScreen/AccrualsTable';
import styles from './PeriodCloseScreen.module.css';

const PeriodCloseScreen = () => {
	const [datePickerFormat, setDatePickerFormat] = useState<
		Values<typeof AccrualItemClosingPeriodFormats> | null
	>(AccrualItemClosingPeriodFormats.MONTH_AND_YEAR);
	const [closePeriod, setClosePeriod] = useState<Moment | null>();

	const [selectedVessel, setSelectedVessel] = useState<number | null>(null);
	const [selectedVoyages, setSelectedVoyages] = useState<number[]>([]);

	const [vessels] = useFetchedState(getVessels);
	const [voyages] = useFetchedState(
		async () => {
			if (selectedVessel == null) {
				return [];
			}

			return getVoyages(selectedVessel);
		},
		[selectedVessel],
	);

	const [accrualItems, refresh, _error, loading] = useFetchedState(getAccrualItems);
	const [contractDurations] = useFetchedState(
		async () => {
			if (selectedVoyages == null || selectedVoyages.length === 0) {
				return null;
			}

			return getVoyageDurations(selectedVoyages);
		},
		[selectedVoyages],
	);

	const filteredAccrualItems = useMemo(() => {
		const filteredByVoyage =
			accrualItems?.filter(
				(item) => item.vessel.id === selectedVessel &&
					item.status === AccrualItemStates.OPEN,
			).filter(
				(i) => selectedVoyages.includes(i.voyage.id),
			);

		// Filtering accruals for (month & year) close
		if (datePickerFormat === AccrualItemClosingPeriodFormats.MONTH_AND_YEAR) {
			return filteredByVoyage?.filter(
				(item) => {
					const isSameOrAfter = closePeriod?.isSameOrAfter(item.dateFrom, 'month');
					const isSameOfBefore = closePeriod?.isSameOrBefore(item.dateTo, 'month');

					if (item.fixtureType === FixtureTypes.SPOT) {
						return item.dateTo != null ? (
							isSameOrAfter && isSameOfBefore
						) : isSameOrAfter;
					}

					return isSameOrAfter && isSameOfBefore;
				},
			);
		}

		// Filtering accruals for year close
		return filteredByVoyage?.filter(
			(item) => {
				const isSameOrAfter = closePeriod?.isSameOrAfter(item.dateFrom, 'year');
				const isSameOrBefore = closePeriod?.isSameOrBefore(item.dateTo, 'year');

				if (item.fixtureType === FixtureTypes.SPOT) {
					return item.dateTo != null ? (
						isSameOrAfter && isSameOrBefore
					) : isSameOrAfter;
				}

				return isSameOrAfter && isSameOrBefore;
			},
		);
	}, [accrualItems, closePeriod, datePickerFormat, selectedVessel, selectedVoyages]);

	const [previewItems, refreshPreview] = useFetchedState(async () => {
		if (
			filteredAccrualItems != null &&
			filteredAccrualItems.length !== 0 &&
			closePeriod != null &&
			datePickerFormat != null
		) {
			try {
				return await closeAccountingItems(
					filteredAccrualItems,
					closePeriod,
					datePickerFormat,
					true,
				);
			} catch (e) {
				showErrorNotification('Something went wrong', e as Error);
			}
		}

		return null;
	}, [closePeriod, datePickerFormat, filteredAccrualItems, accrualItems]);

	const history = useHistory();

	const onSubmit = async () => {
		if (
			filteredAccrualItems != null &&
			filteredAccrualItems.length !== 0 &&
			closePeriod != null &&
			datePickerFormat != null
		) {
			try {
				await closeAccountingItems(filteredAccrualItems, closePeriod, datePickerFormat);
				showSuccessNotification(
					'Accrual item(s) successfully closed',
					datePickerFormat === AccrualItemClosingPeriodFormats.YEAR ? `${closePeriod.format('yyyy')} has been closed` :
						`${closePeriod.format('yyyy-MMM')} has been closed`,
					5,
				);
				history.push(Links.Accounting.Accruals.get());
			} catch (e) {
				showErrorNotification('Something went wrong', e as Error);
			}
		} else {
			showErrorNotification('Could not close period since no accrual items were found');
		}
	};

	return (
		<SimpleScreen
			title="Create Closing Period"
			rootPageTitle="Accruals"
			canGoBack
			breadcrumbs={[['Accruals', Links.Accounting.Accruals.get()]]}
			goBackToUrl={Links.Accounting.Accruals.get()}
		>
			<Card>
				<Row>
					<Col span={6} className={styles.headerCol}>
						<Space size="middle">
							<Typography.Text>1.</Typography.Text>
							<Typography.Text>Select a year and / or month to close</Typography.Text>
						</Space>
					</Col>
					<Col className={styles.selectCol}>
						<Space
							size="middle"
						>
							<Space direction="vertical">
								<b>Format</b>
								<Select
									value={datePickerFormat != null ? datePickerFormat : undefined}
									options={[
										{ label: 'Year', value: AccrualItemClosingPeriodFormats.YEAR },
										{ label: 'Month & Year', value: AccrualItemClosingPeriodFormats.MONTH_AND_YEAR }]}
									placeholder="Select an option"
									onChange={(format) => {
										setDatePickerFormat(format);
										setClosePeriod(null);
									}}
									className={styles.selector}
								/>
							</Space>
							{datePickerFormat === AccrualItemClosingPeriodFormats.YEAR && (
								<Space direction="vertical">
									<b>Year</b>
									<DatePicker
										picker="year"
										onChange={(date) => setClosePeriod(date as Moment)}
									/>
								</Space>
							)}
							{datePickerFormat === AccrualItemClosingPeriodFormats.MONTH_AND_YEAR && (
								<Space direction="vertical">
									<b>Month & Year</b>
									<DatePicker
										picker="month"
										onChange={(date) => setClosePeriod(date as Moment)}
									/>
								</Space>
							)}
						</Space>
					</Col>
				</Row>
				<>
					<Divider />
					<Row>
						<Col span={6} className={styles.headerCol}>
							<Space size="middle">
								<Typography.Text>2.</Typography.Text>
								<Typography.Text>Select a vessel and one or more contracts</Typography.Text>
							</Space>
						</Col>
						<Col className={styles.selectCol}>
							<Space size="middle">
								<Space direction="vertical">
									<b>Vessel</b>
									<Select
										placeholder="Select a vessel"
										options={(vessels ?? [])
											.sort((a, b) => a.name.localeCompare(b.name))
											.map((v) => ({
												label: v.name,
												value: v.id,
											}))}
										onChange={(v) => {
											setSelectedVessel(v as number);
											setSelectedVoyages([]);
										}}
										className={styles.selector}
										notFoundContent="No vessels found"
										showSearch
									/>
								</Space>
								<Space direction="vertical">
									<b>Contract(s)</b>
									<Select
										value={selectedVoyages}
										placeholder="Select one or more contracts"
										mode="multiple"
										options={(voyages ?? [])
											.sort((a, b) => a.identifier.localeCompare(b.identifier))
											.map((v) => ({
												label: v.identifier,
												value: v.id,
											}))}
										onChange={(v) => setSelectedVoyages(v as number[])}
										className={styles.multipleSelector}
										notFoundContent="Selected vessel has no contracts"
									/>
								</Space>
							</Space>
						</Col>
					</Row>
				</>
				{contractDurations != null && (
					<div className={styles.selectedVoyages}>
						<Typography.Text>Selected contracts</Typography.Text>
						<Table
							size="small"
							pagination={false}
							dataSource={contractDurations}
							columns={[
								{
									title: 'Identifier',
									dataIndex: 'identifier',
									render: (c) => (<b>{c}</b>),
								},
								{
									title: 'Type',
									dataIndex: 'fixtureType',
									render: (c) => FixtureTypeLabels[c],
								},
								{
									title: 'Start Date',
									dataIndex: 'startDate',
									render: (c, row) => (
										<div className={styles.dateBox}>
											<p className={styles.label}>{formatDate(c)}</p>
											<p className={styles.dateLabel}>{row.isStartDateEstimated ? 'estimated' : 'actual'}</p>
										</div>
									),
								},
								{
									title: 'End Date',
									dataIndex: 'endDate',
									render: (c, row) => (
										<div className={styles.dateBox}>
											<p className={styles.label}>{formatDate(c)}</p>
											<p className={styles.dateLabel}>{row.isEndDateEstimated ? 'estimated' : 'actual'}</p>
										</div>
									),
								},
								{
									title: 'Duration',
									dataIndex: 'duration',
									render: (c) => (<b>{c}</b>),
								},
							]}
						/>
					</div>
				)}
				<>
					<Divider />
					<Row>
						<Col span={6} className={styles.headerCol}>
							<Space size="middle">
								<Typography.Text>3.</Typography.Text>
								<Typography.Text>Verify affected items</Typography.Text>
							</Space>
						</Col>
					</Row>
					<br />
					<Card slim>
						<AccrualsTable
							accrualItems={filteredAccrualItems ?? []}
							refresh={refresh}
							loading={loading}
						/>
					</Card>
				</>
				<>
					<Divider />
					<Row>
						<Col span={6} className={styles.headerCol}>
							<Space size="middle">
								<Typography.Text>4.</Typography.Text>
								<Typography.Text>Result</Typography.Text>
							</Space>
						</Col>
					</Row>
					<br />
					<Card slim title="Item(s) to be closed">
						<AccrualsTable
							accrualItems={previewItems?.filter(
								(i) => i.status === AccrualItemStates.CLOSED,
							) ?? []}
							refresh={refresh}
							columnsToHide={['id', 'shouldProrate']}
							loading={loading}
						/>
					</Card>
					<br />
					<Card slim title="Following Period Reversals">
						<AccrualsTable
							accrualItems={previewItems?.filter((i) => i.status === AccrualItemStates.OPEN) ?? []}
							refresh={() => {
								refresh();
								refreshPreview();
							}}
							columnsToHide={['id', 'shouldProrate']}
							loading={loading}
						/>
					</Card>
					<br />
					<Row>
						<Space size="middle">
							<Button
								type="primary"
								onClick={onSubmit}
								disabled={filteredAccrualItems?.length === 0}
								confirmTitle="Are you sure you want to close these items?"
							>
								Close period
							</Button>
							<Button
								danger
								onClick={() => {
									setDatePickerFormat(null);
									setClosePeriod(null);
									setSelectedVessel(null);
									setSelectedVoyages([]);
									history.push(Links.Accounting.Accruals.get());
								}}
								confirmTitle="Are you sure you want to cancel? This will reset all above fields."
							>
								Cancel
							</Button>
						</Space>
					</Row>
				</>
			</Card>
		</SimpleScreen>
	);
};

export default PeriodCloseScreen;
