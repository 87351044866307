import React, { useState } from 'react';
import {
	Menu,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles/CardWithMenu.module.css';

const CardWithMenu = ({ pages }) => {
	const [activePage, setActivePage] = useState(pages[0].key);
	const page = pages.find((p) => p.key === activePage);

	return (
		<div className={styles.card}>
			<div className={styles.sidebar}>
				<Menu
					className={styles.menu}
					onSelect={(item) => setActivePage(pages.find((p) => p.title === item.key).key)}
					selectedKeys={[page.title.toString()]}
				>
					{pages.map((p) => (
						(p.visible == null || (p.visible != null && p.visible)) && (
							<Menu.Item
								key={p.title}
								icon={(<FontAwesomeIcon icon={p.icon} />)}
							>
								{p.title}
							</Menu.Item>
						)
					))}
				</Menu>
			</div>
			<div className={styles.content}>
				<div className={styles.banner}>
					<Space>
						<FontAwesomeIcon icon={page.icon} />
						{page.title}
					</Space>
				</div>
				{page.content}
			</div>
		</div>
	);
};

export default CardWithMenu;
