// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SnloDa0K7I8LKMIzC4mA {\n\tmargin-bottom: 0;\n}\n\n.qQu4I15bz5Dl6Au9wAYi {\n\tcursor: pointer;\n}\n\n.hWHIkyOwPoXM5lABNIRn {\n\tfont-size: 18px;\n}\n\n.qv7UX4t3ezJCbccRIOKp {\n\ttext-align: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/EditableTable.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".editableCell {\n\tmargin-bottom: 0;\n}\n\n.rowClickable {\n\tcursor: pointer;\n}\n\n.buttonIcon {\n\tfont-size: 18px;\n}\n\n.actionsWrapper {\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editableCell": "SnloDa0K7I8LKMIzC4mA",
	"rowClickable": "qQu4I15bz5Dl6Au9wAYi",
	"buttonIcon": "hWHIkyOwPoXM5lABNIRn",
	"actionsWrapper": "qv7UX4t3ezJCbccRIOKp"
};
export default ___CSS_LOADER_EXPORT___;
