import type { GetTcPnlResponse } from '@api/features/voyages/getTcPnl';

const getTcPnlItems = (pnl: GetTcPnlResponse) => {
	const revenueItems = [
		{
			item: 'Gross Hire',
			...pnl.grossHire,
		},
		{
			item: 'Gross Ballast Bonus',
			...pnl.grossBallastBonus,
		},
		{
			item: 'Address Commissions',
			...pnl.addressCommission,
		},
		{
			item: 'Broker Commissions',
			...pnl.brokerCommission,
		},
		{
			item: 'Expenses Subject To Hire Days',
			...pnl.expensesSubjectToHireDays,
		},
		{
			item: 'Revenue Subject To Days',
			...pnl.revenueSubjectToDays,
		},
		{
			item: 'Charterer\'s Expenses',
			...pnl.chartererExpenses,
		},
		{
			item: 'Bunker Gain/(Loss)',
			...pnl.bunkerGainAndLoss,
		},
		{
			item: 'Total Revenue',
			bold: true,
			...pnl.totalRevenue,
		},
	];

	const expenseItems = [
		{
			item: 'Owner\'s Expenses',
			...pnl.ownersExpenses,
		},
		{
			item: 'Off-Hire',
			...pnl.offHire,
		},
		{
			item: 'Repositioning Bunkers',
			...pnl.repositioningBunkers,
		},
		{
			item: 'Total Expenses',
			bold: true,
			...pnl.totalExpenses,
		},
	];

	const resultsItems = [
		{
			item: 'Total Days',
			number: true,
			bold: true,
			...pnl.voyageDays,
		},
		{
			item: 'TC Earnings',
			bold: true,
			...pnl.voyageProfit,
		},
		{
			item: 'TC Earnings per day',
			bold: true,
			...pnl.tce,
		},
		{
			item: 'CAPEX',
			...pnl.capex,
		},
		{
			item: 'OPEX',
			...pnl.opex,
		},
		{
			item: 'Net hire cost',
			...pnl.tcInCosts,
		},
		{
			item: 'Net Result',
			...pnl.netResult,
		},
		{
			item: 'Net Result / day',
			...pnl.netResultPerDay,
		},
	];

	return {
		revenueItems,
		expenseItems,
		resultsItems,
	};
};

export default getTcPnlItems;
