// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inGtXsPMKI2PCJyjv_en {\n\tpadding: 32px 20%;\n\ttext-align: center;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.h_nPmfrQhW0u2zD1i70Y {\n\tfont-size: 150%;\n\tfont-weight: bold;\n}\n\n.TrvdeJN5rOiq0gMnK83I {\n\tmargin: 24px 0;\n}\n\n.lSsGTzX1xQYkO_bc4l2s {\n\tfont-size: 80%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/ErrorMessage.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,kBAAkB;CAClB,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,cAAc;AACf","sourcesContent":[".container {\n\tpadding: 32px 20%;\n\ttext-align: center;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.title {\n\tfont-size: 150%;\n\tfont-weight: bold;\n}\n\n.error {\n\tmargin: 24px 0;\n}\n\n.footer {\n\tfont-size: 80%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "inGtXsPMKI2PCJyjv_en",
	"title": "h_nPmfrQhW0u2zD1i70Y",
	"error": "TrvdeJN5rOiq0gMnK83I",
	"footer": "lSsGTzX1xQYkO_bc4l2s"
};
export default ___CSS_LOADER_EXPORT___;
