// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e8qxmjUH3qso5NL1xUDZ .ant-descriptions-item-content {\n\ttext-align: right;\n}\n\n.ijlDocrdkeQIOSt4V5AC {\n\tcursor: pointer;\n\tcolor: #1677ff;\n}\n\n.PFCQY7EGDOVjy9ovmzf9 {\n\tcolor: red;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/details/components/styles/EstimatedPnL.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,UAAU;AACX","sourcesContent":[".pnl :global(.ant-descriptions-item-content) {\n\ttext-align: right;\n}\n\n.cursor {\n\tcursor: pointer;\n\tcolor: #1677ff;\n}\n\n.locked {\n\tcolor: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pnl": "e8qxmjUH3qso5NL1xUDZ",
	"cursor": "ijlDocrdkeQIOSt4V5AC",
	"locked": "PFCQY7EGDOVjy9ovmzf9"
};
export default ___CSS_LOADER_EXPORT___;
