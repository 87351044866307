import { FeatureFlags } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { useAuth } from '@client/lib/auth';

const useFeatureFlag = (featureFlag: Values<typeof FeatureFlags>): boolean => {
	const { userInfo } = useAuth();

	// If testing a feature flag that doesn't exist, assume it's enabled
	if (!Object.values(FeatureFlags).includes(featureFlag)) {
		return true;
	}

	return userInfo?.featureFlags != null && userInfo.featureFlags.includes(featureFlag);
};

export default useFeatureFlag;
