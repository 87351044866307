// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vs_MzsYrHe12hOoPFS3Z {\n\tposition: fixed;\n\tz-index: 100;\n\tpadding: 0 16px;\n\tborder-radius: 20px;\n\tfont-size: 16px;\n\tbackground-color: #ce0000;\n\tcolor: white;\n\tbox-shadow: #ce0000aa 0 0 5px 1px;\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.hVupvlb59OCk5okqGhWw {\n\ttop: 16px;\n\tright: 16px;\n}\n\n.MpPK6Sngn3cmaXaCjoWg {\n\tbottom: 16px;\n\tright: 16px;\n}\n\n.JGPHnvukRfBgtnOvJE9F {\n\tbottom: 16px;\n\tleft: 16px;\n}\n\n.RIoLdX59CTE0V0s7kxQi {\n\ttop: 16px;\n\tleft: 16px;\n}\n\n.Mx54yv8Oz68ZcirWxnfd {\n\tcolor: white;\n}\n\n.aHks2nXEU5XZHQ4bzZeo {\n\tmargin-left: 8px;\n}\n\n.Mx54yv8Oz68ZcirWxnfd:focus {\n\tcolor: white;\n\tbackground-color: transparent;\n}\n\n.Mx54yv8Oz68ZcirWxnfd:hover {\n\tcolor: white;\n\tbackground-color: #fff4;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/ImpersonationBar.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,YAAY;CACZ,eAAe;CACf,mBAAmB;CACnB,eAAe;CACf,yBAAyB;CACzB,YAAY;CACZ,iCAAiC;CACjC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,SAAS;CACT,WAAW;AACZ;;AAEA;CACC,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,YAAY;CACZ,UAAU;AACX;;AAEA;CACC,SAAS;CACT,UAAU;AACX;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,6BAA6B;AAC9B;;AAEA;CACC,YAAY;CACZ,uBAAuB;AACxB","sourcesContent":[".container {\n\tposition: fixed;\n\tz-index: 100;\n\tpadding: 0 16px;\n\tborder-radius: 20px;\n\tfont-size: 16px;\n\tbackground-color: #ce0000;\n\tcolor: white;\n\tbox-shadow: #ce0000aa 0 0 5px 1px;\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.topRight {\n\ttop: 16px;\n\tright: 16px;\n}\n\n.bottomRight {\n\tbottom: 16px;\n\tright: 16px;\n}\n\n.bottomLeft {\n\tbottom: 16px;\n\tleft: 16px;\n}\n\n.topLeft {\n\ttop: 16px;\n\tleft: 16px;\n}\n\n.button {\n\tcolor: white;\n}\n\n.stopButton {\n\tmargin-left: 8px;\n}\n\n.button:focus {\n\tcolor: white;\n\tbackground-color: transparent;\n}\n\n.button:hover {\n\tcolor: white;\n\tbackground-color: #fff4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vs_MzsYrHe12hOoPFS3Z",
	"topRight": "hVupvlb59OCk5okqGhWw",
	"bottomRight": "MpPK6Sngn3cmaXaCjoWg",
	"bottomLeft": "JGPHnvukRfBgtnOvJE9F",
	"topLeft": "RIoLdX59CTE0V0s7kxQi",
	"button": "Mx54yv8Oz68ZcirWxnfd",
	"stopButton": "aHks2nXEU5XZHQ4bzZeo"
};
export default ___CSS_LOADER_EXPORT___;
