import React from 'react';
import { Moment } from 'moment';
import { Typography } from 'antd';
import { SHORT_DATE_AND_TIME } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import styles from './PrettyDate.module.css';

type Props = {
	date: Moment;
	level?: 1 | 2 | 3 | 4 | 5;
	format?: 'utc' | 'localTime';
}

const PrettyDate = ({
	date,
	level = 3,
	format = 'localTime',
}: Props) => {
	const formatHack = format === 'localTime' ? 'localTime' : 'utc';

	const formattedDate = formatDate(
		date,
		SHORT_DATE_AND_TIME,
		true,
		formatHack,
	);

	if (formattedDate == null) {
		return (<></>);
	}

	const [dateStr, time, offset] = formattedDate.split(' ');

	const offsetSize = (level < 5) ? level + 1 : 1;

	const smallestLevel = level === 5;

	return (
		<div className={styles.container}>
			<Typography.Title
				level={level}
				className={styles.date}
			>
				{dateStr}
				{' '}
				{time}
			</Typography.Title>
			{smallestLevel ? (
				<Typography.Text
					className={styles.offset}
				>
					{format === 'utc' ? 'UTC' : offset}
				</Typography.Text>
			) : (
				<Typography.Title
					className={styles.offset}
					// @ts-ignore
					level={offsetSize}
				>
					{format === 'utc' ? 'UTC' : offset}
				</Typography.Title>
			)}
		</div>
	);
};

export default PrettyDate;
