import React from 'react';
import { Typography } from 'antd';
import {
	CrewReportTypes,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import { TimeFormat } from '@shared/utils/date';
import { round } from '@shared/utils/math';
import { capitalize } from '@shared/utils/string';
import type { DepartureReportProps } from '@api/models/departure-report';
import { formatDate } from '@client/utils/formatDate';
import Details from '@client/components/Details';
import Table from '@client/components/Table/Table';
import { coordinatesToDMSString } from '@client/utils/coordinates';
import Map from '@client/components/Map/Map';
import ReportsLayer from '@client/components/Map/layers/ReportsLayer';
import styles	from './styles/NoonReportDrawerContent.module.css';

const DepartureReportDrawerContent = ({
	report,
	selectedTimeFormat,
}: {
	report: DepartureReportProps;
	selectedTimeFormat?: TimeFormat;
}) => {
	const consumptionColumns = [
		{
			title: 'Engine',
			dataIndex: 'engine',
			key: 'engine',
		},
		{
			title: 'Used for',
			dataIndex: 'reason',
			key: 'reason',
		},
		{
			title: 'Fuel Grade',
			dataIndex: 'fuelType',
			key: 'fuelType',
		},
		{
			title: 'Consumption',
			dataIndex: 'tons',
			key: 'tons',
			render: (tons: number) => (tons != null ? `${round(tons, 3)} tons` : ''),
		},
		{
			title: 'Time',
			dataIndex: 'hours',
			key: 'hours',
			render: (hours: number) => (hours != null ? `${round(hours)} hours` : ''),
		},
	];

	const robColumns = [
		{
			title: 'Fuel Grade',
			dataIndex: 'fuelType',
			key: 'fuelType',
		},
		{
			title: 'Quantity',
			dataIndex: 'tons',
			key: 'tons',
			render: (tons: number) => (tons != null ? `${round(tons, 3)} tons` : ''),
		},
	];

	const detailItems = [
		{
			key: 'type',
			label: 'Type',
			value: CrewReportTypes.DEPARTURE,
		},
		{
			key: 'date',
			label: 'Date',
			value: formatDate(report.date, DATE_AND_TIME, true, selectedTimeFormat),
		},
		{
			key: 'port',
			label: 'Departure Port',
			value: report.departurePortName,
		},
		{
			key: 'anchorage',
			label: 'Anchorage',
			value: report.anchorage,
		},
		{
			key: 'position',
			label: 'Position',
			value: coordinatesToDMSString({
				lat: report.latitude,
				long: report.longitude,
			}),
		},
		{
			key: 'loadCondition',
			label: 'Load Condition',
			value: report.loadCondition != null ?
				capitalize(report.loadCondition) :
				undefined,
		},
		{
			key: 'totalQuantity',
			label: 'Total Quantity Loaded/Discharged',
			value: report.totalQuantity,
		},
		{
			key: 'totalQuantityUnit',
			label: 'Total Quantity Unit',
			value: report.totalQuantityUnit,
		},
		{
			key: 'draftFore',
			label: 'Draft Fore',
			value: `${report.draftFore} meters`,
		},
		{
			key: 'draftMid',
			label: 'Draft Mid',
			value: `${report.draftMid} meters`,
		},
		{
			key: 'draftAft',
			label: 'Draft Aft',
			value: `${report.draftAft} meters`,
		},
		{
			key: 'remarks',
			label: 'Remarks',
			value: report.remarks,
			maxHeight: 200,
			style: {
				whiteSpace: 'pre-wrap',
				overflow: 'auto',
			} as React.CSSProperties,
		},
	];

	return (
		<>
			<Typography.Title level={5}>Details</Typography.Title>
			<Details
				items={detailItems}
				hideHeader
			/>
			<br />
			<Typography.Title level={5}>Consumption since EOSP</Typography.Title>
			<Table
				columns={consumptionColumns}
				dataSource={report.consumptionItemsEOSP || []}
				pagination={false}
			/>
			<br />
			<Typography.Title level={5}>ROBs</Typography.Title>
			<Table
				columns={robColumns}
				dataSource={report.robItems || []}
				pagination={false}
			/>
			<br />
			<>
				<Typography.Title level={5}>Position</Typography.Title>
				<div className={styles.map}>
					<Map
						defaultPosition={{
							latitude: report.latitude,
							longitude: report.longitude,
						}}
					>
						{({ viewport }) => (
							<ReportsLayer
								reports={[{ ...report, type: CrewReportTypes.DEPARTURE }]}
								zoom={viewport.zoom}
								openReportDrawer={undefined}
							/>
						)}
					</Map>
				</div>
			</>
		</>
	);
};

export default DepartureReportDrawerContent;
