import React, { useState } from 'react';
import {
	Card,
	Divider,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
	AttachmentTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import { getTcPnl } from '@client/lib/api';
import Details from '@client/components/Details';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import Button from '@client/components/Button';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import LoadingIndicator from '@client/components/LoadingIndicator';
import getTcPnlEstimateItems, { getTcFixtureEstimateColumns } from '@client/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/helpers/getTcPnlEstimateItems';
import styles from './styles/SummaryCard.module.css';

const SummaryCard = ({
	itemGroups,
	fixture,
	setFixtureRecapGeneratorOpen,
	refreshFixture,
	currency,
}) => {
	const [activeTab, setActiveTab] = useState(fixture.type === FixtureTypes.BB_OUT ? 'summary' : 'estimate');
	const isTcInContract = fixture.type === FixtureTypes.TC_IN;

	const [pnlData, _refreshPnl, pnlLoading] = useFetchedState(
		() => (fixture.voyageId ? getTcPnl(fixture.voyageId, true) : null),
		[fixture],
		{},
	);

	let revenueTable = null;
	let expenseTable = null;
	let summaryTable = null;

	if (!pnlLoading && pnlData) {
		const { revenueSection, expenseSection, resultsSection } = getTcPnlEstimateItems(pnlData);
		revenueTable = getTcFixtureEstimateColumns(revenueSection, currency, fixture);
		expenseTable = getTcFixtureEstimateColumns(expenseSection, currency, fixture);
		summaryTable = getTcFixtureEstimateColumns(resultsSection, currency, fixture);
	}

	return (
		<Card
			activeTabKey={activeTab}
			onTabChange={setActiveTab}
			tabList={[
				...(fixture.type !== FixtureTypes.BB_OUT ?
					[{
						tab: 'Estimate',
						key: 'estimate',
					}] : []),
				{
					tab: 'Summary',
					key: 'summary',
				},
				{
					tab: 'Documents',
					key: 'documents',
				}]}
		>
			{activeTab === 'summary' && (
				<>
					<div style={{ textAlign: 'center' }}>
						<Button
							disabled={!fixture.fixed}
							disabledTooltip="You cannot generate a recap before estimate is marked as fixed"
							type="primary"
							onClick={() => setFixtureRecapGeneratorOpen(true)}
							icon={(
								<FontAwesomeIcon
									className={styles.icon}
									icon={faFilePdf}
								/>
							)}
						>
							Generate Recap
						</Button>
					</div>
					{itemGroups.map((group) => (
						<>
							<Divider>{group.header}</Divider>
							<Details
								colon={false}
								bordered
								size="small"
								hideHeader
								boldValue
								items={group.items}
								clean
								className={styles.details}
							/>
						</>
					))}
				</>
			)}
			{activeTab === 'documents' && (
				<AttachmentLibrary
					attachments={fixture.attachments}
					refreshAttachments={refreshFixture}
					type={AttachmentTypes.FIXTURE}
					attachToId={fixture.id}
				/>
			)}
			{activeTab === 'estimate' && (
				<>
					<Divider>
						<Space>
							<FontAwesomeIcon icon={faPlus} />
							{ isTcInContract ? 'Costs' : 'Revenue' }
							{
								pnlLoading ?
									(
										<LoadingIndicator
											containerClassName={styles.loadingEstimate}
											size="small"
										/>
									) :
									null
							}
						</Space>
					</Divider>
					{revenueTable}
					<Divider>Expenses</Divider>
					{expenseTable}
					<Divider>Summary</Divider>
					{summaryTable}
				</>
			)}
		</Card>
	);
};

export default SummaryCard;
