// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plrYJ5H2WvaXAAuOsUcT {\n\tfont-size: 120%;\n\tfont-weight: bold;\n}\n\n.qHunZqVKzUM9WRbZJeJz .ant-table {\n\tbackground-color: transparent;\n}\n\n.s0wMFYcpMtjV_DcK9p3w {\n\tbackground-color: transparent;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/OffHireTable.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,6BAA6B;AAC9B","sourcesContent":[".offHireBunkerTitle {\n\tfont-size: 120%;\n\tfont-weight: bold;\n}\n\n.offHireBunkerTable :global(.ant-table) {\n\tbackground-color: transparent;\n}\n\n.offHireBunkerColumn {\n\tbackground-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offHireBunkerTitle": "plrYJ5H2WvaXAAuOsUcT",
	"offHireBunkerTable": "qHunZqVKzUM9WRbZJeJz",
	"offHireBunkerColumn": "s0wMFYcpMtjV_DcK9p3w"
};
export default ___CSS_LOADER_EXPORT___;
