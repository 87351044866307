import React from 'react';
import {
	Alert,
	Space,
} from 'antd';
import { Moment } from 'moment';
import {
	DeleteOutlined,
	EditOutlined,
	PlusOutlined,
} from '@ant-design/icons';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	DATE_AND_TIME,
	HireUnit,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import Button from '@client/components/Button';
import Table from '@client/components/Table/Table';
import { formatDate } from '@client/utils/formatDate';
import { deleteRateScheduleItem } from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import styles from './styles/RateScheduleForm.module.css';

const RateScheduleTable = ({
	fixture,
	refreshFixture,
	fixtureCurrency,
	setRateScheduleDrawerOpen,
}: {
	fixture: GetFixtureDetailsResponse<TcFixtureProps>;
	refreshFixture: () => void;
	fixtureCurrency: Values<typeof Currencies>;
	setRateScheduleDrawerOpen: (open: boolean) => void;
}) => {
	const onDelete = async (id: number) => {
		try {
			await deleteRateScheduleItem(fixture.id, id);
			showSuccessNotification('Rate schedule entry deleted');
			await refreshFixture();
		} catch (e) {
			showErrorNotification('Could not delete rate schedule item', e as Error);
		}
	};

	const columns = [
		{
			dataIndex: 'from',
			title: 'From',
			render: (d: Moment | null) => (d == null ? 'N/A' : formatDate(d, DATE_AND_TIME)),

		},
		{
			dataIndex: 'to',
			title: 'To',
			render: (d: Moment | null) => (d == null ? 'N/A' : formatDate(d, DATE_AND_TIME)),
		},
		{
			dataIndex: 'rate',
			title: fixture.hireUnit === HireUnit.MONTHS ?
				'Hire rate (per month)' :
				'Hire rate (per day)',
			width: 145,
			render: (c: number) => (c == null ? (
				<em>Empty</em>
			) : formatCurrency(c, fixtureCurrency)),
			editingProps: {
				type: 'currency',
				required: true,
				inputProps: {
					placeholder: 'Hire rate',
				},
			},
			editable: true,
		},
		{
			title: '',
			render: (row: any, _idk: any, index: number) => (
				<Space>
					<EditOutlined
						onClick={() => setRateScheduleDrawerOpen(row.id)}
						className={styles.blue}
					/>
					{index > 0 && (
						<Button
							type="link"
							danger
							confirmTitle="Are you sure you want to delete this rate schedule entry?"
							onClick={() => onDelete(row.id)}
							icon={(<DeleteOutlined />)}
						/>
					)}
				</Space>
			),

		},
	];

	return (
		<Space direction="vertical">
			{fixture.rateSchedule.length > 0 ? (
				<Alert
					className={styles.marginTop}
					type="info"
					showIcon
					description="
						The first period starts at the delivery date.
						If the vessel has not delivered yet, it is assumed to start at the 'laycan to' date
					"
				/>
			) : (
				<Alert
					className={styles.marginTop}
					showIcon
					type="warning"
					message="Rate schedule unavailable"
					description={
						(
							<p>
								To use the rate schedule, please
								<br />
								&bull; Specify a minium duration in the
								{' '}
								<b>Durations</b>
								{' '}
								tab
								<br />
								&bull; Specify the laycan to in the
								{' '}
								<b>Ports & Laycan</b>
								{' '}
								tab
							</p>
						)
					}
				/>
			)}
			<Button
				disabled={!fixture.useRateSchedule}
				onClick={() => setRateScheduleDrawerOpen(true)}
				type="primary"
				icon={(<PlusOutlined />)}
			>
				Create new entry
			</Button>
			<Table
				pagination={false}
				size="small"
				columns={columns}
				dataSource={fixture.rateSchedule}
			/>
		</Space>
	);
};

export default RateScheduleTable;
