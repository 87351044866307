import React, { useState } from 'react';
import { Typography } from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { DATE } from '@shared/utils/constants';
import { calculateTotal } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { standardSort } from '@shared/utils/standardSort';
import { formatDate } from '@client/utils/formatDate';
import AddButton from '@client/components/AddButton';
import Table from '@client/components/Table/Table';
import showErrorNotification from '@client/utils/showErrorNotification';
import FormPopover from '@client/components/FormPopover';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import {
	deleteVoyagePayment,
	createVoyagePayment,
	updateVoyagePayment,
} from '@client/lib/api';
import Button from '@client/components/Button';
import { useAuth } from '@client/lib/auth';
import TooltipIcon from '@client/components/TooltipIcon';
import Card from '@client/components/Card/Card';
import validatePaymentInvoiceInput from '../helpers/validatePaymentInvoiceInput';
import styles from './styles/PaymentsTable.module.css';
import PaymentInvoiceInput from './PaymentInvoiceInput';
import PaymentPartRow from './PaymentPartRow';

const PaymentsTable = ({
	voyageId,
	voyageDetails,
	payments,
	refreshDetails,
}) => {
	const [formAmount, setFormAmount] = useState(null);
	const { userInfo } = useAuth();

	const deletePayment = async (id) => {
		try {
			await deleteVoyagePayment(voyageId, id);
			await refreshDetails();

			showSuccessNotification(
				'Payment deleted',
			);
		} catch (e) {
			showErrorNotification('Could not delete payment', e);
		}
	};

	const editPayment = async (id, attributes) => {
		try {
			await updateVoyagePayment(id, voyageId, attributes);

			await refreshDetails();

			showSuccessNotification(
				'Payment saved',
			);
		} catch (e) {
			showErrorNotification('Could not edit payment', e);
		}
	};

	const createPayment = async (values) => {
		const {
			paymentDate,
			amount: totalAmount,
			parts,
			bankCharge,
		} = values;

		await createVoyagePayment(voyageId, paymentDate, totalAmount, parts, bankCharge);
		await refreshDetails();
	};

	const columns = [
		{
			title: 'Date',
			dataIndex: 'paymentDate',
			sorter: standardSort('paymentDate'),
			defaultSortOrder: 'desc',
			render: (date) => formatDate(date, DATE),
		},
		{
			align: 'right',
			title: 'Amount',
			dataIndex: 'amount',
			render: (v) => formatCurrency(v, fixtureCurrency),
		},
		{
			title: '',
			align: 'right',
			dataIndex: '',
			className: styles.editButton,
			render: (values, payment) => (
				<FormPopover
					title="Edit payment"
					onSubmit={(v) => editPayment(
						payment.id,
						v,
					)}
					fields={newPaymentFields}
					initialValues={values}
					placement="leftTop"
					onOpenChange={(visible) => setFormAmount(visible ? values.amount : null)}
					popoverWidth={500}
					destroyTooltipOnHide
				>
					<Button
						className={styles.editButton}
						icon={(<EditOutlined />)}
						type="link"
					/>
				</FormPopover>
			),

		},
		{
			title: '',
			align: 'left',
			dataIndex: '',
			render: (_, payment) => (
				<Button
					confirmTitle="Are you sure you want to delete this payment?"
					onClick={() => deletePayment(payment.id)}
					className={styles.deleteButton}
					icon={(<DeleteOutlined />)}
					danger
					type="link"
				/>
			),
		},
	];

	const fixtureCurrency = voyageDetails.bankAccount == null ?
		userInfo.baseCurrency :
		voyageDetails.bankAccount.currency;

	const newPaymentFields = (
		[
			{
				name: 'paymentDate',
				label: 'Payment date',
				type: 'date',
				required: true,
			},
			{
				name: 'amount',
				label: 'Amount',
				type: 'currency',
				required: true,
				inputProps: {
					allowNegative: true,
					onChange: (v) => setFormAmount(v),
					currency: fixtureCurrency,
				},
			},
			{
				name: 'parts',
				label: 'Invoice(s)',
				required: false,
				renderInput: () => (
					<PaymentInvoiceInput
						hireInvoices={voyageDetails.hireInvoices}
						totalAmount={formAmount}
						currency={fixtureCurrency}
					/>
				),
				rules: [{
					validator: async (_rule, value) => validatePaymentInvoiceInput(
						formAmount,
						value,
						fixtureCurrency,
					),
				}],
			},
			{
				name: 'bankCharge',
				label: (
					<>
						Bank Charge
						<TooltipIcon>
							If a bank charge was subtracted from the payment, add it here.
							<br />
							Bank charges will be shown on the cumulative hire statement.
						</TooltipIcon>
					</>
				),
				required: false,
				type: 'currency',
				inputProps: {
					currency: fixtureCurrency,
				},
			},
		]
	);

	return (
		<Card
			slim
			className={styles.paginationPadding}
			extra={(
				<FormPopover
					title="Add payment"
					onSubmit={createPayment}
					fields={newPaymentFields}
					placement="leftTop"
					onOpenChange={() => setFormAmount(null)}
					data-tour="createPaymentForm"
					popoverWidth={500}
					destroyTooltipOnHide
				>
					<AddButton
						data-tour="createPaymentButton"
					>
						New Payment
					</AddButton>
				</FormPopover>
			)}
			title="Payments"
		>
			<Table
				className={styles.paymentTable}
				columns={columns}
				pagination={false}
				dataSource={payments}
				expandable={{
					expandedRowRender: (row) => {
						const partsTotal = calculateTotal(row.parts, (part) => part.amount);

						return (
							<div className={styles.expandedRow}>
								{row.bankCharge != null && row.bankCharge > 0 && (
									<>
										<strong>Bank charge:</strong>
										{' '}
										{formatCurrency(row.bankCharge, fixtureCurrency)}
										<hr />
									</>
								)}
								<Typography.Title level={4}>Payment was attributed to</Typography.Title>
								<div className={styles.partsWrapper}>
									{row.parts.map((part) => (
										<PaymentPartRow
											key={part.hireInvoiceIdentifier}
											identifier={part.hireInvoiceIdentifier}
											amount={part.amount}
											currency={fixtureCurrency}
										/>
									))}
									{row.amount > partsTotal && (
										<PaymentPartRow
											identifier="No invoice"
											amount={row.amount - partsTotal}
											currency={fixtureCurrency}
										/>
									)}
								</div>
							</div>
						);
					},
				}}
				rowKey="id"
				size="small"
			/>
		</Card>
	);
};

export default PaymentsTable;
