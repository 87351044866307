// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CnZXBbyLmqyjbSCCW8Mw {\n\tmargin-left: 20px;\n\tmax-width: 50%;\n\twidth: 100%;\n}\n\n.dRQswgd73KrPByspAvMW .ant-tabs-nav {\n\tmargin: 0;\n\tmax-width: 90%;\n}\n\n.aYG7yuCPYV6PG9q35KKQ .ant-card-body {\n\tpadding: 0;\n\tpadding-left: 0;\n\tpadding-right: 16px;\n}\n\n.eLz8Y1xJSDcRdgYULHtU {\n\tposition: relative;\n\tmargin-right: 15px;\n\tfilter: grayscale(100%);\n}\n\n.oStqu0QCN4RU6gfUNQCp {\n\tcolor: darkgrey;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/details/components/styles/TopBar.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,cAAc;CACd,WAAW;AACZ;;AAEA;CACC,SAAS;CACT,cAAc;AACf;;AAEA;CACC,UAAU;CACV,eAAe;CACf,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,uBAAuB;AACxB;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".tabContainer {\n\tmargin-left: 20px;\n\tmax-width: 50%;\n\twidth: 100%;\n}\n\n.tabs :global(.ant-tabs-nav) {\n\tmargin: 0;\n\tmax-width: 90%;\n}\n\n.card :global(.ant-card-body) {\n\tpadding: 0;\n\tpadding-left: 0;\n\tpadding-right: 16px;\n}\n\n.loadingIndicator {\n\tposition: relative;\n\tmargin-right: 15px;\n\tfilter: grayscale(100%);\n}\n\n.savingText {\n\tcolor: darkgrey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "CnZXBbyLmqyjbSCCW8Mw",
	"tabs": "dRQswgd73KrPByspAvMW",
	"card": "aYG7yuCPYV6PG9q35KKQ",
	"loadingIndicator": "eLz8Y1xJSDcRdgYULHtU",
	"savingText": "oStqu0QCN4RU6gfUNQCp"
};
export default ___CSS_LOADER_EXPORT___;
