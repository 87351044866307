import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Checkbox,
	Drawer,
	Form,
	Input,
	Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { GetCounterpartiesResponse } from '@api/features/counterparties/getCounterparties';
import type { RevenueItemProps } from '@api/models/revenue-item';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import {
	createRevenueItem,
	updateRevenueItem,
} from '@client/lib/api';
import TooltipIcon from '@client/components/TooltipIcon';
import MultiCurrencyInput from '../MultiCurrencyInput';
import Select from '../Select';
import Button from '../Button';
import { Cargo } from '../CargoCard';

type CreateRevenueFormProps = {
	voyageId: number;
	cargos: Cargo[] | [];
	editingItem?: RevenueItemProps | null;
	open: boolean;
	charterers?: GetCounterpartiesResponse;
	cargoCharterers: (number | null)[];
	fixtureCurrency: Values<typeof Currencies>;
	extraOnSave: () => void;
	onClose: () => void;
}

const CreateRevenueForm = ({
	voyageId,
	cargos,
	editingItem,
	open,
	charterers,
	cargoCharterers,
	fixtureCurrency,
	extraOnSave,
	onClose,
}: CreateRevenueFormProps) => {
	const [form] = useForm();
	const required = [{ required: true, message: 'Field is required' }];

	const [selectedCharterer, setSelectedCharterer] = useState<number | null>(null);

	useEffect(() => {
		form.resetFields();
		setSelectedCharterer(null);
	}, [form, open]);

	const saveRevenueItem = async () => {
		const {
			amount,
			chartererId,
			cargoId,
			itemDescription,
			note,
			subjectToCommissions,
			estimated,
		} = form.getFieldsValue(true);

		try {
			const params = {
				itemDescription,
				cargoId,
				amount: amount ? amount.value : 0,
				currency: amount ? amount.currency : Currencies.USD,
				exchangeRate: amount?.exchangeRate || null,
				note,
				voyageId,
				chartererId,
				subjectToCommissions: subjectToCommissions != null ? subjectToCommissions : false,
				estimated,
			};

			if (editingItem != null) {
				await updateRevenueItem({ id: editingItem.id, ...params });
				showSuccessNotification(
					'Expense updated',
					'The expense was successfully updated',
				);
			} else {
				await createRevenueItem(params);
				showSuccessNotification(
					'Revenue item created',
					'The revenue item was successfully created',
				);
			}

			extraOnSave();
		} catch (e) {
			console.error(e);
			showErrorNotification('Could not save revenue item', e as Error);
		}
	};

	useEffect(() => {
		setSelectedCharterer(editingItem?.chartererId ?? null);
	}, [editingItem]);

	useEffect(() => {
		if (editingItem != null) {
			form.setFieldsValue({
				...editingItem,
				amount: {
					value: editingItem.amount,
					currency: editingItem.currency,
					exchangeRate: editingItem.exchangeRate,
				},
			});
		}
	}, [editingItem, form]);

	const cargoField = useMemo(() => {
		const relevantCargos = cargos.filter((c) => c.charterer === selectedCharterer);

		if (relevantCargos.length === 1) {
			form.setFieldsValue({
				cargoId: relevantCargos[0].id,
			});

			return '';
		}

		if (form.getFieldValue('chartererId') == null && selectedCharterer == null) {
			return '';
		}

		return (
			<Form.Item
				name="cargoId"
				label="Cargo"
				rules={required}
			>
				<Select
					options={
						relevantCargos.map((c) => ({
							label: `${c.type}  ${`for ${charterers?.find((charterer) => charterer.id === c.charterer)?.name}`}`,
							value: c.id,
						}))
					}
				/>
			</Form.Item>
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cargos, charterers, form, selectedCharterer]);

	const expandedOnClose = () => {
		onClose();
		form.resetFields();
	};

	return (
		(
			<Drawer
				title={editingItem != null ? 'Editing revenue item' : 'Create revenue item'}
				open={open}
				onClose={expandedOnClose}
				width={500}
			>
				<Form
					form={form}
					layout="vertical"
				>
					<Form.Item
						name="chartererId"
						label="Charterer"
						rules={required}
					>
						<Select
							onChange={(v) => {
								setSelectedCharterer(v as number);
								form.resetFields(['cargoId']);
							}}
							options={
								charterers?.filter((c) => cargoCharterers.some((cc) => cc === c.id)).map((c) => ({
									label: c.name,
									value: c.id,
								}))
							}
						/>
					</Form.Item>
					{cargoField}
					<Form.Item
						name="itemDescription"
						label="Item Description"
						rules={required}
					>
						<Input type="text" />
					</Form.Item>
					<Form.Item
						name="amount"
						label="Amount"
						rules={required}
					>
						<MultiCurrencyInput baseCurrency={fixtureCurrency} />
					</Form.Item>
					<Form.Item
						name="subjectToCommissions"
						label="Subject to commissions?"
						valuePropName="checked"
					>
						<Checkbox />
					</Form.Item>
					<Form.Item
						name="estimated"
						valuePropName="checked"
						initialValue={false}
						label={(
							<Space>
								Estimated
								<TooltipIcon>
									Estimated items are used for estimating P&L items,
									and cannot be invoiced or put through
									an approvals process.
									If you wish to invoice the item,
									convert the item to an actual by
									deselecting the &quot;estimated&quot; checkbox
								</TooltipIcon>
							</Space>
						)}
					>
						<Checkbox />
					</Form.Item>
					<Form.Item
						name="note"
						label="Internal note"
					>
						<Input.TextArea />
					</Form.Item>
				</Form>
				<Space>
					<Button
						onClick={saveRevenueItem}
						type="primary"
					>
						Save & Close
					</Button>
					<Button
						onClick={expandedOnClose}
					>
						Close
					</Button>
				</Space>
			</Drawer>
		)
	);
};

export default CreateRevenueForm;
