import React, { SetStateAction } from 'react';
import {
	Checkbox,
	Space,
	Tag,
	Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Moment } from 'moment/moment';
import {
	AccountingItemApprovalStates,
	DATE,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import { formatCurrency } from '@shared/utils/currency';
import { capitalize } from '@shared/utils/string';
import type { VesselProps } from '@api/models/vessel';
import type { GetAccountingItemsResponse } from '@api/features/financials/getAccountingItems';
import { formatDate } from '@client/utils/formatDate';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';
import StateTag from '@client/components/StateTag';
import Button from '@client/components/Button';
import { postAccountingItems } from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';

type Props = {
	selectedItems: Array<string>;
	setSelectedItems: React.Dispatch<SetStateAction<string[]>>;
	filterMaps: {
		itemTypes: Array<string>;
		approvalStates: Array<string>;
		vessels: Array<string>;
		contracts: Array<string>;
		counterparties: Array<string>;
	};
	getCounterpartyUrl: (s: GetAccountingItemsResponse[number]) => string;
	onSuccess: () => void;
	selectAll?: (deselect: boolean) => void;
	dateRange: [Moment, Moment] | null;
	dateDropdownVisible: boolean;
	setDateDropdownVisible: React.Dispatch<boolean>;
	filterDropdown: React.ReactElement;
}

export const getArApColumns = ({
	selectAll,
	selectedItems,
	setSelectedItems,
	filterMaps,
	getCounterpartyUrl,
	onSuccess,
	dateRange,
	dateDropdownVisible,
	setDateDropdownVisible,
	filterDropdown,
}: Props): ColumnsType<GetAccountingItemsResponse[number]> => ([
	{
		...(selectAll != null ? { title: (<Checkbox onChange={(e) => {
			if (e.target.checked) {
				selectAll(false);
			} else {
				selectAll(true);
			}
		}}
		/>
		) } : {}),
		render: (_, row) => (
			<Checkbox
				checked={row.posted || selectedItems.find((e) => e === row.customId) != null}
				disabled={row.posted || row.state !== AccountingItemApprovalStates.APPROVED}
				onClick={(e) => {
					e.stopPropagation();
				}}
				onChange={(e) => {
					if (e.target.checked) {
						setSelectedItems([...selectedItems, row.customId]);
					} else {
						setSelectedItems(selectedItems.filter((entry) => entry !== row.customId));
					}
				}}
			/>
		),
	},
	{
		title: 'Type',
		dataIndex: 'type',
		render: (c) => capitalize(c),
		filters: filterMaps.itemTypes.map((itemType) => ({
			text: capitalize(itemType),
			value: itemType,
		})),
		filterMultiple: true,
		key: 'type',
		onFilter: (
			value: any,
			record: GetAccountingItemsResponse[number],
		) => record.type === value,
	},
	{
		title: 'Vessel',
		dataIndex: 'Vessel',
		render: (vessel: VesselProps | undefined) => (
			vessel != null ?
				(<LinkButton url={Links.Vessel.get(vessel.id)}>{vessel.name}</LinkButton>) :
				''),
		filters: filterMaps.vessels.map((vessel) => ({
			text: vessel,
			value: vessel,
		})),
		filterMultiple: true,
		key: 'Vessel',
		onFilter: (value, record) => record.Vessel?.name === value,
	},
	{
		title: 'Item',
		dataIndex: 'item',
	},
	{
		title: 'Description',
		dataIndex: 'description',
		width: 175,
	},
	{
		title: 'Counterparty',
		dataIndex: 'counterparty',
		filters: filterMaps.counterparties.map((cp) => ({
			text: cp,
			value: cp,
		})),
		filterMultiple: true,
		key: 'counterparty',
		onFilter: (
			value: any,
			record: GetAccountingItemsResponse[number],
		) => record?.counterparty === value,
		render: (counterparty, row) => (
			row.counterpartyId == null ?
				counterparty :
				(<LinkButton url={getCounterpartyUrl(row)}>{counterparty}</LinkButton>)
		),
	},
	{
		title: 'Contract',
		dataIndex: 'contract',
		render: (identifier, row) => (identifier == null ? 'Voyage not found' : (
			row.voyageId == null ?
				identifier :
				(
					<div>
						<LinkButton
							url={Links.Voyage.get(row.voyageId)}
						>
							{identifier}
						</LinkButton>
						<Typography />
					</div>

				)
		)),
		filters: filterMaps.contracts.map((contract) => ({
			text: contract,
			value: contract,
		})),
		filterMultiple: true,
		key: 'contract',
		onFilter: (value, record) => record.contract === value,
	},
	{
		title: 'Date',
		dataIndex: 'date',
		key: 'date',
		filters: [],
		filtered: dateRange != null,
		filteredValue: dateRange != null && dateRange?.length > 0 ?
			dateRange as any :
			[],
		filterDropdownVisible: dateDropdownVisible,
		onFilter: (
			value: any,
			record: any,
		) => {
			if (dateRange != null) {
				const date = toMoment(record.date);

				return (
					date?.isSameOrAfter(dateRange[0]) &&
					date?.isSameOrBefore(dateRange[1])
				);
			}

			return true;
		},
		onFilterDropdownVisibleChange: setDateDropdownVisible,
		filterDropdown,
		sorter: (a, b) => {
			if (toMoment(a.date).isAfter(toMoment(b.date))) {
				return -1;
			}

			return 1;
		},
		render: (date) => date != null && formatDate(date, DATE),
	},
	{
		title: 'Base Amount',
		dataIndex: 'baseAmount',
		align: 'right',
		render: (amount, row) => (
			amount == null ?
				' - ' :
				formatCurrency(amount, row.currency, { forceDecimals: true })
		),
	},
	{
		title: 'Approval',
		dataIndex: 'state',
		render: (state) => (<StateTag state={state} />),
		filters: filterMaps.approvalStates.map((state) => ({
			text: capitalize(state),
			value: state,
		})),
		filterMultiple: true,
		key: 'state',
		onFilter: (value, record) => record.state === value,
	},
	{
		title: 'Posted',
		dataIndex: 'posted',
		render: (posted, row) => {
			return (
				<Space>
					<Tag color={!posted ? 'orange' : 'geekblue'}>
						{!posted ? 'Not posted' : 'Posted'}
					</Tag>
					{posted && (
						<Button
							onClick={async () => {
								await postAccountingItems([row.customId], true);
								onSuccess();
								showSuccessNotification('Succesfully unposted item');
							}}
							size="small"
							type="link"
							confirmTitle="Are you sure you want to unpost this item? It will not reflect in the accounting system. This is a dangerous action you should not take unless you are stuck"
							danger
						>
							Undo
						</Button>
					)}
				</Space>
			);
		},
	},
]);
