import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { formatCurrency } from '@shared/utils/currency';
import { toMoment } from '@shared/utils/date';
import type { GetHireInvoiceDetailsResponse } from '@api/features/invoices/hire-invoices/getHireInvoiceDetails';
import type { AttachmentProps } from '@api/models/attachment';
import type { GetCounterpartiesResponse } from '@api/features/counterparties/getCounterparties';
import Button from '@client/components/Button';
import Spacing from '@client/components/Spacing';
import { EditableDetailsItem } from '@client/components/EditableField';

export const getItems = (
	invoice: GetHireInvoiceDetailsResponse,
	charterers: GetCounterpartiesResponse,
	onOpenSupportingDocument: (attachment: AttachmentProps) => void,
	onDownloadSupportingDocuments: () => void,
) => {
	const {
		invoiceNumber,
		amount,
		invoiceDate,
		cumulative,
		supportingDocuments,
		note,
		fixtureCurrency,
		invoiceTitle,
		chartererId,
	} = invoice;

	const items: EditableDetailsItem<any>[] = [
		{
			key: 'invoiceTitle',
			label: 'Invoice Title',
			value: invoiceTitle,
			editable: true,
			type: 'text',
		},
		{
			key: 'invoiceDate',
			label: 'Invoice Date',
			value: toMoment(invoiceDate),
			editable: true,
			type: 'date',
		},
		{
			key: 'identifier',
			label: 'Invoice Number',
			value: `INV-${invoiceNumber}`,
		},
		{
			key: 'chartererId',
			label: 'Charterer',
			value: chartererId,
			render: () => {
				const name = charterers?.find((c) => c.id === chartererId)?.name;

				return (<span>{name}</span>);
			},
		},
		{
			key: 'amount',
			label: 'Amount',
			value: amount == null ? '—' : formatCurrency(amount, fixtureCurrency),
		},
		{
			key: 'cumulative',
			label: 'Cumulative',
			value: cumulative ? 'Yes' : 'No',
		},
		{
			key: 'note',
			label: 'Note',
			value: note,
		},
		{
			key: 'supportingDocuments',
			label: 'Documents',
			alignLabel: 'top',
			value: supportingDocuments != null && supportingDocuments.length > 0 ? (
				<>
					<Button
						type="primary"
						onClick={onDownloadSupportingDocuments}
						icon={(<DownloadOutlined />)}
					>
						Download all
					</Button>
					<Spacing size={8} />
					{supportingDocuments.map((attachment) => (
						<div key={attachment.id}>
							<Button
								onClick={() => onOpenSupportingDocument(attachment)}
								type="link"
								padding={0}
							>
								{attachment.name}
							</Button>
						</div>
					))}
				</>
			) : null,
		},
	];

	return items;
};
