// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LfBNZwZLCColyYzpvX0w {\n\tfont-size: 14pt;\n\ttransform: translate(-50%);\n\tbackground: black;\n\tborder: 2px solid black;\n\tbox-sizing: content-box;\n\tborder-radius: 5px 13px 5px 5px;\n\tcursor: pointer;\n}\n\n.PfVuwuVgbSwoctYZk5Hy svg {\n\tcursor: pointer;\n}\n\n.VgOwKd7adzxaKbTwZLF7 {\n\tfont-size: 24pt;\n}\n\n.VgOwKd7adzxaKbTwZLF7 path {\n\tstroke: black;\n\tstroke-width: 20px;\n}\n\n.AqoHx7bocQs6cQ50634j path {\n\tstroke: black;\n\tstroke-width: 25px;\n}\n\n.AqoHx7bocQs6cQ50634j {\n\tfont-size: 2em;\n}\n\n.y6W13BSbv7uTUaGJaSAJ ul {\n\tpadding-left: 16px;\n\tmargin: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/Map/layers/styles/ReportsLayer.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,0BAA0B;CAC1B,iBAAiB;CACjB,uBAAuB;CACvB,uBAAuB;CACvB,+BAA+B;CAC/B,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,kBAAkB;CAClB,SAAS;AACV","sourcesContent":[".fileIcon {\n\tfont-size: 14pt;\n\ttransform: translate(-50%);\n\tbackground: black;\n\tborder: 2px solid black;\n\tbox-sizing: content-box;\n\tborder-radius: 5px 13px 5px 5px;\n\tcursor: pointer;\n}\n\n.marker svg {\n\tcursor: pointer;\n}\n\n.circleIcon {\n\tfont-size: 24pt;\n}\n\n.circleIcon path {\n\tstroke: black;\n\tstroke-width: 20px;\n}\n\n.groupIcon path {\n\tstroke: black;\n\tstroke-width: 25px;\n}\n\n.groupIcon {\n\tfont-size: 2em;\n}\n\n.groupPopoverContent ul {\n\tpadding-left: 16px;\n\tmargin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileIcon": "LfBNZwZLCColyYzpvX0w",
	"marker": "PfVuwuVgbSwoctYZk5Hy",
	"circleIcon": "VgOwKd7adzxaKbTwZLF7",
	"groupIcon": "AqoHx7bocQs6cQ50634j",
	"groupPopoverContent": "y6W13BSbv7uTUaGJaSAJ"
};
export default ___CSS_LOADER_EXPORT___;
