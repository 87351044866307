import {
	DashboardForwardPeriodLabels,
	DashboardBackwardPeriodLabels,
	DashboardPeriods,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

const getDashboardPeriodTag = (
	period: Values<typeof DashboardPeriods>,
	forward = false,
) => {
	const labels = forward ? DashboardForwardPeriodLabels : DashboardBackwardPeriodLabels;

	return labels[period] || '';
};

export default getDashboardPeriodTag;
