import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const navigateToTab = (tab: string) => {
	if (getCurrentTab() === tab) {
		return;
	}

	// Remove the trailing / from the url
	const currentPath = history.location.pathname.replace(/\/$/, '');

	history.push(`${currentPath}/#/${tab}`);
};

export const getCurrentTab = () => history.location.hash.replace(/^#\//, '');

if ((window as any).Cypress) {
	(window as any).reactHistory = history;
}

export default history;
