import type Fixture from '@api/models/fixture';
import type Voyage from '@api/models/voyage';

type RowType = Pick<(Voyage | Fixture), 'identifier'>;

export const vesselIdentifierAndNameSorter = (valueA: RowType, valueB: RowType, key: string, type: string) => {
	const valueALastIndex = valueA.identifier?.lastIndexOf('-');
	const valueBLastIndex = valueB.identifier?.lastIndexOf('-');

	const firstEntryIdentifierString = valueA.identifier?.slice(0, valueALastIndex);
	const firstEntryIdentifierNumber = Number(valueA.identifier?.slice(valueALastIndex));

	const secondEntryIdentifierString = valueB.identifier?.slice(0, valueBLastIndex);
	const secondEntryIdentifierNumber = Number(valueB.identifier?.slice(valueBLastIndex));

	if (firstEntryIdentifierString === secondEntryIdentifierString) {
		if (key === 'vesselName' && type === 'descend') {
			return secondEntryIdentifierNumber - firstEntryIdentifierNumber;
		}

		return firstEntryIdentifierNumber - secondEntryIdentifierNumber;
	}

	return (valueA[key]).toString().localeCompare(valueB[key]);
};
