import React, {
	useEffect,
	useState,
} from 'react';
import { CheckboxProps } from 'antd/lib/checkbox';
import { Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { formatNumber } from '@shared/utils/formatNumber';
import showErrorNotification from '@client/utils/showErrorNotification';

type Props = Omit<CheckboxProps, 'onChange'> & {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void;
	seperateThousands?: boolean;
	inputFieldType?: 'number' | 'textarea';
};

const AsyncInput = ({
	value,
	onChange,
	seperateThousands,
	inputFieldType,
	...rest
}: Props) => {
	const [localValue, setLocalValue] = useState(value);
	const [cache, setCache] = useState(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (error) {
			setLocalValue(cache);
			setCache(null);
			setError(false);
		}
	}, [cache, error]);

	const eventSave = async (event: React.ChangeEvent<HTMLInputElement>) => {
		setCache(localValue);

		setLocalValue(event.target.value);

		try {
			if (typeof onChange === 'function') {
				await onChange(event);
				setCache(null);
			}
		} catch (e) {
			showErrorNotification('Some field(s) were not saved correctly', e as Error);
			setError(true);
		}
	};

	switch (inputFieldType) {
		case 'textarea':
			return (
				<TextArea
					onChange={async (event) => {
						if (cache == null) {
							setCache(localValue);
						}

						setLocalValue(event.target.value);
					}}
					{...rest}
					value={localValue}
					onBlur={async (event) => {
						await eventSave(event as any as React.ChangeEvent<HTMLInputElement>);
					}}
				/>
			);

		default:
			return (
				<Input
					value={
						inputFieldType === 'number' ?
							formatNumber(
								localValue, { separateThousands: seperateThousands || false },
							) || undefined : localValue
					}
					onChange={async (event) => {
						if (cache == null) {
							setCache(localValue);
						}

						setLocalValue(event.target.value);
						eventSave(event as any as React.ChangeEvent<HTMLInputElement>);
					}}
					onPressEnter={
						async (event) => await eventSave(event as any as React.ChangeEvent<HTMLInputElement>)
					}
					{...rest}
					onBlur={async (event: React.ChangeEvent<HTMLInputElement>) => {
						await eventSave(event);
					}}
				/>
			);
	}
};

export default AsyncInput;

