import React from 'react';
import { formatCurrency } from '@shared/utils/currency';
import type { GetFleetAnalyticsResponse } from '@api/features/statistics/getFleetAnalytics';
import { useAuth } from '@client/lib/auth';
import NumberWidget from '../components/NumberWidget';

type Props = {
	staticData: GetFleetAnalyticsResponse['data'] | undefined;
	loading: boolean;
}

const ForwardHireNumberWidget = ({
	staticData,
	loading,
}: Props) => {
	const { userInfo } = useAuth();

	return (
		<NumberWidget
			title="Forward Gross Hire"
			badge="GROSS"
			headerTooltip="Expected gross hire from TC Out voyages assuming earliest possible redelivery"
			staticData={staticData}
			transformData={
				(data) => formatCurrency(
					Math.round(data.forwardHire.totalRemainingHire), userInfo.baseCurrency,
				)
			}
			forward
			externalLoading={loading}
		/>
	);
};

export default ForwardHireNumberWidget;
