/* eslint-disable max-len */
import moment, { Moment } from 'moment';
import { toMoment } from '@shared/utils/date';
import { TcFixtureProps } from '@api/models/tc-fixture';
import { BbFixtureProps } from '@api/models/bb-fixture';

const getExactDurationStr = (startDate: Moment, exactDate: Moment) => {
	const maxDays = toMoment(exactDate).diff(startDate, 'day');

	return maxDays > 0 ? `${maxDays} ${maxDays > 1 ? 'days' : 'day'}` : '';
};

const formatTcAndBbFixtureDuration = (fixture: (TcFixtureProps | BbFixtureProps)) => {
	let { durationMinimumValue: minValue, durationMaximumValue: maxValue } = fixture;
	const {
		durationMinimumUnit: minUnit,
		durationMinimumVariance: minVariance,
		durationMaximumUnit: maxUnit,
		durationMaximumVariance: maxVariance,
		minimumDurationExact: minExact,
		maximumDurationExact: maxExact,
		laycanFrom,
		laycanTo,
	} = fixture;

	if (
		((minValue == null || minUnit == null) && minExact == null) ||
		((maxValue == null || maxUnit == null) && maxExact == null)
	) {
		return null;
	}

	if (laycanTo != null) {
		const startDate = toMoment(laycanTo);

		if (minUnit === 'months') {
			minValue = toMoment(startDate).add(minValue, 'months').diff(startDate, 'day');
		}

		if (minUnit === 'years') {
			minValue = toMoment(startDate).add(minValue, 'years').diff(startDate, 'day');
		}
	}

	if (laycanFrom != null && laycanTo != null) {
		const startDate = toMoment(laycanTo);

		if (maxUnit === 'months') {
			maxValue = toMoment(startDate).add(maxValue, 'months').diff(toMoment(laycanFrom), 'day');
		}

		if (maxUnit === 'years') {
			maxValue = toMoment(startDate).add(maxValue, 'years').diff(toMoment(laycanFrom), 'day');
		}

		if (maxUnit === 'days') {
			maxValue = toMoment(startDate).add(maxValue, 'days').diff(toMoment(laycanFrom), 'day');
		}
	}

	let maxDuration;
	let minDuration;

	if (minExact != null && laycanTo != null) {
		minDuration = getExactDurationStr(toMoment(laycanTo), minExact);
	}

	if (maxExact != null && laycanFrom != null) {
		maxDuration = getExactDurationStr(toMoment(laycanFrom), maxExact);
	}

	const minVarianceString = (minVariance === 0 || minVariance === null) ?
		'' :
		` ± ${minVariance} days`;
	const maxVarianceString = (maxVariance === 0 || maxVariance === null) ?
		'' :
		` ± ${maxVariance} days`;

	type UnitOfTime = 'days' | 'months' | 'years';

	const formatDuration = (
		value: number | null,
		duration: string | undefined,
		unit: UnitOfTime,
		varianceString: string,
	): string | null => {
		if (!duration && value != null) {
			const units = {
				days: value,
				months: Math.round(moment.duration(value, 'days').asMonths()),
				years: Math.round(moment.duration(value, 'days').asYears()),
			};

			const formattedValue = units[unit ?? 'days'];

			return `${formattedValue} ${unit?.slice(0, -1) ?? 'day'}${formattedValue === 1 ? '' : 's'}${varianceString}`;
		}

		return duration || null;
	};

	const minStr = formatDuration(minValue, minDuration, minUnit as UnitOfTime, minVarianceString);
	const maxStr = formatDuration(maxValue, maxDuration, maxUnit as UnitOfTime, maxVarianceString);

	if (minStr == null && maxStr == null) {
		return null;
	}

	return `${minStr ?? ''} - ${maxStr ?? ''}`;
};

export default formatTcAndBbFixtureDuration;
