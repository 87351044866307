// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PvLj5hHW5atWKauzviDa {\n\tmin-width: 40%;\n\tmax-width: 850px;\n}\n\n.MsMaoPAnP0l7fEGOZjrC {\n\tmargin-bottom: 20px;\n}\n\n.zwlYCvbFtuiSTtm8XpFm .ant-descriptions-item-label {\n\twidth: 300px;\n}\n\n.ant-select {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/management/report-groups/ReportGroups.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".tableContainer {\n\tmin-width: 40%;\n\tmax-width: 850px;\n}\n\n.alert {\n\tmargin-bottom: 20px;\n}\n\n.details :global(.ant-descriptions-item-label) {\n\twidth: 300px;\n}\n\n:global(.ant-select) {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": "PvLj5hHW5atWKauzviDa",
	"alert": "MsMaoPAnP0l7fEGOZjrC",
	"details": "zwlYCvbFtuiSTtm8XpFm"
};
export default ___CSS_LOADER_EXPORT___;
