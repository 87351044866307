import React, { useMemo } from 'react';
import { DeferredAccrualFields } from '@shared/utils/constants';
import type { GetConnectionAndMappingResponse } from '@api/features/accounting-system/getConnectionAndMapping';
import Card from '@client/components/Card/Card';
import EditableTable from '@client/components/EditableTable';
import { createMappingEntry } from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';

const AccrualTypeMappingTab = ({
	connection,
	refreshConnection,
}: {
	connection: GetConnectionAndMappingResponse | undefined;
	refreshConnection: () => void;
}) => {
	const onSave = async (
		field: string,
		result: {accountingCode: string | undefined },
	) => {
		const value = result.accountingCode;

		if (value != null && field != null && connection != null) {
			try {
				await createMappingEntry(connection.id, 'accrual', field, value);
				await refreshConnection();
				showSuccessNotification('Successfully mapped entry');
			} catch (e) {
				showErrorNotification('Could not create mapping', e as Error);
			}
		}

		refreshConnection();
	};

	const accrualsWithCode = useMemo(() => {
		if (connection == null) {
			return [];
		}

		const formatted = Object.keys(DeferredAccrualFields).map((key) => {
			const mappingEntry = connection.AccountingMappingEntries.find((mapping) => (
				mapping.type === 'accrual' &&
				mapping.cvIdentifier === String(key)
			));

			return {
				key,
				field: DeferredAccrualFields[key],
				accountingCode: mappingEntry?.code ?? undefined,
			};
		});

		return formatted;
	}, [connection]);

	return (
		<Card slim>
			<EditableTable
				actionsTitle=""
				iconButtons
				pagination={false}
				keyDataIndex="key"
				onSave={onSave}
				dataSource={accrualsWithCode ?? []}
				showSorterTooltip
				columns={[
					{
						key: 'fieldRow',
						dataIndex: 'field',
						title: 'Field',
					},
					{
						dataIndex: 'accountingCode',
						title: 'Accounting System Code',
						editable: true,
						render: (v: string | null) => (v == null ? (<i>Empty</i>) : v),
					},
				]}
			/>
		</Card>
	);
};

export default AccrualTypeMappingTab;
