// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HJUKJyxWkMDoPLti3lZv {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.Rj_pibJOFsrWCLu9NtjV {\n\twidth: 60px;\n}\n\n.Zta5FNZKO55IJkVeNJYv {\n\twidth: 100px;\n}\n\n.cMHs4aIFArhRcrUkijsp {\n\tcolor: #666;\n}\n\n.ny2Omexm00DM6nsprxRi {\n\twidth: 100px;\n}\n\n.ny2Omexm00DM6nsprxRi input {\n\ttext-align: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/PeriodSelector.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,eAAe;CACf,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.valueInput {\n\twidth: 60px;\n}\n\n.unitSelector {\n\twidth: 100px;\n}\n\n.labelRight {\n\tcolor: #666;\n}\n\n.varianceInput {\n\twidth: 100px;\n}\n\n.varianceInput input {\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "HJUKJyxWkMDoPLti3lZv",
	"valueInput": "Rj_pibJOFsrWCLu9NtjV",
	"unitSelector": "Zta5FNZKO55IJkVeNJYv",
	"labelRight": "cMHs4aIFArhRcrUkijsp",
	"varianceInput": "ny2Omexm00DM6nsprxRi"
};
export default ___CSS_LOADER_EXPORT___;
