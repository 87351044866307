import React, {
	useEffect,
	useState,
} from 'react';
import {
	Col,
	Row,
} from 'antd';
import {
	AccountingSystemStrategies,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getUserData,
	getVessels,
	getVoyages,
} from '@client/lib/api';
import MappingDetails from './MappingDetails';
import VesselsList from './VesselsList';
import ContractsList from './ContractsList';

const AccountingSystemConfigurationScreen = () => {
	const [vessels, refreshVessels] = useFetchedState(getVessels);
	const [voyages, refreshVoyages] = useFetchedState(getVoyages);

	const [userData] = useFetchedState(getUserData);
	const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

	const strategy = userData?.accountingSystemStrategy;
	const provider = userData?.accountingSystemProvider;

	useEffect(() => {
		if (userData != null) {
			setSelectedItemId(userData.organizationId);
		}
	}, [userData, setSelectedItemId]);

	const refreshItems = () => {
		refreshVessels();
		refreshVoyages();
	};

	const filteredVessels = vessels?.filter((v) => v.ownershipType !== VesselOwnershipTypes.MARKET);

	return (
		<SimpleScreen
			title="Accounting system configuration"
			rootPageTitle="Accounting Mapping"
		>
			{strategy == null || provider == null ? (
				<Row>
					<Col span={12}>
						<Card title="Accounting system not configured">
							Please contact your organization administrator to configure the accounting system.
						</Card>
					</Col>
				</Row>
			) : (
				<Row gutter={[16, 16]}>
					{strategy !== AccountingSystemStrategies.GLOBAL && (
						<Col span={6}>
							{
								strategy === AccountingSystemStrategies.VESSEL ? (
									<VesselsList
										items={filteredVessels ?? []}
										setSelectedItemId={setSelectedItemId}
										selectedItemId={selectedItemId}
									/>
								) : (
									<ContractsList
										items={voyages ?? []}
										selectedItemId={selectedItemId}
										setSelectedItemId={setSelectedItemId}
									/>
								)
							}
						</Col>
					)}
					<Col span={strategy === AccountingSystemStrategies.GLOBAL ? 24 : 18}>
						<Card title="Mapping" slim>
							{
								selectedItemId == null ? 'Select an item to get started' : (
									<MappingDetails
										refreshItems={refreshItems}
										selectedItemId={selectedItemId}
										strategy={strategy}
										provider={provider}
									/>
								)
							}
						</Card>
					</Col>
				</Row>
			)}
		</SimpleScreen>
	);
};

export default AccountingSystemConfigurationScreen;
