// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gq4mKbD_5urMh2TtpXYp {\n\tmargin-top: 5px;\n}\n\n.Dfj3M6esdQSQpA_f1gBk {\n\tmargin-bottom: 16px;\n}\n\n.p4Zr3yAbZNgp4ilDAtdt {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.p4Zr3yAbZNgp4ilDAtdt > p,\n.p4Zr3yAbZNgp4ilDAtdt > b {\n\tmargin: 0;\n}\n\n.naFcYY_UK1wVFKJDtycq {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tgap: 5px;\n}\n\n.agGmo2KOsZwtGGC5n6Wm {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.agGmo2KOsZwtGGC5n6Wm .ant-typography {\n\tcolor: gray;\n}\n\n.IM_dw5qNale1sUNji53e {\n\tcolor: red;\n\tcursor: pointer;\n\tpadding: 0;\n\theight: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/LinkedVoyages/LinkedVoyages.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;AAChB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;;CAEC,SAAS;AACV;;AAEA;CACC,aAAa;CACb,2BAA2B;CAC3B,QAAQ;AACT;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,UAAU;CACV,eAAe;CACf,UAAU;CACV,mBAAmB;AACpB","sourcesContent":[".linkedVoyage {\n\tmargin-top: 5px;\n}\n\n.linkedContractsCard {\n\tmargin-bottom: 16px;\n}\n\n.ownershipPeriod {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.ownershipPeriod > p,\n.ownershipPeriod > b {\n\tmargin: 0;\n}\n\n.contractIdentifierAndType {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\tgap: 5px;\n}\n\n.contractLink {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.contractLink :global(.ant-typography) {\n\tcolor: gray;\n}\n\n.unlinkBtn {\n\tcolor: red;\n\tcursor: pointer;\n\tpadding: 0;\n\theight: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkedVoyage": "Gq4mKbD_5urMh2TtpXYp",
	"linkedContractsCard": "Dfj3M6esdQSQpA_f1gBk",
	"ownershipPeriod": "p4Zr3yAbZNgp4ilDAtdt",
	"contractIdentifierAndType": "naFcYY_UK1wVFKJDtycq",
	"contractLink": "agGmo2KOsZwtGGC5n6Wm",
	"unlinkBtn": "IM_dw5qNale1sUNji53e"
};
export default ___CSS_LOADER_EXPORT___;
