// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ddDs5Z5EBvsOOTJR1iM_ {\n\tflex: 1;\n}\n\n._8AD0i5u_0zwOBawmRV1F {\n\tmargin-right: 5px;\n\twidth: 25px !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/navbar/NavbarMenu.module.css"],"names":[],"mappings":"AAAA;CACC,OAAO;AACR;;AAEA;CACC,iBAAiB;CACjB,sBAAsB;AACvB","sourcesContent":[".menu {\n\tflex: 1;\n}\n\n.menuIcon {\n\tmargin-right: 5px;\n\twidth: 25px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "ddDs5Z5EBvsOOTJR1iM_",
	"menuIcon": "_8AD0i5u_0zwOBawmRV1F"
};
export default ___CSS_LOADER_EXPORT___;
