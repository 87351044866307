import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	Col,
	Drawer,
	Row,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import {
	CrewReportTypes,
	DATE,
} from '@shared/utils/constants';
import type { CrewReportsWithTypes } from '@api/utils/sequelize/getAllCrewReports';
import { formatDate } from '@client/utils/formatDate';
import BunkeringReportDrawerContent
	from '@client/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/BunkeringReportDrawerContent';
import StatementOfFactsReportDrawerContent
	from '@client/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/StatementOfFactsReportDrawerContent';
import Button from '@client/components/Button';
import fetchPdf from '@client/utils/fetchPdf';
import {
	getCrewReportAsPdf,
	getVesselDetails,
} from '@client/lib/api';
import APIError from '@client/utils/APIError';
import DeliveryReportDrawerContent from './DeliveryReportDrawerContent';
import NoonAtSeaReportDrawerContent from './NoonAtSeaReportDrawerContent';
import ArrivalReportDrawerContent from './ArrivalReportDrawerContent';
import DepartureReportDrawerContent from './DepartureReportDrawerContent';
import NoonInPortReportDrawerContent from './NoonInPortReportDrawerContent';
import RedeliveryReportDrawerContent from './RedeliveryReportDrawerContent';
import styles from './styles/CrewReportDrawer.module.css';
import BerthingReportDrawerContent from './BerthingReportDrawerContent';

type Props = {
	report: CrewReportsWithTypes | null;
	onClose: () => void;
}

export const CrewReportDrawer = ({
	report: reportProp,
	onClose,
}: Props) => {
	const [report, setReport] = useState<CrewReportsWithTypes | null>();

	const visible = reportProp != null;

	useEffect(() => {
		if (reportProp == null) {
			return;
		}

		setReport(reportProp);
	}, [reportProp]);

	const getContent = useCallback(() => {
		if (report == null) {
			return null;
		}

		switch (report.type) {
			case CrewReportTypes.NOON_AT_SEA:
				return (<NoonAtSeaReportDrawerContent report={report} />);
			case CrewReportTypes.NOON_IN_PORT:
				return (<NoonInPortReportDrawerContent report={report} />);
			case CrewReportTypes.ARRIVAL:
				return (<ArrivalReportDrawerContent report={report} />);
			case CrewReportTypes.DEPARTURE:
				return (<DepartureReportDrawerContent report={report} />);
			case CrewReportTypes.DELIVERY:
				return (<DeliveryReportDrawerContent report={report} />);
			case CrewReportTypes.REDELIVERY:
				return (<RedeliveryReportDrawerContent report={report} />);
			case CrewReportTypes.STATEMENT_OF_FACTS:
				return (<StatementOfFactsReportDrawerContent report={report} />);
			case CrewReportTypes.BERTHING:
				return (<BerthingReportDrawerContent report={report} />);
			case CrewReportTypes.BUNKERING:
				return (<BunkeringReportDrawerContent report={report} />);
			default:
				return 'The selected report cannot be displayed';
		}
	}, [report]);

	const handlePdfDownload = async () => {
		if (report == null) {
			throw new APIError('Something went wrong', true, 400);
		}

		const { name } = await getVesselDetails(report.vesselId);

		let pdfFileName = `${name}_${report.type}_Report_${formatDate(report.createdAt, DATE)}.pdf`;
		pdfFileName = pdfFileName.replace(/\s/g, '_');

		fetchPdf({
			fileName: pdfFileName,
			fetchCallback: () => getCrewReportAsPdf(report.vesselId, report.id),
		});
	};

	return (
		(
			<Drawer
				title={(
					<div className={styles.titleWrapper}>
						<Row>
							<Col span={12}>
								{(report?.type ?? 'Report')}
							</Col>
							<Col span={12}>
								<Button
									className={styles.pdfDownloadBtnWrapper}
									onClick={handlePdfDownload}
								>
									<DownloadOutlined />
									Download report
								</Button>
							</Col>
						</Row>
					</div>
				)}
				closable
				placement="right"
				onClose={onClose}
				open={visible}
				width="800px"
			>
				{getContent()}
			</Drawer>
		)
	);
};
