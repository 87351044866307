import {
	DepartmentKey,
	Departments,
} from '@shared/utils/constants';
import { Writeable } from '@shared/utils/generics';

const getAvailableDepartments = (
	isManagement: boolean,
	isAdmin: boolean,
	departments?: DepartmentKey[],
): Partial<typeof Departments> => {
	const availableDepartments: Partial<Writeable<typeof Departments>> = { ...Departments };

	if (!isAdmin) {
		delete availableDepartments.ADMIN;
	}

	if (!isManagement) {
		delete availableDepartments.MANAGEMENT;
	}

	if (departments != null) {
		const keys = Object.keys({ ...availableDepartments });
		keys.forEach((a) => {
			const lowerCaseKey = a.toLowerCase();

			if (
				!departments?.includes(lowerCaseKey as DepartmentKey) &&
				lowerCaseKey !== Departments.MANAGEMENT.key &&
				lowerCaseKey !== Departments.ADMIN.key
			) {
				delete availableDepartments[a];
			}
		});
	}

	return availableDepartments;
};

export default getAvailableDepartments;
