import React, {
	useEffect,
	useState,
} from 'react';
import classNames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import Reactour from 'reactour';
import getUniqueSelector from 'unique-selector';
import {
	ArrowLeftOutlined,
	ArrowRightOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons';
import Button from '../Button';
import styles from './Tour.module.css';

const mapStep = (step) => {
	const allSelectors = (Array.isArray(step.highlight) ?
		step.highlight :
		[step.highlight]
	).map((s) => {
		if (typeof s === 'string') {
			return `[data-tour="${s}"]`;
		}

		if (typeof s === 'function') {
			return s();
		}

		if (typeof s === 'object' && s.current != null) {
			return getUniqueSelector(s.current);
		}

		return null;
	});

	return {
		stepInteraction: false,
		...step,
		content: typeof step.renderContent === 'function' ? step.renderContent : (
			<div>
				{step.image != null && (
					<div className={styles.imageWrapper}>
						{step.image}
					</div>
				)}
				<h4>{step.title}</h4>
				<span>{step.body}</span>
			</div>
		),
		selector: allSelectors[0],
		highlightedSelectors: allSelectors,
		mutationObservables: allSelectors,
		resizeObservables: allSelectors,
	};
};

const Tour = ({
	steps,
	open,
	renderFinishButtons,
	finishButtonText = 'Finish',
	className,
	onClose,
	onFinish,
	...props
}) => {
	const [currentStep, setCurrentStep] = useState(0);

	useEffect(() => {
		if (open) {
			setCurrentStep(0);

			if (typeof steps[0].onEnter === 'function') {
				steps[0].onEnter(0, 0);
			}
		}
		// Don't run whenever steps change
		// Only when opened
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const close = () => {
		if (typeof steps[currentStep].onLeave === 'function') {
			steps[currentStep].onLeave(0, currentStep);
		}

		if (currentStep === steps.length - 1 && typeof onFinish === 'function') {
			onFinish();
		}

		onClose();
	};

	const goToStep = (newStep) => {
		// If navigating beyond last step, finish tour
		if (newStep >= steps.length) {
			close();

			return;
		}

		// Don't navigate to negative step number
		if (newStep < 0) {
			return;
		}

		const leaveHandler = steps[currentStep] && steps[currentStep].onLeave;
		const enterHandler = steps[newStep] && steps[newStep].onEnter;

		if (typeof leaveHandler === 'function') {
			leaveHandler(newStep, currentStep);
		}

		if (typeof enterHandler === 'function') {
			enterHandler(newStep, currentStep);
		}

		setCurrentStep(newStep);
	};

	const nextStep = () => goToStep(currentStep + 1);
	const prevStep = () => goToStep(currentStep - 1);

	return (
		<Reactour
			className={classNames(styles.tour, className)}
			disableDotsNavigation
			disableFocusLock
			scrollDuration={750}
			badgeContent={(curr, tot) => `${curr}/${tot}`}
			{...props}
			showNavigation={false}
			showButtons={false}
			steps={steps.map(mapStep)}
			isOpen={open}
			onRequestClose={close}
			goToStep={currentStep}
			// Used for keyboard navigation
			nextStep={nextStep}
			prevStep={prevStep}
		>
			<div className={styles.navigationWrapper}>
				<Button
					type="link"
					onClick={prevStep}
					className={styles.navigationChevron}
					icon={(<ArrowLeftOutlined />)}
					padding={0}
					disabled={currentStep === 0}
				/>
				<div className={styles.navigationDots}>
					{steps.map((_step, index) => (
						<FontAwesomeIcon
						// We only have the index, and there's no difference between the circles
						// eslint-disable-next-line react/no-array-index-key
							key={index}
							icon={index === currentStep ? faCircleSolid : faCircle}
							className={classNames(styles.navigationDot, {
								[styles.current]: index === currentStep,
							})}
						/>
					))}
				</div>
				{currentStep === steps.length - 1 ? (
					<>
						{typeof renderFinishButtons === 'function' ? (
							renderFinishButtons(nextStep)
						) : (
							<Button
								type="link"
								padding={0}
								className={styles.lastStepNextButton}
								onClick={nextStep}
							>
								{finishButtonText}
							</Button>
						)}
					</>
				) : (
					<Button
						type="link"
						onClick={nextStep}
						className={styles.navigationChevron}
						icon={(<ArrowRightOutlined />)}
					/>
				)}
			</div>
		</Reactour>
	);
};

export default Tour;
