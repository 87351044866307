import React from 'react';
import styles from './styles/Label.module.css';

type Props = {
	text: string;
	required?: boolean;
	children?: React.ReactElement | Array<React.ReactElement | null>;
}

const Label = ({ text, required, children }: Props) => (
	<div className={styles.wrapper}>
		<span className={styles.label}>
			{text}
			{required && (
				<span className={styles.required}>*</span>
			)}
		</span>
		{children}
	</div>
);

export default Label;
