// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mmmNM_x9tW_OnmYedlH3 {\n\tborder-bottom: 0;\n\tborder-bottom-left-radius: 0;\n\tborder-bottom-right-radius: 0;\n\tmax-width: 500px;\n}\n\n.DaUGNQ9mQ5izCGV52rFb {\n\twidth: fit-content;\n}\n\n.XQrwb9QT3B6PfJcS3APN {\n\twidth: fit-content;\n}\n\n.rqDNJec4OUcZTpSTcKiZ.ant-typography {\n\tmargin: 0 !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/styles/PortCallBunkersTab.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,4BAA4B;CAC5B,6BAA6B;CAC7B,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,oBAAoB;AACrB","sourcesContent":[".cardTable {\n\tborder-bottom: 0;\n\tborder-bottom-left-radius: 0;\n\tborder-bottom-right-radius: 0;\n\tmax-width: 500px;\n}\n\n.addStemBtn {\n\twidth: fit-content;\n}\n\n.stemTable {\n\twidth: fit-content;\n}\n\n.tableTitle:global(.ant-typography) {\n\tmargin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardTable": "mmmNM_x9tW_OnmYedlH3",
	"addStemBtn": "DaUGNQ9mQ5izCGV52rFb",
	"stemTable": "XQrwb9QT3B6PfJcS3APN",
	"tableTitle": "rqDNJec4OUcZTpSTcKiZ"
};
export default ___CSS_LOADER_EXPORT___;
