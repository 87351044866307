// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HpZ9N2ZyuVS41mKcBfMj {\n\tmax-width: 850px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/management/estimator/EstimatorSettings.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB","sourcesContent":[".details {\n\tmax-width: 850px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": "HpZ9N2ZyuVS41mKcBfMj"
};
export default ___CSS_LOADER_EXPORT___;
