let dev: boolean = false;

export const setDev = (newDev: boolean) => {
	dev = newDev;
};

// Warning: isDev will only return true AFTER runtime initialization
const isDev = (): boolean => dev;

export default isDev;
