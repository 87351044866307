// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ep0pb6uUtXlVbZVgowic {\n\twhite-space: nowrap;\n\tcolor: rgb(128, 128, 128) !important;\n\tfont-weight: normal !important;\n\tmargin-bottom: 1px !important;\n}\n\n.HB2ZS75JGgACPXGFp0di {\n\tmargin: 0 !important;\n\tcolor: rgb(23, 17, 90) !important;\n}\n\n.XOMoAOVQGFpwNoS3L5pQ {\n\tgap: 5px;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tflex-direction: row;\n\talign-items: end;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/PrettyDate/PrettyDate.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,oCAAoC;CACpC,8BAA8B;CAC9B,6BAA6B;AAC9B;;AAEA;CACC,oBAAoB;CACpB,iCAAiC;AAClC;;AAEA;CACC,QAAQ;CACR,aAAa;CACb,eAAe;CACf,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".offset {\n\twhite-space: nowrap;\n\tcolor: rgb(128, 128, 128) !important;\n\tfont-weight: normal !important;\n\tmargin-bottom: 1px !important;\n}\n\n.date {\n\tmargin: 0 !important;\n\tcolor: rgb(23, 17, 90) !important;\n}\n\n.container {\n\tgap: 5px;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tflex-direction: row;\n\talign-items: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offset": "ep0pb6uUtXlVbZVgowic",
	"date": "HB2ZS75JGgACPXGFp0di",
	"container": "XOMoAOVQGFpwNoS3L5pQ"
};
export default ___CSS_LOADER_EXPORT___;
