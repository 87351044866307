import React, {
	ReactElement,
	ReactNode,
} from 'react';
import { InputProps } from 'antd/lib/input';
import { Moment } from 'moment';
import Checkbox, { CheckboxProps } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { toMoment } from '@shared/utils/date';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import NumericInput, { NumericInputProps } from '@client/components/NumericInput';
import DatePicker, { DatePickerProps } from '@client/components/DatePicker';
import CurrencyInput, { CurrencyInputProps } from '@client/components/CurrencyInput';
import EditableInput from '@client/components/EditableTableRedux/EditableInput';
import Select, { SelectProps } from '../Select';
import styles from './styles/EditableCellTableRedux.module.css';

export type EditableFieldReduxProps = (
	& {
		editable: boolean;
		missing?: boolean;
	}
	& EditableFieldReduxPropsByType
);

type CommonEditableFieldProps<Key, ValueType, InputPropsType> = {
	type: Key;
	value: ValueType;
	onChange: (newValue: ValueType) => void;
	inputProps?: Partial<InputPropsType>;
	renderInput?: RenderInput<ValueType, InputPropsType>;
	renderValue?: RenderValue<ValueType, InputPropsType>;
}

export type EditableFieldReduxPropsByType = (
	| CommonEditableFieldProps<'text', string, InputProps>
	| CommonEditableFieldProps<'number', number | null, NumericInputProps>
	| (CommonEditableFieldProps<'currency', number | null, Omit<CurrencyInputProps, 'currency'>> & { inputProps: { currency: Values<typeof Currencies> } })
	| CommonEditableFieldProps<'date', Moment | null, DatePickerProps>
	| (CommonEditableFieldProps<'select', string | null, Omit<SelectProps<string>, 'options'>> & { inputProps: { options: SelectProps['options'] } })
	| CommonEditableFieldProps<'checkbox', boolean, CheckboxProps>
) & {
	transformData?: {
		in?: (value: any) => any;
		out?: (transformedValues: any) => any;
	};
}

type EditableFieldsByType = {
	[T in EditableFieldReduxPropsByType as T['type']]: {
		renderInput: RenderInput<T['value'], T['inputProps']>;
		renderValue: RenderValue<T['value'], T['inputProps']>;
	}
}

type RenderInput<ValueType, InputPropsType> = (props: {
	value: ValueType;
	onChange: (newValue: ValueType) => Promise<void> | void;
	inputProps: InputPropsType;
}) => ReactNode;

type RenderValue<ValueType, InputPropsType> = (props: {
	value: ValueType;
	inputProps: InputPropsType;
}) => ReactNode;

const editableFieldsByType: EditableFieldsByType = {
	text: {
		renderInput: (props) => (
			<EditableInput
				{...props.inputProps}
				variant="borderless"
				value={props.value}
				onChange={props.onChange}
			/>
		),
		renderValue: (props) => (
			props.value
		),
	},
	number: {
		renderInput: (props) => (
			<NumericInput
				asAsync
				variant="borderless"
				{...props.inputProps}
				value={props.value ?? undefined}
				onChange={props.onChange}
			/>
		),
		renderValue: (props) => (
			props.value
		),
	},
	currency: {
		renderInput: (props) => (
			<CurrencyInput
				asAsync
				variant="borderless"
				{...props.inputProps}
				value={props.value ?? undefined}
				onChange={props.onChange}
			/>
		),
		renderValue: (props) => {
			if (props.value == null) {
				return null;
			}

			return (
				formatCurrency(props.value, props.inputProps.currency)
			);
		},
	},
	date: {
		renderInput: (props) => (
			<DatePicker
				variant="borderless"
				{...props.inputProps}
				range={false}
				value={props.value}
				onOk={(newValue: any) => {
					if (newValue == null) {
						props.onChange(null);

						return;
					}

					if (Array.isArray(newValue)) {
						throw Error('Return value should not be a range.');
					}

					props.onChange(toMoment(newValue));
				}}
				onChange={(newValue: any) => {
					if (newValue == null) {
						props.onChange(null);

						return;
					}

					if (Array.isArray(newValue)) {
						throw Error('Return value should not be a range.');
					}

					props.onChange(toMoment(newValue));
				}}
			/>
		),
		renderValue: (props) => {
			if (props.value == null) {
				return null;
			}

			return formatDate(props.value);
		},
	},
	select: {
		renderInput: (props) => (
			<Select<string>
				variant="borderless"
				{...props.inputProps}
				value={props.value ?? undefined}
				options={props.inputProps?.options}
				onChange={(newValue) => props.onChange(newValue)}
				className={classNames(styles.fullWidth, props.inputProps?.className)}
			/>
		),
		renderValue: (props) => (
			props.inputProps?.options?.find((o) => o.value === props.value)?.label ?? null
		),
	},
	checkbox: {
		renderInput: (props) => (
			<Checkbox
				{...props.inputProps}
				checked={props.value}
				onChange={(newValue) => {
					props.onChange(newValue.target.checked);
				}}
			/>
		),
		renderValue: (props) => (
			<Checkbox
				checked={props.value}
				disabled
			/>
		),
	},
};

const EditableFieldRedux = (
	props: EditableFieldReduxProps,
): ReactElement => {
	const {
		type,
		editable,
		renderInput: renderInputOverride,
		renderValue: renderValueOverride,
	} = props;

	const { renderInput, renderValue } = editableFieldsByType[type];

	if (editable) {
		return (
			<>
				{(renderInputOverride ?? renderInput)(props as any)}
			</>
		);
	}

	return <>{(renderValueOverride ?? renderValue)(props as any)}</>;
};

export default EditableFieldRedux;
