// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oFPQ2RCzcsxbMFbtzu_W {\n\twidth: 100%;\n}\n\n.BjjIaySTA4gqnTz9KOuL {\n\tmargin-top: 15px;\n}\n\n.P_MKVkMkee9Xa0e7HGAg {\n\tcolor: blue;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fixtures/tc/styles/RateScheduleForm.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".fullWidth {\n\twidth: 100%;\n}\n\n.marginTop {\n\tmargin-top: 15px;\n}\n\n.blue {\n\tcolor: blue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "oFPQ2RCzcsxbMFbtzu_W",
	"marginTop": "BjjIaySTA4gqnTz9KOuL",
	"blue": "P_MKVkMkee9Xa0e7HGAg"
};
export default ___CSS_LOADER_EXPORT___;
