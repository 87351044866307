import React from 'react';
import RootScreen, { RootScreenProps } from './RootScreen';

type FullSizeScreenProps = RootScreenProps;

const FullSizeScreen: React.FC<FullSizeScreenProps> = ({ children, ...props }) => (
	<RootScreen
		padding={false}
		{...props}
	>
		{children}
	</RootScreen>
);

export default FullSizeScreen;
