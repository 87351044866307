// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IEBZKTDtdFLrVRCa_vmW {\r\n\tdisplay: flex;\r\n\talign-items: baseline;\r\n\twhite-space: nowrap;\r\n}\r\n\r\n.kMOfYAnGv5JUehe9H1Jg {\r\n\tmargin-left: 2px;\r\n\tfont-weight: lighter;\r\n\tfont-size: 85%;\r\n}\r\n", "",{"version":3,"sources":["webpack://./client/src/utils/styles/date.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,qBAAqB;CACrB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,oBAAoB;CACpB,cAAc;AACf","sourcesContent":[".utcDateWrapper {\r\n\tdisplay: flex;\r\n\talign-items: baseline;\r\n\twhite-space: nowrap;\r\n}\r\n\r\n.utcTag {\r\n\tmargin-left: 2px;\r\n\tfont-weight: lighter;\r\n\tfont-size: 85%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"utcDateWrapper": "IEBZKTDtdFLrVRCa_vmW",
	"utcTag": "kMOfYAnGv5JUehe9H1Jg"
};
export default ___CSS_LOADER_EXPORT___;
