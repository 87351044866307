import React from 'react';
import { notification } from 'antd';
import APIError from './APIError';

type Options = {
	showReload?: boolean;
	duration?: number;
}

const showErrorNotification = (title: string, error?: Error, options?: Options) => {
	let message: string;

	if (error instanceof APIError && error.humanReadable) {
		message = error.message;
	} else {
		message = 'An error has occurred. Please try again. If this keeps happening, please contact support.';
	}

	notification.error({
		message: title,
		duration: options?.duration ?? null,
		description: (
			<span>
				{message}
				{options?.showReload && (
					<>
						<br />
						<br />
						{/* eslint-disable-next-line */}
						<a onClick={() => window.location.reload()}>Click here to reload the page</a>
					</>
				)}
			</span>
		),
	});
};

export default showErrorNotification;
