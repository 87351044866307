import { formatNumber } from './formatNumber';

export const formatPercentage = (
	number: number,
	decimals?: number,
	showPercentageSign: boolean = true,
) => {
	if (number == null) {
		return null;
	}

	const formattedNumber = formatNumber(number, { separateThousands: false }, decimals);

	return showPercentageSign ? `${formattedNumber}%` : formattedNumber;
};
