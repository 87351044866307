import { formatCurrency } from '@shared/utils/currency';
import { calculateTotal } from '@shared/utils/math';
import { InvoiceTemplates } from '@shared/utils/constants';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';
import HIIPayment from './HIIPayment';

/*
 * Only to be used on the backend while generating cumulative hire invoices
 */
class HIIPaymentsTotal extends HireInvoiceItem {
	static get itemType() {
		return 'paymentsTotal';
	}

	constructor(id, isOriginal, params) {
		super(id, isOriginal, params);

		this._showTotal = false;
		this.itemTypeLabel = 'Payments total';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.PAYMENTS;
	}

	getTotalPaymentsAmount(invoiceItems) {
		const paymentItems = invoiceItems.filter((i) => (
			i instanceof HIIPayment
		));

		return Math.abs(
			calculateTotal(
				paymentItems,
				(p) => p.getTotal(invoiceItems),
			),
		);
	}

	_calculateTotal() {
		return 0;
	}

	getDescription(invoiceItems) {
		return `Total payments: ${formatCurrency(this.getTotalPaymentsAmount(invoiceItems), this.fixtureCurrency)}`;
	}

	static _getClassParams(_model, _parentModel) {
		throw new Error('Cannot run _getClassParams on HIIPayment');
	}

	async saveModel(_model) {
		throw new Error('Cannot run saveModel on HIIPayment');
	}

	_getTemplateItemParams(invoiceItems) {
		return {
			[InvoiceTemplates.CLASSIC]: {
				hideTotal: true,
				columns: [
					[
						[{
							content: `Total payments: ${formatCurrency(this.getTotalPaymentsAmount(invoiceItems), this.fixtureCurrency)}`,
							small: true,
							italic: true,
							paddingBottom: 8,
						}],
					],
				],
			},
			[InvoiceTemplates.NEO]: {
				hideTotal: true,
				columns: [
					`Total payments: ${formatCurrency(this.getTotalPaymentsAmount(invoiceItems), this.fixtureCurrency)}`,
					' ',
					' ',
					' ',
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIPaymentsTotal);

export default HIIPaymentsTotal;
