import React, { ReactElement } from 'react';
import { ResponsiveContainer } from 'recharts';
import LoadingIndicator from '@client/components/LoadingIndicator';
import ChartLegend from '@client/components/ChartLegend';
import DashboardWidgetWrapper, {
	DashboardWidgetProps,
	FetchDataParams,
} from './DashboardWidgetWrapper';

export type ChartWidgetProps<Data, Params extends FetchDataParams> = DashboardWidgetProps<{
	renderChart: (data: Data) => ReactElement;
	renderExtra?: (data: Data) => ReactElement;
	height?: number;
	legendItems?: Array<{
		key?: string;
		label: string;
		fill?: string;
	}>;
}, 'renderWidget', Data, Params>;

const ChartWidget = <Data, Params extends FetchDataParams>({
	renderChart,
	renderExtra,
	height = 400,
	legendItems,
	...props
}: ChartWidgetProps<Data, Params>) => (
	<DashboardWidgetWrapper
		{...props}
		renderWidget={({ data, loading }) => {
			if (loading || data == null) {
				return (<LoadingIndicator />);
			}

			return (
				<>
					{renderExtra != null && renderExtra(data)}
					<ResponsiveContainer
						width="100%"
						height={height}
					>
						{renderChart(data)}
					</ResponsiveContainer>
					{legendItems && (
						<ChartLegend items={legendItems} />
					)}
				</>
			);
		}}
	/>
);

export default ChartWidget;
