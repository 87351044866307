// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UEd8PDPb0KxBg7qbkU90 {\n\tmin-width: 900px;\n}\n\n.kmAWsi3KI0qbB5A7cTnZ {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/VoyageDetailsScreen.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".recap {\n\tmin-width: 900px;\n}\n\n.spotRecap {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recap": "UEd8PDPb0KxBg7qbkU90",
	"spotRecap": "kmAWsi3KI0qbB5A7cTnZ"
};
export default ___CSS_LOADER_EXPORT___;
