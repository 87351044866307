import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Alert,
	Checkbox,
} from 'antd';
import type { ApprovalItem } from '@api/models/organization';
import EditableCellTableRedux, { EditableColumns } from '@client/components/EditableTableRedux/EditableCellTableRedux';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getRequiredApprovals,
	updateOrganizationDetails,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import styles from './ApprovalSettings.module.css';

export type Approvals = {
	type: string;
	requiresApproval: boolean;
}

const ApprovalsOverview = () => {
	const [
		approvalsList,
		refreshApprovalsList,
		_error,
		isLoading,
	] = useFetchedState(getRequiredApprovals);

	const columns: EditableColumns<Approvals> = [
		{
			title: 'Type',
			dataIndex: 'type',
			editable: false,
			type: 'text',
		},
		{
			title: 'Requires approval',
			dataIndex: 'requiresApproval',
			editable: true,
			width: 200,
			type: 'checkbox',
			align: 'center',
			renderInput: ({ value, onChange }) => (
				<div style={{ textAlign: 'center' }}>
					<Checkbox
						onChange={(e) => onChange(e.target.checked)}
						checked={value}
					/>
				</div>
			),
		}];

	const dataSource = useMemo(() => approvalsList ?? [], [approvalsList]);

	const handleChange = useCallback(async (values: Approvals[]) => {
		try {
			await updateOrganizationDetails({ approvalsList: values as ApprovalItem[] });
			await refreshApprovalsList();
		} catch (e: any) {
			showErrorNotification(e.toString());
		}
	}, [refreshApprovalsList]);

	return (
		<div className={styles.tableContainer}>
			<Alert
				className={styles.alert}
				message="Items which require approval can only be posted to the accounting system after a management team member has approved them."
			/>
			<EditableCellTableRedux<Approvals>
				columns={columns}
				dataSource={dataSource}
				onChange={handleChange}
				loading={isLoading}
				pagination={false}
			/>
		</div>
	);
};

export default ApprovalsOverview;
