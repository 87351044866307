// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PaLYjI0ler3Ewhe0e_Bw {\n\twidth: fit-content;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VesselDetailsScreen/tabs/styles/VesselConsumptionTab.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB","sourcesContent":[".card {\n\twidth: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "PaLYjI0ler3Ewhe0e_Bw"
};
export default ___CSS_LOADER_EXPORT___;
