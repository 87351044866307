import React from 'react';
import moment from 'moment';
import { Space } from 'antd';
import { toMoment } from '@shared/utils/date';
import {
	ContractNamingConventions,
	Currencies,
	DATE_AND_TIME,
	FixtureTypes,
	UnnamedBankAccountName,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import VesselDescription from '@client/components/VesselDescription';
import LaycanDate from '@client/screens/fixtures/shared/LaycanDate';
import { renderUtcDate } from '@client/utils/table';
import TooltipIcon from '@client/components/TooltipIcon';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';
import getVesselOptions from '@client/utils/getVesselOptions';
import Button from '@client/components/Button';

export const getChartererField = ({ fixture, required }) => ({
	key: 'charterer',
	label: fixture.type === FixtureTypes.TC_IN ? 'Owner' : 'Charterer',
	value: (
		<LinkButton
			url={(fixture.type === FixtureTypes.TC_IN ?
				Links.Owner.get(fixture.counterpartyId) :
				Links.Charterer.get(fixture.counterpartyId)
			)}
		>
			{fixture.counterpartyName}
		</LinkButton>
	),
	editable: false,
	type: 'false',
	required,
});

export const getIdentifierField = ({ fixture, namingConvention, onRegenerate }) => ({
	key: 'identifier',
	label: 'Contract Identifier',
	value: fixture.identifier,
	editable: namingConvention === ContractNamingConventions.FREE,
	render: () => (fixture.identifier == null ? (
		<>
			—
			<TooltipIcon>
				The unique identifier is set when a
				vessel is selected for the contract
			</TooltipIcon>
		</>
	) : (
		<Space>
			{fixture.identifier}
			<Button onClick={onRegenerate} type="link">
				Regenerate
			</Button>
		</Space>
	)),
});

export const getVesselField = ({ fixture, vessels, required }) => ({
	key: 'vesselId',
	label: 'Vessel',
	value: fixture.vesselId,
	editable: !fixture.fixed,
	required,
	type: 'select',
	render: () => (
		<LinkButton
			url={Links.Vessel.get(fixture.vesselId)}
		>
			<VesselDescription
				name={fixture.vessel.name}
				flag={fixture.vessel.flag}
			/>
		</LinkButton>
	),
	options: [
		{
			label: 'No vessel',
			value: null,
		},
		{
			label: 'My vessels',
			options: getVesselOptions(vessels, 'myVessels', fixture.fixtureType),
		},
		{
			label: 'Market vessels',
			options: getVesselOptions(vessels, 'marketVessels'),
		},
	],
});

const shouldIncludeVesselCosts = (
	localFixture,
) => {
	return !!(
		localFixture.vessel &&
		localFixture.bankAccount &&
		localFixture.bankAccount.currency &&
		localFixture.vessel.ownershipType === VesselOwnershipTypes.TC_IN
	);
};

export const getVesselCosts = ({ fixture }) => ({
	key: 'tcInRate',
	label: 'TC In hire cost',
	value: fixture.tcInRate,
	editable: !fixture.fixed,
	required: shouldIncludeVesselCosts(fixture),
	type: 'currency',
	render: (i) => {
		return formatCurrency(i.value || 0, fixture.bankAccount ?
			fixture.bankAccount.currency : Currencies.USD);
	},
	inputProps: {
		returnNullOnEmpty: true,
		currency: shouldIncludeVesselCosts(fixture) ? fixture.bankAccount.currency : Currencies.USD,
		addonAfter: '/ day',
		disabled: !shouldIncludeVesselCosts(fixture),
	},
});

export const getBankAccountField = ({ fixture, bankAccounts, required }) => ({
	key: 'bankAccountId',
	label: 'Bank Account & Currency',
	value: fixture.bankAccountId || (bankAccounts.length === 1 ? bankAccounts[0].id : null),
	editable: (bankAccounts.length > 1),
	inputProps: {
		placeholder: 'Select Bank Account',
	},
	required,
	type: 'select',
	options: [
		{
			label: 'No bank account',
			value: null,
		},
		{
			label: 'Organization',
			options: [
				...bankAccounts
					.filter((account) => account?.vesselId === null)
					.map((account) => ({
						label: `${account.name || UnnamedBankAccountName} (${account.currency}) (${account.accountNo})`,
						value: account.id,
						disabled: fixture.fixed && account.currency !== fixture.bankAccount?.currency,
					}))],
		},
		{
			label: 'Vessel',
			options: [
				...bankAccounts
					.filter((account) => account?.vesselId != null)
					.map((account) => {
						const label = account.name || UnnamedBankAccountName;
						const suffix = account.businessUnit != null ?
							`(${account.businessUnit}) (${account.currency}) ` :
							`(${account.currency})`;

						return {
							label: `${label} ${suffix} (${account.accountNo})`,
							value: account.id,
							disabled: fixture.fixed && account.currency !== fixture.bankAccount?.currency,
						};
					})],
		},
	],
	render: () => {
		const bankAccount = bankAccounts.find((ba) => ba.id === fixture.bankAccountId);
		const additionalBankAccountsTooltip = (
			<TooltipIcon>
				Additional bank accounts can be created on the vessel page.
			</TooltipIcon>
		);

		if (bankAccount == null && bankAccounts.length === 0) {
			return (
				<span>
					No bank account selected.
					{additionalBankAccountsTooltip}
				</span>
			);
		}

		const account = (bankAccount || bankAccounts[0]);

		return (
			<span>
				{`${account.name} (${account.currency}) no. ${account?.accountNo}`}
				{additionalBankAccountsTooltip}
			</span>
		);
	},
});

export const getCpDateField = ({ fixture, required }) => ({
	key: 'cpDate',
	label: 'CP Date UTC',
	editable: true,
	required,
	type: 'date',
	value: fixture.cpDate,
	render: (entry) => renderUtcDate(entry.value, DATE_AND_TIME),
});

export const getCpFormField = ({ fixture, required }) => ({
	key: 'cpForm',
	label: 'CP Form',
	value: fixture.cpForm,
	editable: true,
	required,
});

export const getLaycanFromField = ({
	fixture,
	required,
	defaultLaycanUtcOffset,
	setDefaultLaycanUtcOffset,
	defaultPickerValue,
	setDefaultPickerValue,
}) => ({
	key: 'laycanFrom',
	label: 'Laycan From',
	value: fixture.laycanFrom,
	render: (value) => renderUtcDate(value, DATE_AND_TIME),
	editable: true,
	required,
	type: 'date',
	inputProps: {
		defaultPickerValue,
		time: true,
		maxDate: fixture.laycanTo,
		showNow: false,
		disableTimezone: false,
		defaultUtcOffset: defaultLaycanUtcOffset,
		transformResult: (newValue) => {
			const newLaycanTo = fixture.laycanTo != null ?
				toMoment(fixture.laycanTo)
					.utcOffset(newValue.utcOffset(), true) :
				null;

			return {
				laycanTo: newLaycanTo,
			};
		},
		onChange: (newValue) => {
			if (!moment.isMoment(newValue)) {
				return;
			}

			if (newValue.utcOffset() !== 0) {
				setDefaultLaycanUtcOffset(newValue.utcOffset());
			}

			if (fixture.laycanTo == null) {
				setDefaultPickerValue(newValue);
			}
		},
	},
});

export const getLaycanToField = ({
	fixture,
	required,
	defaultLaycanUtcOffset,
	setDefaultLaycanUtcOffset,
	defaultPickerValue,
	setDefaultPickerValue,
}) => ({
	key: 'laycanTo',
	label: 'Laycan To',
	value: fixture.laycanTo,
	render: (entry) => renderUtcDate(entry.value, DATE_AND_TIME),
	editable: true,
	required,
	type: 'date',
	inputProps: {
		defaultPickerValue,
		time: true,
		minDate: fixture.laycanFrom,
		showNow: false,
		disableTimezone: false,
		defaultUtcOffset: defaultLaycanUtcOffset,
		dateRender: (d) => (
			<LaycanDate
				date={d}
				referenceDate={fixture.laycanFrom != null ? toMoment(fixture.laycanFrom) : null}
			/>
		),
		transformResult: (newValue) => {
			const newLaycanFrom = fixture.laycanFrom != null ?
				toMoment(fixture.laycanFrom)
					.utcOffset(newValue.utcOffset(), true) :
				null;

			return {
				laycanFrom: newLaycanFrom,
			};
		},
		onChange: (newValue) => {
			if (!moment.isMoment(newValue)) {
				return;
			}

			if (newValue.utcOffset() !== 0) {
				setDefaultLaycanUtcOffset(newValue.utcOffset());
			}

			setDefaultPickerValue(newValue);
		},
	},
});

export const getAddressCommissionField = ({ fixture, allowEditKeyTerms, required }) => ({
	key: 'addressCommission',
	label: 'Address Commission',
	type: 'percentage',
	value: fixture.addressCommission,
	render: ({ value }) => value != null && `${value}%`,
	inputProps: {
		returnNullOnEmpty: true,
	},
	editable: allowEditKeyTerms,
	required,
});

export const getNotesField = ({ fixture, required }) => ({
	key: 'note',
	label: 'Note',
	type: 'textarea',
	value: fixture.note,
	editable: true,
	required,
	inputProps: { rows: 5 },
});

export const getDefaultLaycanUtcOffset = (fixture) => {
	const offset = (
		(fixture?.laycanFrom != null ? toMoment(fixture.laycanFrom)
			.utcOffset() : null) ??
		(fixture?.laycanTo != null ? toMoment(fixture.laycanTo)
			.utcOffset() : null)
	);

	if (offset === 0) {
		return null;
	}

	return offset;
};
