import React from 'react';
import { standardSort } from '@shared/utils/standardSort';
import { getOrganizationUsers } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';

const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		key: 'id',
		width: 40,
		align: 'center',
		sorter: standardSort('id'),
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		sorter: standardSort('name'),
	},
];

const UsersListScreen = () => {
	const [users, _refreshUsers, error, loading] = useFetchedState(() => getOrganizationUsers());

	return (
		<ListTableScreen
			title="Team Members"
			rootPageTitle="Team Members"
			columns={columns}
			data={users}
			loading={loading}
			error={error}
			getRowHref={(row) => Links.User.get(row.id)}
		/>
	);
};

export default UsersListScreen;
