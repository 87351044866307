export const PRODUCTION = process.env.NODE_ENV === 'production';

export const API_URL = process.env.REACT_APP_API_URL;

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const MOCK_DATE = process.env.REACT_APP_MOCK_DATE === 'true';

export const ChartColors = {
	BLUE: '#096dd9',
	DARK_BLUE: '#06488f',
	LIGHTBLUE: '#5cc0ff',
	DARK_LIGHTBLUE: '#3d779c',
	CYAN: '#14CCAA',
	DARK_CYAN: '#0e806b',
	RED: '#DE6449',
	GREEN: '#43C59E',
	GREY: '#DADADA',
};

export const userThousandSeperator =
	new Intl.NumberFormat(navigator.language)
		.format(123456.789)[3];

export const TourKeys = {
	General: {
		WELCOME: 'welcome',
		CHARTERING_INTRO: 'charteringIntro',
		OPERATIONS_INTRO: 'operatinsIntro',
		MANAGEMENT_INTRO: 'managementIntro',
	},
	VoyageDetails: {
		RECEIVABLES: 'receivables',
		HIRE_INVOICES: 'hireInvoices',
		REGISTER_PAYMENT: 'registerPayment',
		EDIT_ITEM: 'editItem',
		CUMULATIVE: 'cumulative',
	},
	Inbox: {
		INTRO: 'inboxIntro',
	},
};

