import React, {
	useCallback,
	useEffect,
} from 'react';
import {
	Badge,
	Card,
	Col,
	Form,
	FormInstance,
	Row,
	Space,
} from 'antd';
import type AccountingSystemConnection from '@api/models/accounting-system-connection';
import Button from '@client/components/Button';
import { createConnection } from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import NavisionCompanySelector from './NavisionCompanySelector';

const NavisionConnectionDetails = ({
	connection,
	selectedItemId,
	onDisconnect,
	refreshConnection,
	form,
}: {
	connection?: AccountingSystemConnection;
	selectedItemId?: number | null;
	onDisconnect: () => void;
	refreshConnection: () => void;
	form: FormInstance;
}) => {
	useEffect(() => {
		if (connection != null) {
			form.setFieldValue('companyId', connection.companyId);
		} else {
			form.setFieldValue('companyId', null);
		}
	});

	const onCreateConnection = useCallback(async () => {
		const companyId = form.getFieldValue('companyId');

		if (selectedItemId == null || companyId == null) {
			return;
		}

		try {
			await createConnection(selectedItemId, companyId);
			await refreshConnection();
			showSuccessNotification('Successfully connected to accounting system');
		} catch (e) {
			showErrorNotification('Could not connect to accounting system', e as Error);
		}
	}, [selectedItemId, form, refreshConnection]);

	return (
		<Card title="Connection details">
			<Row gutter={[16, 16]}>
				<Col span={24}>
					{connection == null ? (
						<Badge status="error" text="No connection" />
					) : (
						<Badge status="success" text="Connected" />
					)}
				</Col>
				<Col span={18}>
					<Form form={form}>
						<NavisionCompanySelector key={`selector-${selectedItemId}`} />
					</Form>
				</Col>
				<Col span={6}>
					<Space>
						<Button
							disabled={connection != null}
							type="primary"
							onClick={onCreateConnection}
						>
							Connect
						</Button>
						<Button
							onClick={onDisconnect}
							disabled={connection == null}
						>
							Disconnect
						</Button>
					</Space>
				</Col>
			</Row>
		</Card>
	);
};

export default NavisionConnectionDetails;
