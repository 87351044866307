import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Tour from '@client/components/Tour/Tour';
import { PRODUCTION } from '@client/utils/constants';

const ToursContext = createContext();

export const useTours = (getTours, dependencies) => {
	const {
		activeTour,
		tours,
		startTour,
		stopTour,
		setTours,
	} = useContext(ToursContext);

	if (!PRODUCTION && getTours != null && dependencies == null) {
		throw new Error('You must define `dependencies`. If tours don\'t change, put an empty array.');
	}

	// Let the caller handle dependencies
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const toursToAdd = useMemo(getTours ?? (() => []), dependencies ?? []);

	useEffect(() => {
		setTours((oldTours) => {
			const existingTourKeys = oldTours.map((t) => t.key);
			const newTours = toursToAdd.filter((t) => !existingTourKeys.includes(t.key));

			if (newTours.length === 0) {
				return oldTours;
			}

			// Add new tours at the top
			return [
				...newTours,
				...oldTours,
			];
		});

		return () => {
			// Remove the tours
			setTours((oldTours) => oldTours.filter((t) => !toursToAdd.includes(t)));
		};
	}, [setTours, toursToAdd]);

	return {
		activeTour: activeTour != null ? activeTour.key : null,
		tours,
		startTour,
		stopTour,
	};
};

export const ToursProvider = ({ children }) => {
	const [tours, setTours] = useState([]);
	const [activeTourKey, setActiveTourKey] = useState(null);
	const startingTour = useRef(null);

	// Set `delayed` to true when starting a tour automatically
	const startTour = useCallback((tourKey, delayed = false) => {
		if (startingTour.current != null) {
			clearTimeout(startingTour.current);
		}

		startingTour.current = setTimeout(() => {
			setActiveTourKey(tourKey);
			startingTour.current = null;
		}, delayed ? 1000 : 0);
	}, []);

	const stopTour = useCallback(() => setActiveTourKey(null), []);

	const activeTour = useMemo(() => (
		tours.find((t) => t.key === activeTourKey)
	), [tours, activeTourKey]);

	const value = useMemo(() => ({
		tours,
		activeTour,
		setTours,
		startTour,
		stopTour,
	}), [tours, activeTour, startTour, stopTour]);

	const close = () => {
		if (activeTour != null && typeof activeTour.onClose === 'function') {
			activeTour.onClose();
		}

		setActiveTourKey(null);
	};

	return (
		<ToursContext.Provider value={value}>
			{children}
			<Tour
				{...(activeTour || {})}
				steps={activeTour != null ? activeTour.steps : []}
				open={activeTour != null}
				onClose={close}
			/>
		</ToursContext.Provider>
	);
};
