import React, { useMemo } from 'react';
import classNames from 'classnames';
import type { SelectProps as AntSelectProps } from 'antd/lib/select';
import { NULL_STRING } from '@shared/utils/constants';
import TooltipIcon from '@client/components/TooltipIcon';
import { PRODUCTION } from '@client/utils/constants';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import AsyncSelect from '@client/components/Async/AsyncSelect';
import LoadingIndicator from './LoadingIndicator';
import styles from './styles/Select.module.css';

type Value = string | number | boolean | (number | string | null)[];
export type NullableValue = string | number | boolean | null | (number | string | null)[];

type CoreSelectOption = {
	key?: string | number;
	label?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
}

export type SelectOption = CoreSelectOption & {
	disabled?: boolean;
	title?: string;
	tooltip?: React.ReactNode;
	children?: React.ReactNode;
	value?: NullableValue;
	options?: CoreSelectOption[];
};

export type SelectProps<T extends NullableValue = NullableValue> = ExtendUnique<{
	onChange?: (newValue: T | null) => Promise<void | boolean> | void | boolean;
	onSelect?: (newValue: T | null) => Promise<void> | void;
	options?: SelectOption[];
	fullWidth?: boolean;
}, AntSelectProps<T>>;

// This component does not support passing options as children.
// Always use `options` prop!
const Select = <ValueType extends Value>({
	value: valueProp = undefined,
	options: optionsProp = [],
	onChange: onChangeProp = undefined,
	onSelect: onSelectProp = undefined,
	optionFilterProp = 'label',
	loading = false,
	children = null,
	mode = undefined,
	fullWidth = false,
	className = undefined,
	...props
}: SelectProps<ValueType>) => {
	if (!PRODUCTION && children != null) {
		console.error('[Select] Do not pass options as children. Instead, pass them through the `options` prop.');
	}

	// Sections can be defined by giving `options` to an entry in options prop (https://stackoverflow.com/a/63967009/3985544)
	// This memo returns both the formatted options and whether there's a null value
	const [options, hasNullValue] = useMemo(() => {
		let optionsHasNullValue = false;

		const transformOption = (option: SelectOption): SelectOption => {
			if (option.value === null) {
				optionsHasNullValue = true;

				return {
					...option,
					value: NULL_STRING,
				};
			}

			if (option.tooltip != null) {
				option.label = (
					<>
						{option.label}
						<>
							{' '}
							<TooltipIcon>{option.tooltip}</TooltipIcon>
						</>
					</>
				);
			}

			return option;
		};

		const formattedOptions = (Array.isArray(optionsProp) ? optionsProp.map((o) => {
			// If option is actually an array, transform all section options
			if (Array.isArray(o.options)) {
				return {
					...o,
					options: o.options.map(transformOption),
				};
			}

			// Transform option value
			return transformOption(o);
		}) : []);

		return [formattedOptions, optionsHasNullValue];
	}, [optionsProp]);

	// If value is null and there's an option with value of null, transform to NULL_STRING
	// Otherwise just keep the value
	const value = (valueProp === null && hasNullValue ?
		NULL_STRING :
		valueProp
	);

	const change = async (rawValue: ValueType | null) => {
		let newValue = rawValue;

		if (rawValue === NULL_STRING && hasNullValue) {
			newValue = null;
		}

		if (mode === 'multiple' && Array.isArray(rawValue)) {
			newValue = rawValue.map((item) => (item === NULL_STRING ? null : item)) as ValueType;
		}

		if (typeof onChangeProp === 'function') {
			await onChangeProp(newValue);
		}

		if (typeof onSelectProp === 'function') {
			await onSelectProp(newValue);
		}
	};

	return (
		// @ts-ignore
		<AsyncSelect<ValueType>
			key={value}
			notFoundContent={loading && (<LoadingIndicator fullHeight />)}
			value={value}
			options={options}
			onChange={change}
			mode={mode}
			optionFilterProp={optionFilterProp}
			{...props}
			className={classNames(
				fullWidth && styles.fullWidth,
				className,
			)}
		/>
	);
};

export default Select;
