import React, {
	useEffect,
	useState,
} from 'react';
import {
	PlusSquareOutlined,
	MinusSquareOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import AnimateHeight from 'react-animate-height';
import Button from '@client/components/Button';
import CircledNumber from '@client/components/CircledNumber';
import styles from './styles/FormSection.module.css';

type FormSectionProps = {
	title: React.ReactNode;
	count?: number;
	defaultOpen?: boolean;
	children?: React.ReactElement | Array<React.ReactElement | null>;
};

const FormSection: React.FC<FormSectionProps> = ({
	title,
	count,
	defaultOpen,
	children,
}) => {
	const [open, setOpen] = useState(defaultOpen);

	useEffect(() => {
		if (defaultOpen != null) {
			setOpen(defaultOpen);
		}
	}, [defaultOpen]);

	return (
		<>
			<Button
				className={styles.header}
				onClick={() => setOpen(!open)}
				block
				type="link"
			>
				<div>
					<Typography.Title level={4}>
						{title}
						{count != null && (
							<CircledNumber
								number={count || 0}
							/>
						)}
					</Typography.Title>
				</div>
				{open ? (
					<MinusSquareOutlined />
				) : (
					<PlusSquareOutlined />
				)}
			</Button>
			{/* @ts-ignore */}
			<AnimateHeight
				height={open ? 'auto' : 0}
			>
				<div>
					{children}
				</div>
			</AnimateHeight>
		</>
	);
};

export default FormSection;
