const isVesselInBounds = (vesselPosition, viewport, mapRef, latestPositionRef) => {
	const latest = latestPositionRef.current;
	// Only do calculation when map viewport has changed enough
	const diff = Math.abs((
		(latest.latitude + latest.longitude + latest.zoom) -
        (viewport.latitude + viewport.longitude + viewport.zoom)
	));

	if (diff < 2 || mapRef.current == null || vesselPosition == null) {
		return null;
	}

	latestPositionRef.current = viewport;

	const bounds = mapRef.current.getMap().getBounds();

	const vesselInViewport = (
		bounds._sw.lat < vesselPosition.latitude &&
		bounds._sw.lng < vesselPosition.longitude &&
		bounds._ne.lat > vesselPosition.latitude &&
		bounds._ne.lng > vesselPosition.longitude
	);

	return vesselInViewport;
};

export default isVesselInBounds;
