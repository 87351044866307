import React from 'react';
import { CalculationMethodForExpensesSubjectToHireDays } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import CVEFormulaAnnual from './CVEFormulaAnnual';
import styles from './styles/CVEFormulaLabel.module.css';
import CVEFormulaAnnualThreeSixty from './CVEFormulaAnnualThreeSixty';

const CVEFormulaLabel = ({ type }: {
	type: Values<typeof CalculationMethodForExpensesSubjectToHireDays>;
}) => {
	let formula;

	switch (type) {
		case CalculationMethodForExpensesSubjectToHireDays.ANNUALLY:
			formula = (<CVEFormulaAnnual />);
			break;
		case CalculationMethodForExpensesSubjectToHireDays.MONTHLY:
			formula = (<CVEFormulaAnnualThreeSixty />);
			break;
		default:
			break;
	}

	return (
		<>
			<span className={styles.formula}>
				{formula}
			</span>
		</>
	);
};

export default CVEFormulaLabel;
