import React, {
	ReactElement,
	useEffect,
	useState,
} from 'react';
import {
	Col,
	Form,
	Grid,
	Row,
	Space,
	Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	ChildBunkerTypes,
	Currencies,
	HireInvoiceItemStates,
} from '@shared/utils/constants';
import type { Values } from '@shared/utils/objectEnums';
import type { CreateBunkerProps } from '@api/models/bunker';
import type { CreateVesselBunkerProps } from '@api/models/vessel-bunker';
import type { CreateVoyageBunkerProps } from '@api/models/voyage-bunker';
import {
	deleteVesselBunker,
	deleteVoyageBunker,
} from '@client/lib/api';
import EmptyText from '@client/components/EmptyText';
import EditableTable from '@client/components/EditableTable';
import AddButton from '@client/components/AddButton';
import VesselBunkerForm from '@client/components/VesselBunkerForm';
import VoyageBunkerForm from '@client/components/VoyageBunkerForm';
import Card from './Card/Card';

export type CombinedVesselBunkerProps = Omit<
	(CreateBunkerProps & CreateVesselBunkerProps), 'vesselId' | 'bunkerId'
	> & { id: number }

export type CombinedVoyageBunkerProps = Omit<
	(CreateBunkerProps & CreateVoyageBunkerProps), 'voyageId' | 'bunkerId'
	> & { id: number }

type BunkersTableProps<BunkerTypeValues extends (
	CombinedVoyageBunkerProps | CombinedVesselBunkerProps
)> = {
	childType: Values<typeof ChildBunkerTypes>;
	vesselOrVoyageId: number;
	bunkers: BunkerTypeValues[];
	showState?: boolean;
	refreshDetails: () => void;
	currency: Values<typeof Currencies>;
	columns: ColumnsType<BunkerTypeValues>;
	size?: 'middle' | 'small';
}

const BunkersTable = <BunkerTypeValues extends (
	CombinedVoyageBunkerProps | CombinedVesselBunkerProps
), >({
	childType,
	vesselOrVoyageId,
	bunkers,
	refreshDetails,
	currency,
	columns,
	size = 'middle',
}: BunkersTableProps<BunkerTypeValues>): ReactElement => {
	const [bunkersForm] = Form.useForm();
	const [editingBunker, setEditingBunker] = useState<
		CombinedVoyageBunkerProps |
		CombinedVesselBunkerProps |
		null | {}
	>(null);
	const [childBunkers, setChildBunkers] = useState<BunkerTypeValues[]>([]);
	const screens = Grid.useBreakpoint();

	useEffect(() => {
		if (bunkers != null) {
			setChildBunkers(bunkers);
		}
	}, [bunkers]);

	const updateEditingBunkers = (_id: number | null, newEditing?: BunkerTypeValues) => {
		if (newEditing != null) {
			setEditingBunker(newEditing);
		}
	};

	const deleteBunkers = async (bunkerId: number) => {
		if (childType === ChildBunkerTypes.VOYAGE) {
			await deleteVoyageBunker(vesselOrVoyageId, bunkerId);
		} else {
			await deleteVesselBunker(vesselOrVoyageId, bunkerId);
		}

		await refreshDetails();
	};

	return (
		<>
			<Card
				slim
				title={(
					<Space>
						<FontAwesomeIcon
							color="red"
							icon={faGasPump as IconProp}
						/>
						Bunkers
					</Space>
				)}
				data-tour="bunkersCard"
				extra={(
					<AddButton
						disabled={editingBunker != null}
						onClick={() => setEditingBunker({})}
						data-tour="createBunker"
					>
						New Bunker
					</AddButton>
				)}
			>
				<Row gutter={16}>
					<Col span={24}>
						<EditableTable<BunkerTypeValues, 'id'>
							data-tour="bunkersTable"
							useCards={screens.xs}
							iconButtons
							enableDelete={() => true}
							pagination={false}
							expandable={{
								// @ts-ignore
								expandedRowRender: childType === ChildBunkerTypes.VOYAGE ?
									(record: CombinedVoyageBunkerProps) => (
										<div>
											<Typography.Title level={4}>
												Note for invoice
											</Typography.Title>
											{record.note != null ? record.note : (
												<EmptyText
													text="The note is empty"
												/>
											)}
										</div>
									) : undefined,
								rowExpandable: () => childType === ChildBunkerTypes.VOYAGE,
							}}
							keyDataIndex="id"
							columns={columns}
							dataSource={childBunkers}
							enableEdit={() => true}
							disableEdit={(row) => {
								// @ts-ignore
								if (row.state === HireInvoiceItemStates.PENDING) {
									return false;
								}

								return true;
							}}
							editingRow={null}
							onEditingRowChange={updateEditingBunkers}
							onDelete={deleteBunkers}
							onSave={() => null}
							size={size}
						/>
					</Col>
				</Row>
			</Card>
			{childType === ChildBunkerTypes.VESSEL ? (
				<VesselBunkerForm
					visible={editingBunker != null}
					onClose={() => setEditingBunker(null)}
					vesselId={vesselOrVoyageId}
					initialValues={editingBunker}
					onSave={refreshDetails}
					form={bunkersForm}
					baseCurrency={currency}
				/>
			) : (
				<VoyageBunkerForm
					visible={editingBunker != null}
					onClose={() => setEditingBunker(null)}
					voyageId={vesselOrVoyageId}
					initialValues={editingBunker}
					onSave={refreshDetails}
					form={bunkersForm}
					baseCurrency={currency}
				/>
			)}
		</>
	);
};

export default BunkersTable;
