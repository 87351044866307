// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f_4Se6QzYZq_orZr2F4w {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 5px;\n}\n\n.KCk2Qe0THfUEX9lTPtfC .ant-card-body {\n\tpadding-top: 10px;\n\twidth: 100%;\n}\n\n.KCk2Qe0THfUEX9lTPtfC.ant-card {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/LinkedContractDetails/LinkedContractDetails.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,QAAQ;AACT;;AAEA;CACC,iBAAiB;CACjB,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 5px;\n}\n\n.card :global(.ant-card-body) {\n\tpadding-top: 10px;\n\twidth: 100%;\n}\n\n.card:global(.ant-card) {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "f_4Se6QzYZq_orZr2F4w",
	"card": "KCk2Qe0THfUEX9lTPtfC"
};
export default ___CSS_LOADER_EXPORT___;
