// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rDGvWYiLtWRNSZIFCItA .rct-items > .rct-item {\n\tmin-width: 15px;\n\theight: 30px !important;\n\tborder-radius: 0;\n}\n\n.f6Q3o4oMr6K9oQjfFo7n {\n\tpadding-left: 24px;\n\tfont-size: 90%;\n\tposition: relative;\n}\n\n.f6Q3o4oMr6K9oQjfFo7n::after,\n.f6Q3o4oMr6K9oQjfFo7n::before {\n\tcontent: '';\n\tposition: absolute;\n\tleft: 4px;\n\tborder: 0 solid darkgray;\n}\n\n.f6Q3o4oMr6K9oQjfFo7n::before {\n\tborder-top-width: 1px;\n\twidth: 18px;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n}\n\n.f6Q3o4oMr6K9oQjfFo7n::after {\n\theight: 100%;\n\twidth: 1px;\n\tborder-left-width: 1px;\n}\n\n.f6Q3o4oMr6K9oQjfFo7n.liHKSa9wbz2s7o295qGm::after {\n\theight: 50%;\n}\n\n.mPh2JvoY9G_Phz_6Yqo4 {\n\tmax-height: 600px;\n\toverflow-y: scroll;\n\tpadding-top: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/widgets/styles/TcInTimeline.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,uBAAuB;CACvB,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;CAClB,cAAc;CACd,kBAAkB;AACnB;;AAEA;;CAEC,WAAW;CACX,kBAAkB;CAClB,SAAS;CACT,wBAAwB;AACzB;;AAEA;CACC,qBAAqB;CACrB,WAAW;CACX,QAAQ;CACR,2BAA2B;AAC5B;;AAEA;CACC,YAAY;CACZ,UAAU;CACV,sBAAsB;AACvB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,iBAAiB;CACjB,kBAAkB;CAClB,cAAc;AACf","sourcesContent":[".fleetTimeline :global(.rct-items > .rct-item) {\n\tmin-width: 15px;\n\theight: 30px !important;\n\tborder-radius: 0;\n}\n\n.subGroup {\n\tpadding-left: 24px;\n\tfont-size: 90%;\n\tposition: relative;\n}\n\n.subGroup::after,\n.subGroup::before {\n\tcontent: '';\n\tposition: absolute;\n\tleft: 4px;\n\tborder: 0 solid darkgray;\n}\n\n.subGroup::before {\n\tborder-top-width: 1px;\n\twidth: 18px;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n}\n\n.subGroup::after {\n\theight: 100%;\n\twidth: 1px;\n\tborder-left-width: 1px;\n}\n\n.subGroup.last::after {\n\theight: 50%;\n}\n\n.fleetTimelineWrapper {\n\tmax-height: 600px;\n\toverflow-y: scroll;\n\tpadding-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fleetTimeline": "rDGvWYiLtWRNSZIFCItA",
	"subGroup": "f6Q3o4oMr6K9oQjfFo7n",
	"last": "liHKSa9wbz2s7o295qGm",
	"fleetTimelineWrapper": "mPh2JvoY9G_Phz_6Yqo4"
};
export default ___CSS_LOADER_EXPORT___;
