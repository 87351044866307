import React, {
	useEffect,
	useState,
} from 'react';
import {
	Col,
	Divider,
	Form,
	Input,
	Popover,
	Row,
	Space,
} from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
} from '@ant-design/icons';
import type { FeatureHighlightType } from '@api/models/feature-highlight';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import Table from '@client/components/Table/Table';
import Card from '@client/components/Card/Card';
import Button from '@client/components/Button';
import {
	createFeatureHighlight,
	deleteFeatureHighlight,
	getFeatureHighlights,
	updateFeatureHighlight,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showErrorNotification from '@client/utils/showErrorNotification';

const FeatureHighlightScreen = () => {
	const [testEmailValue, setTestEmailValue] = useState<null | string>(null);
	const [form] = Form.useForm();
	const [featureHightlights, refreshHighlights] = useFetchedState(getFeatureHighlights);
	const [editing, setEditing] = useState<null | FeatureHighlightType>(null);

	useEffect(() => {
		if (editing != null) {
			form.setFieldsValue(editing);
		}
	}, [form, editing]);

	const createHighlight = async (email?: string | null, id?: number, update?: boolean | null) => {
		const {
			order,
			featureDescription,
			featureTitle,
			featureFunFact,
			featureImageUrl,
		} = form.getFieldsValue();

		if (update && id != null) {
			try {
				await updateFeatureHighlight(
					id,
					order,
					featureDescription,
					featureTitle,
					featureFunFact,
					featureImageUrl,
				);
			} catch (e) {
				showErrorNotification('Failed to update highlight', e as Error);
			}
		} else {
			try {
				await createFeatureHighlight(
					order,
					featureDescription,
					featureTitle,
					featureFunFact,
					featureImageUrl,
					email,
					id,
				);
			} catch (e) {
				showErrorNotification('Failed to create highlight', e as Error);
			}
		}

		if (email == null) {
			form.resetFields();
		}

		setEditing(null);

		setTestEmailValue(null);
		await refreshHighlights();
	};

	const required = [{ required: true, message: 'Field is required' }];

	return (
		<SimpleScreen
			rootPageTitle="Admin"
			title="Feature Highlights"
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Card title="Create new feature highlight">
						<span>
							You can use Markdown language for description and fun fact. For a cheat sheet click
							{' '}
							<a
								href="https://www.markdownguide.org/cheat-sheet/"
								target="_blank"
								rel="noopener noreferrer"
							>
								here
							</a>
							.
						</span>
						<Divider />
						<Form
							form={form}
							layout="vertical"
						>
							<Form.Item
								key="featureTitle"
								name="featureTitle"
								label="Title of feature"
								rules={required}
							>
								<Input />
							</Form.Item>
							<Form.Item
								key="featureDescription"
								name="featureDescription"
								label="Description of feature"
								rules={required}
							>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								key="featureFunFact"
								name="featureFunFact"
								label="Fun fact"
							>
								<Input.TextArea />
							</Form.Item>
							<Form.Item
								key="featureImageUrl"
								name="featureImageUrl"
								label="Url of feature image/gif"
							>
								<Input />
							</Form.Item>
							<Form.Item
								key="order"
								name="order"
								label="Sequence number (unique, starts from 0)"
								rules={required}
							>
								<Input />
							</Form.Item>
							{editing == null ? (
								<Button
									onClick={async () => {
										try {
											await form.validateFields();
											await createHighlight();
										} catch (e) {
											showErrorNotification('Missing field in form', e as Error);
										}
									}}
									type="primary"
								>
									Create
								</Button>
							) : (
								<>
									<Button
										onClick={async () => {
											await createHighlight(null, editing.id, true);
										}}
										type="primary"
									>
										Save
									</Button>
									<Button
										onClick={async () => {
											setTestEmailValue(null);
											setEditing(null);
											await refreshHighlights();
											form.resetFields();
										}}
										type="default"
										danger
									>
										Cancel
									</Button>
								</>
							)}
							<Popover
								trigger="click"
								content={(
									<Space direction="vertical">
										<Input
											value={testEmailValue == null ? undefined : testEmailValue}
											placeholder="recipient"
											onChange={(e) => setTestEmailValue(e.target.value)}
										/>
										<Button
											onClick={async () => {
												try {
													await form.validateFields();
													await createHighlight(testEmailValue);
												} catch (e) {
													showErrorNotification('Missing field in form', e as Error);
												}
											}}
											type="primary"
										>
											Send test email
										</Button>
									</Space>
								)}
							>
								<Button>
									Send test email
								</Button>
							</Popover>
						</Form>
					</Card>
				</Col>
				<Col span={24}>
					<Card>
						<Table
							pagination={false}
							dataSource={featureHightlights}
							columns={[
								{
									title: 'Title',
									dataIndex: 'featureTitle',
									key: 'featureTitle',
								},
								{
									title: 'Description',
									dataIndex: 'featureDescription',
									key: 'featureDescription',
								},
								{
									title: 'Fun fact',
									dataIndex: 'featureFunFact',
									key: 'featureFunFact',
								},
								{
									title: 'Order',
									dataIndex: 'order',
									key: 'order',
								},
								{
									title: 'Actions',
									key: 'actions',
									render: (_, row) => (
										<Space>
											<Button
												onClick={async () => {
													await deleteFeatureHighlight(row.id);
													await refreshHighlights();
												}}
												type="link"
												danger
												icon={(<DeleteOutlined />)}
											/>
											<Button
												onClick={() => {
													if (featureHightlights != null) {
														const entry = featureHightlights.find((h) => h.id === row.id);

														if (entry != null) {
															setEditing(entry);
														}
													}
												}}
												type="link"
												icon={(<EditOutlined />)}
											/>
										</Space>
									),
								},
							]}
						/>
					</Card>
				</Col>
			</Row>
		</SimpleScreen>
	);
};

export default FeatureHighlightScreen;
