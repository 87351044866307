import React, {
	useEffect,
	useState,
} from 'react';
import { CheckboxProps } from 'antd/lib/checkbox';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type Props = Omit<CheckboxProps, 'onChange'> & {
	onChange: (e: CheckboxChangeEvent) => Promise<void> | void;
};

const AsyncCheckbox = ({ checked, onChange, ...rest }: Props) => {
	const [localValue, setLocalValue] = useState<boolean>(checked ?? false);
	const [cache, setCache] = useState<boolean | null>(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (error) {
			if (cache != null) {
				setLocalValue(cache);
				setCache(null);
			}

			setError(false);
		}
	}, [cache, error]);

	return (
		<Checkbox
			checked={localValue}
			onChange={async (event) => {
				if (cache == null) {
					setCache(localValue);
				}

				setLocalValue(event.target.value);

				try {
					if (typeof onChange === 'function') {
						await onChange(event);
						setCache(null);
					}
				} catch (e) {
					setError(true);
				}
			}}
			{...rest}
		/>
	);
};

export default AsyncCheckbox;
