import React, {
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import ReactMapGL, {
	FlyToInterpolator,
	MapRef,
	TransitionInterpolator,
} from 'react-map-gl';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLocationArrow,
	faSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { faShip } from '@fortawesome/pro-duotone-svg-icons';
import {
	Card,
	Col,
	Divider,
	Row,
	Space,
	Spin,
	Switch,
} from 'antd';
import {
	Icon,
	IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { flushSync } from 'react-dom';
import Button from '../Button';
import styles from './Map.module.css';
import legendStyles from './MapLegend.module.css';
import useAnchorPosition from './helpers/useAnchorPosition';
import isViewport from './helpers/isViewport';
import VesselsLayer from './layers/VesselsLayer';
import EcaLayer from './layers/EcaLayer';

const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export type Viewport = {
	latitude: number;
	longitude: number;
	width?: number;
	height?: number;
	zoom?: number;
	transitionDuration?: number;
	transitionInterpolator?: TransitionInterpolator;
}

const defaultViewport = {
	width: 400,
	height: 400,
	latitude: 59,
	longitude: 11,
	zoom: 4,
};
const mapStyle = 'mapbox://styles/qanders/cko1sxloq168h17qovp5ialoc';

export type MapProps = {
	children?: (props: { viewport: Viewport }) => ReactNode;
	defaultPosition?: Viewport;
	anchorPosition?: Viewport | null;
	defaultZoom?: number;
	loading?: boolean;
	curved?: boolean;
	loadingMessage?: string;
	enableLegend?: boolean;
	legendShowWhat?: {
		vesselsLayer?: boolean;
		majorPortsLayer?: boolean;
		ecaLayer?: boolean;
	};
};

const Map: React.FC<MapProps> = ({
	children,
	defaultPosition,
	anchorPosition,
	defaultZoom,
	curved = false,
	loading = false,
	loadingMessage = 'Loading',
	enableLegend = false,
	legendShowWhat = {
		vesselsLayer: false,
		majorPortsLayer: false,
		ecaLayer: false,
	},
	...props
}) => {
	const [viewport, setViewport] = useState(defaultPosition || defaultViewport);
	const mapRef = useRef<MapRef>();

	const isInBounds = useAnchorPosition(anchorPosition, viewport, mapRef);

	const unbatchedSetViewPort = (args: any) => flushSync(() => setViewport(args));

	useEffect(() => {
		if (!isViewport(defaultPosition) && isViewport(anchorPosition)) {
			unbatchedSetViewPort({
				zoom: defaultViewport.zoom,
				...anchorPosition,
			});
		}
	}, [defaultPosition, anchorPosition]);

	useEffect(() => {
		if (!isViewport(defaultPosition)) {
			return;
		}

		unbatchedSetViewPort({
			zoom: defaultZoom || defaultViewport.zoom,
			...defaultPosition,
		});
	}, [defaultPosition, defaultZoom]);

	useEffect(() => {
		const map = mapRef.current?.getMap();

		if (map == null) {
			return;
		}

		map.loadImage('/arrow.png', (error: Error | undefined, image: string) => {
			if (error) {
				throw error;
			}

			if (!map.hasImage('arrow')) {
				map.addImage('arrow', image);
			}
		});

		map.loadImage('/longerArrow.png', (error: Error | undefined, image: string) => {
			if (error) {
				throw error;
			}

			if (!map.hasImage('longerArrow')) {
				map.addImage('longerArrow', image);
			}
		});
	}, []);

	const [showVessels, setShowVessels] = useState(legendShowWhat.vesselsLayer);
	const [showEca, setShowEca] = useState(legendShowWhat.ecaLayer);

	return (
		<div className={classNames(styles.mapWrapper, {
			[styles.curved]: curved,
		})}
		>
			{enableLegend && (
				<div className={legendStyles.legend}>
					<Card size="small" bodyStyle={{ padding: '9px 12px 0' }} className={legendStyles.legendCard}>
						<Row gutter={[16, 8]}>
							<Col span={3} className={legendStyles.icon}>
								<FontAwesomeIcon
									className={legendStyles.shipIcon}
									size="lg"
									icon={faLocationArrow as Icon}
								/>
							</Col>
							<Col span={13}>
								<b className={legendStyles.label}>Vessels</b>
							</Col>
							<Col span={8} className={legendStyles.switchCol}>
								<Switch
									size="small"
									checked={showVessels}
									onChange={() => {
										setShowVessels(!showVessels);
									}}
								/>
							</Col>
							<Divider className={legendStyles.divider} />
							<Col span={3} className={legendStyles.icon}>
								<FontAwesomeIcon
									className={legendStyles.ecaZonesIcon}
									size="lg"
									icon={faSquare as Icon}
								/>
							</Col>
							<Col span={13}>
								<b className={legendStyles.label}>ECA Zones</b>
							</Col>
							<Col span={8} className={legendStyles.switchCol}>
								<Switch
									size="small"
									checked={showEca}
									onChange={() => {
										setShowEca(!showEca);
									}}
								/>
							</Col>
						</Row>
					</Card>
				</div>
			)}
			{isViewport(anchorPosition) && (
				<Button
					type="primary"
					shape="circle"
					className={classNames(styles.vesselPositionButton, {
						[styles.vesselButtonShown]: !isInBounds,
					})}
					icon={(
						<FontAwesomeIcon
							icon={faShip as IconProp}
						/>
					)}
					onClick={() => unbatchedSetViewPort({
						zoom: defaultViewport.zoom,
						transitionDuration: 1664,
						transitionInterpolator: new FlyToInterpolator(),
						...anchorPosition,
					})}
				/>
			)}
			<ReactMapGL
				// Their ref only supports old ways of using ref
				// Functionally it works perfectly
				// @ts-ignore
				ref={mapRef}
				{...viewport}
				onViewportChange={(nextViewport: Viewport) => unbatchedSetViewPort(nextViewport)}
				mapStyle={mapStyle}
				mapboxApiAccessToken={mapboxToken}
				width="100%"
				sprite="mapbox://sprites/mapbox/bright-v8"
				height="100%"
				dragRotate={false}
				touchRotate={false}
				keyboard={false}
				{...props}
			>
				{showVessels && (<VesselsLayer />)}
				{showEca && (<EcaLayer />)}
				{children && children({ viewport })}
			</ReactMapGL>
			<Space
				className={classNames(styles.loadingIndicator, {
					[styles.loading]: loading,
				})}
			>
				<Spin />
				{loadingMessage}
			</Space>
		</div>
	);
};

export default Map;
