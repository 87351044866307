import React from 'react';
import { Avatar } from 'antd';
import { getNameInitials } from '@shared/utils/string';
import styles from './styles/UserCircle.module.css';

const UserCircle = ({ name }: { name: string }) => (
	<Avatar
		className={styles.avatar}
	>
		{getNameInitials(name)}
	</Avatar>
);

export default UserCircle;
