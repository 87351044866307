import { DATE_AND_TIME } from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import { formatDate } from '@client/utils/formatDate';

export const getTcExtraColumns = () => [
	{
		title: 'Delivery Date',
		dataIndex: 'deliveryDate',
		key: 'deliveryDate',
		render: (value) => {
			if (value == null) {
				return '—';
			}

			return formatDate(value, DATE_AND_TIME, true);
		},
	},
	{
		title: 'Delivery Port/Range',
		dataIndex: 'commencementPortName',
		key: 'commencementPortName',
		sorter: standardSort('commencementPortName'),
		render: (commencementPortName) => {
			if (commencementPortName == null) {
				return '—';
			}

			return commencementPortName;
		},
	},
	{
		title: 'Duration',
		dataIndex: 'duration',
		key: 'duration',
		sorter: standardSort('duration'),
		render: (duration) => duration || '—',
	},
];
