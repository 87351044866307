import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import { Currencies } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import type { GetEstimatedDespatchAndDemurrageResponse } from '@api/features/estimates/getEstimatedDespatchAndDemurrage';
import StatisticCard from '@client/components/StatisticCard';
import styleColorPicker
	from '@client/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/utils/styleColorPicker';

type Props = {
	stats: GetCargosAndLaytimesResponse[number]['stats'];
	currency: Values<typeof Currencies>;
	estimatedDemDes?: GetEstimatedDespatchAndDemurrageResponse[number] | null;
}

const NonReversibleStats = ({ stats, currency, estimatedDemDes }: Props) => {
	const {
		totalDemurrage,
		totalDespatch,
		totalReceivable,
	} = stats;

	return (
		<Row gutter={[16, 16]}>
			<Col>
				<StatisticCard
					items={[{
						label: 'Total Demurrage',
						children: formatCurrency(Math.abs(totalDemurrage ?? 0), currency),
						childColor: styleColorPicker(totalDemurrage).color,
					}]}
					customStyles={{
						maxWidth: '300px',
					}}
				/>
			</Col>
			<Col>
				<StatisticCard
					items={[{
						label: 'Total Despatch',
						children: formatCurrency(Math.abs(totalDespatch ?? 0), currency),
						childColor: styleColorPicker(totalDespatch, true).color,
					}]}
					customStyles={{
						maxWidth: '300px',
					}}
				/>
			</Col>
			<Col>
				<StatisticCard
					items={[{
						label: `Total ${totalReceivable != null && totalReceivable >= 0 ? 'Receivable' : 'Payable'}`,
						children: formatCurrency(Math.abs(totalReceivable ?? 0), currency),
						childColor: styleColorPicker(totalReceivable).color,
					}]}
					customStyles={{
						maxWidth: '300px',
					}}
				/>
			</Col>
			{estimatedDemDes != null && (
				<>
					<Col>
						<StatisticCard
							items={[{
								label: 'Estimated Total Demurrage',
								children: formatCurrency(
									Math.abs(estimatedDemDes.estimatedDemurrage ?? 0),
									currency,
									{ forceDecimals: true },
								),
								childColor: styleColorPicker(
									Math.abs(estimatedDemDes.estimatedDemurrage ?? 0),
								).color,
							}]}
							customStyles={{
								maxWidth: '300px',
							}}
						/>
					</Col>
					<Col>
						<StatisticCard
							items={[{
								label: 'Estimated Total Despatch',
								children: formatCurrency(
									Math.abs(estimatedDemDes.estimatedDespatch ?? 0),
									currency,
									{ forceDecimals: true },
								),
								childColor: styleColorPicker(
									Math.abs(estimatedDemDes.estimatedDespatch ?? 0),
									true,
								).color,
							}]}
							customStyles={{
								maxWidth: '300px',
							}}
						/>
					</Col>
				</>
			)}
		</Row>
	);
};

export default NonReversibleStats;
