// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T5D7E6co_3kb43DxekGr {\n\theight: 300px;\n}\n\n.gao3YonhnZmnnnRRBwJX {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.E2HkueInu1wopK4p0tAZ {\n\twhite-space: nowrap;\n\tmargin: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/styles/NoonReportDrawerContent.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;CACnB,SAAS;AACV","sourcesContent":[".map {\n\theight: 300px;\n}\n\n.rowBox {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.formattedValue {\n\twhite-space: nowrap;\n\tmargin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map": "T5D7E6co_3kb43DxekGr",
	"rowBox": "gao3YonhnZmnnnRRBwJX",
	"formattedValue": "E2HkueInu1wopK4p0tAZ"
};
export default ___CSS_LOADER_EXPORT___;
