// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GAJYlBGvVC1Xk4IBpq9g {\n\twhite-space: nowrap;\n}\n\n.JHb302tIOGWSIeQQn5vN > :first-child {\n\talign-self: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/VesselDescription.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".name {\n\twhite-space: nowrap;\n}\n\n.spacer > :first-child {\n\talign-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "GAJYlBGvVC1Xk4IBpq9g",
	"spacer": "JHb302tIOGWSIeQQn5vN"
};
export default ___CSS_LOADER_EXPORT___;
