import {
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import { formatPercentage } from '@shared/utils/formatPercentage';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';

class HIIOffHireAddressCommission extends HireInvoiceItem {
	static get itemType() {
		return 'offHireAddressCommission';
	}

	constructor(id, isOriginal, params) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this.percentage = params.percentage;

		this._showTotal = true;
		this.itemTypeLabel = 'Address Commission';
		this.addManually = false;
		this.pnlGroup = PnlGroups.OFFHIRE;
		this.templateSection = TemplateItem.Sections.COMMISSIONS;

		this._fields = {};
	}

	_calculateTotal(invoiceItems) {
		const offHirePeriod = this.getParent(invoiceItems);

		if (offHirePeriod == null) {
			return 0;
		}

		const offHireTotal = offHirePeriod.getTotal(invoiceItems);

		const total = offHireTotal * (this.percentage / 100);

		return -1 * total;
	}

	getDescription() {
		return `${formatPercentage(this.percentage, 2)}`;
	}

	static async _getClassParams(_model, parentModel) {
		const voyage = await parentModel.getVoyage();
		const fixture = await voyage.getFixture();

		return {
			percentage: fixture.addressCommission,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);
		await model.save();
	}

	_getTemplateItemParams(invoiceItems) {
		const offHirePeriod = this.getParent(invoiceItems);

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: ['Address commission', `${this.getDescription()}`],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					' — Address commission',
					`${formatPercentage(this.percentage, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(offHirePeriod.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIOffHireAddressCommission);

export default HIIOffHireAddressCommission;
