import { Moment } from 'moment';
import {
	toMoment,
	formatDate,
} from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import TemplateItem from '@shared/TemplateItem';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import type { HIIBbItemType } from '@api/models/hii-bb-item';
import type HireInvoiceItemModel from '@api/models/hire-invoice-item';
import {
	HIIFieldTypes,
	PnlGroups,
	InvoiceTemplates,
	BareboatItemType,
	DATE_AND_TIME,
	MillisecondsPer,
} from '../utils/constants';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';
import HireInvoiceDifference from './HireInvoiceDifference';

export type HIIBbItemParams = HIIConstructorParams & {
	bbItemId: number;
	type: Values<typeof BareboatItemType>;
	amount: number;
	addComm?: number | null;
	brokerPaidByOwnerIds?: Array<number | null>;
	brokerPaidByOwnerPercentages?: Array<number | null>;
	brokerPaidByChartererIds?: Array<number | null>;
	brokerPaidByChartererPercentages?: Array<number | null>;
	from: string | Moment;
	to: string | Moment;
	useUTC?: boolean;
};

class HIIBbItem extends HireInvoiceItem {
	static get itemType() {
		return 'bareboatItem';
	}

	bbItemId: number;
	type: Values<typeof BareboatItemType>;
	amount: number;
	addComm?: number | null;
	brokerPaidByOwnerIds?: Array<number | null>;
	brokerPaidByOwnerPercentages?: Array<number | null>;
	brokerPaidByChartererIds?: Array<number | null>;
	brokerPaidByChartererPercentages?: Array<number | null>;
	from: Moment;
	to: Moment;
	useUTC: boolean;

	constructor(id: number, isOriginal: boolean, params: HIIBbItemParams) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.bbItemId = params.bbItemId;
		this.type = params.type;
		this.amount = params.amount;
		this.addComm = params.addComm;
		this.brokerPaidByOwnerIds = params.brokerPaidByOwnerIds ?? [];
		this.brokerPaidByOwnerPercentages = params.brokerPaidByOwnerPercentages ?? [];
		this.brokerPaidByChartererIds = params.brokerPaidByChartererIds ?? [];
		this.brokerPaidByChartererPercentages = params.brokerPaidByChartererPercentages ?? [];
		this.from = toMoment(params.from);
		this.to = toMoment(params.to);
		this.useUTC = params.useUTC ?? true;
		this.pnlGroup = PnlGroups.BAREBOAT_ITEM;
		this.addManually = true;
		this.templateSection = TemplateItem.Sections.CAPEX_OPEX_OTHER;
		this.itemTypeLabel = 'Bareboat';

		this._fields = {
			type: {
				label: 'Item Name',
				type: HIIFieldTypes.SELECT,
				options: Object.values(BareboatItemType).map((value) => ({ label: value, value })),
			},
			amount: {
				label: 'Amount per Day',
				type: HIIFieldTypes.CURRENCY,
			},
			from: {
				label: 'From',
				type: HIIFieldTypes.DATE,
			},
			to: {
				label: 'To',
				type: HIIFieldTypes.DATE,
			},
		};
	}

	_calculateTotal() {
		return this.amount * this.getDays();
	}

	getDuration() {
		return `
			${formatDate(this.from, 'MMM Do YYYY HH:mm')} - 
			${formatDate(this.to, 'MMM Do YYYY HH:mm')}
		`;
	}

	getDays() {
		return round(toMoment(this.to).diff(this.from) / MillisecondsPer.DAY) ?? 15;
	}

	getDescription() {
		return `
			${this.type} - ${this.getDays()} days at 
			${formatCurrency(this.amount, this.fixtureCurrency)} per day.\n
			Duration: ${this.getDuration()}
		`;
	}

	static async _getClassParams(model: HIIBbItemType, _parentModel: typeof HireInvoiceItemModel) {
		return {
			bbItemId: model.bbItemId,
			type: model.type,
			amount: model.amount,
			addComm: model.addComm,
			from: model.from,
			to: model.to,
			useUTC: model.useUTC,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);

		model.addComm = this.addComm ?? 0;
		model.amount = this.amount;
		model.bbItemId = this.bbItemId;
		model.type = this.type;
		model.from = this.from;
		model.to = this.to;
		model.useUTC = this.useUTC;

		await model.save();
	}

	_getTemplateItemParams() {
		const utcSuffix = {
			content: 'UTC',
			small: true,
		} as const;

		const ltSuffix = {
			content: 'LT',
			small: true,
		} as const;

		return {
			[InvoiceTemplates.NEO]: {
				columns: [
					`${this.type} ${this.getDuration()}`,
					`${this.getDays()}`,
					'days',
					formatCurrency(this.amount, this.fixtureCurrency),
				],
			},
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					[
						[
							`${this.type}`,
						],
						[
							'From:',
							formatDate(this.from, DATE_AND_TIME, false, this.useUTC ? 'utc' : 'localTime') || '',
							this.useUTC ? utcSuffix : ltSuffix,
						],
						[
							'To:',
							formatDate(this.to, DATE_AND_TIME, false, this.useUTC ? 'utc' : 'localTime') || '',
							this.useUTC ? utcSuffix : ltSuffix,
						],
					],
					`${this.getDays()} days`,
					[
						[`@ ${formatCurrency(this.amount, this.fixtureCurrency)}`],
					],
				],
			},
		};
	}

	static groupItems(items: HireInvoiceItem[], differences: HireInvoiceDifference[]) {
		if (items == null || items.length === 0) {
			return { items, differences };
		}

		const groupRecursively = (
			groupedItems: HireInvoiceItem[],
			groupedDifferences: HireInvoiceDifference[],
		): {
			items: HireInvoiceItem[];
			differences: HireInvoiceDifference[];
		} => {
			let newGroupedDifferences = groupedDifferences;

			const updateGroupedDifferencesForItem = (
				item1: HireInvoiceItem,
				item2: HireInvoiceItem,
				newItem: HireInvoiceItem,
			) => {
				const changedItem1 = newGroupedDifferences.find((d) => d.original.id === item1.id)?.changed;
				const changedItem2 = newGroupedDifferences.find((d) => d.original.id === item2.id)?.changed;

				if (
					changedItem1 != null ||
					changedItem2 != null ||
					!item1.accepted ||
					!item2.accepted
				) {
					const changedItems = groupedItems.map((i) => (
						HireInvoiceDifference.getChangedInvoiceItem(i, newGroupedDifferences)
					));

					const changedItem1Total = !item1.accepted ? 0 : (
						changedItem1 == null ?
							item1.getTotal(items, false) :
							changedItem1.getTotal(changedItems, false)
					);
					const changedItem2Total = !item2.accepted ? 0 : (
						changedItem2 == null ?
							item2.getTotal(items, false) :
							changedItem2.getTotal(changedItems, false)
					);

					const changedNewItem = newItem.copy();
					changedNewItem.id -= 0.5;

					changedNewItem.totalOverride = round(changedItem1Total + changedItem2Total);
					changedNewItem.accepted = true;

					newGroupedDifferences = [
						...newGroupedDifferences.filter((d) => (
							d.original.id !== item1.id &&
							d.original.id !== item2.id
						)),
						new HireInvoiceDifference(newItem, changedNewItem),
					];
				}
			};

			for (let i1 = 0; i1 < groupedItems.length; i1++) {
				const item1 = groupedItems[i1];
				for (let i2 = 0; i2 < groupedItems.length; i2++) {
					const item2 = groupedItems[i2];

					if (
						item1 !== item2 &&
						item1 instanceof HIIBbItem &&
						item2 instanceof HIIBbItem &&
						item1.canBeGroupedWith(item2, differences)
					) {
						const newItem = new HIIBbItem(
							Math.min(item1.id, -item1.id),
							item1.isOriginal && item2.isOriginal,
							{
								from: item1.from,
								to: item2.to,
								amount: item1.amount,
								fixtureCurrency: item1.fixtureCurrency,
								bbItemId: item2.id,
								type: item1.type,
							},
						);

						const item1Total = item1.getTotal(items, false);
						const item2Total = item2.getTotal(items, false);

						newItem.totalOverride = round(item1Total + item2Total);
						newItem.isGrouped = true;
						newItem.accepted = true;
						newItem.groupedItems = [
							...(item1.isGrouped ? (item1.groupedItems ?? [item1]) : [item1]),
							...(item2.isGrouped ? (item2.groupedItems ?? [item2]) : [item2]),
						];

						updateGroupedDifferencesForItem(item1, item2, newItem);

						newItem.children = item1.children.map((child1, index) => {
							// This should be safe all current scenarios
							const child2 = item2.children[index];

							const newChild = child1.copy();

							newChild.totalOverride = round(
								child1.getTotal(items, false) +
								child2.getTotal(items, false),
							);

							updateGroupedDifferencesForItem(child1, child2, newChild);

							newChild.id = Math.min(newChild.id, -newChild.id);
							newChild.parentId = newItem.id;
							newChild.isGrouped = true;
							newChild.accepted = true;
							newChild.groupedItems = [
								...(child1.isGrouped ? (child1.groupedItems ?? [child1]) : [child1]),
								...(child2.isGrouped ? (child2.groupedItems ?? [child2]) : [child2]),
							];

							return newChild;
						});

						let newGroupedItems = groupedItems.filter((i) => i !== item1 && i !== item2);
						const insertIndex = Math.min(i1, i2);
						newGroupedItems = [
							...newGroupedItems.slice(0, insertIndex),
							newItem,
							...newGroupedItems.slice(insertIndex),
						];

						return groupRecursively(newGroupedItems, newGroupedDifferences);
					}
				}
			}

			return {
				items: groupedItems,
				differences: groupedDifferences,
			};
		};

		return groupRecursively(items, differences);
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return (
			(this.amount === other.amount) &&
			(toMoment(this.to).isSame(toMoment(other.from)))
		);
	}
}

HireInvoiceItem.addItemClass(HIIBbItem);

export default HIIBbItem;
