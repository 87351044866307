import React, {
	useState,
	useEffect,
} from 'react';
import {
	Popover,
	Input,
	Empty,
	Spin,
} from 'antd';
import useSearch from 'react-hook-search';
import Button from '@client/components/Button';
import styles from './styles/SearchableSelect.module.css';

const SearchableSelect = ({
	popoverProps,
	buttonProps,
	buttonText,
	items,
	onSelect,
	disabled,
	loading,
	renderItem,
	searchProperties,
}) => {
	const [open, setOpen] = useState(false);

	const [filteredItems, searchValue, setSearchValue] = useSearch(
		items,
		searchProperties,
	);

	useEffect(() => {
		setSearchValue('');
	}, [open, setSearchValue]);

	const select = async (item) => {
		const result = await onSelect(item);

		if (result) {
			setOpen(false);
		}
	};

	let content = (
		<Empty
			image={Empty.PRESENTED_IMAGE_SIMPLE}
			description={loading ? '' : 'No results found'}
			className={styles.empty}
		/>
	);

	if (filteredItems.length > 0) {
		content = filteredItems.map((i, index) => (
			<>
				<button
					type="button"
					className={styles.item}
					onClick={() => select(i)}
				>
					{renderItem(i, index)}
				</button>
				<div className={styles.separator} />
			</>
		));
	}

	return (
		<Popover
			trigger="click"
			placement="bottom"
			overlayClassName={styles.list}
			onOpenChange={setOpen}
			open={disabled ? false : open}
			{...popoverProps}
			title={(
				<Input.Search
					data-cy="searchable-select-input"
					className={styles.search}
					placeholder="Search..."
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			)}
			content={(
				<div
					data-cy="searchable-select-content"
					className={styles.listInner}
				>
					{loading && (<Spin className={styles.loadingOverlay} />)}
					{content}
				</div>
			)}
		>
			<Button
				type="primary"
				disabled={disabled}
				{...buttonProps}
			>
				{buttonText}
			</Button>
		</Popover>
	);
};

export default SearchableSelect;
