// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AgOtsKBMp_bz9XO4STux {\n\tbackground: rgba(161, 161, 161, 0.073);\n}\n\n.TSt9N0biZVBR0qV1uqJc {\n\ttext-align: right;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/TcInCostAllocation.module.css"],"names":[],"mappings":"AAAA;CACC,sCAAsC;AACvC;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".disabled {\n\tbackground: rgba(161, 161, 161, 0.073);\n}\n\n.alignRight {\n\ttext-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabled": "AgOtsKBMp_bz9XO4STux",
	"alignRight": "TSt9N0biZVBR0qV1uqJc"
};
export default ___CSS_LOADER_EXPORT___;
