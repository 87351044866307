// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OA0oQoeVB1clWmspZHGS {\n\twidth: 300px;\n}\n\n.CODrJmzk1SvVDj61z6Nw {\n\tmargin-left: 10px;\n\tmargin-right: 10px;\n}\n\n.w1ObOPBbrnA7S3LCKFvj {\n\theight: 500px;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/dashboards/ReceivablesAgingDashboard.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,WAAW;CACX,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".rangeSelectorCard {\n\twidth: 300px;\n}\n\n.rangeSelector {\n\tmargin-left: 10px;\n\tmargin-right: 10px;\n}\n\n.emptyWrapper {\n\theight: 500px;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rangeSelectorCard": "OA0oQoeVB1clWmspZHGS",
	"rangeSelector": "CODrJmzk1SvVDj61z6Nw",
	"emptyWrapper": "w1ObOPBbrnA7S3LCKFvj"
};
export default ___CSS_LOADER_EXPORT___;
