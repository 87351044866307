import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Card,
	Typography,
	Divider,
	Spin,
	notification,
} from 'antd';
import qs from 'qs';
import { SUPPORT_EMAIL } from '@shared/utils/constants';
import { Links } from '@client/utils/links';
import Button from '@client/components/Button';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	getActivationDetails,
	activateAccount,
	setSelectedOrganizationId,
} from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import dynamicDelay from '@client/utils/dynamicDelay';
import logo from '@client/assets/images/logo_black.png';
import styles from './styles/ActivationScreen.module.css';

const ActivationScreen = () => {
	const [activating, setActivating] = useState(false);

	const { refreshAuthInfo } = useAuth();
	const history = useHistory();
	const { t: token } = qs.parse(history.location.search.replace('?', ''));

	const [
		details,
		_refresh,
		error,
		loading,
	] = useFetchedState(
		() => getActivationDetails(token),
		[token],
		{ showNotification: false, autoRefresh: false },
	);

	const activateAccountClick = async () => {
		setActivating(true);

		try {
			// Spend at least 2 seconds, so the user has a chance to see it loading
			await dynamicDelay(async () => {
				await activateAccount(token);
				await refreshAuthInfo();

				notification.success({
					message: 'Account was activated',
					description: `Your account (${details.name}) has been activated`,
				});

				setSelectedOrganizationId(details.id);
			}, 2000);

			history.push(Links.Home.get());
			window.location.reload();
		} catch (e) {
			console.error('Error activating.', e);
			showErrorNotification('Something went wrong', e);
		}

		setActivating(false);
	};

	let content;

	if (error != null) {
		content = (
			<div className={styles.errorContainer}>
				<Typography.Paragraph className={styles.errorMessage}>
					{error.message}
				</Typography.Paragraph>
				<Typography.Paragraph className={styles.errorSupport}>
					If you believe this is a mistake,
					<br />
					please do not hesitate to contact us at:
					<br />
					<strong>{SUPPORT_EMAIL}</strong>
				</Typography.Paragraph>
			</div>
		);
	} else if (loading) {
		content = (<Spin />);
	} else {
		content = (
			<>
				<Typography.Text>
					Please confirm that you would like to activate the following account:
				</Typography.Text>
				<Typography.Title level={4} className={styles.accountName}>
					{details.name}
				</Typography.Title>
				<Button
					type="primary"
					block
					loading={activating}
					className={styles.activateButton}
					onClick={activateAccountClick}
				>
					Activate
				</Button>
			</>
		);
	}

	return (
		<div className={styles.container}>
			<Card className={styles.inner}>
				<img
					src={logo}
					className={styles.logo}
					alt="Logo"
				/>
				<Typography.Title
					level={2}
				>
					Account activation
				</Typography.Title>
				<Divider />
				{content}
			</Card>
		</div>
	);
};

export default ActivationScreen;
