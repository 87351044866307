import {
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '../utils/currency';
import TemplateItem from '../TemplateItem';
import getFirstNotNull from '../utils/getFirstNotNull';
import HireInvoiceItem from './HireInvoiceItem';

class HIIOffHireBunker extends HireInvoiceItem {
	static get itemType() {
		return 'offHireBunker';
	}

	constructor(id, isOriginal, params = {}) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this._showTotal = true;
		this.itemTypeLabel = 'Bunker';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.OFF_HIRE_BUNKERS;

		this.bunkerId = params.bunkerId;
		this.fuelGrade = params.fuelGrade;
		this.quantity = params.quantity;
		this.pricePerTon = params.pricePerTon;
		this.baseCurrency = params.currency;
		this.exchangeRate = params.exchangeRate;
		this.pnlGroup = PnlGroups.OFFHIRE;

		this._fields = {
			quantity: {
				type: HIIFieldTypes.NUMBER,
				label: 'Quantity',
			},
			pricePerTon: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Price per ton',
			},
		};
	}

	_calculateTotal(invoiceItems) {
		const offHirePeriod = this.getParent(invoiceItems);

		if (offHirePeriod == null) {
			return 0;
		}

		let total;

		if (this.fixtureCurrency !== this.currency) {
			total = this.pricePerTon / (this.exchangeRate ?? 1);
		} else {
			total = this.pricePerTon;
		}

		total *= this.quantity;

		return -1 * total * (offHirePeriod.percentageForOwnersAccount / 100);
	}

	getDescription() {
		const pricePerTon = (this.currency !== this.fixtureCurrency && this.exchangeRate != null) ?
			this.pricePerTon / this.exchangeRate :
			this.pricePerTon;

		return `${this.quantity}MT ${this.fuelGrade} at ${formatCurrency(pricePerTon, this.fixtureCurrency)}/MT off-hire bunker`;
	}

	static async _getClassParams(model, _parentModel) {
		const offHireBunkerInstance = await model.getVoyageOffHireBunker();
		const bunker = await offHireBunkerInstance.getBunker();

		return {
			bunkerId: offHireBunkerInstance.id,
			quantity: getFirstNotNull(model.quantityOverride, bunker.quantity),
			fuelGrade: getFirstNotNull(model.fuelGradeOverride, bunker.fuelGrade),
			pricePerTon: getFirstNotNull(model.pricePerTonOverride, bunker.pricePerTon),
			currency: getFirstNotNull(model.currencyOverride, bunker.currency),
			exchangeRate: getFirstNotNull(model.exchangeRateOverride, bunker.exchangeRate),
			fixtureCurrency: model.fixtureCurrency,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.voyageOffHireBunkerId = this.bunkerId;

		await model.save();

		if (this.isOriginal) {
			const offHireBunkerInstance = await model.getVoyageOffHireBunker();
			const bunker = await offHireBunkerInstance.getBunker();

			bunker.quantity = this.quantity;
			bunker.fuelGrade = this.fuelGrade;
			bunker.pricePerTon = this.pricePerTon;
			bunker.currency = this.currency;
			bunker.exchangeRate = this.exchangeRate;

			await bunker.save();
		} else {
			model.quantityOverride = this.quantity;
			model.fuelGradeOverride = this.fuelGrade;
			model.pricePerTonOverride = this.pricePerTon;
			model.currencyOverride = this.currency;
			model.exchangeRateOverride = this.exchangeRate;

			await model.save();
		}
	}

	_getTemplateItemParams(_invoiceItems) {
		const price = (this.currency !== this.fixtureCurrency && this.exchangeRate != null) ?
			this.pricePerTon / this.exchangeRate :
			this.pricePerTon;

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.fuelGrade,
					`${this.quantity} MT`,
					`${formatCurrency(price, this.fixtureCurrency)} / MT`,
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					` — ${this.fuelGrade} ${this.itemTypeLabel}`,
					`${this.quantity}`,
					'MT',
					`${formatCurrency(
						price,
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIOffHireBunker);

export default HIIOffHireBunker;
