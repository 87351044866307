import {
	HIIFieldTypes,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '../utils/currency';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';

class HIIBallastBonus extends HireInvoiceItem {
	static get itemType() {
		return 'ballastBonus';
	}

	constructor(id, isOriginal, params = {}) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.amount = Number(params.amount);

		this._showTotal = true;
		this.itemTypeLabel = 'Gross ballast bonus';
		this.pnlGroup = PnlGroups.BALLAST_BONUSES;
		this.addManually = true;
		this.templateSection = TemplateItem.Sections.EXPENSES_CONTRACTUAL;

		this._fields = {
			amount: {
				type: HIIFieldTypes.CURRENCY,
				label: 'Gross ballast bonus',
			},
		};
	}

	_calculateTotal() {
		return this.amount;
	}

	getDescription() {
		return `${formatCurrency(this.amount, this.fixtureCurrency)} gross ballast bonus`;
	}

	static _getClassParams(model, _parentModel) {
		return {
			amount: model.amount,
			fixtureCurrency: model.fixtureCurrency,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.amount = this.amount;

		await model.save();
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: ['Ballast bonus', formatCurrency(this.amount, this.fixtureCurrency)],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					'Ballast bonus',
					'1',
					'',
					formatCurrency(this.amount, this.fixtureCurrency, { hideSymbol: true }),
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIBallastBonus);

export default HIIBallastBonus;
