import {
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import { formatPercentage } from '@shared/utils/formatPercentage';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';

class HIIDespatchBrokerCommission extends HireInvoiceItem {
	static get itemType() {
		return 'despatchBrokerCommission';
	}

	constructor(id, isOriginal, params) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this.brokerId = params.brokerId;
		this.brokerName = params.brokerName;
		this.percentage = params.percentage;
		this.cargoId = params.cargoId;

		this._showTotal = true;
		this.itemTypeLabel = 'Broker Commission';
		this.addManually = false;
		this.pnlGroup = PnlGroups.BROKER_COMMISSIONS;
		this.templateSection = TemplateItem.Sections.COMMISSIONS;

		this._fields = {};
	}

	_calculateTotal(invoiceItems) {
		const despatch = this.getParent(invoiceItems);

		const total = despatch.getTotal(invoiceItems) * (this.percentage / 100);

		return -1 * total;
	}

	getDescription(invoiceItems) {
		const parent = this.getParent(invoiceItems);

		if (parent?.children.filter((c) => c instanceof HIIDespatchBrokerCommission).length > 1) {
			return `${formatPercentage(this.percentage, 2)}, ${this.brokerName}`;
		}

		return `${formatPercentage(this.percentage, 2)}`;
	}

	static async _getClassParams(model, parentModel) {
		const broker = await model.getBroker(parentModel);

		return {
			brokerId: model.brokerId,
			brokerName: broker.name,
			percentage: broker.BrokerInFixture.commission,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.brokerId = this.brokerId;
		model.cargoId = this.cargoId;

		await model.save();
	}

	_getTemplateItemParams(invoiceItems) {
		const despatch = this.getParent(invoiceItems);

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: ['Broker commission', this.getDescription(invoiceItems)],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					' — Broker commission',
					`${formatPercentage(this.percentage, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(despatch.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIDespatchBrokerCommission);

export default HIIDespatchBrokerCommission;
