import React, {
	ReactElement,
	ReactNode,
} from 'react';
import { Switch } from 'antd';
import {
	InputProps,
	TextAreaProps,
} from 'antd/lib/input';
import moment, { Moment } from 'moment';
import { PeriodValue } from 'aws-sdk/clients/servicequotas';
import { toMoment } from '@shared/utils/date';
import { capitalize } from '@shared/utils/string';
import { formatCurrency } from '@shared/utils/currency';
import { formatPercentage } from '@shared/utils/formatPercentage';
import { formatDate } from '@client/utils/formatDate';
import Select, { SelectProps } from '@client/components/Select';
import EmptyText from '@client/components/EmptyText';
import CurrencyInput, { CurrencyInputProps } from '@client/components/CurrencyInput';
import DatePicker, { DatePickerProps } from '@client/components/DatePicker';
import NumericInput, { NumericInputProps } from '@client/components/NumericInput';
import PercentageInput, { PercentageInputProps } from '@client/components/PercentageInput';
import getOptionsLabel from '@client/utils/getOptionsLabel';
import PeriodSelector, { PeriodSelectorProps } from '@client/components/PeriodSelector';
import styles from '@client/components/EditableDetails/EditableDetails.module.css';
import EditableInput from '@client/components/EditableTableRedux/EditableInput';
import NumberListInput, { NumberListInputProps } from './NumberListInput';
import ListInput, { ListInputProps } from './ListInput';
import MultiCurrencyInput, {
	MultiCurrencyInputProps,
	MultiCurrencyValueObject,
} from './MultiCurrencyInput';
import { DetailsItem } from './Details';
import AsyncInput from './Async/AsyncInput';

export type EditableFieldType = (
	| 'number'
	| 'currency'
	| 'multiCurrency'
	| 'percentage'
	| 'text'
	| 'textarea'
	| 'select'
	| 'list'
	| 'numberList'
	| 'date'
	| 'daterange'
	| 'switch'
	| 'period'
	| 'empty'
);

export type ExtraPropsByType = (
	{
		type?: undefined;
		inputProps?: undefined;
		value?: any;
		editable?: undefined;
	} | (
		{
			editable?: boolean;
		} & (
			{
				type: 'text';
				inputProps?: InputProps;
				value: string | null;
			} | {
				type: 'number';
				inputProps?: NumericInputProps;
				value: number | null;
			} | {
				type: 'currency';
				inputProps?: CurrencyInputProps;
				value: number | null;
			} | {
				type: 'multiCurrency';
				inputProps: MultiCurrencyInputProps;
				value: Partial<MultiCurrencyValueObject>;
			} | {
				type: 'percentage';
				inputProps?: PercentageInputProps;
				value: number | null;
			} | {
				type: 'textarea';
				value: string;
				inputProps?: TextAreaProps;
				rows?: TextAreaProps['rows'];
			} | {
				type: 'select';
				inputProps?: SelectProps<any>;
				value: string | number | boolean | (string | number)[] | null;
				onChange?: SelectProps<any>['onChange'];
				options: SelectProps<any>['options'];
				multiple?: boolean;
			} | {
				type: 'list';
				inputProps?: Partial<ListInputProps>;
				value: string[];
			} | {
				type: 'numberList';
				inputProps?: NumberListInputProps;
				value: number[];
			} | {
				type: 'date';
				inputProps?: DatePickerProps;
				value: Moment | string | null;
			} | {
				type: 'daterange';
				inputProps?: DatePickerProps;
				value: [Moment, Moment];
			} | {
				type: 'switch';
				inputProps?: undefined;
				value: boolean;
			} | {
				type: 'period';
				inputProps?: PeriodSelectorProps;
				value: PeriodValue;
			} | {
				type: 'empty';
				inputProps?: undefined;
				value: undefined;
			}
		)
	)
);

export type EditableDetailsItem<Values> = Omit<DetailsItem, 'value'> & {
	render?: (item: any, editValues?: Values) => ReactElement | string | null | number;
	renderInput?: (props: {
		value: any;
		onChange: (value: any) => void;
	}) => ReactElement;
	show?: boolean | ((values: Values, editing: boolean) => boolean);
} & ExtraPropsByType;

type EditChange = (itemKey: string, newValue: any) => Promise<void> | void;

const getValue = <Values, >(item: EditableDetailsItem<Values>, editValues: Values | undefined) => {
	if (editValues == null || !(typeof editValues === 'object')) {
		return item.value;
	}

	return (
		item.key in editValues ?
			editValues[item.key] :
			item.value
	);
};

const getDateValue = <Values, >(item: EditableDetailsItem<Values>, editValues: Values) => (
	getValue(item, editValues) ?? undefined
);

export const editableFieldTypes: {
	[key in EditableFieldType]: {
		renderInput: <Values>(
			item: EditableDetailsItem<Values> & { type: key },
			editChange: EditChange,
			editValues: Values,
		) => ReactElement | null;
		renderValue?: <Values>(item: EditableDetailsItem<Values> & { type: key }) => (
			ReactNode
		);
		isFilled?: <Values>(
			value: Exclude<(EditableDetailsItem<Values> & { type: key })['inputProps'], undefined>['value'],
			item: EditableDetailsItem<Values> & { type: key },
		) => boolean;
	};
} = {
	date: {
		renderInput: (item, editChange, editValues) => (
			<DatePicker
				{...item.inputProps || {}}
				range={item.inputProps?.range ?? false}
				allowClear={item.inputProps?.allowClear ?? false}
				value={getDateValue(item, editValues)}
				onChange={(result) => {
					editChange(item.key, result);

					if (item.inputProps != null && typeof item.inputProps.onChange === 'function') {
						item.inputProps.onChange(result);
					}
				}}
				className={styles.datePicker}
			/>
		),
		renderValue: (item) => (moment.isMoment(item.value) ? formatDate(item.value) : item.value),
	},
	daterange: {
		renderInput: (item, editChange, _editvalues) => {
			let value: [Moment, Moment] | undefined;

			if (
				Array.isArray(item.value) &&
				item.value[0] != null &&
				item.value[1] != null
			) {
				value = [toMoment(item.value[0]), toMoment(item.value[1])];
			}

			return (
				<DatePicker
					{...item.inputProps || {}}
					range
					defaultValue={value}
					onChange={(result) => editChange(item.key, result)}
					className={styles.datePicker}
				/>
			);
		},
	},
	select: {
		isFilled: (value, item) => {
			if (value == null) {
				return false;
			}

			if (item.multiple) {
				return value.length > 0;
			}

			return true;
		},
		renderValue: (item) => {
			if (item.multiple && Array.isArray(item.value)) {
				const values = item.value.map((value) => (
					getOptionsLabel(value, item.options) || value
				));

				if (values.length === 0) {
					return null;
				}

				return values.join(', ');
			}

			const label = item.value != null && getOptionsLabel(item.value, item.options);

			if (label != null) {
				return label;
			}

			return item.value as ReactNode;
		},
		renderInput: (item, editChange, editValues) => {
			const value = editValues[item.key] === undefined ? item.value : editValues[item.key];

			const handleChange = async (
				newValue:
				| string
				| number
				| null
				| boolean
				| Array<string | number | null>,
			) => {
				await editChange(item.key, newValue);
				if (typeof item.onChange === 'function') {
					item.onChange(newValue);
				}
			};

			const handleDeselect = async (deselectedValue: string) => {
				await editChange(item.key, value.filter((i: string) => i !== deselectedValue));
			};

			return (
				<Select<string>
					className={styles.select}
					value={value}
					onSelect={handleChange}
					onDeselect={item.multiple ? handleDeselect : undefined}
					{...item.inputProps || {}}
					mode={item.multiple ? 'multiple' : undefined}
					options={item.options}
				/>
			);
		},
	},

	number: {
		renderInput: (item, editChange, editValues) => (
			<NumericInput
				className={styles.input}
				{...item.inputProps || {}}
				value={getValue(item, editValues)}
				onChange={async (newValue) => await editChange(item.key, newValue)}
			/>
		),
	},
	list: {
		isFilled: (value) => value != null && value.length > 0,
		renderValue: (item) => item.value.join(', '),
		renderInput: (item, editChange, editValues) => (
			<ListInput
				{...item.inputProps || {}}
				className={styles.input}
				value={getValue(item, editValues)}
				onChange={async (values) => await editChange(item.key, values)}
			/>
		),
	},
	numberList: {
		isFilled: (value) => value != null && value.length > 0,
		renderValue: (item) => item.value.join(', '),
		renderInput: (item, editChange, editValues) => (
			<NumberListInput
				{...item.inputProps || {}}
				className={styles.input}
				value={getValue(item, editValues)}
				onChange={async (values) => await editChange(item.key, values)}
			/>
		),
	},
	currency: {
		renderValue: (item) => (
			(item.inputProps?.currency != null && item.value != null) ?
				formatCurrency(item.value, item.inputProps.currency) :
				'—'
		),
		renderInput: (item, editChange, editValues) => (
			<CurrencyInput
				{...item.inputProps || {}}
				className={styles.input}
				value={getValue(item, editValues)}
				onChange={async (newValue) => await editChange(item.key, newValue)}
			/>
		),
	},
	multiCurrency: {
		renderValue(item) {
			if (item.value.value == null || item.value.currency == null) {
				return '—';
			}

			return formatCurrency(item.value.value, item.value.currency);
		},
		renderInput(item, editChange, editValues) {
			const value = getValue(item, editValues);

			return (
				<MultiCurrencyInput
					{...item.inputProps || {}}
					className={styles.input}
					inputValues={value}
					onChange={async (newValue) => await editChange(item.key, newValue)}
				/>
			);
		},
	},
	percentage: {
		renderValue: (item) => item.value != null && formatPercentage(item.value),
		renderInput: (item, editChange, editValues) => (
			<PercentageInput
				{...item.inputProps || {}}
				className={styles.input}
				value={getValue(item, editValues)}
				onChange={async (newValue) => await editChange(item.key, newValue)}
			/>
		),
	},

	textarea: {
		renderInput: (item, editChange, editValues) => (
			<AsyncInput
				type="text"
				inputFieldType="textarea"
				{...item.inputProps || {}}
				className={styles.input}
				value={getValue(item, editValues)}
				onChange={async (e) => await editChange(item.key, e.target.value)}
			/>
		),
	},
	period: {
		isFilled: (value) => (
			value != null &&
			value[0] != null &&
			value[1] != null &&
			value[2] != null
		),
		renderValue: (item) => `${capitalize(item.value[2])} ${item.value[0]} ${capitalize(item.value[1])}`,
		renderInput: (item, editChange, editValues) => (
			<PeriodSelector
				{...(item.inputProps || {})}
				value={getValue(item, editValues)}
				onChange={async (newValue) => await editChange(item.key, newValue)}
			/>
		),
	},
	text: {
		renderInput: (item, editChange, editValues) => (
			<EditableInput
				{...item.inputProps || {}}
				className={styles.input}
				value={getValue(item, editValues)}
				onChange={async (e) => await editChange(item.key, e)}
			/>
		),
	},
	switch: {
		renderInput: (item, editChange, editValues) => (
			<Switch
				{...item.inputProps || {}}
				checked={getValue(item, editValues)}
				onChange={async (e) => await editChange(item.key, e)}
			/>
		),
	},
	empty: {
		isFilled: () => true,
		renderInput: () => null,
	},
};

const getInput = <Values, >(
	item: EditableDetailsItem<Values>,
	editChange: EditChange,
	editValues?: Values,
) => {
	if (typeof item.renderInput === 'function') {
		return item.renderInput({
			value: getValue(item, editValues),
			onChange: async (value) => await editChange(item.key, value),
		});
	}

	const type = item.type == null ?
		editableFieldTypes.text :
		(editableFieldTypes[item.type] ?? editableFieldTypes.text);

	// Below line will give error: Expression produces a union type that is too complex to represent.
	// Nothing is wrong though!
	// @ts-ignore
	return type.renderInput(item, editChange, editValues);
};

export type EditableFieldProps<Values> = {
	item: EditableDetailsItem<Values>;
	editChange: EditChange;
	editing: boolean;
	editValues?: Values;
};

const EditableField = <Values, >({
	item,
	editChange,
	editValues,
	editing,
}: EditableFieldProps<Values>): ReactElement | null => {
	if (editing && item.editable) {
		return getInput(item, editChange, editValues);
	}

	if (typeof item.render === 'function') {
		const toRender = item.render(item, editValues);

		if (toRender == null) {
			return null;
		}

		if (typeof toRender === 'string' || typeof toRender === 'number') {
			return <>{toRender}</>;
		}

		return toRender;
	}

	if (item.value == null) {
		return (<EmptyText />);
	}

	const type = item.type == null ?
		editableFieldTypes.text :
		(editableFieldTypes[item.type] ?? editableFieldTypes.text);

	if (type.renderValue != null) {
		// Below line will give the following error:
		// Expression produces a union type that is too complex to represent.
		// Nothing is wrong though!
		// @ts-ignore
		return type.renderValue(item);
	}

	return item.value as ReactElement;
};

export default EditableField;
