import React from 'react';
import {
	Layer,
	Source,
} from 'react-map-gl';

const GeoJsonLayer = ({
	id,
	data,
	type,
	layerStyle = {},
	lineMetrics = false,
}) => (
	<Source
		id={id}
		type="geojson"
		data={data}
		lineMetrics={lineMetrics}
	>
		<Layer
			id={`${id}-layer`}
			type={type}
			{...layerStyle}
		/>
	</Source>
);

export default GeoJsonLayer;
