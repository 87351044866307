import React from 'react';
import {
	Badge,
	Card,
	Grid,
	Space,
} from 'antd';
import classNames from 'classnames';
import { Moment } from 'moment';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute } from '@fortawesome/pro-solid-svg-icons';
import {
	capitalize,
	trimString,
} from '@shared/utils/string';
import type { Port } from '@api/utils/ports';
import type { ItineraryEntryState } from '@api/features/ops/getVesselItinerary';
import { getEtaText } from './utils/getEtaText';
import styles from './SeaPassageEntry.module.css';

type SeaPassageEntryProps = {
	state: ItineraryEntryState;
	departurePort: Port | null;
	arrivalPort: Port | null;
	departureDate: Moment | null;
	arrivalDate: Moment | null;
	selected: boolean;
	selectSeaPassage: () => void;
	setActiveTab: React.Dispatch<React.SetStateAction<string>>;
	speed: number;
};

const SeaPassageEntry = (props: SeaPassageEntryProps) => {
	const {
		state,
		departurePort,
		arrivalPort,
		departureDate,
		arrivalDate,
		selected,
		selectSeaPassage,
		speed,
		setActiveTab,
	} = props;

	const etaText = getEtaText(state, departureDate, arrivalDate, speed);

	const screens = Grid.useBreakpoint();

	let status: PresetStatusColorType;

	switch (state) {
		case 'in progress':
			status = 'processing';
			break;
		case 'completed':
			status = 'success';
			break;
		case 'estimated':
			status = 'warning';
			break;
		default:
			status = 'warning';
			break;
	}

	return (
		<div
			className={styles.cardWrapper}
		>
			<Card
				className={classNames(styles.seaPassage, {
					[styles.estimated]: state === 'estimated',
					[styles.lessPadding]: !screens.xl,
					[styles.selectedSeaPassage]: selected,
				})}
				onClick={() => {
					selectSeaPassage();
					setActiveTab('summary');
				}}
			>
				<Space
					direction="vertical"
					className={classNames(styles.content, { [styles.smallerTitle]: !screens.xl })}
				>
					<div className={styles.header}>
						<Space>
							<FontAwesomeIcon icon={faRoute} />
							<b>
								{trimString(
									capitalize(departurePort?.name ?? 'Unknown port'),
									screens.xxl ? 18 : 10,
									true,
								)}
								{' '}
								to
								{' '}
								{trimString(
									capitalize(arrivalPort?.name ?? 'Unknown port'),
									screens.xxl ? 18 : 10,
									true,
								)}
							</b>
							{!screens.xl && (
								<Badge status={status} />
							)}
						</Space>
						{screens.xl && (
							<Badge status={status} text={(<b>{capitalize(state)}</b>)} />
						)}
					</div>
					<div className={classNames(styles.smallerTitle, { [styles.smaller]: !screens.xl })}>
						{etaText}
					</div>
				</Space>
			</Card>
		</div>
	);
};

export default SeaPassageEntry;
