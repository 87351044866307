import type { ItineraryEntryState } from '@api/features/ops/getVesselItinerary';

export function getPortCallProgress(
	state: ItineraryEntryState,
): number {
	if (state === 'estimated') {
		return 0;
	}

	if (state === 'in progress') {
		return 50;
	}

	return 100;
}
