import React, { ReactNode } from 'react';
import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import { ExtendUnique } from '@client/utils/ExtendUnique';

type BlockableDrawerProps = ExtendUnique<{
	changed: boolean;
	onClose: () => void;
	blockMessage?: string;
	children?: ReactNode;
	visible: boolean;
}, DrawerProps>;

const DEFAULT_BLOCK_MESSAGE = 'You have unsaved data. Are you sure you want to discard your changes?';

const BlockableDrawer = (props: BlockableDrawerProps) => {
	const {
		changed,
		onClose,
		blockMessage,
		children,
		visible,
		...rest
	} = props;

	const {
		makeBlockable,
		useBlocker,
	} = useNavigationBlock();

	const toggleVisibility = makeBlockable(() => {
		onClose();
	});

	const isBlocked = visible && changed;

	useBlocker(
		isBlocked,
		blockMessage ?? DEFAULT_BLOCK_MESSAGE,
	);

	return (
		<Drawer
			onClose={toggleVisibility}
			open={visible}
			{...rest}
		>
			{children}
		</Drawer>
	);
};

export default BlockableDrawer;
