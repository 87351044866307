import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import QuickBar from '@client/components/QuickBar/QuickBar';
import VesselActivityWidget from '../widgets/VesselActivityWidget';
import { DashboardComponent } from '../helpers/dashboardInfo';

const HomeDashboard: DashboardComponent = ({
	setLastUpdated,
}) => {
	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<QuickBar />
			</Col>
			<Col span={24}>
				<VesselActivityWidget setLastUpdated={setLastUpdated} />
			</Col>
		</Row>
	);
};

export default HomeDashboard;
