import type {
	ItineraryEntryState,
	ItinerarySeaPassageDto,
} from '@api/features/ops/getVesselItinerary';

export const getSeaPassageProgress = (seaPassage: ItinerarySeaPassageDto, state: ItineraryEntryState): number => {
	if (state === 'estimated') {
		return 0;
	}

	if (state === 'in progress') {
		return 50;
	}

	return 100;
};
