// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q3nswS07RfM50IOW0fxE {\r\n\theight: 100%;\r\n\twidth: 100%;\r\n\tpadding: 2em;\r\n}\r\n\r\n.dhmC_OOTkzuAbkuYKCig {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tflex-direction: column;\r\n}\r\n\r\n.eKWuoV35PfXdAA5GpFwL {\r\n\tdisplay: flex;\r\n\ttext-align: center;\r\n\tflex-direction: column;\r\n\tjustify-content: center;\r\n}\r\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/ErrorPage.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;CACX,YAAY;AACb;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,sBAAsB;CACtB,uBAAuB;AACxB","sourcesContent":[".centeredContainer {\r\n\theight: 100%;\r\n\twidth: 100%;\r\n\tpadding: 2em;\r\n}\r\n\r\n.centeredContent {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tflex-direction: column;\r\n}\r\n\r\n.textBox {\r\n\tdisplay: flex;\r\n\ttext-align: center;\r\n\tflex-direction: column;\r\n\tjustify-content: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centeredContainer": "Q3nswS07RfM50IOW0fxE",
	"centeredContent": "dhmC_OOTkzuAbkuYKCig",
	"textBox": "eKWuoV35PfXdAA5GpFwL"
};
export default ___CSS_LOADER_EXPORT___;
