import React, { useCallback } from 'react';
import { faInbox } from '@fortawesome/pro-light-svg-icons';
import { ColumnsType } from 'antd/lib/table/interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { DATE_AND_TIME } from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import type { GetOrganizationsResponse } from '@api/features/admin/getOrganizations';
import {
	getOrganizations,
	createOrganization,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { renderDate } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';

type OrganizationTableData = GetOrganizationsResponse[number];

type OrganizationFormData = Pick<OrganizationTableData, (
	| 'name'
	| 'providerIds'
)>;

const columns: ColumnsType<OrganizationTableData> = [
	{
		title: 'Id',
		dataIndex: 'id',
		key: 'id',
		width: 40,
		align: 'center',
		sorter: standardSort('id'),
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		sorter: standardSort('name'),
	},
	{
		title: 'Number of team members',
		dataIndex: 'numberOfUsers',
		key: 'numberOfUsers',
		sorter: standardSort('numberOfUsers'),
	},
	{
		title: 'Number of vessels',
		dataIndex: 'numberOfVessels',
		key: 'numberOfVessels',
		sorter: standardSort('numberOfVessels'),
	},
	{
		title: 'Creation date',
		dataIndex: 'createdAt',
		render: renderDate(DATE_AND_TIME),
	},
	{
		title: 'Feature highlight #',
		dataIndex: 'featureHighlightNo',
	},
];

const OrganizationListScreen: React.FC = () => {
	const [organizations, refresh, error, loading] = useFetchedState(getOrganizations);

	const submitCreateOrganizationForm = useCallback(
		async (values: { name: string; providerId: string }) => {
			const {
				name,
				providerId,
			} = values;

			const org = await createOrganization(name, providerId);
			refresh();

			return org;
		}, [refresh],
	);

	return (
		<ListTableScreen<OrganizationTableData, OrganizationFormData>
			title="Organizations"
			rootPageTitle="Organizations"
			columns={columns}
			loading={loading}
			emptyTitle="No organizations found"
			emptyDescription="No organizations found"
			emptyIcon={faInbox as IconDefinition}
			error={error}
			data={organizations}
			formFields={[
				{
					label: 'Name',
					name: 'name',
					type: 'text',
					required: true,
				},
				{
					label: 'Tenant ID',
					name: 'providerIds',
					type: 'text',
					required: false,
					tooltip: 'The client\'s Microsoft tenant ID. Leave blank to generate activation link',
				},
			]}
			formButtonText="Create organization"
			// @ts-ignore
			onFormSubmit={submitCreateOrganizationForm}
			getRowHref={(row) => Links.Organization.get(row.id)}
			redirectOnSubmit={false}
			showEmptyArrow
		/>
	);
};

export default OrganizationListScreen;
