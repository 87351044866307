import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faBadgeDollar as faBadgeDollarLight,
	faUndo,
	faTimes,
	faDotCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faBadgeDollar } from '@fortawesome/pro-solid-svg-icons';
import { ExpenseActions } from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';

export const getActionDetails = (action) => {
	switch (action) {
		case ExpenseActions.SUBMITTED:
			return {
				icon: (<FontAwesomeIcon icon={faCheck} />),
				iconColor: 'blue',
				label: 'Submitted for approval',
			};
		case ExpenseActions.APPROVED:
			return {
				icon: (<FontAwesomeIcon icon={faBadgeDollarLight} />),
				iconColor: 'green',
				label: 'Approved',
			};
		case ExpenseActions.UNSUBMITTED:
			return {
				icon: (<FontAwesomeIcon icon={faUndo} />),
				iconColor: 'red',
				label: 'Unsubmitted',
			};
		case ExpenseActions.REJECTED:
			return {
				icon: (<FontAwesomeIcon icon={faTimes} />),
				iconColor: 'red',
				label: 'Rejected',
			};
		case ExpenseActions.MARKED_PAID:
			return {
				icon: (<FontAwesomeIcon icon={faBadgeDollar} />),
				iconColor: 'blue',
				label: 'Marked as paid',
			};
		case ExpenseActions.MARKED_UNPAID:
			return {
				icon: (<FontAwesomeIcon icon={faUndo} />),
				iconColor: 'red',
				label: 'Marked as unpaid',
			};
		default:
			return {
				icon: (<FontAwesomeIcon icon={faDotCircle} />),
				iconColor: 'blue',
				label: capitalize(action),
			};
	}
};
