import React from 'react';
import { useParams } from 'react-router';
import { FixtureTypes } from '@shared/utils/constants';
import FixtureDetailsScreen from '@client/screens/fixtures/shared/FixtureDetailsScreen';

const FixtureBbOutDetailsScreen = () => {
	const { id }: {id?: string | undefined} = useParams();

	const fixtureId = Number(id);

	return (
		<FixtureDetailsScreen
			fixtureType={FixtureTypes.BB_OUT}
			fixtureId={fixtureId}
		/>
	);
};

export default FixtureBbOutDetailsScreen;
