import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Drawer,
	Row,
	Col,
	Form,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Moment } from 'moment';
import {
	Currencies,
	FuelTypes,
	VoyageBunkerTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import showErrorNotification from '@client/utils/showErrorNotification';
import { MultiCurrencyValueObject } from '@client/components/MultiCurrencyInput';
import {
	createVoyageBunker,
	updateVoyageBunker,
} from '@client/lib/api';
import getVoyageBunkerForm from '@client/utils/getVoyageBunkerForm';

type VoyageBunkerFormProps = {
	voyageId: number;
	onClose: () => void;
	initialValues?: null | {
		id?: number;
		currency?: Values<typeof Currencies>;
		type?: Values<typeof VoyageBunkerTypes>;
		pricePerTon?: number;
		exchangeRate?: number;
		date?: Moment;
	};
	visible: boolean;
	form: FormInstance;
	onSave: () => Promise<void> | void;
	baseCurrency: Values<typeof Currencies>;
}

const VoyageBunkerForm: React.FC<VoyageBunkerFormProps> = ({
	onClose,
	initialValues = null,
	visible,
	form,
	onSave,
	voyageId,
	baseCurrency,
}) => {
	const [dirty, setDirty] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [multiCurrencyValues, setMultiCurrencyValues] = useState<MultiCurrencyValueObject>(
		{
			currency: initialValues?.currency ?? baseCurrency,
			value: initialValues?.pricePerTon,
			exchangeRate: initialValues?.exchangeRate,
		},
	);

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	useBlocker(dirty);

	const isEditing = useMemo(() => {
		if (initialValues == null || Object.keys(initialValues).length === 0) {
			return false;
		}

		return true;
	}, [initialValues]);

	const forceCloseDrawer = () => {
		form.resetFields();
		onClose();
		setDirty(false);
	};

	const closeDrawer = makeBlockable(forceCloseDrawer);

	const saveBunkers = async (values: {
		currency: Values<typeof Currencies>;
		exchangeRate: number;
		pricePerTon: number;
		type: Values<typeof VoyageBunkerTypes>;
		fuelGrade: Values<typeof FuelTypes>;
		quantity: number;
		date: Moment;
	}) => {
		try {
			setSaveLoading(true);
			if (initialValues != null && initialValues.id != null) {
				await updateVoyageBunker(voyageId, initialValues.id, values);
			} else if (
				values.currency != null &&
				values.exchangeRate != null &&
				values.pricePerTon != null
			) {
				await createVoyageBunker(voyageId, values);
			}

			await onSave();
			forceCloseDrawer();
		} catch (e) {
			showErrorNotification('Could not add bunker', e as Error);
		} finally {
			setSaveLoading(false);
		}
	};

	useEffect(() => {
		form.resetFields();
		setMultiCurrencyValues({
			currency: initialValues?.currency ?? baseCurrency,
			value: initialValues?.pricePerTon,
			exchangeRate: initialValues?.exchangeRate,
		});
		if (initialValues != null) {
			form.setFieldsValue({ value: initialValues.pricePerTon });
		}
	}, [baseCurrency, form, initialValues]);

	return (
		<Drawer
			title={isEditing ? 'Edit bunkers' : 'Add bunkers'}
			placement="left"
			onClose={closeDrawer}
			open={visible}
			width={550}
			// Needed for tours to function properly
			destroyOnClose
		>
			<Row gutter={16}>
				<Col span={24}>
					<Form
						form={form}
						layout="vertical"
						onFinish={(values) => {
							let transformedValues = { ...values };

							transformedValues = {
								...transformedValues,
								pricePerTon: transformedValues.pricePerTon.value,
								currency: transformedValues.pricePerTon.currency,
								exchangeRate: transformedValues.pricePerTon.exchangeRate,
							};

							saveBunkers(transformedValues);
						}}
						initialValues={initialValues == null ? undefined : initialValues}
						onValuesChange={() => setDirty(true)}
						data-tour="bunkersForm"
					>
						{
							getVoyageBunkerForm(
								baseCurrency,
								saveLoading,
								multiCurrencyValues,
								setMultiCurrencyValues,
							)
						}
					</Form>
				</Col>
			</Row>
		</Drawer>
	);
};

export default VoyageBunkerForm;
