import React from 'react';
import {
	Skeleton,
	Typography,
} from 'antd';
import classNames from 'classnames';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';
import styles from './styles/AmountAndConvertedAmount.module.css';

export type MultiCurrencyValuesType = {
	currency: Values<typeof Currencies>;
	value: number;
	exchangeRate: number;
}
// Display currency, amount and a converted amount if amount and inputCurrency props differ
const MultiCurrencyAmount = ({
	amount,
	fixtureCurrency,
	inputCurrency,
	exchangeRate = 1,
	className,
	loading = false,
	direction = 'horizontal',
}: {
	amount: number;
	fixtureCurrency: Values<typeof Currencies>;
	inputCurrency: Values<typeof Currencies>;
	exchangeRate: number;
	direction?: 'vertical' | 'horizontal';
	className?: string;
	loading?: boolean;
}) => {
	if (loading) {
		return (<Skeleton.Input active className={styles.loader} size="small" />);
	}

	return (
		<div className={classNames(
			className,
			{
				[styles.verticalContainer]: direction === 'vertical',
			},
		)}
		>
			{formatCurrency(amount / exchangeRate, fixtureCurrency)}
			{inputCurrency != null && fixtureCurrency !== inputCurrency && (
				<Typography.Text
					className={styles.expenseAmount}
					type="secondary"
				>
					{`(${formatCurrency(amount, inputCurrency)})`}
				</Typography.Text>
			)}
		</div>
	);
};

export default MultiCurrencyAmount;
