// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kLdusyhsvfmhPwmKAkIB.ant-typography {\n\tmargin: 0 !important;\n}\n\n.gIVsYgKnIo7RGJ5uHz9j .ant-card-body {\n\tpadding: 16px;\n}\n\n.PzCgs6fzzv5GQfrAEa3q:not(:last-child) {\n\tmargin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/StatisticCard.module.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;AACrB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".noMargin:global(.ant-typography) {\n\tmargin: 0 !important;\n}\n\n.card :global(.ant-card-body) {\n\tpadding: 16px;\n}\n\n.statEntry:not(:last-child) {\n\tmargin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noMargin": "kLdusyhsvfmhPwmKAkIB",
	"card": "gIVsYgKnIo7RGJ5uHz9j",
	"statEntry": "PzCgs6fzzv5GQfrAEa3q"
};
export default ___CSS_LOADER_EXPORT___;
