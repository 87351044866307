import React, { SetStateAction } from 'react';
import { Moment } from 'moment/moment';
import DatePicker from '@client/components/DatePicker';
import Button from '@client/components/Button';
import styles from './DateFilterDropDown.module.css';

type Props = {
	filterValue: [Moment, Moment] | null;
	setFilterValue: React.Dispatch<SetStateAction<[Moment, Moment] | null>>;
	setFilterVisible: React.Dispatch<SetStateAction<boolean>>;
}

const DateFilterDropDown = ({
	filterValue,
	setFilterValue,
	setFilterVisible,
}: Props) => {
	return (
		<div className={styles.filterDateWrapper}>
			<div className={styles.rangeSelectorWrapper}>
				<DatePicker
					range
					onChange={(newRange) => {
						setFilterValue((newRange as [Moment, Moment]) ?? null);
					}}
					value={filterValue}
					time={false}
					rangeHorizontal
					placeholder={['From', 'To']}
				/>
			</div>
			<Button
				onClick={() => {
					setFilterValue(null);
					setFilterVisible(false);
				}}
				type="primary"
			>
				Clear
			</Button>
		</div>
	);
};

export default DateFilterDropDown;
