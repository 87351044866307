// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VdARHB8O1_JHkL25jD5H {\n\tgap: 10px;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.Gu6Ke9ehZJTn1eZY9YCi {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.Mb7OjWVJTqNorwYXwse7 {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-content: center;\n\talign-items: center;\n\tgap: 10px;\n}\n\n.Mb7OjWVJTqNorwYXwse7 > .ant-typography {\n\tmargin-bottom: 0;\n}\n\n.kGbXZLgBzksqPSlvYLf4 {\n\twidth: 100px;\n}\n\n.RqTBzjvcPptGC7ISk6VN {\n\tmargin-left: 10px;\n\tmargin-top: 39px;\n\twidth: fit-content;\n\theight: fit-content;\n}\n\n.QfkH188g2M81Ed_OECBn {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 5px;\n}\n\n.QfkH188g2M81Ed_OECBn > p {\n\tmargin: 0;\n\tfont-size: 13px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/tabs/styles/ConditionTab.module.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,qBAAqB;CACrB,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,iBAAiB;CACjB,gBAAgB;CAChB,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,QAAQ;AACT;;AAEA;CACC,SAAS;CACT,eAAe;AAChB","sourcesContent":[".container {\n\tgap: 10px;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.consumptionContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.conditionContainer {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-content: center;\n\talign-items: center;\n\tgap: 10px;\n}\n\n.conditionContainer > :global(.ant-typography) {\n\tmargin-bottom: 0;\n}\n\n.conditionSelect {\n\twidth: 100px;\n}\n\n.alert {\n\tmargin-left: 10px;\n\tmargin-top: 39px;\n\twidth: fit-content;\n\theight: fit-content;\n}\n\n.draftLabel {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 5px;\n}\n\n.draftLabel > p {\n\tmargin: 0;\n\tfont-size: 13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "VdARHB8O1_JHkL25jD5H",
	"consumptionContainer": "Gu6Ke9ehZJTn1eZY9YCi",
	"conditionContainer": "Mb7OjWVJTqNorwYXwse7",
	"conditionSelect": "kGbXZLgBzksqPSlvYLf4",
	"alert": "RqTBzjvcPptGC7ISk6VN",
	"draftLabel": "QfkH188g2M81Ed_OECBn"
};
export default ___CSS_LOADER_EXPORT___;
