import React, {
	useEffect,
	useState,
} from 'react';
import {
	Flex,
	Typography,
} from 'antd';
import styles from './styles/SavingIndicator.module.css';
import LoadingIndicator from './LoadingIndicator';

type Props = {
	spinning: boolean;
}

const SavingIndicator = ({ spinning }: Props) => {
	const [isLoading, setIsLoading] = useState(false);
	const [fadeClass, setFadeClass] = useState('');
	const [showSavedLabel, setShowSavedLabel] = useState(false);

	useEffect(() => {
		if (isLoading) {
			setShowSavedLabel(true);
		}

		if (!isLoading) {
			setFadeClass('');

			const fadeTimer = setTimeout(() => setFadeClass(styles.fadeOut),
				2500);

			return () => {
				clearTimeout(fadeTimer);
			};
		}

		return () => {};
	}, [isLoading]);

	useEffect(() => {
		setIsLoading(spinning);
	}, [spinning]);

	return (
		<Flex
			gap={10}
			className={styles.container}
		>
			{isLoading && (
				<>
					<Typography.Text
						className={styles.savingText}
					>
						Saving...
					</Typography.Text>
					<LoadingIndicator
						size="small"
						classNameOverride={styles.loadingIndicator}
					/>
				</>
			)}
			{showSavedLabel && !isLoading && (
				<div className={`${styles.savedLabel} ${fadeClass}`}>Changes saved!</div>
			)}
		</Flex>
	);
};

export default SavingIndicator;

