// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o7ckmNshYZwAanAOqzn8.ant-typography-edit-content {\n\tleft: 0 !important;\n\tmargin-bottom: 0;\n\tmargin-top: 0;\n}\n\n.KGc4q29VDqeG9CBYEG1M {\n\twidth: 100%;\n}\n\n.wrVU5RS1n7apstg9RnbN {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/admin/OrganizationDetailsScreen/OrganizationDetailsScreen.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,gBAAgB;CAChB,aAAa;AACd;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,aAAa;CACb,uBAAuB;AACxB","sourcesContent":[".name:global(.ant-typography-edit-content) {\n\tleft: 0 !important;\n\tmargin-bottom: 0;\n\tmargin-top: 0;\n}\n\n.logoWrapper {\n\twidth: 100%;\n}\n\n.centerContent {\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "o7ckmNshYZwAanAOqzn8",
	"logoWrapper": "KGc4q29VDqeG9CBYEG1M",
	"centerContent": "wrVU5RS1n7apstg9RnbN"
};
export default ___CSS_LOADER_EXPORT___;
