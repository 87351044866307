// Note this actually downloads the file (even if the link is never clicked)
// Please don't use it unnecessarily
const getLocalFileUrl = async (
	url: string,
	name?: string,
): Promise<string> => {
	try {
		const response = await fetch(url);

		if (!response.ok) {
			throw new Error('Response not OK');
		}

		const blob = await response.blob();
		const blobUrl = window.URL.createObjectURL(blob);

		return blobUrl;
	} catch (e) {
		console.error('Could not download url', { url, name });

		throw e;
	}
};

export default getLocalFileUrl;
