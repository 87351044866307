import { DATE_AND_TIME } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';

const getItems = ({
	name,
	companyName,
	address,
	city,
	country,
	phoneNumberOffice,
	phoneNumberMobile,
	email,
	invoiceAddress,
	invoicePhoneNumber,
	invoiceEmail,
	createdAt,
	updatedAt,
}) => [
	{
		key: 'name',
		label: 'Name',
		value: name,
		editable: true,
	},
	{
		key: 'companyName',
		label: 'Company name',
		value: companyName,
		editable: true,
	},
	{
		key: 'address',
		label: 'Address',
		value: address,
		editable: true,
	},
	{
		key: 'city',
		label: 'City',
		value: city,
		editable: true,
	},
	{
		key: 'country',
		label: 'Country',
		value: country,
		editable: true,
	},
	{
		key: 'phoneNumberOffice',
		label: 'Office phone number',
		value: phoneNumberOffice,
		editable: true,
	},
	{
		key: 'phoneNumberMobile',
		label: 'AOH phone number',
		value: phoneNumberMobile,
		editable: true,
	},
	{
		key: 'email',
		label: 'Email',
		value: email,
		editable: true,
	},
	{
		key: 'invoiceAddress',
		label: 'Invoice address',
		value: invoiceAddress,
		editable: true,
	},
	{
		key: 'invoicePhoneNumber',
		label: 'Invoice phone number',
		value: invoicePhoneNumber,
		editable: true,
	},
	{
		key: 'invoiceEmail',
		label: 'Invoice email',
		value: invoiceEmail,
		editable: true,
	},
	{
		key: 'createdAt',
		label: 'Creation date',
		value: formatDate(createdAt),
	},
	{
		key: 'updatedAt',
		label: 'Last updated',
		value: formatDate(updatedAt, DATE_AND_TIME),
	},
];

export default getItems;
