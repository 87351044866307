import React, {
	useMemo,
	useState,
} from 'react';
import {
	Dropdown,
	Menu,
} from 'antd';
import {
	AccountingItemApprovalStates,
	FixtureCounterpartyTypes,
} from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetCounterpartiesResponse } from '@api/features/counterparties/getCounterparties';
import type { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import AddButton from '@client/components/AddButton';
import {
	getCounterparties,
	submitHireInvoice,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import Table from '@client/components/Table/Table';
import {
	onDeleteHireInvoice,
	onUnsubmitHireInvoice,
} from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceTables/helpers/invoiceFunctions';
import { getFreightInvoiceColumns } from '@client/screens/fleet/VoyageDetailsScreen/helpers/tableColumns';
import Card from '@client/components/Card/Card';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import FreightInvoiceForm from './FreightInvoiceForm';

type Props = {
	details: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
	refreshDetails: () => void;
	voyageInvoices: VoyageInvoice[];
	hireInvoicesLoading: boolean;
	loading: boolean;
}

const FreightInvoicesTable = ({
	details,
	fixtureDetails,
	refreshDetails,
	voyageInvoices,
	hireInvoicesLoading,
	loading,
}: Props) => {
	const [charterers, _reloadCharterers, _error, _loading] = useFetchedState(
		() => getCounterparties(FixtureCounterpartyTypes.CHARTEREROWNER),
	);

	const [freightInvoiceFormOpen, setFreightInvoiceFormOpen] = useState(false);
	const [selectedCharterer, setSelectedCharterer] = useState<
		GetCounterpartiesResponse[number] | null>(null);

	const expandedRowKeys = useMemo(() => {
		return voyageInvoices.reduce<Array<number>>((acc, h) => {
			if (h.state === AccountingItemApprovalStates.REJECTED) {
				acc.push(h.id);
			}

			return acc;
		}, []);
	}, [voyageInvoices]);

	const freightInvoiceColumns = getFreightInvoiceColumns({
		voyageDetails: details,
		charterers,
		voyageInvoices: voyageInvoices ?? [],
		onDelete: async (id) => {
			await onDeleteHireInvoice(id);
			await refreshDetails();
		},
		onSubmit: async (id) => {
			if (voyageInvoices != null) {
				const requiresApproval = voyageInvoices[0]?.requiresApproval;
				const successMsg = requiresApproval ? 'Submitted for approval' : 'Invoice approved';
				await submitHireInvoice(id);
				showSuccessNotification(successMsg);
				await refreshDetails();
			}
		},
		onUndo: async (id) => {
			await onUnsubmitHireInvoice(id);
			refreshDetails();
		},
	});

	const cargoCharterers = charterers?.filter((c) => (
		fixtureDetails.cargos?.find((cargo) => cargo.charterer === c.id) != null
	));

	return (
		<Card
			slim
			title="Invoices"
			extra={(
				<Dropdown
					trigger={['click']}
					overlay={(
						<Menu>
							{cargoCharterers != null && cargoCharterers.map((charterer) => (
								<Menu.Item
									key={charterer.name}
									onClick={() => {
										setSelectedCharterer(charterer);
										setFreightInvoiceFormOpen(true);
									}}
								>
									{charterer.name}
								</Menu.Item>
							))}
						</Menu>
					)}
				>
					<AddButton
						data-tour="createHireInvoice"
						loading={hireInvoicesLoading}
					>
						New Invoice
					</AddButton>
				</Dropdown>
			)}
		>
			<Table
				data-tour="freightInvoicesTable"
				size="middle"
				rowKey="id"
				// @ts-ignore
				columns={freightInvoiceColumns}
				dataSource={voyageInvoices}
				loading={loading}
				expandable={{
					expandedRowRender: (row) => (row.stateNote != null && (
						<div>
							<strong>Reason:</strong>
							<p>{row.stateNote}</p>
						</div>
					)),
					rowExpandable: (row) => row.stateNote != null,
					defaultExpandedRowKeys: expandedRowKeys,
				}}
			/>
			<FreightInvoiceForm
				open={freightInvoiceFormOpen}
				voyageDetails={details}
				fixtureDetails={fixtureDetails}
				onClose={() => setFreightInvoiceFormOpen(false)}
				onInvoiceCreated={refreshDetails}
				selectedCharterer={selectedCharterer}
				voyageInvoices={voyageInvoices}
			/>
		</Card>
	);
};

export default FreightInvoicesTable;
