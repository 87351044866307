import React from 'react';
import {
	Alert,
	Card,
} from 'antd';
import { CustomFieldParentTypes } from '@shared/utils/constants';
import { getUserData } from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import CustomFields from '../banking/CustomFields';

const Invoicing = () => {
	const [userData] = useFetchedState(getUserData);

	return (
		<>
			<Alert
				message="Custom fields will appear on the actual invoice"
				// eslint-disable-next-line react/forbid-component-props
				style={{ marginBottom: 10 }}
			/>
			<Card
				bordered
			>
				{userData?.organizationId != null && (
					<CustomFields
						parentId={userData.organizationId}
						parentType={CustomFieldParentTypes.ORG}
						showValueField={false}
					/>
				)}

			</Card>
		</>
	);
};

export default Invoicing;
