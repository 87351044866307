import React from 'react';

type Props = {
	url: string;
	title: string;
}

const LoomVideo = ({
	url,
	title,
}: Props) => {
	return (
		<div
			style={{
				position: 'relative',
				paddingBottom: '56.25%',
				height: 0,
				width: 600,
				backgroundSize: 'cover',
			}}
		>
			<iframe
				title={title}
				src={url}
				frameBorder="0"
				allowFullScreen
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				}}
			/>
		</div>
	);
};

export default LoomVideo;
