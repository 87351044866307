import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from './showErrorNotification';

const asyncWrapper = <T extends (...args: any[]) => any>(
	fn: T,
	errorMessage: string,
	successMessage?: string,
) => async (...args: Parameters<T>) => {
	try {
		await fn(...args);
		if (successMessage != null) {
			showSuccessNotification(successMessage);
		}
	} catch (e) {
		console.error(errorMessage, e);
		showErrorNotification(errorMessage, e as Error);
	}
};

export default asyncWrapper;
