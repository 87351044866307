import React from 'react';
import styles from './styles/SettingEntry.module.css';

const SettingEntry = ({ label, children }) => (
	<div className={styles.setting}>
		<b>{label}</b>
		<div>{children}</div>
	</div>
);

export default SettingEntry;
