// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hRHrAKOOMpjM8YJn9sgA {\n\tpadding-left: 0;\n\tpadding-right: 0;\n}\n\n.DkubLrnKtvqXLDZKdaPB .ant-card-body {\n\tpadding: 0;\n}\n\n.n7NSF04kvkBO6tTvmdro > .ant-card-body {\n\tbackground: #f0f2f5 !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/screens/styles/ListTableScreen.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".header {\n\tpadding-left: 0;\n\tpadding-right: 0;\n}\n\n.tableCard :global(.ant-card-body) {\n\tpadding: 0;\n}\n\n.grey > :global(.ant-card-body) {\n\tbackground: #f0f2f5 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "hRHrAKOOMpjM8YJn9sgA",
	"tableCard": "DkubLrnKtvqXLDZKdaPB",
	"grey": "n7NSF04kvkBO6tTvmdro"
};
export default ___CSS_LOADER_EXPORT___;
