// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iAuclvv2jf6Z9Vc2Rk1_ {\n\theight: 500px;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/dashboards/OffHireDashboard.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,WAAW;CACX,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".emptyWrapper {\n\theight: 500px;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyWrapper": "iAuclvv2jf6Z9Vc2Rk1_"
};
export default ___CSS_LOADER_EXPORT___;
