import {
	useLayoutEffect,
	useMemo,
	useRef,
} from 'react';
import styles from './useFixedRows.module.css';

let currentTableId = 1;

const getTableId = () => {
	const toReturn = currentTableId;

	currentTableId += 1;

	return toReturn;
};

const useFixedRows = (fixedRows, customFixedRowEventListeners) => {
	const tableRef = useRef();

	const tableClassName = useMemo(() => `cv-table-${getTableId()}`, []);

	useLayoutEffect(() => {
		tableRef.current = document.querySelector(`.${tableClassName}`);
	}, [tableClassName]);

	useLayoutEffect(() => {
		if (fixedRows.length === 0) {
			return undefined;
		}

		const ghostTable = document.createElement('table');
		const ghostBody = document.createElement('tbody');

		ghostTable.classList.add(styles.ghostTable);
		ghostBody.classList.add('ant-table-tbody');

		ghostTable.appendChild(ghostBody);

		tableRef.current.querySelector('table').parentNode.appendChild(ghostTable);

		const getTableElements = () => {
			const fixedRowNodeList = document.querySelectorAll(`.${tableClassName} tr.${styles.fixed}`);
			const fixedRowElements = Array.from(fixedRowNodeList).reverse();
			const columns = Array.from(fixedRowNodeList.item(0).children);

			return {
				rows: fixedRowElements,
				columns,
			};
		};

		const onResize = () => {
			const { columns } = getTableElements();
			// Set correct widths for table and columns
			ghostTable.style.width = `${tableRef.current.offsetWidth}px`;

			Array.from(ghostBody.children.item(0).children).forEach((e, index) => {
				e.style.width = `${columns[index].clientWidth}px`;
			});
		};

		const onMutation = () => {
			const { rows } = getTableElements();

			ghostBody.innerHTML = '';

			[...rows].reverse().forEach((e) => {
				ghostBody.appendChild(e.cloneNode(true));
			});

			if (customFixedRowEventListeners != null) {
				const { key } = customFixedRowEventListeners;
				const { callback } = customFixedRowEventListeners;
				const element = ghostBody.querySelector(key);

				if (element != null) {
					element.addEventListener('click', callback);
				}
			}

			onResize();
		};

		onMutation();

		const { rows } = getTableElements();

		const bottomRow = rows[rows.length - 1];

		const onChangeVisibility = ([{ isIntersecting }]) => {
			const { scrollTop } = document.documentElement;
			const hasScrolledPast = scrollTop >= bottomRow.offsetTop;

			// isIntersecting is true if bottom row is totally visible on screen = hide ghost!
			if (isIntersecting) {
				ghostTable.classList.add(styles.ghostHidden);
			} else if (!hasScrolledPast) {
				// Only show again if we haven't scrolled past the table
				ghostTable.classList.remove(styles.ghostHidden);
			}
		};

		const resizeObserver = new ResizeObserver(onResize);
		const intersectionObserver = new IntersectionObserver(onChangeVisibility, {
			threshold: [0.8],
		});

		const toObserve = tableRef.current;

		resizeObserver.observe(toObserve);

		if (bottomRow != null) {
			intersectionObserver.observe(bottomRow);
		}

		return () => {
			resizeObserver.disconnect();
			intersectionObserver.disconnect();

			ghostTable.remove();
		};
	}, [tableClassName, fixedRows, customFixedRowEventListeners]);

	return {
		tableClassName,
		fixedRowClassName: styles.fixed,
	};
};

export default useFixedRows;
