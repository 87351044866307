import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table/interface';
import { Currencies } from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import TooltipIcon from '@client/components/TooltipIcon';
import getHireInvoiceFromHII from '@client/utils/getHireInvoiceFromHII';
import { RowType } from '@client/screens/fleet/VoyageDetailsScreen/components/CumulativeComparison';
import { VcRowType } from '../components/VcStatementOfAccountsTab';
import renderItemAmount from './renderItemAmount';
import styles from './styles/getCumulativeComparisonColumns.module.css';

const getFixtureCurrency = (voyageDetails: GetVoyageDetailsResponse) => (
	voyageDetails == null ? Currencies.USD : voyageDetails.bankAccount.currency
);

type Props = {
	voyageDetails: GetVoyageDetailsResponse;
	voyageInvoices: VoyageInvoice[];
};

export const getVcSoaColumns = ({
	voyageDetails,
	fixtureDetails,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
}): ColumnsType<VcRowType> => {
	const fixtureCurrency = getFixtureCurrency(voyageDetails);
	const charterers = fixtureDetails.cargos.map((c) => ({
		id: c.charterer,
		name: c.FixtureCounterparty.name,
	}));

	const uniqueCharterers = charterers.filter((c, index) => (
		charterers.findIndex((cc) => cc.id === c.id) === index
	));

	return [
		{
			title: 'Invoice',
			dataIndex: 'invoiceIdentifier',
			render: (invoiceIdentifier: string, row: VcRowType) => {
				if ((row.isTotal && !row?.isExpandablePayments) || row.item.isChild()) {
					return '';
				}

				return invoiceIdentifier;
			},
			sorter: (a: VcRowType, b: VcRowType) => (a.isTotal || b.isTotal ?
				0 :
				a.invoiceIdentifier.localeCompare(b.invoiceIdentifier)),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			render: (type: string, row: VcRowType) => {
				if (!row.isTotal) {
					return type;
				}

				return {
					children: (<strong>{type}</strong>),
					props: {
						colSpan: 2,
					},
				};
			},
		},
		{
			title: 'Description',
			className: styles.description,
			dataIndex: 'description',
			shouldCellUpdate: (row: VcRowType, prevRow: VcRowType) => (
				row.description !== prevRow.description
			),
			render: (description: string, row: VcRowType) => {
				if (row.isTotal) {
					return {
						children: '',
						props: {
							colSpan: 0,
						},
					};
				}

				return description;
			},
		},
		{
			title: 'Charterer',
			dataIndex: 'chartererId',
			render: (chartererId: number) => {
				const charterer = uniqueCharterers.find((c) => c.id === chartererId);

				return charterer?.name ?? '';
			},
		},
		{
			title: 'Invoiced',
			align: 'right',
			dataIndex: 'ours',
			render: (value: number, row: VcRowType) => (
				renderItemAmount(value, row.isTotal, false, fixtureCurrency)
			),
		},
	];
};

export const getCumulativeComparisonColumns = ({
	voyageDetails,
	voyageInvoices,
}: Props): ColumnsType<RowType> => {
	const fixtureCurrency = getFixtureCurrency(voyageDetails);

	return [
		{
			title: 'Invoice',
			dataIndex: 'invoiceIdentifier',
			render: (invoiceIdentifier: string, row: RowType) => {
				if ((row.isTotal && !row?.isExpandablePayments) || row.item.isChild()) {
					return '';
				}

				if (row.isGrouped) {
					return (
						<>
							<i>Grouped</i>
							<TooltipIcon Icon={InfoCircleOutlined}>
								{(row.groupedItems || []).map((item, index) => {
									const invoice = getHireInvoiceFromHII(item, voyageInvoices);

									return (
										<>
											{index !== 0 && (<br />)}
											{invoice?.invoiceIdentifier}
										</>
									);
								})}
							</TooltipIcon>
						</>
					);
				}

				return invoiceIdentifier;
			},
			sorter: (a: RowType, b: RowType) => (a.isTotal || b.isTotal ?
				0 :
				a.invoiceIdentifier.localeCompare(b.invoiceIdentifier, undefined, { numeric: true })),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			render: (type: string, row: RowType) => {
				if (!row.isTotal) {
					return type;
				}

				return {
					children: (<strong>{type}</strong>),
					props: {
						colSpan: 2,
					},
				};
			},
		},
		{
			title: 'Description',
			className: styles.description,
			dataIndex: 'description',
			shouldCellUpdate: (row: RowType, prevRow: RowType) => row.description !== prevRow.description,
			render: (description: string, row: RowType) => {
				if (row.isTotal) {
					return {
						children: '',
						props: {
							colSpan: 0,
						},
					};
				}

				return description;
			},
		},
		{
			title: 'Invoiced',
			align: 'right',
			dataIndex: 'ours',
			shouldCellUpdate: (row: RowType, prevRow: RowType) => row.ours !== prevRow.ours,
			render: (value: number, row: RowType) => (
				renderItemAmount(value, row.isTotal, false, fixtureCurrency)
			),
		},
	];
};
