import React from 'react';
import {
	Alert,
	Checkbox,
} from 'antd';
import TooltipIcon from './TooltipIcon';

const CreditNoteCheckbox = ({
	createCreditNote,
	setCreateCreditNote,
	showCumulativeAlert = true,
	description,
}: {
	createCreditNote: boolean;
	setCreateCreditNote: (value: boolean) => void;
	showCumulativeAlert?: boolean;
	description?: string;
}) => {
	return (
		<>
			<Checkbox
				checked={createCreditNote}
				onChange={(e) => setCreateCreditNote(e.target.checked)}
			>
				{description ?? 'Generate credit note'}
				{' '}
				<TooltipIcon width={450}>
					Generate a credit note and re-raise the invoice(s) with a new invoice
					number?
					<br />
					<br />
					Otherwise, the invoice will be re-generated with the same invoice
					number.
				</TooltipIcon>
			</Checkbox>
			{showCumulativeAlert && (
				<Alert
					message="Any cumulative statements need to be generated again to reflect these changes"
					type="warning"
				/>
			)}
		</>
	);
};

export default CreditNoteCheckbox;
