import React from 'react';
import { Moment } from 'moment';
import { Tag } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import {
	CargoUnitLabels,
	DATE_AND_TIME,
	EstimateStatus,
	FreightRateType,
} from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import type { Port } from '@api/utils/ports';
import type { GetCargosResponse } from '@api/features/cargos/getCargos';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import { formatDate } from '@client/utils/formatDate';
import { getFilterProps } from '@client/utils/table';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';

type Row = GetCargosResponse[number];

const getConfirmTitleText = (status: EstimateStatus | null, fixtureId: number | null) => {
	if (status === EstimateStatus.Available) {
		return null;
	}

	if (status === EstimateStatus.Working) {
		return 'This cargo is being worked in another estimate. Are you sure you want to add it to this estimate?';
	}

	if (status === EstimateStatus.Subjects) {
		return 'This cargo is already on subjects. Are you sure you want to add it to this estimate?';
	}

	if (status === EstimateStatus.Fixed) {
		const fixtureText = fixtureId == null ? null : (
			<Button href={`${Links.Voyage.get(fixtureId)}/#/recap`} type="link">Go to existing contract</Button>
		);

		return (
			<p>
				This cargo is already on a fixed contract. Are you sure you want
				to add it to this estimate? You will not be able to turn this estimate into
				a fixed contract until you unfix the existing contract
				{fixtureText}
			</p>
		);
	}

	return null;
};

const getStatusColor = (status: EstimateStatus) => {
	if (status === EstimateStatus.Available) {
		return 'green';
	}

	if (status === EstimateStatus.Working) {
		return 'blue';
	}

	if (status === EstimateStatus.Subjects) {
		return 'orange';
	}

	return 'red';
};

const getImportCargoColumns = ({
	onImportCargo,
	cargosOnEstimate,
	chartererOptions,
}: {
	onImportCargo: (id: number) => void;
	cargosOnEstimate: GetEstimateDetailsResponse['cargos'];
	chartererOptions: {chartererName: string | null}[];
}) => {
	return [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Created/Modified',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sorter: standardSort('updatedAt'),
			defaultSortOrder: 'descend' as SortOrder,
			render: (date: Moment) => (date != null ? formatDate(date, DATE_AND_TIME) : 'N/A'),
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			key: 'quantity',
			sorter: standardSort('quantity'),
			render: (quantity: number, row: Row) => (quantity != null ? `${quantity} ${CargoUnitLabels[row.unit]}` : 'N/A'),
		},
		{
			title: 'Freight',
			dataIndex: 'freightType',
			key: 'freightType',
			render: (freightType: Values<typeof FreightRateType>, row: Row) => {
				if (freightType == null || row.freightRate == null || row.currency == null) {
					return 'N/A';
				}

				return freightType === FreightRateType.LUMPSUM ?
					formatCurrency(row.freightRate, row.currency) :
					`${formatCurrency(row.freightRate, row.currency)} /${row.unit}`;
			},
		},
		{
			title: 'Laydays',
			dataIndex: 'firstLaycan',
			key: 'firstLaycan',
			sorter: standardSort('firstLaycan'),
			render: (date: Moment | null) => (date != null ? formatDate(date, DATE_AND_TIME) : 'Not specified'),
		},
		{
			title: 'Loading',
			dataIndex: 'loadingPorts',
			key: 'loadingPorts',
			render: (loadingPorts: Port[]) => loadingPorts.map((p) => p.name).join(', '),
		},
		{
			title: 'Discharging',
			dataIndex: 'dischargePorts',
			key: 'dischargePorts',
			render: (dischargePorts: Port[]) => dischargePorts.map((p) => p.name).join(', '),
		},
		{
			title: 'Charterer',
			dataIndex: 'chartererName',
			key: 'chartererName',
			render: (chartererName: string) => chartererName ?? 'N/A',
			...getFilterProps(chartererOptions, 'select', 'chartererName', 'desc'),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status: EstimateStatus) => (status == null ? ' - ' : (
				<Tag color={getStatusColor(status)}>
					{EstimateStatus[status]}
				</Tag>
			)),
		},
		{
			title: '',
			dataIndex: '',
			key: 'actions',
			render: (_a: any, row: Row) => (
				<Button
					confirmTitle={getConfirmTitleText(row.status, row.fixtureId)}
					disabled={cargosOnEstimate.some((c) => c.id === row.id)}
					disabledTooltip="Cargo is already added to this estimate"
					onClick={() => onImportCargo(row.id)}
					type="primary"
				>
					Add
				</Button>
			),
		},
	];
};

export default getImportCargoColumns;
