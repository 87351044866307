import React from 'react';
import {
	Spin,
	Statistic,
	Tag,
} from 'antd';
import DashboardWidgetWrapper, {
	DashboardWidgetProps,
	FetchDataParams,
} from '@client/screens/general/HomeScreen/components/DashboardWidgetWrapper';
import { ChartColors } from '@client/utils/constants';
import styles from './styles/NumberWidget.module.css';

const colors = [
	ChartColors.BLUE,
	ChartColors.CYAN,
];

const getBadgeColor = (text: string) => {
	// Get the sum of the string ascii codes
	const sum = text.split('').reduce((s, char) => s + char.charCodeAt(0), 0);

	return colors[sum % colors.length];
};

type NumberWidgetProps<Data, Params extends FetchDataParams> = DashboardWidgetProps<{
	transformData?: (data: Data) => string;
	badge?: string;
	allowDecimals?: boolean;
}, 'renderWidget' | 'extra', Data, Params>;

const NumberWidget = <Data, Params extends FetchDataParams>({
	transformData,
	badge,
	allowDecimals = false,
	...props
}: NumberWidgetProps<Data, Params>) => {
	const getValue = (data: Data | undefined) => {
		if (data === undefined) {
			return null;
		}

		if (transformData != null) {
			return transformData(data);
		}

		if (typeof data === 'number' && !allowDecimals) {
			return Math.round(data);
		}

		return data as React.ReactElement;
	};

	return (
		<DashboardWidgetWrapper
			showPeriodLabel
			{...props}
			extra={badge != null && (
				<Tag
					color={getBadgeColor(badge)}
					className={styles.badge}
				>
					{badge}
				</Tag>
			)}
			renderWidget={({ data, loading }) => (
				<Statistic
					valueRender={() => {
						if (loading) {
							return (<Spin />);
						}

						return getValue(data);
					}}
				/>
			)}
		/>
	);
};

export default NumberWidget;
