import React, {
	useState,
	useMemo,
	useEffect,
} from 'react';
import {
	Drawer,
	Form,
	InputNumber,
} from 'antd';
import { toMoment } from '@shared/utils/date';
import { Values } from '@shared/utils/objectEnums';
import {
	Currencies,
	HireUnit,
} from '@shared/utils/constants';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import Button from '@client/components/Button';
import CurrencyInput from '@client/components/CurrencyInput';
import Select from '@client/components/Select';
import {
	createRateScheduleItem,
	updateRateScheduleItem,
} from '@client/lib/api';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import DatePicker from '@client/components/DatePicker';
import styles from './styles/RateScheduleForm.module.css';

const RateScheduleForm = ({
	setRateScheduleDrawerOpen,
	rateScheduleDrawerOpen,
	fixture,
	fixtureCurrency,
	refreshFixture,
}: {
	setRateScheduleDrawerOpen: (open: boolean) => void;
	rateScheduleDrawerOpen: number | boolean;
	fixture: GetFixtureDetailsResponse<TcFixtureProps | SpotFixtureProps> | undefined;
	fixtureCurrency: Values<typeof Currencies>;
	refreshFixture: () => void;
}) => {
	const [useExactDate, setUseExactDate] = useState(false);
	const [selectedDuration, setSelectedDuration] = useState<number | null>(null);
	const [selectedUnit, setSelectedUnit] = useState<'months' | 'days' | 'years' | null>('months');
	const [form] = Form.useForm();

	const calculatedFromDate = useMemo(() => {
		if (fixture != null) {
			if (rateScheduleDrawerOpen != null && typeof rateScheduleDrawerOpen === 'number') {
				const result = fixture.rateSchedule.find((item) => item.id === rateScheduleDrawerOpen);

				if (result != null) {
					return result.from;
				}
			}

			return fixture.rateSchedule[fixture.rateSchedule.length - 1]?.to;
		}

		return null;
	}, [fixture, rateScheduleDrawerOpen]);

	const calculatedToDate = useMemo(() => {
		if (
			!useExactDate &&
			calculatedFromDate != null &&
			selectedDuration != null &&
			selectedUnit != null
		) {
			return toMoment(
				calculatedFromDate,
			).add(selectedDuration, selectedUnit);
		}

		return null;
	}, [useExactDate, calculatedFromDate, selectedDuration, selectedUnit]);

	const editingRateScheduleItem = useMemo(() => {
		if (rateScheduleDrawerOpen != null && typeof rateScheduleDrawerOpen === 'number' && fixture != null) {
			const result = fixture.rateSchedule.find((item) => item.id === rateScheduleDrawerOpen);

			if (result != null) {
				setUseExactDate(result.useExactDate);
				setSelectedDuration(result.duration);
				setSelectedUnit(result.unit);
				form.setFieldsValue(result);
			}

			return result;
		}

		return null;
	}, [fixture, form, rateScheduleDrawerOpen]);

	useEffect(() => {
		if (!rateScheduleDrawerOpen || rateScheduleDrawerOpen == null) {
			form.resetFields();
		}
	}, [form, rateScheduleDrawerOpen]);

	const onReset = async () => {
		setSelectedDuration(null);
		setRateScheduleDrawerOpen(false);
		setUseExactDate(false);
	};

	const handleAddRateScheduleItem = async () => {
		const {
			rate,
			duration,
			unit,
			to,
		} = form.getFieldsValue();

		if (fixture != null) {
			try {
				await createRateScheduleItem({
					fixtureId: fixture.id,
					rate,
					duration,
					unit,
					exactDate: to,
					useExactDate,
				});

				await refreshFixture();
				onReset();
				showSuccessNotification('Succesfully updated rate schedule');
			} catch (e) {
				showErrorNotification('Could not update rate schedule', e as Error);
			}
		}
	};

	const handleEditRateScheduleItem = async () => {
		if (fixture != null) {
			const editing = fixture.rateSchedule.find((item) => item.id === rateScheduleDrawerOpen);

			if (editing != null) {
				const values = form.getFieldsValue();

				if (values.to != null) {
					values.exactDate = values.to;
				}

				try {
					await updateRateScheduleItem(fixture.id, editing.id, values);
					await refreshFixture();
					onReset();
					showSuccessNotification('Succesfully updated rate schedule');
				} catch (e) {
					showErrorNotification('Could not update rate schedule', e as Error);
				}
			}
		}
	};

	return (
		<Drawer
			title={typeof rateScheduleDrawerOpen === 'number' ? 'Edit rate schedule' : 'Add entry to rate schedule'}
			placement="left"
			width={400}
			onClose={onReset}
			// @ts-ignore idc this works and is legal
			open={rateScheduleDrawerOpen}
		>
			{fixture?.rateSchedule == null ? 'NA' : (
				<Form
					initialValues={editingRateScheduleItem ?? {
						to: calculatedFromDate,
						useExactDate: false,
						unit: 'months',
					}}
					onValuesChange={(values) => {
						if (values.useExactDate != null) {
							setUseExactDate(values.useExactDate);
						}

						if (values.unit) {
							setSelectedUnit(values.unit);
						}

						if (values.duration) {
							setSelectedDuration(values.duration);
						}
					}}
					layout="vertical"
					form={form}
				>
					<Form.Item
						name="useExactDate"
						label="How would you like specify duration"
					>
						<Select
							placeholder="Select duration type"
							options={[
								{ label: 'Exact date', value: true },
								{ label: 'Duration', value: false },
							]}
						/>

					</Form.Item>
					<Form.Item label="From date">
						<DatePicker
							value={calculatedFromDate}
							disabled
							time
						/>
					</Form.Item>
					{useExactDate ? (
						<>
							<Form.Item name="to" label="To date">
								<DatePicker minDate={calculatedFromDate ?? undefined} time />
							</Form.Item>
							<Form.Item name="rate" label="Hire rate">
								<CurrencyInput
									className={styles.fullWidth}
									placeholder="Value"
									currency={fixtureCurrency}
								/>
							</Form.Item>
						</>
					) : (
						<>
							<Form.Item name="duration" label="Duration">
								<InputNumber
									className={styles.fullWidth}
									placeholder="Value"
								/>
							</Form.Item>
							<Form.Item name="unit" label="Unit">
								<Select options={[
									{ label: 'Years', value: 'years' },
									{ label: 'Months', value: 'months' },
									{ label: 'Days', value: 'days' },
								]}
								/>
							</Form.Item>
							<Form.Item name="rate" label="Hire rate">
								<CurrencyInput
									className={styles.fullWidth}
									placeholder="Value"
									currency={fixtureCurrency}
									addonAfter={
										fixture && 'hireUnit' in fixture ?
											fixture.hireUnit === HireUnit.MONTHS ?
												'/ month' :
												'/ day' :
											''
									}
								/>
							</Form.Item>
							<Form.Item label="Calculated to date">
								<DatePicker
									value={calculatedToDate}
									disabled
								/>
							</Form.Item>

						</>
					)}
					<Button
						onClick={
							typeof rateScheduleDrawerOpen === 'number' ?
								handleEditRateScheduleItem :
								handleAddRateScheduleItem
						}
						type="primary"
					>
						Submit
					</Button>
				</Form>
			)}
		</Drawer>
	);
};

export default RateScheduleForm;
