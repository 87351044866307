import React, {
	useMemo,
	useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faFlagCheckered } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Space } from 'antd';
import { Link } from 'react-router-dom';
import { CompassOutlined } from '@ant-design/icons';
import { nowMoment } from '@shared/utils/date';
import { FixtureTypes } from '@shared/utils/constants';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import {
	markFixtureAsCompleted,
	resumeFixture,
} from '@client/lib/api';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import styles from './styles/HeaderAction.module.css';

const HeaderAction = ({
	details,
	fixtureDetails,
	refreshDetails,
	disabled = false,
}: {
	details: GetVoyageDetailsResponse | undefined;
	fixtureDetails?: GetFixtureDetailsResponse<
	SpotFixtureProps |
	TcFixtureProps |
	BbFixtureProps
	> | null;
	refreshDetails: () => void;
	disabled?: boolean;
}) => {
	const [actionLoading, setActionLoading] = useState<boolean>(false);

	const estimateId = useMemo(() => {
		if (details != null) {
			if (details?.fixture.type === FixtureTypes.SPOT && fixtureDetails != null) {
				const spotFixture = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

				return {
					estimateId: spotFixture.estimateId,
					estimateGroupId: spotFixture.estimateGroupId,
				};
			}

			return {
				estimateId: details.fixture.id,
			};
		}

		return null;
	}, [details, fixtureDetails]);

	const markComplete = async () => {
		setActionLoading(true);

		try {
			await markFixtureAsCompleted(details?.id);

			await refreshDetails();
			showSuccessNotification('Contract completed', 'The contract has successfully been completed');
		} catch (e) {
			showErrorNotification('Could not complete contract', e as Error);
		}

		setActionLoading(false);
	};

	const resumeVoyage = async () => {
		setActionLoading(true);

		try {
			await resumeFixture(details?.id);

			await refreshDetails();
			showSuccessNotification('Contract resumed', 'The contract has been resumed');
		} catch (e) {
			showErrorNotification('Could not resume contract', e as Error);
		}

		setActionLoading(false);
	};

	let action;

	if (details?.id == null) {
		action = null;
	} else if (details.fixture.completed) {
		action = (
			<Button
				onClick={resumeVoyage}
				className={styles.approveButton}
				type="primary"
				loading={actionLoading}
				icon={(<FontAwesomeIcon className={styles.icon} icon={faPlay as IconProp} />)}
			>
				Resume contract
			</Button>
		);
	} else {
		let confirmTitle = (<>Mark contract as complete? This can be undone at any point</>);

		if (details.stats.outstanding > 0) {
			confirmTitle = (
				<>
					There is still an outstanding amount.
					<br />
					Really mark contract as complete?
					<br />
					This can be undone at any point.
				</>
			);
		} else if (!nowMoment().isAfter(details.completionDate)) {
			confirmTitle = (
				<>
					Contract completion date is in the future.
					<br />
					Really mark contract as complete?
					<br />
					This can be undone at any point.
				</>
			);
		}

		let link;

		if (fixtureDetails?.type === FixtureTypes.SPOT) {
			link = Links.Fixtures[details.fixture.type].Details
				.get(estimateId?.estimateGroupId!, estimateId?.estimateId!);
		} else {
			link = Links.Fixtures[details.fixture.type].Details
				// @ts-ignore
				.get(estimateId?.estimateId!);
		}

		action = (
			<Space>
				{estimateId != null && (
					<Link key="voyage-link" to={link}>
						<Button
							className={styles.goToEstimate}
							type="link"
							icon={(<CompassOutlined />)}
						>
							Go to estimate
						</Button>
					</Link>
				)}
				<Button
					loading={actionLoading}
					onClick={markComplete}
					disabled={disabled}
					confirmTitle={confirmTitle}
					icon={(<FontAwesomeIcon icon={faFlagCheckered as IconProp} className={styles.icon} />)}
				>
					<b>Complete Contract</b>
				</Button>
			</Space>
		);
	}

	return action;
};

export default HeaderAction;
