import React, {
	useMemo,
	useState,
} from 'react';
import { Moment } from 'moment';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import sortBy from 'lodash.sortby';
import { capitalize } from '@shared/utils/string';
import {
	AccrualItemStates,
	AccrualItemTypes,
	DATE_AND_TIME,
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { toMoment } from '@shared/utils/date';
import { formatCurrency } from '@shared/utils/currency';
import type { VesselProps } from '@api/models/vessel';
import type { TransformedAccrualItem } from '@api/features/financials/getAccrualItems';
import type { VoyageProps } from '@api/models/voyage';
import type { FixtureCounterpartyProps } from '@api/models/fixture-counterparty';
import type { BrokerProps } from '@api/models/broker';
import type { SupplierProps } from '@api/models/supplier';
import { formatDate } from '@client/utils/formatDate';
import Table from '@client/components/Table/Table';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';
import DateFilterDropDown from '@client/components/DateFilterDropDown/DateFilterDropDown';
import MultiCurrencyAmount from '@client/components/MultiCurrencyAmount';
import showErrorNotification from '@client/utils/showErrorNotification';
import { updateAccrualItem } from '@client/lib/api';
import TooltipIcon from '@client/components/TooltipIcon';
import AsyncCheckbox from '@client/components/Async/AsyncCheckbox';

type Props = {
	accrualItems: Array<TransformedAccrualItem>;
	loading?: boolean;
	refresh?: () => void;
	inModal?: boolean;
	columnsToHide?: Array<keyof TransformedAccrualItem>;
}

const AccrualsTable = ({
	accrualItems,
	loading = false,
	refresh,
	inModal = false,
	columnsToHide,
}: Props) => {
	const [postedDateRange, setPostedDateRange] = useState<[Moment, Moment] | null>(null);
	const [postedDropDownVisible, setPostedDropDownVisible] = useState(false);

	const filterMaps = useMemo(() => {
		const vessels = new Set<string>();
		const contracts = new Set<string>();
		const itemTypes = new Set<string>();

		accrualItems?.forEach((item) => {
			if (item.vessel?.name != null) {
				vessels.add(item.vessel.name);
			}

			contracts.add(item.voyage.identifier);
			itemTypes.add(item.item);
		});

		return {
			vessels: Array.from(vessels),
			contracts: Array.from(contracts),
			itemTypes: Array.from(itemTypes),
			status: [AccrualItemStates.OPEN, AccrualItemStates.CLOSED],
		};
	}, [accrualItems]);

	const columns: ColumnsType<TransformedAccrualItem> = useMemo(() => {
		const col: ColumnsType<TransformedAccrualItem> = [
			{
				title: 'ID',
				key: 'id',
				dataIndex: 'identifier',
				defaultSortOrder: 'descend',
			},
			{
				title: 'Type',
				key: 'type',
				dataIndex: 'type',
				render: (c) => capitalize(c),
			},
			{
				title: 'Item',
				key: 'item',
				dataIndex: 'item',
				filters: filterMaps.itemTypes.map((itemType) => ({
					text: itemType,
					value: itemType,
				})),
				filterMultiple: true,
				onFilter: (value: any, record: TransformedAccrualItem) => record.item === value,
			},
			{
				title: 'Origin',
				key: 'origin',
				dataIndex: 'origin',
			},
			{
				title: 'Vessel',
				dataIndex: 'vessel',
				render: (vessel: VesselProps | undefined) => (
					vessel != null ?
						(<LinkButton url={Links.Vessel.get(vessel.id)}>{vessel.name}</LinkButton>) :
						''),
				filters: sortBy(filterMaps.vessels).map((vessel) => ({
					text: vessel,
					value: vessel,
				})),
				filterMultiple: true,
				key: 'vessel',
				onFilter: (value: any, record: TransformedAccrualItem) => record.vessel?.name === value,
			},
			{
				title: 'Contract',
				dataIndex: 'voyage',
				render: (voyage: VoyageProps | undefined) => (
					voyage != null ?
						(<LinkButton url={Links.Voyage.get(voyage.id)}>{voyage.identifier}</LinkButton>) :
						''),
				filters: sortBy(filterMaps.contracts).map((voyage) => ({
					text: voyage,
					value: voyage,
				})),
				filterMultiple: true,
				key: 'voyage',
				onFilter: (
					value: any,
					record: TransformedAccrualItem,
				) => record.voyage?.identifier === value,
			},
			{
				title: 'Contract Type',
				key: 'fixtureType',
				dataIndex: 'fixtureType',
				render: (c: Values<typeof FixtureTypes>) => FixtureTypeLabels[c],
			},
			{
				title: 'Counterparty',
				key: 'counterparty',
				dataIndex: 'counterparty',
				render: (c: FixtureCounterpartyProps | BrokerProps | SupplierProps) => c?.name,
			},
			{
				title: 'Date',
				dataIndex: 'date',
				key: 'date',
				render: (c: Moment) => formatDate(toMoment(c).subtract(1, 'second'), DATE_AND_TIME),
			},
			{
				title: 'Posted',
				dataIndex: 'posted',
				key: 'posted',
				filters: [],
				filtered: postedDateRange != null,
				filteredValue: postedDateRange != null && postedDateRange?.length > 0 ?
					postedDateRange as any :
					[],
				filterDropdownVisible: postedDropDownVisible,
				onFilter: (
					value: any,
					record: TransformedAccrualItem,
				) => {
					if (postedDateRange != null) {
						return (
							record.posted.isSameOrAfter(postedDateRange[0]) &&
							record.posted.isSameOrBefore(postedDateRange[1])
						);
					}

					return true;
				},
				onFilterDropdownVisibleChange: setPostedDropDownVisible,
				filterDropdown: (
					<DateFilterDropDown
						filterValue={postedDateRange}
						setFilterValue={setPostedDateRange}
						setFilterVisible={setPostedDropDownVisible}
					/>
				),
				render: (c: Moment) => formatDate(c),
			},
			{
				title: (
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<p style={{ marginBottom: 0, marginRight: 5 }}>Prorated</p>
						<TooltipIcon>
							Decides whether or not the item is prorated across the voyage duration when closed.
							Only applies to non-time related items, such as expenses, bunker purchases etc.
						</TooltipIcon>
					</div>
				),
				key: 'shouldProrate',
				dataIndex: 'shouldProrate',
				width: 80,
				align: 'center',
				render: (c: boolean, row: TransformedAccrualItem) => (
					!row.isTimeRelated && (
						<AsyncCheckbox
							checked={c}
							disabled={row.periodClosed || row.type === AccrualItemTypes.REVERSAL}
							onChange={async (e) => {
								try {
									if (row.id == null) {
										return;
									}

									await updateAccrualItem(row.id, {
										shouldProrate: e.target.checked,
									});

									if (typeof refresh === 'function') {
										refresh();
									}
								} catch (error) {
									showErrorNotification('Could not update accrual item', error as Error);

									throw error;
								}
							}}
						/>
					)
				),
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				key: 'amount',
				render: (c: number, record: TransformedAccrualItem) => {
					if (record.exchangeRate == null) {
						return formatCurrency(c, record.currency, { forceDecimals: true });
					}

					return (
						<MultiCurrencyAmount
							direction="vertical"
							amount={c}
							fixtureCurrency={record.fixtureCurrency}
							inputCurrency={record.currency}
							exchangeRate={record.exchangeRate}
						/>
					);
				},
				align: 'right',
			},
			...(!inModal ? [{
				title: 'Status',
				dataIndex: 'status',
				filters: filterMaps.status.map((state) => ({
					text: capitalize(state),
					value: state,
				})),
				filterMultiple: false,
				key: 'status',
				onFilter: (
					value: any,
					record: TransformedAccrualItem,
				) => record.status === value,
				render: (c: AccrualItemStates) => (
					<Tag color={c === AccrualItemStates.CLOSED ? 'red' : 'blue'}>
						{capitalize(c)}
					</Tag>
				),
			}] : []),
		];

		return col.filter((c) => !columnsToHide?.includes(c.key as keyof TransformedAccrualItem));
	}, [refresh, columnsToHide, filterMaps, inModal, postedDateRange, postedDropDownVisible]);

	return (
		<Table<TransformedAccrualItem>
			columns={columns}
			pagination={false}
			dataSource={sortBy(accrualItems, 'identifier')}
			loading={loading}
		/>
	);
};

export default AccrualsTable;
