import React, { useMemo } from 'react';
import {
	Layer,
	Source,
} from 'react-map-gl';

const PointLayer = ({
	id,
	latitude,
	longitude,
	name,
	layerStyle,
}) => {
	const data = useMemo(() => ({
		type: 'Feature',
		geometry: {
			type: 'Point',
			coordinates: [longitude, latitude],
		},
		properties: {
			name,
		},
	}), [latitude, longitude, name]);

	return (
		<Source
			id={`${id}-source`}
			type="geojson"
			data={data}
		>
			<Layer
				id={id}
				type="circle"
				{...layerStyle}
			/>
		</Source>
	);
};

export default PointLayer;
