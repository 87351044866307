// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nyIzld9P_q1YrjGZT5_g {\n\twidth: 600px;\n\tmargin-bottom: 24px;\n}\n\n.nyIzld9P_q1YrjGZT5_g .ant-card-body {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tpadding: 16px;\n}\n\n.y_16NZAjzPJdsKl1tGZm {\n\tmargin: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VesselDetailsScreen/tabs/styles/VesselCrewReportsTab.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,aAAa;AACd;;AAEA;CACC,SAAS;AACV","sourcesContent":[".settingsCard {\n\twidth: 600px;\n\tmargin-bottom: 24px;\n}\n\n.settingsCard :global(.ant-card-body) {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tpadding: 16px;\n}\n\n.alertText {\n\tmargin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsCard": "nyIzld9P_q1YrjGZT5_g",
	"alertText": "y_16NZAjzPJdsKl1tGZm"
};
export default ___CSS_LOADER_EXPORT___;
