import { SetStateAction } from 'react';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import asyncWrapper from '@client/utils/asyncWrapper';
import { deleteHireInvoice } from '@client/lib/untyped-api';
import {
	submitHireInvoice,
	unsubmitHireInvoice,
} from '@client/lib/api';

export const onDeleteHireInvoice = asyncWrapper(async (id) => {
	await deleteHireInvoice(id);
}, 'Could not delete hire invoice');

export const onSubmitHireInvoice = (
	id: number,
	successMsg: string,
) => {
	asyncWrapper(async () => submitHireInvoice(id),
		'Could not submit hire invoice',
		successMsg)();
};

export const onUnsubmitHireInvoice = asyncWrapper(async (id) => {
	await unsubmitHireInvoice(id);
}, 'Could not un-submit hire invoice', 'Submission of invoice undone');

export const updateHIIAttribute = (
	item: HireInvoiceItem,
	attribute: string,
	newValue: any,
	setItems: React.Dispatch<SetStateAction<Array<HireInvoiceItem>>>,
) => {
	const newItem = item.copy();
	newItem[attribute] = newValue;

	setItems((oldItems) => {
		const newItems = [...oldItems];
		const itemIndex = newItems.indexOf(item);

		newItems[itemIndex] = newItem;

		return newItems;
	});
};
