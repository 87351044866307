import React, { useMemo } from 'react';
import {
	Card,
	Col,
	List,
	Row,
	Space,
	Typography,
} from 'antd';
import {
	DATE_AND_TIME,
	FixtureTypes,
	PortActionTypeLabels,
} from '@shared/utils/constants';
import { sortByDates } from '@shared/utils/sortByDates';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { EstimatePortRotationEntryProps } from '@api/models/estimate-port-rotation-entry';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import { formatDate } from '@client/utils/formatDate';
import getSpotFixtureItems from '@client/screens/fleet/VoyageDetailsScreen/helpers/getSpotFixtureItems';
import styles from '@client/screens/fleet/VoyageDetailsScreen/VoyageDetailsScreen.module.css';
import CargoCard from '@client/components/CargoCard';
import Details from '@client/components/Details';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getEstimateDetails } from '@client/lib/api';
import CountryFlag from '@client/components/CountryFlag';
// eslint-disable-next-line no-restricted-syntax
import getFixtureItems from '../../helpers/getFixtureItems';

const Recap = ({
	voyageDetails,
	fixtureDetails,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<
		TcFixtureProps |
		SpotFixtureProps |
		BbFixtureProps
	>;
}) => {
	const [
		estimateDetails] = useFetchedState(
		async () => {
			const { estimateId } = fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>;

			if (estimateId != null) {
				const newEstimateDetails = await getEstimateDetails(estimateId);

				return newEstimateDetails;
			}

			return null;
		},
		[fixtureDetails],
	);

	const spot = fixtureDetails?.type === FixtureTypes.SPOT;

	const fixtureItems = voyageDetails?.id ?
		(spot ? getSpotFixtureItems({
			voyageDetails,
			fixtureDetails: fixtureDetails as GetFixtureDetailsResponse<SpotFixtureProps>,
		}) : getFixtureItems({ voyageDetails, fixtureDetails })) : [];

	const cargos = estimateDetails?.cargos;

	const portRotation = useMemo(() => {
		if (estimateDetails?.portRotationEntries != null) {
			return sortByDates(estimateDetails.portRotationEntries, 'departureDate');
		}

		return [];
	}, [estimateDetails]);

	if (spot) {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col span={12}>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<Card className={styles.spotRecap}>
										<Details
											title="Recap"
											items={fixtureItems}
											labelWidth={300}
										/>
									</Card>
								</div>
							</Col>
							<Col span={24}>
								<Card title="Port Rotation">
									<List
										bordered
										dataSource={portRotation}
										header={(
											<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
												<b>Port</b>
												<b>Departure Date</b>
											</div>
										)}
										renderItem={(item: EstimatePortRotationEntryProps) => (
											<List.Item>
												<Typography.Text>
													<Space>
														<CountryFlag countryCode={item?.port?.countryCode} />
														{item.type != null && (
															<>
																<b>
																	{PortActionTypeLabels[item.type]}
																</b>
																in
															</>
														)}
														{item?.port?.name}
													</Space>
												</Typography.Text>
												<div>
													{formatDate(item.departureDate, DATE_AND_TIME)}
												</div>
											</List.Item>
										)}
									/>
								</Card>
							</Col>
						</Row>
					</Col>
					<Col span={12}>
						<Card
							className={styles.spotRecap}
							title="Cargos"
						>
							{cargos?.map((c) => (
								<Row gutter={[0, 12]}>
									<Col span={24}>
										<CargoCard
											fixtureId={fixtureDetails.id}
											cargo={c}
										/>
									</Col>
								</Row>
							))}
						</Card>
					</Col>
				</Row>
			</>
		);
	}

	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Card className={styles.recap}>
						<Details
							title="Recap"
							items={fixtureItems}
							labelWidth={300}
						/>
					</Card>
				</div>
			</Col>
		</Row>
	);
};

export default Recap;
