import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../navbar/NavbarMenu.module.css';

const buildMenuItemComponents = (menuItems) => (
	menuItems
		.filter((i) => !i.hidden)
		.map((item) => {
			delete item.admin;
			delete item.management;

			const {
				children,
				icon,
				text,
				link,
				...rest
			} = item;

			if (children) {
				return {
					key: text,
					label: (
						<>
							{icon && (
								<FontAwesomeIcon
									icon={icon}
									className={styles.menuIcon}
								/>
							)}
							{text}
						</>
					),
					children: buildMenuItemComponents(children),
					...rest,
				};
			}

			return {
				key: link,
				label: (
					<Link to={link}>
						{icon && (
							<FontAwesomeIcon
								icon={icon}
								className={styles.menuIcon}
							/>
						)}
						{text}
					</Link>
				),
				...rest,
			};
		})
);

export default buildMenuItemComponents;
