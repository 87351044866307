import {
	HIIFieldTypes,
	CalculationMethodForExpensesSubjectToHireDays,
	PnlGroups,
	ExpensesSubjectToHireDaysIntervals,
	ExpensesSubjectToHireDaysIntervalLabels,
	InvoiceTemplates,
} from '@shared/utils/constants';
import getHireChildPnlAmountCalculator from '@shared/utils/pnlAmountCalculators/getHireChildPnlAmountCalculator';
import { Values } from '@shared/utils/objectEnums';
import HIIHirePeriod from '@shared/hireInvoice/HIIHirePeriod';
import TemplateItem from '../TemplateItem';
import { formatCurrency } from '../utils/currency';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

export type HIIExpenseSubjectToHireDaysType = HIIExpenseSubjectToHireDays;
type Intervals = Values<typeof ExpensesSubjectToHireDaysIntervals>;

class HIIExpenseSubjectToHireDays extends HireInvoiceItem {
	static get itemType() {
		return 'expenseSubjectToHireDays';
	}

	name: string;
	amount: number;
	interval: Intervals;
	formulaType: Values<typeof CalculationMethodForExpensesSubjectToHireDays>;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			name: string;
			amount: number;
			interval: Intervals;
			formulaType: Values<typeof CalculationMethodForExpensesSubjectToHireDays>;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.name = params.name;
		this.amount = params.amount;
		this.interval = params.interval ?? ExpensesSubjectToHireDaysIntervals.pcm;
		this.formulaType = params.formulaType;
		this._showTotal = true;
		this.itemTypeLabel = params.name;
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.EXPENSES_CONTRACTUAL;

		this.pnlGroup = PnlGroups.EXPENSES_SUBJECT_TO_HIRE_DAYS;
		this.pnlAmountCalculator = getHireChildPnlAmountCalculator(this);

		this._fields = {
			amount: {
				label: 'Amount',
				type: HIIFieldTypes.CURRENCY,
			},
			interval: {
				label: 'Interval',
				type: HIIFieldTypes.SELECT,
				options: [
					{
						label: ExpensesSubjectToHireDaysIntervalLabels[ExpensesSubjectToHireDaysIntervals.pcm],
						value: ExpensesSubjectToHireDaysIntervals.pcm,
					},
					{
						label: ExpensesSubjectToHireDaysIntervalLabels[ExpensesSubjectToHireDaysIntervals.perDay],
						value: ExpensesSubjectToHireDaysIntervals.perDay,
					},
				],
			},
			formulaType: {
				label: 'Formula',
				type: HIIFieldTypes.SELECT,
				options: [
					{
						label: 'Annual',
						value: CalculationMethodForExpensesSubjectToHireDays.ANNUALLY,
					},
					{
						label: 'Monthly',
						value: CalculationMethodForExpensesSubjectToHireDays.MONTHLY,
					},
				],
			},
		};
	}

	calculateAmountAnnual(cveAmount: number, days: number, interval: Intervals) {
		if (interval === ExpensesSubjectToHireDaysIntervals.pcm) {
			return cveAmount * 12 * (days / 365);
		}

		return cveAmount * days;
	}

	calculateAmountAnnualThreeSixty(cveAmount: number, days: number, interval: Intervals) {
		if (interval === ExpensesSubjectToHireDaysIntervals.pcm) {
			return cveAmount * 12 * (days / 360);
		}

		return cveAmount * days;
	}

	calculateAmount(days: number) {
		switch (this.formulaType) {
			case CalculationMethodForExpensesSubjectToHireDays.FIXED:
				return this.amount;
			case CalculationMethodForExpensesSubjectToHireDays.ANNUALLY:
				return this.calculateAmountAnnual(this.amount, days, this.interval);

			case CalculationMethodForExpensesSubjectToHireDays.MONTHLY:
				return this.calculateAmountAnnualThreeSixty(this.amount, days, this.interval);

			default:
				return this.calculateAmountAnnual(this.amount, days, this.interval);
		}
	}

	_calculateTotal(invoiceItems: HireInvoiceItem[]) {
		const hirePeriod = this.getParent(invoiceItems) as HIIHirePeriod;

		if (hirePeriod == null) {
			return 0;
		}

		return this.calculateAmount(hirePeriod.getDays());
	}

	getDescription() {
		if (this.formulaType === CalculationMethodForExpensesSubjectToHireDays.FIXED) {
			return formatCurrency(this.amount, this.fixtureCurrency);
		}

		return `
			${formatCurrency(this.amount, this.fixtureCurrency)} 
			${ExpensesSubjectToHireDaysIntervalLabels[this.interval]}
		`;
	}

	static _getClassParams(model: any, _parentModel: any) {
		return {
			name: model.name,
			amount: model.amount,
			interval: model.interval,
			formulaType: model.formulaType,
			fixtureCurrency: model.fixtureCurrency,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);

		model.name = this.name;
		model.amount = this.amount;
		model.interval = this.interval;
		model.formulaType = this.formulaType;

		await model.save();
	}

	_getTemplateItemParams(invoiceItems: HireInvoiceItem[]) {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.name,
					this.getDescription(),
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					` — ${this.name} (${this.getDescription()})`,
					'1',
					'',
					`${formatCurrency(this.getTotal(invoiceItems), this.fixtureCurrency, { hideSymbol: true })}`,
				],
			},
		};
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return (
			(this.name === other.name) &&
			(this.amount === other.amount) &&
			(this.formulaType === other.formulaType)
		);
	}
}

HireInvoiceItem.addItemClass(HIIExpenseSubjectToHireDays);

export default HIIExpenseSubjectToHireDays;
