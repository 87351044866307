import React from 'react';
import {
	Card,
	Space,
	Tag,
} from 'antd';
import {
	toMoment,
	nowMoment,
} from '@shared/utils/date';
import { AttachmentTypes } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import AttachmentLibrary from '@client/components/AttachmentLibrary';
import EditableText from '@client/components/EditableText';
import { updateAttachment } from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';

export const VesselDocumentsTab = ({
	vessel,
	refreshVessel,
	vesselId,
}) => {
	const updateAttachmentExpirationDate = async (attachmentId, expirationDate) => {
		try {
			await updateAttachment(attachmentId, {
				expirationDate: formatDate(expirationDate) || null,
			});
			await refreshVessel();
		} catch (e) {
			showErrorNotification('Could not update expiration date', e);
		}
	};

	return (
		<Card>
			<AttachmentLibrary
				attachments={vessel.attachments}
				refreshAttachments={refreshVessel}
				type={AttachmentTypes.VESSEL}
				attachToId={vesselId}
				renderExtra={(attachment) => {
					let color = null;

					const expirationDate = attachment.expirationDate == null ?
						null :
						toMoment(attachment.expirationDate);

					if (expirationDate == null) {
						color = null;
					} else if (expirationDate.isBefore(nowMoment().startOf('day'))) {
						color = 'red';
					} else if (expirationDate.isBefore(nowMoment().startOf('day').add(1, 'month'))) {
						color = 'orange';
					} else {
						color = 'green';
					}

					return (
						<Tag color={color}>
							<Space>
								<strong>Expires:</strong>
								<EditableText
									defaultValue={(attachment.expirationDate != null ?
										toMoment(attachment.expirationDate) :
										undefined
									)}
									placeholder={(
										<em>Never</em>
									)}
									type="date"
									onChange={(date) => updateAttachmentExpirationDate(attachment.id, date)}
								/>
							</Space>
						</Tag>
					);
				}}
			/>
		</Card>
	);
};
