import React, {
	useEffect,
	useLayoutEffect,
	useState,
	useRef,
} from 'react';
import {
	Popover,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Currencies } from '@shared/utils/constants';
import isDev from '@shared/utils/isDev';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import NumericInput, { NumericInputProps } from '@client/components/NumericInput';
import { getExchangeRates } from '@client/lib/api';
import { useGlobalState } from '@client/lib/globalState';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import styles from './styles/MultiCurrencyInput.module.css';
import Select from './Select';

export type MultiCurrencyValueObject = {
	currency: Values<typeof Currencies> | undefined;
	value: number | undefined;
	exchangeRate: number | undefined;
}

export type MultiCurrencyInputProps = ExtendUnique<{
	baseCurrency: Values<typeof Currencies>;
	onChange?: (newValue: MultiCurrencyValueObject) => void;
	inputClassName?: string;
	inputValues?: MultiCurrencyValueObject;
	formValues?: {} | undefined;
	disabled?: boolean;
}, NumericInputProps>

const MultiCurrencyInput = ({
	allowNegative = false,
	separateThousands = true,
	baseCurrency,
	onChange,
	inputClassName = undefined,
	inputValues,
	disabled = false,
	value: formValues,
	...props
}: MultiCurrencyInputProps) => {
	const [exchangeRates, setExchangeRates] = useGlobalState(`exchange-rate-${baseCurrency}`);
	const [stackElements, setStackElements] = useState(false);

	const inputFieldRef = useRef<HTMLInputElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const refreshExchangeRates = async () => {
			const rates = await getExchangeRates(baseCurrency);
			setExchangeRates(rates);
		};

		if (exchangeRates == null) {
			refreshExchangeRates();
		}
	}, [baseCurrency, exchangeRates, setExchangeRates]);

	if (isDev() && baseCurrency == null) {
		throw new Error('A base currency must be specified');
	}

	// We sometimes use MultiCurrencyInputs in Ant Design Forms, where values are passed to the
	// value prop - sometimes we DO NOT want to use the form value,
	// but instead set the values manually
	// If we've passed inputValues but formValues also exist,
	// we use inputValues, otherwise we use formValues
	const valuesToUse = inputValues != null ? inputValues : formValues;

	const valueObj: Partial<MultiCurrencyValueObject> = {
		currency: baseCurrency,
		...(typeof valuesToUse === 'object' ? valuesToUse : {}),
	};

	const { value, currency } = valueObj;

	const exchangeRate = valueObj.exchangeRate ?? 1;

	const changeValue = (newValue: Partial<MultiCurrencyValueObject>) => {
		if (newValue.currency != null && newValue.currency !== valueObj.currency) {
			newValue.exchangeRate = exchangeRates?.[newValue.currency];
		}

		if (onChange != null) {
			onChange({
				currency: newValue?.currency ?? valueObj.currency,
				value: newValue?.value ?? valueObj.value,
				exchangeRate: newValue?.exchangeRate ?? exchangeRate,
			});
		}
	};

	useEffect(() => {
		if (exchangeRates == null || exchangeRate != null || currency == null) {
			return;
		}

		if (currency === baseCurrency) {
			changeValue({ exchangeRate: 1 });

			return;
		}

		changeValue({ exchangeRate: exchangeRates[currency] });

	// Only run this when base currency changes
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseCurrency, exchangeRates]);

	const currencyInput = (
		<div
			className={styles.multiCurrencySelector}
		>
			<Select
				key={currency}
				disabled={disabled}
				onSelect={(v) => changeValue({ currency: v as Values<typeof Currencies> })}
				defaultValue={currency ?? baseCurrency}
				value={currency ?? baseCurrency}
				showSearch
				options={Object.values(Currencies).map((c) => ({
					label: c,
					value: c,
				}))}
			/>
		</div>
	);

	const exchangeRatePopover = (
		<Popover
			className={classNames({ [styles.stackExchangeRate]: stackElements })}
			content={(
				<>
					Exchange rate
					<br />
					<NumericInput
						onChange={(v) => changeValue({ exchangeRate: v ?? undefined })}
						value={exchangeRate}
						disabled={disabled}
						placeholder="Exchange Rate"
						addonAfter={`${currency ?? baseCurrency} = 1 ${baseCurrency}`}
					/>
				</>
			)}
			title="Override Exchange Rate"
			trigger="click"
		>
			<div>
				<FontAwesomeIcon
					icon={faExchange as IconProp}
					size="1x"
				/>
			</div>
		</Popover>
	);

	useLayoutEffect(() => {
		const container = containerRef.current;

		const setIsStacked = () => {
			if (inputFieldRef?.current?.clientWidth) {
				setStackElements(inputFieldRef?.current?.clientWidth < 250);
			}
		};

		const observer = new ResizeObserver(setIsStacked);

		if (container != null) {
			observer.observe(container);
		}

		setIsStacked();

		return () => {
			if (container != null) {
				observer.unobserve(container);
			}
		};
	}, [inputFieldRef, containerRef]);

	const numericInput = (
		<NumericInput
			addonBefore={!stackElements && currencyInput}
			addonAfter={!stackElements && exchangeRatePopover}
			className={classNames(inputClassName, styles.clickableAddon)}
			separateThousands={separateThousands}
			disabled={disabled}
			allowNegative={allowNegative}
			onChange={(v) => changeValue({ value: v ?? undefined })}
			defaultValue={value}
			value={value}
			{...props}
		/>
	);

	const convertedCurrency = (
		baseCurrency !== currency && value != null && value !== 0 && (
			<Typography.Text
				// @ts-ignore
				italic
				type="secondary"
				className={stackElements ? styles.stackedConvertedAmount : styles.convertedAmount}
			>
				{`≈ ${formatCurrency(value / exchangeRate, baseCurrency)}`}
			</Typography.Text>
		)
	);

	return (
		<div ref={containerRef}>
			<div
				className={stackElements ?
					styles.multiCurrencyInputWrapperStacked :
					styles.multiCurrencyInputWrapper}
				ref={inputFieldRef}
			>
				{numericInput}
				{stackElements ? (
					<div className={styles.currencyActionsContainer}>
						{currencyInput}
						{exchangeRatePopover}
					</div>
				) : (<br />)}
			</div>
			{convertedCurrency}
		</div>
	);
};

export default MultiCurrencyInput;
