import React, { useState } from 'react';
import {
	Col,
	Divider,
	Empty,
	Row,
	Space,
	Timeline,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLink,
	faPlus,
} from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import {
	AccountingItemApprovalStates,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import { sortByDates } from '@shared/utils/sortByDates';
import type { TransformedHireInvoices } from '@api/utils/getTransformedHireInvoices';
import type { AttachmentProps } from '@api/models/attachment';
import type { TransformedBrokerInvoices } from '@api/utils/getTransformedBrokerInvoices';
import { formatDate } from '@client/utils/formatDate';
import Button from '@client/components/Button';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import AttachmentViewer from '@client/components/AttachmentViewer';
import { getActionDetails } from '@client/components/ExpenseDetailsCard/private/helpers/getActionDetails';
import getSelectedInvoiceItems from '@client/components/InvoiceDetailsCard/helpers/getSelectedInvoiceItems';
import getSelectedBrokerInvoiceItems from '@client/components/InvoiceDetailsCard/helpers/getSelectedBrokerInvoiceItems';
import styles from '../ExpenseDetailsCard/ExpenseDetailsCard.module.css';

type Props = {
	invoice: TransformedHireInvoices[number] | TransformedBrokerInvoices[number];
}

const InvoiceDetailsCard = ({
	invoice,
}: Props) => {
	const [selectedAttachment, setSelectedAttachment] = useState<AttachmentProps | null>(null);

	const getTimelineItems = (
		invoiceWithActions: TransformedHireInvoices[number] | TransformedBrokerInvoices[number],
	) => {
		// @ts-ignore
		return sortByDates(invoiceWithActions.actions, 'createdAt', { newestFirst: true })
			.map((action) => {
				const {
					icon,
					iconColor,
					label,
				} = getActionDetails(action.action);

				return (
					<Timeline.Item
						key={action.id}
						className={styles.timelineItem}
						label={formatDate(action.createdAt, DATE_AND_TIME)}
						dot={icon}
						color={iconColor}
					>
						{label}
						<br />
						<div className={styles.actionCreatedBy}>
							{`by ${action.User.name}`}
							{
								action.description != null && (
									<>
										<br />
										{action.action === AccountingItemApprovalStates.REJECTED ?
											'reason: ' : 'note: ' }
										{action.description}
									</>
								)
							}
						</div>
					</Timeline.Item>
				);
			});
	};

	return (
		<>
			<Typography.Title level={5}>Attachments</Typography.Title>
			<Space className={styles.attachments} direction="vertical">
				{invoice.attachments.length === 0 && (
					<em>No attachments</em>
				)}
				{invoice.attachments.map((attachment) => (
					<div className={styles.attachmentButton} key={attachment.id}>
						<Button
							className={styles.noPaddingButton}
							onClick={() => setSelectedAttachment(attachment)}
							icon={(
								<FontAwesomeIcon
									icon={faLink as Icon}
									className={styles.icon}
								/>
							)}
							type="link"
						>
							{attachment.name}
						</Button>
					</div>
				))}
			</Space>
			<Row gutter={[16, 24]}>
				<Col xxl={12} xs={24}>
					<EditableDetails
						title={`Invoice to ${invoice.itemType === 'invoice' ? invoice.charterer : invoice.brokerName}`}
						onSave={() => undefined}
						editButtonProps={{
							disabled: true,
						}}
						// @ts-ignore
						items={invoice.itemType === 'invoice' ?
							getSelectedInvoiceItems(invoice) :
							getSelectedBrokerInvoiceItems(invoice)}
					/>
				</Col>
				<Divider />
				<Col span={24}>
					<Typography.Title level={4}>
						Timeline
					</Typography.Title>
				</Col>
				<Col span={24}>
					{invoice.actions == null || invoice.actions.length === 0 ?
						(<Empty description="No events" />) : (
							<Timeline mode="left">
								{getTimelineItems(invoice)}
								<Timeline.Item
									label={formatDate(invoice.createdAt, DATE_AND_TIME)}
									className={styles.timelineItem}
									dot={(<FontAwesomeIcon icon={faPlus as Icon} />)}
									color="green"
								>
									Invoice created
									<br />
									<div className={styles.actionCreatedBy}>
										{`by ${invoice?.createdByUserName}`}
									</div>
								</Timeline.Item>
							</Timeline>
						)}
				</Col>
			</Row>
			<AttachmentViewer
				attachment={selectedAttachment}
				modal
				onCloseModal={() => setSelectedAttachment(null)}
			/>
		</>
	);
};

export default InvoiceDetailsCard;
