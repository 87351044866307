import React, { useCallback } from 'react';
import { notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { standardSort } from '@shared/utils/standardSort';
import {
	createUserGroup,
	getUserGroups,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';

const columns = [
	{
		title: 'Id',
		dataIndex: 'id',
		key: 'id',
		width: 40,
		align: 'center',
		sorter: standardSort('id'),
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		sorter: standardSort('name'),
	},
	{
		title: 'Number of Team Members',
		dataIndex: 'numberOfUsers',
		key: 'numberOfUsers',
		sorter: standardSort('numberOfUsers'),
	},
	{
		title: 'Default group',
		dataIndex: 'default',
		key: 'default',
		render: (isDefault) => (isDefault && (
			<CheckCircleOutlined />
		)),
	},
];

const UsersListScreen = () => {
	const [groups, reloadGroups, error, loading] = useFetchedState(() => getUserGroups());

	const createNewGroup = useCallback(async ({ name }) => {
		await createUserGroup(name);

		reloadGroups();

		notification.success({
			message: 'Group created',
			description: `The group "${name}" has been created and added to the list.`,
		});
	}, [reloadGroups]);

	return (
		<ListTableScreen
			title="Groups"
			rootPageTitle="Groups"
			columns={columns}
			loading={loading}
			error={error}
			data={groups}
			formFields={[
				{
					label: 'Name',
					name: 'name',
					type: 'text',
					required: true,
				},
			]}
			formButtonText="Create group"
			onFormSubmit={createNewGroup}
			getRowHref={(row) => Links.Group.get(row.id)}
			redirectOnSubmit={false}
		/>
	);
};

export default UsersListScreen;
