import { HIIFieldTypes } from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import HireInvoiceItem from './HireInvoiceItem';

class HireInvoiceDifference {
	constructor(
		originalHireInvoiceItem,
		changedHireInvoiceItem,
		dismissed = false,
		id = null,
	) {
		this.original = originalHireInvoiceItem;
		this.changed = changedHireInvoiceItem;
		this.dismissed = dismissed;
		this.id = id;
	}

	toJSON() {
		return {
			original: this.original.toJSON(),
			changed: this.changed.toJSON(),
			dismissed: this.dismissed,
			id: this.id,
		};
	}

	isFieldChanged(fieldName) {
		const fields = this.original.getFields();

		const originalValue = this.original[fieldName];
		const changedValue = this.changed[fieldName];

		switch (fields[fieldName].type) {
			case HIIFieldTypes.DATE:
				return !toMoment(originalValue).isSame(toMoment(changedValue));

			default:
				return originalValue !== changedValue;
		}
	}

	getChanges(includeUnchanged = false) {
		const fields = this.original.getFields();

		const changes = Object.entries(fields).map(([fieldName, field]) => ({
			fieldName,
			field,
			ourValue: this.original[fieldName],
			theirValue: this.changed[fieldName],
			isChanged: this.isFieldChanged(fieldName),
		}));

		if (includeUnchanged) {
			return changes;
		}

		return changes.filter((change) => change.isChanged);
	}

	static fromJSON(json) {
		const obj = typeof json === 'string' ? JSON.parse(json) : json;

		return new HireInvoiceDifference(
			HireInvoiceItem.fromJSON(obj.original),
			HireInvoiceItem.fromJSON(obj.changed),
			obj.dismissed,
			obj.id,
		);
	}

	static async fromModel(model, fixtureCurrency) {
		const ourModel = await model.getOwnerItem();
		const theirModel = await model.getChartererItem();

		return new HireInvoiceDifference(
			await HireInvoiceItem.fromModel(ourModel, fixtureCurrency),
			await HireInvoiceItem.fromModel(theirModel, fixtureCurrency),
			model.dismissed,
			model.id,
		);
	}

	static itemsHaveDifferences(a, b, extraIgnoreFields = []) {
		const jsonA = a.toJSON();
		const jsonB = b.toJSON();

		const ignoreFields = [
			'id',
			'isOriginal',
			'children',
			'isPrevious',
			...extraIgnoreFields,
		];

		const deleteFields = (obj) => ignoreFields.forEach((f) => {
			delete obj[f];
		});

		deleteFields(jsonA);
		deleteFields(jsonB);

		return JSON.stringify(jsonA) !== JSON.stringify(jsonB);
	}

	static getChangedInvoiceItem(item, differences) {
		const diff = differences.find((d) => (d.original.id === item.id));

		if (diff != null) {
			// Set the item id and children to the same as the original item
			const newItem = diff.changed.copy();

			newItem.children = item.children.map((i) => (
				HireInvoiceDifference.getChangedInvoiceItem(i, differences)
			));

			newItem.id = item.id;

			return newItem;
		}

		return item;
	}
}

export default HireInvoiceDifference;
