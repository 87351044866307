import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import {
	Card,
	Row,
	Col,
	Typography,
	notification,
	Input,
	Space,
	Image,
	Empty,
	Upload,
	Checkbox,
} from 'antd';
import {
	MailOutlined,
	EyeOutlined,
	DeleteOutlined,
	ReloadOutlined,
} from '@ant-design/icons';
import {
	useParams,
	useHistory,
} from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table/interface';
import debounce from 'lodash.debounce';
import sortBy from 'lodash.sortby';
import { DATE } from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import { asyncForEach } from '@shared/utils/array';
import { standardSort } from '@shared/utils/standardSort';
import type { UserProps } from '@api/models/user';
import type { VesselProps } from '@api/models/vessel';
import type { UpdateOrganizationRequest } from '@api/features/admin/updateOrganization';
import { formatDate } from '@client/utils/formatDate';
import Table from '@client/components/Table/Table';
import {
	getOrganizationDetails,
	updateOrganization,
	deleteOrganization as apiDeleteOrganization,
	sendAdminInvitation,
	updateOrganizationLogo,
	updateReportEmail,
	sendWelcomeEmail,
	updateUser,
} from '@client/lib/api';
import FormPopover from '@client/components/FormPopover';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import showErrorNotification from '@client/utils/showErrorNotification';
import { useImpersonation } from '@client/lib/impersonation';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import Button from '@client/components/Button';
import { Links } from '@client/utils/links';
import { Field } from '@client/components/SimpleForm';
import styles from './OrganizationDetailsScreen.module.css';
import getItems from './helpers/getItems';
import InviteUsersDrawer from './components/InviteUsersDrawer';

type FileType = {
	lastModified: number;
	name: string;
	size: number;
	type: string;
	uid: string;
	webkitRelativePath: string;
}

const getUserColumns = (
	onSendWelcomeEmail: (userId: number) => void,
	setShouldReceiveEmails: (value: boolean, userId: number) => void,
): ColumnsType<UserProps> => [
	{
		title: 'Id',
		dataIndex: 'id',
		key: 'id',
		width: 40,
		align: 'center',
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Created',
		dataIndex: 'createdAt',
		key: 'createdAt',
		render: (createdAt) => formatDate(toMoment(createdAt), DATE),
	},
	{
		title: 'Welcome email',
		dataIndex: 'hasReceivedWelcomeEmail',
		key: 'hasReceivedWelcomeEmail',
		render: (hasReceivedWelcomeEmail, row) => (
			<Button
				icon={hasReceivedWelcomeEmail ? (<ReloadOutlined />) : (<MailOutlined />)}
				onClick={() => onSendWelcomeEmail(row.id)}
				type={hasReceivedWelcomeEmail ? 'default' : 'primary'}
				disabled={!row.shouldReceiveEmails}
				disabledTooltip={'Enable "Should receive emails" to send a welcome email'}
			>
				{hasReceivedWelcomeEmail ? 'Re-send' : 'Send welcome email'}
			</Button>
		),
	},
	{
		title: 'Should receive emails?',
		dataIndex: 'shouldReceiveEmails',
		key: 'shouldReceiveEmails',
		render: (shouldReceiveEmails, row) => (
			<Checkbox
				checked={shouldReceiveEmails}
				onChange={(event) => {
					setShouldReceiveEmails(event.target.checked, row.id);
				}}
			/>
		),
	},
];

type AdminInvitationValues = {
	name: string;
	email: string;
	external: boolean;
	isManagement: boolean;
}

const adminInvitationFields: Field<AdminInvitationValues>[] = [
	{
		label: 'First name',
		name: 'name',
		type: 'text',
		required: true,
	},
	{
		label: 'Email',
		name: 'email',
		type: 'text',
		required: true,
	},
	{
		label: 'Non-office 365 user?',
		name: 'external',
		type: 'switch',
		required: true,
	},
	{
		label: 'Is management',
		name: 'isManagement',
		type: 'switch',
		required: true,
	},
];

type ReportEmailInputs = Record<number, string | undefined>;

const debouncedUpdateReportEmail = debounce(updateReportEmail, 200);

const OrganizationDetailsScreen: React.FC = () => {
	const params = useParams<{id: string}>();
	const id = Number(params.id);

	const history = useHistory();
	const { beginImpersonating } = useImpersonation();

	const [inviteUsersOpen, setInviteUsersOpen] = useState(false);
	const [reportEmailInputs, setReportEmailInputs] = useState<ReportEmailInputs>({});

	const vesselColumns: ColumnsType<VesselProps> = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
			width: 40,
			align: 'center',
			sortOrder: 'ascend',
			sorter: standardSort('id'),
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Report Email',
			dataIndex: 'reportEmail',
			key: 'reportEmail',
			render: (reportEmail, vessel) => (
				<Input
					value={reportEmailInputs[vessel.id] ?? reportEmail}
					onChange={(e) => {
						const newValue = e.target.value;

						setReportEmailInputs((prev) => ({
							...prev,
							[vessel.id]: newValue,
						}));
					}}
				/>
			),
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (createdAt) => formatDate(toMoment(createdAt), DATE),
		},
	];

	const [
		organization,
		refreshOrganization,
		error,
		loading,
	] = useFetchedState(
		() => getOrganizationDetails(id),
		[],
		{ showNotification: false },
	);

	const {
		name,
		users,
		vessels,
	} = (organization ?? {
		name: '',
		users: [],
		vessels: [],
	});

	useEffect(() => {
		asyncForEach(Object.entries(reportEmailInputs), async ([vesselId, newEmail]) => {
			await debouncedUpdateReportEmail({
				orgId: id,
				vesselId: Number(vesselId),
				reportEmail: newEmail,
			});
		});
	}, [reportEmailInputs, id]);

	const onSendWelcomeEmail = async (userId: number) => {
		if (organization != null) {
			await sendWelcomeEmail(organization.id, userId);
			await refreshOrganization();
		}
	};

	const setShouldReceiveEmails = async (value: boolean, userId: number) => {
		await updateUser({
			userId,
			attributes: {
				shouldReceiveEmails: value,
			},
		});
		refreshOrganization();
	};

	const userColumns = getUserColumns(onSendWelcomeEmail, setShouldReceiveEmails);

	const saveDetails = useCallback(async (values: Partial<UpdateOrganizationRequest['attributes']>) => {
		await updateOrganization(id, {
			...values,
			...(values.useVesselCompanyOnInvoice != null ? {
				useVesselCompanyOnInvoice: values.useVesselCompanyOnInvoice,
			} : {}),
		});
		await refreshOrganization();

		if (values.featureFlags != null) {
			// Timeout so React has time to re-render and disable nav block
			setTimeout(() => {
				window.location.reload();
			}, 100);
		}
	}, [id, refreshOrganization]);

	const onUploadImage = async (file: FileType) => {
		try {
			await updateOrganizationLogo(id, file);
			await refreshOrganization();
		} catch (e) {
			showErrorNotification('Could not upload image', e as Error);
		}
	};

	const deleteOrganization = useCallback(async () => {
		try {
			await apiDeleteOrganization(id);

			notification.success({
				message: 'Organization was deleted',
				description: `${name} has successfully been deleted.`,
			});

			history.goBack();
		} catch (e) {
			showErrorNotification('Could not delete organization', e as Error);
		}
	}, [id, history, name]);

	const onSubmitInvite = (values: AdminInvitationValues) => {
		try {
			sendAdminInvitation(id, values.name, values.email, values.external, values.isManagement);
			showSuccessNotification(
				'Invitation sent',
				`An invitation was successfully sent to ${values.email}`,
			);
		} catch (e) {
			showErrorNotification('Could send invitation', e as Error);
		}
	};

	const detailItems = organization == null ? [] : getItems(organization);

	return (
		<SimpleScreen
			canGoBack
			error={error}
			breadcrumbs={[['Organizations', Links.Organizations.get()]]}
			rootPageTitle="Organizations"
			title={(
				<Typography.Text
					className={styles.name}
				>
					{name}
				</Typography.Text>
			)}
			loading={name == null}
			headerActions={[
				users.length === 0 ? (
					<FormPopover
						key="invite-admin"
						onSubmit={onSubmitInvite}
						buttonText="Invite Admin"
						title="Send admin invitation email"
						fields={adminInvitationFields}
						data-cy="invite-admin-form"
						disabled={users.length > 0}
						buttonProps={{
							// @ts-ignore
							'data-cy': 'invite-admin-button',
							icon: (<MailOutlined />),
						}}
					/>
				) : (
					<Button
						type="primary"
						data-cy="open-invite-users"
						onClick={() => setInviteUsersOpen(true)}
						icon={(<MailOutlined />)}
					>
						Invite Team Members
					</Button>
				),
				(
					<Button
						confirmTitle={`Are you sure you want to delete ${name}?`}
						onClick={deleteOrganization}
						key="delete"
						type="primary"
						danger
					>
						Delete organization
					</Button>
				),
			]}
			className={undefined}
		>
			<Row gutter={16}>
				<Col span={8}>
					<Card
						title="Details"
					>
						{detailItems != null && (
							<EditableDetails
								data-cy="details"
								items={detailItems}
								onSave={saveDetails}
							/>
						)}
					</Card>
				</Col>
				<Col span={16}>
					<Row gutter={[16, 16]}>
						<Col span={24}>
							<Card
								title={(
									<>
										Team Members
										{' '}
										<Button
											type="link"
											icon={(<EyeOutlined />)}
											onClick={() => beginImpersonating(id)}
										/>
									</>
								)}
							>
								<Table
									data-cy="users"
									columns={userColumns}
									rowKey="id"
									dataSource={sortBy(users, 'id')}
									loading={loading}
								/>
							</Card>
						</Col>
						<Col span={24}>
							<Card title="Vessels">
								<Table
									data-cy="vessels"
									columns={vesselColumns}
									rowKey="id"
									dataSource={vessels}
									loading={loading}
								/>
							</Card>
						</Col>
						<Col span={24}>
							<Card
								bordered
								title="Organization logo"
								extra={(
									<Space>
										<Button
											type="link"
											danger
											icon={(<DeleteOutlined />)}
											onClick={() => saveDetails({ logoObjectKey: null })}
										/>
										<Upload
											beforeUpload={(file) => onUploadImage(file)}
											showUploadList={false}
										>
											<Button type="primary">Upload new</Button>
										</Upload>
									</Space>
								)}
							>
								<Space
									direction="vertical"
									size="middle"
									className={styles.logoWrapper}
								>
									<div className={styles.centerContent}>
										{organization?.logoUrl == null ? (

											<Empty description="No logo provided" />

										) : (
											<Image
												width={200}
												src={organization?.logoUrl}
											/>
										)}
									</div>
								</Space>
							</Card>
						</Col>
					</Row>
				</Col>
			</Row>
			<InviteUsersDrawer
				open={inviteUsersOpen}
				onClose={() => setInviteUsersOpen(false)}
				organizationDetails={organization ?? {}}
			/>
		</SimpleScreen>
	);
};

export default OrganizationDetailsScreen;
