// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FZ34cxKCtCvxPmtRnYHn {\n\twidth: 100%;\n\twhite-space: break-spaces;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/VoyageSelector/VoyageSelector.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,yBAAyB;AAC1B","sourcesContent":[".ownershipBox {\n\twidth: 100%;\n\twhite-space: break-spaces;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ownershipBox": "FZ34cxKCtCvxPmtRnYHn"
};
export default ___CSS_LOADER_EXPORT___;
