import React, {
	useEffect,
	useState,
} from 'react';
import {
	Switch,
	SwitchProps,
} from 'antd';

type Props = Omit<SwitchProps, 'onChange'> & {
	onChange: (e: boolean) => Promise<void> | void;
};

const AsyncSwitch = ({ checked, onChange, ...rest }: Props) => {
	const [localValue, setLocalValue] = useState<boolean>(checked ?? false);
	const [cache, setCache] = useState<boolean | null>(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (error) {
			if (cache != null) {
				setLocalValue(cache);
				setCache(null);
			}

			setError(false);
		}
	}, [cache, error]);

	return (
		<Switch
			checked={localValue}
			onChange={async (event) => {
				if (cache == null) {
					setCache(localValue);
				}

				setLocalValue(event);

				try {
					if (typeof onChange === 'function') {
						await onChange(event);
						setCache(null);
					}
				} catch (e) {
					setError(true);
				}
			}}
			{...rest}
		/>
	);
};

export default AsyncSwitch;
