export const calculateDemurrage = (
	demurrageRate: number | null | undefined,
	timeGainedOrLost: number,
	isHour: boolean,
) => Math.abs(((demurrageRate ?? 0) / (isHour ? 1 : 24) / 60) * timeGainedOrLost);

export const calculateDespatch = (
	despatchRate: number | null | undefined,
	timeGainedOrLost: number,
	isHour: boolean,
) => ((despatchRate ?? 0) / (isHour ? 1 : 24) / 60) * timeGainedOrLost * -1;
