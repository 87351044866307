import timekeeper from 'timekeeper';

const DEFAULT_DATE = new Date('2020-12-30T00:00:00Z');

export const withRealTime = async (fn) => {
	const oldDate = Date.now();
	timekeeper.reset();

	try {
		return await fn();
	} finally {
		timekeeper.travel(oldDate);
	}
};

export const timeTravel = (date = DEFAULT_DATE, freeze = false) => {
	if (process.env.NODE_ENV !== 'test') {
		// eslint-disable-next-line no-console
		console.info(process.env.NODE_ENV, 'Time traveling to date', date);
	}

	if (freeze) {
		timekeeper.freeze(date);
	} else {
		timekeeper.travel(date);
	}
};

export const resetTimeTravel = () => {
	timekeeper.reset();
};
