import React from 'react';
import Select from '@client/components/Select';
import { countries } from '@client/utils/countries';
import CountryFlag from './CountryFlag';
import styles from './styles/FlagSelector.module.css';

const FlagSelector = ({
	countryCode,
	onChange,
	allowClear = true,
	...props
}: {
	countryCode: string;
	onChange: (code: string | null) => void;
	allowClear?: boolean;
}) => (
	<Select
		className={styles.flagSelector}
		onChange={onChange}
		showSearch
		optionFilterProp="searchValue"
		value={countryCode}
		allowClear={allowClear}
		{...props}
		options={Object.keys(countries).map((c) => ({
			label: (<CountryFlag showCountryName countryCode={c} />),
			searchValue: countries[c],
			value: c.toLowerCase(),
		}))}
	/>
);

export default FlagSelector;
