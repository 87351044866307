import React, {
	createContext,
	useContext,
} from 'react';
import type { GetEstimateDetailsResponse } from '@api/features/estimates/getEstimateDetails';
import type { GetVesselDetailsResponse } from '@api/features/vessels/getVesselDetails';
import type { GetCounterpartiesResponse } from '@api/features/counterparties/getCounterparties';
import type { Port } from '@api/utils/ports';
import type { GetVesselsResponse } from '@api/features/vessels/getVessels';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import { useMiscHandlers } from './hooks/useMiscHandlers';
import {
	OnRefreshEuaPriceMutator,
	OnUpdateEstimate,
	useEstimateHandlers,
} from './hooks/useEstimateHandlers';
import { useCentralStore } from './CentralStoreContext';

export type EstimatorContextType = {
	estimate: GetEstimateDetailsResponse | null;
	estimateResult: GetEstimateDetailsResponse | null | undefined;
	vessel?: GetVesselDetailsResponse | null;
	consumptionSets: Array<ConsumptionSetProps>;
	refreshVessel: () => void;
	onUpdateEstimate: OnUpdateEstimate;
	estimateLoading: boolean;
	charterers: GetCounterpartiesResponse | undefined;
	chartererOptions: { chartererName: string | null }[];
	ports: Port[] | undefined;
	portOptions: {
		label: string;
		value: number;
	}[];
	onRefreshEuaPriceMutator: OnRefreshEuaPriceMutator;
	allVessels: GetVesselsResponse | undefined;
	fixtureHasInvoices: boolean | undefined;
}

export const EstimatorContext = createContext<EstimatorContextType>(undefined!);
export const useEstimate = () => useContext(EstimatorContext);

const EstimateProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const {
		estimate: centralEstimate,
		setEstimateChanged,
		selectedEstimateId,
		setSelectedEstimateId,
		setEstimateGroupId,
		syncEstimate,
		setSyncEstimate,
		vessel,
		refreshVessel,
		refreshEverything,
		setLoading,
	} = useCentralStore();

	const {
		estimate,
		estimateLoading,
		onRefreshEuaPriceMutator,
		onUpdateEstimate,
		allVessels,
		fixtureHasInvoices,
	} = useEstimateHandlers({
		selectedEstimateId,
		setSelectedEstimateId,
		setEstimateGroupId,
		syncEstimate,
		setSyncEstimate,
		remoteEstimate: centralEstimate,
		setEstimateChanged,
		refreshEverything,
		setLoading,
	});

	const {
		ports,
		portOptions,
		charterers,
		chartererOptions,
	} = useMiscHandlers();

	return (
		<EstimatorContext.Provider
			value={{
				estimate,
				estimateResult: centralEstimate,
				vessel,
				consumptionSets: vessel?.consumptionSets ?? [],
				refreshVessel,
				onUpdateEstimate,
				estimateLoading,
				charterers,
				chartererOptions,
				ports,
				portOptions,
				onRefreshEuaPriceMutator,
				allVessels,
				fixtureHasInvoices,
			}}
		>
			{children}
		</EstimatorContext.Provider>
	);
};

export default EstimateProvider;
