import React from 'react';
import {
	Tag,
	Typography,
} from 'antd';
import {
	CrewReportTypes,
	DATE_AND_TIME,
	NoonReportThresholds,
} from '@shared/utils/constants';
import { TimeFormat } from '@shared/utils/date';
import { capitalize } from '@shared/utils/string';
import {
	calculateTotal,
	round,
} from '@shared/utils/math';
import {
	ConsumptionStopLightProps,
	getConsumptionStoplight,
	getSpeedStoplight,
	getSteamingHoursStoplight,
	getWeatherStoplight,
	RED_STOPLIGHT_COLOR,
	SpeedStopLightProps,
	SteamingHoursStopLightProps,
	StoplightResult,
	WeatherStopLightProps,
	YELLOW_STOPLIGHT_COLOR,
} from '@shared/utils/performanceStoplights';
import checkThresholds from '@shared/utils/checkThresholds';
import type { NoonAtSeaReportProps } from '@api/models/noon-at-sea-report';
import { formatDate } from '@client/utils/formatDate';
import Details from '@client/components/Details';
import ReportsLayer from '@client/components/Map/layers/ReportsLayer';
import Map from '@client/components/Map/Map';
import Table from '@client/components/Table/Table';
import { coordinatesToDMSString } from '@client/utils/coordinates';
import TooltipIcon from '@client/components/TooltipIcon';
import styles from './styles/NoonReportDrawerContent.module.css';

type Thresholds = {
	min: number;
	max: number;
} | null;

export type NoonReportWithPerformanceFields = NoonAtSeaReportProps & {
	actualConsumption?: number;
	speedMargin?: number;
	minSteamingHours?: number;
	warrantedConsumption?: number;
	consumptionMargin?: number;
	windForceLimit?: number;
}

const formatValueWithIndicators = <Params, >(
	value: number | null,
	unit: string,
	stoplightFunction: ((params: Params) => StoplightResult) | null = null,
	stoplightParams: Params,
	thresholds: Thresholds = null,
) => {
	if (value == null) {
		return '';
	}

	const formattedValue = `${value}${unit}`;

	if (thresholds != null) {
		if (checkThresholds({ thresholds, value })) {
			return (
				<div className={styles.rowBox}>
					<p style={{ color: 'red' }} className={styles.formattedValue}>{formattedValue}</p>
					<TooltipIcon danger>
						Potential error detected
					</TooltipIcon>
				</div>
			);
		}
	}

	if (stoplightFunction == null) {
		return formattedValue;
	}

	const stoplight = stoplightFunction(stoplightParams);

	if (stoplight.color === YELLOW_STOPLIGHT_COLOR) {
		return (
			<>
				{formattedValue}
				{' '}
				<Tag
					color="orange"
					// eslint-disable-next-line react/forbid-component-props
					style={{
						marginLeft: 8,
					}}
				>
					{stoplight.description}
				</Tag>
			</>
		);
	}

	if (stoplight.color === RED_STOPLIGHT_COLOR) {
		return (
			<>
				{formattedValue}
				{' '}
				<Tag
					color="red"
					// eslint-disable-next-line react/forbid-component-props
					style={{
						marginLeft: 8,
					}}
				>
					{stoplight.description}
				</Tag>
			</>
		);
	}

	return formattedValue;
};

const NoonAtSeaReportDrawerContent = ({
	report,
	selectedTimeFormat,
}: {
	report: NoonReportWithPerformanceFields;
	selectedTimeFormat?: TimeFormat;
}) => {
	const consumptionColumns = [
		{
			title: 'Engine',
			dataIndex: 'engine',
			key: 'engine',
		},
		{
			title: 'Used for',
			dataIndex: 'reason',
			key: 'reason',
		},
		{
			title: 'Fuel Grade',
			dataIndex: 'fuelType',
			key: 'fuelType',
		},
		{
			title: 'Consumption',
			dataIndex: 'tons',
			key: 'tons',
			render: (tons: number) => (tons != null ? `
				${formatValueWithIndicators(
					round(tons, 3),
					' tons',
					null,
					{},
					NoonReportThresholds.CONSUMPTION,
				)}` : ''),
		},
		{
			title: 'Time',
			dataIndex: 'hours',
			key: 'hours',
			render: (hours: number) => (hours != null ? `${round(hours)} hours` : ''),
		},
	];

	const robColumns = [
		{
			title: 'Fuel Grade',
			dataIndex: 'fuelType',
			key: 'fuelType',
		},
		{
			title: 'Quantity',
			dataIndex: 'tons',
			key: 'tons',
			render: (tons: number) => (tons != null ? `${round(tons, 3)} tons` : ''),
		},
	];

	const detailItems = [
		{
			key: 'type',
			label: 'Type',
			value: CrewReportTypes.NOON_AT_SEA,
		},
		{
			key: 'date',
			label: 'Date',
			value: formatDate(report.date, DATE_AND_TIME, true, selectedTimeFormat),
		},
		{
			key: 'captainName',
			label: 'Master\'s name',
			value: report.captainName,
		},
		{
			key: 'position',
			label: 'Position',
			value: coordinatesToDMSString({
				lat: report.latitude,
				long: report.longitude,
			}),
		},
		{
			key: 'heading',
			label: 'Present Course',
			value: `${report.heading} degrees`,
		},
		{
			key: 'presentSpeed',
			label: 'Present Speed',
			value: `${report.presentSpeed} kt`,
		},
		{
			key: 'observedDistance',
			label: 'Observed Distance',
			value: report.observedDistance != null ? `${report.observedDistance} nm` : null,
		},
		{
			key: 'loadCondition',
			label: 'Load Condition',
			value: capitalize(report.loadCondition),
		},
		{
			key: 'instructedSpeed',
			label: 'Charter party speed',
			value: `${report.instructedSpeed != null ? `${report.instructedSpeed} kt` : ''}`,
		},
		{
			key: 'midDraft',
			label: 'Draft Mid',
			value: `${report.draftMid != null ? `${report.draftMid} meters` : ''}`,
		},
		{
			key: 'averageSpeed',
			label: 'Average Speed',
			value: formatValueWithIndicators<SpeedStopLightProps>(
				report.averageSpeed,
				' kt',
				getSpeedStoplight,
				{
					actual: report.averageSpeed,
					warranty: report.instructedSpeed,
					margin: report?.speedMargin ?? null,
				},
				NoonReportThresholds.AVERAGE_SPEED,
			),
		},
		{
			key: 'slip',
			label: 'Slip',
			value: formatValueWithIndicators(
				report.slip,
				'%',
				null,
				{},
				NoonReportThresholds.SLIP,
			),
		},
		{
			key: 'mainEnginePower',
			label: 'Main Engine Output',
			value: formatValueWithIndicators(
				report.mainEnginePower,
				' kWH',
				null,
				{},
				NoonReportThresholds.MAIN_ENGINE_OUTPUT,
			),
		},
		{
			key: 'averageRPM',
			label: 'Average RPM',
			value: formatValueWithIndicators(
				report.averageRPM,
				'',
				null,
				{},
				NoonReportThresholds.AVERAGE_RPM,
			),
		},
		{
			key: 'steamingHours',
			label: 'Steaming Hours',
			value: formatValueWithIndicators<SteamingHoursStopLightProps>(
				report.steamingHours,
				' hours',
				getSteamingHoursStoplight,
				{
					actual: report.steamingHours,
					limit: report?.minSteamingHours ?? null,
				},
				NoonReportThresholds.STEAMING_HOURS,
			),
		},
		{
			key: 'totalConsumption',
			label: 'Total Consumption',
			value: formatValueWithIndicators<ConsumptionStopLightProps>(
				calculateTotal(report.consumptionItems, (i) => i.tons),
				' MT',
				getConsumptionStoplight,
				{
					actual: calculateTotal(report.consumptionItems, (i) => i.tons),
					warranty: report?.warrantedConsumption ?? null,
					marginPercent: report?.consumptionMargin ?? null,
				},
				NoonReportThresholds.CONSUMPTION,
			),
		},
		{
			key: 'remarks',
			label: 'Remarks',
			value: report.remarks,
			maxHeight: 200,
			style: {
				whiteSpace: 'pre-wrap',
				overflow: 'auto',
			} as React.CSSProperties,
		},
	];

	const etaItems = [
		{
			key: 'etaPort1Name',
			label: 'Port 1',
			value: report?.etaPort1Name != null ?
				capitalize(report?.etaPort1Name) :
				null,
		},
		{
			key: 'etaDate1',
			label: 'ETA 1',
			value: report?.etaDate1 != null ?
				formatDate(report?.etaDate1, DATE_AND_TIME, true, selectedTimeFormat) :
				null,
		},
		{
			key: 'etaPort2Name',
			label: 'Port 2',
			value: report?.etaPort2Name != null ?
				capitalize(report?.etaPort2Name) :
				null,
		},
		{
			key: 'etaDate2',
			label: 'ETA 2',
			value: report?.etaDate2 != null ?
				formatDate(report?.etaDate2, DATE_AND_TIME, true, selectedTimeFormat) :
				null,
		},
	];

	const weatherItems = [
		{
			key: 'windForceBeaufort',
			label: 'Wind Force',
			value: formatValueWithIndicators<WeatherStopLightProps>(
				report.windForceBeaufort,
				` ${capitalize('bf')}`,
				getWeatherStoplight,
				{
					actual: report.windForceBeaufort,
					limit: report?.windForceLimit ?? null,
				},
				NoonReportThresholds.WIND_FORCE,
			),
		},
		{
			key: 'windDirection',
			label: 'Wind Direction',
			value: report.windDirection != null ? `${report.windDirection} degrees` : null,
		},
		{
			key: 'currentSpeedKnots',
			label: 'Current Speed',
			value: formatValueWithIndicators(
				report.currentSpeedKnots,
				' kt',
				null,
				{},
				NoonReportThresholds.CURRENT_SPEED,
			),
		},
		{
			key: 'currentDirection',
			label: 'Current Direction',
			value: report.currentDirection != null ? `${report.currentDirection} degrees` : null,
		},
		{
			key: 'seaHeight',
			label: 'Sea Height',
			value: formatValueWithIndicators(
				report.seaHeight,
				' m',
				null,
				{},
				NoonReportThresholds.SEA_HEIGHT,
			),
		},
		{
			key: 'seaStateDouglas',
			label: 'Sea State',
			value: report.seaStateDouglas != null ? `${report.seaStateDouglas} douglas` : null,
		},
		{
			key: 'seaDirection',
			label: 'Sea Direction',
			value: report.seaDirection != null ? `${report.seaDirection} degrees` : null,
		},
		{
			key: 'swellHeight',
			label: 'Swell Height',
			value: formatValueWithIndicators(
				report.swellHeight,
				' m',
				null,
				{},
				NoonReportThresholds.SWELL_HEIGHT,
			),
		},
		{
			key: 'swellDirection',
			label: 'Swell Direction',
			value: report.swellDirection != null ? `${report.swellDirection} degrees` : null,
		},
	];

	return (
		<>
			<Typography.Title level={5}>Details</Typography.Title>
			<Details
				labelWidth={250}
				items={detailItems}
				hideHeader
			/>
			<br />
			<Typography.Title level={5}>ETA</Typography.Title>
			<Details
				labelWidth={250}
				items={etaItems}
				hideHeader
			/>
			<br />
			<Typography.Title level={5}>Weather</Typography.Title>
			<Details
				labelWidth={250}
				items={weatherItems}
				hideHeader
			/>
			<br />
			<Typography.Title level={5}>Consumption</Typography.Title>
			<Table
				columns={consumptionColumns}
				dataSource={report.consumptionItems || []}
				pagination={false}
			/>
			<br />
			<Typography.Title level={5}>ROBs</Typography.Title>
			<Table
				columns={robColumns}
				dataSource={report.robItems || []}
				pagination={false}
			/>
			<br />
			<>
				<Typography.Title level={5}>Position</Typography.Title>
				<div className={styles.map}>
					<Map
						defaultPosition={{
							latitude: report.latitude,
							longitude: report.longitude,
						}}
					>
						{({ viewport }) => (
							<ReportsLayer
								reports={[{ ...report, type: CrewReportTypes.NOON_AT_SEA }]}
								zoom={viewport.zoom}
								openReportDrawer={undefined}
							/>
						)}
					</Map>
				</div>
			</>
		</>
	);
};

export default NoonAtSeaReportDrawerContent;
