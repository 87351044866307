import React from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';
import NumericInput, { NumericInputProps } from '@client/components/NumericInput';
import { useAuth } from '@client/lib/auth';

export type CurrencyInputProps = {
	currency?: Values<typeof Currencies>;
	addCurrencyBefore?: boolean;
	disabledTooltip?: string | React.ReactElement;
	tooltipProps?: TooltipProps;
} & NumericInputProps;

const CurrencyInput = ({
	allowNegative = false,
	separateThousands = true,
	addCurrencyBefore = true,
	disabledTooltip,
	tooltipProps,
	currency,
	...props
}: CurrencyInputProps) => {
	const { userInfo } = useAuth();

	const content = (
		<NumericInput
			addonBefore={addCurrencyBefore ? (currency ?? userInfo.baseCurrency) : null}
			separateThousands={separateThousands}
			allowNegative={allowNegative}
			{...props}
		/>
	);

	if (props.disabled && disabledTooltip != null) {
		return (
			<Tooltip
				title={disabledTooltip}
				{...tooltipProps}
			>
				{content}
			</Tooltip>
		);
	}

	return content;
};

export default CurrencyInput;
