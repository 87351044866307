import React from 'react';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';

const renderItemAmount = (
	amount: number,
	isTotal: boolean,
	isDifference: boolean,
	currency: Values<typeof Currencies>,
) => {
	let toRender = (
		<span>
			{formatCurrency(amount, currency, { forceDecimals: true, maximumFractionDigits: 2 })}
		</span>
	);

	if (isDifference) {
		let color;

		if (amount < 0) {
			color = 'green';
		} else if (amount > 0) {
			color = 'red';
		}

		toRender = (
			<span style={{ color }}>{toRender}</span>
		);
	}

	if (isTotal) {
		toRender = (
			<strong>{toRender}</strong>
		);
	}

	return (
		<span>
			{toRender}
		</span>
	);
};

export default renderItemAmount;
