class APIError extends Error {
	public rawMessage: string;

	constructor(
		errorMessage: string,
		public humanReadable: boolean,
		public statusCode: number,
	) {
		super(errorMessage);

		this.statusCode = statusCode;
		this.rawMessage = errorMessage;
		this.humanReadable = humanReadable;
	}
}

export default APIError;
