import React, { useRef } from 'react';
import {
	Badge,
	Card,
	Grid,
	Space,
} from 'antd';
import classNames from 'classnames';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import {
	useDrag,
	useDrop,
} from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAnchor,
	faGripDotsVertical,
	faRightLeftLarge,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faFlagCheckered,
	faPlay,
} from '@fortawesome/pro-regular-svg-icons';
import {
	capitalize,
	trimString,
} from '@shared/utils/string';
import {
	PortActionTypes,
	PortCallTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type {
	ItineraryEntryState,
	ItineraryPortCallDto,
} from '@api/features/ops/getVesselItinerary';
import CountryFlag from '@client/components/CountryFlag';
import PrettyDate from '@client/components/PrettyDate/PrettyDate';
import { getPortCallActionTag } from '@client/screens/fleet/VoyageDetailsScreen/components/ItineraryTab/utils/getPortCallActionTag';
import styles from './PortCallEntry.module.css';

type ItineraryEntryCardProps = {
	selectedTimeFormat: 'utc' | 'localTime';
	warning?: boolean;
	state: ItineraryEntryState;
	stale: boolean;
	portCall: ItineraryPortCallDto;
	selected: boolean;
	selectPortCall: () => void;
	order: number;
	onReorder: (draggedIndex: number, droppedOverIndex: number) => void;
}

const PORT_CALL = 'PORT_CALL' as const;

const PortCallEntry = (props: ItineraryEntryCardProps) => {
	const {
		state,
		warning,
		portCall,
		selectedTimeFormat,
		selected,
		selectPortCall,
		order,
		onReorder,
	} = props;

	const {
		port,
		estimated,
		arrivalDate,
		estimatedArrivalDate,
		estimatedDepartureDate,
		departureDate,
		isCanalTransit,
		type,
	} = portCall;

	const ref = useRef<HTMLDivElement>(null);

	const canDropItem = (item: any) => !item.isCanalTransit;

	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: PORT_CALL,
		canDrop: (item) => canDrag && canDropItem(item),
		collect: (monitor) => {
			const item = monitor.getItem();

			if (item == null) {
				return {};
			}

			const newIsOver = monitor.isOver();
			const canDrop = monitor.canDrop();

			return {
				item,
				isOver: newIsOver,
				canDrop,
				dropClassName: canDrop ?
					(
						item.order < order ?
							styles.dropOverDownward :
							styles.dropOverUpward
					) :
					undefined,
			};
		},
		drop: (item: any) => {
			if (item.isCanalTransit) {
				return;
			}

			onReorder(item.order, order);
		},
	});

	const canDrag = !isCanalTransit;

	const [_, drag] = useDrag({
		item: {
			type: PORT_CALL,
			order,
			isCanalTransit,
			estimated,
			portCallType: type,
		},
		canDrag,
		collect: (monitor) => {
			return {
				canDrag: monitor.canDrag(),
			};
		},
	});

	drop(drag(ref));

	const screens = Grid.useBreakpoint();

	let status: PresetStatusColorType;

	switch (state) {
		case 'in progress':
			status = 'processing';
			break;
		case 'completed':
			status = 'success';
			break;
		case 'estimated':
			status = 'warning';
			break;
		default:
			status = 'warning';
			break;
	}

	const smallScreenWithLongName = !screens.xl && port.name.length > 18;

	const getIcon = () => {
		let icon = faAnchor;

		if (isCanalTransit) {
			icon = faRightLeftLarge;
		}

		if (type === PortCallTypes.COMMENCEMENT) {
			icon = faPlay;
		}

		if (type === PortCallTypes.COMPLETION) {
			icon = faFlagCheckered;
		}

		return icon;
	};

	const icon = getIcon();

	const getDepartureDateLabel = (
		portCallType: Values<typeof PortCallTypes>,
		isEstimated: boolean,
	) => {
		let msg = 'Departure date';

		if (portCallType === PortCallTypes.COMPLETION) {
			msg = 'Completion date';
		}

		if (isEstimated) {
			msg = `Estimated ${msg.toLowerCase()}`;
		}

		if (portCallType === PortCallTypes.COMMENCEMENT) {
			msg = 'Commencement Date';
		}

		return msg;
	};

	return (
		<div
			ref={ref}
			className={classNames(styles.cardWrapper, isOver ? dropClassName : undefined)}
			key={portCall.id}
		>
			<Card
				className={classNames(styles.portCall, {
					[styles.warning]: warning,
					[styles.selectedPortCall]: selected,
				})}
				onClick={selectPortCall}
			>
				{estimated && (<div className={styles.overlay} />)}
				<Space direction="vertical" className={styles.content}>
					<div className={styles.header}>
						<Space>
							{canDrag && (<FontAwesomeIcon color="gray" icon={faGripDotsVertical as IconProp} />)}
							<FontAwesomeIcon icon={icon} />
							{!smallScreenWithLongName && (
								<CountryFlag countryCode={port.countryCode} />
							)}
							<Space>
								<b className={classNames({ [styles.smallerTitle]: !screens.xl })}>
									{smallScreenWithLongName ?
										trimString(port.name, 18, true) :
										port.name}
								</b>
							</Space>
							{screens.xl && (
								<div className={styles.tagContainer}>
									{portCall.actions
										.map((action) => {
											if (
												action.action === PortActionTypes.DELIVERING &&
												portCall.type === PortCallTypes.DELIVERY
											) {
												return null;
											}

											return getPortCallActionTag(
												action.action as Values<typeof PortActionTypes>,
												screens,
											);
										})}
								</div>
							)}
						</Space>
						{portCall.type === PortCallTypes.NORMAL ? (
							<Badge
								className={classNames({ [styles.smallerStatus]: !screens.xl })}
								status={status}
								text={(<b>{capitalize(portCall.estimated ? ('estimated') : state)}</b>)}
							/>
						) : (
							<b>{capitalize(portCall.type)}</b>
						)}
					</div>
					<div className={styles.datesWrapper}>
						{portCall.type === PortCallTypes.COMMENCEMENT ? (
							<div className={styles.innerDatesWrapper} />
						) : (
							<>
								{arrivalDate != null ? (
									<div className={styles.innerDatesWrapper}>
										<p className={styles.dateLabel}>Arrival date</p>
										<p className={styles.date}>
											<PrettyDate
												format={selectedTimeFormat}
												level={5}
												date={arrivalDate}
											/>
										</p>
									</div>
								) : (
									<div>
										<p className={styles.dateLabel}>Estimated arrival date</p>
										{estimatedArrivalDate != null ? (
											<p className={styles.date}>
												<PrettyDate
													format={selectedTimeFormat}
													level={5}
													date={estimatedArrivalDate}
												/>
											</p>
										) : (<p className={styles.date}>N/A</p>)}
									</div>
								)}
							</>
						)}
						{departureDate != null ? (
							<div className={styles.innerDatesWrapper}>
								<p className={styles.dateLabel}>{getDepartureDateLabel(portCall.type, false)}</p>
								<PrettyDate
									format={selectedTimeFormat}
									level={5}
									date={departureDate}
								/>
							</div>
						) : (
							<div className={styles.innerDatesWrapper}>
								<p className={styles.dateLabel}>{getDepartureDateLabel(portCall.type, true)}</p>
								{
									estimatedDepartureDate != null ? (
										(
											<PrettyDate
												level={5}
												format={selectedTimeFormat}
												date={estimatedDepartureDate}
											/>
										)
									) : (<p className={styles.date}>N/A</p>)
								}
							</div>
						)}
					</div>
				</Space>
			</Card>
		</div>
	);
};

export default PortCallEntry;
