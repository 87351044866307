import React, {
	useMemo,
	useRef,
	useState,
} from 'react';
import type { RcFile } from 'rc-upload/lib/interface';
import {
	Card,
	Divider,
	Input,
	List,
	notification,
	Space,
	Upload,
} from 'antd';
import { useDropArea } from 'react-use';
import useSearch from 'react-hook-search';
import classNames from 'classnames';
import {
	CalendarOutlined,
	DeleteOutlined,
	SearchOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import {
	AttachmentTypes,
	VesselDocumentTags,
	VoyageDocumentTags,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { formatDate } from '@client/utils/formatDate';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	addAttachment,
	deleteAttachment,
	updateAttachment,
} from '@client/lib/api';
import AttachmentViewer from './AttachmentViewer';
import styles from './styles/AttachmentLibrary.module.css';
import Button from './Button';
import TagFilter from './TagFilter';
import FileIcon from './FileIcon';
import TagList from './TagList';

type Attachments = GetVoyageDetailsResponse['attachments'];
type Attachment = Attachments[number];

export type Tag = typeof VesselDocumentTags[number] | typeof VoyageDocumentTags[number]
export type Tags = Tag[];

const AttachmentLibrary = ({
	attachments,
	attachToId,
	refreshAttachments,
	type,
	allowMultiUpload = true,
	renderExtra,
}: {
	attachments: Attachments;
	refreshAttachments: () => void;
	type: Values<typeof AttachmentTypes>;
	attachToId: number;
	allowMultiUpload?: boolean;
	renderExtra?: (data: any) => React.FC;
}) => {
	const [tagFilterValue, setTagFilterValue] = useState<Tags>([]);
	const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
	const [uploadingAttachments, setUploadingAttachments] = useState({});
	const tags = type === AttachmentTypes.VESSEL ? VesselDocumentTags : VoyageDocumentTags;

	const listRef = useRef<HTMLDivElement>(null);

	const showNotAFileNotication = (text: string) => notification.info({
		message: 'Cannot upload the dropped item',
		description: `"${text}" is not a file`,
	});

	const [containerBond, dropState] = useDropArea();
	const [overlayBond, overlayDropState] = useDropArea({
		onFiles: (files) => files.forEach((f) => {
			const file = f as RcFile;
			file.uid = `${f.name}-${f.lastModified}`;

			uploadAttachment(file);
		}),
		onUri: showNotAFileNotication,
		onText: showNotAFileNotication,
	});

	const dropping = dropState.over || overlayDropState.over;

	const [filteredAttachments, searchValue, setSearchValue] = useSearch(
		attachments || [],
		['name', 'createdAt', 'tags'],
	);

	const addTag = async (attachment: Attachment, tag: Tag) => {
		// Cannot add the same tag twice
		if (attachment?.tags?.includes(tag)) {
			return;
		}

		try {
			await updateAttachment(attachment.id, { tags: [...attachment.tags ?? [], tag] });
			await refreshAttachments();
		} catch (e) {
			showErrorNotification(`Could not create new tag ${e}`);
		}
	};

	const removeTag = async (attachment: Attachment, tag: Tag) => {
		try {
			if (attachment?.tags?.includes(tag)) {
				const newTags = attachment.tags.filter((t) => t !== tag);
				const newTagFilter = tagFilterValue.filter((t) => t !== tag);

				setTagFilterValue(newTagFilter);
				await updateAttachment(attachment.id, { tags: newTags });
				await refreshAttachments();
			}
		} catch (e) {
			showErrorNotification(`Could not remove tag ${e}`);
		}
	};

	const uploadAttachment = async (file: RcFile) => {
		if (file == null) {
			return;
		}

		setUploadingAttachments((state) => ({
			...state,
			[file.uid]: true,
		}));

		try {
			await addAttachment(
				type,
				attachToId,
				file,
			);
			await refreshAttachments();
		} catch (e) {
			showErrorNotification('Could not upload file', e as Error);
		}

		setUploadingAttachments((state) => ({
			...state,
			[file.uid]: false,
		}));
	};

	const onDeleteAttachment = async (id: number) => {
		try {
			await deleteAttachment(id);
			await refreshAttachments();

			notification.success({
				message: 'Attachment was deleted',
				description: 'The selected document was successfully deleted',
			});
		} catch (e) {
			showErrorNotification('Could not delete document', e as Error);
		}
	};

	const uploading = Object.values(uploadingAttachments).some((i) => i);

	const visibleAttachments = useMemo(() => {
		if (tagFilterValue.length > 0) {
			// Only return attachments that have all the tags selected in tag filter
			return filteredAttachments.filter((attachment) => (
				tagFilterValue.every((t) => attachment?.tags?.includes(t))
			));
		}

		return filteredAttachments;
	}, [filteredAttachments, tagFilterValue]);

	/*
	* See https://github.com/microsoft/TypeScript/issues/33591
	* @ts-ignore */
	const usedTags = tags.filter((t: Tag) => (
		attachments.some((a) => a?.tags?.includes(t))
	));

	const itemClick = (event: React.MouseEvent, item: Attachment) => {
		// Clicks on a popover (i.e. date picker) will still call this function
		// Make sure click was actually on an item
		if (
			listRef?.current != null &&
			event.target !== listRef.current &&
			!listRef.current.contains(event.target as Node)
		) {
			return;
		}

		setOpenAttachment(item);
	};

	return (
		<Card>
			<div
				{...containerBond}
				className={classNames(styles.container, {
					[styles.dropping]: dropping,
				})}
			>
				<div
					className={styles.dropOverlay}
					{...overlayBond}
				>
					Drop to upload
				</div>
				<Upload
					name="attachments"
					multiple={allowMultiUpload}
					showUploadList={false}
					customRequest={({ file }) => uploadAttachment(file as RcFile)}
				>
					<Button
						loading={uploading}
						type="primary"
						icon={(<UploadOutlined />)}
					>
						Upload document
					</Button>
				</Upload>
				<br />
				<span className={styles.dropLabel}>{' or drop files here to upload'}</span>
				<Divider />
				<TagFilter
					tags={usedTags}
					value={tagFilterValue}
					onChange={(v: Tags) => setTagFilterValue(v)}
				/>
				<Input
					placeholder="Search"
					onChange={(e) => setSearchValue(e.target.value)}
					value={searchValue}
					prefix={(<SearchOutlined />)}
					className={styles.search}
				/>
				<div ref={listRef}>
					<List
						dataSource={visibleAttachments}
						renderItem={(item) => (
							<List.Item
								key={item.id}
								onClick={(e) => itemClick(e, item)}
								className={styles.item}
							>
								<FileIcon
									fileType={item.type}
									size="2x"
								/>
								<div className={styles.panelHeader}>
									<div className={styles.documentTitle}>
										<b>{item.name}</b>
										<TagList
											options={tags}
											tags={item.tags || []}
											onRemoveTag={(tag: Tag) => removeTag(item, tag)}
											onAddTag={(tag: Tag) => addTag(item, tag)}
										/>
									</div>
									<Space>
										<>
											<span>
												<CalendarOutlined />
												{` ${formatDate(item.createdAt)}`}
											</span>
											{typeof renderExtra === 'function' && renderExtra(item)}
											{typeof onDeleteAttachment === 'function' && (
												<Button
													confirmTitle="Are you sure you want to delete this document?"
													clickPropagation={false}
													onClick={() => {
														onDeleteAttachment(item.id);

														return true;
													}}
													type="link"
													danger
													icon={(<DeleteOutlined />)}
													className={styles.deleteButton}
													size="small"
												>
													Delete
												</Button>
											)}
										</>
									</Space>
								</div>
							</List.Item>
						)}
					/>
				</div>
				<AttachmentViewer
					modal
					onCloseModal={() => setOpenAttachment(null)}
					attachment={openAttachment}
				/>
			</div>
		</Card>
	);
};

export default AttachmentLibrary;
