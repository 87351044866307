// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mFx8yeM98A0M0sPqN6kj {\n\tcursor: pointer;\n\twidth: 100%;\n}\n\n.pIU3mqTZsiOz77bHUMtr {\n\twidth: 65px;\n\ttext-align: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/financials/Accounting/styles/List.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,WAAW;AACZ;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB","sourcesContent":[".listEntry {\n\tcursor: pointer;\n\twidth: 100%;\n}\n\n.tag {\n\twidth: 65px;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listEntry": "mFx8yeM98A0M0sPqN6kj",
	"tag": "pIU3mqTZsiOz77bHUMtr"
};
export default ___CSS_LOADER_EXPORT___;
