// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dqgncxd35SBlgF8D9_JI {\n\tdisplay: flex;\n\tmargin-bottom: 8px;\n}\n\n._DUDQEGLWd_CTNQJoE4n {\n\tdisplay: flex;\n\tflex-direction: column-reverse;\n}\n\n.GJ13IsM607CrwHi3g0gT {\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-top: 16px;\n}\n\n.VxTcVjA6sSbIYNVurmDD,\n.VxTcVjA6sSbIYNVurmDD:focus {\n\tcolor: #666;\n}\n\n[disabled=true] > .VxTcVjA6sSbIYNVurmDD {\n\tcolor: #ccc;\n}\n\n.toujmYRJSvgc2_hpTNSw {\n\tmargin: 0 16px;\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.vJZNXDemT5nO8m6Sz9pz {\n\tfont-size: 7px;\n\tmargin: 0 4px;\n\tcolor: #999;\n}\n\n.vJZNXDemT5nO8m6Sz9pz.ziF08sOGDILQsiB1YEB9 {\n\tcolor: var(--primary);\n\tfont-size: 11px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/Tour/Tour.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;;CAEC,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,cAAc;CACd,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,cAAc;CACd,aAAa;CACb,WAAW;AACZ;;AAEA;CACC,qBAAqB;CACrB,eAAe;AAChB","sourcesContent":[".imageWrapper {\n\tdisplay: flex;\n\tmargin-bottom: 8px;\n}\n\n.tour {\n\tdisplay: flex;\n\tflex-direction: column-reverse;\n}\n\n.navigationWrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tmargin-top: 16px;\n}\n\n.navigationChevron,\n.navigationChevron:focus {\n\tcolor: #666;\n}\n\n[disabled=true] > .navigationChevron {\n\tcolor: #ccc;\n}\n\n.navigationDots {\n\tmargin: 0 16px;\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.navigationDot {\n\tfont-size: 7px;\n\tmargin: 0 4px;\n\tcolor: #999;\n}\n\n.navigationDot.current {\n\tcolor: var(--primary);\n\tfont-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageWrapper": "Dqgncxd35SBlgF8D9_JI",
	"tour": "_DUDQEGLWd_CTNQJoE4n",
	"navigationWrapper": "GJ13IsM607CrwHi3g0gT",
	"navigationChevron": "VxTcVjA6sSbIYNVurmDD",
	"navigationDots": "toujmYRJSvgc2_hpTNSw",
	"navigationDot": "vJZNXDemT5nO8m6Sz9pz",
	"current": "ziF08sOGDILQsiB1YEB9"
};
export default ___CSS_LOADER_EXPORT___;
