import { splitActionKey } from '@shared/utils/splitActionKey';
import type { CargoWithPortsAndCalculationsJSONnoLaytime } from '@api/features/laytime/getCargosAndLaytimes';
import { FreightRateType } from './constants';

const getFDDNumbers = (
	cargo: CargoWithPortsAndCalculationsJSONnoLaytime[number],
) => {
	const cpQuantity = cargo.quantity;
	const cpQuantityMin = ((100 - (cargo.quantityTolerance ?? 0)) / 100) * cpQuantity;
	const cpQuantityMax = ((100 + (cargo.quantityTolerance ?? 0)) / 100) * cpQuantity;

	const totalActuallyLoaded = cargo.CargoPorts.reduce((total, cp) => {
		const { action } = splitActionKey(cp.portAndActionKey);

		if (action !== 'loading') {
			return total;
		}

		return total + (cp.blQuantity ?? 0);
	}, 0);

	const totalActuallyDischarged = cargo.CargoPorts.reduce((total, cp) => {
		const { action } = splitActionKey(cp.portAndActionKey);

		if (action !== 'discharging') {
			return total;
		}

		return total + (cp.blQuantity ?? 0);
	}, 0);

	let totalFreightAmount = 0;
	let totalOverageAmount = 0;
	let totalDeadfreightAmount = 0;

	if (totalActuallyLoaded > cpQuantity) {
		// Actually loaded more than CP
		if (totalActuallyLoaded >= cpQuantityMax) {
			// Actually loaded more than CP + tolerance %
			totalFreightAmount = cpQuantityMax;
			totalOverageAmount = totalActuallyLoaded - cpQuantityMax;
		} else {
			totalFreightAmount = totalActuallyLoaded;
		}
	}

	if (totalActuallyLoaded === cpQuantity) {
		// loaded exactly CP quantity
		totalFreightAmount = cpQuantity;
	}

	if (totalActuallyLoaded < cpQuantity) {
		// Actually loaded less than CP
		if (totalActuallyLoaded <= cpQuantityMin) {
			// Actually loaded less than CP - tolerance %
			totalFreightAmount = totalActuallyLoaded;
			totalDeadfreightAmount = cpQuantityMin - totalActuallyLoaded;
		} else {
			totalFreightAmount = totalActuallyLoaded;
		}
	}

	const calculateTotal = (
		items: Array<{ percentage: number; quantity: number }>,
		key: 'freightRate' | 'overageAmount' | 'deadfreightAmount',
	) => {
		if (items == null) {
			return 0;
		}

		const rate = cargo[key];

		if (rate == null) {
			return 0;
		}

		return items.reduce((total: number, item) => {
			if (cargo.freightType === FreightRateType.LUMPSUM) {
				return total + ((item.percentage) / 100) * rate;
			}

			return total + (((item.percentage) / 100) * item.quantity) * rate;
		}, 0);
	};

	const freightItems = cargo.HIIFreights;
	const overageItems = cargo.HIIOverages;
	const deadfreightItems = cargo.HIIDeadfreights;

	const totalFreightInvoiced = calculateTotal(freightItems, 'freightRate');
	const totalOverageInvoiced = calculateTotal(overageItems, 'overageAmount');
	const totalDeadfreightInvoiced = calculateTotal(deadfreightItems, 'deadfreightAmount');

	return {
		totalFreightAmount,
		totalDeadfreightAmount,
		totalOverageAmount,
		totalActuallyLoaded,
		totalActuallyDischarged,
		totalFreightInvoiced,
		totalOverageInvoiced,
		totalDeadfreightInvoiced,
	};
};

export default getFDDNumbers;
