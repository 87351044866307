import React from 'react';
import { formatCurrency } from '@shared/utils/currency';
import { round } from '@shared/utils/math';
import type { GetFleetAnalyticsResponse } from '@api/features/statistics/getFleetAnalytics';
import { useAuth } from '@client/lib/auth';
import NumberWidget from '../components/NumberWidget';

type Props = {
	staticData: GetFleetAnalyticsResponse['data'] | undefined;
	loading: boolean;
}

const ForwardHireRateNumberWidget = ({
	staticData,
	loading,
}: Props) => {
	const { userInfo } = useAuth();

	return (
		<NumberWidget
			title="Forward Gross Hire per day"
			badge="GROSS"
			headerTooltip={(data) => data && (
				`Expected gross hire of ${formatCurrency(round(data.forwardHire.averageTCE.meta.tcIncome, 0), userInfo.baseCurrency)} divided by ${data.forwardHire.averageTCE.meta.fixedDays} minimum fixed forward hire days`
			)}
			staticData={staticData}
			transformData={
				(data) => formatCurrency(round(data.forwardHire.averageTCE.data, 0), userInfo.baseCurrency)
			}
			forward
			externalLoading={loading}
		/>
	);
};

export default ForwardHireRateNumberWidget;
