import { FixtureTypes } from '@shared/utils/constants';
import getAvailableFreightInvoiceItems from '@shared/utils/getAvailableFreightInvoiceItems';
import { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import { SpotFixtureProps } from '@api/models/spot-fixture';
import { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';

const getAvailableHIIsFromSpotDetails = (
	voyageDetails: GetVoyageDetailsResponse,
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>,
	invoices: VoyageInvoice[],
	chartererId: number,
) => {
	const {
		voyageExpenseReceivables,
		revenueItems,
	} = voyageDetails;

	const {
		addressCommission,
		brokers,
		bankAccount,
		cargos,
		type,
	} = fixtureDetails;

	if (bankAccount == null) {
		return [];
	}

	if (type !== FixtureTypes.SPOT) {
		return [];
	}

	return getAvailableFreightInvoiceItems({
		type,
		brokers,
		cargos,
		chartererId,
		hireInvoices: invoices ?? [],
		addressCommission,
		voyageExpenseReceivables,
		revenueItems,
		bankAccount,
	});
};

export default getAvailableHIIsFromSpotDetails;
