import React, { useMemo } from 'react';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons';
import { Grid } from 'antd';
import { standardSort } from '@shared/utils/standardSort';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getPerformanceOverview } from '@client/lib/api';
import { useAuth } from '@client/lib/auth';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';
import { getPerformanceStatusColumns } from '@client/screens/performance/PerformanceDetailsScreen/helpers/getPerformanceStatusColumns';
import { getFilterProps } from '@client/utils/table';
import VesselDescription from '@client/components/VesselDescription';
import styles from './PerformanceListScreen.module.css';

const PerformanceListScreen = () => {
	const [vessels, _reloadVessels, error, loading] = useFetchedState(getPerformanceOverview);
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const vesselFilter = useMemo(() => (
		userInfo.filterVessels ?
			userInfo.relevantVessels.map((v) => v.name) :
			[]
	), [userInfo.filterVessels, userInfo.relevantVessels]);

	const columns = useMemo(() => [
		{
			title: 'Vessel',
			dataIndex: 'name',
			key: 'name',
			sorter: standardSort('name'),
			filteredValue: vesselFilter,
			...getFilterProps((vessels ?? []), 'select', 'name', 'desc'),
			defaultSortOrder: 'ascend',
			render: (_, vessel) => (
				<VesselDescription
					name={vessel.name}
					flag={vessel.flag}
				/>
			),
		},
		...getPerformanceStatusColumns(true),
	], [vessels, vesselFilter]);

	return (
		<ListTableScreen
			title="Performance Overview"
			rootPageTitle="Performance"
			columns={columns}
			className={!screens.xl ? styles.smallText : undefined}
			tableProps={{ size: 'middle' }}
			loading={loading}
			error={error}
			data={vessels}
			getRowHref={(row) => Links.PerformanceDetails.get(row.id)}
			emptyTitle="You do not have any vessels yet"
			emptyDescription="To see the performance overview, first create a vessel"
			emptyIcon={faRoute}
		/>
	);
};

export default PerformanceListScreen;
