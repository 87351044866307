// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mhYmcCT6ADPiwh_yBZv5 {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 0;\n}\n\n.CQQl5B3SucO7tYoAj1pT {\n\twidth: 100px;\n}\n\n.V8407GkYg3sCCuZ4EZTW {\n\tmin-width: 120px;\n}\n\n.C_no0MwjiPjo_oPhTPW4 {\n\tmax-width: 85px;\n}\n\n.bHeUDCPLXsVRZyekaKNu {\n\tmax-width: 120px;\n}\n\n.wu8PjMGkUThTKvbJCrhU {\n\tmax-width: 50px;\n\ttext-align: center;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/cargos/components/CommissionsCard/BrokerCommissionDetails.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,MAAM;AACP;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,kBAAkB;AACnB","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 0;\n}\n\n.addressCommission {\n\twidth: 100px;\n}\n\n.brokerCol {\n\tmin-width: 120px;\n}\n\n.commissionCol {\n\tmax-width: 85px;\n}\n\n.paidByCol {\n\tmax-width: 120px;\n}\n\n.addCol {\n\tmax-width: 50px;\n\ttext-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "mhYmcCT6ADPiwh_yBZv5",
	"addressCommission": "CQQl5B3SucO7tYoAj1pT",
	"brokerCol": "V8407GkYg3sCCuZ4EZTW",
	"commissionCol": "C_no0MwjiPjo_oPhTPW4",
	"paidByCol": "bHeUDCPLXsVRZyekaKNu",
	"addCol": "wu8PjMGkUThTKvbJCrhU"
};
export default ___CSS_LOADER_EXPORT___;
