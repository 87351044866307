import React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';
import { toMoment } from '@shared/utils/date';
import type { TransformedBrokerInvoices } from '@api/utils/getTransformedBrokerInvoices';
import { Links } from '@client/utils/links';
import StateTag from '@client/components/StateTag';
import { EditableDetailsItem } from '@client/components/EditableField';
import styles from '@client/components/ExpenseDetailsCard/private/styles/SelectedExpenseItems.module.css';

const getSelectedBrokerInvoiceItem = (
	selectedInvoice: TransformedBrokerInvoices[number],
) => {
	if (selectedInvoice == null) {
		return [];
	}

	const { brokerName } = selectedInvoice;

	return [
		{
			label: 'Status',
			key: 'state',
			value: (
				<StateTag state={selectedInvoice.state} />
			),
		},
		{
			key: 'amount',
			label: 'Amount',
			value: {
				value: selectedInvoice.amount,
				currency: selectedInvoice.currency,
			},
			type: 'multiCurrency',
			inputProps: {
				baseCurrency: selectedInvoice.currency,
			},
		},
		{
			label: 'Description',
			key: 'itemDescription',
			value: selectedInvoice.itemDescription,
		},
		{
			label: 'Contract',
			key: 'contract',
			value: (
				<Space direction="vertical" size={1}>
					<b>
						<Link to={Links.Voyage.get(selectedInvoice.voyageId)}>
							{selectedInvoice.voyageIdentifier}
						</Link>
					</b>
					{brokerName != null && (
						<span className={styles.contractSubSection}>
							Broker:
							{' '}
							{brokerName}
						</span>
					)}
				</Space>
			),
		},
		{
			label: 'Broker',
			key: 'broker',
			value: selectedInvoice.brokerName,
		},
		{
			type: 'date',
			key: 'dateReceived',
			label: 'Date Received',
			value: selectedInvoice.dateReceived ? toMoment(selectedInvoice.dateReceived) : '',
		},
		{
			type: 'date',
			key: 'dueDate',
			label: 'Due Date',
			value: selectedInvoice.dueDate ? toMoment(selectedInvoice.dueDate) : '',
		},
		{
			label: 'Created By',
			value: selectedInvoice.createdByUserName,
			key: 'createdBy',
		},
	] as EditableDetailsItem<Partial<typeof selectedInvoice>>[];
};

export default getSelectedBrokerInvoiceItem;
