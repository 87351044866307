// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nzqs9MlLb6Pee5inyege {\n\twidth: 100%;\n}\n\n._qOr1jfrIEfLUmZjucOG {\n\tfont-size: 0.9em;\n\tpadding-left: 0;\n\tposition: absolute;\n\tbottom: -2px;\n}\n\ntr.xmnZGlPzDW4eo321a86s > td {\n\tpadding-bottom: 32px;\n}\n\n.xmnZGlPzDW4eo321a86s .ant-row {\n\tmargin-bottom: 10px;\n}\n\n.v5xlxneJY8QMEmElqh3F {\n\tfont-size: 0.8rem;\n\tpadding: 0;\n}\n\n.FbT1xyNU3G1zSk4B_SLV {\n\tfont-size: 0.8rem;\n\tpadding: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fixtures/tc/styles/useTcSectionItems.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,gBAAgB;CAChB,eAAe;CACf,kBAAkB;CAClB,YAAY;AACb;;AAEA;CACC,oBAAoB;AACrB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;CACjB,UAAU;AACX;;AAEA;CACC,iBAAiB;CACjB,UAAU;AACX","sourcesContent":[".fullWidth {\n\twidth: 100%;\n}\n\n.createBrokerButton {\n\tfont-size: 0.9em;\n\tpadding-left: 0;\n\tposition: absolute;\n\tbottom: -2px;\n}\n\ntr.editingBroker > td {\n\tpadding-bottom: 32px;\n}\n\n.editingBroker :global(.ant-row) {\n\tmargin-bottom: 10px;\n}\n\n.exactDateWithPicker {\n\tfont-size: 0.8rem;\n\tpadding: 0;\n}\n\n.exactDateWithoutPicker {\n\tfont-size: 0.8rem;\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": "nzqs9MlLb6Pee5inyege",
	"createBrokerButton": "_qOr1jfrIEfLUmZjucOG",
	"editingBroker": "xmnZGlPzDW4eo321a86s",
	"exactDateWithPicker": "v5xlxneJY8QMEmElqh3F",
	"exactDateWithoutPicker": "FbT1xyNU3G1zSk4B_SLV"
};
export default ___CSS_LOADER_EXPORT___;
