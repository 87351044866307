import React, { useMemo } from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Departments } from '@shared/utils/constants';
import Select from '@client/components/Select';
import dynamicDelay from '@client/utils/dynamicDelay';
import showErrorNotification from '@client/utils/showErrorNotification';
import { updateUser } from '@client/lib/api';
import logo from '@client/assets/images/logo.png';
import { useAuth } from '@client/lib/auth';
import { Links } from '@client/utils/links';
import getAvailableDepartments from '@client/utils/getAvailableDepartments';
import NavbarMenu from './NavbarMenu';
import styles from './Navbar.module.css';

const Navbar = ({
	setLoadingInfo,
	onCollapse,
}) => {
	const history = useHistory();

	const {
		isAdmin,
		userInfo,
		refreshAuthInfo,
	} = useAuth();

	const activeDepartment = useMemo(() => userInfo.activeDepartment, [userInfo]);

	const selectActiveDepartment = async (newActiveDepartment) => {
		try {
			const departmentName = Object.values(Departments)
				.find((t) => t.key === newActiveDepartment)
				.name;
			setLoadingInfo({
				loading: true,
				text: `Switching to ${departmentName}...`,
			});
			await dynamicDelay(async () => {
				await updateUser({
					attributes: { activeDepartment: newActiveDepartment },
				});
				await refreshAuthInfo();
				history.push(Links.Home.get());
			}, 1664);
		} catch (error) {
			console.error(error);
			showErrorNotification('Could not switch department', error);
		} finally {
			setLoadingInfo({
				loading: false,
				text: null,
			});
		}
	};

	return (
		<Layout.Sider
			className={styles.sidebar}
			breakpoint="lg"
			collapsedWidth="0"
			onCollapse={onCollapse}
		>
			<div className={styles.sidebarHeader}>
				<Link to={Links.Home.get()}>
					<img
						className={styles.logo}
						src={logo}
						alt="logo"
					/>
				</Link>
			</div>
			<div className={styles.activeDepartmentWrapper}>
				<span className={styles.activeDepartmentLabel}>Department:</span>
				<Select
					type="select"
					value={activeDepartment}
					placeholder="Select department..."
					onChange={selectActiveDepartment}
					options={
						Object
							.values(getAvailableDepartments(
								userInfo.isManagement,
								isAdmin,
								userInfo.departments,
							)).map((department) => ({
								label: department.name,
								value: department.key,
							}))
					}
				/>
			</div>
			<NavbarMenu
				activeDepartment={activeDepartment}
				isAdmin={isAdmin}
			/>
		</Layout.Sider>
	);
};

export default Navbar;
