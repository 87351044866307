import React, {
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Descriptions,
	Modal,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump } from '@fortawesome/pro-light-svg-icons';
import Button from '@client/components/Button';
import ConsumptionSets from '@client/screens/fleet/VesselDetailsScreen/components/ConsumptionSets/ConsumptionSets';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import { useEstimate } from '../context/EstimatorContext';
import estimateConfigurationItems from '../helpers/estimateConfigurationItems';
import { useCentralStore } from '../context/CentralStoreContext';
import styles from './styles/EstimateConfigurationCard.module.css';

const EstimateConfigurationCard = () => {
	const [showConsumptionModal, setShowConsumptionModal] = useState(false);
	const [blockClose, setBlockClose] = useState(false);

	const {
		estimate,
		vessel,
		refreshVessel,
		onUpdateEstimate,
		onRefreshEuaPriceMutator,
		allVessels,
	} = useEstimate();

	const {
		refreshEverything,
	} = useCentralStore();

	const items = useMemo(() => estimateConfigurationItems({
		estimate,
		vessel,
		onUpdateEstimate,
		onRefreshEuaPriceMutator,
		allVessels,
		refreshEverything,
	}), [
		estimate,
		vessel,
		onUpdateEstimate,
		onRefreshEuaPriceMutator,
		allVessels,
		refreshEverything,
	]);

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	useBlocker(blockClose);

	const onCloseModal = makeBlockable(() => setShowConsumptionModal(false));

	return (
		<>
			<Card
				styles={{ body: { padding: 0 } }}
				size="small"
				extra={(
					<Button icon={(<FontAwesomeIcon icon={faGasPump} />)} onClick={() => setShowConsumptionModal(true)} size="small">
						Set consumption details
					</Button>
				)}
			>
				<Descriptions
					className={styles.desc}
					size="small"
					bordered
					items={items}
				/>
			</Card>
			<Modal
				open={showConsumptionModal}
				footer={null}
				// eslint-disable-next-line react/forbid-component-props
				style={{ top: 20 }}
				onCancel={onCloseModal}
				okButtonProps={{
					onClick: onCloseModal,
				}}
				width="75%"
			>
				<ConsumptionSets
					vesselId={estimate?.vesselId}
					refreshVessel={refreshVessel}
					activeConsumptionSetId={estimate?.activeConsumptionSetId}
					consumptionSets={vessel?.consumptionSets ?? []}
					inPortEntries={vessel?.inPortEntries}
					secondaryInPortEnabled={vessel?.enableSecondaryInPortConsumption ?? false}
					setEditing={setShowConsumptionModal}
					onRefresh={async () => {
						await refreshEverything();
					}}
					setBlockClose={setBlockClose}
				/>
			</Modal>
		</>

	);
};

export default EstimateConfigurationCard;
