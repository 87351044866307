// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uJqznMks8nJ15sdo9rO7 {\n\tword-wrap: break-word;\n\twhite-space: pre-wrap;\n\twidth: 200px;\n}\n\n.P_TD8kx4m3nGl5GPQt3k {\n\tfont-size: 0.9em;\n\tfont-style: italic;\n}\n\n.hzBhIgOVPvnYfGrCSriV {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-bottom: 24px;\n\toverflow: scroll;\n}\n\n.hzBhIgOVPvnYfGrCSriV:last-child {\n\tmargin-bottom: 0;\n}\n\n.xRmeqQn1nhEjSmagzBkr {\n\tmargin-left: 5px;\n}\n\n.uAXUa4ln89grjdi72ddM {\n\tmargin-right: 5px;\n}\n\n.yaf6PNfnq0M_VpROjucs {\n\tmargin-left: 16px;\n}\n\n.Tu_mV1vmZGXqzjzPK_2E {\n\tmargin-left: 5px;\n\tcolor: #1793ff;\n\tcursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/CommissionsTab/CommissionsTab.module.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,qBAAqB;CACrB,YAAY;AACb;;AAEA;CACC,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,gBAAgB;CAChB,cAAc;CACd,eAAe;AAChB","sourcesContent":[".note {\n\tword-wrap: break-word;\n\twhite-space: pre-wrap;\n\twidth: 200px;\n}\n\n.invoiceIdentifier {\n\tfont-size: 0.9em;\n\tfont-style: italic;\n}\n\n.tableContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-bottom: 24px;\n\toverflow: scroll;\n}\n\n.tableContainer:last-child {\n\tmargin-bottom: 0;\n}\n\n.undoButton {\n\tmargin-left: 5px;\n}\n\n.undoIcon {\n\tmargin-right: 5px;\n}\n\n.dismissButton {\n\tmargin-left: 16px;\n}\n\n.editButton {\n\tmargin-left: 5px;\n\tcolor: #1793ff;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"note": "uJqznMks8nJ15sdo9rO7",
	"invoiceIdentifier": "P_TD8kx4m3nGl5GPQt3k",
	"tableContainer": "hzBhIgOVPvnYfGrCSriV",
	"undoButton": "xRmeqQn1nhEjSmagzBkr",
	"undoIcon": "uAXUa4ln89grjdi72ddM",
	"dismissButton": "yaf6PNfnq0M_VpROjucs",
	"editButton": "Tu_mV1vmZGXqzjzPK_2E"
};
export default ___CSS_LOADER_EXPORT___;
