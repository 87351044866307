import {
	FixtureCounterpartyTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';

type LinkEntry = {
	path: string;
	get: (...params: any[]) => string;
};

type LinkMap = { [key: string]: LinkEntry | LinkMap };

// When creating a new root page, add it here - that way we can find it when adding video guides
export const RootPages = [
	'Accruals',
	'Accounting Mapping',
	'Admin',
	'Brokers',
	'Cargos',
	'Charterers',
	'Company Settings',
	'Contract - TC',
	'Contract - VC',
	'Contracts',
	'Counterparties',
	'Estimate - TC',
	'Estimate - VC',
	'Estimate Comparison',
	'Home',
	'Management',
	'Organizations',
	'Owners',
	'Performance',
	'Postings',
	'Previous Period Closes',
	'Suppliers',
	'Owners',
	'Management',
	'Team Members',
	'Team Members Groups',
	'Performance',
	'Video Guides',
	'Voyages',
	'Vessels',
	'World Map',
] as const;

export const Links = {
	Home: {
		path: '/',
		get: () => '/',
	},
	Organization: {
		path: '/admin/organizations/:id',
		get: (orgId: number) => `/admin/organizations/${orgId}`,
	},
	Organizations: {
		path: '/admin/organizations',
		get: () => '/admin/organizations',
	},
	VideoGuides: {
		path: '/admin/video-guides',
		get: () => '/admin/video-guides',
	},
	FeatureHighlights: {
		path: '/admin/highlights',
		get: () => '/admin/highlights',
	},
	Management: {
		Approvals: {
			path: '/management/approvals',
			get: () => '/management/approvals',
		},
		Settings: {
			path: '/management/settings',
			get: () => '/management/settings',
		},
	},
	Vessel: {
		path: '/vessels/:id',
		get: (vesselId: number) => `/vessels/${vesselId}`,
	},
	Vessels: {
		path: '/vessels',
		get: () => '/vessels',
	},
	Accounting: {
		Mapping: {
			path: '/accounting/mapping',
			get: () => '/accounting/mapping',
		},
		Accruals: {
			path: '/accounting/accruals',
			get: () => '/accounting/accruals',
		},
		PeriodClose: {
			path: '/accounting/period-close',
			get: () => '/accounting/period-close',
		},
		PreviousCloses: {
			path: '/accounting/previous-closes',
			get: () => '/accounting/previous-closes',
		},
	},
	AccountingItems: {
		path: '/ar-ap',
		get: () => '/ar-ap',
	},
	Fixtures: {
		[FixtureTypes.BB_OUT]: {
			List: {
				path: '/estimates/bb-out',
				get: () => '/estimates/bb-out',
			},
			Details: {
				path: '/estimates/bb-out/:id',
				get: (fixtureId: number) => `/estimates/bb-out/${fixtureId}`,
			},
		},
		[FixtureTypes.TC_IN]: {
			List: {
				path: '/estimates/tc-in',
				get: () => '/estimates/tc-in',
			},
			Details: {
				path: '/estimates/tc-in/:id',
				get: (fixtureId: number) => `/estimates/tc-in/${fixtureId}`,
			},
		},
		[FixtureTypes.TC_OUT]: {
			List: {
				path: '/estimates/tc-out',
				get: () => '/estimates/tc-out',
			},
			Details: {
				path: '/estimates/tc-out/:id',
				get: (fixtureId: number) => `/estimates/tc-out/${fixtureId}`,
			},
		},
		[FixtureTypes.SPOT]: {
			List: {
				path: '/estimates/spot',
				get: () => '/estimates/spot',
			},
			Details: {
				path: '/estimates/spot/:groupId/:id',
				get: (
					estimateGroupId: number,
					estimateId: number,
				) => `/estimates/spot/${estimateGroupId}/${estimateId}`,
			},
			CompareEstimates: {
				path: '/estimates/spot/compare',
				// @ts-ignore
				get: (ids: number[]) => `/estimates/spot/compare?ids=${encodeURIComponent(ids)}`,
			},
		},
	},
	Expenses: {
		path: '/expenses',
		get: () => '/expenses',
	},
	Cargos: {
		path: '/cargos',
		get: () => '/cargos',
	},
	Cargo: {
		path: '/cargos/:id',
		get: (cargoId: number) => `/cargos/${cargoId}`,
	},
	ExpensesApproval: {
		path: '/management/approvals',
		get: () => '/management/approvals',
	},
	VoyageInvoice: {
		path: '/contracts/:voyageId/voyage-invoices/:id',
		get: (voyageId: number, invoiceId: number) => `/contracts/${voyageId}/voyage-invoices/${invoiceId}`,
	},
	Voyage: {
		path: '/contracts/:id',
		get: (voyageId: number) => `/contracts/${voyageId}`,
	},
	Voyages: {
		path: '/contracts',
		get: () => '/contracts',
	},
	PerformanceOverview: {
		path: '/performance',
		get: () => '/performance',
	},
	PerformanceDetails: {
		path: '/performance/:id',
		get: (vesselId: number) => `/performance/${vesselId}`,
	},
	Ops: {
		path: '/ops',
		get: () => '/ops',
	},
	OpsDetails: {
		path: '/ops/:vesselId',
		get: (vesselId: number) => `/ops/${vesselId}`,
	},
	Itinerary: {
		path: '/contracts/:voyageId/#/itinerary',
		get: (voyageId: number) => `/contracts/${voyageId}/#/itinerary`,
	},
	Inbox: {
		path: '/inbox',
		get: () => '/inbox',
	},
	Charterer: {
		path: '/charterers/:id',
		get: (chartererId: number) => `/charterers/${chartererId}`,
	},
	Charterers: {
		path: '/charterers',
		get: () => '/charterers',
	},
	Counterparty: {
		path: '/counterparties/:id/:type',
		get: (counterpartyId: number, type: Values<typeof FixtureCounterpartyTypes>) => {
			return `/counterparties/${counterpartyId}/${encodeURIComponent(type)}`;
		},
	},
	Counterparties: {
		path: '/counterparties',
		get: () => '/counterparties',
	},
	Map: {
		path: '/map',
		get: (vesselId?: number) => `/map${vesselId != null ? `?v=${vesselId}` : ''}`,
	},
	Owner: {
		path: '/owners/:id',
		get: (ownerId: number) => `/owners/${ownerId}`,
	},
	Owners: {
		path: '/owners',
		get: () => '/owners',
	},
	Supplier: {
		path: '/suppliers/:id',
		get: (supplierId: number) => `/suppliers/${supplierId}`,
	},
	Suppliers: {
		path: '/suppliers',
		get: () => '/suppliers',
	},
	Broker: {
		path: '/brokers/:id',
		get: (brokerId: number) => `/brokers/${brokerId}`,
	},
	Brokers: {
		path: '/brokers',
		get: () => '/brokers',
	},
	User: {
		path: '/settings/users/:id',
		get: (userId: number) => `/settings/users/${userId}`,
	},
	Users: {
		path: '/settings/users/',
		get: () => '/settings/users/',
	},
	Group: {
		path: '/settings/groups/:id',
		get: (groupId: number) => `/settings/groups/${groupId}`,
	},
	Groups: {
		path: '/settings/groups/',
		get: () => '/settings/groups/',
	},
	AuditTrail: {
		path: '/audit-trail',
		get: () => '/audit-trail',
	},
	Development: {
		path: '/dev',
		get: () => '/dev',
	},
};

// If this has a type error, that means an entry in Links has the wrong type.
// We do it this way instead of typing it directly to preserve get function parameters.
// Otherwise, they would just show up as "(...any[]) => string" when used.
const _linksTypeCheck: LinkMap = Links;
