export type StyleColor = {
	color: string;
};

const styleColorPicker = (value: number | null, reverse: boolean = false): StyleColor => {
	let color: string = 'black';

	if (value == null) {
		return { color };
	}

	const newVal = reverse ? value * -1 : value;

	if (newVal < 0) {
		color = 'red';
	}

	if (newVal > 0) {
		color = 'green';
	}

	return { color };
};

export default styleColorPicker;
