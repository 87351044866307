import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { SpinProps } from 'antd/lib/spin';
import styles from './styles/LoadingIndicator.module.css';

type LoadingIndicatorProps = {
	containerClassName?: string;
	fullHeight?: boolean;
	classNameOverride?: string;
} & SpinProps;

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	containerClassName,
	fullHeight = false,
	classNameOverride,
	...props
}) => {
	const className = classNameOverride != null ?
		classNameOverride :
		classNames(styles.container, containerClassName, {
			[styles.fullHeight]: fullHeight,
		});

	return (
		<div
			className={className}
		>
			<Spin {...props} />
		</div>
	);
};

export default LoadingIndicator;

