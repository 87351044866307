// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WBl4IuOteYYHJKyg86on .cg_deZ76Q_ygqEcFuQtu {\n\tmargin-bottom: 0;\n}\n\n.UmkQZYNU74Ojyxqi9RZS {\n\tdisplay: block;\n\tmargin-bottom: 8px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fixtures/shared/styles/FixButton.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,cAAc;CACd,kBAAkB;AACnB","sourcesContent":[".missingSection .missingTitle {\n\tmargin-bottom: 0;\n}\n\n.missingLink {\n\tdisplay: block;\n\tmargin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"missingSection": "WBl4IuOteYYHJKyg86on",
	"missingTitle": "cg_deZ76Q_ygqEcFuQtu",
	"missingLink": "UmkQZYNU74Ojyxqi9RZS"
};
export default ___CSS_LOADER_EXPORT___;
