import {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Moment } from 'moment';
import HIIHirePeriod from '@shared/hireInvoice/HIIHirePeriod';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import { toMoment } from '@shared/utils/date';

type HirePeriodField = 'hireType' | 'hireRate' | 'hireDescription';
type UpdateHirePeriodProperty = (
	primaryHirePeriod: boolean,
	field: HirePeriodField,
	value: any,
) => void;

// Internally we also want to allow updating `to`
type InternalUpdateHirePeriodProperty = (
	primaryHirePeriod: boolean,
	field: HirePeriodField | 'to',
	value: any,
) => void;

type UpdateHirePeriodEnd = (date: Moment) => void;

type UseHirePeriodSplit = (
	items: HireInvoiceItem[] | [],
	splitDate?: Moment | null
) => [
    itemsWithHirePeriods: HireInvoiceItem[],
    hirePeriods: HIIHirePeriod[],
	splitDate: Moment | null,
	setSplitDate: (splitDate: Moment | null) => void,
    updateHirePeriodProperty: UpdateHirePeriodProperty,
    updateHirePeriodEnd: UpdateHirePeriodEnd,
	updateExpenseSubjectToHireDays: (
		hirePeriodId: number,
		expenseId: number,
		amount: number | null,
		overrideTotal?: boolean,
	) => void,
];

const useHirePeriodSplit: UseHirePeriodSplit = (items) => {
	const [splitDate, setSplitDate] = useState<Moment | null>(null);
	const [internalItems, setInternalItems] = useState<HireInvoiceItem[]>(items);

	useEffect(() => {
		// Always keep internal items up to date
		setInternalItems(items);
		setSplitDate(null);
	}, [items]);

	useEffect(() => {
		const itemsWithoutHire = internalItems.filter((i) => !(i instanceof HIIHirePeriod));
		const hirePeriods = internalItems.filter(
			(i) => (i instanceof HIIHirePeriod),
		) as HIIHirePeriod[];
		const numberOfHirePeriods = hirePeriods.length;

		// If split date is set to null and there is more than one hire period
		// Combine the hire periods
		if (splitDate == null && numberOfHirePeriods > 1) {
			const newItem = hirePeriods[0].copy();

			newItem.to = hirePeriods[1].to;

			setInternalItems([
				newItem,
				...itemsWithoutHire,
			]);
		// If split date is set to a date and there is only one hire period
		// Split it into 2
		} else if (splitDate != null && numberOfHirePeriods === 1) {
			const firstPart = hirePeriods[0].copy();
			const secondPart = hirePeriods[0].copy();

			secondPart.id += Math.random();

			firstPart.to = splitDate;
			secondPart.from = splitDate;

			secondPart.children.forEach((c: HireInvoiceItem) => {
				c.parentId = secondPart.id;
			});

			setInternalItems([
				firstPart,
				secondPart,
				...itemsWithoutHire,
			]);
		// If split date is set and there are already two hire periods
		// Just update the date of the split, unless it's already the correct date
		} else if (
			splitDate != null &&
			numberOfHirePeriods > 1 &&
			toMoment(hirePeriods[0].to).diff(splitDate) !== 0
		) {
			const firstPart = hirePeriods[0].copy();
			const secondPart = hirePeriods[1].copy();

			firstPart.to = splitDate;
			secondPart.from = splitDate;

			setInternalItems([
				firstPart,
				secondPart,
				...itemsWithoutHire,
			]);
		}
	// Only update this when hire period split date is changed
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [internalItems, splitDate]);

	const updateHirePeriodProperty = useCallback<InternalUpdateHirePeriodProperty>(
		(primaryHirePeriod, field, value) => {
			const [primary, secondary] = internalItems.filter((i) => (i instanceof HIIHirePeriod));
			const hireItem = (primaryHirePeriod ? primary : secondary) as HIIHirePeriod;

			const newHire = hireItem.copy();
			newHire[field] = value;

			const newItems = [...internalItems];

			newItems.splice(
				internalItems.indexOf(hireItem as HireInvoiceItem),
				1,
				newHire,
			);

			setInternalItems(newItems);
		},
		[internalItems],
	);

	const updateExpenseSubjectToHireDays = useCallback((
		hirePeriodId: number,
		expenseId: number,
		amount: number | null,
		overrideTotal?: boolean,
	) => {
		if (amount == null) {
			return;
		}

		const hirePeriod = internalItems.find((item) => item.id === hirePeriodId)?.copy();
		const expense = hirePeriod?.children.find((child: HireInvoiceItem) => child.id === expenseId)?.copy();

		if (overrideTotal != null && overrideTotal) {
			expense.totalOverride = amount;
		} else {
			expense.amount = amount;
		}

		hirePeriod.children.splice(
			hirePeriod.children.findIndex((i: HireInvoiceItem) => i.id === expenseId),
			1,
			expense,
		);

		const newItems = [...internalItems];

		newItems.splice(
			internalItems.findIndex((i) => i.id === hirePeriodId),
			1,
			hirePeriod,
		);

		setInternalItems(newItems);
	}, [internalItems]);

	const hirePeriods = useMemo(() => internalItems.filter((i) => (
		i instanceof HIIHirePeriod
	)) as HIIHirePeriod[], [internalItems]);

	const updateHirePeriodEnd = useCallback<UpdateHirePeriodEnd>((date) => {
		updateHirePeriodProperty(hirePeriods[1] == null, 'to', date);
	}, [hirePeriods, updateHirePeriodProperty]);

	return [
		internalItems,
		hirePeriods,
		splitDate,
		setSplitDate,
		updateHirePeriodProperty,
		updateHirePeriodEnd,
		updateExpenseSubjectToHireDays,
	];
};

export default useHirePeriodSplit;
