import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles/TopBarButton.module.css';

const TopBarButton = ({
	children,
	icon,
	...props
}: {
	children?: React.ReactElement | string | null;
	icon: ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
	<button
		className={styles.button}
		type="button"
		{...props}
	>
		<div
			className={classNames(styles.icon, {
				[styles.hasLabel]: !!children,
			})}
		>
			{icon}
		</div>
		{children}
	</button>
);

export default TopBarButton;
