import React, { useState } from 'react';
import { Moment } from 'moment';
import { ColumnsType } from 'antd/es/table';
import { Modal } from 'antd';
import {
	AccrualItemClosingPeriodFormats,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import type { VesselProps } from '@api/models/vessel';
import type { VoyageProps } from '@api/models/voyage';
import type { GetClosedPeriodsResponse } from '@api/features/financials/getClosedPeriods';
import { formatDate } from '@client/utils/formatDate';
import Card from '@client/components/Card/Card';
import Table from '@client/components/Table/Table';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getPreviousCloses } from '@client/lib/api';
import Button from '@client/components/Button';
import AccrualsTable from '../AccrualsScreen/AccrualsTable';

const PreviousClosesScreen = () => {
	const [selectedPeriod, setSelectedPeriod] = useState<
		GetClosedPeriodsResponse[number] | null
	>(null);
	const [data] = useFetchedState(getPreviousCloses);

	const getModalTitle = (period: GetClosedPeriodsResponse[number] | null) => {
		if (period == null) {
			return '';
		}

		if (period.closeDateFormat === AccrualItemClosingPeriodFormats.MONTH_AND_YEAR) {
			return `Accrual items for closed period ${period.period}`;
		}

		return `Accrual items for closed year ${period.period}`;
	};

	const columns: ColumnsType<GetClosedPeriodsResponse[number]> = [
		{
			title: '',
			dataIndex: 'link',
			render: (_val, row) => {
				return (
					<Button
						type="link"
						onClick={() => setSelectedPeriod(row)}
					>
						See details
					</Button>
				);
			},
			width: 100,
		},
		{
			title: 'Period',
			dataIndex: 'period',
		},
		{
			title: 'Vessel',
			dataIndex: 'vessel',
			render: (vessel: VesselProps | undefined) => (
				vessel != null ?
					(<LinkButton url={Links.Vessel.get(vessel.id)}>{vessel.name}</LinkButton>) :
					''),
		},
		{
			title: 'Contract(s)',
			dataIndex: 'contracts',
			render: (voyages: Array<VoyageProps>) => {
				return voyages
					.sort((a, b) => a.identifier.localeCompare(b.identifier))
					.map((voyage, index) => {
						return (
							<LinkButton
								url={Links.Voyage.get(voyage.id)}
							>
								{`${voyage.identifier}${index === voyages.length - 1 ? '' : ', '}`}
							</LinkButton>
						);
					});
			},
		},
		{
			title: 'Date Closed',
			dataIndex: 'dateClosed',
			render: (c: Moment) => formatDate(c, DATE_AND_TIME),
		},
		{
			title: 'Closed By',
			dataIndex: 'closedBy',
		},
	];

	return (
		<SimpleScreen
			title="Previous Period Closes"
			rootPageTitle="Previous Period Closes"
		>
			<Modal
				title={getModalTitle(selectedPeriod)}
				open={selectedPeriod != null}
				onCancel={() => setSelectedPeriod(null)}
				footer={null}
				width="100%"
			>
				{selectedPeriod != null && (
					<AccrualsTable
						accrualItems={selectedPeriod.items}
						inModal
						columnsToHide={['shouldProrate']}
					/>
				)}
			</Modal>
			<Card
				slim
			>
				<Table
					columns={columns}
					pagination={false}
					dataSource={data ?? []}
					onRowClick={setSelectedPeriod}
				/>
			</Card>
		</SimpleScreen>
	);
};

export default PreviousClosesScreen;
