import React, {
	useCallback,
	useRef,
	useState,
} from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
	Card,
	Space,
} from 'antd';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import Table, { TableProps } from '../Table/Table';
import ErrorPage from '../ErrorPage';
import FormPopover, { FormPopoverProps } from '../FormPopover';
import TableEmptyText from '../Table/TableEmptyText';
import RootScreen, { RootScreenProps } from './RootScreen';
import styles from './styles/ListTableScreen.module.css';

type ListTableScreenProps<TableData, FormData> = ExtendUnique<{
	title: React.ReactNode;
	columns: TableProps<TableData>['columns'];
	data: TableProps<TableData>['dataSource'];
	loading: boolean;
	error: Error | null;
	children?: React.ReactElement | Array<React.ReactElement>;
	formFields?: FormPopoverProps<FormData>['fields'];
	formButtonText?: React.ReactNode;
	onFormSubmit?: (values: FormData) => void;
	redirectOnSubmit?: boolean;
	pagination?: boolean;
	getRowHref: TableProps<TableData>['getRowHref'];
	tabs?: Array<{
		key: string;
		tab: React.ReactNode;
		disabled?: boolean;
		filter: (data: TableData, index: number) => boolean;
		emptyTabTitle: string;
		emptyTabDescription: string;
		showEmptyTabArrow: boolean;
	}> | null;
	showEmptyArrow: boolean;
	useTabsEmptyText?: boolean;
	emptyTitle: React.ReactNode;
	emptyDescription?: React.ReactNode;
	extraButtonContent?: React.ReactNode;
	extraContent?: React.ReactNode;
	emptyIcon: any;
	formProps?: Partial<FormPopoverProps<FormData>>;
	tableProps?: Partial<TableProps<TableData>>;
}, RootScreenProps>;

const ListTableScreen = <TableData extends object, FormData extends object>({
	title,
	columns,
	data,
	loading,
	error,
	formFields,
	formButtonText,
	onFormSubmit,
	redirectOnSubmit = true,
	// Set to true to use default Ant pagination settings
	pagination = false,
	getRowHref,
	tabs,
	formProps = {},
	tableProps: {
		rowKey = 'id',
		...tableProps
	} = {},
	showEmptyArrow = false,
	useTabsEmptyText = false,
	emptyTitle,
	emptyDescription,
	emptyIcon,
	extraButtonContent,
	extraContent,
	...props
}: ListTableScreenProps<TableData, FormData>) => {
	const history = useHistory();

	const newButtonRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
	const cardRef = useRef<HTMLDivElement>(null);

	const hasTabs = tabs != null;

	const [activeTab, setActiveTab] = useState(hasTabs ? tabs[0].key : tabs);

	const submitRedirect = useCallback((row: TableData | null) => {
		if (row == null || getRowHref == null) {
			return;
		}

		history.push(getRowHref(row));

		// This should change as little as possible, so ignore getRowHref...
		// It should be pure and not change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);

	const getTableData = useCallback(() => {
		if (loading) {
			return [];
		}

		if (error) {
			return [];
		}

		if (!hasTabs) {
			return data;
		}

		const tab = tabs.find((t) => t.key === activeTab);

		if (tab == null) {
			return data;
		}

		return (data ?? []).filter(tab.filter);
	}, [loading, error, hasTabs, tabs, data, activeTab]);

	return (
		<RootScreen {...props}>
			<PageHeader
				title={title}
				className={styles.header}
				extra={extraContent != null ? (
					<Space>
						{ extraContent }
					</Space>
				) : (loading || formFields == null || onFormSubmit == null) ? null : (
					<Space>
						{extraButtonContent}
						<FormPopover
							key="create"
							onSubmit={onFormSubmit}
							buttonText={formButtonText}
							buttonRef={newButtonRef}
							title={formButtonText}
							fields={formFields}
							afterSubmit={redirectOnSubmit ? submitRedirect : undefined}
							{...formProps}
						/>
					</Space>
				)}
			/>
			{error ? (
				<ErrorPage error={error} />
			) : (
				<div ref={cardRef}>
					{tableProps.useCards && !tabs ? (
						<Table<TableData>
							columns={columns}
							dataSource={getTableData()}
							loading={loading}
							rowKey={rowKey}
							getRowHref={getRowHref}
							pagination={pagination === true ? undefined : pagination}
							{...tableProps}
						/>
					) : (
						<Card
							activeTabKey={activeTab ?? undefined}
							onTabChange={setActiveTab}
							tabList={tabs ?? undefined}
							className={classNames({
								[styles.grey]: tableProps.useCards && tabs != null,
								[styles.tableCard]: !tableProps.useCards,
							})}
						>
							<Table<TableData>
								columns={columns}
								dataSource={getTableData()}
								loading={loading}
								rowKey={rowKey}
								getRowHref={getRowHref}
								pagination={pagination === true ? undefined : pagination}
								emptyText={emptyTitle == null ? undefined : (
									<TableEmptyText
										loading={loading}
										icon={emptyIcon}
										title={
											useTabsEmptyText ?
												tabs?.filter(
													(tab) => tab.key === activeTab,
												)[0].emptyTabTitle : emptyTitle
										}
										description={
											useTabsEmptyText ? tabs?.filter(
												(tab) => tab.key === activeTab,
											)[0].emptyTabDescription : emptyDescription
										}
										showArrow={
											useTabsEmptyText ? tabs?.filter(
												(tab) => tab.key === activeTab,
											)[0].showEmptyTabArrow : showEmptyArrow
										}
										arrowTargetRef={newButtonRef}
										containerRef={cardRef}
										hasTabs={hasTabs}
									/>
								)}
								{...tableProps}
							/>
						</Card>
					)}
				</div>
			)}
		</RootScreen>
	);
};

export default ListTableScreen;
