// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g9j20eVeIkcoyJF4L5wX {\n\tdisplay: block;\n}\n\n.yMrqTYbN2PicGnG7YG_A {\n\tdisplay: flex;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/CountryFlag.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;AACf;;AAEA;CACC,aAAa;AACd","sourcesContent":[".flag {\n\tdisplay: block;\n}\n\n.flex {\n\tdisplay: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flag": "g9j20eVeIkcoyJF4L5wX",
	"flex": "yMrqTYbN2PicGnG7YG_A"
};
export default ___CSS_LOADER_EXPORT___;
