import React from 'react';
import { Badge } from 'antd';
import Button from '@client/components/Button';
import { getStringModuloColor } from '@client/utils/colors';
import {
	Tag,
	Tags,
} from '@client/components/AttachmentLibrary';
import styles from './styles/TagFilter.module.css';

type Props = {
	tags: Tags;
	value: any;
	onChange: (t: Tags) => void;
}

const TagFilter: React.FC<Props> = ({
	tags,
	value,
	onChange,
}) => {
	const toggle = (tag: Tag) => {
		let newValue;

		if (value.includes(tag)) {
			newValue = value.filter((t: Tag) => t !== tag);
		} else {
			newValue = [...value, tag];
		}

		onChange(newValue);
	};

	if (tags == null || tags.length === 0) {
		return null;
	}

	return (
		<div className={styles.categoryWrapper}>
			<span className={styles.categoryLabel}>
				<b>Tags</b>
			</span>
			{tags.map((tag: Tag) => {
				const color = tag != null ? getStringModuloColor(tag) : 'geekblue';

				return (
					<Button
						key={tag}
						className={styles.button}
						type={value.includes(tag) ? 'primary' : 'default'}
						onClick={() => toggle(tag)}
						size="small"
					>
						<Badge color={color} />
						{tag}
					</Button>
				);
			})}
		</div>
	);
};

export default TagFilter;
