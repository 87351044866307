import React from 'react';
import {
	Card,
	Empty,
} from 'antd';
import {
	FixtureTypeLabels,
	FixtureTypes,
} from '@shared/utils/constants';
import type { Values } from '@shared/utils/objectEnums';
import { toMoment } from '@shared/utils/date';
import type { VesselTimelineData } from '@api/utils/sequelize/getVesselTimelineData';
import { ChartColors } from '@client/utils/constants';
import Timeline, {
	Item,
	SectionItems,
} from '@client/components/Timeline';
import { Links } from '@client/utils/links';
import styles from './styles/VesselTimeline.module.css';

type InputFilter = [Values<typeof FixtureTypes>, Values<typeof FixtureTypes>];

type TimelineProps = {
	data: VesselTimelineData;
	monthsToShow?: number;
}

const VesselTimeline: React.FC<TimelineProps> = ({
	data,
	...props
}) => {
	if (data == null || data.fixtures.length === 0) {
		return (<Empty description="Vessel has no fixtures to show" />);
	}

	const getItems = (
		fixtures: VesselTimelineData['fixtures'],
		ownershipPeriods: VesselTimelineData['ownershipPeriods'],
	) => fixtures.reduce((acc: Item[], fixture) => [
		...acc,
		...ownershipPeriods.map((p) => (
			{
				id: `${p.id}-owned`,
				link: Links.Vessel.get(fixture.vesselId),
				period: [toMoment(p.fromDate), p.toDate == null ? toMoment(p.fromDate).add(100, 'years') : toMoment(p.toDate)],
				backgroundColor: ChartColors.GREY,
			}
		)),
		{
			id: `${fixture.id}-redelivery`,
			period: fixture.maxPeriod,
			link: Links.Voyage.get(fixture.voyageId),
			backgroundColor: ChartColors.LIGHTBLUE,
		},
		{
			id: `${fixture.id}-laycan`,
			period: fixture.laycanPeriod,
			link: Links.Voyage.get(fixture.voyageId),
			backgroundColor: ChartColors.GREEN,
		},
		{
			id: `${fixture.id}-fixed`,
			period: fixture.minPeriod,
			label: fixture.identifier,
			link: Links.Voyage.get(fixture.voyageId),
			backgroundColor: ChartColors.BLUE,
		},
	], []);

	const getSections = (filters: InputFilter) => filters.reduce((arr: SectionItems, fixtureType) => {
		const filteredFixtures = data.fixtures.filter((i) => i.type === fixtureType);

		if (filteredFixtures.length === 0) {
			return arr;
		}

		return [
			...arr,
			{
				label: FixtureTypeLabels[fixtureType],
				items: getItems(filteredFixtures, data.ownershipPeriods),
			},
		];
	}, []);

	const sections = getSections([
		FixtureTypes.TC_IN,
		FixtureTypes.TC_OUT,
	]);

	return (
		<Card className={styles.timelineCard}>
			<Timeline
				className={styles.vesselTimeline}
				sections={sections}
				legend={[
					{
						label: 'Owned Period',
						fill: ChartColors.GREY,
					},
					{
						label: 'Fixed Period',
						fill: ChartColors.BLUE,
					},
					{
						label: 'Redelivery Period',
						fill: ChartColors.LIGHTBLUE,
					},
					{
						label: 'Laycan Period',
						fill: ChartColors.GREEN,
					},
				]}
				{...props}
			/>
		</Card>
	);
};

export default VesselTimeline;
