import React from 'react';
import GeoJsonLayer from './GeoJsonLayer';

const RouteLayer = ({
	id,
	data,
	loading,
	lineColor = '#BF93E4',
}) => (
	<GeoJsonLayer
		id={id}
		data={data}
		type="line"
		before="destination"
		layerStyle={{
			layout: {
				'line-join': 'round',
			},
			paint: {
				'line-color': loading ? '#CCC' : lineColor,
				'line-width': 3,
			},
		}}
	/>
);

export default RouteLayer;
