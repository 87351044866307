import React, {
	useCallback,
	useMemo,
} from 'react';
import {
	Card,
	Col,
	Grid,
	notification,
	Row,
} from 'antd';
import { faAddressBook } from '@fortawesome/pro-duotone-svg-icons';
import { ColumnType } from 'antd/es/table';
import { FixtureCounterpartyTypes } from '@shared/utils/constants';
import { standardSort } from '@shared/utils/standardSort';
import { Values } from '@shared/utils/objectEnums';
import { fixtureCounterpartyTypeToName } from '@shared/utils/fixtureUtils';
import type { FixtureCounterpartyProps } from '@api/models/fixture-counterparty';
import type { SupplierProps } from '@api/models/supplier';
import type { BrokerProps } from '@api/models/broker';
import {
	getBrokers,
	getCounterparties,
	getSuppliers,
	createCounterparty,
	createSupplier,
	createBroker,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { renderDate } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';

type CounterpartyProps = SupplierProps | FixtureCounterpartyProps | BrokerProps;

const createGenericCounterparty = (
	props: CounterpartyProps,
	type: Values<typeof FixtureCounterpartyTypes>,
): GenericCounterparty => {
	return {
		...props,
		// Since we might have same id's from different entties,
		// a prefix is added to satisfy Reacts unique keys
		id: `${type.toLowerCase()}_${props.id}`,
		genericId: props.id,
	};
};

interface GenericCounterparty {
	id: string;
	genericId: number;
	organizationId: number;
	name: string;
	companyName: string | null;
	address: string | null;
	city: string | null;
	country: string | null;
	phoneNumberOffice: string | null;
	phoneNumberMobile: string | null;
	email: string | null;
	invoiceAddress: string | null;
	invoicePhoneNumber: string | null;
	invoiceEmail: string | null;
	pointOfContactName: string | null;
	pointOfContactEmail: string | null;
	pointOfContactPhoneNumber: string | null;
	phoneNumber: string | null;
	placeholder: boolean;
	type: Values<typeof FixtureCounterpartyTypes>;
}

const CounterpartiesListScreen: React.FC = () => {
	const screens = Grid.useBreakpoint();
	const [counterparties, reloadCounterparties,
		counterpartiesError, counterpartiesLooading] = useFetchedState(getCounterparties);
	const [brokers, reloadBrokers,
		brokersError, brokersLoading] = useFetchedState(getBrokers);
	const [suppliers, reloadSuppliers,
		suppliersError, suppliersLoading] = useFetchedState(getSuppliers);

	const isLoading = counterpartiesLooading || brokersLoading || suppliersLoading;
	const hasError = counterpartiesError || brokersError || suppliersError;

	const createNewCounterparty = useCallback(async ({ type, name }:
	{ type: Values<typeof FixtureCounterpartyTypes>; name: string }) => {
		let result = null;
		switch (type) {
			case FixtureCounterpartyTypes.BROKER:
				result = createGenericCounterparty(
					await createBroker(name), FixtureCounterpartyTypes.BROKER,
				);
				await reloadBrokers();
				break;
			case FixtureCounterpartyTypes.SUPPLIER:
				result = createGenericCounterparty(
					await createSupplier(name), FixtureCounterpartyTypes.SUPPLIER,
				);
				await reloadSuppliers();
				break;
			default:
				result = createGenericCounterparty(
					await createCounterparty(
						FixtureCounterpartyTypes.CHARTEREROWNER, name,
					), FixtureCounterpartyTypes.CHARTEREROWNER,
				);
				await reloadCounterparties();
		}

		notification.success({
			message: 'Counterparty created',
			description: `${name} has been created.`,
		});

		return result;
	}, [reloadBrokers, reloadCounterparties, reloadSuppliers]);

	const combinedModel = useMemo(() => {
		if (counterparties == null || brokers == null || suppliers == null) {
			return [];
		}

		return [
			...brokers.map((b) => createGenericCounterparty(
				b as BrokerProps, FixtureCounterpartyTypes.BROKER,
			)),
			...counterparties.map((c) => createGenericCounterparty(c, c.type)),
			...suppliers.map((s) => createGenericCounterparty(s, FixtureCounterpartyTypes.SUPPLIER)),
		];
	}, [brokers, counterparties, suppliers]);

	const filterMaps = useMemo(() => {
		const cities = new Set<string>();
		const countries = new Set<string>();
		const types = new Set<string>();
		combinedModel?.forEach((item) => {
			if (item.city != null) {
				cities.add(item.city);
			}

			if (item.country != null) {
				countries.add(item.country);
			}

			if (item.type != null) {
				types.add(item.type);
			}
		});

		return {
			cities: Array.from(cities),
			countries: Array.from(countries),
			types: Array.from(types),
		};
	}, [combinedModel]);

	const columns: ColumnType<GenericCounterparty>[] = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			defaultSortOrder: 'ascend',
			sorter: standardSort('name'),
			render: (row) => row ?? '-',
		},
		{
			title: 'Point of contact',
			render: (row: GenericCounterparty) => (
				row.pointOfContactName || row.pointOfContactEmail || row.pointOfContactPhoneNumber ?
					(
						<>
							{row.pointOfContactName}
							<br />
							{row.pointOfContactEmail}
							<br />
							{row.pointOfContactPhoneNumber}
						</>
					) :
					'-'
			),
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			sorter: standardSort('address'),
			render: (row) => row ?? '-',
		},
		{
			title: 'City',
			dataIndex: 'city',
			key: 'city',
			filters: filterMaps.cities.map((city) => ({
				text: city,
				value: city,
			})),
			onFilter: (value: any, record: GenericCounterparty) => record.country === value,
			sorter: standardSort('city'),
			render: (row) => row ?? '-',
		},
		{
			title: 'Country',
			dataIndex: 'country',
			key: 'country',
			filters: filterMaps.countries.map((country) => ({
				text: country,
				value: country,
			})),
			onFilter: (value: any, record: GenericCounterparty) => record.country === value,
			sorter: standardSort('country'),
			render: (row) => row ?? '-',
		},
		{
			title: 'Phone number',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			sorter: standardSort('phoneNumber'),
			render: (row) => row ?? '-',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			sorter: standardSort('email'),
			render: (row) => row ?? '-',
		},
		{
			title: 'Last updated',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sorter: standardSort('updatedAt'),
			render: renderDate() ?? '-',
		},
		{
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			filters: filterMaps.types.map((type) => ({
				text: fixtureCounterpartyTypeToName(type as Values<typeof FixtureCounterpartyTypes>),
				value: type,
			})),
			onFilter: (value: any, record: GenericCounterparty) => record.type === value,
			sorter: standardSort('type'),
			render: (row) => (row ? fixtureCounterpartyTypeToName(row) : '-'),
		},
	];

	if (brokersLoading || counterpartiesLooading || suppliersLoading) {
		return (
			<Row gutter={[16, 16]} align="middle" justify="center">
				<Col span={12}>
					<div style={{ padding: 16 }}>
						<Card title="Loading">
							Counterparties is loading
							<br />
							Please wait
						</Card>
					</div>
				</Col>
			</Row>
		);
	}

	return (
		<ListTableScreen
			title="Counterparties"
			rootPageTitle="Counterparties"
			columns={columns}
			loading={isLoading}
			error={hasError}
			tableProps={{ useCards: screens.xs }}
			data={combinedModel}
			formFields={[
				{
					label: 'Name',
					name: 'name',
					type: 'text',
					required: true,
				},
				{
					label: 'Type',
					name: 'type',
					type: 'select',
					options: Object.values(FixtureCounterpartyTypes).map((t) => ({
						label: t,
						value: t,
					})),
					required: true,
				},
			]}
			formButtonText="Create counterparty"
			onFormSubmit={createNewCounterparty}
			getRowHref={(row) => Links.Counterparty.get(row.genericId, row.type)}
			showEmptyArrow
			emptyTitle="You have not registered any counterparties yet"
			emptyDescription="Click the button to create one"
			emptyIcon={faAddressBook}
		/>
	);
};

export default CounterpartiesListScreen;
