// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8atFvAj719d2dXjazOD {\n\tword-wrap: break-word;\n\tmax-width: 200px;\n}\n\n.kz911uhPdyyThQez6grp {\n\twidth: 102px;\n}\n\n.nU04RDpS5myjUzg4_Wx2 {\n\t/* white-space: pre-wrap; https://github.com/ClearVoyage/clearvoyage/pull/1746 */\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/helpers/styles/getCumulativeComparisonColumns.module.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;CACrB,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gFAAgF;AACjF","sourcesContent":[".note {\n\tword-wrap: break-word;\n\tmax-width: 200px;\n}\n\n.actionButton {\n\twidth: 102px;\n}\n\n.description {\n\t/* white-space: pre-wrap; https://github.com/ClearVoyage/clearvoyage/pull/1746 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"note": "_8atFvAj719d2dXjazOD",
	"actionButton": "kz911uhPdyyThQez6grp",
	"description": "nU04RDpS5myjUzg4_Wx2"
};
export default ___CSS_LOADER_EXPORT___;
