import React, { useMemo } from 'react';
import {
	Grid,
	Tag,
} from 'antd';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons';
import sortBy from 'lodash.sortby';
import {
	DATE_AND_TIME,
	FixtureStates,
	FixtureTypeLabels,
} from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import { standardSort } from '@shared/utils/standardSort';
import { formatDate } from '@client/utils/formatDate';
import VesselDescription from '@client/components/VesselDescription';
import { getFilterProps } from '@client/utils/table';
import {
	getVessels,
	getVoyages,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getFixtureStateColor } from '@client/utils/colors';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';
import { useAuth } from '@client/lib/auth';
import { vesselIdentifierAndNameSorter } from '../VoyageDetailsScreen/helpers/vesselIdentifierAndNameSorter';

const VoyagesListScreen = () => {
	const [
		voyages,
		_refreshVoyages,
		error,
		loadingVoyages,
	] = useFetchedState(getVoyages);
	const [vessels] = useFetchedState(getVessels);
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const columns = useMemo(() => [
		{
			title: 'Status',
			dataIndex: 'state',
			key: 'state',
			align: 'center',
			render: (state) => (
				<Tag color={getFixtureStateColor(state)}>
					{capitalize(state)}
				</Tag>
			),
		},
		{
			title: 'Type',
			dataIndex: 'fixtureType',
			key: 'fixtureType',
			render: (type) => FixtureTypeLabels[type],
		},
		{
			title: 'Vessel name',
			dataIndex: 'vesselName',
			key: 'vesselName',
			sorter: (a, b, type) => vesselIdentifierAndNameSorter(a, b, 'vesselName', type),
			filteredValue: userInfo.filterVessels ? userInfo.relevantVessels.map((v) => v.name) : [],
			...getFilterProps(sortBy((vessels ?? []), 'name').map((v) => ({ vesselName: v.name })), 'select', 'vesselName'),
			render: (_, voyage) => (
				<VesselDescription
					name={voyage.vesselName}
					flag={voyage.vesselFlag}
				/>
			),
		},
		{
			title: 'Identifier',
			dataIndex: 'identifier',
			key: 'identifier',
			sorter: (a, b, type) => vesselIdentifierAndNameSorter(a, b, 'identifier', type),
		},
		{
			title: 'Charterer / Owner',
			dataIndex: 'counterpartyName',
			key: 'counterpartyName',
			sorter: standardSort('counterpartyName'),
			...getFilterProps(voyages ?? [], 'select', 'counterpartyName', 'desc'),
		},
		{
			title: 'Commencement date',
			dataIndex: 'commencementDate',
			key: 'commencementDate',
			sorter: standardSort('commencementDate'),
			render: (value) => (
				formatDate(value, DATE_AND_TIME, true)
			),
		},
		{
			title: 'Completion date',
			dataIndex: 'completionDate',
			key: 'completionDate',
			sorter: standardSort('completionDate'),
			render: (value) => (
				formatDate(value, DATE_AND_TIME, true)
			),
		},
	], [userInfo, vessels, voyages]);

	const isLoading = loadingVoyages;

	return (
		<ListTableScreen
			title="Contracts"
			rootPageTitle="Contracts"
			columns={columns}
			loading={isLoading}
			error={error}
			tableProps={{ useCards: screens.xs }}
			data={voyages}
			getRowHref={(row) => Links.Voyage.get(row.id)}
			tabs={[{
				tab: 'Active',
				key: 'active',
				filter: (v) => v.state !== FixtureStates.COMPLETED,
				emptyTabTitle: 'You do not have any contracts yet',
				emptyTabDescription: 'They will be available here after an estimate has been fixed by the chartering team', // TODO
			},
			{
				tab: 'Completed',
				key: 'completed',
				filter: (v) => v.state === FixtureStates.COMPLETED,
				emptyTabTitle: 'You do not have any completed contracts yet',
				emptyTabDescription: 'Once a contract is complete, it will appear here',
			}]}
			useTabsEmptyText
			emptyTitle="You do not have any contracts yet"
			emptyDescription="They will be available here after an estimate has been fixed by the chartering team"
			emptyIcon={faRoute}
		/>
	);
};

export default VoyagesListScreen;
