import React from 'react';
import * as FlagComponents from 'react-flags-select';
import {
	Space,
	Tooltip,
} from 'antd';
import classNames from 'classnames';
import { countries } from '@client/utils/countries';
import unknownFlag from '@client/assets/images/unknown_flag.svg';
import styles from './styles/CountryFlag.module.css';

const CountryFlag = ({
	countryCode,
	showCountryName = false,
	className = undefined,
	size = 22,
}: {
	countryCode?: string;
	showCountryName?: boolean;
	className?: string;
	size?: number;
}) => {
	if (
		countryCode == null ||
		countryCode[0] == null
	) {
		return null;
	}

	const country = countryCode[0].toUpperCase() + countryCode[1].toLowerCase();

	const Flag = FlagComponents[country];
	const countryName = countries[countryCode.toUpperCase()];

	if (Flag == null) {
		return (
			<Space className={styles.flex}>
				<Tooltip title={countryName}>
					{/* eslint-disable-next-line react/forbid-component-props */}
					<img
						src={unknownFlag}
						alt="Unknown flag"
						className={classNames(styles.flag, className)}
						style={{ fontSize: size }}
						width={25}
					/>
				</Tooltip>
				{showCountryName && countryName}
			</Space>
		);
	}

	return (
		<Space className={styles.flex}>
			<Tooltip title={countryName}>
				{/* eslint-disable-next-line react/forbid-component-props */}
				<Flag className={classNames(styles.flag, className)} style={{ fontSize: size }} />
			</Tooltip>
			{showCountryName && countryName}
		</Space>
	);
};

export default CountryFlag;
