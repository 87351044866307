// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PvBjm2aVgs4FTWCC4_jC {\n\theight: 22px;\n}\n\n.UQNb_tVW_pAb8CYEHx1m {\n\tfloat: right;\n\tmargin-right: 20px;\n\tmargin-top: -5px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/styles/CrewReportDrawer.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,kBAAkB;CAClB,gBAAgB;AACjB","sourcesContent":[".titleWrapper {\n\theight: 22px;\n}\n\n.pdfDownloadBtnWrapper {\n\tfloat: right;\n\tmargin-right: 20px;\n\tmargin-top: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrapper": "PvBjm2aVgs4FTWCC4_jC",
	"pdfDownloadBtnWrapper": "UQNb_tVW_pAb8CYEHx1m"
};
export default ___CSS_LOADER_EXPORT___;
