const normalize = (num: number | null | undefined) => {
	if (num == null) {
		return 0;
	}

	if (Number.isNaN(num)) {
		return 0;
	}

	if (!Number.isFinite(num)) {
		return 0;
	}

	return num;
};

export default normalize;
