import React, {
	useCallback,
	useMemo,
} from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import uuid from 'short-uuid';
import {
	Checkbox,
	Tooltip,
} from 'antd';
import { EstimatedItemTypes } from '@shared/utils/constants';
import type {
	EstimatedExpenseItem,
	EstimatedRevenueItem,
} from '@api/models/cargo';
import type { EditableCargo } from '@api/features/cargos/updateCargo';
import Card from '@client/components/Card/Card';
import Button from '@client/components/Button';
import styles from '@client/screens/cargos/components/CommissionsCard/BrokerCommissionDetails.module.css';
import AddButton from '@client/components/AddButton';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import { OnUpdateCargo } from '@client/screens/estimates/details/context/hooks/useCargoHandlers';

type Props = {
	handleChange: OnUpdateCargo;
	cargoDetails: EditableCargo | undefined;
}

const EstimatedItemsCard = ({
	cargoDetails,
	handleChange,
}: Props) => {
	const updateEstimatedItems = useCallback((
		updatedItems: Array<EstimatedRevenueItem | EstimatedExpenseItem>,
	) => {
		if (cargoDetails?.id == null) {
			return;
		}

		handleChange(cargoDetails.id, { estimatedItems: updatedItems });
	}, [cargoDetails?.id, handleChange]);

	const dataSource = useMemo(() => cargoDetails?.estimatedItems ?? [], [cargoDetails]);

	return (
		<Card
			slim
			title="Estimated Expenses / Revenues"
		>
			<EditableCellTableRedux<EstimatedExpenseItem | EstimatedRevenueItem>
				columns={[
					{
						title: 'Type',
						dataIndex: 'type',
						editable: true,
						type: 'select',
						inputProps: {
							options: [{
								value: EstimatedItemTypes.EXPENSE,
								label: 'Expense',
							}, {
								value: EstimatedItemTypes.REVENUE,
								label: 'Revenue',
							}],
						},
					},
					{
						title: 'Description',
						dataIndex: 'description',
						editable: true,
						type: 'text',
					},
					{
						title: 'Amount',
						dataIndex: 'amount',
						editable: true,
						type: 'currency',
						inputProps: {
							currency: cargoDetails?.currency ?? 'USD',
						},
					},
					{
						title: 'Subject to comm.',
						dataIndex: 'subjectToCommissions',
						editable: true,
						type: 'checkbox',
						render: (record) => {
							let box = (
								<Tooltip title="Commissions are only applicable to revenue items">
									<Checkbox
										checked={false}
										disabled={record.type === EstimatedItemTypes.EXPENSE}
									/>
								</Tooltip>
							);

							if (record.type === EstimatedItemTypes.REVENUE) {
								box = (
									<Checkbox
										checked={record.subjectToCommissions}
										onChange={async (e) => {
											const items = cargoDetails?.estimatedItems ?? [];

											const updatedItems = items
												.map((item) => (item.id === record.id ?
													{
														...item,
														subjectToCommissions: e.target.checked,
													} as EstimatedRevenueItem :
													item
												));

											updateEstimatedItems(updatedItems);
										}}
									/>
								);
							}

							return box;
						},
					},
					{
						title: (
							<div className={styles.addCol}>
								<AddButton
									onClick={async () => {
										const existingItems = cargoDetails?.estimatedItems;
										const newRow: EstimatedExpenseItem = {
											id: uuid.generate(),
											type: EstimatedItemTypes.EXPENSE,
											amount: null,
											description: '',
											subjectToCommissions: false,
											referenceToModelId: null,
										};

										const updatedItems = [
											...(existingItems != null ?
												existingItems : []
											),
											newRow,
										];

										updateEstimatedItems(updatedItems);
									}}
								/>
							</div>
						),
						// @ts-ignore
						dataIndex: 'addEntry',
						render: (record: EstimatedExpenseItem | EstimatedRevenueItem) => (
							<Button
								onClick={async () => {
									const entries = cargoDetails?.estimatedItems ?? [];
									const filtered = entries.filter((e) => e.id !== record.id);
									updateEstimatedItems(filtered);
								}}
								type="text"
								confirmTitle="Are you sure you want to delete this row?"
								danger
								icon={(<DeleteOutlined />)}
								className={styles.addCol}
							/>
						),
						className: styles.addCol,
						width: 50,
					},
				]}
				allowAddNew
				dataSource={dataSource}
				size="small"
				pagination={false}
				onChange={updateEstimatedItems}
				emptyText="No estimated items"
			/>
		</Card>
	);
};

export default EstimatedItemsCard;
