let scrollBarWidth: number | null = null;

const measure = () => {
	// Add temporary box to wrapper
	const scrollbox = document.createElement('div');

	// Make box scrollable
	scrollbox.style.overflow = 'scroll';

	// Append box to document
	document.body.appendChild(scrollbox);

	// Measure inner width of box
	scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;

	// Remove box
	document.body.removeChild(scrollbox);
};

const getScrollBarWidth = () => {
	if (scrollBarWidth == null) {
		measure();
	}

	return scrollBarWidth;
};

export default getScrollBarWidth;
