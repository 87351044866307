import {
	Currencies,
	HIIFieldTypes,
	InvoiceTemplates,
	LaytimeTerms,
	PnlGroups,
} from '@shared/utils/constants';
import TemplateItem from '@shared/TemplateItem';
import { Values } from '@shared/utils/objectEnums';
import { round } from '@shared/utils/math';
import HireInvoiceItemModel from '@api/models/hire-invoice-item';
import type { HIIDespatchType } from '@api/models/hii-despatch';
import { Port } from '@api/utils/ports';
import { formatCurrency } from '../utils/currency';
import HireInvoiceItem, {
	HIIConstructorParams,
	TemplateItemChildren,
} from './HireInvoiceItem';

class HIIDespatch extends HireInvoiceItem {
	static get itemType() {
		return 'despatch';
	}

	cargoId: number;
	cargoPortId: number | null;
	amount: number;
	hours: number;
	rate: number;
	charterer: number | null;
	currency: Values<typeof Currencies>;
	terms: LaytimeTerms;
	type: 'loading' | 'discharging' | null;
	laytimeDetails: TemplateItemChildren | null;
	port: Port | null;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			cargoId: number;
			cargoPortId: number | null;
			amount: number;
			hours: number;
			rate: number;
			charterer: number | null;
			currency: Values<typeof Currencies>;
			terms: LaytimeTerms;
			type: 'loading' | 'discharging' | null;
			laytimeDetails: TemplateItemChildren | null;
			port: Port | null;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.cargoId = params.cargoId;
		this.cargoPortId = params.cargoPortId;
		this.amount = params.amount;
		this.hours = params.hours;
		this.rate = params.rate;
		this.charterer = params.charterer;
		this.currency = params.currency;
		this.terms = params.terms;
		this.type = params.type;
		this.laytimeDetails = params.laytimeDetails;
		this.port = params.port;
		this.pnlGroup = PnlGroups.DEMURRAGE_DESPATCH;
		this._showTotal = true;
		this.itemTypeLabel = 'Despatch';
		this.addManually = true;
		this.templateSection = TemplateItem.Sections.DND;

		this._fields = {
			hours: {
				label: 'Hours',
				type: HIIFieldTypes.NUMBER,
			},
		};
	}

	_calculateTotal() {
		return this.amount;
	}

	getDescription() {
		const days = this.getDays();
		const unit = this.getUnit();
		const daysStr = `${days} ${unit} gained at `;
		const rate = formatCurrency(round(this.rate, 2), this.fixtureCurrency);

		return `${this.terms !== LaytimeTerms.AVERAGED ? daysStr : ''}${rate} per day`;
	}

	getDays() {
		const days = round(Math.abs(this.hours / 24), 2);

		return days;
	}

	getUnit() {
		const days = this.getDays();
		const daysStr = `${days === 1 ? 'day' : 'days'}`;

		return daysStr;
	}

	static _getClassParams(model: HIIDespatchType, _parentModel: HireInvoiceItemModel) {
		return {
			cargoId: model.amount,
			cargoPortId: model.cargoPortId,
			amount: model.amount,
			terms: model.terms,
			hours: model.hours,
			type: model.type,
			rate: model.rate,
		};
	}

	async saveModel(model: HIIDespatchType) {
		await super.saveModel(model);

		model.cargoId = this.cargoId;
		model.cargoPortId = this.cargoPortId;
		model.amount = this.amount;
		model.terms = this.terms;
		model.hours = this.hours;
		model.rate = this.rate;
		model.type = this.type;

		await model.save();
	}

	_getTemplateItemParams() {
		const rowStart = `Despatch, ${this.terms}`;
		let daysGained = `${Math.abs(round(this.hours / 24, 2))} days gained`;
		let descriptionDetails;
		switch (this.terms) {
			case LaytimeTerms.REVERSIBLE:
				descriptionDetails = `@ ${formatCurrency(round(this.rate, 2),
					this.fixtureCurrency)
				} per day`;
				break;

			case LaytimeTerms.NON_REVERSIBLE:
				descriptionDetails = `${this.type} in ${this.port?.name}`;
				break;

			case LaytimeTerms.AVERAGED:
				descriptionDetails = `${daysGained} ${this.type} @ ${formatCurrency(round(this.rate, 2),
					this.fixtureCurrency)
				} per day`;
				daysGained = '';
				break;

			default:
				descriptionDetails = '';
		}

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					`${rowStart}`,
					`${daysGained} ${descriptionDetails}`,
				],
				children: this.laytimeDetails ?? undefined,
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					`${rowStart}`,
					`${this.getDays()}`,
					`${this.getUnit()} gained`,
					`${formatCurrency(this.rate, this.fixtureCurrency, { hideSymbol: true })}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIDespatch);

export default HIIDespatch;
