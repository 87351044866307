// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wsJ5beS3cHXnyEUkI3Ar {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-top: 16px;\n\tpadding: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/PdfGenerator/private/styles/FormSection.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,8BAA8B;CAC9B,gBAAgB;CAChB,UAAU;AACX","sourcesContent":[".header {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-top: 16px;\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "wsJ5beS3cHXnyEUkI3Ar"
};
export default ___CSS_LOADER_EXPORT___;
