import React from 'react';
import {
	CargoUnitLabels,
	CargoUnitSingularLabels,
	CargoUnitTypes,
	Currencies,
	FreightRateType,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { formatNumber } from '@shared/utils/formatNumber';
import type { GetCounterpartiesResponse } from '@api/features/counterparties/getCounterparties';
import type { Port } from '@api/utils/ports';
import type { EditableCargo } from '@api/features/cargos/updateCargo';
import EmptyText from '@client/components/EmptyText';
import { getPortOptions } from '@client/utils/getPortAndRangeOptions';

const getKeyCargoDetailsFields = ({
	cargoDetails,
	charterers,
	isTceLocked,
	ports,
}: {
	cargoDetails: EditableCargo;
	charterers: GetCounterpartiesResponse | undefined;
	isTceLocked?: boolean;
	ports: Port[] | undefined;
}) => {
	if (cargoDetails == null) {
		return [];
	}

	const addonAfterFreightRate = cargoDetails.freightType === FreightRateType.PER_UNIT ? `/ ${CargoUnitSingularLabels[cargoDetails.unit]}` : '';
	const portOptions = getPortOptions(ports ?? []);

	return [
		{
			key: 'type',
			label: 'Cargo Description',
			type: 'text',
			value: cargoDetails.type,
			editable: true,
			inputProps: {
				tabIndex: 1,
			},
		},
		{
			key: 'quantity',
			label: 'Quantity',
			type: 'number',
			value: cargoDetails.quantity,
			render: () => (cargoDetails.quantity != null ?
				`${formatNumber(cargoDetails.quantity, { separateThousands: true })} ${CargoUnitLabels[cargoDetails.unit]}` : ''),
			editable: true,
			inputProps: {
				addonAfter: CargoUnitLabels[cargoDetails.unit],
				separateThousands: true,
				tabIndex: 2,
			},
		},
		{
			key: 'unit',
			label: 'Unit',
			type: 'select',
			value: cargoDetails.unit,
			options: Object.values(CargoUnitTypes).map((c) => ({
				label: CargoUnitLabels[c],
				value: c,
			})),
			render: () => (CargoUnitLabels[cargoDetails.unit]),
			editable: true,
			inputProps: {
				tabIndex: 3,
			},
		},
		{
			key: 'freightRate',
			label: 'Freight Rate',
			type: 'currency',
			value: cargoDetails.freightRate,
			inputProps: {
				currency: cargoDetails.currency,
				disabled: isTceLocked,
				tabIndex: 4,
			},
			editable: true,
			render: () => (
				<>
					{cargoDetails.freightRate != null ?
						`${formatCurrency(cargoDetails.freightRate, cargoDetails.currency)}
                                ${addonAfterFreightRate}
                            ` : (<EmptyText />)}
				</>
			),
		},
		{
			key: 'currency',
			label: 'Currency',
			type: 'select',
			value: cargoDetails.currency,
			options: Object.values(Currencies).map((c) => ({
				label: Currencies[c],
				value: c,
			})),
			inputProps: {
				showSearch: true,
				tabIndex: 5,
			},
			render: () => <>{Currencies[cargoDetails.currency]}</>,
			editable: true,
		},
		{
			key: 'freightType',
			label: 'Freight Type',
			type: 'select',
			value: cargoDetails.freightType,
			options: [
				{
					label: `Per ${cargoDetails.unit != null ? CargoUnitSingularLabels[cargoDetails.unit] ?? 'Unit' : 'Unit'}`,
					value: FreightRateType.PER_UNIT,
				},
				{
					label: 'Lumpsum',
					value: FreightRateType.LUMPSUM,
				},
			],
			render: () => (
				<>
					{cargoDetails.freightType != null ?
						(
							cargoDetails.freightType === FreightRateType.PER_UNIT ?
								`Per ${cargoDetails.unit != null ? CargoUnitSingularLabels[cargoDetails.unit] ?? 'Unit' : 'Unit'}` :
								FreightRateType.LUMPSUM
						) : (<EmptyText />)}
				</>
			),
			inputProps: {
				tabIndex: 6,
			},
			editable: true,
		},
		{
			key: 'charterer',
			label: 'Charterer',
			type: 'select',
			value: cargoDetails.charterer,
			editable: true,
			render: () => <>{ cargoDetails.chartererName || (<EmptyText />) }</>,
			options: charterers?.map((c) => ({
				label: c.name,
				value: c.id,
			})),
			inputProps: {
				showSearch: true,
				placeholder: 'Select Charterer',
				tabIndex: 7,
			},
		},
		{
			label: 'Loading Ports',
			value: cargoDetails.loadingPorts.map((lp) => (typeof lp !== 'number' && 'id' in lp ? lp.id : lp)),
			key: 'loadingPorts',
			options: portOptions,
			multiple: true,
			inputProps: {
				showSearch: true,
				allowClear: false,
				tabIndex: 8,
			},
			editable: true,
			type: 'select',
		},
		{
			label: 'Discharge Ports',
			key: 'dischargePorts',
			multiple: true,
			options: portOptions,
			value: cargoDetails.dischargePorts.map((lp) => (typeof lp !== 'number' && 'id' in lp ? lp.id : lp)),
			inputProps: {
				showSearch: true,
				allowClear: false,
				tabIndex: 9,
			},
			editable: true,
			type: 'select',
		},
	];
};

export default getKeyCargoDetailsFields;
