import React from 'react';
import {
	Col,
	Form,
	Input,
	Row,
} from 'antd';
import { capitalize } from '@shared/utils/string';
import {
	Currencies,
	FuelTypes,
	VoyageBunkerTypeLabels,
	VoyageBunkerTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { rules } from '@client/utils/form';
import Select from '@client/components/Select';
import NumericInput from '@client/components/NumericInput';
import styles from '@client/screens/fleet/VoyageDetailsScreen/components/styles/BunkerForm.module.css';
import Button from '@client/components/Button';
import MultiCurrencyInput, { MultiCurrencyValueObject } from '@client/components/MultiCurrencyInput';

const getVoyageBunkerForm = (
	fixtureCurrency: Values<typeof Currencies>,
	saveLoading: boolean | { delay?: number | undefined } | undefined,
	multiCurrencyValues: {
		value: number | undefined;
		currency: Values<typeof Currencies> | undefined;
		exchangeRate: number | undefined;
	},
	setMultiCurrencyValues: (val: MultiCurrencyValueObject) => void,
) => (
	<>
		<Row gutter={16}>
			<Col span={11}>
				<Form.Item
					name="type"
					label="Type"
					rules={[rules.required]}
				>
					<Select
						placeholder="Select a type"
						options={[
							{
								label: capitalize(VoyageBunkerTypeLabels[VoyageBunkerTypes.DELIVERY]),
								value: VoyageBunkerTypes.DELIVERY,
							},
							{
								label: capitalize(VoyageBunkerTypeLabels[VoyageBunkerTypes.REDELIVERY]),
								value: VoyageBunkerTypes.REDELIVERY,
							},
							{
								label: `Other (${VoyageBunkerTypeLabels[VoyageBunkerTypes.OWNERS_ACCOUNT]})`,
								value: VoyageBunkerTypes.OWNERS_ACCOUNT,
							},
							{
								label: `Other (${VoyageBunkerTypeLabels[VoyageBunkerTypes.CHARTERERS_ACCOUNT]})`,
								value: VoyageBunkerTypes.CHARTERERS_ACCOUNT,
							},
						]}
					/>
				</Form.Item>
			</Col>
			<Col span={13}>
				<Form.Item
					name="fuelGrade"
					label="Grade"
					rules={[rules.required]}
				>
					<Select
						placeholder="Select a grade"
						options={Object.values(FuelTypes).map((fuelGrade) => ({
							label: fuelGrade,
							value: fuelGrade,
						}))}
					/>
				</Form.Item>
			</Col>
		</Row>
		<Row gutter={16}>
			<Col span={11}>
				<Form.Item
					name="quantity"
					label="Quantity"
					rules={[rules.required, rules.numberPositive]}
				>
					<NumericInput
						className={styles.inputNumber}
						placeholder="MT"
					/>
				</Form.Item>
			</Col>
			<Col span={13}>
				<Form.Item
					name="pricePerTon"
					label="Price per ton"
					rules={[rules.required]}
					valuePropName="values"
				>
					<MultiCurrencyInput
						inputValues={multiCurrencyValues}
						onChange={setMultiCurrencyValues}
						baseCurrency={fixtureCurrency}
						className={styles.inputNumber}
						placeholder={`${fixtureCurrency} / MT`}
					/>
				</Form.Item>
			</Col>
		</Row>
		<Form.Item
			name="note"
			label="Note for invoice"
		>
			<Input.TextArea />
		</Form.Item>
		<Form.Item>
			<Button
				type="primary"
				htmlType="submit"
				loading={saveLoading}
			>
				Save
			</Button>
		</Form.Item>
	</>
);

export default getVoyageBunkerForm;
