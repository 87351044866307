// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.JEfWBN4w1uTEX5gQC4wR .ant-table-cell {\n\tfont-size: 12px !important;\n\tpadding: 4px 4px !important;\n}\n\n.JEfWBN4w1uTEX5gQC4wR .ant-table-container table th:first-child {\n\tpadding-left: 8px !important;\n}\n\n.JEfWBN4w1uTEX5gQC4wR .ant-table-cell input {\n\tfont-size: 12px !important;\n\tpadding-left: 4px !important;\n}\n\n.JEfWBN4w1uTEX5gQC4wR .ant-table-cell .RM_7vOj4aTtwFLUYYLQN {\n\tfont-size: 12px !important;\n}\n\n.JEfWBN4w1uTEX5gQC4wR .ant-table-cell .Da9xRZvhQ5GvYIKOwsUt {\n\tfont-size: 12px !important;\n}\n\n.JEfWBN4w1uTEX5gQC4wR .ant-select-selection-item {\n\tfont-size: 12px !important;\n}\n\n.JEfWBN4w1uTEX5gQC4wR input {\n\tfont-size: 12px !important;\n}\n\n.JEfWBN4w1uTEX5gQC4wR .ant-input-number-group-addon {\n\tfont-size: 12px !important;\n\tpadding: 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/details/components/styles/CargosCard.module.css"],"names":[],"mappings":";AACA;CACC,0BAA0B;CAC1B,2BAA2B;AAC5B;;AAEA;CACC,4BAA4B;AAC7B;;AAEA;CACC,0BAA0B;CAC1B,4BAA4B;AAC7B;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,0BAA0B;CAC1B,UAAU;AACX","sourcesContent":["\n.table :global(.ant-table-cell) {\n\tfont-size: 12px !important;\n\tpadding: 4px 4px !important;\n}\n\n.table :global(.ant-table-container table th:first-child) {\n\tpadding-left: 8px !important;\n}\n\n.table :global(.ant-table-cell) input {\n\tfont-size: 12px !important;\n\tpadding-left: 4px !important;\n}\n\n.table :global(.ant-table-cell) .ant-select-selector {\n\tfont-size: 12px !important;\n}\n\n.table :global(.ant-table-cell) .ant-select-selection-placeholder {\n\tfont-size: 12px !important;\n}\n\n.table :global(.ant-select-selection-item) {\n\tfont-size: 12px !important;\n}\n\n.table input {\n\tfont-size: 12px !important;\n}\n\n.table :global(.ant-input-number-group-addon) {\n\tfont-size: 12px !important;\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "JEfWBN4w1uTEX5gQC4wR",
	"ant-select-selector": "RM_7vOj4aTtwFLUYYLQN",
	"ant-select-selection-placeholder": "Da9xRZvhQ5GvYIKOwsUt"
};
export default ___CSS_LOADER_EXPORT___;
