/* eslint-disable @typescript-eslint/no-restricted-imports */
import React, { useState } from 'react';
import moment from 'moment';
import { ColumnGroupType } from 'antd/es/table';
import {
	CustomFieldParentTypes,
	CustomFieldType,
} from '@shared/utils/constants';
import { formatDate } from '@shared/utils/date';
import CustomField from '@api/models/custom-field';
import {
	createCustomField,
	deleteCustomField,
	getCustomFields,
	updateCustomField,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import EditableTable, { EditableColumn } from '@client/components/EditableTable';
import useFetchedState from '@client/utils/hooks/useFetchedState';

type Props = {
	parentId: number;
	parentType: CustomFieldParentTypes;
	showValueField?: boolean;
}

const CustomFields = ({
	parentId,
	parentType,
	showValueField = true,
}: Props) => {
	const handleCreate = async (
		attributes: any,
	) => {
		try {
			await createCustomField(attributes);
			refreshFields();
		} catch (e) {
			showErrorNotification('Could not create new custom field', e as Error);
		}
	};

	const handleUpdate = async (
		id: number,
		type: CustomFieldType,
		label: string,
		value: string | number | Date,
	) => {
		try {
			await updateCustomField({
				id,
				type,
				label,
				value,
			});
			refreshFields();
		} catch (e) {
			showErrorNotification('Could not update custom field', e as Error);
		}
	};

	const handleDelete = async (
		id: number,
	) => {
		try {
			await deleteCustomField(id);
			refreshFields();
		} catch (e) {
			showErrorNotification('Could not delete custom field', e as Error);
		}
	};

	const [fields, refreshFields] = useFetchedState(async () => {
		return await getCustomFields({
			parentId,
			parentType,
		});
	}, [parentId, parentType]);

	const [editingType, setEditingType] = useState<CustomFieldType>(CustomFieldType.TEXT);

	const renderField = () => {
		switch (editingType) {
			case CustomFieldType.NUMBER:
				return 'number';
			case CustomFieldType.TEXT:
				return 'textarea';
			case CustomFieldType.DATEPICKER:
				return 'date';
			default:
				return 'textarea';
		}
	};

	const columns: (ColumnGroupType<CustomField> | EditableColumn<CustomField, 'id'>)[] = [
		{
			width: 135,
			title: 'Type',
			dataIndex: 'type',
			editable: true,
			editingProps: {
				options: Object.values(CustomFieldType).map((v) => ({ value: v, label: v })),
				type: 'select',
				inputProps: {
					showSearch: true,
					onChange: (v) => {
						setEditingType(v?.toString() as CustomFieldType);
					},
				},
			},
		},
		{
			title: 'Label',
			dataIndex: 'label',
			editable: true,
		},

	];

	if (showValueField) {
		columns.push({
			title: 'Value',
			dataIndex: 'value',
			render: (value: any) => {
				if (moment.isMoment(value)) {
					return formatDate(value);
				}

				return value;
			},
			editable: true,
			editingProps: {
				type: renderField() as any,
				inputProps: {
					placeholder: 'Enter value',
					required: true,
				},
			},
		} as (ColumnGroupType<CustomField> | EditableColumn<CustomField, 'id'>));
	}

	return (
		<EditableTable
			iconButtons
			enableDelete={() => true}
			enableEdit={() => true}
			allowAddNew
			keyDataIndex="id"
			columns={columns}
			dataSource={fields || []}
			size="small"
			pagination={false}
			onSave={async (id, value) => {
				await handleUpdate(
					id,
					value.type,
					value.label,
					showValueField ? value.value : '',
				);
			}}
			onAddNew={async (value) => {
				await handleCreate({
					parentId,
					parentType,
					type: editingType,
					label: value.label,
					value: showValueField ? value.value : '',
				});
			}}
			onDelete={async (id) => {
				await handleDelete(id);
			}}
			emptyText="No Custom Fields"
			addNewText={(
				<span style={{ fontSize: 'medium' }}>
					Custom fields
				</span>
			)}
		/>
	);
};

export default CustomFields;
