// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ay5aXWnD4Ir_4NNI0bww {\n\twidth: 100%;\n}\n\n.aN3xeUsXqNhKZy6s22O5 {\n\tborder: 1px solid red;\n}\n\n.aN3xeUsXqNhKZy6s22O5 input.ant-input:focus {\n\tborder-color: red;\n\tborder-left-width: 1px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/helpers/styles/voyageInvoiceForm.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;AACZ;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,iBAAiB;CACjB,sBAAsB;AACvB","sourcesContent":[".settingsInput {\n\twidth: 100%;\n}\n\n.missingHire {\n\tborder: 1px solid red;\n}\n\n.missingHire :global(input.ant-input):focus {\n\tborder-color: red;\n\tborder-left-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsInput": "Ay5aXWnD4Ir_4NNI0bww",
	"missingHire": "aN3xeUsXqNhKZy6s22O5"
};
export default ___CSS_LOADER_EXPORT___;
