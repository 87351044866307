import {
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import { formatPercentage } from '@shared/utils/formatPercentage';
import TemplateItem from '../TemplateItem';
import HIIHireAddressCommission from './HIIHireAddressCommission';
import HireInvoiceItem from './HireInvoiceItem';

class HIIBallastBonusAddressCommission extends HireInvoiceItem {
	static get itemType() {
		return 'ballastBonusAddressCommission';
	}

	constructor(id, isOriginal, params) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this._showTotal = true;
		this.itemTypeLabel = 'Address commission';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.COMMISSIONS;
		this.pnlGroup = PnlGroups.ADDRESS_COMMISSIONS;

		this._fields = {};
	}

	_calculateTotal(invoiceItems) {
		const ballastBonus = this.getParent(invoiceItems);
		const addressCommission = HireInvoiceItem.findItem(invoiceItems, HIIHireAddressCommission);

		const total = (ballastBonus?.amount ?? 0) * (addressCommission.percentage / 100);

		return -1 * total;
	}

	getDescription(invoiceItems) {
		const addressCommission = HireInvoiceItem.findItem(invoiceItems, HIIHireAddressCommission);

		return `${formatPercentage(addressCommission.percentage, 2)}`;
	}

	static _getClassParams(_model, _parentModel) {
		return {};
	}

	async saveModel(model) {
		await super.saveModel(model);
		await model.save();
	}

	_getTemplateItemParams(invoiceItems) {
		const ballastBonus = this.getParent(invoiceItems);
		const addressCommission = HireInvoiceItem.findItem(invoiceItems, HIIHireAddressCommission);

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: ['Address commission', this.getDescription(invoiceItems)],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					' — Ballast bonus Address commission',
					`${formatPercentage(addressCommission.percentage, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(ballastBonus.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIBallastBonusAddressCommission);

export default HIIBallastBonusAddressCommission;
