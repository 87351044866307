import React, {
	ReactNode,
	useState,
} from 'react';
import { SmileOutlined } from '@ant-design/icons';
import Select, { SelectProps } from '@client/components/Select';

const notFound = (
	<div style={{ textAlign: 'center' }}>
		<SmileOutlined />
		<p>Input a string and finish by pressing enter</p>
	</div>
);

const separators = ['/', ' ', ','];

export type ListInputProps = {
	value: string[];
	onChange: (newValue: string[]) => void;
	formatValues?: (value: string[]) => string[];
	formatSearchString?: (searchString: string) => string | null;
	filterSort?: SelectProps<string[]>['filterSort'];
	notFoundContent?: ReactNode;
	className?: string;
	allowSpace?: boolean;
}

const ListInput: React.FC<ListInputProps> = ({
	value,
	onChange,
	formatValues = (values) => values,
	formatSearchString = (s) => s,
	allowSpace = false,
	...props
}) => {
	const [searchText, setSearchText] = useState('');

	const valueChange = (rawValues: string[]) => {
		// Only allow unique values
		const newValues = [...new Set(formatValues(rawValues))];

		onChange(newValues);
		setSearchText('');
	};

	const searchChange = (searchValue: string) => {
		// If the last character is a separator
		// Add the value to the list of selected values
		// But only if the separator isn't the only character in the search value
		if (
			!allowSpace &&
			searchValue.length > 1 &&
			separators.includes(searchValue.charAt(searchValue.length - 1))
		) {
			valueChange([
				...(value || []),
				searchValue.slice(0, -1),
			]);

			return;
		}

		// If not, just update the search value
		setSearchText(formatSearchString(searchValue) ?? '');
	};

	return (
		<Select<string[]>
			{...props}
			value={value}
			// We know it will always be string array
			// @ts-ignore
			onChange={valueChange}
			mode="tags"
			multiple
			notFoundContent={notFound}
			onSearch={searchChange}
			searchValue={searchText}
		/>
	);
};

export default ListInput;
