import React from 'react';
import { Moment } from 'moment';
import {
	Flex,
	Typography,
} from 'antd';
import { Link } from 'react-router-dom';
import {
	Currencies,
	DATE_AND_TIME,
	FuelTypes,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { formatNumber } from '@shared/utils/formatNumber';
import { formatQuantity } from '@shared/utils/string';
import { Values } from '@shared/utils/objectEnums';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { Parcel } from '@api/features/voyages/bunker-stems/createBunkerStem';
import type { GetSuppliersResponse } from '@api/features/suppliers/getSuppliers';
import { formatDate } from '@client/utils/formatDate';
import { Links } from '@client/utils/links';
import { IncomingBunkerArray } from '../utils/bunkerTabHelpers';

const getParcelStr = (
	parcel: Parcel,
	currency: Values<typeof Currencies>,
	tcInContract: GetVoyageDetailsResponse['tcInContract'],
	organizationName: string | null,
) => {
	const tcInOwnerToUse = tcInContract?.fixture.fixtureCounterparty.name ?? tcInContract?.fixture.fixtureCounterparty.companyName ?? 'N/A';
	const account = parcel.account === 'owner' ? organizationName : tcInOwnerToUse;

	return `${formatQuantity(parcel.quantity, 'MT')} ${parcel.fuelGrade} @ ${formatCurrency(parcel.pricePerTon, currency)}/MT for ${account} \n`;
};

export const getRobTableColumns = (showPricePerTon?: boolean) => {
	return (
		[
			{
				title: 'Fuel grade',
				dataIndex: 'fuelGrade',
				editable: true,
				editingProps: {
					type: 'select',
					inputProps: {
						defaultActiveFirstOption: true,
						options: Object.keys(FuelTypes).map((key) => ({
							label: FuelTypes[key],
							value: key,
						})),
					},
				},
			},
			{
				title: 'Quantity',
				dataIndex: 'quantity',
				editable: true,
				align: 'right',
				editingProps: {
					type: 'number',
					inputProps: {
						addonAfter: 'MT',
					},
				},
				render: (q: number) => `${formatNumber(q, { separateThousands: true })} MT`,
			},
			...(showPricePerTon ? ([{
				dataIndex: 'pricePerTon',
				title: 'Price / MT',
				editable: true,
				render: (c: number, record: IncomingBunkerArray[number]) => {
					const price = c;

					if (c == null || Number.isNaN(c)) {
						return 0;
					}

					return `${formatCurrency(price, record?.currency ?? 'USD')}`;
				},
			}]) : []),
		]
	);
};

export const getBunkerStemTableColumns = (
	suppliers: GetSuppliersResponse = [],
	tcInContract: GetVoyageDetailsResponse['tcInContract'] | undefined,
	organizationName: string | null,
) => {
	return [
		{
			title: 'Stem date',
			dataIndex: 'stemDate',
			render: (val: Moment) => formatDate(val, DATE_AND_TIME),
		},
		{
			title: 'Invoice date',
			dataIndex: 'invoiceDate',
			render: (val: Moment) => formatDate(val) ?? (<em>-</em>),
		},
		{
			title: 'Parcels',
			dataIndex: 'parcels',
			render: (parcels: Parcel[]) => (
				<Flex vertical gap={5}>
					{parcels.map((p) => (
						<Typography.Text
							// eslint-disable-next-line react/forbid-component-props
							style={{ fontSize: 12 }}
						>
							{getParcelStr(p, p.currency, tcInContract, organizationName)}
						</Typography.Text>
					))}
				</Flex>
			),
		},
		{
			title: 'Supplier',
			dataIndex: 'supplierId',
			render: (supplierId: number) => (
				<Typography.Text>
					{suppliers.find((s) => s.id === supplierId)?.name ?? 'N/A'}
				</Typography.Text>
			),
		},
		{
			title: 'Vendor expense',
			dataIndex: 'voyageExpenseId',
			render: (vendorExpenseId: number) => (
				<Link to={`${Links.Expenses.get()}?id=${vendorExpenseId}`}>
					<span>
						Go to vendor expense
					</span>
				</Link>
			),
		},
	];
};
