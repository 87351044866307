// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qj4KWCYFYco5S_mS9D41 {\n\tmargin-left: 3px;\n\tfont-size: 9pt;\n}\n\n.bxW9Buj3Ch_CBOCrJT7S {\n\twidth: 10em;\n}\n\n.B_CqgV_m5rmcoUjNSyP4 {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/AmountAndConvertedAmount.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,cAAc;AACf;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB","sourcesContent":[".expenseAmount {\n\tmargin-left: 3px;\n\tfont-size: 9pt;\n}\n\n.loader {\n\twidth: 10em;\n}\n\n.verticalContainer {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expenseAmount": "qj4KWCYFYco5S_mS9D41",
	"loader": "bxW9Buj3Ch_CBOCrJT7S",
	"verticalContainer": "B_CqgV_m5rmcoUjNSyP4"
};
export default ___CSS_LOADER_EXPORT___;
