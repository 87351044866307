// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".js6P6Am3F4NfsWZ3gzDQ {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n}\n\n.js6P6Am3F4NfsWZ3gzDQ > p {\n\tmargin: 0;\n\ttext-align: center;\n}\n\n.UqfTW8W9FChI5pycVgXc {\n\tmargin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/LinkedContracts/LinkedContracts.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,mBAAmB;AACpB;;AAEA;CACC,SAAS;CACT,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".title {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n}\n\n.title > p {\n\tmargin: 0;\n\ttext-align: center;\n}\n\n.link {\n\tmargin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "js6P6Am3F4NfsWZ3gzDQ",
	"link": "UqfTW8W9FChI5pycVgXc"
};
export default ___CSS_LOADER_EXPORT___;
