import { Moment } from 'moment';
import { BankAccountProps } from '@api/models/bank-account';
import { VoyageExpenseReceivableProps } from '@api/models/voyage-expense-receivable';
import BbItem from '@api/models/bb-item';
import { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import HIIBbItem from '@api/models/hii-bb-item';
import { VoyageBunkerProps } from '@api/models/voyage-bunker';
import { BunkerProps } from '@api/models/bunker';
import { Values } from './objectEnums';
import {
	AccountTypes,
	HireInvoiceItemStates,
} from './constants';
import {
	getBbHireInvoiceItem,
	getBunkerHireInvoiceItem,
	getExpenseHireInvoiceItem,
} from './hireInvoice';
import filterHireInvoiceItemsByState from './filterHireInvoiceItemsByState';
import { toMoment } from './date';

let _id = -1;

const getId = () => {
	const newId = _id;

	_id -= 1;

	return newId;
};

export type WithHIIState<T> = T & { state: Values<typeof HireInvoiceItemStates> };

export type BareboatParams = {
	hireInvoices: GetVoyageDetailsResponse['hireInvoices'];
	brokers: Array<{
		id: number;
		name: string;
		commission: number;
		paidBy: Values<typeof AccountTypes>;
	}>;
	bankAccount: BankAccountProps;
	voyageExpenseReceivables: WithHIIState<VoyageExpenseReceivableProps>[];
	bbItems: Array<BbItem & {
		state: Values <typeof HireInvoiceItemStates>;
	}>;
	voyageBunkers: Array<WithHIIState<VoyageBunkerProps & BunkerProps>>;
	fromDate: Moment | string | null;
	readyForCumulative: boolean;
	completionDate: Moment | string;
	useUTC: boolean;
};

const getAvailableBbInvoiceItems = (params: BareboatParams) => {
	const {
		hireInvoices,
		bankAccount,
		bbItems,
		voyageExpenseReceivables,
		brokers: allBrokers,
		readyForCumulative,
		completionDate,
		useUTC,
		fromDate: commencementDate,
		voyageBunkers,
	} = params;

	if (commencementDate == null) {
		return [];
	}

	// Fix from date to update af each invoice!!!
	let fromDate = commencementDate;

	const items = [] as any[];

	if (!Array.isArray(hireInvoices)) {
		return items;
	}

	const hireInvoiceItems = hireInvoices.flatMap((i) => i.HireInvoiceItems);

	const previousHireItems = hireInvoiceItems.reduce((arr: HIIBbItem[], item) => {
		if (item.HIIBbItem == null) {
			return arr;
		}

		const period = item.HIIBbItem;

		return [...arr, period];
	}, []).sort((a, b) => toMoment(a.from).diff(b.from));

	if (previousHireItems.length > 0 && !readyForCumulative) {
		const latestHireItem = previousHireItems[previousHireItems.length - 1];

		fromDate = toMoment(latestHireItem.to);
	}

	fromDate = toMoment(fromDate).utc();

	const daysToAdd = 15;

	let toDate = toMoment(fromDate).add(daysToAdd, 'days');

	if (completionDate != null && toMoment(completionDate).isBefore(toDate)) {
		toDate = toMoment(completionDate);
	}

	toDate = toMoment(toDate).utc();

	filterHireInvoiceItemsByState(voyageExpenseReceivables, HireInvoiceItemStates.PENDING)
		.forEach((expense) => items.push(getExpenseHireInvoiceItem(
			getId,
			expense,
			bankAccount.currency,
		)));

	filterHireInvoiceItemsByState(voyageBunkers, HireInvoiceItemStates.PENDING).forEach((bunker) => {
		items.push(getBunkerHireInvoiceItem(getId, bunker, bankAccount.currency));
	});

	filterHireInvoiceItemsByState(bbItems, HireInvoiceItemStates.PENDING)
		.forEach((bbItem) => {
			items.push(getBbHireInvoiceItem(
				getId,
				bbItem,
				bankAccount.currency,
				fromDate,
				toDate,
				{
					addressCommission: bbItem.addComm ?? 0,
					brokers: allBrokers.filter((broker) => (bbItem.brokerPaidByOwnerIds?.includes(broker.id) ||
					bbItem.brokerPaidByChartererIds?.includes(broker.id))),
				},
				useUTC,
			));
		});

	return items;
};

export default getAvailableBbInvoiceItems;
