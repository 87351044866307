import HIIBbItem from '@shared/hireInvoice/HIIBbItem';
import HIIHirePeriod from '@shared/hireInvoice/HIIHirePeriod';

const groupInvoiceItems = (items = [], differences = [], isBareboat = false) => {
	let groupedItems = items;
	let groupedDifferences = differences;

	if (isBareboat) {
		({ items: groupedItems, differences: groupedDifferences } = HIIBbItem.groupItems(
			groupedItems,
			differences,
		));
	} else {
		({ items: groupedItems, differences: groupedDifferences } = HIIHirePeriod.groupItems(
			groupedItems,
			differences,
		));
	}

	return {
		items: groupedItems,
		differences: groupedDifferences,
	};
};

export default groupInvoiceItems;
