import React from 'react';
import ecazones from '@client/components/Map/geojsonFiles/ecazones.json';
import GeoJsonLayer from './GeoJsonLayer';

const EcaLayer = () => (
	<GeoJsonLayer
		layerStyle={{
			id: 'area',
			type: 'fill',
			paint: {
				'fill-color': '#77d2df',
				'fill-opacity': 0.3,
				'fill-outline-color': '#ffffff',
			},
		}}
		id="ecalayer-1"
		type="geojson"
		data={ecazones}
	/>
);

export default EcaLayer;
