import { LaytimeCalculationMethods } from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import { CargoPortProps } from '@api/models/cargo-port';

type EstimatedLaytime = {
	timeToCount: number | null;
	totalTimeInPort: number | null;
	idleDays: number | null;
	workingDays: number | null;
};

export const getEstimatedLaytimes = (
	cargoPort: CargoPortProps,
	estimatedWorkingDaysOverride?: number | null,
): EstimatedLaytime => {
	let {
		// eslint-disable-next-line prefer-const
		calculationMethod,
		holidaysWorked,
		holidaysNotWorked,
		idleDays,
	} = cargoPort;

	holidaysWorked = holidaysWorked ?? 0;
	holidaysNotWorked = holidaysNotWorked ?? 0;
	idleDays = idleDays ?? 0;

	const loadingRate = cargoPort.estimatedLoadingRate ?? cargoPort.loadingRate;

	if (
		cargoPort.cpQuantity == null ||
		loadingRate == null ||
		calculationMethod == null
	) {
		return {
			timeToCount: null,
			totalTimeInPort: null,
			idleDays,
			workingDays: null,
		};
	}

	const estimatedLoadDischarge = estimatedWorkingDaysOverride ?? (cargoPort.cpQuantity / loadingRate);

	let totalTimeInPort = 0;
	let timeToCount = 0;

	switch (calculationMethod) {
		case LaytimeCalculationMethods.SHEX_EIU:
			timeToCount = estimatedLoadDischarge - holidaysWorked;
			totalTimeInPort = estimatedLoadDischarge + holidaysNotWorked + idleDays;
			break;

		case LaytimeCalculationMethods.SHEX_UU_FULL_DAY:
			timeToCount = estimatedLoadDischarge;
			totalTimeInPort = estimatedLoadDischarge + holidaysNotWorked + idleDays;
			break;

		case LaytimeCalculationMethods.SHEX_UU_HALF_DAY:
			timeToCount = estimatedLoadDischarge + (holidaysWorked !== 0 ? holidaysWorked / 2 : 0);
			totalTimeInPort = estimatedLoadDischarge + holidaysNotWorked + idleDays;
			break;

		default:
		case LaytimeCalculationMethods.SHINC:
			timeToCount = estimatedLoadDischarge + holidaysNotWorked;
			totalTimeInPort = estimatedLoadDischarge + holidaysNotWorked + idleDays;
			break;
	}

	const workingDays = estimatedWorkingDaysOverride ?? (totalTimeInPort - idleDays);

	return {
		timeToCount: round(timeToCount, 2),
		totalTimeInPort: round(totalTimeInPort, 2),
		idleDays,
		workingDays,
	};
};
