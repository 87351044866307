// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jC3e6fnGn0QfFlgf53FH {\n\tposition: fixed;\n\tpointer-events: none;\n\tz-index: 100;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.UtZPFZhOo9I8It3zpeJ8 {\n\tcursor: grabbing;\n\tbackground-color: white;\n\tbox-shadow: #ccc 0 0 2px;\n\tpadding: 7px 0;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/DraggableList/private/DraggableListDragLayer.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,oBAAoB;CACpB,YAAY;CACZ,OAAO;CACP,MAAM;CACN,WAAW;CACX,YAAY;AACb;;AAEA;CACC,gBAAgB;CAChB,uBAAuB;CACvB,wBAAwB;CACxB,cAAc;AACf","sourcesContent":[".container {\n\tposition: fixed;\n\tpointer-events: none;\n\tz-index: 100;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.element {\n\tcursor: grabbing;\n\tbackground-color: white;\n\tbox-shadow: #ccc 0 0 2px;\n\tpadding: 7px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jC3e6fnGn0QfFlgf53FH",
	"element": "UtZPFZhOo9I8It3zpeJ8"
};
export default ___CSS_LOADER_EXPORT___;
