import { splitActionKey } from '@shared/utils/splitActionKey';
import {
	LaytimeIntervals,
	LaytimeTerms,
} from '@shared/utils/constants';
import { round } from '@shared/utils/math';
import type { CargoProps } from '@api/models/cargo';
import { sortByDates } from './sortByDates';
import {
	calculateAveragedLaytime,
	CargoPortsWithCalcs,
} from './laytimeStats/laytimeCalculations';
import {
	calculateDemurrage,
	calculateDespatch,
} from './laytime';
import normalize from './normalizeNumber';

export const getCargoPortLaytimeStats = (
	cargoPort: CargoPortsWithCalcs[number],
	cargo?: CargoProps & { CargoPorts: CargoPortsWithCalcs },
) => {
	const { action } = splitActionKey(cargoPort.portAndActionKey);
	const isHour = cargoPort.laytimeInterval === LaytimeIntervals.HOUR;
	const newestLaytimeCalculation = sortByDates(cargoPort.LaytimeCalculations, 'createdAt', { newestFirst: true })[0];

	if (newestLaytimeCalculation == null || newestLaytimeCalculation.timeUsedInMinutes === 0) {
		return {
			action,
			timeGainedOrLost: 0,
			totalDespatch: 0,
			totalDemurrage: 0,
		};
	}

	const timeAllowedInMinutes = normalize(cargoPort?.blTimeAllowed ?? 0) * (isHour ? 60 : (60 * 24));

	// Positive number - gained, negative - lost
	const timeGainedOrLost = normalize(timeAllowedInMinutes -
		(newestLaytimeCalculation?.timeUsedInMinutes ?? 0));
	const isDem = timeGainedOrLost <= 0;

	const timeInDays = round(Math.abs(timeGainedOrLost / 60 / 24), 2);

	if (cargo != null && cargo.laytimeTerms === LaytimeTerms.AVERAGED) {
		// @ts-ignore
		const result = calculateAveragedLaytime(cargo, {}, [cargoPort]);

		return {
			action,
			timeAllowedInMinutes,
			timeInDays,
			timeGainedOrLost,
			isDem,
			totalDemurrage: result.totalDemurrage,
			totalDespatch: result.totalDespatch,
		};
	}

	let demurrageRate = cargoPort.demurrage;
	let despatchRate = cargoPort.despatch;

	if (cargo != null && cargo.laytimeTerms === LaytimeTerms.REVERSIBLE) {
		demurrageRate = cargo.demurrage;
		despatchRate = cargo.despatch;
	}

	const totalDemurrage = normalize(calculateDemurrage(demurrageRate, timeGainedOrLost, isHour));
	const totalDespatch = normalize(calculateDespatch(despatchRate, timeGainedOrLost, isHour));

	return {
		action,
		timeAllowedInMinutes,
		timeInDays,
		timeGainedOrLost,
		isDem,
		totalDemurrage: timeGainedOrLost < 0 ? totalDemurrage : 0,
		totalDespatch: timeGainedOrLost > 0 ? totalDespatch : 0,
	};
};
