import React from 'react';
import { Space } from 'antd';
import { PeriodUnits } from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import Select, { SelectProps } from '@client/components/Select';
import styles from './styles/PeriodSelector.module.css';
import NumericInput, { NumericInputProps } from './NumericInput';

export type PeriodValue = [number, Values<typeof PeriodUnits>, number];

export type PeriodSelectorProps = {
	value: PeriodValue;
	onChange: (newValue: PeriodValue) => void;
	labelRight?: string | React.ReactElement;
	valueInputProps?: Omit<NumericInputProps, 'value' | 'onChange'>;
	unitInputProps?: Omit<SelectProps<Values<typeof PeriodUnits>>, 'value' | 'onChange'>;
	varianceInputProps?: Omit<NumericInputProps, 'value' | 'onChange'>;
}

const PeriodSelector: React.FC<PeriodSelectorProps> = ({
	onChange,
	value,
	labelRight,
	valueInputProps = {},
	unitInputProps = {},
	varianceInputProps = {},
}) => {
	const variance = value[2] || 0;
	const unit = value[1] || PeriodUnits.MONTHS;
	const periodValue = value[0];

	return (
		<div className={styles.wrapper}>
			<Space>
				<NumericInput
					{...valueInputProps}
					onChange={(newPeriodValue) => onChange([newPeriodValue ?? 0, unit, variance])}
					placeholder="Value"
					value={periodValue}
					className={styles.valueInput}
					allowNegative={false}
				/>
				<Select
					{...unitInputProps}
					className={styles.unitSelector}
					value={unit}
					onChange={(newUnit) => onChange([
						periodValue,
						newUnit as Values<typeof PeriodUnits>,
						variance,
					])}
					options={Object.values(PeriodUnits).map((u) => ({
						label: u.toLowerCase(),
						value: u,
					}))}
				/>
				<NumericInput
					{...varianceInputProps}
					prefix="±"
					suffix="days"
					className={styles.varianceInput}
					value={variance}
					onChange={(newVariance) => onChange([periodValue, unit, newVariance ?? 0])}
					allowNegative={false}
					max={999}
					integersOnly
				/>
			</Space>
			{labelRight != null && (
				<span className={styles.labelRight}>{labelRight}</span>
			)}
		</div>
	);
};

export default PeriodSelector;
