import React, { useMemo } from 'react';
import { Values } from '@shared/utils/objectEnums';
import {
	Departments,
	FixtureCounterpartyTypes,
} from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import type { GetCounterpartiesResponse } from '@api/features/counterparties/getCounterparties';
import type { GetConnectionAndMappingResponse } from '@api/features/accounting-system/getConnectionAndMapping';
import EditableTable from '@client/components/EditableTable/EditableTable';
import Card from '@client/components/Card/Card';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	createMappingEntry,
	getAccountingCustomers,
	getCounterparties,
	getUserData,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';

const CounterpartyMappingTab = ({
	connection,
	refreshConnection,
}: {
	connection: GetConnectionAndMappingResponse | undefined;
	refreshConnection: () => void;
}) => {
	const [userData] = useFetchedState(getUserData);

	const [counterparties, refreshCounterparties] = useFetchedState(getCounterparties);

	const [customers] = useFetchedState(async () => (
		connection == null ? [] : getAccountingCustomers(connection.id)
	), [connection]);

	const onSave = async (
		counterpartyId: number | undefined,
		update: Partial<GetCounterpartiesResponse[number] & {accountingCode: string}>,
	) => {
		const { accountingCode } = update;

		if (accountingCode != null && counterpartyId != null && connection != null) {
			try {
				await createMappingEntry(connection.id, 'fixtureCounterparty', counterpartyId, accountingCode);
				await refreshConnection();
				showSuccessNotification('Successfully mapped entry');
			} catch (e) {
				showErrorNotification('Could not create mapping', e as Error);
			}
		}

		refreshCounterparties();
	};

	const counterpartyList = useMemo(() => {
		if (
			counterparties == null ||
			connection == null
		) {
			return [];
		}

		const formatted = counterparties.map((cp) => {
			const mappingEntry = connection.AccountingMappingEntries.find((mapping) => (
				mapping.type === 'fixtureCounterparty' &&
				mapping.cvIdentifier === String(cp.id)
			));

			return {
				...cp,
				accountingCode: mappingEntry?.code ?? undefined,
			};
		});

		return formatted;
	}, [counterparties, connection]);

	return (
		<Card slim>
			<EditableTable
				actionsTitle=""
				enableEdit={userData?.departments.includes(Departments.FINANCE.key)}
				iconButtons
				pagination={false}
				keyDataIndex="id"
				onSave={onSave}
				dataSource={counterpartyList}
				columns={[
					{
						dataIndex: 'id',
						title: 'ID',
					},
					{
						dataIndex: 'name',
						title: 'Name',
					},
					{
						dataIndex: 'type',
						title: 'Type',
						render: (v: Values<typeof FixtureCounterpartyTypes>) => capitalize(v),
					},
					{
						dataIndex: 'accountingCode',
						title: 'Accounting System Entry',
						editingProps: {
							type: 'select',
							options: (customers ?? []).map((c) => ({
								label: c.name,
								value: c.id,
							})),

							inputProps: {
								placeholder: 'Select customer',
								showSearch: true,
							},
						},
						editable: true,
						render: (v: string | null) => (
							v == null ? (<i>Empty</i>) : customers?.find((c) => c.id === v)?.name
						),
					},
				]}
			/>
		</Card>
	);
};

export default CounterpartyMappingTab;
