import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Col,
	Row,
	Modal,
	Space,
	Progress,
	Typography,
	Divider,
	Result,
	Statistic,
	message,
	Grid,
} from 'antd';
import Confetti from 'react-confetti';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useWindowSize from 'react-use/lib/useWindowSize';
import {
	DeleteOutlined,
	DownOutlined,
	CompassOutlined,
} from '@ant-design/icons';
import {
	Bar,
	BarChart,
	Cell,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import classNames from 'classnames';
import {
	FixtureTypes,
	VesselOwnershipTypes,
} from '@shared/utils/constants';
import { fixtureTypeToName } from '@shared/utils/fixtureUtils';
import { calculateTotal } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { sortByDates } from '@shared/utils/sortByDates';
import { formatHumanReadable } from '@shared/utils/string';
import type {
	AutofilledFrom,
	GetFixtureDetailsResponse,
} from '@api/features/fixtures/getFixtureDetails';
import type { TcFixtureProps } from '@api/models/tc-fixture';
import type { BbFixtureProps } from '@api/models/bb-fixture';
import { formatDate } from '@client/utils/formatDate';
import FixtureSections from '@client/screens/fixtures/shared/FixtureSections';
import styles from '@client/screens/fixtures/shared/styles/FixtureDetailsScreen.module.css';
import SummaryCard from '@client/screens/fixtures/shared/SummaryCard';
import SearchableSelect from '@client/components/SearchableSelect';
import FixtureRecapGenerator from '@client/components/FixtureRecapGenerator';
import Button from '@client/components/Button';
import { editableFieldTypes } from '@client/components/EditableField';
import LoadingIndicator from '@client/components/LoadingIndicator';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import FixButton from '@client/screens/fixtures/shared/FixButton';
import { Links } from '@client/utils/links';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import {
	autofillFixture,
	deleteFixture,
	getFixtureDetails,
	getFixtures,
} from '@client/lib/api';
import getScrollBarWidth from '@client/utils/getScrollBarWidth';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { useAuth } from '@client/lib/auth';
import { ChartColors } from '@client/utils/constants';
import TooltipIcon from '@client/components/TooltipIcon';
import { useTcSectionItems } from '../tc/useTcSectionItems';
import { useTcSummaryItemGroups } from '../tc/useTcSummaryItemGroups';
import RateScheduleForm from '../tc/RateScheduleForm';

const FixtureDetailsScreen = ({
	fixtureType,
	fixtureId,
}: {
	fixtureType: Values<typeof FixtureTypes>;
	fixtureId: number;
}) => {
	const history = useHistory();
	const screens = Grid.useBreakpoint();
	const { userInfo } = useAuth();
	const { width, height } = useWindowSize();
	const scrollBarWidth = getScrollBarWidth();

	const [showConfetti, setShowConfetti] = useState(false);
	const [loadingAutoFill, setLoadingAutoFill] = useState(false);
	const [editValues, setEditValues] = useState({});
	const [fixtureRecapGeneratorOpen, setFixtureRecapGeneratorOpen] = useState(false);
	const [successModalOpen, setSuccessModalOpen] = useState(false);
	const [fixtureResult, setFixtureResult] = useState<any>(null);
	const [count, setCount] = useState(0);
	const [fixtureUpdating, setFixtureUpdating] = useState();
	const [rateScheduleDrawerOpen, setRateScheduleDrawerOpen] = useState<boolean | number>(false);
	const [
		fixture,
		refreshFixture,
		fixtureError,
		fixtureLoading,
	] = useFetchedState(
		() => getFixtureDetails(fixtureId),
		[fixtureId],
		{ showNotification: false },
	);

	useEffect(() => {
		if (fixtureUpdating) {
			message.loading({
				content: 'Saving...',
				key: 'fixtureUpdating',
				duration: 0,
				style: {
					marginLeft: '85vw',
				},
			});
		// Explicit false check, so we don't show it on page load, when it's undefined.
		} else if (fixtureUpdating === false) {
			message.success({
				content: 'Saved',
				key: 'fixtureUpdating',
				duration: 1,
				style: {
					marginLeft: '85vw',
				},
			});
		}
	}, [fixtureUpdating]);

	/* This effect slowly increases the value of forward coverage to animate the progress circle */
	useEffect(() => {
		let interval: ReturnType<typeof setInterval>;

		if (successModalOpen && fixture?.type === FixtureTypes.TC_OUT && fixtureResult != null) {
			const forwardHire = fixtureResult.currentData.fixedDaysPercent;
			interval = setInterval(() => {
				setCount((val) => {
					if (val >= forwardHire) {
						clearInterval(interval);

						return forwardHire;
					}

					return val + 0.75;
				});
			}, 50);
		}

		return () => {
			clearInterval(interval);
		};
	}, [successModalOpen, fixture, fixtureResult]);

	const [allFixtures] = useFetchedState(() => getFixtures(fixtureType));

	const previousFixtures = useMemo(() => (allFixtures == null ? [] : sortByDates(allFixtures, 'cpDate')
		.filter((f) => f.fixed && f.type === fixture?.type)),
	[allFixtures, fixture]);

	const autofill = useCallback(async (otherFixture: {id: number; identifier: string }) => {
		setLoadingAutoFill(true);

		try {
			await autofillFixture(fixtureId, otherFixture.id);
			await refreshFixture();

			showSuccessNotification('Previous estimate copied', `The terms of estimate ${otherFixture.identifier} have successfully been copied`);
		} catch (e) {
			showErrorNotification('Could not copy previous estimate', e as Error);
		}

		setLoadingAutoFill(false);

		return true;
	}, [fixtureId, refreshFixture]);

	const onDeleteFixture = useCallback(async () => {
		try {
			await deleteFixture(fixtureId);

			if (fixture != null) {
				showSuccessNotification('Estimate was deleted', `Estimate ${fixture.identifier} has successfully been deleted.`);
				history.push(Links.Fixtures[fixture.type].List.get());
			}
		} catch (e) {
			showErrorNotification('Could not delete estimate', e as Error);
		}
	}, [fixtureId, history, fixture]);

	const isFieldEntered = useCallback((field: { key: string; type: string; value: string }) => {
		const value = editValues[field.key] !== undefined ?
			editValues[field.key] :
			field.value;

		const type = editableFieldTypes[field.type];

		if (type != null && typeof type.isFilled === 'function') {
			return type.isFilled(value, field);
		}

		// If no custom isFilled is defined, just make sure it's not null nor blank
		return value != null && value !== '';
	}, [editValues]);

	const isFieldRequired = (field: {required: boolean; show: ((values: any) => void) | null}) => (
		field.required &&
		(
			field.show == null ||
			field.show(allValues)
		)
	);

	const sections = useTcSectionItems({
		fixture: fixture as GetFixtureDetailsResponse<TcFixtureProps | BbFixtureProps>,
		fixtureLoading,
		refreshFixture,
		setRateScheduleDrawerOpen,
	});

	const fixtureCurrency = useMemo(() => (
		fixture?.bankAccount?.currency ?? userInfo.baseCurrency),
	[fixture, userInfo]);

	const summaryItemGroups = useTcSummaryItemGroups({
		fixture,
	});

	const allValues = useMemo(() => {
		const fields = sections.reduce((arr: Array<any>, section) => [
			...arr,
			...(section.fields || []),
		], []);

		const fieldValues = fields.reduce((obj, field) => ({
			...obj,
			[field.key]: field.value,
		}), {});

		return {
			...fieldValues,
			...editValues,
		};
	}, [sections, editValues]);

	// how many required fields have been completed in total
	const totalRequiredCompleted = calculateTotal(
		sections,
		// @ts-ignore
		(s) => (s.fields || []).filter((f) => (
			isFieldRequired(f) &&
			isFieldEntered(f)
		)).length,
	);

	const totalRequiredFields = calculateTotal(
		sections,
		// @ts-ignore
		(s) => (s.fields || []).filter(isFieldRequired).length,
	);
	const missingRequiredFieldsCount = totalRequiredFields - totalRequiredCompleted;

	const barChartData = useMemo(() => {
		if (!successModalOpen || fixture?.type === FixtureTypes.TC_IN || fixtureResult == null) {
			return [];
		}

		return [{
			name: 'Previous',
			value: fixtureResult.previousData.forwardHire,
			color: ChartColors.LIGHTBLUE,
		},
		{
			name: 'Current',
			value: fixtureResult.currentData.forwardHire,
			color: ChartColors.BLUE,
		}];
	}, [fixtureResult, fixture, successModalOpen]);

	if (fixtureLoading) {
		return (<LoadingIndicator />);
	}

	return (
		<>
			{showConfetti && (
				<Confetti
					width={width - (scrollBarWidth ?? 0)}
					height={height}
					numberOfPieces={500}
					recycle={false}
					run={showConfetti}
					onConfettiComplete={() => setShowConfetti(false)}
				/>
			)}
			<SimpleScreen
				canGoBack
				breadcrumbs={
					fixture == null ?
						[] :
						[[
							`${fixture?.type != null ? fixtureTypeToName(fixture.type) : ''} Estimates`,
							Links.Fixtures[fixture.type]?.List?.get(),
						]]
				}
				title={fixture?.identifier || (fixture?.vessel?.name || '')}
				rootPageTitle="Estimate - TC"
				error={fixtureError}
				headerActions={[
					<FixButton
						key="fix"
						disabled={missingRequiredFieldsCount > 0 ||
							(fixture?.vessel && fixture.vessel.ownershipType === VesselOwnershipTypes.MARKET)}
						fixtureDetails={fixture}
						refreshFixtureDetails={refreshFixture}
						onFix={(result: any) => {
							setShowConfetti(true);

							setFixtureResult(result);

							if (result != null && fixture?.type === FixtureTypes.TC_OUT) {
								setSuccessModalOpen(true);
								setCount(result.previousData.fixedDaysPercent);
							}
						}}
					/>,
					!fixture?.fixed && [(
						<Button
							confirmTitle="Do you wish to delete this estimate?"
							key="delete"
							danger
							icon={(<DeleteOutlined />)}
							onClick={onDeleteFixture}
						>
							Delete
						</Button>
					)],
				]}
			>

				<Row gutter={[16, 16]}>
					<Col xs={24} sm={10} xl={8}>
						<SummaryCard
							itemGroups={summaryItemGroups}
							setFixtureRecapGeneratorOpen={setFixtureRecapGeneratorOpen}
							fixture={fixture}
							refreshFixture={refreshFixture}
							currency={fixtureCurrency}
						/>
					</Col>
					<Col xs={24} sm={14} xl={16}>
						<Card
							title="Estimate Terms"
							extra={fixture?.fixed && (
								<Link key="voyage-link" to={Links.Voyage.get(fixture?.voyageId)}>
									<Button
										className={styles.goToVoyage}
										type="link"
										icon={(<CompassOutlined />)}
									>
										Go to contract
									</Button>
								</Link>
							)}
						>
							<Row gutter={[12, 12]}>
								{screens.xs ? (
									<Col span={24}>
										<SearchableSelect
											disabled={fixture?.fixed}
											loading={loadingAutoFill}
											buttonProps={{
												className: classNames(
													styles.selectButton, { [styles.fullWidthButton]: screens.xs },
												),
												icon: (<DownOutlined />),
											}}
											buttonText={(fixture?.autofilledFrom == null ?
												'Copy Previous Estimate' :
												`As per ${fixture?.autofilledFrom.identifier}`
											)}
											items={previousFixtures}
											renderItem={(f: AutofilledFrom) => (
												<div className={styles.fixtureEntry}>
													<div>
														<span className={styles.identifier}>
															{f.identifier}
															{' '}
															<span className={styles.fixtureType}>
																{`- ${formatHumanReadable(f.type)?.toUpperCase()}`}
															</span>
														</span>
														<span>{`CP: ${formatDate(f?.cpDate)}`}</span>
													</div>
													<div>
														<span className={styles.identifier}>{f.counterpartyName}</span>
														<span>{f.vesselName}</span>
													</div>
												</div>
											)}
											searchProperties={['identifier', 'cpDate', 'counterpartyName', 'vesselName']}
											onSelect={autofill}
											popoverProps={undefined}
										/>
									</Col>
								) : (
									<Space align="center">
										<SearchableSelect
											disabled={fixture?.fixed}
											loading={loadingAutoFill}
											buttonProps={{
												className: styles.selectButton,
												icon: (<DownOutlined />),
											}}
											buttonText={(fixture?.autofilledFrom == null ?
												'Copy Previous Estimate' :
												`As per ${fixture?.autofilledFrom.identifier}`
											)}
											items={previousFixtures}
											renderItem={(f: AutofilledFrom) => (
												<div className={styles.fixtureEntry}>
													<div>
														<span className={styles.identifier}>
															{f.identifier}
															{' '}
															<span className={styles.fixtureType}>
																{`- ${formatHumanReadable(f.type)?.toUpperCase()}`}
															</span>
														</span>
														<span>{`CP: ${formatDate(f.cpDate)}`}</span>
													</div>
													<div>
														<span className={styles.identifier}>{f.counterpartyName}</span>
														<span>{f.vesselName}</span>
													</div>
												</div>
											)}
											searchProperties={['identifier', 'cpDate', 'counterpartyName', 'vesselName']}
											onSelect={autofill}
											popoverProps={undefined}
										/>
										<span className={styles.buttonText}>
											{(
												fixture?.autofilledFrom != null ?
													`${fixture.autofilledFrom.counterpartyName}, CP dated ${fixture.autofilledFrom.cpDate}` :
													''
											)}
										</span>
									</Space>
								)}
							</Row>
							<FixtureSections
								sections={sections}
								fixture={fixture}
								refreshFixture={refreshFixture}
								isFieldEntered={isFieldEntered}
								editValues={editValues}
								setEditValues={setEditValues}
								allValues={allValues}
								setFixtureUpdating={setFixtureUpdating}
							/>
						</Card>
					</Col>
				</Row>
				<FixtureRecapGenerator
					open={fixtureRecapGeneratorOpen}
					onClose={() => setFixtureRecapGeneratorOpen(false)}
					onSave={refreshFixture}
					fixtureId={fixtureId}
				/>
				<Modal
					open={successModalOpen}
					width={600}
					onCancel={() => setSuccessModalOpen(false)}
					closable={false}
					footer={(
						<Space>
							<Button onClick={() => setSuccessModalOpen(false)}>
								Close
							</Button>
							<Link key="voyage-link" to={fixture != null ? Links.Voyage.get(fixture.voyageId) : Links.Voyages.get()}>
								<Button
									className={styles.goToVoyage}
									type="primary"
									icon={(<CompassOutlined />)}
								>
									Go to contract
								</Button>
							</Link>
						</Space>
					)}
				>
					{fixtureResult != null && (
						<>
							<Typography.Title level={4}>
								<Result
									className={styles.slimmerResult}
									status="success"
									title={`${fixture?.vessel.name} has been fixed`}
									subTitle={(
										<>
											{'Estimated Gross Hire: '}
											{
												fixtureResult.grossHire != null &&
                                            fixtureResult.grossHire !== 0 &&
                                            (fixture != null &&
												fixtureCurrency != null) ?
													(
														<span style={{ color: 'rgb(63, 134, 0)' }}>
															{` ${formatCurrency(fixtureResult.grossHire, fixtureCurrency)}`}
														</span>
													) : (
														<span style={{ color: '#000' }}>
															—
															<TooltipIcon>
																Unable to estimate gross hire,
																as a hire rate has not been specified.
															</TooltipIcon>
														</span>
													)
											}
										</>
									)}
								/>
							</Typography.Title>
							<Divider className={styles.smallDivider}>
								Effects of fixing - Rest of year
							</Divider>
							<div className={styles.centeredWrapper}>
								<Row gutter={[16, 16]}>
									<Col
										span={12}
										className={styles.effectEntry}
									>
										<Statistic
											title="Forward Gross Hire"
											value={fixtureResult?.currentData?.forwardHire == null ? 'N/A' : formatCurrency(
												fixtureResult.currentData.forwardHire,
												userInfo.baseCurrency,
											)}
											suffix={(<div className={styles.forwardHireSuffix}>/day</div>)}
											precision={2}
											className={styles.hirePerDayStat}
										/>
										<ResponsiveContainer width="80%" height={200}>
											<BarChart data={barChartData}>
												<XAxis dataKey="name" />
												<YAxis />
												<Tooltip />
												<Bar animationDuration={3000} dataKey="value">
													{barChartData.map((e) => (
														<Cell fill={e.color} />
													))}
												</Bar>
											</BarChart>
										</ResponsiveContainer>
									</Col>
									<Col
										span={12}
										className={styles.effectEntry}
									>
										<Statistic
											title="Forward Coverage"
											value={fixtureResult?.currentData?.fixedDaysPercent ?? 0}
											precision={2}
											suffix="%"
											className={styles.forwardCoverageStat}
										/>
										<Progress
											type="circle"
											percent={count}
											format={() => (
												<span className={styles.forwardCoverageChange}>
													{`+${fixtureResult?.fixedDaysPercentChange ?? 0}%`}
												</span>
											)}
										/>
									</Col>
								</Row>
							</div>
						</>
					)}
				</Modal>
				{fixture?.type !== FixtureTypes.BB_OUT ? (
					<RateScheduleForm
						setRateScheduleDrawerOpen={setRateScheduleDrawerOpen}
						rateScheduleDrawerOpen={rateScheduleDrawerOpen}
						// @ts-ignore
						fixture={fixture}
						refreshFixture={refreshFixture}
						fixtureCurrency={fixtureCurrency}
					/>
				) :
					null}
			</SimpleScreen>
		</>
	);
};

export default FixtureDetailsScreen;
