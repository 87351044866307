import React, {
	SetStateAction,
	useMemo,
	useState,
} from 'react';
import {
	Card,
	Col,
	Grid,
	Input,
	List,
	Radio,
	Row,
	Space,
	Tabs,
	Typography,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons';
import useSearch from 'react-hook-search';
import { SearchOutlined } from '@ant-design/icons';
import { faInbox } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { CardProps } from 'antd/lib/card';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import { RootPages } from '@client/utils/links';
import LoadingIndicator from '../LoadingIndicator';
import RootScreen from './RootScreen';
import styles from './styles/ListDetailsScreen.module.css';

type Props<
	Items extends Array<Record<string, any>>,
	IdKey extends keyof Items[number]
> = {
	rootPageTitle: string;
	title: string;
	items: Items;
	tabs: Array<{
		key: string;
		title: string | React.ReactElement;
		filterItems: (i: Items[number]) => boolean;
	}
	>;
	getItemTitle: (item: Items[number], i: number, props: {}) => React.ReactElement | string | null;
	getItemInfo: (item: Items[number], i: number, props: {}) => any[];
	renderDetails: (
		item: Items[number],
		props: { resetSelectedItem?: React.Dispatch<SetStateAction<number | null>> }
	) => React.ReactElement;
	getDetailsCardProps: (i: Items[number], extraProps: any) => any;
	emptyText: string;
	searchAttributes: (string)[];
	idKey: IdKey;
	getItemExtra?: (item: Items[number], i: number, props: {}) => any;
	getItemClassName?: (item: Items[number], i: number, props: { active?: boolean }) => string;
	emptyIcon?: IconDefinition;
	listSpan?: number;
	titleButtons?: React.ReactElement | Array<React.ReactElement>;
	renderItem?: (item: Items[number], i: number, props: {}) => React.ReactElement;
	listProps?: CardProps;
	initialTab?: string;
	showSearch?: boolean;
	banner?: any;
	noItemSelectedText?: string;
	noItemSelectedIcon?: IconDefinition;
	loading?: boolean;
	selectedItem?: Items[number];
}

const ListDetailsScreen = <
	Items extends Array<Record<PropertyKey, any>>,
	Key extends keyof Items[number],
>({
	initialTab,
	rootPageTitle,
	title,
	titleButtons,
	listSpan = 12,
	listProps = {},
	items,
	tabs,
	// Either give some/all of the getItem* props to use premade layout
	// or give renderItem to completely customize it
	getItemTitle = () => null,
	getItemInfo = () => [],
	renderItem,
	renderDetails,
	getDetailsCardProps = () => ({}),
	getItemExtra = () => null,
	emptyText = 'No items',
	emptyIcon = faInbox as IconDefinition,
	showSearch = true,
	banner,
	searchAttributes = [],
	idKey,
	noItemSelectedText = 'Select an item',
	noItemSelectedIcon = faFolderOpen as IconDefinition,
	loading,
	selectedItem: selectedItemIdProp,
	...props
}: Props<Items, Key>) => {
	const [activeTab, setActiveTab] = useState(initialTab || (tabs != null ? tabs[0].key : null));
	const [selectedItemIdState, setSelectedItemId] = useState<string | null>(null);

	const screens = Grid.useBreakpoint();

	// Allow overwriting selected item id
	const selectedItemId = selectedItemIdProp || selectedItemIdState;

	const selectedItem = useMemo(() => (
		items.find((i) => i[idKey].toString() === selectedItemId)
	), [items, idKey, selectedItemId]);

	const tabItems = useMemo(() => {
		if (tabs == null) {
			return items;
		}

		const tab = tabs.find((t) => t.key === activeTab);

		if (tab == null) {
			return items;
		}

		return items.filter(tab.filterItems);
	}, [items, tabs, activeTab]);

	const [shownItems, searchValue, setSearchValue] = useSearch(tabItems, searchAttributes);

	const changeTab = (newTab: string, resetSelected = true) => {
		setActiveTab(newTab);

		if (resetSelected) {
			setSelectedItemId(null);
		}
	};

	const selectItem = (itemId: string) => {
		if (selectedItemId === itemId) {
			setSelectedItemId(null);

			return;
		}

		setSelectedItemId(itemId);
	};

	const extraProps = {
		activeTab,
		changeTab,
		selectedItem,
		shownItems,
		resetSelectedItem: () => setSelectedItemId(null),
	};

	const detailsCardProps = (selectedItem != null ?
		getDetailsCardProps(selectedItem, extraProps) :
		{}
	);

	return (
		<RootScreen
			rootPageTitle={rootPageTitle as typeof RootPages[number]}
			{...props}
		>
			<Row gutter={[16, 0]} className={styles.container}>
				<Col span={listSpan} className={screens.xs ? styles.mobileColumn : styles.column}>
					<Card
						{...listProps}
						className={classNames(styles.listCard, {
							[styles.hasSearch]: showSearch,
						})}
					>
						<div className={styles.header}>
							<div className={styles.titleWrapper}>
								<Typography.Title level={5} className={styles.title}>
									{title}
								</Typography.Title>
								<Space
									direction={screens.xs || !screens.xl ? 'vertical' : 'horizontal'}
									className={styles.titleButtons}
								>
									{titleButtons}
								</Space>
							</div>
							{tabs != null && tabs.length > 0 ? (
								<Tabs
									className={styles.tabs}
									activeKey={activeTab ?? undefined}
									onChange={changeTab}
									items={[
										...tabs.map((t) => ({
											key: t.key,
											label: t.title,
										})),
										{
											key: 'disabled-tab',
											label: 'Disabled Tab',
											disabled: true,
										},
									]}
								/>
							) : null}
							{banner != null && banner}
							{showSearch && (
								<Input
									onChange={(e) => setSearchValue(e.target.value)}
									value={searchValue}
									prefix={(
										<SearchOutlined className={styles.searchIcon} />
									)}
									placeholder="Search..."
									className={styles.searchBar}
									allowClear
								/>
							)}
						</div>
						{loading ? (
							<LoadingIndicator fullHeight />
						) : (
							<List
								className={classNames(styles.list, {
									[styles.listEmpty]: shownItems.length === 0,
								})}
								itemLayout="vertical"
								size="small"
								locale={{
									emptyText: (
										<div className={styles.emptyWrapper}>
											<FontAwesomeIcon
												icon={emptyIcon as Icon}
												className={styles.emptyIcon}
											/>
											<Typography.Title level={3} className={styles.emptyTitle}>
												{emptyText}
											</Typography.Title>
										</div>
									),
								}}
								dataSource={shownItems}
								renderItem={(item, index) => {
									return (
										<List.Item
											onClick={() => {
												const id = item[idKey].toString();

												if (id == null) {
													return;
												}

												selectItem(id);
											}}
											key={item[idKey].toString()}
											className={classNames(
												styles.item,
												{
													[styles.active]: item[idKey].toString() === selectedItemId,
												},
											)}
										>
											<div className={styles.itemInner}>
												<Radio
													checked={item[idKey].toString() === selectedItemId}
												/>
												{renderItem == null ? (
													<>
														<div className={styles.itemContent}>
															<div className={styles.itemTitle}>
																{getItemTitle(item, index, extraProps)}
															</div>
															<div
																className={styles.infoWrapper}
																style={screens.xs || !screens.md ? { display: 'inline-block', textAlign: 'left' } : {}}
															>
																{getItemInfo(item, index, extraProps).map((info, infoIndex) => (
																	<React.Fragment key={info.key}>
																		{(infoIndex > 0 && !screens.xs) && (
																			<div className={styles.infoSeparator} />
																		)}
																		<div className={styles.action}>
																			<Space>
																				{info.icon != null && (
																					<FontAwesomeIcon icon={info.icon} />
																				)}
																				{info.text}
																			</Space>
																		</div>
																	</React.Fragment>
																))}
															</div>
														</div>
														{getItemExtra(item, index, extraProps)}
													</>
												) : renderItem(item, index, extraProps)}
											</div>
										</List.Item>
									);
								}}
							/>
						)}
					</Card>
				</Col>
				<Col
					span={listSpan < 24 ? 24 - listSpan : 24}
					className={screens.xs ? styles.mobileColumn : styles.column}
				>
					{selectedItem == null ? (
						<Card className={styles.noItemSelectedWrapper}>
							<FontAwesomeIcon
								icon={noItemSelectedIcon as Icon}
								className={styles.noItemSelectedIcon}
							/>
							<Typography.Title level={3} className={styles.noItemSelectedTitle}>
								{noItemSelectedText}
							</Typography.Title>
						</Card>
					) : (
						<Card
							{...detailsCardProps}
							className={classNames(styles.detailsCard, detailsCardProps.className)}
						>
							{renderDetails(selectedItem, extraProps)}
						</Card>
					)}
				</Col>
			</Row>
		</RootScreen>
	);
};

export default ListDetailsScreen;
