import moment from 'moment';
import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import type { HIIHirePeriodType } from '@shared/hireInvoice/HIIHirePeriod';
import { PnlAmountCalculatorParams } from './index';

type GetPnlAmountAsEarnedCalculator = (item: HIIHirePeriodType) => (
	allItems: HireInvoiceItem[],
	parameters: PnlAmountCalculatorParams,
) => number;

const getPnlAmountAsEarnedCalculator: GetPnlAmountAsEarnedCalculator = (item) => (
	allItems,
	{
		pnlPeriodStart,
		pnlPeriodEnd,
	},
) => {
	const total = item.getTotal(allItems, false);

	const periodDays = item.to.diff(item.from, 'days', true);

	// Return an amount proportional to the overlap of the hire period
	const overlapRange = moment.range(item.from, item.to).intersect(
		moment.range(pnlPeriodStart, pnlPeriodEnd),
	);

	const overlapDays = overlapRange == null ? 0 : overlapRange.diff('days', true);

	if (periodDays === 0) {
		return 0;
	}

	return total * (overlapDays / periodDays);
};

export default getPnlAmountAsEarnedCalculator;
