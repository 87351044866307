// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p8Xsr3q3ENq76B7NYfrO {\r\n\tbackground-color: red !important;\r\n}\r\n\r\n.iWciBQu2HjFzM2bP76zv .rct-items > .rct-item {\r\n\tmin-width: 15px;\r\n\theight: 30px !important;\r\n\tborder-radius: 0;\r\n}\r\n", "",{"version":3,"sources":["webpack://./client/src/screens/general/HomeScreen/widgets/styles/FleetTimelineWidget.module.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;AACjC;;AAEA;CACC,eAAe;CACf,uBAAuB;CACvB,gBAAgB;AACjB","sourcesContent":[".todayMarker {\r\n\tbackground-color: red !important;\r\n}\r\n\r\n.fleetTimeline :global(.rct-items > .rct-item) {\r\n\tmin-width: 15px;\r\n\theight: 30px !important;\r\n\tborder-radius: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"todayMarker": "p8Xsr3q3ENq76B7NYfrO",
	"fleetTimeline": "iWciBQu2HjFzM2bP76zv"
};
export default ___CSS_LOADER_EXPORT___;
