import React, {
	ReactNode,
	useEffect,
	useState,
} from 'react';
import {
	Empty,
	Tabs,
} from 'antd';
import type { AttachmentProps } from '@api/models/attachment';
import AttachmentViewer from './AttachmentViewer';
import styles from './styles/DocumentDetails.module.css';

export type DocumentDetailsProps = {
	body?: ReactNode;
	attachments: Array<AttachmentProps>;
	initialShownAttachment?: number;
	setShownAttachment?: (attachmentId: number) => void;
	autoFocusFirst?: boolean;
}

const DocumentDetails: React.FC<DocumentDetailsProps> = ({
	body,
	attachments,
	initialShownAttachment,
	setShownAttachment,
	autoFocusFirst = false,
}) => {
	const [currentTab, setCurrentTab] = useState((
		initialShownAttachment != null ?
			initialShownAttachment.toString() :
			undefined
	));

	useEffect(() => {
		if (autoFocusFirst) {
			setCurrentTab(attachments[0]?.id.toString());
		}
	}, [autoFocusFirst, attachments]);

	return (
		<Tabs
			onChange={(t) => {
				setCurrentTab(t);

				if (typeof setShownAttachment === 'function') {
					setShownAttachment(Number(t));
				}
			}}
			activeKey={currentTab}
			destroyInactiveTabPane
			items={[
				...(body ? [{
					key: 'body',
					label: 'Body',
					children: (
						<pre>
							{body}
						</pre>
					),
				}] : []),
				...attachments.map((attachment) => ({
					key: attachment.id.toString(),
					label: (
						<span className={styles.tab}>
							{attachment.name}
							{attachment.cancelled && (
								<div className={styles.cancelledLine} />
							)}
						</span>
					),
					children: (
						<div className={styles.attachmentWrapper}>
							<AttachmentViewer attachment={attachment} />
						</div>
					),
				})),
				...(attachments.length === 0 && !body ? [{
					key: 'no-content',
					label: 'No content',
					children: (<Empty />),
				}] : []),
			]}
		/>
	);
};

export default DocumentDetails;
