import React from 'react';
import { Divider } from 'antd';
import { SUPPORT_EMAIL } from '@shared/utils/constants';
import Button from '@client/components/Button';
import { useAuth } from '@client/lib/auth';
import logo from '@client/assets/images/logo_black.png';
import ErrorPage from '@client/components/ErrorPage';
import styles from './UnauthorizedScreen.module.css';

const UnauthorizedScreen = ({
	accessError,
}) => {
	const {
		logout,
	} = useAuth();

	return (
		<div className={styles.unauthorizedWrapper}>
			<div className={styles.errorInnerWrapper}>
				<img
					className={styles.unauthorizedLogo}
					src={logo}
					alt="logo"
				/>
				<ErrorPage
					error={accessError}
				/>
				<Divider />
				<span className={styles.additionalInfo}>
					If you have questions or need assistance, please contact
					<br />
					our customer service at
					{' '}
					<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
					.
				</span>
				<br />
				<span className={styles.additionalInfo}>
					If you accidentally logged in with the wrong account,
					<br />
					please log out by clicking the button below
				</span>
				<Button
					type="primary"
					onClick={logout}
					className={styles.unauthorizedButton}
				>
					Log out
				</Button>
			</div>
		</div>
	);
};

export default UnauthorizedScreen;
