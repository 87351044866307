import moment from 'moment';

const overrideMomentToJson = (momentClass: typeof moment): void => {
	/* eslint-disable no-param-reassign,func-names */
	// @ts-ignore
	momentClass.fn.toJSON = function (): string {
		return this.toISOString(true);
	};
};

export default overrideMomentToJson;
