// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dUVS01UQ1H0YWCbDDRWo {\n\tdisplay: flex;\n\tjustify-content: center;\n\ttext-align: center;\n}\n\n.h_G6EA6wGJvN8uJ7BMr_ {\n\tmargin-bottom: 10px;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tflex-wrap: wrap;\n}\n\n.RNSCW0VpsY35KGkUPNmP {\n\tmargin-right: 3.5px;\n}\n\n.U1hZMJW4zwx27eftOu5E {\n\tmax-width: 80%;\n}\n\n.U1hZMJW4zwx27eftOu5E .ant-modal-body {\n\theight: calc(80vh - 55px);\n\toverflow: auto;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/AttachmentViewer.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,uBAAuB;CACvB,kBAAkB;AACnB;;AAEA;CACC,mBAAmB;CACnB,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,yBAAyB;CACzB,cAAc;AACf","sourcesContent":[".loadingWrapper {\n\tdisplay: flex;\n\tjustify-content: center;\n\ttext-align: center;\n}\n\n.downloadWrapper {\n\tmargin-bottom: 10px;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tflex-wrap: wrap;\n}\n\n.downloadIcon {\n\tmargin-right: 3.5px;\n}\n\n.modal {\n\tmax-width: 80%;\n}\n\n.modal :global(.ant-modal-body) {\n\theight: calc(80vh - 55px);\n\toverflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingWrapper": "dUVS01UQ1H0YWCbDDRWo",
	"downloadWrapper": "h_G6EA6wGJvN8uJ7BMr_",
	"downloadIcon": "RNSCW0VpsY35KGkUPNmP",
	"modal": "U1hZMJW4zwx27eftOu5E"
};
export default ___CSS_LOADER_EXPORT___;
