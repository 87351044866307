import React, { useMemo } from 'react';
import { Badge } from 'antd';
import classNames from 'classnames';
import {
	MillisecondsPer,
	AccountingItemApprovalStates,
} from '@shared/utils/constants';
import type { Values } from '@shared/utils/objectEnums';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import type { TransformedHireInvoices } from '@api/utils/getTransformedHireInvoices';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	getAccountingItemsForApproval,
	getVoyageExpenses,
} from '@client/lib/api';
import styles from './styles/ExpenseCountBadge.module.css';

const colorClasses = {
	red: styles.red,
	gray: styles.gray,
};

const AccountingItemCountBadge = ({
	state,
	items: itemsProps = null,
	className = undefined,
	color = 'red',
	types = 'expenses',
	...props
}: {
	state: Values<typeof AccountingItemApprovalStates>;
	children: React.ReactElement | string;
	types?: 'expenses' | 'both';
	items?: (TransformedExpenses | TransformedHireInvoices[number])[] | null;
	className?: string | undefined;
	color?: string;
}) => {
	let fn = getAccountingItemsForApproval;

	if (types === 'expenses') {
		fn = getVoyageExpenses;
	}

	const [items] = useFetchedState(async () => {
		if (itemsProps != null) {
			return itemsProps;
		}

		return fn();
	}, [itemsProps], { autoRefreshTime: 5 * MillisecondsPer.MINUTE });

	const count = useMemo(() => {
		const filteredItems = (items ?? []).filter((e) => e.state === state);

		return filteredItems.length;
	}, [items, state]);

	return (
		<Badge
			count={count}
			className={classNames(styles.badge, colorClasses[color], className)}
			offset={[12, 0]}
			{...props}
		/>
	);
};

export default AccountingItemCountBadge;
