import React, {
	createContext,
	ReactNode,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Moment } from 'moment';
import {
	DashboardPeriods,
	DashboardTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { useAuth } from '@client/lib/auth';
import { updateUser } from '@client/lib/api';

type Period = Values<typeof DashboardPeriods>;

export type DashboardComponent<Periods extends Period = Period> = React.FC<{
	period: Periods;
	setLastUpdated: React.Dispatch<SetStateAction<Moment | undefined>>;
}>;

export type Dashboard<Periods extends Period> = {
	key: Values<typeof DashboardTypes>;
	label: string;
	periodOptions: Periods[];
	component: DashboardComponent<Periods>;
	refresh: (
		dashboardType: Values<typeof DashboardTypes>,
		period: Values<typeof DashboardPeriods>
	) => void;
}

export type DashboardInfo = {
	period: Values<typeof DashboardPeriods>;
	setPeriod: (period: Values<typeof DashboardPeriods>) => void;
	setDashboard: (dashboardKey: Values<typeof DashboardTypes>) => void;
	lastUpdated: Moment | undefined;
	setLastUpdated: React.Dispatch<SetStateAction<Moment | undefined>>;
	selectedDashboard: Dashboard<Period>;
	dashboards: Array<Dashboard<Period>>;
	DashboardComponent: DashboardComponent<Period>;
};

const DashboardPeriodContext = createContext<Values<typeof DashboardPeriods> | null>(null);

// This may be used in widgets that rely on the dashboard period for fetching data
export const useDashboardPeriod = () => useContext(DashboardPeriodContext);

type DashboardInfoProviderProps = {
	dashboards: DashboardInfo['dashboards'];
	children: (options: DashboardInfo) => ReactNode;
}

const DashboardInfoProvider: React.FC<DashboardInfoProviderProps> = ({ children, dashboards }) => {
	const { userInfo, refreshAuthInfo } = useAuth();
	const [activeDashboard, setActiveDashboard] = useState(
		userInfo.defaultDashboard || userInfo.selectedDashboard || dashboards[0].key,
	);
	const [lastUpdated, setLastUpdated] = useState<Moment | undefined>();

	const selectedDashboard = dashboards.find((d) => d.key === activeDashboard);
	const periods = useMemo(
		() => selectedDashboard?.periodOptions ?? ['quarter', 'year', 'hundredtwentydays', 'sixtydays'] as Period[],
		[selectedDashboard?.periodOptions],
	);

	const [period, setPeriod] = useState<Values<typeof DashboardPeriods>>(
		periods[0],
	);

	useEffect(() => {
		if (selectedDashboard == null) {
			setActiveDashboard(dashboards[0].key);

			return;
		}

		if (
			periods != null && (
				period == null ||
				!periods.includes(period)
			)
		) {
			setPeriod(periods[0] as Values<typeof DashboardPeriods>);
		}
	}, [period, periods, selectedDashboard, activeDashboard, dashboards]);

	const setDashboard = async (key: Values<typeof DashboardTypes>) => {
		setActiveDashboard(key);
		await updateUser({ attributes: { selectedDashboard: key } });
		await refreshAuthInfo();
	};

	return (
		<DashboardPeriodContext.Provider value={period}>
			{selectedDashboard != null && (
				periods == null ||
				periods.includes(period!)
			) && children({
				period,
				setPeriod,
				setDashboard,
				lastUpdated,
				setLastUpdated,
				selectedDashboard,
				dashboards,
				DashboardComponent: selectedDashboard.component,
			})}
		</DashboardPeriodContext.Provider>
	);
};

export default DashboardInfoProvider;
