// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HdXgc7uNZLaidAMIrw3d {\n\twidth: auto;\n\theight: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: relative;\n\tborder: none;\n\tbackground: white;\n\tcursor: pointer;\n\tpadding: 0 16px;\n\toutline: none !important;\n}\n\n.HdXgc7uNZLaidAMIrw3d:hover {\n\tbackground: rgba(0, 0, 0, 0.05) !important;\n}\n\n.HdXgc7uNZLaidAMIrw3d::after {\n\tposition: absolute;\n\tcontent: '';\n\ttop: 0;\n\tbottom: 0;\n\tbackground: #d3dae6;\n\tleft: 0;\n\twidth: 1px;\n}\n\n.VvLP0SSrsSaWN4f0qlwP {\n\tfont-size: 14pt;\n}\n\n.VvLP0SSrsSaWN4f0qlwP.Knx5kt85OEHu_77aRrgH {\n\tpadding-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/TopBar/private/styles/TopBarButton.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,kBAAkB;CAClB,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,eAAe;CACf,wBAAwB;AACzB;;AAEA;CACC,0CAA0C;AAC3C;;AAEA;CACC,kBAAkB;CAClB,WAAW;CACX,MAAM;CACN,SAAS;CACT,mBAAmB;CACnB,OAAO;CACP,UAAU;AACX;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".button {\n\twidth: auto;\n\theight: 100%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: relative;\n\tborder: none;\n\tbackground: white;\n\tcursor: pointer;\n\tpadding: 0 16px;\n\toutline: none !important;\n}\n\n.button:hover {\n\tbackground: rgba(0, 0, 0, 0.05) !important;\n}\n\n.button::after {\n\tposition: absolute;\n\tcontent: '';\n\ttop: 0;\n\tbottom: 0;\n\tbackground: #d3dae6;\n\tleft: 0;\n\twidth: 1px;\n}\n\n.icon {\n\tfont-size: 14pt;\n}\n\n.icon.hasLabel {\n\tpadding-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "HdXgc7uNZLaidAMIrw3d",
	"icon": "VvLP0SSrsSaWN4f0qlwP",
	"hasLabel": "Knx5kt85OEHu_77aRrgH"
};
export default ___CSS_LOADER_EXPORT___;
