import React from 'react';
import { Typography } from 'antd';
import { Moment } from 'moment';
import {
	CrewReportTypes,
	DATE_AND_TIME,
} from '@shared/utils/constants';
import { TimeFormat } from '@shared/utils/date';
import type { StatementOfFactsReportProps } from '@api/models/statement-of-facts-report';
import { formatDate } from '@client/utils/formatDate';
import Details from '@client/components/Details';
import Table from '@client/components/Table/Table';
import EmptyText from '@client/components/EmptyText';

const StatementOfFactsReportDrawerContent = ({
	report,
	selectedTimeFormat,
}: {
	report: StatementOfFactsReportProps;
	selectedTimeFormat?: TimeFormat;
}) => {
	const detailItems = [
		{
			key: 'type',
			label: 'Type',
			value: CrewReportTypes.STATEMENT_OF_FACTS,
		},
		{
			key: 'portName',
			label: 'Current Port',
			value: report.portName,
		},
		{
			key: 'date',
			label: 'Date',
			value: formatDate(report.date, DATE_AND_TIME, true, selectedTimeFormat),
		},
		{
			key: 'remarks',
			label: 'Remarks',
			value: report.remarks,
			maxHeight: 200,
			style: {
				whiteSpace: 'pre-wrap',
				overflow: 'auto',
			} as React.CSSProperties,
		},
	];

	return (
		<>
			<Typography.Title level={5}>Details</Typography.Title>
			<Details
				items={detailItems}
				hideHeader
			/>
			<br />
			<Typography.Title level={5}>Activities</Typography.Title>
			<Table
				columns={[
					{
						title: 'Type',
						dataIndex: 'type',
						width: '20%',
					},
					{
						title: 'Date',
						dataIndex: 'date',
						render: (date: Moment) => formatDate(date, DATE_AND_TIME, true, selectedTimeFormat),
						width: '35%',
					},
					{
						title: 'Note',
						dataIndex: 'note',
						width: '45%',
						render: (value: string | null) => value ?? (<EmptyText />),
					},
				]}
				dataSource={report.activities}
				pagination={false}
				rowKey="id"
			/>
		</>
	);
};

export default StatementOfFactsReportDrawerContent;
