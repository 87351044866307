import React, { ReactNode } from 'react';
import {
	Checkbox,
	Form,
	Input,
} from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import { InputProps } from 'antd/lib/input/Input';
import { CheckboxProps } from 'antd/lib/checkbox';
import Select, {
	SelectOption,
	SelectProps,
} from '@client/components/Select';
import DatePicker, { DatePickerProps } from '@client/components/DatePicker';
import CurrencyInput, { CurrencyInputProps } from '@client/components/CurrencyInput';
import PercentageInput, { PercentageInputProps } from '@client/components/PercentageInput';
import CargoQuantityInput, { CargoQuantityInputProps } from '@client/components/CargoQuantityInput';
import MultiCurrencyInput, { MultiCurrencyInputProps } from '@client/components/MultiCurrencyInput';
import NumericInput, { NumericInputProps } from '@client/components/NumericInput';
import styles from '@client/components/styles/EditableTable.module.css';
import type { EditableColumn } from '@client/components/EditableTable';

export type EditableExtraProps<Row extends object> = {
	placeholder?: string;
	required?: boolean;
	renderExtra?: (p: { form?: FormInstance<Row> }) => ReactNode;
	form?: FormInstance<Row>;
	saveOnEnter?: boolean;
} & (
	{
		type: 'select';
		inputProps: SelectProps<(number | string | null)[]>;
		options: SelectOption[];
	}
	| {
		type: 'date';
		inputProps: DatePickerProps;
	}
	| {
		type: 'currency';
		inputProps: CurrencyInputProps;
	}
	| {
		type: 'percentage';
		inputProps: PercentageInputProps;
	}
	| {
		type: 'cargoQuantity';
		inputProps: CargoQuantityInputProps;
	}
	| {
		type: 'multiCurrency';
		inputProps: MultiCurrencyInputProps;
	}
	| {
		type: 'number';
		inputProps: NumericInputProps;
	}
	| {
		type: 'textarea';
		inputProps: TextAreaProps;
	}
	| {
		type: 'check';
		inputProps: CheckboxProps;
	}
	| {
		type: undefined;
		inputProps: InputProps;
	}
);

type EditableCellProps<Row extends object, KeyDataIndex extends keyof Row> = {
	col: EditableColumn<Row, KeyDataIndex>;
	editing: boolean;
	children?: ReactNode;
	save: (e: React.KeyboardEvent<any>) => void;
	extra?: EditableExtraProps<Row>;
	colSpan?: number;
};

export const EditableCell = <Row extends object, KeyDataIndex extends keyof Row>({
	col,
	editing,
	children,
	save,
	extra,
	colSpan,
}: EditableCellProps<Row, KeyDataIndex>) => {
	const {
		title,
		dataIndex,
		align,
	} = (col || {});

	let input;

	const inputFieldProps = {
		placeholder: extra?.placeholder ?? title as string,
		onPressEnter: extra?.saveOnEnter ? save : undefined,
		style: { textAlign: align },
	};

	switch (extra?.type) {
		case 'select':
			input = (
				<Select
					placeholder={extra.placeholder || title as string}
					options={extra.options}
					{...extra.inputProps}
				/>
			);
			break;

		case 'date':
			input = (
				<DatePicker {...extra.inputProps} />
			);
			break;

		case 'currency':
			input = (
				<CurrencyInput asAsync {...inputFieldProps} {...extra.inputProps} />
			);
			break;

		case 'percentage':
			input = (
				<PercentageInput asAsync {...inputFieldProps} {...extra.inputProps} />
			);
			break;

		case 'cargoQuantity':
			input = (
				<CargoQuantityInput asAsync {...extra.inputProps} />
			);
			break;

		case 'multiCurrency':
			input = (
				<MultiCurrencyInput asAsync {...extra.inputProps} />
			);
			break;

		case 'number':
			input = (
				<NumericInput asAsync {...inputFieldProps} {...extra.inputProps} />
			);
			break;

		case 'check':
			input = (
				<Checkbox {...extra.inputProps} />
			);
			break;

		case 'textarea':
			input = (
				<Input.TextArea
					{...inputFieldProps}
					{...extra.inputProps}
					onPressEnter={(e) => {
						if (e.shiftKey || e.metaKey) {
							save(e);

							e.preventDefault();

							return false;
						}

						return undefined;
					}}
				/>
			);
			break;

		default:
			input = (
				<Input {...inputFieldProps} {...extra?.inputProps} />
			);
			break;
	}

	return (
		<td colSpan={colSpan} style={{ textAlign: align }}>
			{editing ? (
				<>
					<Form.Item
						className={styles.editableCell}
						name={dataIndex as string}
						rules={extra?.required ? [
							{
								required: true,
								message: `${title} is required`,
							},
						] : []}
					>
						{input}
					</Form.Item>
					{extra?.renderExtra?.({ form: extra.form })}
				</>
			) : children}
		</td>
	);
};
