// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nYhuJ4SUpD65MKBXZGCK {\n\tposition: fixed;\n\tpointer-events: none;\n\tz-index: 100;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.EDG4gmEJ8yrsOM6RXTzs {\n\ttransform: rotate(7deg);\n\tborder: 1px solid lightgray;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/PdfGenerator/private/styles/CustomDragLayer.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,oBAAoB;CACpB,YAAY;CACZ,OAAO;CACP,MAAM;CACN,WAAW;CACX,YAAY;AACb;;AAEA;CACC,uBAAuB;CACvB,2BAA2B;AAC5B","sourcesContent":[".container {\n\tposition: fixed;\n\tpointer-events: none;\n\tz-index: 100;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100%;\n}\n\n.rotated {\n\ttransform: rotate(7deg);\n\tborder: 1px solid lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "nYhuJ4SUpD65MKBXZGCK",
	"rotated": "EDG4gmEJ8yrsOM6RXTzs"
};
export default ___CSS_LOADER_EXPORT___;
