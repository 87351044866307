import HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import type { RowType } from '@client/screens/fleet/VoyageDetailsScreen/components/CumulativeComparison';

const isItemDeleted = (itemsToDelete: HireInvoiceItem[], row: HireInvoiceItem | RowType) => (
	itemsToDelete.find((i) => (
		i.id === row.id ||
		i.id === row.parentId
	)) != null
);

export default isItemDeleted;
