import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Button, { ButtonProps } from '@client/components/Button';

const AddButton = ({
	className = undefined,
	...props
}: ButtonProps) => (
	<Button
		type="primary"
		className={className}
		icon={(<PlusOutlined />)}
		{...props}
	/>
);

export default AddButton;
