import React from 'react';
import {
	DATE_AND_TIME,
	DATE,
	FixtureTypeLabels,
} from '@shared/utils/constants';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import { formatDate } from '@client/utils/formatDate';
import VesselDescription from '@client/components/VesselDescription';
import LinkButton from '@client/components/LinkButton';
import { Links } from '@client/utils/links';

type getSpotFixtureItemsProps = {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
}

const getSpotFixtureItems = ({
	voyageDetails: {
		identifier,
		vesselId,
		vesselName,
		vesselFlag,
		createdAt,
	},
	fixtureDetails,
}: getSpotFixtureItemsProps) => {
	const {
		identifier: fixtureIdentifier,
		cpDate,
		type,
	} = fixtureDetails;

	return [
		{
			key: 'fixtureId',
			label: 'Estimate',
			value: (
				<LinkButton
					url={Links.Fixtures[type]
						.Details.get(fixtureDetails.estimateGroupId, fixtureDetails.estimateId)}
				>
					{fixtureIdentifier}
				</LinkButton>
			),
		},
		{
			key: 'fixtureType',
			label: 'Estimate Type',
			value: FixtureTypeLabels[type],
		},
		{
			key: 'vesselId',
			label: 'Vessel',
			value: (
				<LinkButton
					url={Links.Vessel.get(vesselId)}
				>
					<VesselDescription
						name={vesselName}
						flag={vesselFlag}
					/>
				</LinkButton>
			),
		},
		{
			key: 'identifier',
			label: 'Identifier',
			value: identifier,
		},
		{
			key: 'cpDate',
			label: 'CP Date',
			value: formatDate(cpDate, DATE),
		},
		{
			key: 'createdAt',
			label: 'Creation Date',
			value: formatDate(createdAt, DATE_AND_TIME),
			editable: false,
		},
	];
};

export default getSpotFixtureItems;
