// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OSTJvaJhPmhJ23YRaBgm.ant-typography {\n\tcolor: green;\n}\n\n.Mh6r3YItDG9gB0b2SB2t.ant-typography {\n\tcolor: red;\n}\n\n.ySWEmaIdxp9jiDxb9Wrc.ant-typography {\n\tcolor: black;\n}\n\n.s1Eq7v6Vg8b65xkShdFA.ant-typography {\n\tcolor: gray;\n\tfont-size: 14px;\n\tfont-style: italic;\n\tfont-weight: 400;\n\tmargin-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/tabs/components/Stats.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,WAAW;CACX,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,gBAAgB;AACjB","sourcesContent":[".positiveTotalLabel:global(.ant-typography) {\n\tcolor: green;\n}\n\n.negativeTotalLabel:global(.ant-typography) {\n\tcolor: red;\n}\n\n.neutralTotalLabel:global(.ant-typography) {\n\tcolor: black;\n}\n\n.rateLabel:global(.ant-typography) {\n\tcolor: gray;\n\tfont-size: 14px;\n\tfont-style: italic;\n\tfont-weight: 400;\n\tmargin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"positiveTotalLabel": "OSTJvaJhPmhJ23YRaBgm",
	"negativeTotalLabel": "Mh6r3YItDG9gB0b2SB2t",
	"neutralTotalLabel": "ySWEmaIdxp9jiDxb9Wrc",
	"rateLabel": "s1Eq7v6Vg8b65xkShdFA"
};
export default ___CSS_LOADER_EXPORT___;
