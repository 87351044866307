import TemplateItem from '@shared/TemplateItem';
import { InvoiceTemplates } from '@shared/utils/constants';
import { formatCurrency } from '../utils/currency';
import HireInvoiceItem from './HireInvoiceItem';

class HIICreditNote extends HireInvoiceItem {
	static get itemType() {
		return 'creditNote';
	}

	constructor(id, isOriginal, params = {}) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this.amount = params.amount;
		this.description = params.description;

		this._showTotal = false;
		this.itemTypeLabel = 'Credit Note';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.OTHER;

		this._fields = {};
	}

	_calculateTotal(_invoiceItems) {
		// Credit note amounts should be positive
		return this.amount;
	}

	getDescription() {
		return this.description;
	}

	static _getClassParams(_model, _parentModel) {
		throw new Error('Cannot run _getClassParams on HIICreditNote');
	}

	async saveModel(_model) {
		throw new Error('Cannot run saveModel on HIICreditNote');
	}

	_getTemplateItemParams() {
		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					this.description,
					formatCurrency(this.amount, this.fixtureCurrency),
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					this.description,
					'1',
					' ',
					formatCurrency(this.amount, this.fixtureCurrency),
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIICreditNote);

export default HIICreditNote;
