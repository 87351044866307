/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import {
	Form,
	Space,
	Tabs,
} from 'antd';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/pro-light-svg-icons';
import FormSection from './FormSection';
import styles from './styles/Settings.module.css';

type SettingsEntry = {
	label: string | React.ReactElement;
	content: ReactNode;
	className?: string;
	hide?: boolean;
	error?: boolean;
}

type SettingsArray = SettingsEntry[];

type SettingsObject = Record<string, SettingsArray>;

export interface SettingsProps {
	settings?: SettingsArray | SettingsObject;
	defaultOpen: boolean;
}

const hasErrorCheck = (settings: SettingsArray | SettingsObject) => {
	// Check if settings is an array and search for errors
	if (Array.isArray(settings)) {
		return settings.some((entry) => entry.error);
	}

	// Check if settings is an object and search for errors in each array
	if (typeof settings === 'object') {
		return Object.values(settings).some((group) => group.some((entry) => entry.error));
	}

	// Return false if settings is neither an array nor an object, or no errors found
	return false;
};

const Settings: React.FC<SettingsProps> = ({
	settings,
	defaultOpen,
}) => {
	if (
		settings == null ||
		(Array.isArray(settings) && settings.length === 0) ||
		(typeof settings === 'object' && Object.keys(settings).length === 0)
	) {
		return null;
	}

	const renderTab = (settingsArray: SettingsArray) => (
		settingsArray
			.filter((e) => !e.hide)
			.map(({
				content,
				label,
				className,
				hide: _hide,
				...props
			}) => (
				<Form.Item
					className={classNames(styles.formItem, className)}
					key={typeof label === 'string' ? label : null}
					label={label}
					{...props}
				>
					{content}
				</Form.Item>
			))
	);

	const hasError = hasErrorCheck(settings);

	return (
		<FormSection
			title={(
				<Space>
					Settings
					{hasError && (
						<div style={{ color: 'orangered' }}>

							<FontAwesomeIcon icon={faWarning} />
						</div>
					)}
				</Space>
			)}
			defaultOpen={defaultOpen}
		>
			{Array.isArray(settings) ? renderTab(settings) : (
				<Tabs
					size="small"
					items={Object.entries(settings).map(([title, settingsArray]) => {
						const sectionHasError = settingsArray.some((e) => e.error != null && e.error);

						return {
							key: title,
							label: !sectionHasError ? title : (
								<div style={{ color: 'orangered' }}>
									<Space size="small">
										<FontAwesomeIcon icon={faWarning} />
										{title}
									</Space>
								</div>
							),
							children: renderTab(settingsArray),
							className: sectionHasError ? styles.error : undefined,
						};
					})}
				/>
			)}
		</FormSection>
	);
};

export default Settings;
