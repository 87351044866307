import React from 'react';
import {
	InputNumber,
	InputNumberProps,
} from 'antd';
import styles from './styles/misc.module.css';

const TemporaryNumberInput = ({
	...props
}: InputNumberProps) => {
	return (
		<InputNumber
			className={styles.fullWidth}
			variant="borderless"
			type="number"
			{...props}
		/>
	);
};

export default TemporaryNumberInput;
