import React from 'react';
import Table, { TableProps } from '@client/components/Table/Table';
import DashboardWidgetWrapper, {
	DashboardWidgetProps,
	FetchDataParams,
} from './DashboardWidgetWrapper';

type TableWidgetProps<Row, Data, Params extends FetchDataParams> = DashboardWidgetProps<{
	transformData?: (data: Data) => Row[];
	columns: TableProps<Row>['columns'];
	rowKey: string;
	tableProps?: Partial<TableProps<Row>>;
}, 'renderWidget' | 'extra', Data, Params>;

// Currently, this widget needs `fetchData` to return an array.
// However, it should be allowed to fetch data that is *not* an array,
// as long as you provide the `transformData` prop to turn it into an array of rows.
// To be typed later...
const TableWidget = <
	Row extends object,
	Data extends any[],
	Params extends FetchDataParams,
>({
	columns,
	rowKey,
	tableProps,
	transformData,
	...props
}: TableWidgetProps<Row, Data, Params>) => (
	<DashboardWidgetWrapper
		{...props}
		initialData={[] as unknown as Data}
		fullWidth
		renderWidget={({ data, loading }) => (
			<Table<Row>
				{...(tableProps || {})}
				rowKey={rowKey}
				dataSource={typeof transformData === 'function' ? transformData(data!) : data}
				columns={columns}
				loading={loading}
			/>
		)}
	/>
);

export default TableWidget;
