import {
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import getHireChildPnlAmountCalculator from '@shared/utils/pnlAmountCalculators/getHireChildPnlAmountCalculator';
import { formatPercentage } from '@shared/utils/formatPercentage';
import TemplateItem from '../TemplateItem';
import HIIHirePeriod from './HIIHirePeriod';
import HireInvoiceItem, { HIIConstructorParams } from './HireInvoiceItem';

export type HIIHireBrokerCommissionType = HIIHireBrokerCommission;

class HIIHireBrokerCommission extends HireInvoiceItem {
	static get itemType() {
		return 'brokerCommission';
	}

	percentage: number;
	brokerId: number;
	brokerName: string;

	constructor(
		id: number,
		isOriginal: boolean,
		params: HIIConstructorParams & {
			percentage: number;
			brokerId: number;
			brokerName: string;
		},
	) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.brokerId = params.brokerId;
		this.brokerName = params.brokerName;
		this.percentage = params.percentage;

		this._showTotal = true;
		this.itemTypeLabel = 'Broker Commission';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.COMMISSIONS;
		this.pnlGroup = PnlGroups.BROKER_COMMISSIONS;
		this.pnlAmountCalculator = getHireChildPnlAmountCalculator(this);

		this._fields = {};
	}

	_calculateTotal(invoiceItems: HireInvoiceItem[]) {
		const hirePeriod = this.getParent(invoiceItems);

		if (hirePeriod == null) {
			return 0;
		}

		return -1 * hirePeriod.getTotal(invoiceItems) * (
			this.percentage != null ? (this.percentage / 100) : 1
		);
	}

	getDescription(invoiceItems: HireInvoiceItem[]) {
		const parent = this.getParent(invoiceItems);

		if (parent == null) {
			return '';
		}

		if (parent?.children.filter((c) => c instanceof HIIHireBrokerCommission).length > 1) {
			return `${formatPercentage(this.percentage, 2)}, ${this.brokerName}`;
		}

		return `${formatPercentage(this.percentage, 2)}`;
	}

	static async _getClassParams(model: any, parentModel: any) {
		const broker = await model.getBroker(parentModel);

		return {
			brokerId: model.brokerId,
			brokerName: broker.name,
			percentage: broker.BrokerInFixture.commission,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);

		model.brokerId = this.brokerId;

		await model.save();
	}

	_getTemplateItemParams(invoiceItems: HireInvoiceItem[]) {
		const hirePeriod = this.getParent(invoiceItems) as HIIHirePeriod;

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: ['Broker commission', this.getDescription(invoiceItems)],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					' — Broker commission',
					`${formatPercentage(this.percentage, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(hirePeriod.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return this.percentage === other.percentage;
	}
}

HireInvoiceItem.addItemClass(HIIHireBrokerCommission);

export default HIIHireBrokerCommission;
