import React from 'react';
import styles from './styles/CircledNumber.module.css';

const CircledNumber = ({ number }: {number: number}) => (
	<div className={styles.circle}>
		<div className={styles.badge}>
			{number}
		</div>
	</div>
);

export default CircledNumber;
