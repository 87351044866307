import React, {
	useState,
	useEffect,
	useCallback,
	useMemo,
} from 'react';
import {
	Form,
	Input,
	Drawer,
	Modal,
	Space,
	Checkbox,
	InputNumber,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Moment } from 'moment';
import {
	AccountTypes,
	Currencies,
	FixtureTypes,
} from '@shared/utils/constants';
import { getPayerString } from '@shared/utils/string';
import { asyncMap } from '@shared/utils/array';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { AttachmentProps } from '@api/models/attachment';
import type Supplier from '@api/models/supplier';
import Select from '@client/components/Select';
import Button from '@client/components/Button';
import {
	createVoyageExpenseReceivable,
	getSuppliers,
	getVoyageExpenses,
	updateVoyageExpenseReceivable,
} from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import useFileUpload from '@client/utils/hooks/useFileUpload';
import InvoiceAttachment from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceAttachment';
import getFileFromAttachment from '@client/utils/getFileFromAttachment';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import useSeed from '../utils/hooks/useSeed';
import styles from './styles/CreateVoyageExpenseReceivableForm.module.css';
import LoadingIndicator from './LoadingIndicator';
import MultiCurrencyInput from './MultiCurrencyInput';
import DatePicker from './DatePicker';

type CreateVoyageExpenseReceivableFormProps = {
	open: boolean;
	onClose: () => void;
	voyageId: number;
	onInvoiceCreated: () => void;
	fixtureCurrency: Values<typeof Currencies>;
	fixtureType: Values<typeof FixtureTypes>;
	form?: FormInstance;
	editingExpense?: GetVoyageDetailsResponse['voyageExpenseReceivables'][number] & {
		supplier?: Supplier;
		invoiceDate?: Moment;
	} | null;
}

export type ExtendedFileType = File & {
	uid?: string;
	attachmentId?: number;
}

type NonUndefinedExtendedFileType = File & {
	uid: string;
	attachmentId: number;
}

const required = [{ required: true, message: 'Field is required' }];

const CreateVoyageExpenseReceivableForm = ({
	open,
	onClose,
	form: formOverride,
	voyageId,
	editingExpense,
	onInvoiceCreated,
	fixtureCurrency,
	fixtureType,
}: CreateVoyageExpenseReceivableFormProps) => {
	const isOpen = open || editingExpense != null;

	const [form] = Form.useForm(formOverride);
	const [dirty, setDirty] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [loadingAttachments, setLoadingAttachments] = useState(false);
	const [suppliers] = useFetchedState(getSuppliers);
	const [addInput, setAddInput] = useState(false);

	const isEditingExpense = useMemo(() => {
		if (editingExpense == null) {
			return false;
		}

		return true;
	}, [editingExpense]);

	const {
		uploaded,
		uploadProps,
		onRemoveUpload,
		onSetUploaded,
		onResetUploaded,
	} = useFileUpload({ multiple: true });

	const [expenses, refreshExpenses] = useFetchedState(async () => {
		if (open) {
			return await getVoyageExpenses();
		}

		return undefined;
	});

	useEffect(() => {
		onResetUploaded();
		refreshExpenses();
	}, [isOpen, onResetUploaded, refreshExpenses]);

	const selectedExpenseId = form.getFieldValue('expenseId');

	useEffect(() => {
		const selectedExpense = (expenses ?? []).find((e) => e.id === selectedExpenseId);

		const updateDefaultFiles = async () => {
			setLoadingAttachments(true);

			try {
				const getUploadObject = async (attachment: AttachmentProps) => {
					const file: ExtendedFileType = await getFileFromAttachment(attachment);

					file.uid = `${file.name}-${file.lastModified}`;
					file.attachmentId = attachment.id;

					return {
						file: file as NonUndefinedExtendedFileType,
						url: window.URL.createObjectURL(file),
					};
				};

				let files: Array<{
					file: NonUndefinedExtendedFileType;
					url: string;
					deleteable?: boolean;
				}> = [];

				if (editingExpense != null) {
					files = await asyncMap(
						editingExpense.attachments,
						async (attachment) => getUploadObject(attachment),
					);
				}

				if (selectedExpense?.attachments != null) {
					const expenseAttachments = selectedExpense.attachments;

					const expenseFiles = await asyncMap(
						expenseAttachments,
						async (attachment) => {
							const uploadObj = await getUploadObject(attachment);

							return {
								file: uploadObj.file as NonUndefinedExtendedFileType,
								url: uploadObj.url,
								deleteable: false,
							};
						},
					);

					files = [
						...expenseFiles,
						...files,
					];
				}

				onSetUploaded(files);
			} catch (e) {
				showErrorNotification('Could not load attachments', e as Error);
				close();
			}

			setLoadingAttachments(false);
		};

		updateDefaultFiles();
	// Don't include onClose
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editingExpense, selectedExpenseId, onSetUploaded]);

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	useBlocker(dirty);

	// Used to refresh the whole component when necessary
	const [_seed, refreshSeed] = useSeed();

	const editingExpenseId = (editingExpense || {}).id;

	useEffect(() => {
		if (editingExpense == null) {
			return;
		}

		form.setFieldsValue({
			expenseId: editingExpense.voyageExpenseId,
			account: editingExpense.account,
			itemDescription: editingExpense.itemDescription,
			customInvoiceId: editingExpense.customInvoiceId,
			supplier: editingExpense.supplier,
			invoiceDate: editingExpense.invoiceDate,
			amount: {
				value: editingExpense.amount,
				currency: editingExpense.currency,
				exchangeRate: editingExpense.exchangeRate,
			},
			note: editingExpense.note,
			quantity: editingExpense.quantity,
			unit: editingExpense.unit,
			unitPrice: {
				value: editingExpense.unitPrice,
				currency: editingExpense.currency,
				exchangeRate: editingExpense.exchangeRate,
			},
		});

		if (editingExpense.quantity || editingExpense.unitPrice || editingExpense.unit) {
			setAddInput(true);
		} else {
			setAddInput(false);
		}

		refreshSeed();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, editingExpenseId, refreshSeed]);

	const close = () => {
		onClose();
		setDirty(false);
		form.resetFields();
		refreshSeed();
	};

	const onCloseForm = makeBlockable(close);

	const valuesChange = useCallback((values: { changedField: string; newValue: string }) => {
		setDirty(true);

		const [[changedField, newValue]] = Object.entries(values);

		switch (changedField) {
			case 'account':
				refreshSeed();
				break;
			case 'expenseId':
				if (newValue != null) {
					const selectedExpense = (expenses ?? []).find((e) => e.id === Number(newValue));
					const newValues = {
						amount: selectedExpense?.amount,
						itemDescription: selectedExpense?.itemDescription,
						...(editingExpense || {}),
						...form.getFieldsValue(),
						account: AccountTypes.CHARTERER,
					};

					form.setFieldsValue(newValues);
				}

				refreshSeed();
				break;

			default:
				break;
		}
	}, [expenses, refreshSeed, form, editingExpense]);

	const submit = async () => {
		try {
			setSaveLoading(true);

			const {
				itemDescription,
				amount,
				note,
				customInvoiceId,
				account,
				expenseId,
				supplier,
				invoiceDate,
			} = form.getFieldsValue(true);

			let {
				quantity,
				unit,
				unitPrice,
			} = form.getFieldsValue(true);

			if (!addInput) {
				quantity = null;
				unit = null;
				unitPrice = null;
			}

			try {
				const params = {
					voyageId,
					itemDescription,
					customInvoiceId,
					amount,
					account,
					note,
					voyageExpenseId: expenseId,
					supplier,
					invoiceDate,
					quantity,
					unit,
					unitPrice,
				};

				if (!addInput) {
					quantity = null;
					unit = null;
					unitPrice = null;
				}

				const {
					value: amountNumber,
					currency,
				} = amount;

				const {
					value: unitPriceValue,
					currency: unitPriceCurrency,
				} = unitPrice || {};

				const total = unitPriceValue * quantity;

				const { exchangeRate } = unitPrice || amount;

				const transformedUploaded = Array.isArray(uploaded) ? uploaded : [uploaded];

				const files = transformedUploaded.map((u) => u.file);
				const newFiles = files.filter((f: ExtendedFileType) => f.attachmentId == null);
				const existingFiles: ExtendedFileType[] =
					files.filter((f: ExtendedFileType) => f.attachmentId != null);

				if (editingExpense == null) {
					await createVoyageExpenseReceivable({
						...params,
						amount: total || amountNumber,
						currency: unitPriceCurrency ?? currency,
						exchangeRate,
						attachments: newFiles,
						unitPrice: unitPriceValue,
					});
				} else {
					const existingAttachments: number[] = [];

					existingFiles.forEach(
						(f) => f.attachmentId != null && existingAttachments.push(f.attachmentId),
					);

					await updateVoyageExpenseReceivable(
						voyageId,
						editingExpense.id,
						{
							...params,
							amount: total || amountNumber,
							currency: unitPriceCurrency ?? currency,
							exchangeRate,
							unitPrice: unitPriceValue,
						},
						newFiles,
					);
				}

				await refreshExpenses();

				showSuccessNotification(
					'Invoice saved',
					'The invoice was successfully saved',
				);

				form.resetFields();

				close();

				if (onInvoiceCreated && typeof onInvoiceCreated === 'function') {
					onInvoiceCreated();
				}
			} catch (e) {
				console.error(e);
				showErrorNotification('Could not save voyage expense receivable', e as Error);
			}
		} finally {
			setSaveLoading(false);
		}
	};

	const handleModalBackgroundClick = (
		e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
	) => {
		if (e.target === e.currentTarget) {
			setAddInput(!addInput);
			onCloseForm();
		}
	};

	const expenseReceivableAccountTypes = {
		...AccountTypes,
		BOTH: 'payable_and_receivable',
	};

	const selectedAccount = form.getFieldsValue().account;

	interface CustomFieldState {
		exchangeRate: number | null;
		quantity: number | null;
		unitPrice: number | null;
	}

	const [customFieldState, setCustomFieldState] = useState<CustomFieldState>({
		exchangeRate: null,
		quantity: null,
		unitPrice: null,
	});

	const { exchangeRate, quantity, unitPrice } = customFieldState;

	const {
		unit: formUnit,
		quantity: formQuantity,
		unitPrice: formUnitPrice,
		account: formAccount,
	} = form.getFieldsValue(true);

	const totalAmount = useMemo(() => {
		if (formQuantity && formUnitPrice?.value && formUnitPrice?.exchangeRate) {
			const formAmount = formQuantity * formUnitPrice.value;
			const formLocalAmount = formAmount / formUnitPrice.exchangeRate;

			return formLocalAmount;
		}

		if (exchangeRate && quantity && unitPrice) {
			const calculatedAmount = quantity * unitPrice;
			const calculatedLocalAmount = calculatedAmount / exchangeRate;

			return calculatedLocalAmount;
		}

		return 0;
	}, [exchangeRate, formQuantity, formUnitPrice?.exchangeRate,
		formUnitPrice?.value, quantity, unitPrice]);

	const handleValuesChange = useCallback((value: any, val: any) => {
		setCustomFieldState((prevState) => ({
			...prevState,
			quantity: val.quantity ?? prevState.quantity,
			unitPrice: val.unitPrice?.value ?? prevState.unitPrice,
			exchangeRate: val.unitPrice?.exchangeRate ?? prevState.exchangeRate,
		}));
		valuesChange(value);
	}, [valuesChange]);

	return (
		<>
			<Modal
				open={isOpen}
				wrapClassName={styles.rightSideModalWrapper}
				footer={null}
				width="100%"
				mask={false}
				forceRender
			>
				<div className={styles.drawerSpacerContainer}>
					<div
						className={styles.attachmentsDraggerContainer}
						onClick={handleModalBackgroundClick}
						role="presentation"
						onKeyDown={handleModalBackgroundClick}
						data-cy="expense-attachments"
					>
						<div
							className={styles.attachmentsInner}
							data-tour="createReceivableAttachments"
						>
							{loadingAttachments ? (
								<LoadingIndicator />
							) : (
								<InvoiceAttachment
									files={uploaded}
									uploadProps={uploadProps}
									onRemoveFile={onRemoveUpload}
								/>
							)}
						</div>
					</div>
				</div>
			</Modal>
			<Drawer
				width={310}
				title={isEditingExpense ? 'Edit expense' : 'Create new expense'}
				placement="left"
				onClose={onCloseForm}
				open={isOpen}
				mask={false}
				forceRender
			>
				<Form
					layout="vertical"
					form={form}
					onFinish={submit}
					onValuesChange={handleValuesChange}
					preserve
					data-tour="createReceivableForm"
				>
					<div>
						<Form.Item
							name="account"
							label="Expense Type"
							rules={required}
						>
							<Select
								placeholder="Expense Type"
								disabled={selectedExpenseId != null}
								options={
									Object.values(expenseReceivableAccountTypes).map((account) => ({
										label: getPayerString(account, fixtureType),
										value: account,
									}))
								}
							/>
						</Form.Item>
						{selectedAccount === expenseReceivableAccountTypes.BOTH && (
							<>
								<Form.Item
									name="customInvoiceId"
									label="Invoice ID"
								>
									<Input />
								</Form.Item>
								<Form.Item
									name="supplier"
									label="Supplier"
									rules={required}
								>
									<Select
										placeholder="Supplier"
										showSearch
										disabled={selectedExpenseId != null}
										options={
											Object.values(suppliers ?? {}).map((supplier) => ({
												label: supplier.name,
												value: supplier.id,
											}))
										}
									/>
								</Form.Item>
								<Form.Item
									name="invoiceDate"
									label="Invoice Date"
								>
									<DatePicker
										className={styles.datepicker}
										range={false}
									/>
								</Form.Item>
							</>
						)}
						<>
							<Form.Item
								name="itemDescription"
								label="Item description"
								rules={required}
							>
								<Input />
							</Form.Item>
							{(formAccount === AccountTypes.OWNER || formAccount === AccountTypes.CHARTERER) && (
								<Form.Item
									name="addInput"
									label="Include units and price per unit"
								>
									<Checkbox
										checked={addInput}
										onClick={() => {
											setAddInput(!addInput);
										}}
									/>
								</Form.Item>
							)}
							<Form.Item
								name="unit"
								label="Unit Name"
								hidden={!addInput}
								required={addInput}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="quantity"
								label="Quantity"
								hidden={!addInput}
								required={addInput}
							>
								<InputNumber addonAfter={formUnit || 'unit'} />
							</Form.Item>
							<Form.Item
								name="unitPrice"
								label={`Price per ${formUnit || 'unit'} `}
								hidden={!addInput}
								required={addInput}
								rules={required}
								initialValue={{ currency: fixtureCurrency }}
							>
								<MultiCurrencyInput
									baseCurrency={fixtureCurrency}
									placeholder="Unit Price"
								/>
							</Form.Item>
							<Form.Item
								name="amount"
								label="Invoice Amount"
								rules={required}
								initialValue={{ currency: fixtureCurrency }}
								hidden={addInput}
							>
								<MultiCurrencyInput
									baseCurrency={fixtureCurrency}
									placeholder="Amount"
								/>
							</Form.Item>
							<Form.Item
								name="total"
								label="Total"
								hidden={!addInput}
							>
								{
									formatCurrency(totalAmount, fixtureCurrency)
								}
							</Form.Item>
							<Form.Item
								name="note"
								label="Internal note"
							>
								<Input.TextArea />
							</Form.Item>
						</>

					</div>
					<div className={styles.actions}>
						<Space align="end">
							<>
								<Button
									htmlType="submit"
									type="primary"
									loading={saveLoading}
								>
									Save Voyage Expense
								</Button>
							</>
						</Space>
					</div>
				</Form>
			</Drawer>
		</>
	);
};

export default CreateVoyageExpenseReceivableForm;
