import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { TooltipProps } from 'antd/lib/tooltip';
import Button from '@client/components/Button';
import { ExtendUnique } from '@client/utils/ExtendUnique';
import styles from './styles/TooltipIcon.module.css';

type TooltipIconProps = ExtendUnique<{
	Icon?: React.ComponentType;
	label?: React.ReactNode;
	iconProps?: Record<any, any>;
	height?: React.CSSProperties['height'];
	width?: React.CSSProperties['width'];
	marginLeft?: React.CSSProperties['marginLeft'];
	danger?: boolean;
	overlay?: TooltipProps['overlay'];
}, TooltipProps>;

const TooltipIcon: React.FC<TooltipIconProps> = ({
	children,
	Icon = undefined,
	label = undefined,
	iconProps = {},
	height = 'auto',
	marginLeft = 4,
	danger = false,
	...props
}) => {
	const IconComponent = Icon || QuestionCircleOutlined;

	return (
		<Tooltip
			{...props}
			title={children}
		>
			<Button
				danger={danger}
				type="link"
				className={styles.helpButton}
				// eslint-disable-next-line react/forbid-component-props
				style={{
					height,
					marginLeft,
				}}
				// @ts-ignore
				icon={(<IconComponent {...iconProps} />)}
			>
				{label}
			</Button>
		</Tooltip>
	);
};

export default TooltipIcon;
