import React, { useState } from 'react';
import {
	Form,
	Input,
	Popover,
	Switch,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons/faRoute';
import { Icon } from '@fortawesome/fontawesome-svg-core';
import type { SeaPassageSettings } from '@api/utils/sequelize/calculateVoyageItineraryDetails/helpers/itineraryConstants';
import Button from '@client/components/Button';
import styles from '@client/screens/estimates/details/styles/shared.module.css';

type Props = {
	portRotationId: number | undefined;
	routeOptions: SeaPassageSettings;
	handleConfigUpdate: (
		id: number | undefined,
		updatedRouteSettings: SeaPassageSettings
	) => void;
	disabled?: boolean;
}

const ConfigContent = ({
	portRotationId,
	routeOptions,
	handleConfigUpdate,
	disabled,
}: Props) => {
	const handleSubmit = (updatedRouteSettings: SeaPassageSettings) => {
		setShowPopover(false);
		handleConfigUpdate(portRotationId, updatedRouteSettings);
	};

	const [showPopover, setShowPopover] = useState<boolean>(false);

	const content = (
		<div className={styles.configContent}>
			<Form
				onFinish={handleSubmit}
				initialValues={routeOptions}
			>
				<Form.Item
					className={styles.configSwitch}
					label="Allow Kiel Canal"
					name="allowKielTransit"
					valuePropName="checked"
					colon={false}
				>
					<Switch />
				</Form.Item>
				<Form.Item
					className={styles.configSwitch}
					label="Allow Suez Canal"
					name="allowSuezTransit"
					valuePropName="checked"
					colon={false}
				>
					<Switch />
				</Form.Item>
				<Form.Item
					className={styles.configSwitch}
					label="Allow Panama Canal"
					name="allowPanamaTransit"
					valuePropName="checked"
					colon={false}
				>
					<Switch />
				</Form.Item>
				<Form.Item
					className={styles.configSwitch}
					label="Avoid High Risk Areas (HRA)"
					name="avoidHRA"
					valuePropName="checked"
					colon={false}
				>
					<Switch />
				</Form.Item>
				<Form.Item
					className={styles.configSwitch}
					label="Avoid ECA"
					name="avoidSeca"
					valuePropName="checked"
					colon={false}
				>
					<Switch />
				</Form.Item>
				<Form.Item
					className={styles.configSwitch}
					label="Allow Ice Areas"
					name="allowIceAreas"
					valuePropName="checked"
					colon={false}
				>
					<Switch />
				</Form.Item>
				<Form.Item
					className={styles.configSwitch}
					label="Override Draft"
					name="overrideDraft"
					valuePropName="value"
					colon={false}
				>
					<Input
						className={styles.configNumberField}
					/>
				</Form.Item>
				<Form.Item>
					<div className={styles.configSubmitContainer}>
						<Button
							type="primary"
							htmlType="submit"
						>
							Save
						</Button>
					</div>
				</Form.Item>
			</Form>
		</div>
	);

	return (
		<Popover
			title="Sea passage settings"
			trigger="click"
			open={showPopover}
			onOpenChange={(visible) => {
				setShowPopover(visible);
			}}
			content={content}
			destroyTooltipOnHide
		>
			<Button
				type="link"
				disabled={disabled}
				icon={(
					<FontAwesomeIcon icon={faRoute as Icon} />
				)}
			/>
		</Popover>
	);
};

export default ConfigContent;
