import React, { useState } from 'react';
import classNames from 'classnames';
import {
	ArrowUpOutlined,
	ArrowRightOutlined,
	ArrowDownOutlined,
	ArrowLeftOutlined,
	StopOutlined,
} from '@ant-design/icons';
import Button from '@client/components/Button';
import { useImpersonation } from '@client/lib/impersonation';
import styles from './styles/ImpersonationBar.module.css';

const cornerOptions = [
	styles.topRight,
	styles.bottomRight,
	styles.bottomLeft,
	styles.topLeft,
];

const iconOptions = [
	ArrowDownOutlined,
	ArrowLeftOutlined,
	ArrowUpOutlined,
	ArrowRightOutlined,
];

const ImpersonationBar = () => {
	const {
		isImpersonating,
		orgId,
		stopImpersonating,
	} = useImpersonation();

	const [corner, setCorner] = useState(0);

	if (!isImpersonating) {
		return null;
	}

	const nextCorner = () => {
		setCorner((corner + 1) % 4);
	};

	const Icon = iconOptions[corner];

	return (
		<div className={classNames(styles.container, cornerOptions[corner])}>
			{`Viewing as organization #${orgId}`}
			<Button
				icon={(<StopOutlined />)}
				className={classNames(styles.button, styles.stopButton)}
				type="link"
				onClick={stopImpersonating}
			>
				Stop
			</Button>
			<Button
				type="link"
				className={styles.button}
				icon={(<Icon />)}
				onClick={nextCorner}
			>
				Move
			</Button>
		</div>
	);
};

export default ImpersonationBar;
