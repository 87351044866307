import React, {
	useCallback,
	useMemo,
	useState,
} from 'react';
import { Tabs } from 'antd';
import type { UpdateVideoGuideRequest } from '@api/features/admin/updateVideoGuide';
import type { CreateVideoGuideRequest } from '@api/features/admin/createVideoGuide';
import SimpleScreen from '@client/components/screens/SimpleScreen';
import Card from '@client/components/Card/Card';
import EditableTable from '@client/components/EditableTable';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import {
	createVideoGuide,
	deleteVideoGuide,
	getVideoGuides,
	updateVideoGuide,
} from '@client/lib/api';
import { RootPages } from '@client/utils/links';
import PagesWithVideosTab from '@client/screens/admin/VideoGuideScreen/PagesWithVideosTab';
import showErrorNotification from '@client/utils/showErrorNotification';

const VideoGuideScreen = () => {
	const [activeTab, setActiveTab] = useState('videos');
	const [videos, refresh, _error, loading] = useFetchedState(getVideoGuides);

	const videoColumns = useMemo(() => {
		const options = RootPages.map((page, i) => ({
			key: `${page}-${i}`,
			value: page,
			label: page,
		}));

		return [
			{
				title: 'Title',
				dataIndex: 'title',
				editable: true,
			},
			{
				title: 'Description',
				dataIndex: 'description',
				editable: true,
			},
			{
				title: 'URL',
				dataIndex: 'url',
				editable: true,
			},
			{
				title: 'Page',
				dataIndex: 'page',
				editable: true,
				width: 200,
				editingProps: {
					type: 'select',
					inputProps: {
						options,
						showSearch: true,
					},
				},
			},
		];
	}, []);

	const handleAddNewVideo = useCallback(async (values: Omit<CreateVideoGuideRequest, 'order'>) => {
		const regex = /^https?:\/\/(?:www\.)?loom\.com\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=.]+$/;
		const valid = regex.test(values.url.trim());

		if (!valid) {
			showErrorNotification('Video URL does not look like a valid Loom address');

			return;
		}

		await createVideoGuide(values);
		refresh();
	}, [refresh]);

	const handleEditVideo = useCallback(async (id: number, attributes: UpdateVideoGuideRequest['attributes']) => {
		const regex = /^https?:\/\/(?:www\.)?loom\.com\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=.]+$/;
		const valid = regex.test(attributes?.url ?? '');

		if (!valid) {
			showErrorNotification('Video URL does not look like a valid Loom address');

			return;
		}

		await updateVideoGuide({ id, attributes });
		refresh();
	}, [refresh]);

	const handleDeleteVideo = useCallback(async (id: number) => {
		await deleteVideoGuide(id);
		refresh();
	}, [refresh]);

	return (
		<SimpleScreen
			title="Video Guides"
			rootPageTitle="Video Guides"
		>
			<Card>
				<Tabs
					onChange={setActiveTab}
					activeKey={activeTab?.toString()}
					items={[
						{
							key: 'videos',
							label: 'Videos',
							children: (
								<EditableTable
									// @ts-ignore type in component broken cba to fix now
									columns={videoColumns}
									dataSource={videos ?? []}
									keyDataIndex="id"
									allowAddNew
									enableDelete
									addNewText="Add new video"
									onSave={handleEditVideo}
									onAddNew={handleAddNewVideo}
									onDelete={handleDeleteVideo}
									iconButtons
									pagination={false}
								/>
							),
						},
						{
							key: 'pages',
							label: 'Pages',
							children: (
								<PagesWithVideosTab
									videos={videos ?? []}
									refresh={refresh}
									loading={loading}
								/>
							),
						},
					]}
				/>
			</Card>
		</SimpleScreen>
	);
};

export default VideoGuideScreen;
