const fetchPdf = async ({
	fileName,
	fetchCallback,
}: {
	fileName: string;
	fetchCallback: () => Promise<{
		blob: string;
	}>;
}) => {
	const { blob } = await fetchCallback();

	const pdfUrl = `data:application/pdf;base64,${blob}`;

	const link = document.createElement('a');
	link.href = pdfUrl;
	link.download = fileName;
	link.click();
};

export default fetchPdf;
