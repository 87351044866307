import React from 'react';
import {
	Card,
	Col,
	Divider,
	Grid,
	Row,
	Space,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { DeleteOutlined } from '@ant-design/icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	CargoUnitLabels,
	EstimateStatus,
	FreightRateType,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { formatNumber } from '@shared/utils/formatNumber';
import type { CargoProps } from '@api/models/cargo';
import CountryFlag from '@client/components/CountryFlag';
import Button from '@client/components/Button';
import styles from './styles/CargoCard.module.css';
import TooltipIcon from './TooltipIcon';

type CargoCardProps = {
	cargo: Cargo;
	canDeleteCargo?: boolean;
	onEditCargo?: (id: number) => void;
	onDeleteCargo?: (id: number) => void;
	fixtureId?: number | null;
	size?: 'small' | 'normal';
}

export type Cargo = Omit<CargoProps, (
	| 'fixtureId'
	| 'createdAt'
	| 'updatedAt'
	| 'deletedAt'
	| 'laycanFrom'
	| 'laycanTo'
	| 'quantityTolerance'
	| 'quantityOption'
	| 'overageAmount'
	| 'overageType'
	| 'deadfreightAmount'
	| 'deadfreightType'
	| 'laytimeTerms'
)> & { chartererName?: string | null; fixtureId?: number | null; status?: EstimateStatus | null };

const CargoCard = ({
	cargo,
	canDeleteCargo = false,
	onEditCargo,
	onDeleteCargo,
	size = 'normal',
	fixtureId,
}: CargoCardProps) => {
	const { loadingPorts, dischargePorts } = cargo;
	const screens = Grid.useBreakpoint();

	const fixedElsewhere = cargo.status === EstimateStatus.Fixed && cargo.fixtureId !== fixtureId;

	return (
		<Card
			className={styles.cargoCard}
			title={(
				<>
					{`${cargo.type}  ${cargo?.chartererName != null ? `for ${cargo.chartererName}` : ''}`}
					{fixedElsewhere && (
						<TooltipIcon
							danger
						>
							This cargo is attached to another fixed estimate.
							While this is the case, you cannot fix this estimate
						</TooltipIcon>
					)}
				</>
			)}
			size="small"
			extra={(
				<>
					<Space>
						{onEditCargo != null ? (
							<Button
								type="link"
								onClick={() => onEditCargo(cargo.id)}
								icon={(
									<FontAwesomeIcon
										className={styles.iconMargin}
										icon={faPencil as IconProp}
									/>
								)}
							>
								<b>Edit</b>
							</Button>
						) : ''}
						{onDeleteCargo != null ? (
							<Button
								onClick={() => onDeleteCargo(cargo.id)}
								type="link"
								confirmTitle="Are you sure you want to remove this cargo from the estimate?"
								danger
								icon={(<DeleteOutlined />)}
								disabled={!canDeleteCargo}
								disabledTooltip="You cannot remove cargos from a fixed estimate"
							/>
						) : ''}
					</Space>
				</>
			)}
		>
			<Row className={styles.cargoCardContent} gutter={4}>
				<Col xs={24} md={size === 'small' ? 24 : 8}>
					<b>Loading</b>
					<br />
					{loadingPorts.length > 0 ? loadingPorts.map((port) => (
						<div className={styles.portOrRange}>
							{port.countryCode !== '' && (
								<CountryFlag countryCode={port.countryCode} />
							)}
							<p className={styles.portName}>{port.name}</p>
						</div>
					)) : 'N/A'}
				</Col>
				<Col xs={24} md={size === 'small' ? 24 : 8}>
					<b>Discharge</b>
					<br />
					{dischargePorts.length > 0 ? dischargePorts.map((port) => (
						<div className={styles.portOrRange}>
							{port.countryCode !== '' && (
								<CountryFlag countryCode={port.countryCode} />
							)}
							<p className={styles.portName}>{port.name}</p>
						</div>
					)) : 'N/A'}
				</Col>
				<Col xs={24} md={size === 'small' ? 24 : 8}>
					<b>{cargo.freightType === FreightRateType.LUMPSUM ? 'Lumpsum' : 'Per Ton'}</b>
					<br />
					{formatCurrency(cargo?.freightRate ?? 0, cargo.currency)}
				</Col>
			</Row>
			{screens.xs || size === 'small' ? (
				<div style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 8 }}>
					<Divider className={styles.slimDivider} />
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div>
							<b>Quantity</b>
							<br />
							{cargo.quantity != null ? ` ${formatNumber(
								cargo.quantity,
								{ separateThousands: true },
							)} ${CargoUnitLabels[cargo.unit]}` : 'N/A'}
						</div>
						<div>
							<b>Gross freight</b>
							<br />
							{formatCurrency(
								cargo.freightType === FreightRateType.LUMPSUM ?
									cargo.freightRate :
									cargo.freightRate * cargo.quantity,
								cargo.currency,
							)}
						</div>
					</div>
				</div>
			) : (
				<div className={styles.cardFooter}>
					<div>
						<b>Quantity: </b>
						{cargo.quantity != null ? ` ${formatNumber(
							cargo.quantity,
							{ separateThousands: true },
						)} ${CargoUnitLabels[cargo.unit]}` : 'N/A'}
					</div>
					<div>
						<b>Gross freight: </b>
						{formatCurrency(
							cargo.freightType === FreightRateType.LUMPSUM ?
								cargo.freightRate :
								cargo.freightRate * cargo.quantity,
							cargo.currency,
						)}
					</div>
				</div>
			)}
		</Card>
	);
};

export default CargoCard;
