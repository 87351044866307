import React from 'react';
import {
	Badge,
	Tooltip,
} from 'antd';
import type { VesselProps } from '@api/models/vessel';

export const getCrewAppStatusBadge = (
	vessel: VesselProps,
	withText: boolean,
) => {
	let tooltip;
	let icon;
	let status: 'initial' | 'invitationSent' | 'activated' = 'initial';

	if (vessel.crewAppEmail != null && vessel.crewAppInvitationSent && !vessel.crewAppActivated) {
		status = 'invitationSent';
	}

	if (vessel.crewAppActivated) {
		status = 'activated';
	}

	switch (status) {
		case 'initial':
			tooltip = 'Reporting app not activated for this vessel';
			icon = (
				<Badge status="error" text={withText ? 'Not activated' : null} />
			);

			break;
		case 'invitationSent':
			tooltip = `Invitation sent to ${vessel.crewAppEmail}`;
			icon = (
				<Badge status="warning" text={withText ? 'Invitation sent' : null} />
			);
			break;
		case 'activated':
			tooltip = `Reporting app activated with ${vessel.crewAppEmail}`;
			icon = (
				<Badge status="success" text={withText ? 'Activated' : null} />
			);
			break;
		default:
			tooltip = '';
			icon = null;
	}

	return (
		<Tooltip title={tooltip}>
			{icon}
		</Tooltip>
	);
};

export const getCrewAppStatus = (vessel: VesselProps) => {
	if (vessel?.crewAppActivated) {
		return 'success';
	}

	if (vessel?.crewAppEmail != null && vessel.crewAppInvitationSent) {
		return 'warning';
	}

	return 'error';
};
