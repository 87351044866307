import React from 'react';
import { Form } from 'antd';
import Select from '@client/components/Select';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getNavisionCompanies } from '@client/lib/api';

const NavisionCompanySelector = () => {
	const [companies] = useFetchedState(getNavisionCompanies);

	return (
		<Form.Item name="companyId" label="Company Code / ID">
			<Select
				virtual
				placeholder="Select Company"
				showSearch
				options={(companies ?? []).map((company) => ({
					label: company.name,
					value: company.id,
				}))}
			/>
		</Form.Item>
	);
};

export default NavisionCompanySelector;
