import type { GetVcPnlResponse } from '@api/features/voyages/getVcPnl';

const getVcPnlItems = (pnl: GetVcPnlResponse) => {
	const revenueItems = [
		{
			item: 'Freight',
			...pnl.freight,
		},
		{
			item: 'Other Revenue',
			...pnl.otherRevenue,
		},
		{
			item: 'Demurrage',
			...pnl.demurrage,
		},
		{
			item: 'Despatch',
			...pnl.despatch,
		},
		{
			item: 'Address Commission',
			...pnl.addressCommission,
		},
		{
			item: 'Broker Commission',
			...pnl.brokerCommission,
		},
		{
			item: 'Total Revenue',
			bold: true,
			...pnl.totalRevenue,
		},
	];

	const expenseItems = [
		{
			item: 'Port Expenses',
			...pnl.expensesInPort,
		},
		{
			item: 'Other Expenses',
			...pnl.otherExpenses,
		},
		{
			item: 'Bunker expenditure',
			...pnl.bunkerExpenditure,
		},
		{
			item: 'Total Expenses',
			bold: true,
			...pnl.totalExpenses,
		},
	];

	const resultsItems = [
		{
			item: 'TCE earnings',
			bold: true,
			...pnl.voyageProfit,
		},
		{
			item: 'Voyage Days',
			number: true,
			bold: true,
			...pnl.voyageDays,
		},
		{
			item: 'TCE per day',
			bold: true,
			...pnl.tce,
		},
		{
			item: 'CAPEX',
			...pnl.capex,
		},
		{
			item: 'OPEX',
			...pnl.opex,
		},
		{
			item: 'Net hire cost',
			...pnl.tcInCosts,
		},
		{
			item: 'Net Result',
			...pnl.netResult,
		},
		{
			item: 'Net Result / day',
			...pnl.netResultPerDay,
		},
	];

	return {
		revenueItems,
		expenseItems,
		resultsItems,
	};
};

export default getVcPnlItems;
