import { round } from '@shared/utils/math';

export const shortenNumber = (number: number) => {
	if (number >= 1_000_000) {
		return `${round(number / 1_000_000, 2)}M`;
	}

	return `${round(number / 1_000, 2)}K`;
};

// @ts-ignore
// eslint-disable-next-line no-restricted-globals
export const isValidNumber = (string: string) => !isNaN(parseFloat(string)) && isFinite(string);
