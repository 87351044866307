import moment from 'moment';
import type { HireInvoiceItemType } from '@shared/hireInvoice/HireInvoiceItem';
import type { HIIHireAddressCommissionType } from '@shared/hireInvoice/HIIHireAddressCommission';
import type { HIIHireBrokerCommissionType } from '@shared/hireInvoice/HIIHireBrokerCommission';
import type { HIIExpenseSubjectToHireDaysType } from '@shared/hireInvoice/HIIExpenseSubjectToHireDays';
import type { HIIHirePeriodType } from '@shared/hireInvoice/HIIHirePeriod';
import type { HIIRevenueSubjectToDaysType } from '@shared/hireInvoice/HIIRevenueSubjectToDays';
import type { PnlAmountCalculatorParams } from './index';

type GetHireChildPnlAmountCalculator = (
	item:
	HIIHireAddressCommissionType |
	HIIHireBrokerCommissionType |
	HIIExpenseSubjectToHireDaysType |
	HIIRevenueSubjectToDaysType
) => (
	allItems: HireInvoiceItemType[],
	parameters: PnlAmountCalculatorParams,
) => number;

const getHireChildPnlAmountCalculator: GetHireChildPnlAmountCalculator = (item) => (
	allItems,
	{ pnlPeriodStart, pnlPeriodEnd },
) => {
	const total = item.getTotal(allItems, false);
	const parent = item.getParent(allItems) as HIIHirePeriodType;

	const periodDays = parent.to.diff(parent.from, 'days', true);

	// Return an amount proportional to the overlap of the hire period
	const overlapRange = moment.range(parent.from, parent.to).intersect(
		moment.range(pnlPeriodStart, pnlPeriodEnd),
	);

	const overlapDays = overlapRange == null ? 0 : overlapRange.diff('days', true);

	if (periodDays === 0) {
		return 0;
	}

	return total * (overlapDays / periodDays);
};

export default getHireChildPnlAmountCalculator;

