import React from 'react';
import {
	Col,
	Row,
	Typography,
} from 'antd';
import classNames from 'classnames';
import {
	Currencies,
	LaytimeIntervals,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { formatCurrency } from '@shared/utils/currency';
import { formatLaytimeDuration } from '@shared/utils/date';
import type { GetCargosAndLaytimesResponse } from '@api/features/laytime/getCargosAndLaytimes';
import type { GetEstimatedDespatchAndDemurrageResponse } from '@api/features/estimates/getEstimatedDespatchAndDemurrage';
import StatisticCard from '@client/components/StatisticCard';
import styleColorPicker
	from '@client/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/utils/styleColorPicker';
import styles from './Stats.module.css';

type Props = {
	stats: GetCargosAndLaytimesResponse[number]['stats'];
	interval: LaytimeIntervals;
	currency: Values<typeof Currencies>;
	demurrageRateLoading: number | null | undefined;
	demurrageRateDischarging: number | null | undefined;
	despatchRateLoading: number | null | undefined;
	despatchRateDischarging: number | null | undefined;
	estimatedDemDes?: GetEstimatedDespatchAndDemurrageResponse[number] | null;
}

const AveragedStats = ({
	stats,
	interval,
	currency,
	despatchRateLoading,
	despatchRateDischarging,
	demurrageRateLoading,
	demurrageRateDischarging,
	estimatedDemDes,
}: Props) => {
	const {
		totalReceivable,
		totalDemurrageLoading,
		totalDemurrageDischarging,
		totalDespatchLoading,
		totalDespatchDischarging,
		totalTimeLoading,
		totalTimeDischarging,
	} = stats;

	const isZeroLoading =
		(totalDemurrageLoading == null || totalDemurrageLoading === 0) &&
		(totalDespatchLoading == null || totalDespatchLoading === 0);
	const isZeroDischarging =
		(totalDemurrageDischarging == null || totalDemurrageDischarging === 0) &&
		(totalDespatchDischarging == null || totalDespatchDischarging === 0);
	const isDemLoading = (totalDemurrageLoading ?? 0) > (totalDespatchLoading ?? 0);
	const isDemDischarging = (totalDemurrageDischarging ?? 0) > (totalDespatchDischarging ?? 0);
	const timeGainedLoading = (totalTimeLoading ?? 0) > 0;
	const timeGainedDischarging = (totalTimeDischarging ?? 0) > 0;

	const estimatedTimeLoading = estimatedDemDes?.loadPortsData.timeDifference ?? 0;
	const estimatedTimeDischarging = estimatedDemDes?.disPortsData.timeDifference ?? 0;

	const estimatedLoadingDemurrage = Math.abs(
		estimatedDemDes?.loadPortsData.estimatedDemurrage ?? 0,
	);
	const estimatedLoadingDespatch = Math.abs(estimatedDemDes?.loadPortsData.estimatedDespatch ?? 0);

	const estimatedDischargeDemurrage = Math.abs(
		estimatedDemDes?.disPortsData.estimatedDemurrage ?? 0,
	);
	const estimatedDischargeDespatch = Math.abs(estimatedDemDes?.disPortsData.estimatedDespatch ?? 0);

	const estimatedIsZeroLoading =
		(estimatedLoadingDemurrage == null || estimatedLoadingDemurrage === 0) &&
		(estimatedLoadingDespatch == null || estimatedLoadingDespatch === 0);

	const estimatedIsZeroDischarging =
		(estimatedDischargeDemurrage == null || estimatedDischargeDemurrage === 0) &&
		(estimatedDischargeDespatch == null || estimatedDischargeDespatch === 0);

	const estimatedIsDemLoading = (estimatedLoadingDemurrage ?? 0) > (estimatedLoadingDespatch ?? 0);
	const estimatedIsDemDischarging = (estimatedDischargeDemurrage ?? 0) >
		(estimatedDischargeDespatch ?? 0);

	const estimatedTimeGainedLoading = (estimatedTimeLoading ?? 0) > 0;
	const estimatedTimeGainedDischarging = (estimatedTimeDischarging ?? 0) > 0;

	return (
		<>
			<Row gutter={[16, 16]}>
				<Col>
					<StatisticCard
						items={[
							{
								label: `Time ${timeGainedLoading ? 'gained' : 'lost'} in load ports`,
								children: formatLaytimeDuration(Math.abs(totalTimeLoading ?? 0), interval, 3),
								childColor: styleColorPicker(totalTimeLoading, true).color,
								level: 5,
							}, {
								label: `${isDemLoading ? 'Demurrage' : 'Despatch'} Loading`,
								children: (
									<>
										<Typography.Text
											className={classNames({
												[styles.neutralTotalLabel]: isZeroLoading,
												[styles.positiveTotalLabel]: isDemLoading,
												[styles.negativeTotalLabel]: !isDemLoading,

											})}
										>
											{formatCurrency(
												isDemLoading ?
													(totalDemurrageLoading ?? 0) :
													(totalDespatchLoading ?? 0),
												currency,
											)}
										</Typography.Text>
										<Typography.Text
											className={styles.rateLabel}
										>
											(
											{formatCurrency(isDemLoading ?
												demurrageRateLoading ?? 0 :
												despatchRateLoading ?? 0,
											currency)}
											{' '}
											/ day)
										</Typography.Text>
									</>),
							},
						]}
						customStyles={{
							maxWidth: '300px',
						}}
					/>
				</Col>
				<Col>
					<StatisticCard
						items={[
							{
								label: `Time ${timeGainedDischarging ? 'gained' : 'lost'} in discharge ports`,
								children: formatLaytimeDuration(Math.abs(totalTimeDischarging ?? 0), interval, 3),
								childColor: styleColorPicker(totalTimeDischarging, true).color,
								level: 5,
							}, {
								label: `${isDemDischarging ? 'Demurrage' : 'Despatch'} Discharging`,
								children: (
									<>
										<Typography.Text
											className={classNames({
												[styles.neutralTotalLabel]: isZeroDischarging,
												[styles.positiveTotalLabel]: isDemDischarging,
												[styles.negativeTotalLabel]: !isDemDischarging,

											})}
										>
											{formatCurrency(
												isDemDischarging ?
													(totalDemurrageDischarging ?? 0) :
													(totalDespatchDischarging ?? 0),
												currency,
											)}
										</Typography.Text>
										<Typography.Text
											className={styles.rateLabel}
										>
											(
											{formatCurrency(isDemDischarging ?
												demurrageRateDischarging ?? 0 :
												despatchRateDischarging ?? 0,
											currency)}
											{' '}
											/ day)
										</Typography.Text>
									</>),
							},
						]}
						customStyles={{
							maxWidth: '300px',
						}}
					/>
				</Col>
				<Col>
					<StatisticCard
						items={[{
							label: `Total ${totalReceivable != null && totalReceivable >= 0 ? 'Receivable' : 'Payable'}`,
							children: formatCurrency(Math.abs(totalReceivable ?? 0), currency),
							childColor: styleColorPicker(totalReceivable).color,
						}]}
						customStyles={{
							maxWidth: '300px',
						}}
					/>
				</Col>
				{estimatedDemDes != null && (
					<>
						<Col>
							<StatisticCard
								items={[
									{
										label: `Estimated Time ${estimatedTimeGainedLoading ? 'gained' : 'lost'} in load ports`,
										children: formatLaytimeDuration(
											Math.abs(estimatedTimeLoading ?? 0) * 1440,
											interval,
											3,
										),
										childColor: styleColorPicker(estimatedTimeLoading, true).color,
										level: 5,
									}, {
										label: `Estimated ${estimatedIsDemLoading ? 'Demurrage' : 'Despatch'} Loading`,
										children: (
											<>
												<Typography.Text
													className={classNames({
														[styles.neutralTotalLabel]: estimatedIsZeroLoading,
														[styles.positiveTotalLabel]: estimatedIsDemLoading,
														[styles.negativeTotalLabel]: !estimatedIsDemLoading,
													})}
												>
													{formatCurrency(
														estimatedIsDemLoading ?
															(estimatedLoadingDemurrage ?? 0) :
															(estimatedLoadingDespatch ?? 0),
														currency,
													)}
												</Typography.Text>
												<Typography.Text
													className={styles.rateLabel}
												>
													(
													{formatCurrency(estimatedIsDemLoading ?
														demurrageRateLoading ?? 0 :
														despatchRateLoading ?? 0,
													currency)}
													{' '}
													/ day)
												</Typography.Text>
											</>),
									},
								]}
								customStyles={{
									maxWidth: '300px',
								}}
							/>
						</Col>
						<Col>
							<StatisticCard
								items={[
									{
										label: `Estimated Time ${estimatedTimeGainedDischarging ? 'gained' : 'lost'} in discharge ports`,
										children: formatLaytimeDuration(
											Math.abs(estimatedTimeDischarging ?? 0) * 1440,
											interval,
											3,
										),
										childColor: styleColorPicker(estimatedTimeDischarging, true).color,
										level: 5,
									}, {
										label: `Estimated ${estimatedIsDemDischarging ? 'Demurrage' : 'Despatch'} Discharging`,
										children: (
											<>
												<Typography.Text
													className={classNames({
														[styles.neutralTotalLabel]: estimatedIsZeroDischarging,
														[styles.positiveTotalLabel]: estimatedIsDemDischarging,
														[styles.negativeTotalLabel]: !estimatedIsDemDischarging,
													})}
												>
													{formatCurrency(
														estimatedIsDemDischarging ?
															(estimatedDischargeDemurrage ?? 0) :
															(estimatedDischargeDespatch ?? 0),
														currency,
													)}
												</Typography.Text>
												<Typography.Text
													className={styles.rateLabel}
												>
													(
													{formatCurrency(estimatedIsDemDischarging ?
														demurrageRateDischarging ?? 0 :
														despatchRateDischarging ?? 0,
													currency)}
													{' '}
													/ day)
												</Typography.Text>
											</>),
									},
								]}
								customStyles={{
									maxWidth: '300px',
								}}
							/>
						</Col>
					</>
				)}
			</Row>
		</>
	);
};

export default AveragedStats;
