import React from 'react';
import { Typography } from 'antd';
import Card from './Card/Card';
import styles from './styles/StatisticCard.module.css';

const StatisticCard = ({
	items,
	customStyles,
}: {
	items: Array<{
		label: string;
		children: React.ReactNode;
		childColor?: string;
		level?: 5 | 1 | 2 | 3 | 4 | undefined;
	}>;
	customStyles?: React.CSSProperties;
}) => {
	return (
		// eslint-disable-next-line react/forbid-component-props
		<Card className={styles.card} style={customStyles}>
			{items.map(({ label, children, childColor, level }) => (
				<div className={styles.statEntry}>
					<Typography.Text type="secondary">{label}</Typography.Text>
					<Typography.Title
						className={styles.noMargin}
						level={level ?? 3}
					>
						<div style={{ color: childColor }}>{children}</div>
					</Typography.Title>
				</div>
			))}
		</Card>
	);
};

export default StatisticCard;
