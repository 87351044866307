// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BHMkkzrweW6KsGN64BMq .ant-card-body {\n\tpadding-top: 5px;\n\tpadding-bottom: 5px;\n}\n\n._6Xp7skEteuB4cmrMMLZg .rct-items > .rct-item {\n\theight: 25px !important;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/VesselTimeline.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".timelineCard :global(.ant-card-body) {\n\tpadding-top: 5px;\n\tpadding-bottom: 5px;\n}\n\n.vesselTimeline :global(.rct-items > .rct-item) {\n\theight: 25px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timelineCard": "BHMkkzrweW6KsGN64BMq",
	"vesselTimeline": "_6Xp7skEteuB4cmrMMLZg"
};
export default ___CSS_LOADER_EXPORT___;
