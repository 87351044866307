import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { ColumnsType } from 'antd/lib/table/interface';
import { Typography } from 'antd';
import { toMoment } from '@shared/utils/date';
import { DATE_AND_TIME } from '@shared/utils/constants';
import { capitalize } from '@shared/utils/string';
import {
	getConsumptionStoplight,
	getLatestReportDateStoplight,
	getSpeedStoplight,
	getWeatherStoplight,
} from '@shared/utils/performanceStoplights';
import { standardSort } from '@shared/utils/standardSort';
import type { GetPerformanceOverviewResponse } from '@api/features/performance/getPerformanceOverview';
import { formatDate } from '@client/utils/formatDate';
import styles from './getPerformanceStatusColumns.module.css';

export type PerformanceData = GetPerformanceOverviewResponse[number];

export const getPerformanceStatusColumns = (sorting: boolean): ColumnsType<PerformanceData> => [
	{
		title: 'Active Contract',
		dataIndex: 'activeVoyageIdentifier',
		key: 'activeVoyageIdentifier',
		render: (value) => {
			if (value == null) {
				return (<em>-</em>);
			}

			return (
				<span style={{ whiteSpace: 'nowrap' }}>
					{value}
				</span>
			);
		},
	},
	{
		title: 'Condition',
		dataIndex: 'loadCondition',
		key: 'loadCondition',
		sorter: sorting ? standardSort('loadCondition') : undefined,
		render: (_, row) => {
			if (row.isInPort) {
				return 'In port';
			}

			if (row.loadCondition == null) {
				return (<em>-</em>);
			}

			return (
				<span style={{ whiteSpace: 'nowrap' }}>
					{capitalize(row.loadCondition)}
				</span>
			);
		},
	},
	{
		title: 'Latest Report',
		dataIndex: 'latestReport',
		key: 'latestReport',
		sorter: sorting ? standardSort('latestReport') : undefined,
		render: (_, row) => {
			if (row.latestReport == null) {
				return (<em>-</em>);
			}

			const { color, description } = getLatestReportDateStoplight({
				actual: toMoment(row.latestReport),
			});

			return (
				<div className={styles.outerCell}>
					<div className={styles.tableCell}>
						<FontAwesomeIcon
							color={color}
							// @ts-ignore
							icon={faCircle}
						/>
						<p>{formatDate(row.latestReport, DATE_AND_TIME, true)}</p>
					</div>
					<Typography.Text
						type="secondary"
						className={styles.differenceText}
					>
						{description}
					</Typography.Text>
				</div>
			);
		},
	},
	{
		title: 'Speed',
		dataIndex: 'averageSpeed',
		key: 'averageSpeed',
		render: (_, row) => {
			if (row.averageSpeed == null) {
				return (<em>-</em>);
			}

			const stoplight = getSpeedStoplight({
				actual: row.averageSpeed,
				warranty: row.instructedSpeed,
				margin: row.speedMargin,
			});

			return (
				<div className={styles.outerCell}>
					<div className={styles.tableCell}>
						<FontAwesomeIcon
							color={stoplight.color}
							// @ts-ignore
							icon={faCircle}
						/>
						<p>{`${row.averageSpeed} kt`}</p>
					</div>
					<Typography.Text
						type="secondary"
						className={styles.differenceText}
					>
						{stoplight.description}
					</Typography.Text>
				</div>
			);
		},
	},
	{
		title: 'Consumption',
		dataIndex: 'totalConsumption',
		key: 'totalConsumption',
		render: (_, row) => {
			if (row.totalConsumption == null) {
				return (<em>-</em>);
			}

			const stoplight = getConsumptionStoplight({
				actual: row.totalConsumption,
				marginPercent: row.consumptionMargin,
				warranty: row.warrantedConsumption,
			});

			return (
				<div className={styles.outerCell}>
					<div className={styles.tableCell}>
						<FontAwesomeIcon
							color={stoplight.color}
							// @ts-ignore
							icon={faCircle}
						/>
						<p>{`${row.totalConsumption} MT`}</p>
					</div>
					<Typography.Text
						type="secondary"
						className={styles.differenceText}
					>
						{stoplight.description}
					</Typography.Text>
				</div>
			);
		},
	},
	{
		title: 'Weather',
		dataIndex: 'windForceBeaufort',
		key: 'windForceBeaufort',
		render: (_, row) => {
			if (row.windForceBeaufort == null) {
				return (<em>-</em>);
			}

			const stoplight = getWeatherStoplight({
				actual: row.windForceBeaufort,
				limit: row.windForceLimit,
			});

			return (
				<div className={styles.outerCell}>
					<div className={styles.tableCell}>
						<FontAwesomeIcon
							color={stoplight.color}
							// @ts-ignore
							icon={faCircle}
						/>
						{row.windForceLimit != null && (
							<p>{row.windForceBeaufort <= row.windForceLimit ? 'Good' : 'Bad'}</p>
						)}
					</div>
					<Typography.Text
						type="secondary"
						className={styles.differenceText}
					>
						{`${row.windForceBeaufort} beaufort`}
					</Typography.Text>
				</div>
			);
		},
	},
];
