import React, { ReactNode } from 'react';
import { SUPPORT_EMAIL } from '@shared/utils/constants';
import styles from './styles/ErrorMessage.module.css';

const ErrorMessage = ({
	children,
}: {
	children: ReactNode;
}) => (
	<div className={styles.container}>
		<span className={styles.title}>An error has occurred</span>
		<span className={styles.error}>{children}</span>
		<span className={styles.footer}>
			If the error persists, please do not hesitate to contact our customer service at
			{' '}
			<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
			.
		</span>
	</div>
);

export default ErrorMessage;
