// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GvvOyXJQ9OVnS1jq2Est {\n\tpadding: 0 !important;\n}\n\n.e4iKjDwFGwyv0cAUmig9 {\n\tmargin-right: 5px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/estimates/list/EstimatesListPage.module.css"],"names":[],"mappings":"AAAA;CACC,qBAAqB;AACtB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".noPaddingCell {\n\tpadding: 0 !important;\n}\n\n.icon {\n\tmargin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noPaddingCell": "GvvOyXJQ9OVnS1jq2Est",
	"icon": "e4iKjDwFGwyv0cAUmig9"
};
export default ___CSS_LOADER_EXPORT___;
