import type HireInvoiceItem from '@shared/hireInvoice/HireInvoiceItem';
import type { HireInvoiceProps } from '@api/models/hire-invoice';

// Check all hire invoices for a matching item (either as parent or child)
const getHireInvoiceFromHII = (
	item: HireInvoiceItem,
	hireInvoices: Array<HireInvoiceProps & { items: HireInvoiceItem[] }>,
) => hireInvoices.find((invoice) => (
	invoice.items.find((i) => (!i.isPrevious && (
		i.id === item.id ||
		i.id === item.parentId
	))) != null
));

export default getHireInvoiceFromHII;
