import React from 'react';
import {
	Col,
	Row,
} from 'antd';
import type { GetVoyageDetailsResponse } from '@api/features/voyages/getVoyageDetails';
import type { GetFixtureDetailsResponse } from '@api/features/fixtures/getFixtureDetails';
import type { SpotFixtureProps } from '@api/models/spot-fixture';
import type { VoyageInvoice } from '@api/utils/sequelize/getAllVoyageInvoices';
import PaymentsTable from '@client/screens/fleet/VoyageDetailsScreen/components/PaymentsTable';
import FreightInvoicesTable from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceTables/FreightInvoicesTable';

const FreightInvoices = ({
	voyageDetails,
	fixtureDetails,
	refreshDetails,
	voyageInvoices,
	voyageInvoicesLoading,
}: {
	voyageDetails: GetVoyageDetailsResponse;
	fixtureDetails: GetFixtureDetailsResponse<SpotFixtureProps>;
	refreshDetails: () => void;
	voyageInvoices: VoyageInvoice[] | [];
	voyageInvoicesLoading: boolean;
}) => {
	return (
		<>
			<Row gutter={[16, 16]}>
				<Col span={8}>
					<PaymentsTable
						voyageId={voyageDetails.id}
						voyageDetails={voyageDetails}
						payments={voyageDetails.payments}
						refreshDetails={refreshDetails}
					/>
				</Col>
				<Col span={16}>
					<FreightInvoicesTable
						details={voyageDetails}
						fixtureDetails={fixtureDetails}
						refreshDetails={refreshDetails}
						voyageInvoices={voyageInvoices}
						hireInvoicesLoading={voyageInvoicesLoading}
						loading={false}
					/>
				</Col>
			</Row>
		</>
	);
};

export default FreightInvoices;
