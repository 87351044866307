import React, { useMemo } from 'react';
import {
	faAnchor,
	faLayerGroup,
	faFileAlt,
	faQuestionCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Marker } from 'react-map-gl';
import bearing from '@turf/bearing';
import distance from '@turf/distance';
import { Popover } from 'antd';
import type { Icon } from '@fortawesome/fontawesome-svg-core';
import {
	CrewReportTypes,
	DATE,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import {
	combineStoplightColors,
	getConsumptionStoplight,
	getSpeedStoplight,
	GREY_STOPLIGHT_COLOR,
} from '@shared/utils/performanceStoplights';
import type { GetPerformanceDetailsResponse } from '@api/features/performance/getPerformanceDetails';
import type { CrewReportsWithTypes } from '@api/utils/sequelize/getAllCrewReports';
import Button from '@client/components/Button';
import {
	groupReports,
	Report,
} from '@client/utils/groupReports';
import { NoonReportWithPerformanceFields } from '@client/screens/fleet/VesselDetailsScreen/components/CrewReportDrawer/NoonAtSeaReportDrawerContent';
import styles from './styles/ReportsLayer.module.css';
import GeoJsonLayer from './GeoJsonLayer';

export type AllPerformanceReports = [
	...GetPerformanceDetailsResponse['noonAtSeaReports'],
	...GetPerformanceDetailsResponse['noonInPortReports'],
	...GetPerformanceDetailsResponse['arrivalReports'],
	...GetPerformanceDetailsResponse['departureReports'],
]

type ReportsLayerProps = {
	reports: CrewReportsWithTypes[] | AllPerformanceReports;
	openReportDrawer: ((r: any) => void) | undefined;
	connectReports?: boolean;
	zoom: number | undefined;
}

const ReportsLayer = ({
	reports,
	openReportDrawer,
	connectReports = false,
	zoom,
}: ReportsLayerProps): React.ReactElement => {
	const getReportMarkerContent = (
		report: CrewReportsWithTypes | AllPerformanceReports[number],
	) => {
		let color = 'white';

		if (report.type === CrewReportTypes.NOON_AT_SEA) {
			const {
				actualConsumption,
				warrantedConsumption,
				consumptionMargin,
				averageSpeed,
				instructedSpeed,
				speedMargin,
			} = report as NoonReportWithPerformanceFields;

			color = combineStoplightColors(
				getConsumptionStoplight({
					actual: actualConsumption ?? null,
					warranty: warrantedConsumption ?? null,
					marginPercent: consumptionMargin ?? null,
				}).color,
				getSpeedStoplight({
					actual: averageSpeed,
					warranty: instructedSpeed,
					margin: speedMargin ?? null,
				}).color,
			);
		}

		if (color === GREY_STOPLIGHT_COLOR) {
			color = 'white';
		}

		let content;
		switch (report.type) {
			case CrewReportTypes.NOON_AT_SEA:
				content = (
					<FontAwesomeIcon
						icon={faFileAlt as Icon}
						color={color}
						className={styles.fileIcon}
					/>
				);
				break;

			case CrewReportTypes.NOON_IN_PORT:
			case CrewReportTypes.DELIVERY:
			case CrewReportTypes.BUNKERING:
			case CrewReportTypes.REDELIVERY:
			case CrewReportTypes.STATEMENT_OF_FACTS:
				content = (
					<FontAwesomeIcon
						icon={faFileAlt as Icon}
						color="white"
						className={styles.fileIcon}
					/>
				);
				break;

			case CrewReportTypes.ARRIVAL:
			case CrewReportTypes.DEPARTURE:
				content = (
					<FontAwesomeIcon
						className={styles.circleIcon}
						icon={faAnchor as Icon}
						color="white"
					/>
				);
				break;

			default:
				content = (
					<FontAwesomeIcon
						className={styles.circleIcon}
						icon={faQuestionCircle as Icon}
						color="white"
					/>
				);
				break;
		}

		return content;
	};

	const groupedReports = useMemo(
		() => (reports == null ?
			[] :
			groupReports<AllPerformanceReports[number] | CrewReportsWithTypes>(reports)),
		[reports],
	);

	const reportLayers = useMemo(() => groupedReports.map((reportOrGroup) => {
		if (reportOrGroup?.isGroup) {
			const group = reportOrGroup;

			return (
				<Marker
					key={`marker-position-group-${group.reports.map((r) => r.id).join(',')}`}
					longitude={group.longitude}
					latitude={group.latitude}
					offsetTop={-12}
					offsetLeft={-14}
					className={styles.marker}
				>
					<Popover
						// @ts-ignore
						zIndex={0}
						trigger="click"
						content={(
							<div className={styles.groupPopoverContent}>
								<span><b>Reports</b></span>
								<ul>
									{group.reports.map((r) => (
										<li>
											<Button
												onClick={() => {
													openReportDrawer?.(r);
												}}
												type="link"
												size="small"
											>
												{`${r.type} (${toMoment(r.date).format(DATE)})`}
											</Button>
										</li>
									))}
								</ul>
							</div>
						)}
					>
						<FontAwesomeIcon
							icon={faLayerGroup as Icon}
							color="white"
							className={styles.groupIcon}
						/>
					</Popover>
				</Marker>
			);
		}

		const report = reportOrGroup;

		if (report.longitude == null || report.latitude == null) {
			return null;
		}

		return (
			<Marker
				key={`marker-position-${report.id}`}
				longitude={report.longitude}
				latitude={report.latitude}
				offsetTop={
					(
						report.type === CrewReportTypes.NOON_AT_SEA ||
						report.type === CrewReportTypes.NOON_IN_PORT
					) ? -14 : -12
				}
				offsetLeft={
					(
						report.type === CrewReportTypes.NOON_AT_SEA ||
						report.type === CrewReportTypes.NOON_IN_PORT
					) ? 0 : -16
				}
				className={styles.marker}
				// @ts-ignore
				onClick={() => openReportDrawer?.(report)}
			>
				{getReportMarkerContent(report)}
			</Marker>
		);
	}), [groupedReports, openReportDrawer]);

	const lineCoordinates = useMemo(() => {
		let coords: Report[] = [];

		let previouslyUsedReport: Report | null = null;
		let nextReportCandidate: Report | null = null;

		const isBetterCandidate = (newCandidate: Report) => (
			(
				nextReportCandidate == null ||
				toMoment(newCandidate.date).isBefore(nextReportCandidate.date)
			) &&
			(
				previouslyUsedReport == null ||
				toMoment(newCandidate.date).isAfter(previouslyUsedReport.date)
			) && (
				nextReportCandidate?.groupId == null ||
				nextReportCandidate.groupId !== newCandidate.groupId
			)
		);

		while (true) {
			nextReportCandidate = null;

			for (const gr of groupedReports) {
				if (gr.isGroup) {
					for (const r of gr.reports) {
						if (isBetterCandidate(r)) {
							nextReportCandidate = {
								latitude: gr.latitude,
								longitude: gr.longitude,
								date: r.date,
							};
						}
					}
				} else if (isBetterCandidate(gr as Report)) {
					nextReportCandidate = {
						latitude: gr.latitude,
						longitude: gr.longitude,
						date: gr.date,
					};
				}
			}

			if (nextReportCandidate == null) {
				break;
			}

			previouslyUsedReport = nextReportCandidate;

			coords = [...coords, previouslyUsedReport];
		}

		return coords;
	}, [groupedReports]);

	const arrowLayer = useMemo(() => lineCoordinates.map((coordinate, i) => {
		const nextCoordinate = lineCoordinates[i + 1];

		if (nextCoordinate == null) {
			return null;
		}

		const lineBearing = bearing(
			[coordinate.longitude, coordinate.latitude],
			[nextCoordinate.longitude, nextCoordinate.latitude],
		);

		const legDistance = distance(
			[coordinate.longitude, coordinate.latitude],
			[nextCoordinate.longitude, nextCoordinate.latitude],
		);

		const midPointLongitude = (coordinate.longitude + nextCoordinate.longitude) / 2;
		const midPointLatitude = (coordinate.latitude + nextCoordinate.latitude) / 2;

		// Don't render arrow if there is not enough room to show it
		if (legDistance * (zoom ?? 1) < 900) {
			return (<></>);
		}

		return (
			<GeoJsonLayer
				layerStyle={{
					layout: {
						'icon-image': 'longerArrow',
						'icon-size': 0.03,
						'icon-rotate': lineBearing + 270,
					},
					paint: {
						'icon-opacity': 0.80,
					},
				}}
				id={`arrow-${i}`}
				type="symbol"
				data={{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [midPointLongitude, midPointLatitude],
					},
				}}
			/>
		);
	}), [lineCoordinates, zoom]);

	return (
		<>
			{connectReports && reports != null && arrowLayer}
			{reports != null && reportLayers}
		</>
	);
};

export default ReportsLayer;
