// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tycEiMWiFXUL12O9Y7sE {\n\tfont-size: 11pt;\n\tcolor: cyan;\n}\n\n.Rk4nSzhG4DJR7fi_IxUG {\n\tfont-size: 11pt;\n\tcolor: #ff4d4f;\n}\n\n.SsDn95CgcCkBJn3GC_OO {\n\tcursor: pointer;\n}\n\n.nF6bcFUOltMLVT5Fqvww path {\n\tstroke: black;\n\tstroke-width: 25px;\n}\n\n.QOWiLQxBlrkSaR5iJUsx {\n\t--fa-animation-duration: 3s;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/Map/VesselMarker.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,cAAc;AACf;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,2BAA2B;AAC5B","sourcesContent":[".ship {\n\tfont-size: 11pt;\n\tcolor: cyan;\n}\n\n.outdatedShip {\n\tfont-size: 11pt;\n\tcolor: #ff4d4f;\n}\n\n.clickable {\n\tcursor: pointer;\n}\n\n.shipIcon path {\n\tstroke: black;\n\tstroke-width: 25px;\n}\n\n.circle {\n\t--fa-animation-duration: 3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ship": "tycEiMWiFXUL12O9Y7sE",
	"outdatedShip": "Rk4nSzhG4DJR7fi_IxUG",
	"clickable": "SsDn95CgcCkBJn3GC_OO",
	"shipIcon": "nF6bcFUOltMLVT5Fqvww",
	"circle": "QOWiLQxBlrkSaR5iJUsx"
};
export default ___CSS_LOADER_EXPORT___;
