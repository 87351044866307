// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FdhGUmREZ_utJOMb2CIT {\n\tfont-weight: bold;\n}\n\n.e6JVnARoOEV6z4_8h_Ef {\n\tfont-size: 85%;\n}\n", "",{"version":3,"sources":["webpack://./client/src/components/styles/FixtureRecapGenerator.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,cAAc;AACf","sourcesContent":[".itemTitle {\n\tfont-weight: bold;\n}\n\n.itemDescription {\n\tfont-size: 85%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemTitle": "FdhGUmREZ_utJOMb2CIT",
	"itemDescription": "e6JVnARoOEV6z4_8h_Ef"
};
export default ___CSS_LOADER_EXPORT___;
