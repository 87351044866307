// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Pk5H1hBN2WoMkjYFfvrb {\n\twidth: 430px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fixtures/shared/styles/FixtureListSceen.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb","sourcesContent":[".createForm {\n\twidth: 430px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createForm": "Pk5H1hBN2WoMkjYFfvrb"
};
export default ___CSS_LOADER_EXPORT___;
