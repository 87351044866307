import {
	Currencies,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { ensureNegative } from '@shared/utils/math';
import { formatPercentage } from '@shared/utils/formatPercentage';
import { Values } from '@shared/utils/objectEnums';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';
import HIIBbItem from './HIIBbItem';

class HIIBbItemBrokerCommission extends HireInvoiceItem {
	static get itemType() {
		return 'bbItemBrokerCommission';
	}

	brokerId: number;
	bbItemId: number;
	brokerName: string;
	percentage: number;
	fixtureCurrency: Values<typeof Currencies>;

	constructor(id: number, isOriginal: boolean, params: {
		brokerId: number;
		bbItemId: number;
		brokerName: string;
		percentage: number;
		fixtureCurrency: Values<typeof Currencies>;
	}) {
		super(id, isOriginal, { fixtureCurrency: params?.fixtureCurrency });

		this.brokerId = params.brokerId;
		this.bbItemId = params.bbItemId;
		this.brokerName = params.brokerName;
		this.percentage = params.percentage;
		this.fixtureCurrency = params.fixtureCurrency;

		this._showTotal = true;
		this.itemTypeLabel = 'Broker Commission';
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.COMMISSIONS;
		this.pnlGroup = PnlGroups.BROKER_COMMISSIONS;
		this._fields = {};
	}

	_calculateTotal(invoiceItems: HireInvoiceItem[]) {
		const bbItem = this.getParent(invoiceItems) as HIIBbItem;

		if (!bbItem || !invoiceItems) {
			return 0;
		}

		const total = bbItem.getTotal(invoiceItems) * (this.percentage / 100);

		return -1 * total;
	}

	getDescription() {
		return `${formatPercentage(this.percentage, 2)}`;
	}

	static async _getClassParams(model: any, parentModel: any) {
		const broker = await model.getBroker(parentModel);

		return {
			brokerId: model.brokerId,
			brokerName: broker.dataValues.name,
			percentage: model.percentage,
		};
	}

	async saveModel(model: any) {
		await super.saveModel(model);
		model.brokerId = this.brokerId;
		model.bbItemId = this.bbItemId;
		model.percentage = this.percentage;
		await model.save();
	}

	_getTemplateItemParams(invoiceItems: HireInvoiceItem[]) {
		const bbItem = this.getParent(invoiceItems) as HIIBbItem;

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [
					`Broker commission - ${this.brokerName}`,
					`${this.getDescription()}`,
				],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					` — Broker commission (${this.brokerName})`,
					`${formatPercentage(this.percentage, 2, false)}`,
					'%',
					`${formatCurrency(
						ensureNegative(bbItem.getTotal(invoiceItems)),
						this.fixtureCurrency,
						{ hideSymbol: true },
					)}`,
				],
			},
		};
	}

	// @ts-ignore
	_canBeGroupedWith(other: any) {
		return this.percentage === other.percentage;
	}
}

HireInvoiceItem.addItemClass(HIIBbItemBrokerCommission);

export default HIIBbItemBrokerCommission;
