import React, {
	useCallback,
	useState,
} from 'react';
import {
	Grid,
	notification,
} from 'antd';
import { faShip } from '@fortawesome/pro-duotone-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ColumnsType } from 'antd/lib/table/interface';
import {
	VesselOwnershipTypeLabels,
	VesselOwnershipTypes,
	VesselTypeLabels,
	VesselTypes,
} from '@shared/utils/constants';
import { stringToValidNumericString } from '@shared/utils/string';
import { standardSort } from '@shared/utils/standardSort';
import { Values } from '@shared/utils/objectEnums';
import type { GetVesselsResponse } from '@api/features/vessels/getVessels';
import VesselDescription from '@client/components/VesselDescription';
import {
	getVessels,
	createVessel,
} from '@client/lib/api';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import { getFilterProps } from '@client/utils/table';
import ListTableScreen from '@client/components/screens/ListTableScreen';
import { Links } from '@client/utils/links';
import { useAuth } from '@client/lib/auth';
import getSizeOptions from '../VesselDetailsScreen/helpers/getVesselSizeOptions';

type VesselTableData = GetVesselsResponse[number];

type VesselFormData = Pick<VesselTableData, (
	| 'name'
	| 'ownershipType'
	| 'type'
	| 'size'
)>;

const VesselsListScreen: React.FC = () => {
	const [vesselType, setVesselType] = useState(null);
	const [vessels, reloadVessels, error, loading] = useFetchedState(getVessels);
	const { userInfo } = useAuth();
	const screens = Grid.useBreakpoint();

	const vesselFilter = userInfo.filterVessels ? (
		userInfo.relevantVessels
			.map((v) => v.name)
			.filter((n) => n != null)
	) as string[] : [];

	const vesselColumns: ColumnsType<VesselTableData> = [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (name, vessel) => (
				<VesselDescription
					name={name}
					flag={vessel.flag}
				/>
			),
			key: 'name',
			sorter: standardSort('name'),
			defaultSortOrder: 'ascend',
			filteredValue: vesselFilter,
			// @ts-ignore
			...getFilterProps((vessels ?? []), 'select', 'name', 'desc'),
		},
		{
			title: 'Ownership Type',
			dataIndex: 'ownershipType',
			key: 'ownershipType',
			render: (v) => VesselOwnershipTypeLabels[v],
			sorter: standardSort('ownershipType'),
		},
		{
			title: 'Vessel Type',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Vessel Size',
			dataIndex: 'size',
			key: 'size',
		},
		{
			title: 'DWT',
			dataIndex: 'dwt',
			render: (v) => (v == null ? null : `${stringToValidNumericString(v.toString(), { separateThousands: true })} MT`),
			key: 'dwt',
		},
	];

	const createNewVessel = useCallback(async ({
		name,
		ownershipType,
		type,
		size,
	}:
	{
		name: string;
		ownershipType:
		Values<typeof VesselOwnershipTypes>;
		type: Values<typeof VesselTypes> | null;
		size: string | null;
	}) => {
		await createVessel(name, ownershipType, type, size);

		await reloadVessels();

		notification.success({
			message: 'Vessel created',
			description: `${name} has been created and added to the list.`,
		});
	}, [reloadVessels]);

	return (
		<ListTableScreen<VesselTableData, VesselFormData>
			title="Vessels"
			rootPageTitle="Vessels"
			columns={vesselColumns}
			loading={loading}
			tableProps={{ useCards: screens.xs }}
			error={error}
			data={vessels}
			tabs={[{
				key: 'My vessels',
				emptyTabDescription: 'You do not have any vessels yet',
				emptyTabTitle: 'No vessels',
				filter: (v) => v.ownershipType !== VesselOwnershipTypes.MARKET,
				showEmptyTabArrow: true,
				tab: 'My vessels',
			},
			{
				key: 'Market vessels',
				emptyTabDescription: 'You do not have any vessels yet',
				emptyTabTitle: 'No vessels',
				filter: (v) => v.ownershipType === VesselOwnershipTypes.MARKET,
				showEmptyTabArrow: true,
				tab: 'Market vessels',
			}]}
			formProps={{
				onValuesChange: (changedFields) => {
					if (changedFields.type != null) {
						setVesselType(changedFields.type);
					}
				},
			}}
			formFields={[
				{
					label: 'Name',
					name: 'name',
					type: 'text',
					required: true,
				},
				{
					label: 'Ownership Type',
					name: 'ownershipType',
					type: 'select',
					required: true,
					inputProps: {
						placeholder: 'Select ownership type',
					},
					options: Object.values(VesselOwnershipTypes).map((type) => ({
						label: VesselOwnershipTypeLabels[type],
						value: type,
					})),
				},
				{
					label: 'Vessel type',
					name: 'type',
					type: 'select',
					required: false,
					options: Object.keys(VesselTypes).map((t) => ({
						label: VesselTypeLabels[t],
						value: t,
					})),
				},
				{
					name: 'size',
					required: false,
					label: 'Vessel size',
					type: 'select',
					options: getSizeOptions(vesselType),
				},
			]}
			formButtonText="Create vessel"
			onFormSubmit={createNewVessel}
			getRowHref={(row) => Links.Vessel.get(row.id)}
			redirectOnSubmit={false}
			showEmptyArrow
			emptyTitle="You do not have any vessels yet"
			emptyDescription="Click the button to create one"
			emptyIcon={faShip as IconDefinition}
		/>
	);
};

export default VesselsListScreen;
