import React from 'react';

const Spacing = ({ size }: { size: number }) => (
	<div
		style={{
			height: size,
			width: size,
		}}
	/>
);

export default Spacing;
