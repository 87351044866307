import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Collapse,
	Descriptions,
	Badge,
	Row,
	Col,
	Space,
} from 'antd';
import debounce from 'lodash.debounce';
import EditableField from '@client/components/EditableField';
import { updateFixture } from '@client/lib/api';
import showErrorNotification from '@client/utils/showErrorNotification';
import { parseCurrencyAmount } from '@client/utils/form';
import styles from './styles/FixtureSections.module.css';

const FixtureSections = ({
	sections,
	fixture,
	refreshFixture,
	isFieldEntered,
	editValues,
	setEditValues,
	allValues,
	setFixtureUpdating,
}) => {
	const [activeSection, setActiveSection] = useState('Key Terms');

	const saveChanges = useCallback(async (values) => {
		try {
			if (Object.keys(values).length === 0) {
				return;
			}

			const allFields = sections.reduce((arr, s) => [...arr, ...(s.fields || [])], []);

			const transformedResult = Object.entries(values).reduce((obj, [fieldName, value]) => {
				const field = allFields.find((f) => f.key === fieldName);

				let parsedValue = value;

				if (field.type === 'currency' && parsedValue != null) {
					parsedValue = parseCurrencyAmount(parsedValue);
				} else if (field.type === 'select' && parsedValue == null) {
					parsedValue = null;
				}

				const transformed = field.transformResult == null ?
					{ [field.key]: parsedValue } :
					field.transformResult(parsedValue);

				return {
					...obj,
					...transformed,
				};
			}, {});

			await updateFixture(fixture.id, transformedResult);
			await refreshFixture();
		} catch (e) {
			console.error(e);
			showErrorNotification('Could not save estimate', e);
		} finally {
			setFixtureUpdating(false);
		}
	}, [
		fixture.id,
		refreshFixture,
		sections,
		setFixtureUpdating,
	]);

	// Use a ref to always have the latest saveChanges
	const saveChangesRef = useRef(saveChanges);
	useEffect(() => {
		saveChangesRef.current = saveChanges;
	}, [saveChanges]);

	// Create debounced function only once
	const debouncedSaveChanges = useRef(
		debounce((values) => saveChangesRef.current(values), 1000),
	).current;

	const editChange = useCallback((fieldName, value) => {
		setFixtureUpdating(true);
		setEditValues((prevValues) => {
			const newValues = {
				...prevValues,
				[fieldName]: value,
			};

			debouncedSaveChanges(newValues);

			return newValues;
		});
	}, [setEditValues, debouncedSaveChanges, setFixtureUpdating]);

	const getSectionProgress = useCallback((section) => {
		let missingAny = false;
		let missingRequired = false;

		if (section.content) {
			missingAny = !section.isFilled;
			missingRequired = !section.isFilled && section.required;
		}

		if (section.fields) {
			const requiredFields = section.fields.filter((field) => field.required);

			const completedFields = section.fields.filter((field) => (
				isFieldEntered(field)
			));

			const completedRequiredFields = completedFields.filter((field) => field.required);

			const missingFields = section.fields.length - completedFields.length;
			const missingRequiredFields = requiredFields.length - completedRequiredFields.length;

			missingAny = missingAny || missingFields > 0;
			missingRequired = missingRequired || missingRequiredFields > 0;
		}

		if (section.fields && section.isFilled != null) {
			missingAny = !section.isFilled;
			missingRequired = !section.isFilled && section.required;
		}

		if (missingAny) {
			return (
				<Space size="middle" align="center">
					{missingRequired > 0 ? (
						<span className={styles.missingField}>Missing Required</span>
					) : (
						<span className={styles.optionalField}>Optional</span>
					)}
					<Badge
						dot
						color={missingRequired ? 'red' : '#1890ff'}
					/>
				</Space>
			);
		}

		return null;
	}, [isFieldEntered]);

	const sectionsToShow = sections.reduce((arr, section) => {
		let newSection = section;

		if (section.fields) {
			const newFields = section.fields.filter((field) => (
				field.show !== false &&
				!(typeof field.show === 'function' && !field.show(allValues))
			));

			newSection = {
				...section,
				fields: newFields,
			};
		}

		return [
			...arr,
			newSection,
		];
	}, []);

	const editable = !fixture.hasHireInvoices;

	return (
		<div className={styles.container}>
			<Row>
				<Col xs={24} md={14} xl={18}>
					{fixture.fixed && 'The estimate details can be edited until the vessel delivers'}
				</Col>
				<Col xs={24} md={10} xl={6} className={styles.legendRow}>
					<Space align="end">
						<div>
							<Badge
								dot
								color="red"
								className={styles.legendBadge}
							/>
							Required
						</div>
						<div>
							<Badge
								dot
								color="blue"
								className={styles.legendBadge}
							/>
							Optional
						</div>
					</Space>
				</Col>
			</Row>
			<Collapse
				activeKey={activeSection}
				onChange={(activeSections) => setActiveSection(activeSections[activeSections.length - 1])}
				destroyInactivePanel
			>
				{sectionsToShow.map((section) => (
					<Collapse.Panel
						header={section.title}
						key={section.title}
						extra={editable && getSectionProgress(section)}
					>
						<>
							{section.fields && (
								<>
									{section.header}
									<Descriptions bordered>
										{section.fields.map((field) => (
											<Descriptions.Item
												className={styles.row}
												key={field.key}
												label={(field.required ? (
													<>
														{field.label}
														<span style={{ color: 'red' }}>*</span>
														{field?.labelSuffix && field.labelSuffix}
													</>
												) : (field.label))}
												span={field.span || 3}
												labelStyle={field.labelStyle ?? null}
											>
												<EditableField
													item={{
														...field,
														inputProps: {
															...field.inputProps,
															// Add onBlur handler on all input fields
															// Make sure we have the newest value by calling set state
															// But don't change the state
															onBlur: () => setEditValues((values) => {
																saveChanges(values);

																return values;
															}),
														},
													}}
													editChange={(fieldName, value) => editChange(fieldName, value)}
													editValues={editValues}
													editing
												/>
											</Descriptions.Item>
										))}
									</Descriptions>
								</>
							)}
							{section.content && (
								section.content
							)}
						</>
					</Collapse.Panel>
				))}
			</Collapse>
		</div>
	);
};

export default FixtureSections;
