import { Moment } from 'moment';
import { round } from '@shared/utils/math';
import { nowMoment } from '@shared/utils/date';

export const RED_STOPLIGHT_COLOR = 'hsl(0, 100%, 50%)';
export const YELLOW_STOPLIGHT_COLOR = 'hsl(39, 100%, 50%)';
export const GREEN_STOPLIGHT_COLOR = 'hsl(98, 62%, 53%)';
export const GREY_STOPLIGHT_COLOR = 'hsl(0, 0%, 80%)';

type StoplightColor = (
	| typeof RED_STOPLIGHT_COLOR
	| typeof YELLOW_STOPLIGHT_COLOR
	| typeof GREEN_STOPLIGHT_COLOR
	| typeof GREY_STOPLIGHT_COLOR
);

export type StoplightResult = {
	color: StoplightColor;
	description: string;
};

export type SpeedStopLightProps = {
	actual: number | null;
	warranty: number | null;
	margin: number | null;
};

export const getSpeedStoplight = (params: SpeedStopLightProps): StoplightResult => {
	if (params.actual == null || params.warranty == null || params.margin == null) {
		return {
			description: '',
			color: GREY_STOPLIGHT_COLOR,
		};
	}

	const diff = params.actual - params.warranty;

	return {
		description: `${Math.abs(round(diff))} kt ${diff > 0 ? 'over' : 'under'}`,
		color: diff >= 0 ?
			GREEN_STOPLIGHT_COLOR :
			-diff < params.margin ?
				YELLOW_STOPLIGHT_COLOR :
				RED_STOPLIGHT_COLOR,
	};
};

export type ConsumptionStopLightProps = {
	actual: number | null;
	warranty: number | null;
	marginPercent: number | null;
};

export const getConsumptionStoplight = (params: ConsumptionStopLightProps): StoplightResult => {
	if (params.actual == null || params.warranty == null || params.marginPercent == null) {
		return {
			description: '',
			color: GREY_STOPLIGHT_COLOR,
		};
	}

	const diff = params.actual - params.warranty;

	const diffPercentage = (diff / params.warranty) * 100;

	return {
		description: `${Math.abs(round(diffPercentage, 0))}% ${diff > 0 ? 'over' : 'under'}`,
		color: diff <= 0 ?
			GREEN_STOPLIGHT_COLOR :
			diffPercentage <= params.marginPercent ?
				YELLOW_STOPLIGHT_COLOR :
				RED_STOPLIGHT_COLOR,
	};
};

export const getLatestReportDateStoplight = (params: {
	actual: Moment;
}): StoplightResult => {
	const diff = nowMoment().diff(params.actual, 'hours');

	let description: string = `${diff} hours old`;
	let color: StoplightColor = GREEN_STOPLIGHT_COLOR;

	if (diff < 1) {
		description = 'Less than one hour old';
	}

	if (diff === 1) {
		description = `${diff} hour old`;
	}

	if (diff > 24) {
		color = YELLOW_STOPLIGHT_COLOR;
	}

	if (diff > 48) {
		description = `${round(diff / 24, 0)} days old`;
		color = RED_STOPLIGHT_COLOR;
	}

	return {
		description,
		color,
	};
};

export type WeatherStopLightProps = {
	actual: number | null;
	limit: number | null;
};

export const getWeatherStoplight = (
	params: WeatherStopLightProps,
): StoplightResult => {
	if (params.limit == null || params.actual == null) {
		return {
			description: '',
			color: GREY_STOPLIGHT_COLOR,
		};
	}

	return ({
		description: params.actual > params.limit ? 'Bad weather' : '',
		color: params.actual > params.limit ?
			RED_STOPLIGHT_COLOR :
			GREEN_STOPLIGHT_COLOR,
	});
};

export type SteamingHoursStopLightProps = {
	actual: number;
	limit: number | null;
};

export const getSteamingHoursStoplight = (params: SteamingHoursStopLightProps): StoplightResult => {
	if (params.limit == null) {
		return {
			description: '',
			color: GREY_STOPLIGHT_COLOR,
		};
	}

	return ({
		description: params.actual < params.limit ? 'Low steaming hours' : '',
		color: params.actual >= params.limit ? GREEN_STOPLIGHT_COLOR : RED_STOPLIGHT_COLOR,
	});
};

export const combineStoplightColors = (...colors: StoplightColor[]): StoplightColor => {
	if (colors.some((c) => c === RED_STOPLIGHT_COLOR)) {
		return RED_STOPLIGHT_COLOR;
	}

	if (colors.some((c) => c === YELLOW_STOPLIGHT_COLOR)) {
		return YELLOW_STOPLIGHT_COLOR;
	}

	if (colors.some((c) => c === GREEN_STOPLIGHT_COLOR)) {
		return GREEN_STOPLIGHT_COLOR;
	}

	return GREY_STOPLIGHT_COLOR;
};
