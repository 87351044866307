import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import classNames from 'classnames';
import DraggableListDragLayer from '@client/components/DraggableList/private/DraggableListDragLayer';
import DraggableListItem from '@client/components/DraggableList/private/DraggableListItem';
import styles from './DraggableList.module.css';

const DraggableList = ({
	dataItems,
	renderItem,
	renderSeparator,
	itemKeyProp,
	onOrderChange,
	getCanDrag,
	className,
}) => {
	const [dragItems, setDragItems] = useState([]);

	const dragItemsToDataItems = useCallback(
		(dragItemsLocal) => dragItemsLocal.map((item) => item.data),
		[],
	);

	const dataItemsToDragItems = useCallback(
		(itemsLocal) => itemsLocal.map((item, i) => ({
			key: item[itemKeyProp],
			data: item,
			index: i,
		})),
		[itemKeyProp],
	);

	useEffect(() => {
		setDragItems(dataItemsToDragItems(dataItems));
	}, [dataItems, dataItemsToDragItems]);

	const itemsContainerRef = useRef();

	const moveItem = useCallback((dragIndex, hoverIndex) => {
		const draggedItem = dragItems[dragIndex];

		const newDragItems = [...dragItems];

		newDragItems.splice(dragIndex, 1);
		newDragItems.splice(hoverIndex, 0, draggedItem);

		setDragItems(newDragItems);
	}, [dragItems]);

	const draggingDragItem = dragItems.find((di) => di.isDragging);
	const allDataItems = dragItemsToDataItems(dragItems);
	const draggingData = draggingDragItem?.data;

	return (
		<div
			className={classNames(styles.itemsContainer, className)}
			ref={itemsContainerRef}
		>
			<DraggableListDragLayer
				width={itemsContainerRef.current?.offsetWidth ?? 0}
				renderItem={(item, i) => renderItem(item, i, allDataItems, draggingData)}
			/>
			{dragItems.map((dragItem, index) => (
				<DraggableListItem
					key={dragItem.key}
					index={index}
					dragItem={dragItem}
					onDragItemChange={(newItem) => {
						setDragItems([...dragItems.slice(0, index), newItem, ...dragItems.slice(index + 1)]);
					}}
					renderItem={(item, i) => renderItem(item, i, allDataItems, draggingData)}
					renderSeparator={
						renderSeparator == null ?
							null :
							(item, i) => renderSeparator(item, i, allDataItems, draggingData)
					}
					onMove={moveItem}
					onDrop={() => onOrderChange(dragItemsToDataItems(dragItems), dragItem.data, index)}
					getCanDrag={getCanDrag}
					includeSeparator
				/>
			))}
		</div>
	);
};

export default DraggableList;
