import React from 'react';
import { Moment } from 'moment';
import {
	Descriptions,
	Space,
} from 'antd';
import { formatDate } from '@shared/utils/date';
import { DATE_AND_TIME } from '@shared/utils/constants';
import type { Port } from '@api/utils/ports';
import CountryFlag from './CountryFlag';
import Card from './Card/Card';

const SimplePortCall = ({
	port,
	arrivalDate,
	departureDate,
}: {
	port: Port;
	arrivalDate: Moment | null;
	departureDate: Moment | null;
}) => {
	return (
		<Card
			slim
			title={(
				<Space>
					<CountryFlag countryCode={port.countryCode} />
					{port.name}
				</Space>
			)}
			size="small"
		>
			<Descriptions
				size="small"
				column={1}
				bordered
			>
				<Descriptions.Item
					label={(<b>Arrival date</b>)}
					span={1}
				>
					{arrivalDate == null ? 'Unknown' : formatDate(arrivalDate, DATE_AND_TIME)}
				</Descriptions.Item>
				<Descriptions.Item
					span={1}
					label={(<b>Departure date</b>)}
				>
					{departureDate == null ? 'Unknown' : formatDate(departureDate, DATE_AND_TIME)}
				</Descriptions.Item>
			</Descriptions>
		</Card>
	);
};

export default SimplePortCall;
