/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	Dropdown,
	Menu,
} from 'antd';
import { round } from '@shared/utils/math';
import { formatCurrency } from '@shared/utils/currency';
import { Values } from '@shared/utils/objectEnums';
import { Currencies } from '@shared/utils/constants';
import AddButton from '../AddButton';
import styles from './MatrixTable.module.css';

type Category<DataType extends BaseDataType> = {
	key?: keyof DataType;
	title: string;
	inputProps?: {
		addOnAfter?: string;
	};
	break?: boolean;
	render?: (value: any, entry: DataType) => ReactNode;
} & (
	NormalCategory | CurrencyCategory
)

type NormalCategory = {
	type?: 'number' | 'string';
}

type CurrencyCategory = {
	type: 'currency';
}

type BaseDataType = { name: string; id: number; currency?: Values<typeof Currencies> }

const MatrixTable = <DataType extends BaseDataType>({
	categories,
	data,
	remainingEntries,
	onRemoveHeader,
	onAddNewHeader,
}: {
	categories: Category<DataType>[];
	data: DataType[];
	remainingEntries?: BaseDataType[];
	onRemoveHeader: (header: {id: number}) => void;
	onAddNewHeader: (id: number) => void;
}) => {
	return (
		<table style={{ width: '100%' }}>
			<tbody>
				<tr className={styles.row}>
					<th
						colSpan={1}
						className={styles.label}
						style={{ zIndex: 20 }}
					/>
					{data.map((d) => (
						<th colSpan={1} className={styles.label}>
							<div className={styles.spaceBetween}>
								{d.name}
								<FontAwesomeIcon
									className={styles.deleteIcon}
									icon={faXmark as IconProp}
									onClick={() => onRemoveHeader(d)}
								/>
							</div>
						</th>
					))}
					{remainingEntries != null && (
						<th colSpan={1} className={styles.label}>
							<Dropdown
								disabled={remainingEntries.length === 0 || data.length === 5}
								trigger={['click']}
								overlay={(
									<Menu>
										{remainingEntries.map((entry) => (
											<Menu.Item
												key={entry.id}
												onClick={() => onAddNewHeader(entry.id)}
											>
												{entry.name}
											</Menu.Item>
										))}
									</Menu>
								)}
							>
								<AddButton disabledTooltip="No more estimates left">
									Add Estimate
								</AddButton>
							</Dropdown>
						</th>
					)}
				</tr>
				{categories.map((category) => (
					<tr className={styles.row}>
						{category.break ? (
							<th
								colSpan={data.length + 2}
								className={styles.label}
							>
								<b className={styles.categoryTitle}>
									{category.title}
								</b>
							</th>
						) : (
							<>
								<th colSpan={1} className={styles.headerRow}>{category.title}</th>
								{data.map((_e, i) => {
									let value;

									const row = data[i];

									if (category.key != null) {
										value = row[category.key];
									}

									let formattedValue: any = value;

									if (category.type === 'currency') {
										if (row.currency == null) {
											throw new Error('A current must be set on a currency category');
										}

										formattedValue = formatCurrency(
											value as unknown as number ?? 0,
											row.currency,
										);
									}

									if (category.type === 'number') {
										formattedValue = round(value as unknown as number ?? 0, 2);
									}

									if (category.render != null && typeof category.render === 'function') {
										formattedValue = category.render(value ?? 0, data[i]);
									}

									return (
										<td
											colSpan={1}
											className={styles.content}
										>
											{formattedValue}
											{category.inputProps?.addOnAfter != null && ` ${category.inputProps.addOnAfter}`}
										</td>
									);
								})}
							</>
						)}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default MatrixTable;
