import React, {
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { CardTabListType } from 'antd/es/card';
import {
	Col,
	Empty,
	Row,
	Typography,
	Collapse,
	Alert,
	Space,
	Tooltip,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
	faPencil,
	faSave,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { CheckOutlined } from '@ant-design/icons';
import {
	ConsumptionRelationTypes,
	FuelTypes,
	FuelZoneTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { asyncForEach } from '@shared/utils/array';
import type { ConsumptionSetProps } from '@api/models/consumption-set';
import type { PerformanceEntryProps } from '@api/models/performance-entry';
import Card from '@client/components/Card/Card';
import EditableDetails from '@client/components/EditableDetails/EditableDetails';
import EditableCellTableRedux from '@client/components/EditableTableRedux/EditableCellTableRedux';
import showErrorNotification from '@client/utils/showErrorNotification';
import {
	createVesselConsumptionSet,
	deleteVesselConsumptionSet,
	updateVessel,
	updateVesselConsumptionEntries,
	updateVesselConsumptionSet,
} from '@client/lib/api';
import AsyncSwitch from '@client/components/Async/AsyncSwitch';
import Button from '@client/components/Button';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import Table from '@client/components/Table/Table';
import Details from '@client/components/Details';
import {
	getAtSeaEntries,
	getColumns,
	getInPortEntries,
	SIMPLE_COLUMNS_AT_SEA,
	SIMPLE_COLUMNS_IN_PORT,
} from '@client/screens/fleet/VesselDetailsScreen/components/ConsumptionSets/helpers';
import styles from './ConsumptionSets.module.css';

export type SimplePerformance = {
	consumptionSetId?: number;
	relation: ConsumptionRelationTypes;
	fuelZoneType: Values<typeof FuelZoneTypes>;
	fuelType: Values<typeof FuelTypes>;
	ballast?: number;
	laden?: number;
	loading?: number;
	discharging?: number;
	idle?: number;
	ballastId?: number;
	ladenId?: number;
	loadingId?: number;
	dischargingId?: number;
	idleId?: number;
}

type PendingSetChange = {
	id: number;
	ballastSpeed?: number;
	ladenSpeed?: number;
	name?: string;
}

type ConsAndPerformance = ConsumptionSetProps & { PerformanceEntries: Array<PerformanceEntryProps> }

type Props = {
	activeConsumptionSetId?: number | null;
	onMakePrimary?: (id: number) => void;
	consumptionSets: Array<ConsAndPerformance>;
	inPortEntries: Array<PerformanceEntryProps> | undefined;
	vesselId: number | undefined | null;
	refreshVessel: () => void;
	secondaryInPortEnabled: boolean;
	inEstimate?: boolean;
	setEditing?: React.Dispatch<SetStateAction<boolean>>;
	setBlockClose?: React.Dispatch<SetStateAction<boolean>>;
	unsavedChanges?: boolean;
	onRefresh?: () => void;
}

const ConsumptionSets = ({
	consumptionSets,
	onMakePrimary,
	inPortEntries,
	vesselId,
	refreshVessel,
	secondaryInPortEnabled,
	inEstimate = false,
	setEditing,
	activeConsumptionSetId,
	unsavedChanges,
	onRefresh,
	setBlockClose,
}: Props) => {
	const [tabs, setTabs] = useState<Array<CardTabListType>>();
	const [currentTab, setCurrentTab] = useState<string | undefined>(
		activeConsumptionSetId?.toString(),
	);
	const [relevantEntry, setRelevantEntry] = useState<ConsAndPerformance>();
	const [isLoading, setLoading] = useState(false);
	const [oldVesselId] = useState<number | undefined | null>(vesselId);
	const [pendingSetChanges, setPendingSetChanges] = useState<Array<PendingSetChange>>([]);

	const [localData, setLocalData] = useState<{
		atSea: {
			main: Array<SimplePerformance>;
			secondary: Array<SimplePerformance>;
		};
		inPort: {
			main: Array<SimplePerformance>;
			secondary: Array<SimplePerformance>;
		};
	}
	>({
		atSea: {
			main: [],
			secondary: [],
		},
		inPort: {
			main: [],
			secondary: [],
		},
	});

	const handleConsSetChange = (value: { [s: string]: number | string | undefined }) => {
		const id = Number(currentTab);

		if (Number.isNaN(id)) {
			return;
		}

		const change: PendingSetChange = {
			id: Number(currentTab),
			...value,
		};

		if (pendingSetChanges.length === 0) {
			setPendingSetChanges([change]);

			return;
		}

		setPendingSetChanges((prev) => prev.map((c) => {
			if (c.id === id) {
				return {
					...c,
					...change,
				};
			}

			return c;
		}));
	};

	const handleDelete = useCallback(async (id: number) => {
		if (vesselId == null) {
			return;
		}

		try {
			await deleteVesselConsumptionSet(id, vesselId);
			refreshVessel();
			setCurrentTab(tabs?.[0]?.key);
			showSuccessNotification('Consumption set deleted');
			if (typeof onRefresh === 'function') {
				onRefresh();
			}
		} catch (e) {
			showErrorNotification('Could not delete consumption set', e as Error);
		}
	}, [refreshVessel, onRefresh, tabs, vesselId]);

	useEffect(() => {
		if (vesselId !== oldVesselId) {
			setTabs(undefined);
			setCurrentTab(undefined);
		}
	}, [oldVesselId, vesselId]);

	useEffect(() => {
		if (currentTab == null) {
			const tab = tabs?.[0]?.key;

			if (tab == null) {
				return;
			}

			setCurrentTab(tab);

			const entry = consumptionSets.find((s) => s.id.toString() === tab);

			if (entry == null) {
				return;
			}

			setRelevantEntry(entry);
		}
	}, [consumptionSets, currentTab, tabs]);

	useEffect(() => {
		if (isLoading) {
			return;
		}

		const inPort = getInPortEntries(inPortEntries ?? []);

		const entry = consumptionSets.find((s) => s.id.toString() === currentTab);
		setRelevantEntry(entry);

		const atSea = getAtSeaEntries(entry);

		if (localData.atSea.main.length === 0) {
			setLocalData({
				atSea,
				inPort,
			});
		}
	}, [consumptionSets, currentTab, inPortEntries, isLoading, localData.atSea.main.length]);

	const handleTabChange = (tab: string) => {
		setCurrentTab(tab);

		const entry = consumptionSets.find((s) => s.id.toString() === tab);

		if (entry == null) {
			return;
		}

		setRelevantEntry(entry);

		const atSea = getAtSeaEntries(entry);

		setLocalData((prev) => ({
			...prev,
			atSea,
		}));
	};

	const updateConsumptionSet = async (values: { [s: string]: any }) => {
		if (vesselId == null) {
			return;
		}

		if (changesReady) {
			await updateEntriesOnServer(false);
		}

		try {
			await updateVesselConsumptionSet({
				consumptionSetId: Number(currentTab),
				vesselId,
				attributes: values,
			});
			refreshVessel();
			if (typeof onRefresh === 'function') {
				onRefresh();
			}
		} catch (e) {
			showErrorNotification('Could not update consumption set', e as Error);
		}
	};

	const getDiff = useCallback(() => {
		const { inPort } = localData;
		const { atSea } = localData;

		const transformedInPort = [
			...inPort.main,
			...inPort.secondary,
		].map((v) => {
			const loading = {
				id: v.loadingId,
				consumption: v.loading,
				fuelType: v.fuelType,
			};

			const discharging = {
				id: v.dischargingId,
				consumption: v.discharging,
				fuelType: v.fuelType,
			};

			const idle = {
				id: v.idleId,
				consumption: v.idle,
				fuelType: v.fuelType,
			};

			return [loading, discharging, idle];
		}).flat();

		const transformedAtSea = [
			...atSea.main,
			...atSea.secondary,
		].map((v) => {
			const ballast = {
				id: v.ballastId,
				consumption: v.ballast,
				fuelType: v.fuelType,
			};

			const laden = {
				id: v.ladenId,
				consumption: v.laden,
				fuelType: v.fuelType,
			};

			return [ballast, laden];
		}).flat();

		const together = [...transformedAtSea, ...transformedInPort];

		const performanceEntries = consumptionSets.map((p) => p.PerformanceEntries).flat();
		const allOldEntries = [...(inPortEntries ?? []), ...performanceEntries];

		const toUpdate: Array<{
			id?: number;
			consumption?: number;
			fuelType?: Values<typeof FuelTypes>;
		}> = [];

		together.forEach((newEntry) => {
			const oldEntry = allOldEntries.find((old) => old.id === newEntry.id);

			if (oldEntry == null) {
				return;
			}

			if (
				oldEntry.consumption !== newEntry.consumption ||
				oldEntry.fuelType !== newEntry.fuelType
			) {
				toUpdate.push(newEntry);
			}
		});

		return toUpdate;
	}, [consumptionSets, inPortEntries, localData]);

	const makePrimary = async () => {
		if (relevantEntry != null && onMakePrimary != null) {
			onMakePrimary(relevantEntry.id);
		}
	};

	const changesReady = useMemo(() => {
		const changes = (getDiff().length !== 0) || pendingSetChanges.length > 0;

		setBlockClose?.(changes);

		return changes;
	}, [getDiff, setBlockClose, pendingSetChanges]);

	const getTabs = useCallback((
		sets: Array<ConsumptionSetProps>,
		isPreview: boolean = false,
	): Array<CardTabListType> => {
		const isActive = (id: number) => id === activeConsumptionSetId;

		return sets
			.map((s) => {
				const label = changesReady ? (
					<Tooltip title="You have unsaved changes. Please save these before changing consumption profile.">
						{s.name}
					</Tooltip>
				) : s.name;

				return ({
					label: (isActive != null && isActive(s.id)) ? `${s.name} (active)` : label,
					key: s.id.toString(),
					id: s.id.toString(),
					disabled: changesReady && !isPreview,
					closeable: sets.length > 1,
					closeIcon: (
						sets.length > 1 && (
							<Button
								danger
								type="text"
								icon={(<FontAwesomeIcon icon={faTrash} />)}
								onClick={async () => {
									await handleDelete(s.id);
								}}
								confirmTitle="Are you sure you want to delete this entry?"
							/>
						)
					),
				});
			})
			.sort((a, b) => Number(a.id) - Number(b.id));
	}, [handleDelete, activeConsumptionSetId, changesReady]);

	useEffect(() => {
		if (consumptionSets != null && tabs == null) {
			setTabs(getTabs(consumptionSets));
		}
	}, [consumptionSets, getTabs, tabs, activeConsumptionSetId]);

	const updateEntriesOnServer = useCallback(async (_refresh?: boolean) => {
		if (vesselId == null) {
			return;
		}

		setLoading(true);

		const diff = getDiff();

		if (diff.length === 0 && pendingSetChanges.length === 0) {
			setLoading(false);

			return;
		}

		try {
			if (pendingSetChanges.length > 0) {
				await asyncForEach(pendingSetChanges, async (setChange) => {
					const attributes = {
						name: setChange.name,
						ladenSpeed: setChange.ladenSpeed,
						ballastSpeed: setChange.ballastSpeed,
					};
					await updateVesselConsumptionSet({
						consumptionSetId: setChange.id,
						vesselId,
						attributes,
					});
				});
			}

			await updateVesselConsumptionEntries({
				vesselId,
				attributes: diff,
			});

			if (typeof onRefresh === 'function') {
				onRefresh();
			}

			refreshVessel();
			setPendingSetChanges([]);

			setLoading(false);
		} catch (e) {
			showErrorNotification('Could not update consumption entry', e as Error);
		}
	}, [vesselId, getDiff, onRefresh, refreshVessel, pendingSetChanges]);

	const updateEntries = async (vals: Array<SimplePerformance>, type: 'inPort' | 'atSea', relation: 'main' | 'secondary') => {
		if (vesselId == null) {
			return;
		}

		setLocalData((prev) => ({
			...prev,
			[type]: {
				...prev[type],
				[relation]: vals,
			},
		}));
	};

	const handleAdd = async (
		_tab: string | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
		event: 'add' | 'remove',
	) => {
		if (vesselId == null || event === 'remove') {
			return;
		}

		try {
			await createVesselConsumptionSet(vesselId);
			refreshVessel();
			if (typeof onRefresh === 'function') {
				onRefresh();
			}
		} catch (e) {
			showErrorNotification('Could not create new consumption set', e as Error);
		}
	};

	const copyNormalRow = async (ecaRow: SimplePerformance, inPort: boolean = false) => {
		if (vesselId == null) {
			return;
		}

		const type = inPort ? 'inPort' : 'atSea';

		const [normalRow] = localData[type][ecaRow.relation]
			.filter((n) => n.fuelZoneType === FuelZoneTypes.NORMAL);

		let copiedRow = {};

		if (inPort) {
			copiedRow = {
				...ecaRow,
				loading: normalRow.loading,
				discharging: normalRow.discharging,
				idle: normalRow.idle,
			};
		} else {
			copiedRow = {
				...ecaRow,
				ballast: normalRow.ballast,
				laden: normalRow.laden,
			};
		}

		setLocalData((prev) => ({
			...prev,
			[type]: {
				...prev[type],
				[ecaRow.relation]: [normalRow, copiedRow],
			},
		}));
	};

	if (relevantEntry == null) {
		return (<Empty>No consumption data available</Empty>);
	}

	if (inEstimate) {
		const firstTable = (
			<Table
				className={styles.simpleTable}
				pagination={false}
				size="small"
				dataSource={localData.atSea.main}
				bordered
				columns={[{
					title: 'At Sea - Main',
					children: SIMPLE_COLUMNS_AT_SEA,
				}]}
			/>
		);

		const secondTable = (
			<Table
				className={styles.simpleTable}
				pagination={false}
				size="small"
				dataSource={localData.inPort.main}
				bordered
				columns={[{
					title: 'In Port - Main',
					children: SIMPLE_COLUMNS_IN_PORT,
				}]}
			/>
		);

		const thirdTable = relevantEntry.enableSecondary ? (
			<Table
				pagination={false}
				size="small"
				dataSource={localData.atSea.secondary}
				bordered
				columns={[{
					title: 'At Sea - Secondary',
					children: SIMPLE_COLUMNS_AT_SEA,
				}]}
			/>
		) : null;

		const fourthTable = secondaryInPortEnabled ? (
			<Table
				pagination={false}
				size="small"
				dataSource={localData.inPort.secondary}
				bordered
				columns={[{
					title: 'In Port - Secondary',
					children: SIMPLE_COLUMNS_IN_PORT,
				}]}
			/>
		) : null;

		const tables = [firstTable, secondTable, thirdTable, fourthTable];
		const filtered = tables.filter((t): t is React.ReactElement => t !== null);

		return (
			<Card
				tabList={getTabs(consumptionSets, true)}
				onTabChange={handleTabChange}
				activeTabKey={currentTab}
				tabBarExtraContent={(
					<Space>
						{(
							activeConsumptionSetId != null &&
							activeConsumptionSetId !== relevantEntry.id
						) && (
							<Button
								icon={(<CheckOutlined />)}
								onClick={makePrimary}
							>
								Use this consumption profile
							</Button>
						)}
						<Button
							disabled={unsavedChanges}
							disabledTooltip="You have unsaved changes. Please save these before editing consumptions."
							icon={(<FontAwesomeIcon icon={faPencil} />)}
							type="primary"
							onClick={() => {
								if (typeof setEditing === 'function') {
									setEditing(true);
								}
							}}
						>
							Edit
						</Button>
					</Space>
				)}
				className={styles.smallCard}
				bordered={!inEstimate}
				slim
				size="small"
			>
				<div className={styles.simpleContainer}>
					<Row gutter={16}>
						<Col span={4} sm={6}>
							<Details
								hideHeader
								labelWidth={70}
								items={[
									{
										label: 'Ballast',
										key: 'ballastSpeed',
										value: `${relevantEntry.ballastSpeed} kt`,
									}, {
										label: 'Laden',
										key: 'ladenSpeed',
										value: `${relevantEntry.ladenSpeed} kt`,
									},
								]}
							/>
						</Col>
						<Col span={18}>
							<div className={styles.flexVertical}>
								<Space className={styles.flex} align="start">
									{filtered.slice(0, 2)}
								</Space>
								<Space className={styles.flex}>
									{filtered.slice(2, 4)}
								</Space>
							</div>
						</Col>
					</Row>
				</div>
			</Card>
		);
	}

	return (
		<Space direction="vertical">
			<Alert
				className={styles.alert}
				message={`
				Consumption figures are used for estimating bunker consumption during voyages.
				Secondary fuel types will only be included in estimated consumptions if enabled.
				`}
			/>
			<Button
				type="primary"
				size="large"
				className={styles.saveBtn}
				icon={(<FontAwesomeIcon icon={faSave} />)}
				disabled={!changesReady}
				onClick={() => updateEntriesOnServer(true)}
			>
				Save changes
			</Button>
			<Collapse defaultActiveKey={['atSea', 'inPort']}>
				<Collapse.Panel
					key="inPort"
					header={(<Title level={5} className={styles.title}>In Port</Title>)}
					className={styles.collapse}
				>
					<Space>
						<EditableCellTableRedux<SimplePerformance>
							title={() => (<Title level={5} className={styles.title}>Main</Title>)}
							className={styles.consumptionTable}
							pagination={false}
							dataSource={localData.inPort.main}
							columns={getColumns(copyNormalRow, false, true)}
							size="small"
							onChange={(values) => updateEntries(values, 'inPort', 'main')}
							loading={isLoading}
						/>
						<EditableCellTableRedux<SimplePerformance>
							title={() => (
								<div className={styles.spaceBetween}>
									<Title level={5} className={styles.title}>
										Secondary
									</Title>
									<Space>
										<Typography.Text>
											Enable secondary fuel type
										</Typography.Text>
										<AsyncSwitch
											checked={secondaryInPortEnabled}
											onChange={async (v) => {
												if (vesselId == null) {
													return;
												}

												if (changesReady) {
													await updateEntriesOnServer(true);
												}

												await updateVessel(vesselId, {
													enableSecondaryInPortConsumption: v,
												});

												refreshVessel();

												if (typeof onRefresh === 'function') {
													onRefresh();
												}
											}}
										/>
									</Space>
								</div>
							)}
							className={styles.consumptionTable}
							pagination={false}
							dataSource={localData.inPort.secondary}
							columns={getColumns(copyNormalRow, !secondaryInPortEnabled, true)}
							size="small"
							onChange={(values) => updateEntries(values, 'inPort', 'secondary')}
							loading={isLoading}
						/>
					</Space>
				</Collapse.Panel>
				<Collapse.Panel
					key="atSea"
					header={(<Title level={5} className={styles.title}>At Sea</Title>)}
					className={styles.collapse}
				>
					<Card
						tabList={getTabs(consumptionSets)}
						onTabChange={handleTabChange}
						activeTabKey={currentTab}
						className={classNames({
							[styles.card]: !inEstimate,
							[styles.cardWithMargin]: inEstimate,
						})}
						tabProps={{
							type: 'editable-card',
							onEdit: handleAdd,
							addIcon: (
								<Button
									className={styles.tabBtn}
									type="link"
									icon={(<FontAwesomeIcon size="lg" icon={faPlus} />)}
									onClick={async (e) => {
										e?.stopPropagation();
										if (changesReady) {
											await updateEntriesOnServer(true);
										}

										await handleAdd('', 'add');
									}}
									popconfirmProps={{
										onCancel: (e) => e?.stopPropagation(),
									}}
									confirmTitle={changesReady ? 'You have unsaved changes. Do you want to save?' : undefined}
									clickPropagation={false}
								/>
							),
						}}
						bordered={false}
					>
						<Row gutter={16}>
							<Col span={8}>
								<Space className={styles.space} direction="vertical">
									<EditableDetails
										key={relevantEntry.id}
										title="Details"
										labelWidth={100}
										hideButtons
										editing
										onSave={updateConsumptionSet}
										onEditValuesChange={handleConsSetChange}
										items={[
											{
												label: 'Entry name',
												key: 'name',
												type: 'text',
												editable: true,
												value: relevantEntry.name,
											},
											{
												label: 'Ballast',
												key: 'ballastSpeed',
												type: 'number',
												inputProps: {
													addonAfter: 'kt',
												},
												editable: true,
												value: relevantEntry.ballastSpeed,
												render: (c) => (
													<>
														{c.value}
														{' '}
														kt
													</>
												),
											}, {
												label: 'Laden',
												key: 'ladenSpeed',
												type: 'number',
												inputProps: {
													addonAfter: 'kt',
												},
												editable: true,
												value: relevantEntry.ladenSpeed,
												render: (c) => (
													<>
														{c.value}
														{' '}
														kt
													</>
												),
											},
										]}
									/>
								</Space>
							</Col>
							<Col span={16}>
								<div className={styles.flexVertical}>
									<EditableCellTableRedux<SimplePerformance>
										className={styles.consumptionTable}
										pagination={false}
										title={() => (<Title level={5} className={styles.title}>Main</Title>)}
										dataSource={localData.atSea.main}
										columns={getColumns(copyNormalRow)}
										size="small"
										onChange={(values) => updateEntries(values, 'atSea', 'main')}
										loading={isLoading}
									/>
									<EditableCellTableRedux<SimplePerformance>
										className={styles.consumptionTable}
										pagination={false}
										title={() => (
											<div className={styles.spaceBetween}>
												<Title level={5} className={styles.title}>
													Secondary
												</Title>
												<Space>
													<Typography.Text>
														Enable secondary fuel type
													</Typography.Text>
													<AsyncSwitch
														key={`switch-${relevantEntry?.id}`}
														checked={relevantEntry?.enableSecondary}
														onChange={async (v) => {
															await updateConsumptionSet({ 'enableSecondary': v });
														}}
													/>
												</Space>
											</div>
										)}
										dataSource={localData.atSea.secondary}
										columns={getColumns(copyNormalRow, !relevantEntry.enableSecondary)}
										size="small"
										onChange={(values) => updateEntries(values, 'atSea', 'secondary')}
										loading={isLoading}
									/>
								</div>
							</Col>
						</Row>
					</Card>
				</Collapse.Panel>
			</Collapse>
		</Space>
	);
};

export default ConsumptionSets;
