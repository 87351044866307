// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PbwnP3AqwLfR6u0g5Tc7 tr:nth-child(odd) {\n\tbackground-color: #f6f6f6;\n}\n\n.JzNt4kzzKQ_SelGr6Pz3 {\n\twidth: 75px;\n\ttext-align: center;\n}\n\n.PWPRyo1BlgMwmGWXv86a {\n\twidth: 100%;\n}\n\n.gfNuc_YqlTRzHluO3v9o {\n\tmin-width: 600px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/ProfitAndLossTab/helpers/styles/styles.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,gBAAgB;AACjB","sourcesContent":[".table tr:nth-child(odd) {\n\tbackground-color: #f6f6f6;\n}\n\n.tag {\n\twidth: 75px;\n\ttext-align: center;\n}\n\n.fullWidth {\n\twidth: 100%;\n}\n\n.minWidth {\n\tmin-width: 600px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "PbwnP3AqwLfR6u0g5Tc7",
	"tag": "JzNt4kzzKQ_SelGr6Pz3",
	"fullWidth": "PWPRyo1BlgMwmGWXv86a",
	"minWidth": "gfNuc_YqlTRzHluO3v9o"
};
export default ___CSS_LOADER_EXPORT___;
