import { getCargoPortLaytimeStats } from '@shared/utils/getCargoPortLaytimeStats';
import { capitalize } from '@shared/utils/string';
import { LaytimeTerms } from '@shared/utils/constants';
import { formatCurrency } from '@shared/utils/currency';
import { TemplateItemChildren } from '@shared/hireInvoice/HireInvoiceItem';
import { CargoProps } from '@api/models/cargo';
import { CargoPortProps } from '@api/models/cargo-port';
import { LaytimeCalculationProps } from '@api/models/laytime-calculation';

export const getLaytimeInvoiceLineDescriptions = (
	cargo: CargoProps & {
		CargoPorts: Array<CargoPortProps & { LaytimeCalculations: Array<LaytimeCalculationProps> }>;
	},
): TemplateItemChildren => {
	const { laytimeTerms } = cargo;

	return cargo.CargoPorts.map((cargoPort) => {
		const {
			action,
			timeInDays,
			isDem,
			totalDemurrage,
			totalDespatch,
		} = getCargoPortLaytimeStats(cargoPort);
		const type = isDem ? 'Demurrage' : 'Despatch';
		const time = isDem ? 'lost' : 'gained';
		const rate = isDem ?
			formatCurrency(cargoPort.demurrage ?? 0, cargo.currency) :
			formatCurrency(cargoPort.despatch ?? 0, cargo.currency);

		switch (laytimeTerms) {
			case LaytimeTerms.REVERSIBLE:
				return {
					section: `${cargoPort.port.name} - ${capitalize(action)}`,
					columns: [`${timeInDays} ${timeInDays === 1 ? 'day' : 'days'} ${time}`],
				};

			case LaytimeTerms.NON_REVERSIBLE:
				return {
					section: `${cargoPort.port.name} - ${capitalize(action)}`,
					columns: [
						`${timeInDays} ${timeInDays === 1 ? 'day' : 'days'} ${time}`,
						`${type} @ ${rate} per day`,
					],
					total: isDem ? totalDemurrage : totalDespatch,
				};

			case LaytimeTerms.AVERAGED:
				return {
					section: cargoPort.port.name,
					columns: [
						`${timeInDays} ${timeInDays === 1 ? 'day' : 'days'} ${time}`,
					],
				};

			default:
				return {
					section: '',
					columns: ['', ''],
				};
		}
	});
};
