import React, { useState } from 'react';
import classNames from 'classnames';
import { EditOutlined } from '@ant-design/icons';
import {
	Typography,
	Skeleton,
} from 'antd';
import { toMoment } from '@shared/utils/date';
import { DATE } from '@shared/utils/constants';
import { formatDate } from '@client/utils/formatDate';
import Select from '@client/components/Select';
import Button from '@client/components/Button';
import DatePicker from './DatePicker';
import styles from './styles/EditableText.module.css';

const EditableText = ({
	type = 'text',
	options,
	defaultValue,
	placeholder,
	onChange,
	textProps = {},
	inputProps = {},
	warnAsRequired = false,
	disabled = false,
	renderValue,
}) => {
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);

	const onInputChange = async (value) => {
		setLoading(true);
		setEditing(false);
		await onChange(value);
		setLoading(false);
	};

	const getInput = () => {
		if (type === 'date') {
			return (
				<DatePicker
					value={defaultValue}
					onChange={onInputChange}
					defaultOpen
					onOpenChange={(isOpen) => {
						if (!isOpen) {
							setEditing(false);
						}
					}}
					autoFocus
					{...inputProps}
				/>
			);
		}

		// if not date then select
		return (
			<Select
				type="select"
				defaultValue={defaultValue}
				onSelect={onInputChange}
				defaultOpen
				options={options}
				onDropdownVisibleChange={() => setEditing(false)}
				{...inputProps}
				className={classNames(styles.stateSelect, inputProps.className)}
			/>
		);
	};

	if (loading) {
		return (
			<Skeleton.Button
				className={styles.skeleton}
				active
				shape="round"
				size="small"
			/>
		);
	}

	if (editing) {
		return (<span>{getInput()}</span>);
	}

	if (type !== 'text') {
		let text = defaultValue;

		if (text != null && typeof renderValue === 'function') {
			text = renderValue(text);
		} else if (
			type === 'date' &&
			defaultValue != null &&
			toMoment(defaultValue).isValid()
		) {
			text = formatDate(defaultValue, textProps.format || DATE);
		} else if (defaultValue == null && placeholder != null) {
			text = placeholder;
		}

		return (
			<Typography.Text className={styles.customEditField}>
				{warnAsRequired && (<p className={styles.customEditFieldRequired}>﹡</p>)}
				{text}
				{!disabled && (
					<Button
						className={styles.customEditButton}
						type="link"
						onClick={(e) => {
							e.stopPropagation();
							setEditing(!editing);
						}}
					>
						<EditOutlined />
					</Button>
				)}
			</Typography.Text>
		);
	}

	return (
		<Typography.Text
			editable={disabled ? undefined : { onChange: (value) => onInputChange(value) }}
		>
			{defaultValue == null ? placeholder : defaultValue}
		</Typography.Text>
	);
};

export default EditableText;
