import React from 'react';
import {
	FixtureCounterpartyTypes,
	FixtureTypes,
} from '@shared/utils/constants';
import { getTcExtraColumns } from '@client/screens/fixtures/tc/getTcExtraColumns';
import FixtureListScreen from '@client/screens/fixtures/shared/FixtureListScreen';

const FixtureTcOutListScreen = () => {
	const counterpartyType = FixtureCounterpartyTypes.CHARTEREROWNER;

	return (
		<FixtureListScreen
			fixtureType={FixtureTypes.TC_OUT}
			counterpartyType={counterpartyType}
			getExtraColumns={getTcExtraColumns}
			allowCreateNewCharterer
		/>
	);
};

export default FixtureTcOutListScreen;
