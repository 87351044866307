import React, {
	useState,
	useEffect,
	useMemo,
} from 'react';
import {
	AutoComplete,
	Tag,
} from 'antd';
import { TagsOutlined } from '@ant-design/icons';
import Button from '@client/components/Button';
import { getStringModuloColor } from '@client/utils/colors';
import styles from './styles/TagList.module.css';

const filterTagOptions = (value, option) => (
	option.value.toLowerCase().includes(value.toLowerCase())
);

const TagList = ({
	tags: tagsProp,
	options: rawOptions,
	onRemoveTag,
	onAddTag,
}) => {
	const [open, setOpen] = useState(false);
	const [inputValue, setInputValue] = useState('');
	// We use a state value so adding/removing tags is instant,
	// even though they haven't been added/removed on the server yet
	const [tags, setTags] = useState(tagsProp);

	useEffect(() => {
		setTags(tagsProp);
	}, [tagsProp]);

	const options = useMemo(() => (
		rawOptions
			.filter((o) => !tags.includes(o))
			.map((o) => ({ value: o }))
	), [rawOptions, tags]);

	const close = () => {
		setOpen(false);
		setInputValue('');
	};

	const addTag = (tag) => {
		const createMatch = tag.match(/^Create "(.*)"$/);

		let newTag;

		// If the create regex matched anything, we must be creating a new tag
		if (createMatch != null && !rawOptions.includes(tag)) {
			const [_fullMatch, matched] = createMatch;

			newTag = matched;
		} else {
			newTag = tag;
		}

		if (!tags.includes(newTag)) {
			setTags([...tags, newTag]);
			onAddTag(newTag);
		}

		close();
	};

	const removeTag = (tag) => {
		setTags(tags.filter((t) => t !== tag));
		onRemoveTag(tag);
	};

	const keyDown = (e) => {
		if (e.key !== 'Enter') {
			return;
		}

		// Try to find an exact match, except for capitalization
		const match = rawOptions.find((o) => o.toLowerCase() === inputValue.toLowerCase());

		// If there is no match, create a new tag
		addTag(match || inputValue);
	};

	return (
		<>
			<div className={styles.list}>
				{tags.map((tag) => (
					<Tag
						key={tag}
						closable
						onClose={() => removeTag(tag)}
						color={getStringModuloColor(tag)}
						className={styles.tag}
					>
						{tag}
					</Tag>
				))}
			</div>
			{open ? (
				<AutoComplete
					className={styles.input}
					onClick={(e) => e.stopPropagation()}
					onBlur={close}
					size="small"
					mode="tags"
					placeholder="Add a tag..."
					options={options}
					notFoundContent="No tags found"
					autoFocus
					defaultOpen
					filterOption={filterTagOptions}
					onChange={(v) => setInputValue(v)}
					onSelect={addTag}
					onKeyDown={keyDown}
				/>
			) : (
				<Button
					className={styles.addTagButton}
					onClick={(e) => {
						e.stopPropagation();
						setOpen(true);
					}}
					size="small"
				>
					<div>
						<TagsOutlined />
						{' +'}
					</div>
				</Button>
			)}
		</>
	);
};

export default TagList;
