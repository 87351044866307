import React, {
	useState,
	useCallback,
	useEffect,
	Ref,
	CSSProperties,
} from 'react';
import {
	Popover,
	Form,
	Grid,
} from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { FieldData } from 'rc-field-form/lib/interface';
import Button, { ButtonProps } from '@client/components/Button';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import SimpleForm, { SimpleFormProps } from './SimpleForm';
import styles from './styles/FormPopover.module.css';

export type FormPopoverProps<Values> = {
	onSubmit: (values: Values) => void;
	title: React.ReactNode;
	initialValues?: {};
	buttonText?: React.ReactNode;
	buttonRef?: Ref<HTMLButtonElement | HTMLAnchorElement>;
	buttonProps?: ButtonProps;
	resetFormOnHide?: boolean;
	disabled?: boolean;
	afterSubmit?: (row: any | null) => void;
	formVisible?: boolean;
	popoverWidth?: CSSProperties['width'];
	destroyTooltipOnHide?: boolean;
} & Omit<SimpleFormProps<Values>, 'title'> & PopoverProps;

const FormPopover = <Values, >({
	buttonText,
	buttonRef,
	title,
	placement,
	buttonProps,
	resetFormOnHide = true,
	form: formOverride,
	children,
	disabled,
	onSubmit,
	afterSubmit,
	formVisible: formVisibleProp,
	onOpenChange,
	onFieldsChange,
	popoverWidth,
	destroyTooltipOnHide = false,
	...props
}: FormPopoverProps<Values>) => {
	const [form] = Form.useForm<Values>(formOverride);
	const [formVisibleState, setFormVisibleState] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [submitResult, setSubmitResult] = useState<Values | null | undefined>(undefined);

	const screens = Grid.useBreakpoint();

	const formVisible = (formVisibleProp !== undefined ?
		formVisibleProp :
		formVisibleState
	);

	const setFormVisible = (formVisibleProp !== undefined ?
		onOpenChange :
		setFormVisibleState
	);

	const {
		makeBlockable,
		useBlocker,
		isBlocking,
	} = useNavigationBlock();

	const enableBlocker = formVisible && dirty && submitResult === undefined;

	useBlocker(
		enableBlocker,
		'You have not saved the form. Are you sure you want to close it?',
	);

	const toggleVisibility = makeBlockable((visible: boolean) => {
		if (!visible && resetFormOnHide) {
			form.resetFields();
		}

		// Whenever visibility is toggled, reset blocking state
		setDirty(false);
		setSubmitResult(undefined);

		if (onOpenChange != null) {
			onOpenChange(visible);
		}

		setFormVisible?.(visible);
	});

	const submit = async (values: Values) => {
		const result = await onSubmit(values);

		// Hide form and reset fields
		setSubmitResult(result == null ? null : result);
		setFormVisible?.(false);
	};

	useEffect(() => {
		if (
			typeof afterSubmit === 'function' &&
			submitResult !== undefined &&
			!formVisible &&
			!isBlocking
		) {
			afterSubmit(submitResult);
		}
	}, [submitResult, formVisible, isBlocking, afterSubmit]);

	const fieldsChange = useCallback((changedFields: FieldData[], allFields: FieldData[]) => {
		if (typeof onFieldsChange === 'function') {
			onFieldsChange(changedFields, allFields);
		}

		setDirty(form.isFieldsTouched());
	}, [form, onFieldsChange]);

	return (
		<Popover
			trigger={disabled ? [] : 'click'}
			open={formVisible}
			onOpenChange={toggleVisibility}
			placement={placement || 'bottomRight'}
			arrow={{ pointAtCenter: true }}
			title={title}
			destroyTooltipOnHide={destroyTooltipOnHide}
			content={(
				<SimpleForm<Values>
					form={form}
					onSubmit={submit}
					className={styles.form}
					// eslint-disable-next-line react/forbid-component-props
					style={{ width: screens.xs ? '285px' : popoverWidth }}
					onFieldsChange={fieldsChange}
					{...props}
				/>
			)}
		>
			{children || (
				<Button
					ref={buttonRef}
					disabled={disabled}
					type="primary"
					{...(buttonProps || {})}
				>
					{buttonText}
				</Button>
			)}
		</Popover>
	);
};

export default FormPopover;
