import {
	faBoxesStacked,
	faContactBook,
	faFileSignature,
	faRoute,
	faShip,
} from '@fortawesome/pro-thin-svg-icons';
import { Values } from './objectEnums';

export const DATE = 'YYYY-MM-DD';
export const TIME = 'HH:mm';
export const DATE_AND_TIME = `${DATE} ${TIME}`;
export const DATE_SHORT_MONTH = 'DD MMM.';
export const SHORT_DATE_AND_TIME = `DD/MM/YY ${TIME}`;

export const NULL_STRING = '_null';

export const SUPPORT_EMAIL = 'support@clearvoyage.com';

export const SELECTED_ORGANIZATION_HEADER = 'CV-Selected-Organization';
export const SELECTED_ORGANIZATION_ID_LOCAL_STORAGE_KEY = 'CV-selected-organization';

export const IMPERSONATION_HEADER = 'CV-Impersonation';
export const IMPERSONATION_ID_LOCAL_STORAGE_KEY = 'CV-impersonation-key';

// Features that should only be available to a subset of customers, can be added below.
// It can then be checked with the `useFeatureFlag` hook on the frontend
// and the `hasFeatureFlag` function on backend.
// NOTE: Remove the feature flag and all references to it, when the feature is released to everyone
export const FeatureFlags = {
	PERFORMANCE: 'performance',
	ACCOUNTING: 'accounting',
} as const;

export const MillisecondsPer = {
	SECOND: 1000,
	MINUTE: 60000,
	HOUR: 3600000,
	DAY: 86400000,
} as const;

export const TheShipReportTypes = {
	10: 'End of Sea Passage',
	20: 'Start of Sea Passage',
	30: 'Noon Report',
	40: 'Arrival Report',
	50: 'Departure Report',
	80: 'Anchored',
	90: 'Weighted Anchor',
};

export const IndustryStandards = {
	CONSUMPTION_MARGIN: 5,
	SPEED_MARGIN: 0.5,
	WINDFORCE_LIMIT: 4,
	STEAMING_HOURS_LIMIT: 24,
} as const;

export const MetersPer = {
	NAUTICAL_MILE: 1852,
	KILOMETER: 1000,
} as const;

export const VesselTypes = {
	DRY_BULK: 'DRY_BULK',
	CRUDE_PRODUCT: 'CRUDE_PRODUCT',
	CHEMICAL: 'CHEMICAL',
	LPG: 'LPG',
	LNG: 'LNG',
	CONTAINER: 'CONTAINER',
	WIND: 'WIND',
} as const;

export const VesselTypeLabels = {
	[VesselTypes.DRY_BULK]: 'Dry Bulk',
	[VesselTypes.CRUDE_PRODUCT]: 'Crude & Product Tanker',
	[VesselTypes.CHEMICAL]: 'Chemical Tanker',
	[VesselTypes.LPG]: 'LPG',
	[VesselTypes.LNG]: 'LNG',
	[VesselTypes.CONTAINER]: 'Container',
	[VesselTypes.WIND]: 'Wind',
} as const;

export const DryBulkSizes = {
	Capesize: 'Capesize',
	PostPanamax: 'Post-Panamax',
	Kamsarmax: 'Kamsarmax',
	Panamax: 'Panamax',
	Ultramax: 'Ultramax',
	Supramax: 'Supramax',
	Handymax: 'Handymax',
} as const;

export const CrudeProductSizes = {
	VLCC: 'VLCC',
	Suezmax: 'Suezmax',
	LR2: 'LR2',
	Aframax: 'Aframax',
	LR1: 'LR1',
	Panamax: 'Panamax',
};

export const ChemicalSizes = {
	Handymax: 'Handymax',
	Handysize: 'Handysize',
};

export const LPGSizes = {
	VLGC: 'VLGC',
	MGC: 'MGC',
	LGC: 'LGC',
};

export const LNGSizes = {
	QMax: 'Q-Max',
	QFlex: 'Q-Flex',
	Large: 'Large',
	Small: 'Small',
};

export const WINDSizes = {
	OSV: 'OSV',
};

export const ContainerSizes = {
	UltraLarge: 'Ultra Large',
	NewPanamax: 'New Panamax',
	PostPanamax: 'Post-Panamax',
	Panamax: 'Panamax',
	Feedermax: 'Feedermax',
	Feeder: 'Feeder',
};

export const HIIFieldTypes = {
	NUMBER: 'NUMBER',
	CURRENCY: 'CURRENCY',
	TEXT: 'TEXT',
	DATE: 'DATE',
	PERCENTAGE: 'PERCENTAGE',
	SELECT: 'SELECT',
	EXCHANGE_RATE: 'EXCHANGE_RATE',
} as const;

export enum EngineType {
	MainEngine = 'Main Engine',
	AuxEngine = 'Aux Engine',
	Boiler = 'Boiler',
}

export enum ConsumptionReason {
	Propulsion = 'Propulsion',
	CargoHeating = 'Cargo Heating',
	BallastExchange = 'Ballast Exchange',
	Other = 'Other',
}

export const AlertTypes = {
	ETA_SLIPPAGE: 'ETA_SLIPPAGE',
	LATE_HIRE_INVOICING: 'LATE_HIRE_INVOICING',
	NEW_PAYMENT: 'NEW_PAYMENT',
	FLEET_PERFORMANCE: 'FLEET_PERFORMANCE',
	ARRIVAL_DEPARTURE: 'ARRIVAL_DEPARTURE',
	APPROVE_EXPENSE: 'APPROVE_EXPENSE',
	NEW_EXPENSE: 'NEW_EXPENSE',
	WEEKLY_SUMMARY: 'WEEKLY_SUMMARY',
} as const;

export const AlertTypeLabels = {
	[AlertTypes.ETA_SLIPPAGE]: 'Might miss laycan',
	[AlertTypes.LATE_HIRE_INVOICING]: 'New hire invoice is due',
	[AlertTypes.NEW_PAYMENT]: 'A new payment has been registrered',
};

export const PaymentTerms = {
	PERIOD_START: 'PERIOD_START',
	PERIOD_END: 'PERIOD_END',
};

export const PaymentTermLabels = {
	[PaymentTerms.PERIOD_START]: 'Start of each hire period',
	[PaymentTerms.PERIOD_END]: 'End of each hire period',
};

export const CargoUnitTypes = {
	MT: 'MT',
	CBM: 'CBM',
	UNITS: 'UNITS',
	BARRELS: 'BARRELS',
} as const;

export const CargoUnitSingularLabels = {
	[CargoUnitTypes.MT]: 'MT',
	[CargoUnitTypes.CBM]: 'm³',
	[CargoUnitTypes.UNITS]: 'Unit',
	[CargoUnitTypes.BARRELS]: 'Barrel',
} as const;

export const CargoUnitLabels = {
	[CargoUnitTypes.MT]: 'MT',
	[CargoUnitTypes.CBM]: 'm³',
	[CargoUnitTypes.UNITS]: 'Units',
	[CargoUnitTypes.BARRELS]: 'Barrels',
} as const;

export const AnchorOrBerthActions = {
	LET_GO: 'Let go anchor',
	ANCHOR_AWEIGH: 'Anchor aweigh',
	FINISHED: 'Finished with engines',
} as const;

export const FuelTypes = {
	VLSFO: 'VLSFO', // VLS FO (≤ 0.5% sulfur)
	ULSFO: 'ULSFO', // ULS FO (≤ 0.1% sulfur)
	LSFO: 'LSFO', // LS FO (≤ 1.0% sulfur)
	HSFO: 'HSFO', // HS FO (≤ 3.5% sulfur)
	LSMGO: 'LSMGO', // Low Sulfur Marine Gas Oil
	ULSDO: 'ULSDO', // ULS DO/MGO (≤ ~0.001% sulfur)
	VLSDO: 'VLSDO', // VLS DO/MGO (≤ 0.1% sulfur)
	LSDO: 'LSDO', // LS DO/MGO (≤ 0.5% sulfur)
	HSDO: 'HSDO', // HS DO (> 0.5% sulfur)
	LNG: 'LNG', // LNG
} as const;

export const FuelSulfurContents = {
	[FuelTypes.VLSFO]: 0.5,
	[FuelTypes.ULSFO]: 0.1,
	[FuelTypes.LSFO]: 1,
	[FuelTypes.HSFO]: 3.5,
	[FuelTypes.LSMGO]: 0.1,
	[FuelTypes.ULSDO]: 0.001,
	[FuelTypes.VLSDO]: 0.1,
	[FuelTypes.LSDO]: 0.5,
	[FuelTypes.HSDO]: 0.6,
	[FuelTypes.LNG]: 0,
};

export const FuelZoneTypes = {
	NORMAL: 'normal',
	ECA: 'eca',
} as const;

export const FuelZoneTypesLabels = {
	[FuelZoneTypes.NORMAL]: 'Propulsion (Normal)',
	[FuelZoneTypes.ECA]: 'Propulsion (ECA)',
} as const;

export const PortActivityTypes = {
	IDLE: 'idle',
	WORKING: 'working',
} as const;

export const PortActivityTypesLabels = {
	[PortActivityTypes.IDLE]: 'Idle',
	[PortActivityTypes.WORKING]: 'Working',
} as const;

export const FreightStates = {
	SUBMITTED: 'submitted',
	APPROVED: 'approved',
	POSTED: 'posted',
} as const;

export const FUEL_TYPE_EMISSIONS: Record<Values<typeof FuelTypes>, number> = {
	[FuelTypes.HSFO]: 3.114,
	[FuelTypes.LSFO]: 3.151,
	[FuelTypes.VLSFO]: 3.151,
	[FuelTypes.HSDO]: 3.206,
	[FuelTypes.LNG]: 2.750,
	[FuelTypes.ULSDO]: 3.206,
	[FuelTypes.ULSFO]: 3.151,
	[FuelTypes.LSDO]: 3.206,
	[FuelTypes.VLSDO]: 3.206,
	[FuelTypes.LSMGO]: 3.206,
} as const;

export const VoyageBunkerTypes = {
	DELIVERY: 'delivery',
	REDELIVERY: 'redelivery',
	OWNERS_ACCOUNT: 'ownersAccount',
	CHARTERERS_ACCOUNT: 'charterersAccount',
} as const;

export const VoyageBunkerTypeLabels = {
	[VoyageBunkerTypes.DELIVERY]: 'delivery',
	[VoyageBunkerTypes.REDELIVERY]: 'redelivery',
	[VoyageBunkerTypes.OWNERS_ACCOUNT]: 'owner\'s account',
	[VoyageBunkerTypes.CHARTERERS_ACCOUNT]: 'charterer\'s account',
} as const;

export const SenderTypes = {
	SUPPLIER: 'supplier',
	CHARTERER: 'charterer',
	ORGANIZATION: 'organization',
	BROKER: 'broker',
} as const;

export const HIITypeLabels = {
	'hirePeriod': 'Hire Period',
	'addressCommission': 'Address Comm.',
	'brokerCommission': 'Broker Comm.',
	'expenseSubjectToHireDays': 'CVE',
	'vendorExpense': 'Vendor Expense',
	'voyageExpense': 'Voyage Expense',
	'voyageBunker': 'Voyage Bunker',
	'offHirePeriod': 'Off-Hire Period',
	'offHireAddressCommission': 'Off-Hire Address Comm.',
	'offHireBrokerCommission': 'Off-Hire Broker Comm.',
	'offHireExpenseSubjectToHireDays': 'Off-Hire CVE',
	'offHireBunker': 'Off-Hire Bunker',
} as const;

export const TcInAllocationItemTypes = {
	VOYAGE_BUNKER: 'voyageBunker',
	VENDOR_EXPENSE: 'vendorExpense',
	VOYAGE_EXPENSE: 'voyageExpense',
};

export const CalculationMethodForExpensesSubjectToHireDays = {
	ANNUALLY: 'annual',
	MONTHLY: 'monthly',
	FIXED: 'fixed',
} as const;

export const AccountTypes = {
	CHARTERER: 'charterer',
	OWNER: 'owner',
} as const;

export const PortCallTypes = {
	NORMAL: 'normal',
	COMMENCEMENT: 'commencement',
	COMPLETION: 'completion',
	DELIVERY: 'delivery',
	REDELIVERY: 'redelivery',
};

export const AttachmentTypes = {
	VESSEL: 'vessel',
	FIXTURE: 'fixture',
	DOCUMENT: 'document',
	VOYAGE: 'voyage',
	HIRE_INVOICE: 'hireInvoice',
	FREIGHT_INVOICE: 'freightInvoice',
	BROKER_INVOICE: 'brokerInvoice',
	VOYAGE_EXPENSE: 'voyageExpense',
	VENDOR_EXPENSE: 'vendorExpense',
	VOYAGE_EXPENSE_RECEIVABLE: 'voyageExpenseReceivable',
	RobBunker: 'robBunker',
	ROB: 'rob',
	CONNECTION: 'connection',
	CARGO: 'cargo',
	BUNKER_STEM: 'bunkerStem',
} as const;

export enum CustomFieldType {
	NUMBER = 'NUMBER',
	TEXT = 'TEXT',
	DATEPICKER = 'DATEPICKER',
}

export enum CustomFieldParentTypes {
	BANK = 'bank',
	ORG = 'org',
}

export enum HireUnit {
	DAYS = 'days',
	MONTHS = 'months',
}

export const TYPE_TO_ICON = {
	[AttachmentTypes.VOYAGE]: faRoute,
	[AttachmentTypes.VESSEL]: faShip,
	[AttachmentTypes.FIXTURE]: faFileSignature,
	[AttachmentTypes.CONNECTION]: faContactBook,
	[AttachmentTypes.CARGO]: faBoxesStacked,
};

export const AttachmentTypeLabels = {
	[AttachmentTypes.VESSEL]: 'Vessel',
	[AttachmentTypes.FIXTURE]: 'Fixture',
	[AttachmentTypes.DOCUMENT]: 'Document',
	[AttachmentTypes.VOYAGE]: 'Voyage',
	[AttachmentTypes.HIRE_INVOICE]: 'Hire Invoice',
	[AttachmentTypes.BROKER_INVOICE]: 'Broker Invoice',
	[AttachmentTypes.VENDOR_EXPENSE]: 'Vendor Expense',
	[AttachmentTypes.VOYAGE_EXPENSE]: 'Voyage Expense',
	[AttachmentTypes.VOYAGE_EXPENSE_RECEIVABLE]: 'Receivable Voyage Expense',
	[AttachmentTypes.FREIGHT_INVOICE]: 'Invoice',
	[AttachmentTypes.ROB]: 'ROB',
	[AttachmentTypes.RobBunker]: 'Bunker',
} as const;

export const ExpenseTypes = {
	PAYABLE: 'payable',
	BOTH: 'payable_and_receivable',
};

export const VoyageIdentifierTypes = {
	DEFAULT: 'default',
	CUSTOM: 'custom',
} as const;

export const HireTypes = {
	FIXED: 'fixed',
	FLOATING: 'floating',
} as const;

export const HireInvoiceItemStates = {
	PENDING: 'pending',
	INVOICED: 'invoiced',
	UNRESOLVED: 'unresolved',
	PAID: 'paid',
} as const;

export const PortCallParentTypes = {
	FIXTURE: 'fixture',
	VOYAGE: 'voyage',
} as const;

export const PerformanceEntryTypes = {
	FIXTURE: 'fixture',
	VESSEL: 'vessel',
};

export const DashboardTypes = {
	HOME: 'home',
	OFF_HIRE: 'offHire',
	FLEET_PNL: 'fleetPnl',
	TC_OUT: 'tcOut',
	TC_IN: 'tcIn',
	RECEIVABLES: 'receivables',
	OUTSTANDINGS: 'outstandings',
	OPERATIONS: 'operations',
} as const;

export const Departments = {
	MANAGEMENT: {
		key: 'management',
		name: 'Management',
	},
	CHARTERING: {
		key: 'chartering',
		name: 'Chartering',
	},
	OPERATIONS: {
		key: 'operations',
		name: 'Operations',
	},
	FINANCE: {
		key: 'finance',
		name: 'Finance',
	},
	ADMIN: {
		key: 'admin',
		name: 'Admin',
	},
} as const;

export type DepartmentKey = {
	[Key in keyof (typeof Departments)]: (typeof Departments)[Key]['key']
}[keyof typeof Departments];

export const FixtureCounterpartyTypes = {
	CHARTEREROWNER: 'charterer / owner',
	SUPPLIER: 'supplier',
	BROKER: 'broker',
} as const;

export const VesselOwnershipTypes = {
	OWNED: 'owned',
	TC_IN: 'tcIn',
	MARKET: 'market',
} as const;

export const VesselOwnershipTypeLabels = {
	[VesselOwnershipTypes.OWNED]: 'Owned',
	[VesselOwnershipTypes.TC_IN]: 'TC-IN',
	[VesselOwnershipTypes.MARKET]: 'Market',
} as const;

export const VoyageDocumentTags = [
	'Charter Party',
	'Recap',
	'Survey',
	'Voyage Expense',
] as const;

export const VesselDocumentTags = [
	'Cargo',
	'Certificate',
	'Classification',
	'Dimensions',
	'Engines & Propulsion',
	'Flag',
	'General',
	'H&M',
	'Loadline',
	'Mooring & Towage',
	'Ownership',
	'P&I',
	'PSC',
	'Questionnaire',
	'Tanks',
	'USCG',
] as const;

export const FixtureStates = {
	PENDING: 'pending',
	FIXED: 'fixed',
	COMMENCED: 'commenced',
	COMPLETED: 'completed',
} as const;

export const FixtureTypes = {
	TC_OUT: 'tcOut',
	TC_IN: 'tcIn',
	SPOT: 'spot',
	BB_OUT: 'bbOut',
} as const;

export const FixtureTypeLabels = {
	[FixtureTypes.BB_OUT]: 'BB-OUT',
	[FixtureTypes.TC_OUT]: 'TC-OUT',
	[FixtureTypes.TC_IN]: 'TC-IN',
	[FixtureTypes.SPOT]: 'VC-OUT',
} as const;

export enum BareboatItemType {
	CAPEX = 'Capex',
	OPEX = 'Opex',
	DRY_DOCK = 'Drydock',
}

// Values must be valid Moment units
export const PeriodUnits = {
	DAYS: 'days',
	MONTHS: 'months',
	YEARS: 'years',
} as const;

export const DashboardPeriods = {
	YEAR: 'year',
	QUARTER: 'quarter',
	SIXTY_DAYS: 'sixtydays',
	HUNDRED_TWENTY_DAYS: 'hundredtwentydays',
} as const;

export type YearQuarterDashboardPeriods = Values<Pick<typeof DashboardPeriods, 'QUARTER' | 'YEAR'>>;
export type DaysDashboardPeriods = Values<Pick<typeof DashboardPeriods, 'SIXTY_DAYS' | 'HUNDRED_TWENTY_DAYS'>>;

export const ReceivableDaysByPeriod = {
	[DashboardPeriods.SIXTY_DAYS]: 60,
	[DashboardPeriods.HUNDRED_TWENTY_DAYS]: 120,
} as const;

export const ReceivableIntervalByPeriod = {
	[DashboardPeriods.SIXTY_DAYS]: 15,
	[DashboardPeriods.HUNDRED_TWENTY_DAYS]: 30,
} as const;

export const DashboardForwardPeriodLabels = {
	[DashboardPeriods.YEAR]: 'Rest of Year',
	[DashboardPeriods.QUARTER]: 'Rest of Quarter',
	[DashboardPeriods.SIXTY_DAYS]: '60 days',
	[DashboardPeriods.HUNDRED_TWENTY_DAYS]: '120 days',
} as const;

export const DashboardPeriodLabels = {
	[DashboardPeriods.YEAR]: 'Yearly',
	[DashboardPeriods.QUARTER]: 'Quarterly',
	[DashboardPeriods.SIXTY_DAYS]: '60 days',
	[DashboardPeriods.HUNDRED_TWENTY_DAYS]: '120 days',
} as const;

export const DashboardBackwardPeriodLabels = {
	[DashboardPeriods.YEAR]: 'YTD',
	[DashboardPeriods.QUARTER]: 'QTD',
} as const;

export const Colors = {
	PRIMARY: '#368ec9',
} as const;

export const ChartColors = {
	BLUE: '#096dd9',
	DARK_BLUE: '#06488f',
	LIGHTBLUE: '#5cc0ff',
	DARK_LIGHTBLUE: '#3d779c',
	CYAN: '#14CCAA',
	DARK_CYAN: '#0e806b',
	RED: '#DE6449',
	GREEN: '#43C59E',
	GREY: '#DADADA',
};

export const VesselConditionTypes = {
	BALLAST: 'ballast',
	LADEN: 'laden',
	LOADING: 'loading',
	DISCHARGING: 'discharging',
	IDLE: 'idle',
} as const;

export const ExpensesSubjectToHireDaysIntervals = {
	pcm: 'pcm',
	perDay: 'perDay',
};

export const ExpensesSubjectToHireDaysIntervalLabels = {
	[ExpensesSubjectToHireDaysIntervals.pcm]: 'Per calendar month',
	[ExpensesSubjectToHireDaysIntervals.perDay]: 'Per day',
};

export const UtcOffsets = [
	{
		name: 'UTC-12:00',
		offset: -720,
	},
	{
		name: 'UTC-11:00',
		offset: -660,
	},
	{
		name: 'UTC-10:00',
		offset: -600,
	},
	{
		name: 'UTC-09:30',
		offset: -570,
	},
	{
		name: 'UTC-09:00',
		offset: -540,
	},
	{
		name: 'UTC-08:00',
		offset: -480,
	},
	{
		name: 'UTC-07:00',
		offset: -420,
	},
	{
		name: 'UTC-06:00',
		offset: -360,
	},
	{
		name: 'UTC-05:00',
		offset: -300,
	},
	{
		name: 'UTC-04:00',
		offset: -240,
	},
	{
		name: 'UTC-03:30',
		offset: -210,
	},
	{
		name: 'UTC-03:00',
		offset: -180,
	},
	{
		name: 'UTC-02:00',
		offset: -120,
	},
	{
		name: 'UTC-01:00',
		offset: -60,
	},
	{
		name: 'UTC',
		offset: 0,
	},
	{
		name: 'UTC+01:00',
		offset: 60,
	},
	{
		name: 'UTC+02:00',
		offset: 120,
	},
	{
		name: 'UTC+03:00',
		offset: 180,
	},
	{
		name: 'UTC+03:30',
		offset: 210,
	},
	{
		name: 'UTC+04:00',
		offset: 240,
	},
	{
		name: 'UTC+04:30',
		offset: 270,
	},
	{
		name: 'UTC+05:00',
		offset: 300,
	},
	{
		name: 'UTC+05:30',
		offset: 330,
	},
	{
		name: 'UTC+05:45',
		offset: 345,
	},
	{
		name: 'UTC+06:00',
		offset: 360,
	},
	{
		name: 'UTC+06:30',
		offset: 390,
	},
	{
		name: 'UTC+07:00',
		offset: 420,
	},
	{
		name: 'UTC+08:00',
		offset: 480,
	},
	{
		name: 'UTC+08:45',
		offset: 525,
	},
	{
		name: 'UTC+09:00',
		offset: 540,
	},
	{
		name: 'UTC+09:30',
		offset: 570,
	},
	{
		name: 'UTC+10:00',
		offset: 600,
	},
	{
		name: 'UTC+10:30',
		offset: 630,
	},
	{
		name: 'UTC+11:00',
		offset: 660,
	},
	{
		name: 'UTC+12:00',
		offset: 720,
	},
	{
		name: 'UTC+12:45',
		offset: 765,
	},
	{
		name: 'UTC+13:00',
		offset: 780,
	},
	{
		name: 'UTC+14:00',
		offset: 840,
	},
];

export const SeedOrgName = 'ClearVoyage';
export const SeedUserName = 'Test User';

export const Currencies = {
	AUD: 'AUD',
	BGN: 'BGN',
	BRL: 'BRL',
	CAD: 'CAD',
	CHF: 'CHF',
	CNY: 'CNY',
	CZK: 'CZK',
	DKK: 'DKK',
	EUR: 'EUR',
	GBP: 'GBP',
	HKD: 'HKD',
	HRK: 'HRK',
	HUF: 'HUF',
	IDR: 'IDR',
	INR: 'INR',
	ISK: 'ISK',
	ILS: 'ILS',
	JPY: 'JPY',
	KRW: 'KRW',
	MYR: 'MYR',
	MXN: 'MXN',
	NOK: 'NOK',
	NZD: 'NZD',
	PHP: 'PHP',
	PLN: 'PLN',
	RON: 'RON',
	RUB: 'RUB',
	SEK: 'SEK',
	SGD: 'SGD',
	THB: 'THB',
	TRY: 'TRY',
	USD: 'USD',
	ZAR: 'ZAR',
	AED: 'AED',
} as const;

export const currencySymbols = {
	[Currencies.USD]: '$',
	[Currencies.GBP]: '£',
	[Currencies.EUR]: '€',
	[Currencies.JPY]: '¥',
	[Currencies.RUB]: '₽',
	[Currencies.CNY]: '¥',
	[Currencies.INR]: '₹',
	[Currencies.THB]: '฿',
} as const;

export const BankAccountTypes = {
	TC_IN: 'TC_IN',
	OURS: 'OURS',
} as const;

export const PortActionTypes = {
	DELIVERING: 'delivering',
	REDELIVERING: 'redelivering',
	LOADING: 'loading',
	DISCHARGING: 'discharging',
	BUNKERING: 'bunkering',
	DRY_DOCKING: 'dryDocking',
	CANAL_TRANSIT: 'canalTransit',
	WAITING: 'waiting',
	BALLAST_LEG: 'ballastLeg',
} as const;

export const ShortPortActionTypes = {
	[PortActionTypes.DELIVERING]: 'DE',
	[PortActionTypes.REDELIVERING]: 'RD',
	[PortActionTypes.LOADING]: 'LO',
	[PortActionTypes.DISCHARGING]: 'DI',
	[PortActionTypes.BUNKERING]: 'BU',
	[PortActionTypes.DRY_DOCKING]: 'DD',
	[PortActionTypes.CANAL_TRANSIT]: 'CT',
	[PortActionTypes.WAITING]: 'WA',
	[PortActionTypes.BALLAST_LEG]: 'BL',
} as const;

export const PortActionTypeLabels: Record<Values<typeof PortActionTypes>, string> = {
	[PortActionTypes.DELIVERING]: 'Delivering',
	[PortActionTypes.REDELIVERING]: 'Redelivering',
	[PortActionTypes.LOADING]: 'Loading',
	[PortActionTypes.DISCHARGING]: 'Discharging',
	[PortActionTypes.BUNKERING]: 'Bunkering',
	[PortActionTypes.DRY_DOCKING]: 'Dry Docking',
	[PortActionTypes.CANAL_TRANSIT]: 'Canal Transit',
	[PortActionTypes.WAITING]: 'Waiting',
	[PortActionTypes.BALLAST_LEG]: 'Ballast Leg',
} as const;

export const PnlGroups = {
	ADDRESS_COMMISSIONS: 'ADDRESS_COMMISSIONS',
	BROKER_COMMISSIONS: 'BROKER_COMMISSIONS',
	BALLAST_BONUSES: 'BALLAST_BONUSES',
	OWNERS_BUNKERS: 'OWNERS_BUNKERS',
	CHARTERERS_BUNKERS: 'CHARTERERS_BUNKERS',
	EXPENSES_SUBJECT_TO_HIRE_DAYS: 'EXPENSES_SUBJECT_TO_HIRE_DAYS',
	REVENUE_SUBJECT_TO_DAYS: 'REVENUES_SUBJECT_TO_DAYS',
	HIRE: 'HIRE',
	OFFHIRE: 'OFFHIRE',
	OWNER_EXPENSES: 'OWNER_EXPENSES',
	CHARTERER_EXPENSES: 'CHARTERER_EXPENSES',
	DEMURRAGE_DESPATCH: 'DEMURRAGE_DESPATCH',
	FREIGHT: 'FREIGHT',
	OTHER_REVENUE: 'OTHER_REVENUE',
	BAREBOAT_ITEM: 'BAREBOAT_ITEM',
} as const;

export enum PnlTypes {
	ACTUAL = 'actual',
	ESTIMATED = 'estimated',
	INVOICED = 'invoiced',
}

export const UnnamedBankAccountName = 'Unnamed Bank Account';

export const QuantityOptionTypes = {
	MOLOO: 'MOLOO',
	MOLCHOPT: 'MOLCHOPT',
};

export enum EstimateStatus {
	'Available',
	'Working',
	'Subjects',
	'Fixed',
}

export const FreightRateType = {
	PER_UNIT: 'PER_UNIT',
	LUMPSUM: 'LUMPSUM',
} as const;

export const CrewReportTypes = {
	COMMENCEMENT: 'Commencement',
	NOON_AT_SEA: 'Noon at Sea',
	NOON_IN_PORT: 'Noon in Port',
	DEPARTURE: 'Departure',
	ARRIVAL: 'Arrival',
	BUNKERING: 'Bunkering',
	BUNKERS_ROB: 'Bunkers ROB',
	BUNKER_STEM: 'Bunker Stem',
	DELIVERY: 'Delivery',
	REDELIVERY: 'Redelivery',
	READINESS: 'Notice of Readiness',
	ANCHORING: 'Anchoring',
	BERTHING: 'Berthing',
	STATEMENT_OF_FACTS: 'Statement of Facts',
	REEVALUATION: 'Re-evaluation',
	COMPLETION: 'Completion',
} as const;

export const DefaultCargoUnit = CargoUnitTypes.MT;

export const InvoiceLogoModes = {
	NONE: 'NONE',
	ORGANIZATION_LOGO: 'ORGANIZATION_LOGO',
	CUSTOM_LOGO: 'CUSTOM_LOGO',
} as const;

export const CardinalDirections = {
	N: 0,
	NNE: 22.5,
	NE: 45,
	ENE: 67.5,
	E: 90,
	ESE: 112.5,
	SE: 135,
	SSE: 157.5,
	S: 180,
	SSW: 202.5,
	SW: 225,
	WSW: 247.5,
	W: 270,
	WNW: 292.5,
	NW: 315,
	NNW: 337.5,
} as const;

export const UNKNOWN_PORT = 'Unknown Port';

export const NoonReportThresholds = {
	AVERAGE_SPEED: {
		min: 0, max: 30,
	},
	OBSERVED_DISTANCE: {
		min: 0, max: 750,
	},
	ENGINE_DISTANCE: {
		min: 0, max: 750,
	},
	FORWARD_DRAFT: {
		min: 0, max: 25,
	},
	MID_DRAFT: {
		min: 0, max: 25,
	},
	AFT_DRAFT: {
		min: 0, max: 25,
	},
	SLIP: {
		min: -100, max: 100,
	},
	MAIN_ENGINE_OUTPUT: {
		min: 0, max: 10000,
	},
	AVERAGE_RPM: {
		min: 0, max: 400,
	},
	STEAMING_HOURS: {
		min: 0, max: 24,
	},
	WIND_FORCE: {
		min: 0, max: 12,
	},
	CURRENT_SPEED: {
		min: 0, max: 50,
	},
	SEA_HEIGHT: {
		min: 0, max: 25,
	},
	SWELL_HEIGHT: {
		min: 0, max: 25,
	},
	CONSUMPTION: {
		min: 0, max: 75,
	},
};

export const ChildBunkerTypes = {
	VESSEL: 'vessel',
	VOYAGE: 'voyage',
} as const;

export enum VcPortTypes {
	COMPLETION = 'Completion',
	COMMENCEMENT = 'Commencement',
}

export enum PnlItemTypes {
	FREIGHT = 'Freight',
	DEMURRAGE_DESPATCH = 'Demurrage & Despatch',
	PORT_COST = 'Port Cost',
	GROSS_HIRE = 'Gross Hire',
	GROSS_BALLAST_BONUS = 'Gross Ballast Bonus',
	ADDRESS_COMMISSIONS = 'Address Commissions',
	BROKER_COMMISSIONS = 'Broker Commissions',
	BUNKER_EXPENSES = 'Bunker Expenses',
	EXPENSES_SUBJECT_TO_HIRE_DAYS = 'Expenses Subject to Hire Days',
	REVENUE_SUBJECT_TO_DAYS = 'Revenue Subject to Days',
	REIMBURSABLE_EXPENSES = 'Charterer\'s Expenses',
	BUNKER_LOSS_GAIN = 'Bunker Gain/(Loss)',
	REPOSITIONING_BUNKERS = 'Repositioning Bunkers',
	OTHER_REVENUE = 'Charterer\'s Expenses',
	TOTAL_REVENUE = 'Total Revenue',
	PAYABLE_EXPENSES = 'Owner\'s Expenses',
	OFF_HIRE = 'Off Hire',
	CAPEX = 'CAPEX',
	OPEX = 'OPEX',
	TC_IN_COSTS = 'TC-In Costs',
	OTHER_EXPENSES = 'Owner\'s Expenses',
	OTHER_VOYAGE_EXPENSES = 'Other Voyage Expenses',
	TOTAL_EXPENSES = 'Total Expenses',
	FINAL_VOYAGE_PROFIT = 'Voyage Profit',
	FINAL_VOYAGE_PROFIT_PER_DAY = 'TCE',
	DAYS = 'Total Days',
	NET_RESULT = 'Net Result',
	NET_RESULT_PER_DAY = 'Net Result Per Day',
}

export enum PnlRenderTypes {
	CURRENCY = 'currency',
	PERCENTAGE = 'percentage',
}

export enum LaytimeCalculationMethods {
	SHEX_EIU = 'SHEX EIU',
	SHINC = 'SHINC',
	SHEX_UU_FULL_DAY = 'SHEX UU (full day)',
	SHEX_UU_HALF_DAY = 'SHEX UU (half day)',
}

export const DEFAULT_SEA_ROUTE_SETTINGS = {
	allowSuezTransit: true,
	allowPanamaTransit: true,
	allowKielTransit: true,
	avoidHRA: false,
	avoidSeca: false,
	allowIceAreas: false,
	speed: 12,
	seaMargin: 0,
	overrideDraft: undefined,
};

export enum PortCallStatus {
	INTENDED = 'intended',
	FIRM = 'firm',
	FOR_INSTRUCTIONS = 'for instructions',
}

export enum QuantityOptions {
	MOLOO = 'MOLOO',
	MOLCHOPT = 'MOLCHOPT',
}

export enum LaytimeTerms {
	REVERSIBLE = 'Reversible',
	NON_REVERSIBLE = 'Non-reversible',
	AVERAGED = 'Averaged',
}

export enum LaytimeIntervals {
	DAY = 'day',
	HOUR = 'hour',
}

export enum SofActions {
	ALL_FAST = 'All fast',
	ANCHORED = 'Anchored',
	ARRIVED_PILOT_STATION = 'Arrived pilot station',
	BERTHED = 'Berthed',
	CARGO_DOCUMENTS_ON_BOARD = 'Cargo documents onboard',
	COMMENCED_LOADING = 'Commenced loading',
	COMMENCED_DISCHARGING = 'Commenced discharging',
	COMPLETED_LOADING = 'Completed loading',
	COMPLETED_DISCHARGING = 'Completed discharging',
	DEPARTED_PILOT_STATION = 'Departed pilot station',
	FIRST_LINE = 'First line',
	FREE_PRATIQUE_GRANTED = 'Free pratique granted',
	NOTICE_OF_READINESS = 'Notice of Readiness',
	PILOT_ON_BOARD = 'Pilot on board',
	PILOT_DISEMBARKED = 'Pilot disembarked',
	UNBERTHED = 'Unberthed',
}

export enum AccountingItemTypes {
	PAYABLE = 'Payable',
	RECEIVABLE = 'Receivable',
}

export enum AccountingItems {
	TcOutInvoices = 'Invoices - TC Out',
	TcInInvoices = 'Invoices - TC In',
	VCOutInvoices = 'Invoices - VC Out',
	VendorExpenses = 'Vendor expenses',
	BrokerInvoices = 'Broker Invoices',
}

export enum AccountingItemApprovalStates {
	PENDING = 'pending',
	SUBMITTED = 'submitted',
	APPROVED = 'approved',
	REJECTED = 'rejected',
	PAID = 'paid',
}

export const ExpenseActions = {
	SUBMITTED: 'submitted',
	UNSUBMITTED: 'unsubmitted',
	APPROVED: 'approved',
	REJECTED: 'rejected',
	MARKED_PAID: 'markedPaid',
	MARKED_UNPAID: 'markedUnpaid',
} as const;

export enum EstimatedItemTypes {
	REVENUE = 'revenue',
	EXPENSE = 'expense',
}

export const AccountingSystemProviders = {
	CODAT: 'codat',
	NAVISION: 'navision',
} as const;

export const AccountingSystemStrategies = {
	GLOBAL: 'global',
	VESSEL: 'vessel',
	VOYAGE: 'voyage',
} as const;

export enum InvoiceTemplates {
	CLASSIC = 'classic',
	NEO = 'neo',
}

export enum CapexOpexInput {
	Yearly = 'Yearly',
	Monthly = 'Monthly',
}

export enum ContractNamingConventions {
	LEGACY = 'legacy',
	DEFAULT = 'default',
	FREE = 'free text',
}

export const ActualAccrualFields = {
	ACTUAL_TC_HIRE: 'Actual TC Hire',
	ACTUAL_ADDCOM: 'Actual Address Commission',
	ACTUAL_BROKERS_COM: 'Actual Broker\'s Commission (Paid By Charterer)',
	ACTUAL_BROKERS_INVOICE: 'Actual Broker\'s Invoice',
	ACTUAL_VENDOR_EXPENSE: 'Actual Vendor Expense',
	ACTUAL_CHARTERERS_EXPENSE: 'Actual Charterer\'s Expense',
	ACTUAL_CHARTERERS_EXPENSE_SUBJECT: 'Actual Charterer\'s Expense Subject To Days',
	ACTUAL_OWNERS_EXPENSE: 'Actual Owner\'s Expense',
	ACTUAL_OFF_HIRE_TIME: 'Actual Off-Hire Time',
	ACTUAL_OFF_HIRE_BUNKER: 'Actual Off-Hire Bunker',
	ACTUAL_OFF_HIRE_ADDCOM: 'Actual Off-Hire Address Commission',
	ACTUAL_OFF_HIRE_BROKERS_COM: 'Actual Off-Hire Broker\'s Commission (Paid By Charterer)',
	ACTUAL_OFF_HIRE_CHARTERERS_EXPENSE_SUBJECT: 'Actual Off-Hire Charterer\'s Expense Subject To Days',
	ACTUAL_OTHER_REVENUE: 'Actual Other Revenue',
	ACTUAL_BUNKER_SALE: 'Actual Bunker Sale',
	ACTUAL_BUNKER_PURCHASE: 'Actual Bunker Purchase',
	ACTUAL_BUNKER_ADJUSTMENT: 'Actual Bunker Adjustment',
	ACTUAL_BUNKER_CONSUMPTION: 'Actual Bunker Consumption',
	ACTUAL_TC_HIRE_COST: 'Actual TC Hire Cost',
	ACTUAL_FREIGHT_REVENUE: 'Actual Freight Revenue',
	ACTUAL_DEMURRAGE_REVENUE: 'Actual Demurrage Revenue',
	ACTUAL_DESPATCH_REVENUE: 'Actual Despatch Revenue',
	ACTUAL_OVERAGE_REVENUE: 'Actual Overage Revenue',
	ACTUAL_DEADFREIGHT_REVENUE: 'Actual Deadfreight Revenue',
	ACTUAL_REVENUE_SUBJECT: 'Actual Revenue Subject To Days',
} as const;

export const AccrualsWithCommencementFields = {
	[ActualAccrualFields.ACTUAL_FREIGHT_REVENUE]: true,
	[ActualAccrualFields.ACTUAL_DEMURRAGE_REVENUE]: true,
	[ActualAccrualFields.ACTUAL_DESPATCH_REVENUE]: true,
	[ActualAccrualFields.ACTUAL_OVERAGE_REVENUE]: true,
	[ActualAccrualFields.ACTUAL_DEADFREIGHT_REVENUE]: true,
	[ActualAccrualFields.ACTUAL_OTHER_REVENUE]: true,
	[ActualAccrualFields.ACTUAL_BROKERS_COM]: true,
	[ActualAccrualFields.ACTUAL_ADDCOM]: true,
};

export const DeferredAccrualFields = {
	DEFERRED_TC_HIRE: 'Deferred TC Hire',
	DEFERRED_ADDCOM: 'Deferred Address Commission',
	DEFERRED_BROKERS_COM: 'Deferred Broker\'s Commission (Paid By Charterer)',
	DEFERRED_BROKERS_INVOICE: 'Deferred Broker\'s Invoice',
	DEFERRED_VENDOR_EXPENSE: 'Deferred Vendor Expense',
	DEFERRED_CHARTERERS_EXPENSE: 'Deferred Charterer\'s Expense',
	DEFERRED_CHARTERERS_EXPENSE_SUBJECT: 'Deferred Charterer\'s Expense Subject To Days',
	DEFERRED_OWNERS_EXPENSE: 'Deferred Owner\'s Expense',
	DEFERRED_OFF_HIRE_TIME: 'Deferred Off-Hire Time',
	DEFERRED_OFF_HIRE_BUNKER: 'Deferred Off-Hire Bunker',
	DEFERRED_OFF_HIRE_ADDCOM: 'Deferred Off-Hire Address Commission',
	DEFERRED_OFF_HIRE_BROKERS_COM: 'Deferred Off-Hire Broker\'s Commission (Paid By Charterer)',
	DEFERRED_OFF_HIRE_CHARTERERS_EXPENSE_SUBJECT: 'Deferred Off-Hire Charterer\'s Expense Subject To Days',
	DEFERRED_OTHER_REVENUE: 'Deferred Other Revenue',
	DEFERRED_BUNKER_SALE: 'Deferred Bunker Sale',
	DEFERRED_BUNKER_PURCHASE: 'Deferred Bunker Purchase',
	DEFERRED_BUNKER_ADJUSTMENT: 'Deferred Bunker Adjustment',
	DEFERRED_BUNKER_CONSUMPTION: 'Deferred Bunker Consumption',
	DEFERRED_TC_HIRE_COST: 'Deferred TC Hire Cost',
	DEFERRED_FREIGHT_REVENUE: 'Deferred Freight Revenue',
	DEFERRED_DEMURRAGE_REVENUE: 'Deferred Demurrage Revenue',
	DEFERRED_DESPATCH_REVENUE: 'Deferred Despatch Revenue',
	DEFERRED_OVERAGE_REVENUE: 'Deferred Overage Revenue',
	DEFERRED_DEADFREIGHT_REVENUE: 'Deferred Deadfreight Revenue',
	DEFERRED_REVENUE_SUBJECT: 'Deferred Revenue Subject To Days',
} as const;

export enum AccrualItemTypes {
	ENTRY = 'entry',
	REVERSAL = 'reversal',
}

export enum AccrualItemStates {
	OPEN = 'open',
	CLOSED = 'closed',
}

export enum AccrualItemClosingPeriodFormats {
	YEAR = 'year',
	MONTH_AND_YEAR = 'month and year',
}

export enum VcContractCompletionTypes {
	TC_IN_DELIVERY = 'tcInDelivery',
	NEXT_CONTRACT = 'nextContract',
}

export enum ConsumptionRelationTypes {
	MAIN = 'main',
	SECONDARY = 'secondary',
}

export enum SalinityTypes {
	SALT = 'salt',
	FRESH = 'fresh',
	BRACKISH = 'brackish',
}

// See https://emailregex.com/
export const EmailRegex = '(?:[a-z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+\\/=?' +
	'^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b' +
	'\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9' +
	'])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9]' +
	'[0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09' +
	'\x0b\x0c\x0e-\x7f])+)\\])';

export enum DateFormats {
	DMY = 'DD-MM-YYYY HH:mm',
	YMD = 'YYYY-MM-DD HH:mm',
	MDY = 'MM-DD-YYYY HH:mm',
}

export enum GlobalLaytimeCalculationMethod {
	TIMETOCOUNT = 'time to count',
	DEDUCTIONS = 'deductions',
}

export enum AccountingItemParentTypes {
	HIRE_INVOICE = 'hireInvoice',
	VOYAGE_EXPENSE = 'expense',
	BROKER_INVOICE = 'brokerInvoice',
	BUNKER_CONSUMPTION = 'bunkerConsumption',
	BUNKER_ADJUSTMENT = 'bunkerAdjustment',
	BUNKER_SALE = 'bunkerSale',
	BUNKER_LIFTING = 'bunkerLifting',
}
