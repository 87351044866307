// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dkdLB2BKi0SOR4gpuUgT {\n\tmargin-left: 5px;\n}\n\n.h6K9Q57tue3fJ0TWSrxh {\n\tmargin-left: 3px;\n\tfont-size: 9pt;\n}\n\n.jAGsgY8uRjeECmrFtCP4,\n._0qbUVuMrR6jiKd0nJoQ {\n\tfont-style: italic;\n\tfont-size: 12px;\n\tpadding-top: 2px;\n\tline-height: 1.3;\n}\n\n.jAGsgY8uRjeECmrFtCP4 {\n\tcolor: rgba(255, 0, 0, 0.8);\n}\n\n._0qbUVuMrR6jiKd0nJoQ {\n\tcolor: rgba(0, 0, 0, 0.8);\n}\n\n.sem9JOyXOJ9aNdk1J6gA {\n\tmargin-left: 5px;\n}\n\n.XhGze05g4VRefaXKL4_F {\n\tmargin-right: 5px;\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/helpers/styles/tableColumns.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;AACjB;;AAEA;CACC,gBAAgB;CAChB,cAAc;AACf;;AAEA;;CAEC,kBAAkB;CAClB,eAAe;CACf,gBAAgB;CAChB,gBAAgB;AACjB;;AAEA;CACC,2BAA2B;AAC5B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;AAClB","sourcesContent":[".dismissButton {\n\tmargin-left: 5px;\n}\n\n.expenseAmount {\n\tmargin-left: 3px;\n\tfont-size: 9pt;\n}\n\n.redOutstandingAmount,\n.neutralOutstandingAmount {\n\tfont-style: italic;\n\tfont-size: 12px;\n\tpadding-top: 2px;\n\tline-height: 1.3;\n}\n\n.redOutstandingAmount {\n\tcolor: rgba(255, 0, 0, 0.8);\n}\n\n.neutralOutstandingAmount {\n\tcolor: rgba(0, 0, 0, 0.8);\n}\n\n.undoButton {\n\tmargin-left: 5px;\n}\n\n.undoIcon {\n\tmargin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dismissButton": "dkdLB2BKi0SOR4gpuUgT",
	"expenseAmount": "h6K9Q57tue3fJ0TWSrxh",
	"redOutstandingAmount": "jAGsgY8uRjeECmrFtCP4",
	"neutralOutstandingAmount": "_0qbUVuMrR6jiKd0nJoQ",
	"undoButton": "sem9JOyXOJ9aNdk1J6gA",
	"undoIcon": "XhGze05g4VRefaXKL4_F"
};
export default ___CSS_LOADER_EXPORT___;
