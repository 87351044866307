// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jFv6LZ5DokGtxE5SnjDV {\n\tpadding: 8px 12px;\n}\n\n.jFv6LZ5DokGtxE5SnjDV:nth-child(even) {\n\tbackground-color: #00000009;\n}\n\n.d5MLwMb_MJlXG62zR5EW {\n\tfont-weight: bold;\n\tmargin-right: 8px;\n}\n\n.d5MLwMb_MJlXG62zR5EW::after {\n\tcontent: ':';\n}\n", "",{"version":3,"sources":["webpack://./client/src/screens/fleet/VoyageDetailsScreen/components/styles/PaymentPartRow.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,2BAA2B;AAC5B;;AAEA;CACC,iBAAiB;CACjB,iBAAiB;AAClB;;AAEA;CACC,YAAY;AACb","sourcesContent":[".part {\n\tpadding: 8px 12px;\n}\n\n.part:nth-child(even) {\n\tbackground-color: #00000009;\n}\n\n.partInvoice {\n\tfont-weight: bold;\n\tmargin-right: 8px;\n}\n\n.partInvoice::after {\n\tcontent: ':';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"part": "jFv6LZ5DokGtxE5SnjDV",
	"partInvoice": "d5MLwMb_MJlXG62zR5EW"
};
export default ___CSS_LOADER_EXPORT___;
