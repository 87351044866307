import React, {
	useCallback,
	useState,
} from 'react';
import { Drawer } from 'antd';
import { FixtureTcRecapItems } from '@shared/utils/fixtureRecap';
import { groupByProperty } from '@shared/utils/array';
import type { AttachmentProps } from '@api/models/attachment';
import TooltipIcon from '@client/components/TooltipIcon';
import showErrorNotification from '@client/utils/showErrorNotification';
import PdfGenerator from '@client/components/PdfGenerator/PdfGenerator';
import {
	createFixtureRecap,
	getFixtureRecapPreview,
} from '@client/lib/api';
import styles from './styles/FixtureRecapGenerator.module.css';
import AttachmentViewer from './AttachmentViewer';

export type FixtureRecapGeneratorProps = {
	fixtureId: number;
	open: boolean;
	onClose: () => void;
	onSave: (any: any) => void;
};

type FixtureItem = {
	id: string | number;
	label: string;
	description: string;
};

const initialAddedItems = [
	'keyTerms',
	'cp',
	'duration',
];

const FixtureRecapGenerator = ({
	fixtureId,
	open,
	onClose,
	onSave,
}: FixtureRecapGeneratorProps): React.ReactElement => {
	const [
		newAttachment,
		setNewAttachment,
	] = useState<AttachmentProps | null>(null);

	const save = async (addedItemKeys: (number | string)[]) => {
		try {
			const { attachment } = await createFixtureRecap(fixtureId, addedItemKeys as string[]);
			await onSave(attachment);

			setNewAttachment(attachment);
		} catch (e) {
			showErrorNotification('Could not save contract recap', e as Error);
		}
	};

	const fetchPreview = useCallback(
		(previewItemIds: Array<string | number>) => getFixtureRecapPreview(
			fixtureId,
			previewItemIds.map((v) => v.toString()),
			// Update preview whenever generator is opened
			// eslint-disable-next-line react-hooks/exhaustive-deps
		), [fixtureId, open],
	);

	const sections = groupByProperty(
		FixtureTcRecapItems,
		'category',
		(i) => ({
			id: i.key,
			label: i.label,
			description: i.description,
		}),
	);

	return (
		<>
			<PdfGenerator
				title={(
					<>
						Generate Recap
						<TooltipIcon
							placement="left"
							iconProps={{ style: { marginLeft: '5px' } }}
						>
							To add items to the recap, just drag-and-drop
							them from the left onto the PDF preview on the right
						</TooltipIcon>
					</>
				)}
				open={open && newAttachment == null}
				onClose={onClose}
				sections={sections}
				renderItem={(item: FixtureItem) => (
					<div className={styles.item}>
						<div className={styles.itemTitle}>{item.label}</div>
						<div className={styles.itemDescription}>{item.description}</div>
					</div>
				)}
				initialAddedItems={initialAddedItems}
				onSave={save}
				getPreview={fetchPreview}
			/>
			<Drawer
				open={newAttachment != null}
				title="Recap"
				placement="left"
				width={720}
				onClose={() => {
					setNewAttachment(null);
					onClose();
				}}
			>
				<p>
					You can always view the recap by opening the &quot;Documents&quot; section.
				</p>
				<AttachmentViewer attachment={newAttachment} />
			</Drawer>
		</>
	);
};

export default FixtureRecapGenerator;
