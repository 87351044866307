import { Moment } from 'moment';
import {
	DATE_AND_TIME,
	TheShipReportTypes,
} from '@shared/utils/constants';
import { formatDate } from '@shared/utils/date';
import { formatNumber } from '@shared/utils/formatNumber';
import type { TheShipReport } from '@api/lib/the-ship/theShip';

type Item = {
	label: string;
	children: string | null;
};

const StringField = (value: string | undefined | null) => {
	if (value == null) {
		return 'N/A';
	}

	return value;
};

const NumberField = (value: number | null | undefined) => {
	if (value == null) {
		return 'N/A';
	}

	return formatNumber(value);
};

const DateField = (value: Moment | null | undefined) => {
	if (value == null) {
		return 'N/A';
	}

	return formatDate(value, DATE_AND_TIME, true);
};

const getCommonItems = (report: TheShipReport) => {
	return [
		{ label: 'Vessel Name', children: StringField(report.vessel.name) },
		{ label: 'Type', children: StringField(TheShipReportTypes[report.type]) },
		{ label: 'Created', children: DateField(report.created) },
		{ label: 'Date', children: DateField(report.dateAndTime) },
		{ label: 'Distance', children: NumberField(report.distance) },
		{ label: 'Latitude', children: NumberField(report.lat) },
		{ label: 'Longitude', children: NumberField(report.lng) },
		{ label: 'ETA Next Port Call', children: DateField(report.etaNextPortCall) },
		...getConsumptionItems(report),
		...getRobItems(report),
	];
};

const getRobItems = (report: TheShipReport) => {
	return report.robs.map((r) => ({
		label: `${r.tank.name} ${r.fuel.name} Remaining on Board`,
		children: NumberField(r.qty),
	}));
};

const getConsumptionItems = (report: TheShipReport) => {
	return report.consumptions.map((c) => ({
		label: `${c.engine.name} ${c.fuel.name} Consumed`,
		children: NumberField(c.consumed),
	}));
};

const getNoonReportItems = (report: TheShipReport) => {
	const items = [
		...getCommonItems(report),
		{ label: 'Course', children: NumberField(report.course) },
		{ label: 'Draft Aft', children: NumberField(report.draftAft) },
		{ label: 'Draft Forward', children: NumberField(report.draftForward) },
		{ label: 'Displacement', children: NumberField(report.displacement) },
		{ label: 'Leg from', children: StringField(report.legFrom?.port?.name) },
		{ label: 'Leg to', children: StringField(report.legFrom?.port?.name) },
		{ label: 'Speed over ground', children: NumberField(report.speedSog) },
		{ label: 'Speed through water', children: NumberField(report.speedStw) },
		{ label: 'Wind direction (True)', children: StringField(report.windDirectionTrue) },
		{ label: 'Wind force', children: NumberField(report.windForce) },
		{ label: 'Remarks', children: StringField(report.comments) },
	];

	return items;
};

const getArrivalDepartureItems = (report: TheShipReport) => {
	return [
		...getCommonItems(report),
		{ label: 'Port', children: StringField(report.portCall.port.name) },
		{ label: 'Arrival time', children: DateField(report.portCall.arrived) },
		{ label: 'Departure time', children: DateField(report.portCall.departed) },
	];
};

const getCrewReportItems = (
	reports: TheShipReport[] | undefined,
	selectedReportId: string | null,
) => {
	if (selectedReportId == null || reports == null) {
		return [];
	}

	const report = reports.find((r) => r.id === selectedReportId);

	if (report == null) {
		return [];
	}

	let items: Item[] = [];

	switch (report.type) {
		case 30:
			items = getNoonReportItems(report);
			break;
		case 40:
		case 50:
			items = getArrivalDepartureItems(report);
			break;
		default:
			items = getCommonItems(report);
			break;
	}

	return items.map((i) => ({
		...i,
		span: 3,
	}));
};

export default getCrewReportItems;
