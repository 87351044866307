import HIIExpenseSubjectToHireDays from '@shared/hireInvoice/HIIExpenseSubjectToHireDays';
import {
	ExpensesSubjectToHireDaysIntervalLabels,
	InvoiceTemplates,
	PnlGroups,
} from '@shared/utils/constants';
import { toMoment } from '@shared/utils/date';
import { formatCurrency } from '../utils/currency';
import TemplateItem from '../TemplateItem';
import HireInvoiceItem from './HireInvoiceItem';

class HIIOffHireExpenseSubjectToHireDays extends HireInvoiceItem {
	static get itemType() {
		return 'offHireExpenseSubjectToHireDays';
	}

	constructor(id, isOriginal, params) {
		super(id, isOriginal, { fixtureCurrency: params.fixtureCurrency });

		this.name = params.name;
		this.amount = params.amount;
		this.interval = params.interval;
		this.calculationMethodForExpensesSubjectToHireDays =
			params.calculationMethodForExpensesSubjectToHireDays;
		this.pnlGroup = PnlGroups.OFFHIRE;
		this.itemTypeLabel = params.name;

		this._showTotal = true;
		this.addManually = false;
		this.templateSection = TemplateItem.Sections.EXPENSES_CONTRACTUAL;

		this._fields = {};
	}

	_getExpense(invoiceItems) {
		const expenseInstance = new HIIExpenseSubjectToHireDays(-1, this.isOriginal, {
			name: this.name,
			amount: this.amount,
			interval: this.interval,
			formulaType: this.calculationMethodForExpensesSubjectToHireDays,
		});

		const offHirePeriod = this.getParent(invoiceItems);

		if (offHirePeriod == null) {
			return {
				amount: 0,
				total: 0,
			};
		}

		const duration = toMoment(offHirePeriod.endTime).diff(offHirePeriod.startTime, 'minutes');
		const days = duration / 60 / 24;

		return {
			amount: expenseInstance.amount,
			total: (
				expenseInstance.calculateAmount(days) *
				(offHirePeriod.percentageForOwnersAccount / 100)
			),
		};
	}

	_calculateTotal(invoiceItems) {
		const { total } = this._getExpense(invoiceItems);

		return -1 * total;
	}

	getDescription() {
		return `${formatCurrency(this.amount, this.fixtureCurrency)} ${ExpensesSubjectToHireDaysIntervalLabels[this.interval]}`;
	}

	static async _getClassParams(model, parentModel) {
		const voyage = await parentModel.getVoyage();
		const fixture = await voyage.getFixture();
		const { childFixture } = fixture;

		return {
			name: model.name,
			amount: model.amount,
			interval: model.interval,
			calculationMethodForExpensesSubjectToHireDays:
				childFixture.calculationMethodForExpensesSubjectToHireDays,
			fixtureCurrency: childFixture.fixtureCurrency,
		};
	}

	async saveModel(model) {
		await super.saveModel(model);

		model.name = this.name;
		model.amount = this.amount;
		model.interval = this.interval;

		await model.save();
	}

	_getTemplateItemParams(invoiceItems) {
		const { amount } = this._getExpense(invoiceItems);

		return {
			[InvoiceTemplates.CLASSIC]: {
				columns: [this.name, formatCurrency(amount, this.fixtureCurrency)],
			},
			[InvoiceTemplates.NEO]: {
				columns: [
					` — ${this.name} (${ExpensesSubjectToHireDaysIntervalLabels[this.interval]})`,
					'1',
					'',
					`${formatCurrency(this.getTotal(invoiceItems), this.fixtureCurrency, { hideSymbol: true })}`,
				],
			},
		};
	}
}

HireInvoiceItem.addItemClass(HIIOffHireExpenseSubjectToHireDays);

export default HIIOffHireExpenseSubjectToHireDays;
