import React, {
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useDrag,
	useDrop,
} from 'react-dnd';
import classNames from 'classnames';
import styles from './styles/EditableCellTableRedux.module.css';

export type ReorderableRowProps = {
	order?: number;
	reorderRow?: (dragOrder?: number, hoverOrder?: number) => void;
	className?: string;
	children?: React.ReactNode;
	isDroppable?: (item: ReorderableRowProps, props: ReorderableRowProps) => boolean;
	isDraggable?: (index: number) => boolean;
};

const TYPE = 'ReorderableRow' as const;

const ReorderableRow = (props: ReorderableRowProps) => {
	const {
		order,
		reorderRow,
		className,
		children,
		isDroppable,
		isDraggable,
	} = props;

	const [allowDrag, setAllowDrag] = useState(true);

	useEffect(() => {
		if (order != null && typeof isDraggable === 'function') {
			setAllowDrag(isDraggable(order));
		}
	}, [isDraggable, order]);

	const trRef = useRef<HTMLTableRowElement>(null);
	const [{ isOver, dropClassName }, drop] = useDrop({
		accept: TYPE,
		canDrop: (item) => (typeof isDroppable === 'function' ? isDroppable(item, props) : true),
		collect: (monitor) => {
			const item: { order?: number } | undefined = monitor.getItem();

			if (item?.order === order) {
				return {};
			}

			if (item?.order == null || order == null) {
				return {};
			}

			const newIsOver = monitor.isOver();
			const canDrop = monitor.canDrop();

			return {
				item,
				isOver: newIsOver,
				canDrop,
				dropClassName: canDrop ?
					(
						item.order < order ?
							styles.dropOverDownward :
							styles.dropOverUpward
					) :
					undefined,
			};
		},
		drop: (item: any) => {
			if (
				reorderRow == null ||
				order == null ||
				item.order == null
			) {
				return;
			}

			reorderRow(item.order, order);
		},
	});

	const [_, drag] = useDrag({
		item: {
			type: TYPE,
			order,
		},
		canDrag: allowDrag,
		collect: (monitor) => {
			const canDrag = monitor.canDrag();

			return {
				canDrag,
			};
		},
	});

	drop(drag(trRef));

	if (trRef.current != null) {
		trRef.current.draggable = false;
	}

	return (
		<tr
			ref={trRef}
			className={classNames(className, isOver ? dropClassName : undefined)}
		>
			{children ?? null}
		</tr>
	);
};

export default ReorderableRow;
