import React, {
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	Drawer,
	Form,
	Grid,
	Modal,
} from 'antd';
import {
	Currencies,
	ExpenseTypes,
} from '@shared/utils/constants';
import { Values } from '@shared/utils/objectEnums';
import { asyncMap } from '@shared/utils/array';
import type { TransformedExpenses } from '@api/utils/getTransformedVoyageExpenses';
import type { AttachmentProps } from '@api/models/attachment';
import {
	createSupplier,
	createVoyageExpense,
	getSuppliers,
	getVoyages,
	updateVoyageExpense,
} from '@client/lib/api';
import { useNavigationBlock } from '@client/lib/navigationBlock';
import showErrorNotification from '@client/utils/showErrorNotification';
import useFileUpload, { Upload } from '@client/utils/hooks/useFileUpload';
import InvoiceAttachment from '@client/screens/fleet/VoyageDetailsScreen/components/InvoiceAttachment';
import useFetchedState from '@client/utils/hooks/useFetchedState';
import showSuccessNotification from '@client/utils/showSuccessNotification';
import APIError from '@client/utils/APIError';
import getFileFromAttachment from '@client/utils/getFileFromAttachment';
import LoadingIndicator from '@client/components/LoadingIndicator';
import styles from './CreateExpenseForm.module.css';
import FormItems from './FormItems';
import VcFormItems from './VcFormItems';

type Props = {
	open: boolean;
	onClose: () => void;
	initialFiles?: Upload[];
	editing?: Omit<TransformedExpenses, 'charterer'> & { charterer?: number | null };
	documentId?: number;
	voyageId?: number;
}

const CreateExpenseForm = ({
	open,
	onClose,
	initialFiles,
	documentId,
	editing,
	voyageId,
}: Props) => {
	const [expenseType, setExpenseType] = useState<Values<typeof ExpenseTypes>>('payable');
	const [expenseIsPortDA, setExpenseIsPortDA] = useState(false);
	const [suppliers] = useFetchedState(getSuppliers);
	const [voyages] = useFetchedState(getVoyages);
	const [loadingAttachments, setLoadingAttachments] = useState(false);
	const [creatingNewSupplier, setCreatingNewSupplier] = useState(false);

	const {
		uploaded,
		uploadProps,
		onRemoveUpload,
		onSetUploaded,
		onResetUploaded,
	} = useFileUpload({ multiple: true });
	const [dirty, setDirty] = useState(false);

	const [form] = Form.useForm();

	const {
		useBlocker,
		makeBlockable,
	} = useNavigationBlock();

	const screens = Grid.useBreakpoint();

	useEffect(() => {
		if (initialFiles == null || initialFiles.length === 0) {
			return;
		}

		onSetUploaded(initialFiles);
	}, [onSetUploaded, initialFiles]);

	useEffect(() => {
		if (!open) {
			onResetUploaded();
		}
	}, [onResetUploaded, open]);

	const close = () => {
		onClose();
		setExpenseIsPortDA(false);
		onClose();
		setDirty(false);
		form.resetFields();
	};

	const onCloseForm = makeBlockable(close);

	useEffect(() => {
		if (editing != null) {
			const editingExpenseType = editing.receivable == null ?
				ExpenseTypes.PAYABLE :
				ExpenseTypes.BOTH;

			setExpenseType(editingExpenseType);
			if (editing?.portCallId != null) {
				setExpenseIsPortDA(true);
			}

			form.setFieldsValue({
				...editing,
				type: editingExpenseType,
				amount: {
					value: editing.amount,
					currency: editing.currency,
					exchangeRate: editing.exchangeRate,
				},
			});
		}
	}, [editing, form]);

	const attachmentUpload = useMemo(() => (
		<div className={styles.attachmentsInner}>
			<InvoiceAttachment
				files={uploaded}
				uploadProps={uploadProps}
				onRemoveFile={onRemoveUpload}
			/>
		</div>
	), [onRemoveUpload, uploadProps, uploaded]);

	useBlocker(dirty);

	const saveVendorExpense = async () => {
		const {
			voyage,
			itemDescription,
			amount,
			customInvoiceId,
			note,
			supplierName,
			invoiceDate,
			dueDate,
			type,
			isPortDA,
			portCallId,
			charterer,
			estimated,
		} = form.getFieldsValue(true);

		let { supplierId } = form.getFieldsValue(true);

		try {
			if (creatingNewSupplier && supplierName != null) {
				const supplier = await createSupplier(supplierName);
				supplierId = supplier.id;
			}

			const params = {
				attachments: Array.isArray(uploaded) ? uploaded.map((u) => u.file) : uploaded,
				itemDescription,
				amount: amount ? amount.value : 0,
				currency: amount ? amount.currency : Currencies.USD,
				exchangeRate: amount?.exchangeRate || null,
				note,
				customInvoiceId,
				voyageId: voyageId ?? voyage,
				documentId,
				supplierId: supplierId == null ? null : supplierId,
				invoiceDate,
				dueDate,
				type,
				portCallId,
				isPortDA,
				charterer,
				estimated,
			};

			if (editing != null) {
				await updateVoyageExpense(editing.id, params);
				showSuccessNotification(
					'Expense updated',
					'The expense was successfully updated',
				);
			} else {
				if (params.amount < 0 && params.type === ExpenseTypes.BOTH) {
					throw new APIError('You cannot create corresponding receivable from a negative payable', true, 400);
				}

				await createVoyageExpense(params);
				showSuccessNotification(
					'Expense created',
					'The expense was successfully created',
				);
			}

			close();
		} catch (e) {
			console.error(e);
			showErrorNotification('Could not save expense', e as Error);
		}
	};

	useEffect(() => {
		const updateDefaultFiles = async () => {
			setLoadingAttachments(true);

			if (editing == null || editing.attachments?.length === 0) {
				setLoadingAttachments(false);

				return;
			}

			try {
				const getUploadObject = async (attachment: AttachmentProps) => {
					const file: File & {
						uid?: string;
						attachmentId?: number;
						deleteable?: boolean;
					} = await getFileFromAttachment(attachment);

					file.uid = `${file.name}-${file.lastModified}`;
					file.attachmentId = attachment.id;

					return {
						file: file as File & {
							uid: string;
							attachmentId: number;
							deleteable?: boolean;
						},
						url: window.URL.createObjectURL(file),
					};
				};

				const expenseAttachments = editing.attachments;

				if (expenseAttachments == null) {
					return;
				}

				const expenseFiles = await asyncMap(
					expenseAttachments,
					async (attachment) => {
						const uploadObj: {
							url?: any;
							deleteable?: boolean;
							file: File & {
								uid?: string;
								attachmentId?: number;
							};
						} = await getUploadObject(attachment);

						uploadObj.deleteable = true;

						return uploadObj as {
							url: any;
							deleteable?: boolean;
							file: File & {
								uid: string;
								attachmentId: number;
							};
						};
					},
				);

				onSetUploaded(expenseFiles);
			} catch (e) {
				showErrorNotification('Could not load attachments', e as Error);
			}

			setLoadingAttachments(false);
		};

		updateDefaultFiles();
	}, [onSetUploaded, editing]);

	const handleModalBackgroundClick = (e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			onCloseForm();
		}
	};

	return (
		<>
			<Modal
				open={open}
				wrapClassName={styles.rightSideModalWrapper}
				footer={null}
				width="100%"
				mask={false}
			>
				<div className={styles.drawerSpacerContainer}>
					<div className={styles.drawerSpacer} />
					<div
						className={styles.attachmentsDraggerContainer}
						onClick={handleModalBackgroundClick}
						role="presentation"
					>
						{loadingAttachments ? (
							<LoadingIndicator />
						) : (
							!screens.xs && attachmentUpload
						)}
					</div>
				</div>
			</Modal>
			<Drawer
				open={open}
				placement="left"
				width={350}
				title="Create new vendor expense"
				onClose={onCloseForm}
				mask={!open}
			>
				<Form
					layout="vertical"
					form={form}
					onValuesChange={(v: {
						type: Values<typeof ExpenseTypes>;
						isPortDA: boolean;
					}) => {
						if (v.isPortDA != null) {
							setExpenseIsPortDA(v.isPortDA);
						}

						if (v?.type != null) {
							setExpenseType(v.type);
						}
					}}
					onFinish={saveVendorExpense}
					preserve
				>
					{voyageId == null ? (
						<FormItems
							creatingNewSupplier={creatingNewSupplier}
							setCreatingNewSupplier={setCreatingNewSupplier}
							voyages={voyages}
							suppliers={suppliers}
							form={form}
							extra={screens.xs ? attachmentUpload : undefined}
						/>
					) : (
						<VcFormItems
							voyages={voyages}
							suppliers={suppliers}
							expenseType={expenseType}
							onCloseForm={onCloseForm}
							voyageId={voyageId}
							isPortDA={expenseIsPortDA}
							isBunkerStem={editing?.isBunkerStem ?? false}
							isEditing={editing != null}
							extra={screens.xs ? attachmentUpload : undefined}
							creatingNewSupplier={creatingNewSupplier}
							setCreatingNewSupplier={setCreatingNewSupplier}
						/>
					)}
				</Form>
			</Drawer>
		</>
	);
};

export default CreateExpenseForm;
