import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { hot } from 'react-hot-loader/root';
import { useLocation } from 'react-router-dom';
import UnauthorizedScreen from '@client/screens/general/UnauthorizedScreen/UnauthorizedScreen';
import ActivationScreen from '@client/screens/auth/ActivationScreen';
import Dashboard from '@client/screens/Dashboard';
import { useGlobalState } from '@client/lib/globalState';
import { useAuth } from './lib/auth';
import styles from './App.module.css';

const App = () => {
	const [pageTitle] = useGlobalState('PAGE_TITLE');

	useEffect(() => {
		document.title = pageTitle != null ? `${pageTitle} - ClearVoyage VMS` : 'ClearVoyage VMS';
	}, [pageTitle]);

	const {
		isAuthenticated,
		loading,
		loginWithRedirect,
		hasAccess,
		accessError,
	} = useAuth();

	const location = useLocation();

	const isActivating = location.pathname.startsWith('/auth/activation');

	if (loading) {
		return (
			<div className={styles.loadingWrapper}>
				<Spin size="large" />
			</div>
		);
	}

	if (!isAuthenticated) {
		loginWithRedirect();

		return null;
	}

	if (isActivating) {
		return (
			<ActivationScreen />
		);
	}

	if (!hasAccess) {
		return (
			<UnauthorizedScreen accessError={accessError} />
		);
	}

	return (<Dashboard data-cy="dashboard" />);
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
